import React from 'react'
import { Button, Container } from 'react-bootstrap'
import { Copy } from 'react-feather'
import { AppContext } from '../../App'

import * as Messages from '../../components/ui/messages/messages'

import { S3Config } from '../../constants/config'

const CombinedCalendar = () => {
	const { appState } = React.useContext(AppContext)
	const [icsUrl, setIcsUrl] = React.useState<string>(
		`https://${S3Config.BaseUrl}/calendars/${appState.authState.attributes?.['custom:currentAccountId']}/combined.ics`
	)
	const [htmlUrl, setHtmlUrl] = React.useState<string>(
		`https://${S3Config.BaseUrl}/calendars/${appState.authState.attributes?.['custom:currentAccountId']}/combined.html`
	)
	const [messages, updateMessages] = Messages.useMessageReducer([])

	// test the urls

	React.useEffect(() => {
		const fetchData = async () => {
			try {
				await fetch(icsUrl, { mode: 'no-cors' })
			} catch (error) {
				setIcsUrl('')
			}

			try {
				await fetch(htmlUrl, { mode: 'no-cors' })
			} catch (error) {
				setHtmlUrl('')
			}
		}

		if (appState.authState.isLoggedIn) {
			fetchData()
		}
	}, [appState.authState])

	return (
		<Container className="oversight-container" style={{ height: '1000px' }}>
			<p>
				This will aggregate <strong>ALL</strong> LeaveCal calendars under your account making them available to subscribe to in a single view. For now
				there is no ability to filter or modify the information displayed in the calendar. Control of information displayed is dictated by the child
				calendars.{' '}
				<a href={'https://help.finlert.com/leavecal/adding-leavecal-to-your-calendar/'} target="_blank" rel="noopener noreferrer">
					Installation instructions
				</a>
			</p>

			<Button
				type="button"
				disabled={icsUrl === ''}
				onClick={() => {
					navigator.clipboard.writeText(icsUrl)
					updateMessages({
						type: 'add',
						data: {
							severity: 'success',
							message: 'Copied URL to clipboard',
							timeout: 3000,
							dismissible: true,
						},
					})
				}}
			>
				<Copy /> Copy Calendar Feed Link
			</Button>
			<Button
				type="button"
				style={{ marginLeft: '15px' }}
				disabled={htmlUrl === ''}
				onClick={() => {
					navigator.clipboard.writeText(htmlUrl)
					updateMessages({
						type: 'add',
						data: {
							severity: 'success',
							message: 'Copied URL to clipboard',
							timeout: 3000,
							dismissible: true,
						},
					})
				}}
			>
				<Copy /> Copy Graphical Calendar Link
			</Button>
			<br />
			{htmlUrl !== '' ? (
				<iframe src={htmlUrl} name="targetframe" title={'combined-Cal'} style={{ width: '100%', height: '100%' }}></iframe>
			) : (
				<p style={{ paddingTop: '15px' }}>
					No combined calendar was found for this account. You may have no permissions to view this calendar or <strong>ALL</strong> LeaveCal
					calendars under your account have their graphical output turned <strong>Off</strong>.
				</p>
			)}

			<Messages.Messages messages={messages} updateMessage={updateMessages} />
		</Container>
	)
}

export default CombinedCalendar
