import React, { useState, useRef } from 'react'
import * as MessageTypes from './message'
import { Alert } from 'react-bootstrap'
import { AlertTriangle } from 'react-feather'

const addMessage = (severity: 'danger' | 'success', message: string, timeout = 3000, dismissible = true): MessageTypes.MessageAction => ({
	type: 'add',
	data: {
		severity: severity,
		message: message,
		timeout: timeout,
		dismissible: dismissible,
	},
})

const messageReducer = (state: MessageTypes.Message[], action: MessageTypes.MessageAction) => {
	switch (action.type) {
		case 'add':
			return [...state, action.data]
		case 'remove':
			return state.filter((msg) => msg.id !== action.data.id)
		default:
			return state
	}
}
const setMessageTimeout = (props: any[]): void => {
	const setMessages = props[0].setMessages
	const nextState = messageReducer(props[0].messagesRef.current, {
		type: 'remove',
		data: props[0].data,
	})
	setMessages(nextState)
}

const useMessageReducer = (initialState: MessageTypes.Message[]): [MessageTypes.Message[], (action: MessageTypes.MessageAction) => void] => {
	const [messages, setMessages] = useState<MessageTypes.Message[]>(initialState)
	const [messageID, setMessageID] = useState(1)
	const messagesRef = useRef(messages)
	messagesRef.current = messages

	const dispatch = (action: MessageTypes.MessageAction): void => {
		if (action.type === 'add') {
			action.data.id = messageID
			setMessageID(Math.random())
			const timeout = action.data.timeout
			if (timeout > 0) {
				setTimeout(setMessageTimeout, timeout, [
					{
						messageID: messageID,
						data: action.data,
						messagesRef: messagesRef,
						setMessages: setMessages,
					},
				])
				//clearTimeout(timeout);
			}
		}
		const nextState = messageReducer(messages, action)
		setMessages(nextState)
	}

	return [messages, dispatch]
}

const Messages = (props: MessageTypes.MessagesProps) => {
	return (
		<div
			aria-live="polite"
			aria-atomic="true"
			style={{
				position: 'fixed',
				top: 100,
				right: 10,
			}}
		>
			{props.messages.map((message: MessageTypes.Message, id: number) => (
				<Alert
					key={id}
					variant={message.severity}
					dismissible={message.dismissible}
					onClose={() => props.updateMessage({ type: 'remove', data: message })}
					autoFocus
				>
					{message.severity === 'danger' ? <AlertTriangle className="feather" size="18" /> : null}
					{message.message.split('\n').map((line: string, i: number) => (
						<span key={i}>
							<span>{line}</span>
							<br />
						</span>
					))}
				</Alert>
			))}
		</div>
	)
}

export { Messages, useMessageReducer, addMessage }
