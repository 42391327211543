import React from 'react'
import { Navigate } from 'react-router-dom'
import { AppContext } from '../../App'

const Callback = () => {
	const { appState } = React.useContext(AppContext)
	return <Navigate to={appState.context === 'leavecal' ? '/' : '/xero/connections'} />
}

export default Callback
