import * as React from 'react'

// definitions
import * as AppDefinitions from '../../App.d'
import { AccountXeroOrg } from '../../../../back-end/common/accountXeroOrg'
import { XeroAccountBalance } from '../../../../back-end/common/xeroAccountBalance'
import { BankTransaction } from '../../../../back-end/common/bankTransaction'

// components
import { FormLabel, Row, Button, Modal, Col, OverlayTrigger, Tooltip } from 'react-bootstrap'
import TAYGLoadingImage from '../../images/TAYG_100px.gif'
import GstIcon from '../../images/taygIcons/icon_cash.svg'
import PaygIcon from '../../images/taygIcons/icon_payg.svg'
import SuperIcon from '../../images/taygIcons/icons_saving.svg'
import TAYGIcon from '../../images/taygIcons/TaxAsYouGo icon 1.png'
import PaygIncomeTax from '../../images/taygIcons/paygIT.svg'
import NonCurrentLiabilityIcon from '../../images/taygIcons/icon_noncurrent.svg'

// utilities
import * as Request from '../../utilities/request'
import { TaygSettings } from '../../../../back-end/common/taygSettings'
import moment from 'moment'
import { HelpCircle } from 'react-feather'
import { TaygSettingsExtraAccount } from '../../../../back-end/utilities/apiDefinitions'

interface OverviewComponentProps {
	show: boolean
	appState: AppDefinitions.AppState
	currentOrg: AccountXeroOrg
	currentTaygSettings: TaygSettings | TaygSettings[]
	isGstSetup: boolean
	pageStatus: string
	updatePageStatus: React.Dispatch<React.SetStateAction<string>>
}

const getValueForAccount = (balances: XeroAccountBalance[], settingsAccountID: string | undefined) =>
	Number(
		balances
			.filter((xab: XeroAccountBalance) => xab.xeroAccountID === settingsAccountID)
			?.sort((a: XeroAccountBalance, b: XeroAccountBalance) => new Date(b.date).getTime() - new Date(a.date).getTime())
			.shift()?.value || 0
	)

const getValuesForGivenOrg = (balances: XeroAccountBalance[], setting: TaygSettings) => {
	const gst = getValueForAccount(balances, setting.gstXeroAccountID)
	const payg = getValueForAccount(balances, setting.paygPayXeroAccountID)
	const superPay = getValueForAccount(balances, setting.superPayXeroAccountID)
	const savings = getValueForAccount(balances, setting.savingsXeroAccountID)
	return [gst, payg, superPay, savings]
}

const GST = 0
const PAYG = 1
const SUPER = 2
const SAVINGS = 3
const COMPANYINCOMETAX = 4

const OverviewComponent = (props: OverviewComponentProps) => {
	const [xeroAccountBalances, updateXeroAccountBalances] = React.useState<XeroAccountBalance[]>()
	const [unreconciledTransactions, updateUnreconciledTransactions] = React.useState<BankTransaction[]>()
	const [isNew, updateIsNew] = React.useState<boolean>(false)
	const [showPaygSettings, updateShowPaygSettings] = React.useState<boolean>(false)
	const [showSuperSettings, updateShowSuperSettings] = React.useState<boolean>(false)
	const [showSavings, updateShowSavings] = React.useState<boolean>(false)

	const getValues = () => {
		if (xeroAccountBalances && props.currentTaygSettings) {
			if (props.currentOrg.accountXeroOrgID && props.currentOrg.accountXeroOrgID.includes(',') && Array.isArray(props.currentTaygSettings)) {
				let payValues = [0, 0, 0, 0, 0] //gst, pay, super, savings, companyincometax
				const taygSettings = props.currentTaygSettings as TaygSettings[]

				taygSettings.forEach((taygSetting: TaygSettings) => {
					const values = getValuesForGivenOrg(xeroAccountBalances, taygSetting)
					payValues = [
						payValues[GST] + values[GST],
						payValues[PAYG] + values[PAYG],
						payValues[SUPER] + values[SUPER],
						payValues[SAVINGS] + values[SAVINGS],
						payValues[COMPANYINCOMETAX] + Number(taygSetting.paygCompanyIncomeTaxAmount || 0),
					]
				})
				return payValues
			} else {
				const taygSettings = props.currentTaygSettings as TaygSettings
				const values = getValuesForGivenOrg(xeroAccountBalances, taygSettings)
				return values.concat(Number(taygSettings.paygCompanyIncomeTaxAmount || 0))
			}
		}
		return [0, 0, 0, 0, 0]
	}
	const unreconciledValues = unreconciledTransactions
		? unreconciledTransactions.map((bt: BankTransaction) => bt.total).reduce((acc: number, curr: number) => acc + Number(curr), 0)
		: 0

	React.useEffect(() => {
		const fetchData = async () => {
			const [xeroAccountBalanceData, bankTransactionsData] = await Promise.all([
				Request.get(
					`xeroaccountbalance?where=XeroOrgID=in(${props.currentOrg.xeroOrg!.xeroOrgID})&where=XeroAccountBalanceDate>${moment()
						.subtract(2, 'day')
						.toJSON()}`,
					props.appState.authState
				),
				Request.get(`banktransaction?where=XeroOrgID=in(${props.currentOrg.xeroOrg!.xeroOrgID})`, props.appState.authState),
			])

			const balances = xeroAccountBalanceData.data.xeroAccountBalance
			updateXeroAccountBalances(balances)
			const unreconciled = bankTransactionsData.data.bankTransaction.filter((bt: BankTransaction) => bt.isReconciled === false)
			updateUnreconciledTransactions(unreconciled)

			updateShowSavings(
				Array.isArray(props.currentTaygSettings) ||
					(props.currentTaygSettings.savingsXeroAccountID !== null && props.currentTaygSettings.savingsXeroAccountID !== undefined)
			)
			updateShowPaygSettings(
				Array.isArray(props.currentTaygSettings) ||
					(props.currentTaygSettings.paygPayXeroAccountID !== null && props.currentTaygSettings.paygPayXeroAccountID !== undefined)
			)
			updateShowSuperSettings(
				Array.isArray(props.currentTaygSettings) ||
					(props.currentTaygSettings.superPayXeroAccountID !== null && props.currentTaygSettings.superPayXeroAccountID !== undefined)
			)
			if (props.show) {
				props.updatePageStatus('Finished')
			}
		}
		const checkIsNew = async () => {
			if (localStorage['isNewIDs']) {
				let isNew: string[] = JSON.parse(localStorage.getItem('isNewIDs') || '')

				if (isNew.includes(props.currentOrg.accountXeroOrgID!)) {
					updateIsNew(true)

					// Remove the ID from localStorage.
					isNew = isNew.filter((axoid) => axoid !== props.currentOrg.accountXeroOrgID)

					// Reset localStorage without the ID.
					localStorage.setItem('isNewIDs', JSON.stringify(isNew))
				}
			}
		}
		if (props.appState.authState.isLoggedIn && props.currentOrg.xeroOrg && props.currentTaygSettings) {
			fetchData()
			checkIsNew()
		} //eslint-disable-next-line
	}, [props.appState.authState, props.currentOrg.xeroOrg, props.currentOrg.accountXeroOrgID, props.currentTaygSettings])

	const calculateLiability = (gst?: number, payg?: number, superannuation?: number, stringValue = true) => {
		let totalLiability = 0

		if (gst) {
			totalLiability += gst
		}
		if (payg) {
			totalLiability += payg
		}
		if (superannuation) {
			totalLiability += superannuation
		}
		if (gst === undefined || (payg === undefined && showPaygSettings) || (superannuation === undefined && showSuperSettings)) {
			return `${Math.round(totalLiability).toLocaleString(undefined, { maximumFractionDigits: 2 })} (but... we do not have the balance for all accounts)`
		}

		return stringValue ? Math.round(totalLiability).toLocaleString(undefined, { maximumFractionDigits: 2 }) : Math.round(totalLiability)
	}

	const calculateNetValue = (liability: number, taygSavings: number) => {
		const result = Math.round(liability - taygSavings) * -1
		return '$' + result.toLocaleString(undefined, { maximumFractionDigits: 2 })
	}

	// Lodgment Dates
	const calculateDays = (today: moment.Moment, dueDay: moment.Moment) => {
		const diff = moment.duration(dueDay.diff(today)).asDays()
		if (Math.floor(diff) === 0 || Math.ceil(diff) === 0) {
			return 'today'
			// } else if (Math.round(diff) < 0) {
			// 	return `${Math.round(Math.abs(diff))} day${Math.round(Math.abs(diff)) !== 1 ? 's' : ''} ago`
		} else if (Math.round(diff) > 0 || Math.round(diff) < 0) {
			return `in ${Math.abs(Math.round(diff))} day${Math.abs(Math.round(diff)) !== 1 ? 's' : ''}`
		} else {
			return ''
		}
	}

	const handleCombinedViewLodgmentDates = (value: string) => {
		const taygSettings = props.currentTaygSettings as TaygSettings[]
		// go over what we need to show and show it
		if (value === 'gst') {
			return taygSettings
				.filter((settings, index) => index === taygSettings.findIndex((s) => s.gstLodgmentDatePeriod === settings.gstLodgmentDatePeriod)) // get unique date periods only
				.map((settings) => calculateGstLodgmentDate(settings))
				.join('')
		} else if (value === 'payg') {
			// payg
			// handle special case option first as it prints both together
			return taygSettings
				.filter((settings, index) => index === taygSettings.findIndex((s) => s.paygLodgmentDatePeriod === settings.paygLodgmentDatePeriod)) // get unique date periods only
				.map((settings) => calculatePaygLodgmentDate(settings))
				.flatMap((dateString) => dateString.split('<br />')) // to avoid quarter dates being displayed twice when one comes back from the special rule of gst being quarterly and payg being monthly
				.filter((value, index, array) => array.indexOf(value) === index)
				.join('<br />')
		} else if (value === 'paygCIT') {
			return calculatePaygLodgmentDate(taygSettings[0], false, false, true, false, 'paygCIT') // it is always quarterly
		}
		return ''
	}

	const checkDateAndReturnWeekdayEquivalent = (date: moment.Moment) => {
		const dayOfWeek = date.format('d') // 6 = Saturday, 0 = Sunday
		if (dayOfWeek === '6' || dayOfWeek === '0') {
			return date.startOf('isoWeek').add(1, 'week')
		}
		return date
	}

	const getEndOfQuarters = (today: moment.Moment) => {
		const endOfFirstQuarter = checkDateAndReturnWeekdayEquivalent(
			moment(
				`${today.isSameOrBefore(checkDateAndReturnWeekdayEquivalent(moment(`${today.year()}-05-26`)), 'date') ? today.year() : today.year() + 1}-05-26`
			)
		)
		const endOfSecondQuarter = checkDateAndReturnWeekdayEquivalent(
			moment(
				`${today.isSameOrBefore(checkDateAndReturnWeekdayEquivalent(moment(`${today.year()}-08-25`)), 'date') ? today.year() : today.year() + 1}-08-25`
			)
		)
		const endOfThirdQuarter = checkDateAndReturnWeekdayEquivalent(
			moment(
				`${today.isSameOrBefore(checkDateAndReturnWeekdayEquivalent(moment(`${today.year()}-11-25`)), 'date') ? today.year() : today.year() + 1}-11-25`
			)
		)
		const endOfFourthQuarter = checkDateAndReturnWeekdayEquivalent(
			moment(
				`${today.isSameOrBefore(checkDateAndReturnWeekdayEquivalent(moment(`${today.year()}-02-28`)), 'date') ? today.year() : today.year() + 1}-02-28`
			)
		)

		return [endOfFirstQuarter, endOfSecondQuarter, endOfThirdQuarter, endOfFourthQuarter]
	}

	const calculateQuarter = (
		today: moment.Moment,
		endOfFirstQuarter: moment.Moment,
		endOfSecondQuarter: moment.Moment,
		endOfThirdQuarter: moment.Moment,
		endOfFourthQuarter: moment.Moment,
		forWho: string
	) => {
		const deadline = today //.subtract(7, 'days')
		//order quarters based on dates
		const quarterOrder = [endOfFirstQuarter, endOfSecondQuarter, endOfThirdQuarter, endOfFourthQuarter].sort((a, b) => deadline.diff(b) - deadline.diff(a))
		console.log(forWho, 'quarter order', quarterOrder.map((q) => q.toJSON()).join(', '))
		return quarterOrder[0].isSame(endOfFirstQuarter)
			? 1
			: quarterOrder[0].isSame(endOfSecondQuarter)
			? 2
			: quarterOrder[0].isSame(endOfThirdQuarter)
			? 3
			: 4
	}
	// const calculateMonth = (today: moment.Moment, endOfMonth: moment.Moment) => {
	// 	return today.month() + (endOfMonth.isSameOrBefore(today, 'day') ? 1 : 0)
	// }

	const monthlyDueDate = (today: moment.Moment) => {
		const dueDate = checkDateAndReturnWeekdayEquivalent(moment(`${today.year()}-${today.month() + 1}-21`))
		if (today.isSameOrBefore(dueDate)) {
			return dueDate
		} else {
			return checkDateAndReturnWeekdayEquivalent(moment(`${today.year()}-${today.month() + 2}-21`))
		}
	}

	const calculateGstLodgmentDate = (taygSettings: TaygSettings, emptyReturn = false, monthly = false, quarterly = false, annual = false): string => {
		// Monthly
		// 	GST is due on the 21st of the following month + any days to push it to the next Monday if it’s a weekend

		// Quarterly
		// 	GST is due 28 days after the 28th of the month following end of quarter (except Dec qtr which is 28-Feb)
		// + any days to push it to the next Monday if it’s a weekend
		// 	E.g. Jan, Feb, Mar (know as the Mar Qtr) is due on May 26 (this is April 28 + 28 days) and it’s not on the weekend so no extras

		// Annual
		// 	GST is due 31st October for the period Jul to Jun
		const today = moment()
		if (taygSettings.gstLodgmentDatePeriod === 'Monthly' || monthly) {
			// Monthly
			// const dueDate = checkDateAndReturnWeekdayEquivalent(moment(`${today.year()}-${today.month()}-21`))
			// const monthNumber = calculateMonth(today, dueDate)
			const dueDate = monthlyDueDate(today)

			return `<span style="font-weight: bolder">${moment(dueDate.month().toString(), 'M').format('MMMM')} month due ${
				// monthNumber === dueDate.month() + 1 ?
				`${calculateDays(today, dueDate)}</span><br /><span>${dueDate.format('Do MMMM YYYY')}`
				// : `${calculateDays(
				// 		today,
				// 		checkDateAndReturnWeekdayEquivalent(moment(`${today.year()}-${today.month() + 2}-21`))
				//   )}</span><br /><span>${checkDateAndReturnWeekdayEquivalent(moment(`${today.year()}-${today.month() + 2}-21`)).format('Do MMMM YYYY')}`
			}</span><br />`
		} else if (taygSettings.gstLodgmentDatePeriod === 'Quarterly' || quarterly) {
			// Quarterly
			const [endOfFirstQuarter, endOfSecondQuarter, endOfThirdQuarter, endOfFourthQuarter] = getEndOfQuarters(today)
			const quarterAdjustment = calculateQuarter(today, endOfFirstQuarter, endOfSecondQuarter, endOfThirdQuarter, endOfFourthQuarter, 'gst')

			switch (quarterAdjustment) {
				case 1:
					return `<span style="font-weight: bolder">March quarter due ${calculateDays(
						today,
						endOfFirstQuarter
					)}</span><br /><span>${endOfFirstQuarter.format('Do MMMM YYYY')}</span><br />`
				case 2:
					return `<span style="font-weight: bolder">June quarter due ${calculateDays(
						today,
						endOfSecondQuarter
					)}</span><br /><span>${endOfSecondQuarter.format('Do MMMM YYYY')}</span><br />`
				case 3:
					return `<span style="font-weight: bolder">September quarter due ${calculateDays(
						today,
						endOfThirdQuarter
					)}</span><br /><span>${endOfThirdQuarter.format('Do MMMM YYYY')}</span><br />`
				case 4:
					return `<span style="font-weight: bolder">December quarter due ${calculateDays(
						today,
						endOfFourthQuarter
					)}</span><br /><span>${endOfFourthQuarter.format('Do MMMM YYYY')}</span><br />`
			}
		} else if (taygSettings.gstLodgmentDatePeriod === 'Annual' || annual) {
			//Annually
			if (today.month() <= 5) {
				return `<span style="font-weight: bolder">${today.year()} year due ${calculateDays(
					today,
					moment(`${today.year()}-10-31`)
				)}</span><br /><span>${moment(`${today.year()}-10-31`).format('Do MMMM YYYY')}</span><br />`
			} else {
				return `<span style="font-weight: bolder">${today.year() + 1} year due ${calculateDays(
					today,
					moment(`${today.year() + 1}-10-31`)
				)}</span><br /><span>${moment(`${today.year() + 1}-10-31`).format('Do MMMM YYYY')}</span><br />`
			}
		} else if (!emptyReturn) {
			// has not been specified
			return '<span>Select a lodgement period in settings</span>'
		}

		return ''
	}

	const calculatePaygLodgmentDate = (
		taygSettings: TaygSettings,
		emptyReturn = false,
		monthly = false,
		quarterly = false,
		gstQuarterly = false,
		paygType = 'paygW'
	) => {
		// Monthly
		// 	It’s due on the 21st of the following month + any days to push it to the next Monday if it’s a weekend

		// Quarterly
		// 	GST is due 28 days after the 28th of the month following end of quarter (except Dec qtr which is 28-Feb) + any days to push it to the next Monday if it’s a weekend

		// There is a special exception: if you are quarterly GST and monthly PAYG W the PAYG W monthly payment for the end of the quarter
		// (i.e. March in the Jan-Mar quarter) is due with the quarterly GST payment (i.e. later than usual).

		const today = moment()
		if ((monthly || taygSettings.paygLodgmentDatePeriod === 'Monthly') && paygType === 'paygW') {
			// Monthly
			// check special case
			let extraText = ''
			if (gstQuarterly || taygSettings.gstLodgmentDatePeriod === 'Quarterly') {
				extraText = calculateGstLodgmentDate(taygSettings, true, false, true)
			}
			// const dueDate = checkDateAndReturnWeekdayEquivalent(moment(`${today.year()}-${today.month()}-21`))
			// const monthNumber = calculateMonth(today, dueDate)
			const dueDate = monthlyDueDate(today)

			return `<span style="font-weight: bolder">${moment(dueDate.month().toString(), 'M').format('MMMM')} month due ${
				// monthNumber === dueDate.month() + 1 ?
				`${calculateDays(today, dueDate)}</span><br /><span>${dueDate.format('Do MMMM YYYY')}</span><br />`
				// : `${calculateDays(
				// 		today,
				// 		checkDateAndReturnWeekdayEquivalent(moment(`${today.year()}-${today.month() + 2}-21`))
				//   )}</span><br /><span>${checkDateAndReturnWeekdayEquivalent(moment(`${today.year()}-${today.month() + 2}-21`)).format('Do MMMM YYYY')}`
			}${extraText !== '' ? `<br />${extraText}` : ''}`
		} else if (quarterly || taygSettings.paygLodgmentDatePeriod === 'Quarterly' || paygType === 'paygCIT') {
			// Quarterly
			const [endOfFirstQuarter, endOfSecondQuarter, endOfThirdQuarter, endOfFourthQuarter] = getEndOfQuarters(today)
			const quarterAdjustment = calculateQuarter(today, endOfFirstQuarter, endOfSecondQuarter, endOfThirdQuarter, endOfFourthQuarter, 'payg')

			switch (quarterAdjustment) {
				case 1:
					return `<span style="font-weight: bolder">March quarter due ${calculateDays(
						today,
						endOfFirstQuarter
					)}</span><br /><span>${endOfFirstQuarter.format('Do MMMM YYYY')}</span>`
				case 2:
					return `<span style="font-weight: bolder">June quarter due ${calculateDays(
						today,
						endOfSecondQuarter
					)}</span><br /><span>${endOfSecondQuarter.format('Do MMMM YYYY')}</span>`
				case 3:
					return `<span style="font-weight: bolder">September quarter due ${calculateDays(
						today,
						endOfThirdQuarter
					)}</span><br /><span>${endOfThirdQuarter.format('Do MMMM YYYY')}</span>`
				case 4:
					return `<span style="font-weight: bolder">December quarter due ${calculateDays(
						today,
						endOfFourthQuarter
					)}</span><br /><span>${endOfFourthQuarter.format('Do MMMM YYYY')}</span>`
			}
		} else if (!emptyReturn) {
			// has not been specified
			return '<span>Select a lodgement period in settings</span>'
		}

		return ''
	}

	const calculateSuperLodgmentDate = () => {
		// For Superannuation the dates are quarterly only:
		// 28 January
		// 28 April
		// 28 July
		// 28 October
		// All of them are + any days to push it to the next Monday if it’s a weekend.

		const today = moment()
		const firstQuarter = checkDateAndReturnWeekdayEquivalent(
			moment(
				`${today.isSameOrBefore(checkDateAndReturnWeekdayEquivalent(moment(`${today.year()}-04-28`)), 'date') ? today.year() : today.year() + 1}-04-28`
			)
		)
		const secondQuarter = checkDateAndReturnWeekdayEquivalent(
			moment(
				`${today.isSameOrBefore(checkDateAndReturnWeekdayEquivalent(moment(`${today.year()}-07-28`)), 'date') ? today.year() : today.year() + 1}-07-28`
			)
		)
		const thirdQuarter = checkDateAndReturnWeekdayEquivalent(
			moment(
				`${today.isSameOrBefore(checkDateAndReturnWeekdayEquivalent(moment(`${today.year()}-10-28`)), 'date') ? today.year() : today.year() + 1}-10-28`
			)
		)
		const fourthQuarter = checkDateAndReturnWeekdayEquivalent(
			moment(
				`${today.isSameOrBefore(checkDateAndReturnWeekdayEquivalent(moment(`${today.year()}-01-28`)), 'date') ? today.year() : today.year() + 1}-01-28`
			)
		)
		const quarterAdjustment = calculateQuarter(today, firstQuarter, secondQuarter, thirdQuarter, fourthQuarter, 'super')

		switch (quarterAdjustment) {
			case 1:
				return `<span style="font-weight: bolder">March quarter due ${calculateDays(today, firstQuarter)}</span><br /><span>${firstQuarter.format(
					'Do MMMM YYYY'
				)}`
			case 2:
				return `<span style="font-weight: bolder">June quarter due ${calculateDays(today, secondQuarter)}</span><br /><span>${secondQuarter.format(
					'Do MMMM YYYY'
				)}`
			case 3:
				return `<span style="font-weight: bolder">September quarter due ${calculateDays(today, thirdQuarter)}</span><br /><span>${thirdQuarter.format(
					'Do MMMM YYYY'
				)}`
			case 4:
				return `<span style="font-weight: bolder">December quarter due ${calculateDays(today, fourthQuarter)}</span><br /><span>${fourthQuarter.format(
					'Do MMMM YYYY'
				)}`
		}
	}

	if (props.show && props.currentTaygSettings) {
		if (
			props.pageStatus === 'Finished' &&
			(Array.isArray(props.currentTaygSettings) || props.currentOrg.accountXeroOrgID === props.currentTaygSettings.accountXeroOrgID)
		) {
			const extraAssetAccounts = (
				Array.isArray(props.currentTaygSettings)
					? props.currentTaygSettings.flatMap((ts) => ts.extraAccounts || [])
					: props.currentTaygSettings.extraAccounts || []
			).filter((ea) => ea.type === 'asset')
			const extraLiabilityAccounts = (
				Array.isArray(props.currentTaygSettings)
					? props.currentTaygSettings.flatMap((ts) => ts.extraAccounts || [])
					: props.currentTaygSettings.extraAccounts || []
			).filter((ea) => ea.type === 'liability')
			const getExtraAccountsSavingsAmount = (
				extraAccounts: Pick<TaygSettingsExtraAccount, 'taygSettingsExtraAccountID' | 'label' | 'type' | 'xeroAccountID'>[]
			) =>
				Math.round(
					extraAccounts.reduce((total, ea) => {
						const foundBalance = xeroAccountBalances?.find((xab) => xab.xeroAccountID === ea?.xeroAccountID)
						return (total += Number(foundBalance?.value) || 0)
					}, 0)
				)

			const values = getValues()

			const taxSavingsAmount = Number(values[SAVINGS]) || 0
			const assetAccountsAmount = getExtraAccountsSavingsAmount(extraAssetAccounts) || 0
			const liabilityAccountsAmount = getExtraAccountsSavingsAmount(extraLiabilityAccounts) || 0

			return (
				<div className="tayg-main-div">
					<Col className="tayg-overview">
						<Row className="title-with-icon">
							<img src={TAYGIcon} className="tayg-overview-icon" style={{ marginLeft: '30px' }} alt="TAYG Icon" />
							<FormLabel as={Col}>Current Liability</FormLabel>
						</Row>
						<Row className="tayg-main-div">
							<Col style={{ flex: '2', marginTop: '50px' }}>
								<FormLabel as={Col} className="normal-bold-label">
									TOTAL
								</FormLabel>
								<FormLabel as={Col} className="normal-bold-label" style={{ fontSize: 'xxx-large' }}>
									${calculateLiability(values[GST], values[PAYG], values[SUPER])}
								</FormLabel>
							</Col>
							{showSavings ? (
								<Col className="tayg-savings">
									<FormLabel as={Col} className="normal-bold-label" style={{ display: 'flex', justifyContent: 'space-between' }}>
										Assets
										<OverlayTrigger
											placement="auto"
											overlay={
												<Tooltip id={`tooltip-savings`}>
													This is the amount you have in your nominated tax savings account, along with other nominated asset
													accounts. The goal is that the net should read $0. That way you know you have enough money at hand when time
													comes to pay your current liabilities.
												</Tooltip>
											}
										>
											<div style={{ padding: '0px 15px 10px 0px' }}>
												<HelpCircle className={'tayg-help-blue'} />
											</div>
										</OverlayTrigger>
									</FormLabel>
									<Row className="tayg-small-grey-card" style={{ backgroundColor: '#FFFFFF', marginTop: '0px', padding: '15px' }}>
										<FormLabel as={Col} className="normal-bold-label" style={{ display: 'flex', alignItems: 'center' }}>
											Tax Savings
										</FormLabel>
										<FormLabel as={Col} className="normal-bold-green-value" style={{ fontSize: 'larger' }}>{`${
											Array.isArray(props.currentTaygSettings)
												? `$${Math.round(values[SAVINGS]).toLocaleString(undefined, { maximumFractionDigits: 2 })}`
												: (props.currentTaygSettings as TaygSettings).savingsXeroAccountID
												? `$${Math.round(values[SAVINGS]).toLocaleString(undefined, { maximumFractionDigits: 2 })}`
												: 'Pick an account to use on the settings page so we can calculate your savings.'
										}`}</FormLabel>
									</Row>
									{extraAssetAccounts.length > 0 && (
										<Row className="tayg-small-grey-card" style={{ backgroundColor: '#FFFFFF', marginTop: '0px', padding: '15px' }}>
											<Col>
												<Row>
													<FormLabel as={Col} className="normal-bold-label" style={{ display: 'flex', alignItems: 'center' }}>
														Other Assets
													</FormLabel>
												</Row>
												<hr />
												{extraAssetAccounts.map((ea) => {
													const foundBalance = xeroAccountBalances?.find((xab) => xab.xeroAccountID === ea?.xeroAccountID)
													const value = foundBalance
														? '$' + Math.round(foundBalance.value).toLocaleString(undefined, { maximumFractionDigits: 2 })
														: '$0'
													return (
														<Row key={ea?.taygSettingsExtraAccountID}>
															<FormLabel as={Col} style={{ fontSize: 'larger' }}>
																{ea?.label}
															</FormLabel>
															<FormLabel as={Col} className="normal-bold-green-value" style={{ fontSize: 'larger' }}>
																{value}
															</FormLabel>
														</Row>
													)
												})}
											</Col>
										</Row>
									)}
									<Row className="tayg-small-grey-card" style={{ backgroundColor: '#FFFFFF', marginTop: '5px', padding: '15px' }}>
										<FormLabel as={Col} className="normal-bold-label" style={{ display: 'flex', alignItems: 'center' }}>
											Surplus / Shortfall
										</FormLabel>
										<FormLabel as={Col} className="normal-bold-green-value" style={{ fontSize: 'larger' }}>
											{Array.isArray(props.currentTaygSettings) || props.currentTaygSettings.savingsXeroAccountID
												? calculateNetValue(
														calculateLiability(values[GST], values[PAYG], values[SUPER], false) as number,
														Number(taxSavingsAmount) + Number(assetAccountsAmount)
												  )
												: 'N/A'}
										</FormLabel>
									</Row>
								</Col>
							) : null}
						</Row>
						{props.isGstSetup ? (
							<div className="tayg-body-box" style={{ backgroundColor: '#8AA6FF' }}>
								<Row>
									<img src={GstIcon} className="tayg-overview-icon" alt="TAYG Icon for Gst" />
									<FormLabel as={Col} className="normal-bold-label" style={{ display: 'flex', alignItems: 'center', color: '#FFFFFF' }}>
										GST
									</FormLabel>
									<OverlayTrigger
										placement="auto"
										overlay={
											<Tooltip id={`tooltip-gst`}>
												GST is a broad-based consumption tax imposed on most goods and services in Australia. As a business owner, you
												are required to collect GST on your taxable sales and then pay it to the ATO. The GST rate is currently set at
												10% of your sales. By paying GST, you contribute to the funding of public services and infrastructure in
												Australia.
											</Tooltip>
										}
									>
										<div style={{ paddingRight: '15px' }}>
											<HelpCircle className={'tayg-help-blue'} />
										</div>
									</OverlayTrigger>
								</Row>
								<Row>
									<Col style={{ flex: '2', margin: '25px 0px 0px -10px' }}>
										<FormLabel as={Col} className="normal-bold-label">
											TOTAL
										</FormLabel>
										<FormLabel as={Col} className="normal-bold-label" style={{ fontSize: 'xx-large', color: '#FFFFFF' }}>{`${
											Array.isArray(props.currentTaygSettings) && values[GST]
												? `$${Math.round(values[GST]).toLocaleString(undefined, { maximumFractionDigits: 2 })}`
												: (props.currentTaygSettings as TaygSettings).gstXeroAccountID
												? `$${Math.round(values[GST]).toLocaleString(undefined, { maximumFractionDigits: 2 })}`
												: 'Pick an account to use on the settings page so we can calculate your liabilities.'
										}`}</FormLabel>
									</Col>
									<Col className="tayg-dates">
										<div
											dangerouslySetInnerHTML={{
												__html: `${
													Array.isArray(props.currentTaygSettings)
														? handleCombinedViewLodgmentDates('gst')
														: calculateGstLodgmentDate(props.currentTaygSettings as TaygSettings)
												}`,
											}}
											className="tayg-lodgement-date-text"
										/>
									</Col>
								</Row>
							</div>
						) : null}
						<Row className="tayg-pink-cards" style={{ margin: '-15px' }}>
							{showPaygSettings ? (
								<Col style={{ marginRight: '-15px' }}>
									<div className="tayg-body-box" style={{ backgroundColor: '#E4B1F7' }}>
										<Row>
											<img src={PaygIcon} className="tayg-overview-icon" alt="TAYG Icon for Payg" />
											<FormLabel as={Col} className="normal-bold-label" style={{ display: 'flex', alignItems: 'center' }}>
												PAYG Tax Withheld
											</FormLabel>
											<OverlayTrigger
												placement="auto"
												overlay={
													<Tooltip id={`tooltip-paygw`}>
														Pay As You Go (PAYG) tax withholding refers to the tax deducted from payments you make to your employees
														and contractors. As a business owner, you are responsible for deducting the correct amount of tax from
														these payments and then paying them to the ATO. The withheld tax helps individuals meet their income tax
														liabilities throughout the year.
													</Tooltip>
												}
											>
												<div style={{ paddingRight: '15px' }}>
													<HelpCircle className={'tayg-help-blue'} />
												</div>
											</OverlayTrigger>
										</Row>
										<Row>
											<Col style={{ flex: '2', margin: '25px 0px 0px -10px' }}>
												<FormLabel as={Col} className="normal-bold-label">
													TOTAL
												</FormLabel>
												<FormLabel as={Col} className="normal-bold-label" style={{ fontSize: 'xx-large', color: '#FFFFFF' }}>{`${
													Array.isArray(props.currentTaygSettings) && values[PAYG]
														? `$${Math.round(values[PAYG]).toLocaleString(undefined, { maximumFractionDigits: 2 })}`
														: (props.currentTaygSettings as TaygSettings).paygPayXeroAccountID
														? `$${Math.round(values[PAYG]).toLocaleString(undefined, { maximumFractionDigits: 2 })}`
														: 'Pick an account to use on the settings page so we can calculate your liabilities.'
												}`}</FormLabel>
											</Col>
											<Col className="tayg-dates">
												<div
													dangerouslySetInnerHTML={{
														__html: `${
															Array.isArray(props.currentTaygSettings)
																? handleCombinedViewLodgmentDates('payg')
																: calculatePaygLodgmentDate(props.currentTaygSettings as TaygSettings)
														}`,
													}}
													className="tayg-lodgement-date-text"
												/>
											</Col>
										</Row>
									</div>
								</Col>
							) : null}
							{showSuperSettings ? (
								<Col>
									<div className="tayg-body-box" style={{ backgroundColor: '#E4B1F7' }}>
										<Row>
											<img src={SuperIcon} className="tayg-overview-icon" alt="TAYG Icon for Super" />
											<FormLabel as={Col} className="normal-bold-label" style={{ display: 'flex', alignItems: 'center' }}>
												Superannuation Payable
											</FormLabel>
											<OverlayTrigger
												placement="auto"
												overlay={
													<Tooltip id={`tooltip-super`}>
														This is the mandatory contributions that you must make on behalf of your employees towards their
														retirement savings. These contributions need to be deposited into a superannuation fund or account
														nominated by the employee.
													</Tooltip>
												}
											>
												<div style={{ paddingRight: '15px' }}>
													<HelpCircle className={'tayg-help-blue'} />
												</div>
											</OverlayTrigger>
										</Row>
										<Row>
											<Col style={{ flex: '2', margin: '25px 0px 0px -10px' }}>
												<FormLabel as={Col} className="normal-bold-label">
													TOTAL
												</FormLabel>
												<FormLabel as={Col} className="normal-bold-label" style={{ fontSize: 'xx-large', color: '#FFFFFF' }}>{`${
													Array.isArray(props.currentTaygSettings) && values[SUPER]
														? `$${Math.round(values[SUPER]).toLocaleString(undefined, { maximumFractionDigits: 2 })}`
														: (props.currentTaygSettings as TaygSettings).superPayXeroAccountID
														? `$${Math.round(values[SUPER]).toLocaleString(undefined, { maximumFractionDigits: 2 })}`
														: 'Pick an account to use on the settings page so we can calculate your liabilities.'
												}`}</FormLabel>
											</Col>
											<Col className="tayg-dates">
												<div
													dangerouslySetInnerHTML={{ __html: `${calculateSuperLodgmentDate()}` }}
													className="tayg-lodgement-date-text"
												/>
											</Col>
										</Row>
									</div>
								</Col>
							) : null}
						</Row>
						{showPaygSettings && Math.round(values[COMPANYINCOMETAX]) !== 0 ? (
							<div className="tayg-body-box" style={{ backgroundColor: '#E4B1F7' }}>
								<Row>
									{/* change icon */}
									<img src={PaygIncomeTax} className="tayg-overview-icon" alt="TAYG Icon for PAYG Company Income Tax" />
									<FormLabel as={Col} className="normal-bold-label" style={{ display: 'flex', alignItems: 'center' }}>
										PAYG Company Income Tax
									</FormLabel>
									<OverlayTrigger
										placement="auto"
										overlay={
											<Tooltip id={`tooltip-paygcit`}>
												PAYG Company Income Tax - is tax on the profits earned by a company. Based on an estimate of annual profits, a
												regular instalment is paid throughout the year. These payments are known as PAYG Company Income Tax instalments.
												PAYG Company Income Tax is to help companies manage their tax obligations by spreading payments over the income
												year. At the end of the financial year, companies reconcile their estimated PAYG instalments with their actual
												income tax liability based on their final profits. Any overpayments are refunded, or any underpayments may incur
												additional charges or penalties.
											</Tooltip>
										}
									>
										<div style={{ paddingRight: '15px' }}>
											<HelpCircle className={'tayg-help-blue'} />
										</div>
									</OverlayTrigger>
								</Row>
								<Row>
									<Col style={{ flex: '2', margin: '25px 0px 0px -10px' }}>
										<FormLabel as={Col} className="normal-bold-label">
											TOTAL
										</FormLabel>
										<FormLabel as={Col} className="normal-bold-label" style={{ fontSize: 'xx-large', color: '#FFFFFF' }}>{`${
											Array.isArray(props.currentTaygSettings) && values[COMPANYINCOMETAX]
												? `$${Math.round(values[COMPANYINCOMETAX]).toLocaleString(undefined, { maximumFractionDigits: 2 })}`
												: (props.currentTaygSettings as TaygSettings).paygCompanyIncomeTaxAmount
												? `$${Math.round(values[COMPANYINCOMETAX]).toLocaleString(undefined, { maximumFractionDigits: 2 })}`
												: 'Enter the amount on the settings page.'
										}`}</FormLabel>
									</Col>
									<Col className="tayg-dates">
										<div
											dangerouslySetInnerHTML={{
												__html: `${
													Array.isArray(props.currentTaygSettings)
														? handleCombinedViewLodgmentDates('paygCIT')
														: calculatePaygLodgmentDate(
																props.currentTaygSettings as TaygSettings,
																false,
																false,
																true,
																false,
																'paygCIT'
														  )
												}`,
											}}
											className="tayg-lodgement-date-text"
										/>
									</Col>
								</Row>
							</div>
						) : null}
						{extraLiabilityAccounts.length > 0 && (
							<div className="tayg-body-box" style={{ backgroundColor: '#E4B1F7' }}>
								<Row>
									{/* change icon */}
									<img src={NonCurrentLiabilityIcon} className="tayg-overview-icon" alt="TAYG Icon for Other liabilities" />
									<FormLabel as={Col} className="normal-bold-label" style={{ display: 'flex', alignItems: 'center' }}>
										Other Liabilities
									</FormLabel>
									<OverlayTrigger
										placement="auto"
										overlay={
											<Tooltip id={`tooltip-ncl`}>
												This is the total of nominated other liabilities that you have. These are not included in your Current Liability
												total.
											</Tooltip>
										}
									>
										<div style={{ paddingRight: '15px' }}>
											<HelpCircle className={'tayg-help-blue'} />
										</div>
									</OverlayTrigger>
								</Row>
								<Row>
									<Col style={{ margin: '25px 0px 0px -10px' }}>
										<FormLabel as={Col} className="normal-bold-label">
											TOTAL
										</FormLabel>
										<FormLabel as={Col} className="normal-bold-label" style={{ fontSize: 'xx-large', color: '#FFFFFF' }}>
											${liabilityAccountsAmount}
										</FormLabel>
									</Col>
									<Col className="tayg-dates">
										{extraLiabilityAccounts.map((ea) => {
											const foundBalance = xeroAccountBalances?.find((xab) => xab.xeroAccountID === ea?.xeroAccountID)
											const value = foundBalance ? '$' + Math.round(foundBalance.value) : '$0'
											return (
												<Row key={ea?.taygSettingsExtraAccountID} className="tayg-lodgement-date-text" style={{ fontWeight: 'bolder' }}>
													<FormLabel as={Col}>{ea?.label}</FormLabel>
													<FormLabel as={Col} sm={1}>
														{value}
													</FormLabel>
												</Row>
											)
										})}
									</Col>
								</Row>
							</div>
						)}
					</Col>
					{props.isGstSetup && unreconciledTransactions && unreconciledTransactions.length > 0 ? (
						<Col>
							<div className="tayg-special-help">
								<OverlayTrigger
									placement="auto"
									overlay={
										<Tooltip id={`tooltip-unreconciled`}>
											This is the number and value of transactions in and out of your Xero file which are not reconciled. By getting this
											sorted you will be able to get a clearer view of your actual outstanding obligations.
										</Tooltip>
									}
								>
									<div style={{ padding: '15px 15px 0px 0px' }}>
										<HelpCircle className={'tayg-help-blue'} />
									</div>
								</OverlayTrigger>
							</div>
							<Row className="tayg-small-grey-card">
								<FormLabel as={Col} className="normal-bold-label" style={{ display: 'flex', alignItems: 'center' }}>
									Unreconciled Transactions
								</FormLabel>
								<FormLabel as={Col} className="normal-bold-green-value">
									{unreconciledTransactions.length}
								</FormLabel>
							</Row>
							<Row className="tayg-small-grey-card">
								<FormLabel as={Col} className="normal-bold-label" style={{ display: 'flex', alignItems: 'center' }}>
									Unreconciled Value
								</FormLabel>
								<FormLabel as={Col} className="normal-bold-green-value">
									${Math.round(unreconciledValues).toLocaleString(undefined, { maximumFractionDigits: 2 })}
								</FormLabel>
							</Row>
							<Row className="tayg-small-grey-card">
								<FormLabel as={Col} className="normal-bold-label" style={{ display: 'flex', alignItems: 'center' }}>
									Estimated GST
								</FormLabel>
								<FormLabel as={Col} className="normal-bold-green-value">
									${Math.round(unreconciledValues * 0.1).toLocaleString(undefined, { maximumFractionDigits: 2 })}
								</FormLabel>
							</Row>
						</Col>
					) : null}
					{isNew ? <HandleNewAuthPopUp isNew={isNew} updateIsNew={updateIsNew} /> : null}
				</div>
			)
		} else {
			return (
				<div className="loading-gif">
					<img src={TAYGLoadingImage} alt="Loading ..." />
				</div>
			)
		}
	} else {
		return <></>
	}
}

interface HandleNewAuthPopUpProps {
	isNew: boolean
	updateIsNew: (e: boolean) => void
}

const HandleNewAuthPopUp = (props: HandleNewAuthPopUpProps) => {
	return (
		<Modal show={props.isNew} aria-labelledby="modal" centered>
			<Modal.Header>
				<Modal.Title>Doesn&apos;t Look Right?</Modal.Title>
			</Modal.Header>

			<Modal.Body>
				<p>We guessed which accounts to use, you can change them in the settings tab.</p>
			</Modal.Body>

			<Modal.Footer>
				<Button
					variant="primary"
					onClick={() => {
						props.updateIsNew(false)
					}}
				>
					Got it, thanks.
				</Button>
			</Modal.Footer>
		</Modal>
	)
}

export default OverviewComponent
