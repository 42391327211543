import React from 'react'
import { Button, Col, Form, FormLabel, Row, FormGroup } from 'react-bootstrap'
import { Google } from '../../../../back-end/utilities/apiDefinitions'
import GoogleButton from 'react-google-button'

// definitions
import * as I from '../../utilities/me'

// utillites
import * as Request from '../../utilities/request'
import { AppContext } from '../../App'

const ScreensGoogleIntegration = () => {
	const { appState } = React.useContext(AppContext)

	const [googleUrl, updateGoogleUrl] = React.useState<string>('')
	const [google, updateGoogle] = React.useState<Google | null>(null)

	React.useEffect(() => {
		const fetchData = async () => {
			const [url, google] = await Promise.all([Request.get('google/url', appState.authState), Request.get('google', appState.authState)])
			updateGoogleUrl(url.data.url)
			updateGoogle(google.data.google)
		}

		if (I.have('admin', appState.permission[appState.context])) {
			fetchData()
		}
	}, [appState.authState, appState.permission[appState.context]]) // eslint-disable-line

	const disconnectGoogle = async () => {
		const google = await Request.del('google', appState.authState)
		updateGoogle(google.data.google)
	}

	if (google) {
		return (
			<>
				<Form.Group style={{ padding: '30px' }}>
					<Row className="calendar-alerts-card ">
						<Col>
							<FormLabel style={{ marginTop: '-5px' }}>You&apos;re already integrated with Google!</FormLabel>
						</Col>
						<Col>
							<Button variant="danger" onClick={disconnectGoogle}>
								Disconnect
							</Button>
						</Col>
					</Row>
				</Form.Group>
			</>
		)
	} else {
		return (
			<>
				<FormGroup style={{ padding: '30px' }}>
					<Row className="calendar-alerts-card ">
						<Col>
							<p>You are not connected to google</p>
						</Col>
						<Col>
							<GoogleButton onClick={() => (window.location.href = googleUrl)} />
						</Col>
					</Row>
				</FormGroup>
			</>
		)
	}
}

export default ScreensGoogleIntegration
