import React from 'react'
import { Modal, Button } from 'react-bootstrap'

interface DialogProps {
	show: boolean
	handleClose: () => void
	handleConfirm: () => void
	titleText?: string
	bodyText?: string
	children?: React.ReactNode
	cancelText?: string
	confirmText?: string
	style?: React.CSSProperties
	dialogClassName?: string
	showOnlyConfirm?: boolean
	confirmFullWidth?: boolean
}

const Dialog = (props: DialogProps) => {
	return (
		<Modal show={props.show} onHide={props.handleClose} style={props.style} dialogClassName={props.dialogClassName}>
			{props.titleText && (
				<Modal.Header className="align-items-center" closeButton>
					<Modal.Title>{props.titleText}</Modal.Title>
				</Modal.Header>
			)}
			{props.bodyText ? <Modal.Body style={{ whiteSpace: 'pre-wrap' }}>{props.bodyText}</Modal.Body> : props.children || null}
			<Modal.Footer>
				{!props.showOnlyConfirm && (
					<Button variant={'secondary'} onClick={props.handleClose}>
						{props.cancelText || 'Cancel'}
					</Button>
				)}
				<Button
					variant={'success'}
					className={props.confirmFullWidth ? 'w-100' : undefined}
					onClick={() => {
						props.handleClose()
						props.handleConfirm()
					}}
				>
					{props.confirmText || 'Confirm'}
				</Button>
			</Modal.Footer>
		</Modal>
	)
}

export { Dialog }
