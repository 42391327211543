import React, { useContext, useEffect } from 'react'
import { XeroEmbeddedAppContext } from '@xeroapi/embedded-apps-sdk'

function Help() {
	const { xeroEmbeddedAppClient } = useContext(XeroEmbeddedAppContext)

	useEffect(() => {
		xeroEmbeddedAppClient!.display()
	}, [])

	return (
		<div>
			Visit{' '}
			<a href="https://help.finlert.com/subsync/" target="_blank" rel="noreferrer">
				https://help.finlert.com/subsync/
			</a>{' '}
			for help docs on SubSync.
		</div>
	)
}

export { Help }
