import React from 'react'
import { Button, Card, Col, Container, Form, Row } from 'react-bootstrap'
import { ArrowLeftCircle } from 'react-feather'
import { Link, useMatch, useNavigate } from 'react-router-dom'

import * as Messages from '../../components/ui/messages/messages'
import { Dialog } from '../../components/ui/dialog/dialog'

import * as Request from '../../utilities/request'
import * as I from '../../utilities/me'
import { AppContext } from '../../App'

interface UserFields {
	firstName: string
	lastName: string
	email: string
	osPermission: string
	lcPermission: string
	taygPermission: string
	subsyncPermission: string
}

const initialUserData = {
	firstName: '',
	lastName: '',
	email: '',
	osPermission: '',
	lcPermission: '',
	taygPermission: '',
	subsyncPermission: '',
}

const inviteProducts = (user: UserFields) => {
	return [
		user.osPermission ? 'oversight' : '',
		user.lcPermission ? 'leavecal' : '',
		user.taygPermission ? 'tayg' : '',
		user.subsyncPermission ? 'subsync' : '',
	].reduce((acc: string[], permission: string) => (permission !== '' ? acc.concat(permission) : acc), [])
}

const User = () => {
	const { appState, updateAppState } = React.useContext(AppContext)
	const match = useMatch('/user/:userID')
	const navigate = useNavigate()

	const urlUserID = match?.params.userID || ''

	const [originalData, updateOriginalData] = React.useState<UserFields>(initialUserData)
	const [user, updateUser] = React.useState<UserFields>(initialUserData)
	const [permissions, updatePermissions] = React.useState<{ id: string; name: string }[] | null>(null)

	const [isEmailDisabled, updateEmailDisabled] = React.useState<boolean>(true)
	const [isFirstNameDisabled, updateFirstNameDisabled] = React.useState<boolean>(true)
	const [isLastNameDisabled, updateLastNameDisabled] = React.useState<boolean>(true)
	const [isPermissionDisabled, updatePermissionDisabled] = React.useState<boolean>(true)
	const [isSubmitting, updateIsSubmitting] = React.useState<boolean>(false)

	const [isLoading, updateIsLoading] = React.useState<boolean>(false)
	const [userIsMe, updateUserIsMe] = React.useState<boolean>(false)
	const [canEdit, updateCanEdit] = React.useState<boolean>(false)

	const [messages, updateMessages] = Messages.useMessageReducer([])
	const [showDialog, updateShowDialog] = React.useState<boolean>(false)

	React.useEffect(() => {
		const getUser = async () => {
			updateIsLoading(true)
			if (urlUserID !== 'new') {
				const req = await Request.get(`user/${urlUserID}`, appState.authState)

				const currentUser = appState.authState.attributes
				if (currentUser !== null && currentUser['custom:userId'] && currentUser['custom:userId'] === urlUserID) {
					updateUserIsMe(true)
					updateCanEdit(true)
				} else if (I.have('admin', appState.permission[appState.context])) {
					updateCanEdit(true)
				}

				const users = req.data.users
				if (users.length > 0) {
					const user = {
						email: users[0].email,
						firstName: users[0].firstName,
						lastName: users[0].lastName,
						osPermission: users[0].osPermission.permissionID,
						lcPermission: users[0].lcPermission.permissionID,
						taygPermission: users[0].taygPermission.permissionID,
						subsyncPermission: users[0].subsyncPermission.permissionID,
					}
					updateUser(user)
					updateOriginalData(user)
				}
			}
			updateIsLoading(false)
		}

		const getPermissions = async () => {
			const req = await Request.get(`permission?where=PermissionID=in(${I.Admin},${I.GeneralUser})`, appState.authState)
			updatePermissions(req.data)
		}

		if (appState.authState.isLoggedIn) {
			getUser()
			getPermissions()
		}
	}, [appState.authState, appState.permission, appState.context, userIsMe, urlUserID])

	const getTitle = (urlMatch: string, name: string) => (urlMatch === 'new' ? 'New User' : name)

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const name = e.target.name
		const value = e.target.value
		updateUser({ ...user, [name]: value })
	}

	const postUser = async () => {
		if (canEdit) {
			try {
				const permissionsHaveChanged =
					user.osPermission !== originalData.osPermission ||
					user.lcPermission !== originalData.lcPermission ||
					user.taygPermission !== originalData.taygPermission
				const req = await Request.put(
					`user/${urlUserID}`,
					{
						firstName: user.firstName,
						lastName: user.lastName,
						email: user.email,
						osPermissionID: user.osPermission || null,
						lcPermissionID: user.lcPermission || null,
						taygPermissionID: user.taygPermission || null,
						subsyncPermissionID: user.subsyncPermission || null,
					},
					appState.authState
				)
				const users = req.data.users

				updateOriginalData({
					firstName: users[0].firstName,
					lastName: users[0].lastName,
					email: users[0].email,
					osPermission: users[0].osPermission.permissionID,
					lcPermission: users[0].lcPermission.permissionID,
					taygPermission: users[0].taygPermission.permissionID,
					subsyncPermission: users[0].subsyncPermission.permissionID,
				})
				if (permissionsHaveChanged && userIsMe) {
					updateAppState({
						state: 'permission',
						data: {
							oversight: users[0].osPermission.permissionID,
							leavecal: users[0].lcPermission.permissionID,
							tayg: users[0].taygPermission.permissionID,
							subsync: users[0].subsyncPermission.permissionID,
						},
						accepted: appState.authState.invitationAccepted!,
					})
				}
				updateMessages(Messages.addMessage('success', 'Succesfully updated user'))
			} catch (err) {
				updateMessages(Messages.addMessage('danger', 'Failed to update user'))
			}
		} else {
			updateMessages(Messages.addMessage('danger', 'You do not have permission to invite a user'))
		}
	}
	const resetUser = async () => {
		updateUser(originalData)
	}

	const validateForm = () =>
		user.firstName.length > 0 &&
		user.lastName.length > 0 &&
		user.email.length > 0 &&
		(user.osPermission.length > 0 || user.lcPermission.length > 0 || user.taygPermission.length > 0 || user.subsyncPermission.length > 0)

	const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault()
		updateIsSubmitting(true)
		updateFirstNameDisabled(true)
		updateLastNameDisabled(true)
		updateEmailDisabled(true)
		updatePermissionDisabled(true)

		try {
			const products = inviteProducts(user)
			const reply = await Request.post(
				`user/${products.length > 1 ? products.join(',') : products[0]}`,
				{
					firstName: user.firstName,
					lastName: user.lastName,
					email: user.email,
					osPermissionID: user.osPermission || null,
					lcPermissionID: user.lcPermission || null,
					taygPermissionID: user.taygPermission || null,
					subsyncPermissionID: user.subsyncPermission || null,
				},
				appState.authState
			)
			if (!reply.data.success) {
				updateMessages(Messages.addMessage('danger', reply.data.message ? reply.data.message : 'Unable to Invite User'))
				updateIsSubmitting(false)
			} else {
				navigate(`/users`)
			}
		} catch (e) {
			console.log(e)
			updateMessages(Messages.addMessage('danger', 'Unable to Invite User'))
			updateIsSubmitting(false)
		}
	}

	const handleDeleteUser = async () => {
		try {
			const result = await Request.del(`user/${urlUserID}`, appState.authState)
			if (result.data.success) {
				updateMessages(Messages.addMessage('success', 'Successfully deleted user'))
				navigate('/users')
			} else {
				updateMessages(Messages.addMessage('danger', 'Unable to delete user'))
			}
		} catch (e) {
			console.log(e)
			updateMessages(Messages.addMessage('danger', 'Unable to Invite User'))
		}
	}

	if (permissions !== null && !isLoading) {
		return (
			<Container>
				<Row>
					<Col style={{ paddingTop: '3px' }}>
						<Link to="/users">
							<ArrowLeftCircle /> Back to all users
						</Link>
					</Col>
				</Row>
				<Card>
					<Row className={'modal-header-row'}>
						<Col xs="auto">
							<h3>{getTitle(urlUserID, `${originalData.firstName} ${originalData.lastName}`)}</h3>
						</Col>
						<Col></Col>
						<Col xs="auto">
							{urlUserID !== 'new' && I.have('admin', appState.permission[appState.context]) && !userIsMe ? (
								<Button onClick={() => updateShowDialog(true)}>Delete User</Button>
							) : null}
						</Col>
					</Row>
					<Row>
						<Col>
							<Form onSubmit={handleSubmit}>
								<Form.Group controlId="formUserEmail" as={Row}>
									<Form.Label column sm="2">
										Email
									</Form.Label>
									<Col sm="7">
										<Form.Control
											type="text"
											name="email"
											placeholder="Email"
											readOnly={isEmailDisabled && (urlUserID === 'new' ? isSubmitting : true)}
											plaintext={isEmailDisabled && (urlUserID === 'new' ? isSubmitting : true)}
											disabled={urlUserID !== 'new' || isSubmitting}
											onClick={() => canEdit && !isSubmitting && updateEmailDisabled(false)}
											onChange={handleChange}
											value={user.email}
											className={'no-hover'}
										/>
									</Col>
									<Col sm="auto">
										{!isEmailDisabled && urlUserID !== 'new' ? (
											<Button
												type="button"
												variant="success"
												onClick={() => {
													updateEmailDisabled(true)
													postUser()
												}}
											>
												Save
											</Button>
										) : null}
									</Col>
									<Col sm="auto">
										{!isEmailDisabled && urlUserID !== 'new' ? (
											<Button
												type="button"
												onClick={() => {
													updateEmailDisabled(true)
													resetUser()
												}}
											>
												Cancel
											</Button>
										) : null}
									</Col>
								</Form.Group>

								<Form.Group controlId="formUserFirstName" as={Row}>
									<Form.Label column sm="2">
										First Name
									</Form.Label>
									<Col sm="7">
										<Form.Control
											type="text"
											name="firstName"
											placeholder="First Name"
											readOnly={isFirstNameDisabled && (urlUserID === 'new' ? isSubmitting : true)}
											plaintext={isFirstNameDisabled && (urlUserID === 'new' ? isSubmitting : true)}
											disabled={!(urlUserID === 'new' || canEdit) || isSubmitting}
											onClick={() => canEdit && !isSubmitting && updateFirstNameDisabled(false)}
											onChange={handleChange}
											value={user.firstName}
											className={!canEdit ? 'no-hover' : ''}
										/>
									</Col>
									<Col sm="auto">
										{!isFirstNameDisabled && urlUserID !== 'new' ? (
											<Button
												type="button"
												variant="success"
												onClick={() => {
													updateFirstNameDisabled(true)
													postUser()
												}}
											>
												Save
											</Button>
										) : null}
									</Col>
									<Col sm="auto">
										{!isFirstNameDisabled && urlUserID !== 'new' ? (
											<Button
												type="button"
												onClick={() => {
													updateFirstNameDisabled(true)
													resetUser()
												}}
											>
												Cancel
											</Button>
										) : null}
									</Col>
								</Form.Group>

								<Form.Group controlId="formUserLastName" as={Row}>
									<Form.Label column sm="2">
										Last Name
									</Form.Label>
									<Col sm="7">
										<Form.Control
											type="text"
											name="lastName"
											placeholder="Last Name"
											readOnly={isLastNameDisabled && (urlUserID === 'new' ? isSubmitting : true)}
											plaintext={isLastNameDisabled && (urlUserID === 'new' ? isSubmitting : true)}
											disabled={!(urlUserID === 'new' || canEdit) || isSubmitting}
											onClick={() => canEdit && !isSubmitting && updateLastNameDisabled(false)}
											onChange={handleChange}
											value={user.lastName}
											className={!canEdit ? 'no-hover' : ''}
										/>
									</Col>
									<Col sm="auto">
										{!isLastNameDisabled && urlUserID !== 'new' ? (
											<Button
												type="button"
												variant="success"
												onClick={() => {
													updateLastNameDisabled(true)
													postUser()
												}}
											>
												Save
											</Button>
										) : null}
									</Col>
									<Col sm="auto">
										{!isLastNameDisabled && urlUserID !== 'new' ? (
											<Button
												type="button"
												onClick={() => {
													updateLastNameDisabled(true)
													resetUser()
												}}
											>
												Cancel
											</Button>
										) : null}
									</Col>
								</Form.Group>

								<Form.Group controlId="formUserPermission" as={Row}>
									<Form.Label column sm="2">
										LeaveCal Permissions
									</Form.Label>
									<Col sm="7">
										<Form.Control
											as="select"
											name="lcPermission"
											readOnly={isPermissionDisabled && (urlUserID === 'new' ? isSubmitting : true)}
											plaintext={isPermissionDisabled && (urlUserID === 'new' ? isSubmitting : true)}
											disabled={!(urlUserID === 'new' || canEdit) || isSubmitting}
											onClick={() => canEdit && !isSubmitting && updatePermissionDisabled(false)}
											onChange={handleChange}
											value={user.lcPermission}
											className={!canEdit ? 'no-hover' : ''}
										>
											<option value="">Select Permission</option>
											{permissions.map((p) => (
												<option
													key={p.id}
													value={p.id}
													disabled={p.id === I.Admin && !I.have('admin', appState.permission[appState.context])}
												>
													{p.name}
												</option>
											))}
										</Form.Control>
									</Col>
									<Col sm="auto">
										{!isPermissionDisabled && urlUserID !== 'new' ? (
											<Button
												type="button"
												variant="success"
												onClick={() => {
													updatePermissionDisabled(true)
													postUser()
												}}
											>
												Save
											</Button>
										) : null}
									</Col>
									<Col sm="auto">
										{!isPermissionDisabled && urlUserID !== 'new' ? (
											<Button
												type="button"
												onClick={() => {
													updatePermissionDisabled(true)
													resetUser()
												}}
											>
												Cancel
											</Button>
										) : null}
									</Col>
								</Form.Group>
								<Form.Group controlId="formUserPermission" as={Row}>
									<Form.Label column sm="2">
										Oversight Permissions
									</Form.Label>
									<Col sm="7">
										<Form.Control
											as="select"
											name="osPermission"
											readOnly={isPermissionDisabled && (urlUserID === 'new' ? isSubmitting : true)}
											plaintext={isPermissionDisabled && (urlUserID === 'new' ? isSubmitting : true)}
											disabled={!(urlUserID === 'new' || canEdit) || isSubmitting}
											onClick={() => canEdit && !isSubmitting && updatePermissionDisabled(false)}
											onChange={handleChange}
											value={user.osPermission}
											className={!canEdit ? 'no-hover' : ''}
										>
											<option value="">Select Permission</option>
											{permissions.map((p) => (
												<option
													key={p.id}
													value={p.id}
													disabled={p.id === I.Admin && !I.have('admin', appState.permission[appState.context])}
												>
													{p.name}
												</option>
											))}
										</Form.Control>
									</Col>
									<Col sm="auto">
										{!isPermissionDisabled && urlUserID !== 'new' ? (
											<Button
												type="button"
												variant="success"
												onClick={() => {
													updatePermissionDisabled(true)
													postUser()
												}}
											>
												Save
											</Button>
										) : null}
									</Col>
									<Col sm="auto">
										{!isPermissionDisabled && urlUserID !== 'new' ? (
											<Button
												type="button"
												onClick={() => {
													updatePermissionDisabled(true)
													resetUser()
												}}
											>
												Cancel
											</Button>
										) : null}
									</Col>
								</Form.Group>
								<Form.Group controlId="formUserPermission" as={Row}>
									<Form.Label column sm="2">
										TAYG Permissions
									</Form.Label>
									<Col sm="7">
										<Form.Control
											as="select"
											name="taygPermission"
											readOnly={isPermissionDisabled && (urlUserID === 'new' ? isSubmitting : true)}
											plaintext={isPermissionDisabled && (urlUserID === 'new' ? isSubmitting : true)}
											disabled={!(urlUserID === 'new' || canEdit) || isSubmitting}
											onClick={() => canEdit && !isSubmitting && updatePermissionDisabled(false)}
											onChange={handleChange}
											value={user.taygPermission}
											className={!canEdit ? 'no-hover' : ''}
										>
											<option value="">Select Permission</option>
											{permissions.map((p) => (
												<option
													key={p.id}
													value={p.id}
													disabled={p.id === I.Admin && !I.have('admin', appState.permission[appState.context])}
												>
													{p.name}
												</option>
											))}
										</Form.Control>
									</Col>
									<Col sm="auto">
										{!isPermissionDisabled && urlUserID !== 'new' ? (
											<Button
												type="button"
												variant="success"
												onClick={() => {
													updatePermissionDisabled(true)
													postUser()
												}}
											>
												Save
											</Button>
										) : null}
									</Col>
									<Col sm="auto">
										{!isPermissionDisabled && urlUserID !== 'new' ? (
											<Button
												type="button"
												onClick={() => {
													updatePermissionDisabled(true)
													resetUser()
												}}
											>
												Cancel
											</Button>
										) : null}
									</Col>
								</Form.Group>
								<Form.Group controlId="formUserPermission" as={Row}>
									<Form.Label column sm="2">
										SubSync Permissions
									</Form.Label>
									<Col sm="7">
										<Form.Control
											as="select"
											name="subsyncPermission"
											readOnly={isPermissionDisabled && (urlUserID === 'new' ? isSubmitting : true)}
											plaintext={isPermissionDisabled && (urlUserID === 'new' ? isSubmitting : true)}
											disabled={!(urlUserID === 'new' || canEdit) || isSubmitting}
											onClick={() => canEdit && !isSubmitting && updatePermissionDisabled(false)}
											onChange={handleChange}
											value={user.subsyncPermission}
											className={!canEdit ? 'no-hover' : ''}
										>
											<option value="">Select Permission</option>
											{permissions.map((p) => (
												<option
													key={p.id}
													value={p.id}
													disabled={p.id === I.Admin && !I.have('admin', appState.permission[appState.context])}
												>
													{p.name}
												</option>
											))}
										</Form.Control>
									</Col>
									<Col sm="auto">
										{!isPermissionDisabled && urlUserID !== 'new' ? (
											<Button
												type="button"
												variant="success"
												onClick={() => {
													updatePermissionDisabled(true)
													postUser()
												}}
											>
												Save
											</Button>
										) : null}
									</Col>
									<Col sm="auto">
										{!isPermissionDisabled && urlUserID !== 'new' ? (
											<Button
												type="button"
												onClick={() => {
													updatePermissionDisabled(true)
													resetUser()
												}}
											>
												Cancel
											</Button>
										) : null}
									</Col>
								</Form.Group>
								<Row>
									<Col sm={{ span: 'auto', offset: '2' }}>
										{urlUserID === 'new' ? (
											<Button variant="primary" type="submit" disabled={!validateForm() || isSubmitting}>
												Invite User
											</Button>
										) : null}
									</Col>
								</Row>
							</Form>
						</Col>
					</Row>
				</Card>
				<Messages.Messages messages={messages} updateMessage={updateMessages} />
				<Dialog
					show={showDialog}
					handleClose={() => updateShowDialog(false)}
					handleConfirm={handleDeleteUser}
					titleText={'Delete user confirmation'}
					bodyText={'Are you sure you want to delete this user?'}
				/>
			</Container>
		)
	} else if (user === null) {
		return (
			<Container className="oversight-container">
				<Row>
					<Col></Col>
					<Col>
						<p>You do not have permission to view this page.</p>
					</Col>
					<Col></Col>
				</Row>
			</Container>
		)
	} else {
		return <div></div>
	}
}

export default User
