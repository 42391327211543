import React from 'react'
import { ChevronRight, ChevronDown } from 'react-feather'

interface CollapseChevronProps {
	size?: number
	collapsed: boolean
	updateCollapsed: React.Dispatch<React.SetStateAction<boolean>>
}

export const CollapseChevron = (props: CollapseChevronProps) =>
	props.collapsed ? (
		<ChevronRight size={props.size || 16} onClick={() => props.updateCollapsed(false)} />
	) : (
		<ChevronDown size={props.size || 16} onClick={() => props.updateCollapsed(true)} />
	)
