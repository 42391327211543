import React from 'react'
import { Auth } from 'aws-amplify'
import { Button, Card, Col, Container, FormControl, FormGroup, FormLabel, Modal, Row } from 'react-bootstrap'
import * as AppDefinitions from '../../App.d'
import * as Messages from '../../components/ui/messages/messages'
import LoadingImage from '../../images/Finlert-loading-GIF_100px.gif'
import { AppContext } from '../../App'
import * as Request from '../../utilities/request'
import { MessageAction } from '../../components/ui/messages/message'
import { Link, PlusSquare } from 'react-feather'
import { productNameFromContext } from '../../utilities/context'
import { refreshSession } from '../../utilities/login'

interface HandleEventPopUpProps {
	popUpEventType: 'Create' | 'Join' | ''
	handleClose: () => void
	show: boolean
	updateMessages: (message: MessageAction) => void
	appState: AppDefinitions.AppState
	updateAppState?: React.Dispatch<AppDefinitions.AppStateAction>
}

const getInitialAccountName = (appState: AppDefinitions.AppState) =>
	`${appState.authState.attributes?.given_name} ${appState.authState.attributes?.family_name} Account`

const HandleEvent = (props: HandleEventPopUpProps) => {
	const [isSubmitting, updateIsSubmitting] = React.useState<boolean>(false)
	const [accountName, updateAccountName] = React.useState<string>(getInitialAccountName(props.appState))
	const goBackToLogin = () => {
		Auth.signOut()
			.then(() => {
				window.location.href = '/'
			})
			.catch((err: Error) => console.log(err))
	}
	const handleCreation = async () => {
		// start account creation for this user
		const values = {
			name: accountName,
			ownerUserID: props.appState.authState.attributes!['custom:userId'],
			createUserID: props.appState.authState.attributes!['custom:userId'],
			modifiedUserID: props.appState.authState.attributes!['custom:userId'],
			canUseKeypay: window.location.hash === '#keypay' ? '1' : '0',
		}
		const req = await Request.post(`account/user`, values, props.appState.authState)
		if (req.data.success) {
			props.updateMessages({
				type: 'add',
				data: {
					severity: 'success',
					message: 'Successfully created account',
					timeout: 3000,
					dismissible: true,
				},
			})
			props.updateAppState!({
				state: 'newAccountConfirmed',
				data: {
					accounts: req.data.accounts,
					permission: {
						oversight: req.data.accounts[0].osPermissionID || null,
						leavecal: req.data.accounts[0].lcPermissionID || null,
						tayg: req.data.accounts[0].taygPermissionID || null,
						subsync: req.data.accounts[0].subsyncPermissionID || null,
					},
					attributes: {
						...props.appState.authState.attributes,
						'custom:currentAccountId': req.data.accounts[0].accountID,
						'custom:userAccountId': req.data.accounts[0].userAccountID,
					},
				},
			})
			await refreshSession()
			window.location.href = '/'
			window.location.reload()
		}
	}
	return (
		<Modal show={props.show} aria-labelledby="modal" centered onHide={props.handleClose}>
			<Modal.Header>
				<Modal.Title>{props.popUpEventType === 'Join' ? 'Join Existing Account' : 'Create Account'}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<FormGroup>
					<>
						{props.popUpEventType === 'Join' ? (
							<Row style={{ paddingBottom: '10px' }}>
								<Col>
									<p>
										To join an existing account, please contact the person in charge of your organisation&apos;s account and ask them to
										invite you in!
									</p>
									<p>
										Don&apos;t know who&apos;s in charge? <a href="mailto:support@finlert.com">Reach out</a> and we can help you.
									</p>
								</Col>
							</Row>
						) : (
							<FormGroup as={Row}>
								<FormLabel column sm={4}>
									Account Name
								</FormLabel>
								<Col sm="6">
									<FormControl
										type="text"
										id={'name'}
										name="name"
										placeholder="Account Name"
										onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
											const value = event.target.value
											updateAccountName(value)
										}}
										value={accountName}
									/>
								</Col>
							</FormGroup>
						)}
					</>
					{!isSubmitting ? (
						props.popUpEventType === 'Join' ? (
							<Button variant={'success'} disabled={isSubmitting} style={{ marginRight: '15px' }} onClick={goBackToLogin}>
								Ok
							</Button>
						) : (
							<>
								<Button
									variant={'success'}
									disabled={isSubmitting}
									style={{ marginRight: '15px' }}
									onClick={() => {
										handleCreation()
										updateIsSubmitting(true)
									}}
								>
									Create
								</Button>
								<Button variant={'secondary'} onClick={props.handleClose}>
									Cancel
								</Button>
							</>
						)
					) : (
						<img src={LoadingImage} alt="Loading ..." style={{ width: '10%' }} />
					)}
				</FormGroup>
			</Modal.Body>
		</Modal>
	)
}
const AccountCreationConfirmation = () => {
	const { appState, updateAppState } = React.useContext(AppContext)
	const [messages, setMessages] = Messages.useMessageReducer([])
	const [showDialog, updateShowDialog] = React.useState<boolean>(false)
	const [choice, updateChoice] = React.useState<HandleEventPopUpProps['popUpEventType']>('')

	return (
		<Container>
			<Card title="Create Account" className="extra-padded">
				<Row>
					<Col>
						<Row>
							<Col>
								<h3>Welcome {appState.authState.attributes?.given_name} - what are you trying to do today?</h3>
							</Col>
						</Row>
						<Row>
							<Col>
								<Button
									className="big-button"
									variant="outline-secondary"
									onClick={() => {
										try {
											updateChoice('Create')
											updateShowDialog(true)
										} catch (err) {
											setMessages({
												type: 'add',
												data: {
													severity: 'danger',
													timeout: 0,
													message: 'Failed to create account',
													dismissible: true,
												},
											})
										}
									}}
								>
									<PlusSquare size={100} />
									<div>I want to create a new trial of {productNameFromContext(appState.context)}</div>
								</Button>
							</Col>
							<Col>
								<Button
									className="big-button"
									variant="outline-secondary"
									onClick={() => {
										try {
											updateChoice('Join')
											updateShowDialog(true)
										} catch (err) {
											setMessages({
												type: 'add',
												data: {
													severity: 'danger',
													timeout: 0,
													message: 'Failed to display information about existing organisation',
													dismissible: true,
												},
											})
										}
									}}
								>
									<Link size={100} />
									<div>I want to join a {productNameFromContext(appState.context)} account already setup for my company</div>
								</Button>
							</Col>
						</Row>
					</Col>
				</Row>
				{showDialog && (
					<HandleEvent
						popUpEventType={choice}
						handleClose={() => {
							updateShowDialog(false)
						}}
						show={showDialog}
						updateMessages={setMessages}
						appState={appState}
						updateAppState={updateAppState}
					/>
				)}
			</Card>
			<Messages.Messages messages={messages} updateMessage={setMessages} />
		</Container>
	)
}
export default AccountCreationConfirmation
