import React from 'react'
import { Form, Row, Col, DropdownButton, Dropdown } from 'react-bootstrap'

// definitions
import { AccountXeroOrgWithData } from '../../../../screens/subsync/subsync'
import { StripeOrgAccountXeroOrg, XeroAPIContact } from '../../../../../../back-end/utilities/apiDefinitions'

interface CustomerBalanceSettingsProps {
	orgMapping: StripeOrgAccountXeroOrg | undefined
	mappedXeroOrg: AccountXeroOrgWithData | undefined
	stripeOrgID: string
	handleSettingsUpdate: (stripeOrgID: string, values: { [name: string]: string | boolean | XeroAPIContact | undefined }) => void
}

const CustomerBalanceSettings = (props: CustomerBalanceSettingsProps) => {
	return (
		<>
			<Form.Group as={Row} className="d-flex align-items-center" style={{ height: '2.5rem' }}>
				<Col>
					<Form.Check
						type={'checkbox'}
						id={`${props.stripeOrgID}-customer-balance-enabled`}
						name={'customerBalanceEnabled'}
						label={'Enable invoices with customer balance transactions'}
						checked={!!props.orgMapping?.stripeOrgAccountXeroOrgSettings?.customerBalanceEnabled}
						onChange={(e) => props.handleSettingsUpdate(props.stripeOrgID, { [e.target.name]: e.target.checked })}
					/>
				</Col>
			</Form.Group>
			<Form.Group as={Row} style={{ height: '2.5rem' }}>
				<Form.Label column sm={2}>
					Xero Account
				</Form.Label>
				<Col sm={4}>
					<DropdownButton
						id={`${props.stripeOrgID}-customer-balance-account-select`}
						title={
							props.mappedXeroOrg!.accounts.find(
								(a) => a.xeroAccountID === props.orgMapping?.stripeOrgAccountXeroOrgSettings?.customerBalanceXeroAccountID
							)?.name || 'Select Xero Account'
						}
						onSelect={(eventKey) => props.handleSettingsUpdate(props.stripeOrgID, { customerBalanceXeroAccountID: eventKey! })}
						disabled={!props.orgMapping?.stripeOrgAccountXeroOrgSettings?.customerBalanceEnabled}
					>
						<Dropdown.Header>Revenue</Dropdown.Header>
						{props
							.mappedXeroOrg!.accounts.filter((account) => account.type === 'REVENUE')
							.sort((a, b) => a.name!.localeCompare(b.name!, undefined, { numeric: true }))
							.map((xa) => (
								<Dropdown.Item key={xa.xeroAccountID} eventKey={xa.xeroAccountID}>
									{xa.name}
								</Dropdown.Item>
							))}
						<Dropdown.Header>Other Income</Dropdown.Header>
						{props
							.mappedXeroOrg!.accounts.filter((account) => account.type === 'OTHERINCOME')
							.sort((a, b) => a.name!.localeCompare(b.name!, undefined, { numeric: true }))
							.map((xa) => (
								<Dropdown.Item key={xa.xeroAccountID} eventKey={xa.xeroAccountID}>
									{xa.name}
								</Dropdown.Item>
							))}
						<Dropdown.Header>Sales</Dropdown.Header>
						{props
							.mappedXeroOrg!.accounts.filter((account) => account.type === 'SALES')
							.sort((a, b) => a.name!.localeCompare(b.name!, undefined, { numeric: true }))
							.map((xa) => (
								<Dropdown.Item key={xa.xeroAccountID} eventKey={xa.xeroAccountID}>
									{xa.name}
								</Dropdown.Item>
							))}
						<Dropdown.Header>Current Liability</Dropdown.Header>
						{props
							.mappedXeroOrg!.accounts.filter((account) => account.type === 'CURRLIAB')
							.sort((a, b) => a.name!.localeCompare(b.name!, undefined, { numeric: true }))
							.map((xa) => (
								<Dropdown.Item key={xa.xeroAccountID} eventKey={xa.xeroAccountID}>
									{xa.name}
								</Dropdown.Item>
							))}
					</DropdownButton>
				</Col>
			</Form.Group>
			<Form.Group as={Row} style={{ height: '2.5rem' }}>
				<Form.Label column sm={2}>
					Xero Tax Rate
				</Form.Label>
				<Col sm={4}>
					<DropdownButton
						id={`${props.stripeOrgID}-customer-balance-tax-rate-select`}
						title={
							props.mappedXeroOrg!.taxRates.find(
								(tr) => tr.xeroTaxRateID === props.orgMapping?.stripeOrgAccountXeroOrgSettings?.customerBalanceXeroTaxRateID
							)?.name || 'Select Xero Tax Rate'
						}
						onSelect={(eventKey) => props.handleSettingsUpdate(props.stripeOrgID, { customerBalanceXeroTaxRateID: eventKey! })}
						disabled={!props.orgMapping?.stripeOrgAccountXeroOrgSettings?.customerBalanceEnabled}
					>
						{props
							.mappedXeroOrg!.taxRates.filter((tr) => tr.canApplyToRevenue)
							.map((taxRate) => (
								<Dropdown.Item key={taxRate.xeroTaxRateID} eventKey={taxRate.xeroTaxRateID}>
									{taxRate.name} {taxRate.effectiveRate ? `(${(Number(taxRate.effectiveRate) * 100).toFixed(2)}%)` : ''}
								</Dropdown.Item>
							))}
					</DropdownButton>
				</Col>
			</Form.Group>
		</>
	)
}

export { CustomerBalanceSettings }
