import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom'
import { Amplify } from 'aws-amplify'

import { AppState, AppStateAction, ProductContext } from './App.d'
import './App.css'
import ScreensXeroSsoRedirect from './screens/xero/redirectSignIn'
import { AppContent } from './xero-embed'
import { App as XeroEmbedApp } from './xero-embed/App'
import { Help } from './xero-embed/Help'
import { Settings } from './xero-embed/Settings'

import Logo from './components/home/logo'
import UserProfile from './components/home/userProfile'

import * as Request from './utilities/request'
import { awsConfig } from './constants/config'

// datepicker styles
import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'
import NoAccess from './components/home/noaccess'
import { Authenticator, ThemeProvider } from '@aws-amplify/ui-react'
import '@aws-amplify/ui-react/styles.css'
import { AmplifyTheme, Components, Services } from './screens/login/amplifyComponents'
import { AmplifyUser } from '@aws-amplify/ui'
import { UseAuthenticator } from '@aws-amplify/ui-react'
import { productContext } from './utilities/context'
import { MyRoutes } from './components/routes/myRoutes'

Amplify.configure(awsConfig)

const initialState = (host: ProductContext): AppState => ({
	authState: {
		isLoggedIn: false,
		apiToken: null,
		attributes: null,
		invitationAccepted: null,
	},
	permission: { oversight: '', leavecal: '', tayg: '', subsync: '' },
	accounts: [],
	context: host,
})

const appReducer: React.Reducer<AppState, AppStateAction> = (state, action) => {
	switch (action.state) {
		case 'signedIn':
			return {
				...state,
				accounts: action.data.accounts || [],
				authState: {
					...state.authState,
					isLoggedIn: true,
					apiToken: action.data.token,
					attributes: action.data.attributes,
					invitationAccepted: true,
				},
			}
		case 'signIn':
			return {
				...state,
				authState: {
					...state.authState,
					isLoggedIn: false,
				},
			}
		case 'permission':
			return {
				...state,
				permission: action.data,
				authState: {
					...state.authState,
					invitationAccepted: action.accepted,
				},
			}
		case 'accounts':
			return {
				...state,
				accounts: action.data,
			}
		case 'selectedOrg':
			return {
				...state,
				selectedOrg: action.data,
			}
		case 'noAccounts':
			return {
				...state,
				hasNoAccount: action.data.hasNoAccount,
			}
		case 'newAccountConfirmed':
			return {
				...state,
				permission: action.data.permission,
				accounts: action.data.accounts,
				hasNoAccount: false,
				authState: {
					...state.authState,
					attributes: action.data.attributes,
				},
			}
		case 'slack':
			return {
				...state,
				slackChannels: action.data,
			}
		default:
			return state
	}
}

interface AppProps {
	auth: {
		signOut?: UseAuthenticator['signOut']
		user?: AmplifyUser
	}
}

const AppContext = React.createContext<{
	appState: AppState
	updateAppState: React.Dispatch<AppStateAction>
}>({ appState: initialState(productContext), updateAppState: () => {} })

// anything that exists over each page goes here
const App = (props: AppProps) => {
	const [appState, setAppState] = React.useReducer<React.Reducer<AppState, AppStateAction>>(appReducer, initialState(productContext))
	const [isLoading, updateIsLoading] = React.useState<boolean>(true)

	React.useEffect(() => {
		const userSession = props.auth.user?.getSignInUserSession()
		const attributes = props.auth.user?.attributes
		if (userSession && userSession.getIdToken().getJwtToken()) {
			setAppState({
				state: 'signedIn',
				data: { token: userSession.getIdToken().getJwtToken(), attributes },
			})
		}
	}, [props.auth.user])

	React.useEffect(() => {
		const getUserPermissions = async () => {
			if (appState.authState.attributes && appState.authState.attributes['custom:userId']) {
				const req = appState.hasNoAccount
					? await Request.get(`user/${appState.authState.attributes['custom:userId']}/user`, appState.authState)
					: await Request.get(`user/${appState.authState.attributes['custom:userId']}`, appState.authState)
				if (req.data && req.data.users && req.data.users.length > 0) {
					setAppState({
						state: 'permission',
						data: {
							oversight: req.data.users[0].osPermission.permissionID || null,
							leavecal: req.data.users[0].lcPermission.permissionID || null,
							tayg: req.data.users[0].taygPermission.permissionID || null,
							subsync: req.data.users[0].subsyncPermission.permissionID || null,
						},
						accepted: req.data.users[0].invitationAccepted,
					})
				}
			}
		}

		const getUserAccounts = async () => {
			const req = await Request.get(`account`, appState.authState)
			if (req.data.accounts.length > 0) {
				setAppState({ state: 'accounts', data: req.data.accounts })
			} else {
				setAppState({ state: 'noAccounts', data: { ...appState, hasNoAccount: true } })
			}
			updateIsLoading(false)
		}

		if (appState.authState.isLoggedIn) {
			getUserPermissions()
			getUserAccounts()
		}
		// if (appState.hasNoAccount) {
		// 	getUserPermissions()
		// }
	}, [appState.authState.isLoggedIn, appState.hasNoAccount]) // eslint-disable-line

	React.useEffect(() => {
		if (appState.authState.attributes === undefined) {
			window.location.search = ''
			// window.location.reload()
		}
	}, [appState.authState.attributes])

	if (!appState.authState.isLoggedIn) {
		return <div></div>
	}

	return (
		<AppContext.Provider value={{ appState, updateAppState: setAppState }}>
			<Row className="nav-row">
				<Col sm="auto" onClick={() => (window.location.pathname = '/')}>
					<Logo type={appState.context} />
				</Col>
				<Col></Col>
				<Col sm="auto">
					<UserProfile
						authState={appState.authState}
						userAttributes={appState.authState.attributes}
						permission={appState.permission}
						accounts={appState.accounts}
						context={appState.context}
						selectedOrg={appState.selectedOrg}
					/>
				</Col>
			</Row>
			<Row>
				<Col>
					{!isLoading &&
					appState.authState.isLoggedIn &&
					(appState.accounts.length === 0 || appState.permission[appState.context] === null) &&
					!appState.hasNoAccount ? (
						<Col style={{ display: 'flex', justifyContent: 'center' }}>
							<NoAccess appState={appState} />
						</Col>
					) : (
						<MyRoutes />
					)}
				</Col>
			</Row>
		</AppContext.Provider>
	)
}

const AppWithAuth = () => (
	<ThemeProvider theme={AmplifyTheme}>
		<Router>
			<Routes>
				<Route path="/xero/sso" element={<ScreensXeroSsoRedirect />} />
				<Route path="/xero-embedded-app" element={<AppContent />}>
					<Route index element={<XeroEmbedApp />} />
					<Route path="help" element={<Help />} />
					<Route path="settings" element={<Settings />} />
				</Route>
				<Route
					path="*"
					element={
						<Authenticator
							initialState={location.pathname.toLowerCase().includes('signup') ? 'signUp' : 'signIn'}
							components={Components}
							services={Services()}
							signUpAttributes={['email', 'given_name', 'family_name']}
							loginMechanisms={['email']}
							passwordSettings={{ passwordPolicyMinLength: 8, passwordPolicyCharacters: [] }} // leaving the characters one empty since we don't have any restrictions in that sense
						>
							{(auth) => <App auth={auth} />}
						</Authenticator>
					}
				/>
			</Routes>
		</Router>
	</ThemeProvider>
)

export default AppWithAuth
export { AppContext }
