import React from 'react'
import { Button, Col, Row } from 'react-bootstrap'
import ExternalIcon from '../icons/external-white.svg'

interface SubFooterProps {
	customerID?: string
}

const SubFooter = (props: SubFooterProps) => {
	const handleOnClick = React.useCallback(() => {
		if (props.customerID) {
			window.open(
				`https://dashboard.stripe.com/customers/${props.customerID}?create=subscription&subscription_default_customer=${props.customerID}`,
				'StripeNewSubscription'
			)
		} else {
			window.open('https://dashboard.stripe.com/subscriptions?create=subscription', 'StripeNewSubscription')
		}
	}, [props.customerID])

	return (
		<Row className="footer">
			<Col>
				<Button onClick={handleOnClick}>
					New Stripe subscription <img src={ExternalIcon} />
				</Button>
			</Col>
		</Row>
	)
}

export { SubFooter }
