import React from 'react'
import { Auth } from '@aws-amplify/auth'
import { useTheme, View, Image, Text, Heading, Button, useAuthenticator, Theme, Authenticator, TextField } from '@aws-amplify/ui-react'
import lcLogo from '../../images/leavecal_finlert-1.svg'
import osLogo from '../../images/oversight_finlert-1.svg'
import taygLogo from '../../images/taxasyougo_finlert-1.svg'
import ssLogo from '../../images/subSyncIcons/subSync_finlert-1.svg'
import { XeroButton } from '../../components/ui/xeroButton/xeroButton'
import { getSignInProvider } from '../../utilities/login'
import { productContext } from '../../utilities/context'
import { I18n } from 'aws-amplify'

const Services = () => {
	const urlParams = new URLSearchParams(window.location.search)
	const stripeAccountId = urlParams.get('account_id') || ''

	return {
		handleSignIn: (formData: { username: string; password: string }) => {
			const { username, password } = formData
			return Auth.signIn(username.toLowerCase(), password)
		},
		handleSignUp: (formData: { username: string; password: string; attributes: { given_name: string; family_name: string; account_name: string } }) => {
			const signUpParams = {
				...formData,
				autoSignIn: {
					enabled: true,
				},
				clientMetadata: {
					stripe_account_id: stripeAccountId,
				},
			}
			return Auth.signUp(signUpParams)
		},
		handleConfirmSignUp: (formData: { username: string; code: string }) => {
			const confirmSignUpOptions = {
				clientMetadata: {
					stripe_account_id: stripeAccountId,
				},
			}
			return Auth.confirmSignUp(formData.username, formData.code, confirmSignUpOptions)
		},
	}
}

const Components = {
	Header() {
		const { tokens } = useTheme()
		let logo = osLogo
		if (productContext === 'leavecal') {
			logo = lcLogo
		} else if (productContext === 'tayg') {
			logo = taygLogo
		} else if (productContext === 'subsync') {
			logo = ssLogo
		}

		return (
			<View textAlign="center" padding={tokens.space.large}>
				<Image alt="Safetick logo" src={logo} />
			</View>
		)
	},

	Footer() {
		const { tokens } = useTheme()

		return (
			<View textAlign="center" padding={tokens.space.large}>
				<Text color={tokens.colors.neutral[80]}>&copy; All Rights Reserved</Text>
			</View>
		)
	},

	SignIn: {
		Header() {
			const { tokens } = useTheme()
			I18n.setLanguage('en')
			const dict = {
				en: {
					Password: 'Password',
				},
			}
			I18n.putVocabularies(dict)

			return (
				<Heading padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`} level={3}>
					Sign in to your account
				</Heading>
			)
		},

		Footer() {
			const { toResetPassword } = useAuthenticator()

			return (
				<View
					textAlign="center"
					style={{
						padding: '0 var(--amplify-components-authenticator-form-padding) var(--amplify-components-authenticator-form-padding)',
					}}
				>
					<View>
						<XeroButton text={'Sign in with Xero'} onClick={() => Auth.federatedSignIn({ customProvider: getSignInProvider() })} />
					</View>
					<View>
						Forgot your password?
						<Button style={{ textDecoration: 'underline' }} fontWeight="normal" onClick={toResetPassword} size="small" variation="link">
							Reset password
						</Button>
					</View>
				</View>
			)
		},
	},

	SignUp: {
		Header() {
			const { tokens } = useTheme()

			return (
				<Heading padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`} level={3}>
					Create a new account
				</Heading>
			)
		},
		FormFields() {
			I18n.setLanguage('en')
			const dict = {
				en: {
					Password: 'Password (minimum 8 characters)',
				},
			}
			I18n.putVocabularies(dict)

			return (
				<>
					<Authenticator.SignUp.FormFields />
					<TextField label="Account Name" id="name" placeholder="Account Name" name="name" type="text" crossOrigin={undefined}></TextField>
				</>
			)
		},
		Footer() {
			const { toSignIn } = useAuthenticator()

			return (
				<View
					textAlign="center"
					style={{
						padding: '0 var(--amplify-components-authenticator-form-padding) var(--amplify-components-authenticator-form-padding)',
					}}
				>
					<View>
						<XeroButton text={'Sign up with Xero'} onClick={() => Auth.federatedSignIn({ customProvider: getSignInProvider() })} />
					</View>
					<Button style={{ textDecoration: 'underline' }} fontWeight="normal" onClick={toSignIn} size="small" variation="link">
						Back to Sign In
					</Button>
				</View>
			)
		},
	},
	ConfirmSignUp: {
		Header() {
			const { tokens } = useTheme()
			return (
				<Heading padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`} level={3}>
					Enter Information:
				</Heading>
			)
		},
	},
	SetupTOTP: {
		Header() {
			const { tokens } = useTheme()
			return (
				<Heading padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`} level={3}>
					Enter Information:
				</Heading>
			)
		},
	},
	ConfirmSignIn: {
		Header() {
			const { tokens } = useTheme()
			return (
				<Heading padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`} level={3}>
					Enter Information:
				</Heading>
			)
		},
	},
	ResetPassword: {
		Header() {
			const { tokens } = useTheme()
			return (
				<Heading padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`} level={3}>
					Enter Information:
				</Heading>
			)
		},
	},
	ConfirmResetPassword: {
		Header() {
			const { tokens } = useTheme()
			return (
				<Heading padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`} level={3}>
					Enter Information:
				</Heading>
			)
		},
	},
}

const colours = {
	leavecal: {
		hex: '#8aa6ff',
		string: 'blue',
	},
	oversight: {
		hex: '#fada89',
		string: 'yellow',
	},
	tayg: {
		hex: '#bdceba',
		string: 'green',
	},
	subsync: {
		hex: '#8aa6ff',
		string: 'blue',
	},
}

const theme = {
	name: 'finlert-theme',
	tokens: {
		colors: {
			font: {
				primary: { value: '#25282b' },
			},
		},
		components: {
			button: {
				_hover: {
					backgroundColor: {
						value: `{colors.${colours[productContext].string}.20}`,
					},
				},
				_focus: {
					backgroundColor: {
						value: `{colors.${colours[productContext].string}.20}`,
					},
				},
				_active: {
					backgroundColor: {
						value: `{colors.${colours[productContext].string}.30}`,
					},
				},
				primary: {
					backgroundColor: {
						value: colours[productContext].hex,
					},
					_hover: {
						backgroundColor: {
							value: `{colors.${colours[productContext].string}.80}`,
						},
					},
					_focus: {
						backgroundColor: {
							value: `{colors.${colours[productContext].string}.80}`,
						},
					},
					_active: {
						backgroundColor: {
							value: `{colors.${colours[productContext].string}.90}`,
						},
					},
				},
				link: {
					color: { value: '#25282b' },
					_hover: {
						backgroundColor: { value: 'none' },
					},
					_focus: {
						backgroundColor: { value: 'none' },
					},
					_active: {
						backgroundColor: { value: 'none' },
					},
				},
			},
		},
		fonts: {
			default: {
				variable: { value: "'Montserrat', sans-serif" },
				static: { value: "'Montserrat', sans-serif" },
			},
		},
	},
} as Theme

export { Services, Components, theme as AmplifyTheme }
