import React from 'react'
import { Button } from 'react-bootstrap'
import { Admin } from '../../utilities/me'
import * as Request from '../../utilities/request'
import { User } from '../../../../back-end/common/users'
import { AppState } from '../../App.d'
import LcLoadingImage from '../../images/Finlert-loading-GIF_100px.gif'
import OsLoadingImage from '../../images/3887_Oversight_100x100px.gif'
import TaygLoadingImage from '../../images/TAYG_100px.gif'
import SubSyncLoadingImage from '../../images/subSyncIcons/SubSync_100x100.gif'

const LoadingImages = {
	leavecal: LcLoadingImage,
	oversight: OsLoadingImage,
	tayg: TaygLoadingImage,
	subsync: SubSyncLoadingImage,
}

interface NoAccessProps {
	appState: AppState
}

const NoAccess = (props: NoAccessProps) => {
	const [users, setUsers] = React.useState<User[] | null>()
	const [disableButton, setDisableButton] = React.useState<boolean>(false)

	React.useEffect(() => {
		const getUsers = async () => {
			const users = await Request.get('user', props.appState.authState)

			setUsers(users.data.users)
		}

		if (props.appState.authState.isLoggedIn) {
			getUsers()
		}
	}, [props.appState])

	const someoneHasOversight = users && users.length > 0 && users.some((user) => user.osPermission?.permissionID)
	const someoneHasLeavecal = users && users.length > 0 && users.some((user) => user.lcPermission?.permissionID)
	const someoneHasTayg = users && users.length > 0 && users.some((user) => user.taygPermission?.permissionID)
	const someoneHasSubsync = users && users.length > 0 && users.some((user) => user.subsyncPermission?.permissionID)

	const updateUserPermissions = async (property: string) => {
		setDisableButton(true)
		await Request.put(`user/${props.appState.authState.attributes?.['custom:userId']}`, { [property]: Admin }, props.appState.authState)
		setDisableButton(false)
		window.location.reload()
	}

	return (
		<div>
			{users ? (
				<p>You do not have access to this {props.appState.permission[props.appState.context] ? 'account' : 'product'}.</p>
			) : (
				<div className="loading-gif">
					<img src={LoadingImages[props.appState.context]} alt="Loading ..." />
				</div>
			)}
			{users && users.length > 0 && props.appState.context === 'leavecal' && !someoneHasLeavecal && (
				<div className="leavecal-product trial-box">
					<Button disabled={disableButton} onClick={() => updateUserPermissions('lcPermissionID')}>
						Try LeaveCal
					</Button>
				</div>
			)}
			{users && users.length > 0 && props.appState.context === 'oversight' && !someoneHasOversight && (
				<div className="oversight-product trial-box">
					<Button disabled={disableButton} onClick={() => updateUserPermissions('osPermissionID')}>
						Try Oversight
					</Button>
				</div>
			)}
			{users && users.length > 0 && props.appState.context === 'tayg' && !someoneHasTayg && (
				<div className="tayg-product trial-box">
					<Button disabled={disableButton} onClick={() => updateUserPermissions('taygPermissionID')}>
						Try Tayg
					</Button>
				</div>
			)}
			{users && users.length > 0 && props.appState.context === 'subsync' && !someoneHasSubsync && (
				<div className="subsync-product trial-box">
					<Button disabled={disableButton} onClick={() => updateUserPermissions('subsyncPermissionID')}>
						Try SubSync
					</Button>
				</div>
			)}
		</div>
	)
}

export default NoAccess
