import React, { HTMLProps } from 'react'
import { Col, Dropdown, DropdownButton, DropdownButtonProps, Row } from 'react-bootstrap'
import { ArrowLeft, ArrowRight } from 'react-feather'

import './PaginationFooter.css'

interface PaginationFooterProps {
	items: number
	page: number
	pageSize: number
	onPageChange: (newPage: number) => void
	bottomSpacing?: number
}

const CustomToggle = React.forwardRef<HTMLSpanElement, HTMLProps<HTMLSpanElement>>(({ children, onClick }, ref) => {
	return (
		<span
			ref={ref}
			className="pink"
			onClick={(e) => {
				e.preventDefault()
				if (onClick) onClick(e)
			}}
		>
			{children} &#x25be;
		</span>
	)
})
CustomToggle.displayName = 'CustomToggle'

const CustomMenu = React.forwardRef<HTMLDivElement, HTMLProps<HTMLDivElement>>(({ children, style, className }, ref) => (
	<div ref={ref} style={style} className={className}>
		<ul className="list-unstyled">{children}</ul>
	</div>
))
CustomMenu.displayName = 'CustomMenu'

const PaginationFooter = (props: PaginationFooterProps) => {
	const maxPages = React.useMemo(() => Math.ceil(props.items / props.pageSize), [props.items, props.pageSize])

	const handleDropdownOnSelect = React.useCallback((page: string | null) => {
		props.onPageChange(Number(page))
	}, [])

	return (
		<Row className="footer" style={{ bottom: props.bottomSpacing + 'px' || 0 }}>
			<Col xs="auto">Total items: {props.items}</Col>
			<Col />
			{maxPages > 1 && (
				<>
					<Col xs="auto">
						<ArrowLeft onClick={() => props.onPageChange(Math.max(props.page - 1, 1))} />
					</Col>
					<Col xs="auto">
						<Dropdown onSelect={handleDropdownOnSelect} drop="up">
							<Dropdown.Toggle as={CustomToggle}>
								{props.page} of {maxPages}
							</Dropdown.Toggle>
							<Dropdown.Menu as={CustomMenu}>
								{Array.from({ length: maxPages }).map((_, index) => (
									<Dropdown.Item key={index} eventKey={index + 1} active={index + 1 === props.page}>
										{index + 1}
									</Dropdown.Item>
								))}
							</Dropdown.Menu>
						</Dropdown>
					</Col>
					<Col xs="auto">
						<ArrowRight onClick={() => props.onPageChange(Math.min(props.page + 1, maxPages))} />
					</Col>
				</>
			)}
		</Row>
	)
}

export { PaginationFooter }
