import React from 'react'
import { Button } from 'react-bootstrap'

const MoreMenuToggle = React.forwardRef<HTMLButtonElement, { children: JSX.Element; onClick: React.MouseEventHandler<HTMLElement> }>(
	({ children, onClick }, ref) => (
		<Button
			ref={ref}
			onClick={(e) => {
				e.preventDefault()
				onClick(e)
			}}
			className="row-action-button"
		>
			{children}
		</Button>
	)
)
MoreMenuToggle.displayName = 'MoreMenuToggle'

export default MoreMenuToggle
