import * as React from 'react'
import { v4 as uuidv4 } from 'uuid'

// definitions
import * as AppDefinitions from '../../App.d'
import { AccountXeroOrg } from '../../../../back-end/utilities/apiDefinitions'
import { XeroAccount } from '../../../../back-end/common/xeroAccount'
import { TaygSettings } from '../../../../back-end/common/taygSettings'
import { TaygSettingsExtraAccount } from '../../../../back-end/common/taygSettingsExtraAccounts'

// components
import { Row, Col, Card, ButtonGroup, Button, Form } from 'react-bootstrap'
import DropdownButton from 'react-bootstrap/DropdownButton'
import TAYGLoadingImage from '../../images/TAYG_100px.gif'

// utilities
import * as Request from '../../utilities/request'
import * as Messages from '../../components/ui/messages/messages'
import { SettingsDropDown } from './settingsDropdown'
import { SimpleSettingsDropDown } from './simpleSettingsDropdown'
import { ExtraAccounts } from './extraAccounts'

interface SettingsFrameProps {
	show: boolean
	appState: AppDefinitions.AppState
	currentOrg: AccountXeroOrg
	currentTaygSettings: TaygSettings
	updateCurrentTaygSettings: (e: TaygSettings) => void
	isGstSetup: boolean
	pageStatus: string
	updatePageStatus: React.Dispatch<React.SetStateAction<string>>
}

type FilteredAccounts = {
	gstFilter: XeroAccount[] | []
	paygFilter: XeroAccount[] | []
	superFilter: XeroAccount[] | []
	savingsFilter: XeroAccount[] | []
}

type FilterText = {
	gstSearch: string
	paygSearch: string
	superSearch: string
	savingsSearch: string
}

const SettingsFrame = (props: SettingsFrameProps) => {
	const [messages, updateMessages] = Messages.useMessageReducer([])
	const [xeroAccounts, updateXeroAccounts] = React.useState<XeroAccount[] | []>([])
	const [taygSettings, updateTaygSettings] = React.useState<TaygSettings>(props.currentTaygSettings)
	const [gstXeroAccount, updateGstXeroAccount] = React.useState<XeroAccount | null>(null)
	const [paygXeroAccount, updatePaygXeroAccount] = React.useState<XeroAccount | null>(null)
	const [superXeroAccount, updateSuperXeroAccount] = React.useState<XeroAccount | null>(null)
	const [savingsXeroAccount, updateSavingsXeroAccount] = React.useState<XeroAccount | null>(null)
	const [paygCompanyIncomeTaxAmount, updatePaygCompanyIncomeTaxAmount] = React.useState<number | null>(null)
	const [filteredAccounts, updateFilteredAccounts] = React.useState<FilteredAccounts>({
		gstFilter: [],
		paygFilter: [],
		superFilter: [],
		savingsFilter: [],
	})
	const [searchText, updateSearchText] = React.useState<FilterText>({
		gstSearch: '',
		paygSearch: '',
		superSearch: '',
		savingsSearch: '',
	})
	const [gstLodgmentDatePeriod, updateGstLodgmentDatePeriod] = React.useState<string | null>(null)
	const [paygLodgmentDatePeriod, updatePaygLodgmentDatePeriod] = React.useState<string | null>(null)

	React.useEffect(() => {
		const fetchData = async () => {
			const [xeroAccountsData] = await Promise.all([
				Request.get(`xeroaccount?where=XeroOrgID==${props.currentOrg.xeroOrg!.xeroOrgID}`, props.appState.authState),
			])

			const xeroAccounts = xeroAccountsData.data.xeroAccounts || []
			updatePaygCompanyIncomeTaxAmount(props.currentTaygSettings!.paygCompanyIncomeTaxAmount! || null)

			updateTaygSettings({ ...props.currentTaygSettings })
			updateXeroAccounts(
				xeroAccounts.sort(function (a: XeroAccount, b: XeroAccount) {
					return a.name!.localeCompare(b.name!)
				})
			)

			if (props.isGstSetup) {
				updateGstXeroAccount(xeroAccounts.find((xa: XeroAccount) => xa.xeroAccountID === props.currentTaygSettings.gstXeroAccountID))
				updateGstLodgmentDatePeriod(props.currentTaygSettings!.gstLodgmentDatePeriod! || null)
			}

			updatePaygXeroAccount(xeroAccounts.find((xa: XeroAccount) => xa.xeroAccountID === props.currentTaygSettings.paygPayXeroAccountID))
			updatePaygLodgmentDatePeriod(props.currentTaygSettings!.paygLodgmentDatePeriod! || null)
			updateSuperXeroAccount(xeroAccounts.find((xa: XeroAccount) => xa.xeroAccountID === props.currentTaygSettings.superPayXeroAccountID))
			updateSavingsXeroAccount(xeroAccounts.find((xa: XeroAccount) => xa.xeroAccountID === props.currentTaygSettings.savingsXeroAccountID))
			updateFilteredAccounts({
				gstFilter: xeroAccounts,
				paygFilter: xeroAccounts,
				superFilter: xeroAccounts,
				savingsFilter: xeroAccounts.filter((xa: XeroAccount) => xa.type === 'BANK'),
			})
			if (props.show) {
				props.updatePageStatus('Finished')
			}
		}

		if (props.appState.authState.isLoggedIn && props.currentOrg.xeroOrg && props.currentTaygSettings) {
			fetchData()
		} //eslint-disable-next-line
	}, [props.appState.authState, props.currentOrg, props.currentTaygSettings, props.isGstSetup])

	if (!props.show) {
		return <></>
	}
	if (props.pageStatus !== 'Finished') {
		return (
			<div className="loading-gif">
				<img src={TAYGLoadingImage} alt="Loading ..." />
			</div>
		)
	}

	const handleSettingsSave = async () => {
		try {
			props.updateCurrentTaygSettings({ ...taygSettings })
			await Request.put(`taygsettings/${taygSettings.taygSettingsID}`, { ...taygSettings }, props.appState.authState)

			updateMessages({
				type: 'add',
				data: {
					severity: 'success',
					message: `Successful save of settings`,
					timeout: 3000,
					dismissible: true,
				},
			})
		} catch (err) {
			updateMessages({
				type: 'add',
				data: {
					severity: 'danger',
					message: `Unable to save settings`,
					timeout: 3000,
					dismissible: true,
				},
			})
		}
	}

	const handleNewExtraAccount = async (extraAccount: Partial<TaygSettingsExtraAccount>) => {
		const { xeroAccountID, label, type } = extraAccount
		if (!xeroAccountID) {
			updateMessages({
				type: 'add',
				data: {
					severity: 'danger',
					message: `Xero Account not selected`,
					timeout: 3000,
					dismissible: true,
				},
			})
			return
		}
		if (!label) {
			updateMessages({
				type: 'add',
				data: {
					severity: 'danger',
					message: `Label not entered`,
					timeout: 3000,
					dismissible: true,
				},
			})
			return
		}
		if (!type) {
			updateMessages({
				type: 'add',
				data: {
					severity: 'danger',
					message: `Type not specified`,
					timeout: 3000,
					dismissible: true,
				},
			})
			return
		}
		if (
			(taygSettings.extraAccounts && taygSettings.extraAccounts.length > 9) ||
			(type === 'asset' && assetExtraAccounts.length > 4) ||
			(type === 'liability' && liabilityExtraAccounts.length > 4)
		) {
			updateMessages({
				type: 'add',
				data: {
					severity: 'danger',
					message: `Cannot have more than 5 other ${type === 'asset' ? 'assets' : 'liabilities'}`,
					timeout: 3000,
					dismissible: true,
				},
			})
		} else {
			const newExtraAccount = { taygSettingsExtraAccountID: uuidv4(), label, xeroAccountID, type }
			updateTaygSettings({
				...taygSettings,
				extraAccounts: taygSettings.extraAccounts ? taygSettings.extraAccounts.concat([newExtraAccount]) : [newExtraAccount],
			})
		}
	}
	const handleDeleteExtraAccount = (id: string) => {
		updateTaygSettings({
			...taygSettings,
			extraAccounts: taygSettings.extraAccounts ? taygSettings.extraAccounts.filter((ea) => ea.taygSettingsExtraAccountID !== id) : [],
		})
	}
	const handleEditExtraAccount = (id: string, newLabel: string) => {
		updateTaygSettings({
			...taygSettings,
			extraAccounts: taygSettings.extraAccounts
				? taygSettings.extraAccounts.map((ea) => (ea.taygSettingsExtraAccountID === id ? { ...ea, label: newLabel } : ea))
				: [],
		})
	}
	const assetExtraAccounts = (taygSettings.extraAccounts || [])
		.filter((ea) => ea.type === 'asset')
		.map((ea) => ({ ...ea, AccountName: xeroAccounts.find((xa) => xa.xeroAccountID === ea.xeroAccountID)?.name || 'Unknown Account' }))
		.sort((a, b) => a.AccountName!.localeCompare(b.AccountName!))
	const liabilityExtraAccounts = (taygSettings.extraAccounts || [])
		.filter((ea) => ea.type === 'liability')
		.map((ea) => ({ ...ea, AccountName: xeroAccounts.find((xa) => xa.xeroAccountID === ea.xeroAccountID)?.name || 'Unknown Account' }))
		.sort((a, b) => a.AccountName!.localeCompare(b.AccountName!))

	return (
		<>
			<Card
				style={{
					marginLeft: '150px',
					marginRight: '150px',
					marginTop: '25px',
				}}
			>
				<Card.Body>
					<Row>
						<Col>
							<h4 style={{ textAlign: 'center', paddingBottom: '5px' }}>{props.currentOrg.xeroOrg?.name}</h4>
						</Col>
					</Row>
					<Row>
						<Col md={{ span: 5, offset: 1 }}>
							<div className="tayg-settings-label">Tax Basis:</div>
						</Col>
						<Col md={{ span: 5, offset: 0 }}>
							<DropdownButton
								as={ButtonGroup}
								disabled
								id={`dropdown-basic-button`}
								style={{ marginBottom: '5px' }}
								title={props.currentOrg.xeroOrg?.taxBasis ? `${props.currentOrg.xeroOrg.taxBasis}` : 'None'}
							></DropdownButton>
						</Col>
					</Row>
					<Row style={{ margin: '14px' }}></Row>
					<Card.Body className="tayg-settings-inner-cards">
						<Row>
							<Col>
								<h5 style={{ textAlign: 'left' }}>Current Liabilities</h5>
							</Col>
						</Row>
						{props.isGstSetup ? (
							<>
								<Row>
									<Col sm="12">
										<SettingsDropDown
											selectedItem={gstXeroAccount}
											updateSelectedItem={updateGstXeroAccount}
											updateName={`gstXeroAccountID`}
											selectOptions={[...xeroAccounts]}
											filteredAccounts={filteredAccounts}
											filterBy={'gst'}
											updateFilteredAccounts={updateFilteredAccounts}
											title={'GST Account'}
											updateTaygSettings={updateTaygSettings}
											taygSettings={taygSettings}
											searchText={searchText}
											updateSearchText={updateSearchText}
										/>
									</Col>
								</Row>
								<Row>
									<Col sm="12">
										<SimpleSettingsDropDown
											title={'GST Lodgment Period'}
											selectedItem={gstLodgmentDatePeriod}
											updateSelectedItem={updateGstLodgmentDatePeriod}
											type={'gst'}
											updateName={'gstLodgmentDatePeriod'}
											selectOptions={['Monthly', 'Quarterly', 'Annual']}
											taygSettings={taygSettings}
											updateTaygSettings={updateTaygSettings}
											noSelectOption={true}
										/>
									</Col>
								</Row>
								<Row style={{ margin: '14px' }}></Row>
							</>
						) : null}
						<Row>
							<Col sm="12">
								<SettingsDropDown
									selectedItem={paygXeroAccount}
									updateSelectedItem={updatePaygXeroAccount}
									updateName={`paygPayXeroAccountID`}
									selectOptions={[...xeroAccounts]}
									filteredAccounts={filteredAccounts}
									filterBy={'payg'}
									updateFilteredAccounts={updateFilteredAccounts}
									title={'PAYG Withholdings Account'}
									updateTaygSettings={updateTaygSettings}
									taygSettings={taygSettings}
									searchText={searchText}
									updateSearchText={updateSearchText}
								/>
							</Col>
						</Row>
						<Row>
							<Col sm="12">
								<SimpleSettingsDropDown
									title={'PayG Withholdings Lodgment Period'}
									selectedItem={paygLodgmentDatePeriod}
									updateSelectedItem={updatePaygLodgmentDatePeriod}
									type={'payg'}
									updateName={'paygLodgmentDatePeriod'}
									selectOptions={['Monthly', 'Quarterly']}
									taygSettings={taygSettings}
									updateTaygSettings={updateTaygSettings}
									noSelectOption={true}
								/>
							</Col>
						</Row>
						<Row style={{ margin: '14px' }}></Row>
						<Row>
							<Col sm="12">
								<SettingsDropDown
									selectedItem={superXeroAccount}
									updateSelectedItem={updateSuperXeroAccount}
									updateName={'superPayXeroAccountID'}
									selectOptions={[...xeroAccounts]}
									filteredAccounts={filteredAccounts}
									filterBy={'super'}
									updateFilteredAccounts={updateFilteredAccounts}
									title={'Superannuation Account'}
									updateTaygSettings={updateTaygSettings}
									taygSettings={taygSettings}
									searchText={searchText}
									updateSearchText={updateSearchText}
								/>
							</Col>
						</Row>
						<Row>
							<Col md={{ span: 5, offset: 1 }}>
								<div className="tayg-settings-label">Superannuation Lodgment Period:</div>
							</Col>
							<Col md={{ span: 5, offset: 0 }}>
								<DropdownButton
									as={ButtonGroup}
									disabled
									id={`dropdown-basic-button`}
									style={{ marginBottom: '5px' }}
									title={'Quarterly'}
								></DropdownButton>
							</Col>
						</Row>
					</Card.Body>
					<Row style={{ margin: '14px' }}></Row>
					<Card.Body className="tayg-settings-inner-cards">
						<Row>
							<Col>
								<h5 style={{ textAlign: 'left' }}>PAYG Income Tax</h5>
							</Col>
						</Row>
						<Row>
							<Col md={{ span: 5, offset: 1 }}>
								<div className="tayg-settings-label">PAYG Company Income Tax:</div>
							</Col>
							<Col md={{ span: 5, offset: 0 }}>
								<Form.Control
									type="number"
									step="0.1"
									min="0"
									id={`${props.currentOrg.xeroOrgID!}-paygCompanyIncomeTaxAmount`}
									name="paygCompanyIncomeTaxAmount"
									placeholder=""
									onChange={(event) => {
										const value = Number(event.target.value)
										updatePaygCompanyIncomeTaxAmount(value)
										updateTaygSettings({ ...taygSettings, paygCompanyIncomeTaxAmount: value })
									}}
									value={paygCompanyIncomeTaxAmount || 0}
									style={{ display: 'inline', width: '40%', paddingLeft: '12px', fontSize: '16px', marginBottom: '5px' }}
								/>
								{!paygCompanyIncomeTaxAmount || paygCompanyIncomeTaxAmount === 0 ? <span>{` (enter your quarterly payment)`}</span> : null}
							</Col>
						</Row>
						<Row>
							<Col md={{ span: 5, offset: 1 }}>
								<div className="tayg-settings-label">PAYG Company Income Tax Period:</div>
							</Col>
							<Col md={{ span: 5, offset: 0 }}>
								<DropdownButton
									as={ButtonGroup}
									disabled
									id={`dropdown-basic-button`}
									style={{ marginBottom: '5px' }}
									title={'Quarterly'}
								></DropdownButton>
							</Col>
						</Row>
					</Card.Body>
					<Row style={{ margin: '14px' }}></Row>
					<Card.Body className="tayg-settings-inner-cards">
						<Row>
							<Col>
								<h5 style={{ textAlign: 'left' }}>Assets</h5>
							</Col>
						</Row>
						<Row>
							<Col sm="12">
								<SettingsDropDown
									selectedItem={savingsXeroAccount}
									updateSelectedItem={updateSavingsXeroAccount}
									updateName={'savingsXeroAccountID'}
									selectOptions={[...xeroAccounts.filter((xa: XeroAccount) => xa.type === 'BANK')]}
									filteredAccounts={filteredAccounts}
									filterBy={'savings'}
									updateFilteredAccounts={updateFilteredAccounts}
									title={'TAYG Savings Account'}
									updateTaygSettings={updateTaygSettings}
									taygSettings={taygSettings}
									searchText={searchText}
									updateSearchText={updateSearchText}
									noSelectOption={true}
								/>
							</Col>
						</Row>
						<Row style={{ margin: '14px' }}></Row>
						<ExtraAccounts
							accounts={xeroAccounts.filter(
								(xa) =>
									xa.class === 'ASSET' &&
									(xa.type === 'BANK' || xa.type === 'CURRENT') &&
									!assetExtraAccounts.some((ea) => ea.xeroAccountID === xa.xeroAccountID)
							)}
							extraAccounts={assetExtraAccounts}
							handleAdd={handleNewExtraAccount}
							handleEdit={handleEditExtraAccount}
							handleDelete={handleDeleteExtraAccount}
							type="asset"
						/>
					</Card.Body>
					<Row style={{ margin: '14px' }}></Row>
					<Card.Body className="tayg-settings-inner-cards">
						<Row>
							<Col>
								<h5 style={{ textAlign: 'left' }}>Liabilities</h5>
							</Col>
						</Row>
						<ExtraAccounts
							accounts={xeroAccounts.filter(
								(xa) => xa.class === 'LIABILITY' && !liabilityExtraAccounts.some((ea) => ea.xeroAccountID === xa.xeroAccountID)
							)}
							extraAccounts={liabilityExtraAccounts}
							handleAdd={handleNewExtraAccount}
							handleEdit={handleEditExtraAccount}
							handleDelete={handleDeleteExtraAccount}
							type="liability"
						/>
					</Card.Body>
					<Row>
						<Col md={{ span: 12, offset: 6 }} style={{ paddingTop: '20px' }}>
							<Button onClick={handleSettingsSave} variant={'success'}>
								Save Settings
							</Button>
						</Col>
					</Row>
				</Card.Body>
			</Card>
			<Messages.Messages messages={messages} updateMessage={updateMessages} />
		</>
	)
}

export type { FilteredAccounts, FilterText }
export default SettingsFrame
