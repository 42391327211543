import React from 'react'
import { Badge, Button, Card, Col, Container, Form, OverlayTrigger, Row, Tooltip } from 'react-bootstrap'
import { ArrowLeftCircle } from 'react-feather'
import { Link, useMatch, useNavigate } from 'react-router-dom'
import * as yup from 'yup'

import * as Messages from '../../components/ui/messages/messages'

import * as Request from '../../utilities/request'
import * as I from '../../utilities/me'
import { ThresholdMetricSettings } from '../../components/ui/metricSettings/thresholdMetricSettings'
import {
	ThresholdTemplateFields,
	FormErrors,
	ThresholdMetricGroup,
	ThresholdMetric,
	removePropetyFromObject,
	convertData,
	schemaMetrics,
} from '../../utilities/thresholds'
import { AppContext } from '../../App'

const initialThresholdTemplateData = {
	name: '',
	thresholdTemplateID: '',
	linkedAccountXeroOrgs: 0,
	metrics: {
		overview: [],
		analysis: [],
	},
}

const ThresholdTemplate = () => {
	const { appState } = React.useContext(AppContext)
	const match = useMatch('/threshold/:thresholdTemplateID')
	const navigate = useNavigate()

	const urlThresholdTemplateID = match?.params.thresholdTemplateID || ''

	const [originalData, updateOriginalData] = React.useState<ThresholdTemplateFields>(initialThresholdTemplateData)
	const [thresholdTemplate, updateThresholdTemplate] = React.useState<ThresholdTemplateFields>(initialThresholdTemplateData)
	const [permissions, updatePermissions] = React.useState<any[] | null>(null)
	const [isSubmitting, updateIsSubmitting] = React.useState<boolean>(false)
	const [isLoading, updateIsLoading] = React.useState<boolean>(false)
	const [errors, updateErrors] = React.useState<FormErrors>({})

	const [isNameDisabled, updateNameDisabled] = React.useState<boolean>(true)
	//const [isMetricsDisabled, updateMetricsDisabled] = React.useState<boolean>(true)

	const [messages, updateMessages] = Messages.useMessageReducer([])

	React.useEffect(() => {
		const getThresholdTemplate = async () => {
			updateIsLoading(true)
			if (urlThresholdTemplateID !== 'new') {
				const [req, metricGroups, linkedOrgs] = await Promise.all([
					Request.get(`threshold/${urlThresholdTemplateID}`, appState.authState),
					Request.get(`metricgroup?where=MetricDataType=in(INT,FLOAT)`, appState.authState),
					Request.get(`organisation?where=ThresholdTemplateID==${urlThresholdTemplateID}`, appState.authState),
				])
				const templates = req.data.thresholdTemplates
				const allMetrics = Object.keys(metricGroups.data.metricGroups)
					.map((key) => ({
						[key]: metricGroups.data.metricGroups[key].map((group: ThresholdMetricGroup) => ({
							...group,
							metrics: group.metrics!.map((metric: ThresholdMetric) => {
								const currMetric = templates[0].metrics.find((met: any) => metric.metricID === met.metricID)
								if (currMetric) {
									return {
										...metric,
										goodValue: currMetric!.templateThresholdGood,
										badValue: currMetric!.templateThresholdBad,
									}
								}
								return { ...metric, goodValue: null, badValue: null }
							}),
						})),
					}))
					.reduce((obj, item) => {
						obj[Object.keys(item)[0]] = item[Object.keys(item)[0]]
						return obj
					}, {})

				if (templates.length > 0) {
					const template = {
						name: templates[0].name,
						thresholdTemplateID: templates[0].thresholdTemplateID,
						recordStatusID: templates[0].recordStatusID,
						linkedAccountXeroOrgs: linkedOrgs.data.accountXeroOrgs.length,
						metrics: allMetrics,
					}
					updateThresholdTemplate(template)
					updateOriginalData(template)
				}
			} else {
				const metricGroups = await Request.get(`metricgroup?where=MetricDataType=in(INT,FLOAT)`, appState.authState)
				const allMetrics = Object.keys(metricGroups.data.metricGroups)
					.map((key) => ({
						[key]: metricGroups.data.metricGroups[key].map((group: ThresholdMetricGroup) => ({
							...group,
							metrics: group.metrics!.map((metric: ThresholdMetric) => ({
								...metric,
								goodValue: null,
								badValue: null,
							})),
						})),
					}))
					.reduce((obj, item) => {
						obj[Object.keys(item)[0]] = item[Object.keys(item)[0]]
						return obj
					}, {})
				updateThresholdTemplate({
					...initialThresholdTemplateData,
					metrics: allMetrics,
				})
				updateOriginalData({
					...initialThresholdTemplateData,
					metrics: allMetrics,
				})
			}
			updateIsLoading(false)
		}

		const getPermissions = async () => {
			const req = await Request.get(`permission`, appState.authState)
			updatePermissions(req.data)
		}

		if (appState.authState.isLoggedIn) {
			if (I.have('admin', appState.permission[appState.context])) {
				getThresholdTemplate()
			}
			getPermissions()
		}
	}, [appState, urlThresholdTemplateID])

	const getTitle = (urlMatch: string, name: string) => (urlMatch === 'new' ? 'New Threshold Template' : name)

	const postThresholdTemplate = async () => {
		if (I.have('admin', appState.permission[appState.context])) {
			try {
				if (thresholdTemplate.name.length > 0) {
					removePropetyFromObject(errors, 'name')
					const req = await Request.put(
						`threshold/${urlThresholdTemplateID}`,
						{
							name: thresholdTemplate.name,
							thresholdTemplateID: thresholdTemplate.thresholdTemplateID,
							recordStatusID: thresholdTemplate.recordStatusID,
						},
						appState.authState
					)

					const templates = req.data.thresholdTemplates
					updateOriginalData({
						...originalData,
						name: templates[0].name,
					})
					updateMessages(Messages.addMessage('success', 'Succesfully updated threshold template'))
				} else {
					updateNameDisabled(false)
					updateErrors({ name: 'Name is required.' })
					return
				}
			} catch (err) {
				console.log('Update Error: ', err)
				updateMessages(Messages.addMessage('danger', 'Failed to update threshold template'))
			}
		} else {
			updateMessages(Messages.addMessage('danger', 'You do no have permission to update a threshold template'))
		}
	}
	const resetThresholdTemplate = async () => {
		updateErrors({}) //remove any previous errors
		updateThresholdTemplate(originalData)
	}

	// ---- Validation Schema ---- //
	const schema = yup.object().shape({
		name: yup.string().min(1, 'Name is required.'),
		metrics: yup.object().shape({
			live: yup.array().of(
				yup.object().shape({
					metrics: yup.array().of(schemaMetrics),
				})
			),
		}),
	})

	const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault()
		try {
			try {
				// try validation
				await schema.validate(thresholdTemplate, { abortEarly: false })
			} catch (validationError) {
				// handle errors
				if (validationError instanceof yup.ValidationError) {
					const errors = validationError.inner.map((err) => ({
						field: err.path,
						message: err.errors,
					}))
					const errorObject = errors.reduce(
						(obj: FormErrors, curr) =>
							curr.field
								? {
										...obj,
										[curr.field]: curr.message.join(', '),
								  }
								: obj,
						{}
					)

					// set errors in state
					console.log(errorObject)
					updateErrors(errorObject)
					return
				}
			}
			updateErrors({}) //remove any previous errors
			updateIsSubmitting(true)
			//convert back to db namings
			const allMetrics = Object.keys(thresholdTemplate.metrics)
				.map((key) => ({
					[key]: thresholdTemplate.metrics[key].map((group: ThresholdMetricGroup) => ({
						...group,
						metrics: group.metrics!.map((metric: ThresholdMetric) => ({
							...metric,
							templateThresholdGood: metric.goodValue,
							templateThresholdBad: metric.badValue,
						})),
					})),
				}))
				.reduce((obj, item) => {
					obj[Object.keys(item)[0]] = item[Object.keys(item)[0]]
					return obj
				}, {})
			const reply = await Request.post(
				'threshold',
				{
					name: thresholdTemplate.name,
					metrics: convertData(allMetrics),
				},
				appState.authState
			)
			updateNameDisabled(true)
			//updateMetricsDisabled(true)
			updateMessages(Messages.addMessage('success', 'Succesfully created threshold template'))
			navigate(`/threshold/${reply.data.thresholdTemplates[0].thresholdTemplateID}`)
		} catch (err) {
			console.log('Error on create: ', err)
			updateMessages(Messages.addMessage('danger', 'Unable to create threshold template'))
			updateIsSubmitting(false)
		}
	}

	const handleDeleteThresholdTemplate = async () => {
		if (I.have('admin', appState.permission[appState.context])) {
			try {
				await Request.del(`threshold/${urlThresholdTemplateID}`, appState.authState)
				navigate('/threshold')
			} catch (error) {
				console.log('Error on delete: ', error)
				updateMessages(Messages.addMessage('danger', `Failed to delete threshold template`))
			}
		} else {
			updateMessages(Messages.addMessage('danger', `You do not have permission to delete a threshold template`))
		}
	}

	if (permissions !== null && !isLoading) {
		return (
			<Container>
				<Row>
					<Col style={{ paddingTop: '3px' }}>
						<Link to="/threshold">
							<ArrowLeftCircle /> Back to all threshold templates
						</Link>
					</Col>
				</Row>
				<Card>
					<Row className={'modal-header-row'}>
						<Col xs="auto">
							<h3>{getTitle(urlThresholdTemplateID, `${originalData.name}`)}</h3>
						</Col>
						<Col></Col>
						<Col xs="auto">
							{urlThresholdTemplateID !== 'new' &&
							I.have('admin', appState.permission[appState.context]) &&
							originalData.linkedAccountXeroOrgs === 0 ? (
								<Button type="button" onClick={handleDeleteThresholdTemplate}>
									Delete Threshold Template
								</Button>
							) : urlThresholdTemplateID !== 'new' ? (
								<OverlayTrigger
									placement="auto"
									overlay={
										<Tooltip id={`tooltip-cannot-respond-closed`}>
											{`This template must be fully unlinked for it to be deleted. ${
												originalData.linkedAccountXeroOrgs === 1
													? 'There is 1 organisation'
													: `There are ${originalData.linkedAccountXeroOrgs} organisations`
											} currently linked to it.`}
										</Tooltip>
									}
								>
									<Button type="button">Delete Threshold Template</Button>
								</OverlayTrigger>
							) : null}
						</Col>
					</Row>
					<Row>
						<Col>
							<Form onSubmit={handleSubmit} className="needs-validation">
								<Form.Group controlId="formThresholdTemplateName" as={Row}>
									<Form.Label column sm="2">
										Name
									</Form.Label>
									<Col sm="7">
										<Form.Control
											type="text"
											name="name"
											placeholder="Name"
											readOnly={isNameDisabled && urlThresholdTemplateID !== 'new'}
											plaintext={isNameDisabled && urlThresholdTemplateID !== 'new'}
											onClick={() => I.have('admin', appState.permission[appState.context]) && updateNameDisabled(false)}
											onChange={(event: React.ChangeEvent<any>) => {
												const value = event.target.value
												updateThresholdTemplate({
													...thresholdTemplate,
													name: value,
												})
											}}
											value={thresholdTemplate.name}
											className={!I.have('admin', appState.permission[appState.context]) ? 'no-hover' : ''}
											isInvalid={errors.name ? true : false}
										/>
										<div className="invalid-feedback">Name is required.</div>
									</Col>
									<Col sm="auto">
										{!isNameDisabled && urlThresholdTemplateID !== 'new' ? (
											<Button
												type="button"
												variant="success"
												onClick={() => {
													updateNameDisabled(true)
													postThresholdTemplate()
												}}
											>
												Save
											</Button>
										) : null}
									</Col>
									<Col sm="auto">
										{!isNameDisabled && urlThresholdTemplateID !== 'new' ? (
											<Button
												type="button"
												onClick={() => {
													updateNameDisabled(true)
													resetThresholdTemplate()
												}}
											>
												Cancel
											</Button>
										) : null}
									</Col>
								</Form.Group>
								<Form.Group controlId="formThresholdTemplateName" as={Row}>
									<Form.Label column sm="3" style={{ fontWeight: 'bold' }}>
										Metric / Metric Group
									</Form.Label>
									<Form.Label column sm="2" style={{ textAlign: 'center' }}>
										<Badge className={`badge-diff`} pill variant="success">
											{' '}
										</Badge>
									</Form.Label>
									<Form.Label column sm="2" style={{ textAlign: 'center' }}>
										<Badge className={`badge-diff`} pill variant="warning">
											{' '}
										</Badge>
									</Form.Label>
									<Form.Label column sm="2" style={{ textAlign: 'center' }}>
										<Badge className={`badge-diff`} pill variant="danger">
											{' '}
										</Badge>
									</Form.Label>
								</Form.Group>
								{Object.keys(thresholdTemplate.metrics).map((key) =>
									thresholdTemplate.metrics[key].map((group: ThresholdMetricGroup) => (
										<>
											<Form.Group controlId="formThresholdTemplateName" as={Row}>
												<Form.Label column sm="3" style={{ fontWeight: 'bolder' }}>
													{group.name} ({key})
												</Form.Label>
											</Form.Group>
											{group.metrics!.map((metric: any) => (
												<ThresholdMetricSettings
													key={metric.metricID}
													type="thresholdSettings"
													metric={metric}
													metricsArray={convertData(thresholdTemplate.metrics)}
													updateThresholdTemplate={updateThresholdTemplate}
													thresholdTemplate={thresholdTemplate}
													updateOriginalDataThreshold={updateOriginalData}
													updateMessages={updateMessages}
													reset={resetThresholdTemplate}
													readonly={urlThresholdTemplateID !== 'new'}
													showSideButtons={urlThresholdTemplateID !== 'new'}
													updateErrors={updateErrors}
													appState={appState}
													errorMessage={errors[metric.metricID!]}
												/>
											))}
										</>
									))
								)}
								<Row>
									<Col sm={{ span: 'auto', offset: '3' }}>
										{urlThresholdTemplateID === 'new' ? (
											<Button variant="primary" type="submit" disabled={isSubmitting}>
												Create Threshold Template
											</Button>
										) : null}
									</Col>
								</Row>
							</Form>
						</Col>
					</Row>
				</Card>
				<Messages.Messages messages={messages} updateMessage={updateMessages} />
			</Container>
		)
	} else if (thresholdTemplate === null) {
		return (
			<Container className="oversight-container">
				<Row>
					<Col></Col>
					<Col>
						<p>You do not have permission to view this page.</p>
					</Col>
					<Col></Col>
				</Row>
			</Container>
		)
	} else {
		return <div></div>
	}
}

export default ThresholdTemplate
