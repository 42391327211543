import React from 'react'
// components
import { Badge, Tooltip, OverlayTrigger, Row, Col, Button } from 'react-bootstrap'
import { Table, TableColumn } from '../../ui/table/table'

// definitions
import * as AppDefinitions from '../../../App.d'
import { StripeInvoice } from '../../../../../back-end/utilities/apiDefinitions'
import { HistoryView, RetryBody } from '../../../screens/subsync/subsync'

// utilities
import { date, time, properCase, currency } from '../../../utilities/format'
import { RefreshCw } from 'react-feather'

// helper functions
import * as functions from './functions'

interface OverviewComponentFrameProps {
	show: boolean
	appState: AppDefinitions.AppState
	stripeFeesBillsEnabled: boolean
	creditNotesEnabled: boolean
	historyView: HistoryView
	updateHistoryView: (historyView: HistoryView) => void
	refreshData: () => void
	retryFailedTransaction: (body: RetryBody) => void
}

const OverviewComponent = (props: OverviewComponentFrameProps) => {
	if (props.show) {
		return (
			<div style={{ marginTop: '10px' }}>
				<Row>
					<Col style={{ display: 'flex' }}>
						<div className="settings-label">Event History</div>
						<Col sm="auto" style={{ margin: '13px 0 0 -20px' }}>
							<OverlayTrigger placement="auto" overlay={<Tooltip id={`tooltip-remove-connection`}>Refresh</Tooltip>}>
								<Button type="submit" style={{ border: '0px' }} onClick={() => props.refreshData()}>
									<RefreshCw />
								</Button>
							</OverlayTrigger>
						</Col>
					</Col>
				</Row>
				<Table<StripeInvoice>
					name="Event"
					namePlural="Events"
					list={props.historyView.events}
					getIDFunc={(item) => item.stripeInvoiceID!}
					innerTableClassName="subsync-table"
					individualActions={(id) => functions.getOverflowMenuOptions(id, props)}
					columns={(
						[
							{
								value: (item) => item.events![0].createTs!,
								render: (item) => <>{`${date(item.events![0].createTs!)} ${time(item.events![0].createTs!)}`}</>,
								showHeader: true,
								headerText: 'Time',
								sortColumnName: 'createTs',
								filterType: 'dateRange',
								filterOptions: {
									columnName: 'createTs',
								},
							},
							{
								value: (item) => functions.getOriginalInvoiceEvent(item.events)?.stripeInvoiceNumber,
								render: (item) => <p>{functions.getOriginalInvoiceEvent(item.events)?.stripeInvoiceNumber}</p>,
								showHeader: true,
								headerText: 'Invoice',
								sortColumnName: 'stripeInvoice.number',
								filterType: 'string',
								filterOptions: {
									columnName: 'stripeInvoice.number',
								},
							},
							{
								value: (item) => item.invoicee!,
								render: (item) => <p>{item.invoicee!}</p>,
								showHeader: true,
								headerText: 'Contact',
								sortColumnName: 'invoicee',
								filterType: 'string',
								filterOptions: {
									columnName: 'invoicee',
								},
							},
							{
								value: (item) => item.events![0].type!,
								render: (item) => <p>{properCase(item.events![0].type!.split('.')[0])}</p>,
								showHeader: true,
								headerText: 'Action',
								sortColumnName: 'type',
								filterType: 'dropdown',
								filterOptions: {
									columnName: 'type',
									options: [
										{ value: 'created', text: 'Created' },
										{ value: 'paid', text: 'Paid' },
										{ value: 'updated', text: 'Updated' },
										{ value: 'voided', text: 'Voided' },
									],
								},
							},
							{
								value: (item) => functions.getOriginalInvoiceEvent(item.events)?.stripeInvoiceAmount?.toString(),
								render: (item) => {
									const originalEvent = functions.getOriginalInvoiceEvent(item.events)
									return originalEvent ? (
										originalEvent.result !== 'success' ? (
											<OverlayTrigger
												placement={'left'}
												overlay={<Tooltip id={`${originalEvent.subSyncEventID}-tooltip`}>{originalEvent.message}</Tooltip>}
											>
												<Badge variant={originalEvent.result === 'error' ? 'danger' : 'warning'} className={'subsync-badge'}>
													{currency(originalEvent.stripeInvoiceAmount, originalEvent.stripeInvoiceCurrency)}
												</Badge>
											</OverlayTrigger>
										) : (
											<Badge variant={'success'} className={'subsync-badge'}>
												{currency(originalEvent.stripeInvoiceAmount, originalEvent.stripeInvoiceCurrency)}
											</Badge>
										)
									) : (
										<></>
									)
								},
								showHeader: true,
								headerText: 'Amount',
								sortColumnName: 'stripeInvoiceAmount',
								filterType: 'string',
								filterOptions: {
									columnName: 'stripeInvoiceAmount',
								},
							},
							{
								value: (item) => item.stripeOrg!.name!,
								render: (item) => <p>{item.stripeOrg!.name!}</p>,
								showHeader: true,
								headerText: 'Stripe',
								sortColumnName: 'stripeOrg.name',
								filterType: 'string',
								filterOptions: {
									columnName: 'stripeOrg.name',
								},
							},
							{
								value: (item) => item.events![0].xeroOrg?.name || '',
								render: (item) => <p>{item.events![0].xeroOrg?.name}</p>,
								showHeader: true,
								headerText: 'Xero',
								sortColumnName: 'xeroOrg.name',
								filterType: 'string',
								filterOptions: {
									columnName: 'xeroOrg.name',
								},
							},
						] as TableColumn<StripeInvoice>[]
					).concat(
						props.stripeFeesBillsEnabled
							? [
									{
										value: (item) => (functions.getPaidInvoiceEvent(item.events)?.stripeInvoiceAmount || '').toString(),
										render: (item) => {
											const paidEvent = functions.getPaidInvoiceEvent(item.events)
											return paidEvent ? (
												paidEvent.result !== 'success' ? (
													<OverlayTrigger
														placement={'left'}
														overlay={<Tooltip id={`${paidEvent.subSyncEventID}-fees-tooltip`}>{paidEvent.message}</Tooltip>}
													>
														<Badge variant={paidEvent.result === 'error' ? 'danger' : 'warning'} className={'subsync-badge'}>
															{currency(paidEvent.stripeInvoiceAmount, paidEvent.stripeInvoiceCurrency)}
														</Badge>
													</OverlayTrigger>
												) : (
													<Badge variant={'success'} className={'subsync-badge'}>
														{currency(paidEvent.stripeInvoiceAmount, paidEvent.stripeInvoiceCurrency)}
													</Badge>
												)
											) : (
												<></>
											)
										},
										showHeader: true,
										headerText: 'Stripe Fee',
										sortColumnName: 'stripeInvoiceAmount',
										filterType: 'string',
										filterOptions: {
											columnName: 'stripeInvoiceAmount',
										},
									},
							  ]
							: []
					)}
					defaultSort={{ column: 'createTs', order: 'DSC' }}
					isLoading={false}
				/>
			</div>
		)
	}
	return null
}

export default OverviewComponent
export type { OverviewComponentFrameProps }
