import React from 'react'
import { Col, Row, Modal, Form, FormGroup, OverlayTrigger, Button, Tooltip } from 'react-bootstrap'
import { Trash2 } from 'react-feather'

interface LabelPopUpProps {
	show: boolean
	handleClose: () => void
	currentLabel: string
	handleEdit: (id: string, newLabel: string) => void
	handleDelete: (id: string) => void
	id: string
}

const LabelPopUp = (props: LabelPopUpProps) => {
	const [label, updateLabel] = React.useState<string>(props.currentLabel)

	return (
		<Modal
			show={props.show}
			aria-labelledby="modal"
			centered
			onHide={() => {
				updateLabel(props.currentLabel)
				props.handleClose()
			}}
		>
			<Modal.Header>
				<Modal.Title>Edit</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<FormGroup>
					<Row style={{ paddingLeft: '16px' }}>
						<Form.Control
							type="text"
							style={{ width: '85%' }}
							value={label}
							onChange={(e) => {
								const value = e.target.value
								updateLabel(value)
							}}
						/>
						<Col sm="2" style={{ flex: '1', textAlign: 'right' }}>
							<OverlayTrigger placement="auto" overlay={<Tooltip id={`tooltip-remove-extra-account`}>Delete extra Account</Tooltip>}>
								<Trash2
									style={{
										cursor: 'pointer',
										paddingTop: '5px',
										width: '30px',
										height: '30px',
										textAlign: 'right',
									}}
									onClick={() => {
										props.handleDelete(props.id)
										props.handleClose()
									}}
								/>
							</OverlayTrigger>
						</Col>
					</Row>
					<Row style={{ padding: '5px' }}></Row>
					<Button
						variant={'success'}
						style={{ marginRight: '15px' }}
						onClick={() => {
							props.handleClose()
							props.handleEdit(props.id, label)
						}}
					>
						Save
					</Button>
					<Button
						variant={'secondary'}
						onClick={() => {
							updateLabel(props.currentLabel)
							props.handleClose()
						}}
					>
						Cancel
					</Button>
				</FormGroup>
			</Modal.Body>
		</Modal>
	)
}

export { LabelPopUp }
