import React from 'react'
import { Col, Container, Form, Row, Tabs, Tab, Pagination } from 'react-bootstrap'
import { Copy } from 'react-feather'
import { Navigate } from 'react-router-dom'

import * as Messages from '../../components/ui/messages/messages'

import AccountTab, { Account } from './account'
import ClientTab, { Client } from './clients'
import SubTab, { Sub } from './subs'

import * as AppDefinitions from '../../App.d'
import * as Request from '../../utilities/request'
import * as RecordStatus from '../../types/recordStatus'
import { XeroOrg } from '../../../../back-end/common/xeroOrg'
import { AppContext } from '../../App'

const FormatID = (props: { id: string }) =>
	!props.id || props.id === 'xxx' ? null : (
		<>
			{props.id.substr(0, 4)} ... {props.id.substr(-4)}
			<Copy onClick={() => navigator.clipboard.writeText(props.id)} style={{ marginLeft: '5px' }} />
		</>
	)

// eslint-disable-next-line
type CallbackFunctionVariadic = (...args: any[]) => void

interface PagesWrapperProps<T> {
	data: T
	setData: React.Dispatch<React.SetStateAction<T>>
	isLoading: boolean
	filter: string
	page: number
	setPage: React.Dispatch<React.SetStateAction<number>>
	pageSize: number
	setPageSize: React.Dispatch<React.SetStateAction<number>>
	setFilter: React.Dispatch<React.SetStateAction<string>>
	child: (props: PagesWrapperChildProps<T>) => JSX.Element
	actions?: { [name: string]: CallbackFunctionVariadic }
	appState: AppDefinitions.AppState
	setTab: React.Dispatch<React.SetStateAction<string>>
	focusOn: {
		id: string
		setId: React.Dispatch<React.SetStateAction<string>>
	}
}

interface PagesWrapperChildProps<T> {
	data: T
	setData: React.Dispatch<React.SetStateAction<T>>
	isLoading: boolean
	filter: string
	setFilter: React.Dispatch<React.SetStateAction<string>>
	pageSize: number
	setPageSize: React.Dispatch<React.SetStateAction<number>>
	page: number
	setPage: React.Dispatch<React.SetStateAction<number>>
	pageElements: JSX.Element
	actions?: { [name: string]: CallbackFunctionVariadic }
	appState: AppDefinitions.AppState
	setTab: React.Dispatch<React.SetStateAction<string>>
	focusOn: {
		id: string
		setId: React.Dispatch<React.SetStateAction<string>>
	}
}

const PagesWrapper = <T,>(props: PagesWrapperProps<T>) => {
	const WrappedTab = props.child

	return (
		<WrappedTab
			{...props}
			appState={props.appState}
			pageSize={props.pageSize}
			setPageSize={props.setPageSize}
			page={props.page}
			setTab={props.setTab}
			setPage={props.setPage}
			pageElements={
				<>
					<Col>
						<Form>
							<Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
								<Form.Label column sm={2}>
									Filter
								</Form.Label>
								<Col sm={10}>
									<Form.Control
										type="text"
										placeholder="id, org name, user..."
										value={props.filter}
										onChange={(e) => props.setFilter(e.target.value.toLocaleLowerCase())}
									/>
								</Col>
							</Form.Group>
						</Form>
					</Col>
					<Col>
						<Form>
							<Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
								<Form.Label column sm={2}>
									Page Size
								</Form.Label>
								<Col sm={10}>
									<Form.Control
										type="number"
										value={props.pageSize}
										min={1}
										onChange={(e) => props.setPageSize(Number(e.target.value) < 1 ? 1 : Number(e.target.value))}
									/>
								</Col>
							</Form.Group>
						</Form>
					</Col>
					<Col>
						<Pagination>
							{Array.from({
								// eslint-disable-next-line
								length: Math.ceil((props.data as unknown as any[]).length / (props.pageSize < 1 ? 1 : props.pageSize)),
							}).map((_a, index) => (
								<Pagination.Item key={index} active={index + 1 === props.page} onClick={() => props.setPage(index + 1)}>
									{index + 1}
								</Pagination.Item>
							))}
						</Pagination>
					</Col>
				</>
			}
		/>
	)
}

const Admin = () => {
	const { appState } = React.useContext(AppContext)
	const [messages, updateMessages] = Messages.useMessageReducer([])
	const [isLoading, setIsLoading] = React.useState<boolean>(true)
	const [clients, setClients] = React.useState<Client[]>([])
	const [subs, setSubs] = React.useState<Sub[]>([])
	const [accounts, setAccounts] = React.useState<Account[]>([])
	const [filter, setFilter] = React.useState<string>('')
	const [activeTab, setActiveTab] = React.useState<string>('clients')
	const [selection, setSelection] = React.useState<string>('')
	const [page, setPage] = React.useState<number>(1)
	const [pageSize, setPageSize] = React.useState<number>(20)

	React.useEffect(() => {
		const getData = async () => {
			const [reqClients, reqSubs, reqAccounts, reqInvoicePerAcc] = await Promise.all([
				Request.get(`admin/clients`, appState.authState),
				Request.get(`admin/subs`, appState.authState),
				Request.get(`admin/accounts`, appState.authState),
				Request.get(`admin/accounts/invoices`, appState.authState),
			])
			setAccounts(
				reqAccounts.data.accounts.map((a: Account) => {
					const invCount = reqInvoicePerAcc.data.invoiceCounts.find((ic: { accountID: string }) => ic.accountID === a.accountID)
					return {
						...a,
						invoiceCount: invCount ? invCount.invoiceCount : null,
					}
				})
			)

			setClients(
				reqClients.data.clients.map((xo: XeroOrg) => {
					return {
						xeroOrgID: xo.xeroOrgID,
						name: xo.name,
						createTs: xo.createTs,
						products: xo.products,
						version: xo.version,
						legalName: xo.legalName,
						lastSync: xo.leaveCalLastEmployeeDataFetch || xo.leaveCalLastDataFetch,
						active: xo.recordStatusID === RecordStatus.Active ? true : false,
						numberOfEmployees: xo.numEmployees,
						connectionID: xo.connectionID,
					}
				})
			)
			setSubs(reqSubs.data.subs)
			setIsLoading(false)
		}

		if (appState.authState.isLoggedIn) {
			getData()
		}
	}, [appState.authState])

	if (Number(appState?.authState?.attributes?.['custom:isAdmin']) !== 1) {
		return <Navigate to="/" />
	}

	const deleteAccount = async (accountID: string) => {
		await Request.del(`admin/account/${accountID}`, appState.authState)
		setAccounts(accounts.filter((acc) => acc.accountID !== accountID))
		updateMessages({
			type: 'add',
			data: {
				severity: 'success',
				message: 'Deleted Account',
				timeout: 3000,
				dismissible: true,
			},
		})
	}

	const setAccountActivity = async (accountID: string, isActive: boolean) => {
		await Request.put(`admin/account/${accountID}`, { accountActive: isActive }, appState.authState)
		setAccounts(accounts.map((acc) => (acc.accountID === accountID ? { ...acc, accountActive: isActive } : acc)))
		updateMessages({
			type: 'add',
			data: {
				severity: 'success',
				message: 'Updated Account',
				timeout: 3000,
				dismissible: true,
			},
		})
	}

	const filteredAccounts = accounts.filter(
		(a: Account) =>
			a.accountID.toLocaleLowerCase().includes(filter) ||
			a.name.toLocaleLowerCase().includes(filter) ||
			a.owner!.firstName!.toLocaleLowerCase().includes(filter) ||
			a.owner!.lastName!.toLocaleLowerCase().includes(filter) ||
			'active'.includes(filter) ||
			a.owner!.email!.toLocaleLowerCase().includes(filter)
	)

	const filteredClients = clients.filter(
		(xo: Client) =>
			xo.xeroOrgID!.toLocaleLowerCase().includes(filter) ||
			xo.name!.toLocaleLowerCase().includes(filter) ||
			xo.createTs!.toLocaleLowerCase().includes(filter) ||
			(xo.version ? xo.version.toLocaleLowerCase().includes(filter) : false) ||
			(xo.legalName ? xo.legalName.toLocaleLowerCase().includes(filter) : false) ||
			'active'.includes(filter) ||
			xo.numberOfEmployees.toString().includes(filter)
	)

	const filteredSubs = subs.filter(
		(xo: Sub) =>
			xo.xeroOrgProductID!.toLocaleLowerCase().includes(filter) ||
			xo.productName!.toLocaleLowerCase().includes(filter) ||
			xo.xeroOrgName!.toLocaleLowerCase().includes(filter) ||
			xo.xeroOrgID!.toLocaleLowerCase().includes(filter) ||
			xo.createTs!.toLocaleLowerCase().includes(filter) ||
			'active'.includes(filter) ||
			xo.modifiedTs.toString().includes(filter)
	)

	React.useEffect(() => {
		const getSelectionPage = () => {
			const isClientIDIndex = filteredClients.map((d) => d.xeroOrgID).findIndex((el) => el === selection)
			const isAccountIDIndex = filteredAccounts.map((d) => d.accountID).findIndex((el) => el === selection)
			const indexOfFocus = isClientIDIndex !== -1 ? isClientIDIndex : isAccountIDIndex !== -1 ? isAccountIDIndex : -1
			console.log(isClientIDIndex, isAccountIDIndex, indexOfFocus, pageSize, Math.ceil(indexOfFocus / pageSize))
			if (indexOfFocus !== -1) {
				setPage(Math.ceil(indexOfFocus / pageSize) > 0 ? Math.ceil(indexOfFocus / pageSize) : 1)
			}
		}
		if (selection) {
			getSelectionPage()
		}
	}, [selection, page]) //, setPage, filteredClients, filteredAccounts, pageSize

	return (
		<Container className="oversight-container" style={{ maxWidth: '100%' }}>
			<Row>
				<Col sm="auto">
					<h2>Admin Management Tool</h2>
				</Col>
			</Row>

			<Tabs
				activeKey={activeTab}
				onClick={(e: { target: { id: string } }) => setActiveTab(e.target.id.split('-')[e.target.id.split('-').length - 1])}
				id="uncontrolled-tab-example"
				style={{ marginBottom: '20px' }}
			>
				<Tab eventKey="clients" title="Clients">
					<PagesWrapper
						isLoading={isLoading}
						data={filteredClients}
						setData={setClients}
						filter={filter}
						page={page}
						setPage={setPage}
						pageSize={pageSize}
						setPageSize={setPageSize}
						setFilter={setFilter}
						child={ClientTab}
						setTab={setActiveTab}
						appState={appState}
						focusOn={{ id: selection, setId: setSelection }}
					/>
				</Tab>
				<Tab eventKey="subs" title="Subs">
					<PagesWrapper
						isLoading={isLoading}
						data={filteredSubs}
						setData={setSubs}
						filter={filter}
						page={page}
						setPage={setPage}
						pageSize={pageSize}
						setPageSize={setPageSize}
						setFilter={setFilter}
						child={SubTab}
						setTab={setActiveTab}
						appState={appState}
						focusOn={{ id: selection, setId: setSelection }}
					/>
				</Tab>
				<Tab eventKey="accounts" title="Accounts">
					<PagesWrapper
						isLoading={isLoading}
						data={filteredAccounts}
						setData={setAccounts}
						filter={filter}
						page={page}
						setPage={setPage}
						pageSize={pageSize}
						setPageSize={setPageSize}
						setFilter={setFilter}
						child={AccountTab}
						setTab={setActiveTab}
						appState={appState}
						actions={{ setAccountActivity, deleteAccount }}
						focusOn={{ id: selection, setId: setSelection }}
					/>
				</Tab>
			</Tabs>

			<Messages.Messages messages={messages} updateMessage={updateMessages} />
		</Container>
	)
}

export type { PagesWrapperChildProps }
export { FormatID }
export default Admin
