import React from 'react'
import { Row, FormGroup } from 'react-bootstrap'
import { AccountXeroOrg, Calendar } from '../../../../back-end/utilities/apiDefinitions'

// definitions
import * as AppDefinitions from '../../App.d'

// components
import EmailAlertsFrame from './emails'
import SettingsFrame from './settings'
import SlackAlerts from '../common/slackAlerts'
import OverviewComponent from './overview'
import TAYGLoadingImage from '../../images/TAYG_100px.gif'
import { TaygSettings } from '../../../../back-end/common/taygSettings'

type FrameType = 'emails' | 'slack' | 'settings' | 'overview'
interface FrameProps {
	pageStatus: string
	updatePageStatus: React.Dispatch<React.SetStateAction<string>>
	appState: AppDefinitions.AppState
	currentFrame: FrameType
	currentOrg: AccountXeroOrg | null
	currentCalendar: Calendar
	currentTaygSettings: TaygSettings
	isGstSetup: boolean
	updateCurrentTaygSettings: (e: TaygSettings) => void
	updateModal: () => void
	updateAppState: React.Dispatch<AppDefinitions.AppStateAction>
}

const BodyFrame = (props: FrameProps) => {
	if (props.currentOrg) {
		return (
			<>
				<OverviewComponent
					show={props.currentFrame === 'overview'}
					appState={props.appState}
					currentOrg={props.currentOrg}
					currentTaygSettings={props.currentTaygSettings}
					isGstSetup={props.isGstSetup}
					pageStatus={props.pageStatus}
					updatePageStatus={props.updatePageStatus}
				/>
				<EmailAlertsFrame
					show={props.currentFrame === 'emails'}
					appState={props.appState}
					currentOrg={props.currentOrg}
					currentCalendar={props.currentCalendar}
					pageStatus={props.pageStatus}
					updatePageStatus={props.updatePageStatus}
				/>
				<SlackAlerts
					show={props.currentFrame === 'slack'}
					appState={props.appState}
					currentOrg={props.currentOrg}
					currentCalendar={props.currentCalendar}
					pageStatus={props.pageStatus}
					updatePageStatus={props.updatePageStatus}
					loadingImage={TAYGLoadingImage}
					updateAppState={props.updateAppState}
				/>
				<SettingsFrame
					show={props.currentFrame === 'settings'}
					appState={props.appState}
					currentOrg={props.currentOrg}
					currentTaygSettings={props.currentTaygSettings}
					updateCurrentTaygSettings={props.updateCurrentTaygSettings}
					isGstSetup={props.isGstSetup}
					pageStatus={props.pageStatus}
					updatePageStatus={props.updatePageStatus}
				/>
			</>
		)
	} else {
		if (props.pageStatus === 'Finished') {
			return (
				<>
					<FormGroup style={{ padding: '10px' }}>
						<Row className="calendar-alerts-card"></Row>
					</FormGroup>
				</>
			)
		} else {
			return (
				<div className="loading-gif">
					<img src={TAYGLoadingImage} alt="Loading ..." />
				</div>
			)
		}
	}
}

export type { FrameType }
export default BodyFrame
