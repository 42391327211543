import { ProductContext } from '../App.d'

const getProductFromPort = (port: string): ProductContext => {
	switch (port) {
		case '5003':
			return 'subsync'
		case '5002':
			return 'tayg'
		case '5001':
			return 'leavecal'
		case '5000':
			return 'oversight'
		default:
			throw new Error('Unable to determine product context')
	}
}

const getProductFromXeroEmbeddedGuid = (subdomain: string): ProductContext => {
	switch (subdomain) {
		case '0da3a108-eb5d-48d0-8a28-4546af3b9719': // dev
		case '45607e76-1605-4f2a-85cf-5012327bf0ae': // prod
			return 'subsync'
		default:
			throw new Error('Unable to determine product context')
	}
}

const productContext =
	window.location.hostname.split('.').length === 3 && window.location.hostname.split('.')[1] === 'xero-embedded'
		? getProductFromXeroEmbeddedGuid(window.location.hostname.split('.')[0])
		: window.location.hostname !== 'localhost'
		? (window.location.hostname.split('.')[0].replace('-dev', '') as ProductContext)
		: getProductFromPort(window.location.port)

const productNameFromContext = (context: ProductContext) => {
	switch (context) {
		case 'leavecal':
			return 'LeaveCal'
		case 'oversight':
			return 'Oversight'
		case 'subsync':
			return 'SubSync'
		case 'tayg':
			return 'TAYG'
	}
}

export { productContext, getProductFromPort, productNameFromContext }
