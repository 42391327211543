// definitions
import {
	StripeOrgAccountXeroOrg,
	AccountStripeOrg,
	StripeProduct,
	StripeTaxRate,
	XeroAPIContact,
	StripeOrgAccountXeroOrgMapping,
	StripeRuleOperator,
	StripeRule,
	StripeOrgAccountXeroOrgCustomMappingType,
} from '../../../../../back-end/utilities/apiDefinitions'
import { DetailedStripeOrgAccountXeroOrg } from './settings'

const filterUneditedMappings = (orgMappings: DetailedStripeOrgAccountXeroOrg[]) =>
	orgMappings
		.filter((mapping) => mapping.edited)
		.map((mapping) => ({
			...mapping,
			// these ones we can filter because we never delete on the backend, custom mappings can be deleted so they must all be sent
			stripeProductXeroMappings: mapping.stripeProductXeroMappings?.filter((mapping) => mapping.edited),
			stripeTaxRateXeroTaxRates: mapping.stripeTaxRateXeroTaxRates?.filter((mapping) => mapping.edited),
		}))

const validateMappings = (
	stripeOrgs: (AccountStripeOrg & { products: StripeProduct[]; taxRates: StripeTaxRate[] })[],
	orgMappings: (StripeOrgAccountXeroOrg & { edited?: boolean })[]
) => {
	// not every stripe account must be mapped to a xero account, but if it is, all products must be mapped to xero account/item as per mapping type
	return stripeOrgs
		.filter((stripeOrg) => orgMappings.some((orgMapping) => orgMapping.stripeOrgID === stripeOrg.stripeOrg?.stripeOrgID))
		.every((stripeOrg) => {
			const orgMapping = orgMappings.find((orgMapping) => orgMapping.stripeOrgID === stripeOrg.stripeOrg?.stripeOrgID)!
			return (
				!orgMapping.accountXeroOrgID ||
				(orgMapping.stripeOrgAccountXeroOrgSettings?.zeroPercentXeroTaxRateID &&
					validateProducts(stripeOrg.products, orgMapping) &&
					validateTaxRates(stripeOrg.taxRates, orgMapping) &&
					validateStripeFees(orgMapping) &&
					validateCreditNotes(orgMapping) &&
					validateDiscounts(orgMapping) &&
					validateCustomMappings(orgMapping) &&
					validateInvoicePeriods(orgMapping))
			)
		})
}

const validateProducts = (products: StripeProduct[], orgMapping: StripeOrgAccountXeroOrg) =>
	orgMapping.stripeOrgAccountXeroOrgMappingType === 'Custom' ||
	products.every((product) =>
		orgMapping.stripeProductXeroMappings!.some(
			(productMapping) =>
				productMapping.stripeProductID === product.stripeProductID &&
				productMapping[`xero${orgMapping.stripeOrgAccountXeroOrgMappingType as 'Account' | 'Item'}ID`]
		)
	)

const validateTaxRates = (taxRates: StripeTaxRate[], orgMapping: StripeOrgAccountXeroOrg) =>
	orgMapping.stripeOrgAccountXeroOrgSettings?.taxAsLineItem
		? !!(orgMapping.stripeOrgAccountXeroOrgSettings!.taxXeroAccountID && orgMapping.stripeOrgAccountXeroOrgSettings!.taxXeroTaxRateID)
		: taxRates.every((taxRate) =>
				orgMapping.stripeTaxRateXeroTaxRates!.some((taxRateMapping) => taxRateMapping.stripeTaxRateID === taxRate.stripeTaxRateID)
		  )

const validateStripeFees = (orgMapping: StripeOrgAccountXeroOrg) =>
	!orgMapping.stripeOrgAccountXeroOrgSettings?.stripeFeesBillsEnabled ||
	((orgMapping.stripeOrgAccountXeroOrgSettings.stripeFeesXeroContactID || orgMapping.stripeOrgAccountXeroOrgSettings.stripeFeesXeroContactXeroID) &&
		orgMapping.stripeOrgAccountXeroOrgSettings.stripeFeesXeroAccountID &&
		orgMapping.stripeOrgAccountXeroOrgSettings.stripeFeesXeroTaxRateID)

const validateCreditNotes = (orgMapping: StripeOrgAccountXeroOrg) =>
	!orgMapping.stripeOrgAccountXeroOrgSettings?.creditNotesEnabled || orgMapping.stripeOrgAccountXeroOrgSettings.creditNotesXeroAccountID

const validateDiscounts = (orgMapping: StripeOrgAccountXeroOrg) =>
	!orgMapping.stripeOrgAccountXeroOrgSettings?.discountsAsLineItem || orgMapping.stripeOrgAccountXeroOrgSettings.discountsXeroAccountID

const validateCustomMappings = (orgMapping: StripeOrgAccountXeroOrg) =>
	orgMapping.stripeOrgAccountXeroOrgMappingType !== 'Custom' ||
	((orgMapping.stripeOrgAccountXeroOrgSettings?.customMappingDefaultXeroAccountID ||
		orgMapping.stripeOrgAccountXeroOrgSettings?.customMappingDefaultXeroItemID) &&
		orgMapping.customMappings &&
		orgMapping.customMappings.length > 0 &&
		orgMapping.customMappings.every((customMapping) => customMapping.type && validateStripeRules(customMapping.stripeRules)))

const validateInvoicePeriods = (orgMapping: StripeOrgAccountXeroOrg) =>
	!orgMapping.stripeOrgAccountXeroOrgSettings?.invoiceLineItemDatesEnabled || orgMapping.stripeOrgAccountXeroOrgSettings.invoiceLineItemDateFormat

// Will need to be modified for different types of mappings
const validateStripeRules = (stripeRules: StripeRule[] | undefined) =>
	stripeRules && stripeRules.every((stripeRule) => stripeRule.stripeMappingTypeID && stripeRule.key && stripeRule.operator && stripeRule.value)

const handleOrgAdd = (
	orgMappings: DetailedStripeOrgAccountXeroOrg[],
	updateOrgMappings: React.Dispatch<React.SetStateAction<DetailedStripeOrgAccountXeroOrg[]>>,
	stripeOrgID: string,
	accountXeroOrgID: string
) => {
	const updatedOrgMappings = orgMappings.concat([
		{
			stripeOrgAccountXeroOrgMappingType: 'Account',
			stripeOrgID: stripeOrgID,
			accountXeroOrgID: accountXeroOrgID,
			stripeProductXeroMappings: [],
			stripeTaxRateXeroTaxRates: [],
			customMappings: [],
			edited: true,
		},
	])
	updateOrgMappings(updatedOrgMappings)
}

const handleOrgUpdate = (
	orgMappings: DetailedStripeOrgAccountXeroOrg[],
	updateOrgMappings: React.Dispatch<React.SetStateAction<DetailedStripeOrgAccountXeroOrg[]>>,
	stripeOrgID: string,
	name: string,
	value: string
) => {
	const updatedOrgMappings = orgMappings
		.map((mapping) =>
			mapping.stripeOrgID === stripeOrgID
				? {
						...mapping,
						[name]: value,
						edited: true,
				  }
				: mapping
		)
		.filter((mapping) => mapping.stripeOrgAccountXeroOrgID || mapping.accountXeroOrgID)
	updateOrgMappings(updatedOrgMappings)
}

const handleProductAdd = (
	orgMappings: DetailedStripeOrgAccountXeroOrg[],
	updateOrgMappings: React.Dispatch<React.SetStateAction<DetailedStripeOrgAccountXeroOrg[]>>,
	stripeOrgID: string,
	stripeProductID: string,
	name: string,
	value: string
) => {
	const updatedOrgMappings = orgMappings.map((mapping) =>
		mapping.stripeOrgID === stripeOrgID
			? {
					...mapping,
					stripeProductXeroMappings: mapping.stripeProductXeroMappings!.concat({
						stripeProductID: stripeProductID,
						[name]: value,
						edited: true,
					}),
					edited: true,
			  }
			: mapping
	)
	updateOrgMappings(updatedOrgMappings)
}

const handleProductUpdate = (
	orgMappings: DetailedStripeOrgAccountXeroOrg[],
	updateOrgMappings: React.Dispatch<React.SetStateAction<DetailedStripeOrgAccountXeroOrg[]>>,
	stripeOrgID: string,
	stripeProductID: string,
	name: string,
	value: string
) => {
	const updatedOrgMappings = orgMappings.map((mapping) =>
		mapping.stripeOrgID === stripeOrgID
			? {
					...mapping,
					stripeProductXeroMappings: mapping.stripeProductXeroMappings!.map((productMapping) =>
						productMapping.stripeProductID === stripeProductID
							? {
									...productMapping,
									...(value && name === 'xeroAccountID'
										? {
												xeroAccountID: value,
												xeroItemID: null,
										  }
										: value && name === 'xeroItemID'
										? {
												xeroItemID: value,
												xeroAccountID: null,
										  }
										: {
												xeroAccountID: null,
												xeroItemID: null,
										  }),
									edited: true,
							  }
							: productMapping
					),
					edited: true,
					error: false,
			  }
			: mapping
	)
	updateOrgMappings(updatedOrgMappings)
}

const handleApplyToAllProducts = (
	orgMappings: DetailedStripeOrgAccountXeroOrg[],
	updateOrgMappings: React.Dispatch<React.SetStateAction<DetailedStripeOrgAccountXeroOrg[]>>,
	stripeProducts: StripeProduct[],
	stripeOrgID: string,
	name: string,
	value: string
) => {
	const updatedOrgMappings = orgMappings.map((mapping) =>
		mapping.stripeOrgID === stripeOrgID
			? {
					...mapping,
					stripeProductXeroMappings: stripeProducts.map((product) => {
						const existingMapping = mapping.stripeProductXeroMappings?.find((mapping) => mapping.stripeProductID === product.stripeProductID)
						return existingMapping
							? {
									...existingMapping,
									[name]: value,
									edited: true,
							  }
							: {
									stripeProductID: product.stripeProductID,
									[name]: value,
									edited: true,
							  }
					}),
					edited: true,
			  }
			: mapping
	)
	updateOrgMappings(updatedOrgMappings)
}

const handleTaxRateAdd = (
	orgMappings: DetailedStripeOrgAccountXeroOrg[],
	updateOrgMappings: React.Dispatch<React.SetStateAction<DetailedStripeOrgAccountXeroOrg[]>>,
	stripeOrgID: string,
	stripeTaxRateID: string,
	name: string,
	value: string
) => {
	const updatedOrgMappings = orgMappings.map((mapping) =>
		mapping.stripeOrgID === stripeOrgID
			? {
					...mapping,
					stripeTaxRateXeroTaxRates: mapping.stripeTaxRateXeroTaxRates!.concat({
						stripeTaxRateID: stripeTaxRateID,
						[name]: value,
						edited: true,
					}),
					edited: true,
			  }
			: mapping
	)
	updateOrgMappings(updatedOrgMappings)
}

const handleTaxRateUpdate = (
	orgMappings: DetailedStripeOrgAccountXeroOrg[],
	updateOrgMappings: React.Dispatch<React.SetStateAction<DetailedStripeOrgAccountXeroOrg[]>>,
	stripeOrgID: string,
	stripeTaxRateID: string,
	name: string,
	value: string
) => {
	const updatedOrgMappings = orgMappings.map((mapping) =>
		mapping.stripeOrgID === stripeOrgID
			? {
					...mapping,
					stripeTaxRateXeroTaxRates: mapping.stripeTaxRateXeroTaxRates!.map((taxRateMapping) =>
						taxRateMapping.stripeTaxRateID === stripeTaxRateID
							? {
									...taxRateMapping,
									xeroTaxRateID: value,
									edited: true,
							  }
							: taxRateMapping
					),
					edited: true,
					error: false,
			  }
			: mapping
	)
	updateOrgMappings(updatedOrgMappings)
}

const handleSettingsUpdate = (
	orgMappings: DetailedStripeOrgAccountXeroOrg[],
	updateOrgMappings: React.Dispatch<React.SetStateAction<DetailedStripeOrgAccountXeroOrg[]>>,
	stripeOrgID: string,
	values: { [name: string]: string | boolean | XeroAPIContact | undefined }
) => {
	const updatedOrgMappings = orgMappings.map((mapping) =>
		mapping.stripeOrgID === stripeOrgID
			? {
					...mapping,
					stripeOrgAccountXeroOrgSettings: {
						...mapping.stripeOrgAccountXeroOrgSettings,
						...values,
					},
					edited: true,
					error: false,
			  }
			: mapping
	)
	updateOrgMappings(updatedOrgMappings)
}

const handleCustomMappingUpdate = (
	orgMappings: DetailedStripeOrgAccountXeroOrg[],
	updateOrgMappings: React.Dispatch<React.SetStateAction<DetailedStripeOrgAccountXeroOrg[]>>,
	stripeOrgID: string,
	customMappings: Partial<StripeOrgAccountXeroOrgMapping>[]
) => {
	const updatedOrgMappings = orgMappings.map((mapping) =>
		mapping.stripeOrgID === stripeOrgID
			? {
					...mapping,
					customMappings: customMappings,
					edited: true,
					error: false,
			  }
			: mapping
	)
	updateOrgMappings(updatedOrgMappings)
}
const handleAddMapping = (customMappings: StripeOrgAccountXeroOrgMapping[], types: StripeOrgAccountXeroOrgCustomMappingType[]) => {
	const mappingID = `temp_${Math.random()}`
	const newMapping = {
		stripeOrgAccountXeroOrgMappingID: mappingID,
		type: types[0],
		order: customMappings.filter((customMapping) => types.includes(customMapping.type!)).length,
		stripeRules: [
			{
				stripeOrgAccountXeroOrgMappingID: mappingID,
				stripeRuleID: `temp_${Math.random()}`,
				operator: '=' as StripeRuleOperator,
				edited: true,
			},
		],
		edited: true,
	}
	return newMapping
}

const handleMappingUpdate = (
	customMappings: StripeOrgAccountXeroOrgMapping[],
	stripeOrgAccountXeroOrgMappingID: string,
	values: Partial<StripeOrgAccountXeroOrgMapping>
) => {
	const updatedMappings = customMappings.map((customMapping) =>
		customMapping.stripeOrgAccountXeroOrgMappingID === stripeOrgAccountXeroOrgMappingID
			? {
					...customMapping,
					...values,
					edited: true,
			  }
			: customMapping
	)
	return updatedMappings
}

const handleAddRule = (stripeOrgAccountXeroOrgMappingID: string) => {
	const newRule = {
		stripeOrgAccountXeroOrgMappingID: stripeOrgAccountXeroOrgMappingID,
		stripeRuleID: `temp_${Math.random()}`,
		operator: '=' as StripeRuleOperator,
		edited: true,
	}
	return newRule
}

const handleRuleUpdate = (customMapping: StripeOrgAccountXeroOrgMapping, stripeRuleID: string, values: Partial<StripeRule>) => {
	const updatedRules = customMapping.stripeRules?.map((stripeRule) =>
		stripeRule.stripeRuleID === stripeRuleID
			? {
					...stripeRule,
					...values,
					edited: true,
			  }
			: stripeRule
	)
	return updatedRules
}

// After deletion, pass in -1 as moveToIndex, function will just update order property of remaining elements
const handleReorder = (
	customMappings: StripeOrgAccountXeroOrgMapping[],
	stripeOrgAccountXeroOrgMappingID: string,
	moveToIndex: number,
	types: StripeOrgAccountXeroOrgCustomMappingType[]
) => {
	let elementsToReorder = customMappings.filter((customMapping) => types.includes(customMapping.type!))
	const movedFromIndex =
		moveToIndex >= 0 ? elementsToReorder.findIndex((mapping) => mapping.stripeOrgAccountXeroOrgMappingID === stripeOrgAccountXeroOrgMappingID) : -1
	if (movedFromIndex >= 0) {
		const element = elementsToReorder[movedFromIndex]
		const otherElements = elementsToReorder.filter((customMapping) => customMapping.stripeOrgAccountXeroOrgMappingID !== stripeOrgAccountXeroOrgMappingID)
		const beforeMovedElement = otherElements.slice(0, moveToIndex)
		const afterMovedElement = otherElements.slice(moveToIndex)
		elementsToReorder = beforeMovedElement.concat([element]).concat(afterMovedElement)
	}

	return elementsToReorder
		.map((mapping, index) => ({ ...mapping, order: index } as StripeOrgAccountXeroOrgMapping))
		.concat(customMappings.filter((customMapping) => !types.includes(customMapping.type!)))
}

export {
	filterUneditedMappings,
	validateMappings,
	handleOrgAdd,
	handleOrgUpdate,
	handleProductAdd,
	handleProductUpdate,
	handleApplyToAllProducts,
	handleTaxRateAdd,
	handleTaxRateUpdate,
	handleSettingsUpdate,
	handleCustomMappingUpdate,
	handleAddMapping,
	handleMappingUpdate,
	handleAddRule,
	handleRuleUpdate,
	handleReorder,
}
