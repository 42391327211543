import React from 'react'
import { Form, Row, Col, DropdownButton, Dropdown, OverlayTrigger, Tooltip } from 'react-bootstrap'

// components
import { ArrowRight } from 'react-feather'

// definitions
import { StripeOrgAccountXeroOrg, StripeTaxRate, XeroTaxRate } from '../../../../../../back-end/utilities/apiDefinitions'

interface TaxRateMappingProps {
	stripeTaxRate: StripeTaxRate
	orgMapping: StripeOrgAccountXeroOrg
	xeroTaxRates: XeroTaxRate[]
	stripeOrgId: string
	handleTaxRateUpdate: (stripeOrgID: string, stripeTaxRateID: string, name: string, value: string) => void
	handleTaxRateAdd: (stripeOrgID: string, stripeTaxRateID: string, name: string, value: string) => void
}

const TaxRateMapping = (props: TaxRateMappingProps) => {
	const taxRateMapping = props.orgMapping!.stripeTaxRateXeroTaxRates!.find((mapping) => mapping.stripeTaxRateID === props.stripeTaxRate.stripeTaxRateID)
	const selectedItem = props.xeroTaxRates.find((tr) => tr.xeroTaxRateID === taxRateMapping?.xeroTaxRateID)
	return (
		<Form.Group as={Row} key={props.stripeTaxRate.stripeTaxRateID}>
			<Col
				sm={3}
				style={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'flex-end',
					width: '20vw',
				}}
			>
				{/* show all details for tax rates we fetched after adding new info to db */}
				{props.stripeTaxRate.description ? (
					<OverlayTrigger
						placement={'left'}
						overlay={<Tooltip id={`${props.stripeTaxRate.stripeTaxRateID}-tooltip`}>{props.stripeTaxRate.description}</Tooltip>}
					>
						<div className="subsync-settings-label-small">{getStripeTaxRateInformation(props.stripeTaxRate)}</div>
					</OverlayTrigger>
				) : (
					<div className="subsync-settings-label-small">{getStripeTaxRateInformation(props.stripeTaxRate)}</div>
				)}
			</Col>
			<Col
				sm={1}
				style={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					width: '10vw',
				}}
			>
				<ArrowRight size="16"></ArrowRight>
			</Col>
			<Col
				sm={4}
				style={{
					display: 'flex',
					alignItems: 'center',
					width: '20vw',
				}}
			>
				<DropdownButton
					id={`${props.stripeOrgId}-mapping-taxrate-select`}
					title={taxRateMapping?.xeroTaxRateID === 'N/A' ? 'Not in use' : selectedItem?.name || 'Select Xero Tax Rate'}
					onSelect={(eventKey) =>
						taxRateMapping
							? props.handleTaxRateUpdate(props.stripeOrgId, props.stripeTaxRate.stripeTaxRateID!, 'xeroTaxRateID', eventKey!)
							: props.handleTaxRateAdd(props.stripeOrgId, props.stripeTaxRate.stripeTaxRateID!, 'xeroTaxRateID', eventKey!)
					}
				>
					{props.xeroTaxRates
						.filter(
							(tr) =>
								tr.canApplyToRevenue &&
								(!props.stripeTaxRate.stripeID?.startsWith('txr_') || tr.effectiveRate === props.stripeTaxRate.percentage)
						)
						.map((tr) => (
							<Dropdown.Item key={tr.xeroTaxRateID} eventKey={tr.xeroTaxRateID}>
								{tr.name} {tr.effectiveRate ? `(${(Number(tr.effectiveRate) * 100).toFixed(2)}%)` : ''}
							</Dropdown.Item>
						))}
					<Dropdown.Item eventKey="N/A">Not in use</Dropdown.Item>
				</DropdownButton>
			</Col>
			<Col sm={3} style={{ width: '20vw' }}></Col>
		</Form.Group>
	)
}

const getStripeTaxRateInformation = (stripeTaxRate: StripeTaxRate) => (
	<>
		{stripeTaxRate.name} {stripeTaxRate.state ? `${stripeTaxRate.state}, ` : ''}
		{stripeTaxRate.country}{' '}
		{stripeTaxRate.name !== 'No Stripe Tax Rate' && stripeTaxRate.percentage
			? `(${(Number(stripeTaxRate.percentage) * 100).toFixed(2)}%)${stripeTaxRate.inclusive ? ' inc' : stripeTaxRate.inclusive !== null ? ' exc' : ''}`
			: ''}
	</>
)

export { TaxRateMapping }
