// libraries
import React from 'react'
import { Button, ButtonGroup, Col, Dropdown, DropdownButton, Row, FormLabel } from 'react-bootstrap'

// definitions
import * as AppDefinitions from '../../App.d'
import { AccountXeroOrg, Calendar as CalendarData } from '../../../../back-end/utilities/apiDefinitions'

// components
import CloseButton from '../ui/closeButton/closeButton'

// utilies
import { ModalListChildProps } from '../ui/modalList/modalList'

interface SelectOrgCalPickerProps extends ModalListChildProps {
	appState: AppDefinitions.AppState
	orgs: AccountXeroOrg[]
	cals: CalendarData[]
	currentOrg: AccountXeroOrg | undefined
	currentCal: CalendarData | undefined
	updateCurrentOrg: React.Dispatch<React.SetStateAction<AccountXeroOrg | null>>
	updateCurrentCal: React.Dispatch<React.SetStateAction<CalendarData | null>>
}

const OrgCalPicker = (props: SelectOrgCalPickerProps) => {
	const [selectedOrg, updateSelectedOrg] = React.useState<AccountXeroOrg | undefined>(props.currentOrg)
	const [selectedCal, updateSelectedCal] = React.useState<CalendarData | undefined>(props.currentCal)

	const handleOrgChange = (eventKey: string) => {
		if (props.orgs) {
			const org = props.orgs.find((org) => org.accountXeroOrgID === eventKey)

			if (org) {
				updateSelectedOrg(org)

				const cals = props.cals.filter((c) => c.accountXeroOrgID === org.accountXeroOrgID)
				updateSelectedCal(cals.length > 0 ? cals[0] : undefined)
			}
		}
	}

	const handleCalChange = (eventKey: string) => {
		if (props.cals) {
			const cal = props.cals.find((c) => c.calendarID === eventKey)
			if (cal) {
				updateSelectedCal(cal)
			}
		}
	}

	const handleSave = () => {
		selectedOrg && props.updateCurrentOrg(selectedOrg)
		selectedCal && props.updateCurrentCal(selectedCal)
		props.handleClose()
	}

	const filteredCalendars = props.cals.filter((c) => c.accountXeroOrgID === selectedOrg?.accountXeroOrgID)

	if (props.orgs && props.cals) {
		return (
			<div className="leavecal-popUp">
				<Row
					style={{
						display: 'flex',
						justifyContent: 'flex-end',
						paddingRight: '20px',
					}}
				>
					<Col sm="1" className="position-right">
						<CloseButton onClick={props.handleClose} disabled={false} />
					</Col>
				</Row>
				<Row className="leavecal-wrap-style">
					<Col sm="3" style={{ marginTop: '7px' }}>
						<FormLabel>Organisation:</FormLabel>
					</Col>
					<Col sm="auto">
						<DropdownButton
							as={ButtonGroup}
							id={'dropdown'}
							title={selectedOrg?.xeroOrg?.name || 'None'}
							variant={'primary'}
							onSelect={(eventKey: any) => handleOrgChange(eventKey)}
							alignRight
						>
							{props.orgs.length > 0 ? (
								props.orgs.map((org) => (
									<Dropdown.Item
										key={org.accountXeroOrgID}
										eventKey={org.accountXeroOrgID}
										active={org.accountXeroOrgID === props.currentOrg?.accountXeroOrgID}
									>
										{org.xeroOrg?.name}
									</Dropdown.Item>
								))
							) : (
								<Dropdown.Item key={''} eventKey={''} active={true}>
									None
								</Dropdown.Item>
							)}
						</DropdownButton>
					</Col>
				</Row>
				<Row className="leavecal-wrap-style">
					<Col sm="3">
						<FormLabel style={{ marginTop: '7px' }}>Calendar:</FormLabel>
					</Col>
					<Col sm="auto">
						<DropdownButton
							as={ButtonGroup}
							id={'dropdown'}
							title={selectedCal?.fileName || 'None'}
							variant={'primary'}
							onSelect={(eventKey: any) => handleCalChange(eventKey)}
							alignRight
						>
							{filteredCalendars.length > 0 ? (
								filteredCalendars.map((cal) => (
									<Dropdown.Item key={cal.calendarID} eventKey={cal.calendarID} active={cal.calendarID === props.currentCal?.calendarID}>
										{cal.fileName}
									</Dropdown.Item>
								))
							) : (
								<Dropdown.Item key={''} eventKey={''} active={true}>
									None
								</Dropdown.Item>
							)}
						</DropdownButton>
					</Col>
				</Row>
				<Row className="leavecal-wrap-style">
					<Col sm="auto">
						<Button type={'submit'} variant={'success'} onClick={() => handleSave()}>
							OK
						</Button>
					</Col>
					<Col sm="auto">
						<Button onClick={() => props.handleClose()}>Cancel</Button>
					</Col>
				</Row>
				<Row className="leavecal-wrap-style"></Row>
			</div>
		)
	} else {
		return <></>
	}
}

export default OrgCalPicker
