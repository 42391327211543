import React from 'react'
import { Form, Row, Col, DropdownButton, Dropdown } from 'react-bootstrap'

// definitions
import { AccountXeroOrgWithData } from '../../../../screens/subsync/subsync'
import { StripeOrgAccountXeroOrg, XeroAPIContact } from '../../../../../../back-end/utilities/apiDefinitions'

interface GeneralSettingsProps {
	orgMapping: StripeOrgAccountXeroOrg | undefined
	stripeOrgID: string
	mappedXeroOrg: AccountXeroOrgWithData | undefined
	handleOrgUpdate: (stripeOrgID: string, name: string, value: string) => void
	handleSettingsUpdate: (stripeOrgID: string, values: { [name: string]: string | boolean | XeroAPIContact | undefined }) => void
}

const GeneralSettings = (props: GeneralSettingsProps) => {
	return (
		<>
			<Form.Group as={Row} style={{ height: '2.5rem' }}>
				<Col>
					<Form.Check
						type={'checkbox'}
						id={`${props.stripeOrgID}-create-zero-invoices-disabled`}
						name={'createZeroAmountInvoicesDisabled'}
						label={"Don't create invoices in Xero when invoice amount is $0"}
						checked={!!props.orgMapping?.stripeOrgAccountXeroOrgSettings?.createZeroAmountInvoicesDisabled}
						onChange={(e) => props.handleSettingsUpdate(props.stripeOrgID, { [e.target.name]: e.target.checked })}
					/>
				</Col>
			</Form.Group>
			<Form.Group as={Row}>
				<Col>Choose a 0.00% Xero tax rate to use when Stripe does not calculate tax (e.g. in unsupported jurisdictions):</Col>
			</Form.Group>
			<Form.Group as={Row} style={{ height: '2.5rem' }}>
				<Form.Label column sm={2}>
					Xero Tax Rate
				</Form.Label>
				<Col sm={4}>
					<DropdownButton
						id={`${props.stripeOrgID}-zero-percent-tax-rate-select`}
						title={
							props.mappedXeroOrg!.taxRates.find(
								(tr) => tr.xeroTaxRateID === props.orgMapping?.stripeOrgAccountXeroOrgSettings?.zeroPercentXeroTaxRateID
							)?.name || 'Select Xero Tax Rate'
						}
						onSelect={(eventKey) => props.handleSettingsUpdate(props.stripeOrgID, { zeroPercentXeroTaxRateID: eventKey! })}
					>
						{props
							.mappedXeroOrg!.taxRates.filter((tr) => tr.canApplyToRevenue && Number(tr.effectiveRate) === 0)
							.map((taxRate) => (
								<Dropdown.Item key={taxRate.xeroTaxRateID} eventKey={taxRate.xeroTaxRateID}>
									{taxRate.name} {taxRate.effectiveRate ? `(${(Number(taxRate.effectiveRate) * 100).toFixed(2)}%)` : ''}
								</Dropdown.Item>
							))}
					</DropdownButton>
				</Col>
			</Form.Group>
		</>
	)
}

export { GeneralSettings }
