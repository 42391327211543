import React from 'react'
import { Button, ButtonProps } from 'react-bootstrap'
import { Plus } from 'react-feather'

interface PlusButtonProps extends ButtonProps {
	onClick: () => void
}

const PlusButton = (props: PlusButtonProps) => (
	<Button className="circle" variant={'info'} onClick={props.onClick}>
		<Plus />
	</Button>
)

export default PlusButton
