import React from 'react'
import { Card, Col, Row, Table, Form } from 'react-bootstrap'
import MetricValue from '../../components/metric/value'

const OrgOverview = (props: any) => {
	const [xeroOrgMetrics, updateXeroOrgMetrics] = React.useState<null | any[]>(null)

	React.useEffect(() => {
		const getXeroOrgs = async () => {
			updateXeroOrgMetrics(props.accountXeroOrgMetrics)
		}

		getXeroOrgs()
	}, [props.accountXeroOrgMetrics])

	return xeroOrgMetrics ? (
		<Row>
			{xeroOrgMetrics.map((orgGroup: any, index: number) => {
				return (
					<Col xs="12" sm="6" md="4" key={index}>
						<Card title={orgGroup.name}>
							<Table borderless responsive>
								<thead>
									<tr className="header-row" key={''}>
										<th colSpan={orgGroup.name}>{orgGroup.name} </th>
									</tr>
								</thead>
								<tbody>
									{orgGroup.metrics
										.map((metric: any) => props.filteredMetrics.find((detailedMetric: any) => metric.metricID === detailedMetric.metricID))
										.filter((metric: any) => metric)
										.map((metric: any, index: any) => (
											<tr key={index} className={`stripe-row ${index % 2 === 0 ? 'stripe-row-body' : ''}`}>
												<td>
													<Form.Label column> {metric.name}</Form.Label>
												</td>
												<MetricValue metric={metric} />
											</tr>
										))}
								</tbody>
							</Table>
						</Card>
					</Col>
				)
			})}
		</Row>
	) : null
}

export { OrgOverview }
