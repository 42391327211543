import React from 'react'
import { XeroOrg } from '../../../../back-end/utilities/apiDefinitions'
import { ButtonGroup, Dropdown, DropdownButton } from 'react-bootstrap'

interface SubscriptionItemProps {
	disable: boolean
	productCode: string
	selectedXeroOrg?: XeroOrg
}

export const SubscriptionItem = (props: SubscriptionItemProps) => {
	const hasStripeProduct = props.selectedXeroOrg?.products?.some((product) => product.isStripe)

	if (hasStripeProduct) {
		return (
			<Dropdown.Item target="_blank" href={`https://subscriptions.finlert.com/p/login/00gg2TcBq5lXbK05kk`}>
				Manage Subscriptions
			</Dropdown.Item>
		)
	}

	const deepLink = props.selectedXeroOrg ? `${props.selectedXeroOrg.shortCode}/${props.selectedXeroOrg.version}/` : ''

	return (
		<DropdownButton as={ButtonGroup} key="left" id="dropdown-button-drop-sub" drop="left" variant="secondary" title="Manage Subscriptions">
			<Dropdown.Item target="_blank" href={`https://apps.xero.com/${deepLink}subscribe/${props.productCode}`} disabled={props.disable}>
				Change Plan
			</Dropdown.Item>
			<Dropdown.Item target="_blank" href={`https://apps.xero.com/${deepLink}my-apps/${props.productCode}`} disabled={props.disable}>
				Billing History &amp; Cancellation
			</Dropdown.Item>
			<Dropdown.Item target="_blank" href={`https://apps.xero.com/${deepLink}connected`} disabled={props.disable}>
				Payment Details
			</Dropdown.Item>
		</DropdownButton>
	)
}
