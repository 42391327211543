import React from 'react'
import { TaygSettings } from '../../../../back-end/utilities/apiDefinitions'
import { Col, Dropdown, ListGroup, Row } from 'react-bootstrap'

interface SimpleSettingsDropDownProps {
	title: string
	selectedItem: string | null
	updateSelectedItem: (e: string | null) => void
	type: string
	updateName: string
	selectOptions: string[]
	taygSettings: TaygSettings
	updateTaygSettings: (e: TaygSettings) => void
	noSelectOption?: boolean
}

const SimpleSettingsDropDown = (props: SimpleSettingsDropDownProps) => {
	const [show, updateShow] = React.useState<boolean>(false)
	return (
		<Row>
			<Col md={{ span: 5, offset: 1 }}>
				<div className="tayg-settings-label">{props.title}:</div>
			</Col>
			<Col md={{ span: 5, offset: 0 }}>
				<Dropdown style={{ marginBottom: '5px' }} onToggle={() => updateShow(!show)} show={show}>
					<Dropdown.Toggle id={props.selectedItem ? props.selectedItem : 'Pick a period'}>
						{props.selectedItem ? props.selectedItem : 'Pick a period'}
					</Dropdown.Toggle>

					<Dropdown.Menu className={'tayg-settings-dropdown'}>
						<ListGroup>
							{props.selectOptions && props.selectOptions.length > 0
								? props.selectOptions.map((item: string) => {
										return (
											<ListGroup.Item
												className={'list-group-hover tayg-settings-select-option'}
												key={`${props.type}-${item}`}
												onClick={() => {
													props.updateSelectedItem(item)
													props.updateTaygSettings({
														...props.taygSettings,
														[props.updateName]: item,
													})
													updateShow(!show)
												}}
											>
												{item}
											</ListGroup.Item>
										)
								  })
								: null}
							{props.noSelectOption && props.selectedItem ? (
								<ListGroup.Item
									className={'list-group-hover tayg-settings-select-option'}
									key={0}
									onClick={() => {
										props.updateSelectedItem(null)
										props.updateTaygSettings({
											...props.taygSettings,
											[props.updateName]: null,
										})
										updateShow(!show)
									}}
								>
									Remove Selection
								</ListGroup.Item>
							) : null}
						</ListGroup>
					</Dropdown.Menu>
				</Dropdown>
			</Col>
		</Row>
	)
}

export { SimpleSettingsDropDown }
