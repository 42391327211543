import React from 'react'
import { Dropdown } from 'react-bootstrap'
import { MoreVertical } from 'react-feather'
import { StripeInvoice } from '../../../../back-end/utilities/apiDefinitions'
import { OverflowMenuOptions, getOverflowMenuOptions } from '../../components/subsync/overview/functions'

interface SyncLogRowActionProps extends OverflowMenuOptions {
	stripeInvoice: StripeInvoice
}

const SyncLogRowAction = (props: SyncLogRowActionProps) => {
	const overflowOptions = getOverflowMenuOptions(props.stripeInvoice, {
		historyView: props.historyView,
		creditNotesEnabled: props.creditNotesEnabled,
		stripeFeesBillsEnabled: props.stripeFeesBillsEnabled,
	})

	return (
		<Dropdown>
			<Dropdown.Toggle as="div" className="line-options-toggle">
				<MoreVertical size={20} />
			</Dropdown.Toggle>
			<Dropdown.Menu>
				{overflowOptions.map((option) => (
					<Dropdown.Item key={option.name} disabled={option.disabled} onClick={() => option.method(props.stripeInvoice.stripeInvoiceID!)}>
						{option.name}
					</Dropdown.Item>
				))}
			</Dropdown.Menu>
		</Dropdown>
	)
}

export { SyncLogRowAction }
