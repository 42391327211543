import { StripeInvoice, StripeInvoiceSubSyncEvent } from '../../../../../back-end/utilities/apiDefinitions'
import { SubSyncEventStripeEventType, SubSyncEventType } from '../../../constants/subSyncEvent'
import { OverviewComponentFrameProps } from './overview'

type OverflowMenuOptions = Pick<OverviewComponentFrameProps, 'historyView' | 'creditNotesEnabled' | 'stripeFeesBillsEnabled'> &
	Partial<Pick<OverviewComponentFrameProps, 'retryFailedTransaction'>>

const getOverflowMenuOptions = (item: StripeInvoice, props: OverflowMenuOptions) => {
	const invoice = props.historyView.events.find((invoice) => invoice.stripeInvoiceID === item.stripeInvoiceID)
	const originalInvoiceEvent = getOriginalInvoiceEvent(invoice?.events)
	const disableLinks = originalInvoiceEvent?.xeroOrg && originalInvoiceEvent?.xeroInvoiceID ? false : true
	const paidEvent = getPaidInvoiceEvent(invoice?.events)
	const creditNoteEvents = getCreditNoteInvoiceEvents(invoice?.events) ?? []

	return [
		{ name: 'View Stripe Invoice', method: (id: string) => handleViewStripeInvoice(id, props), disabled: false },
		{ name: 'View Xero Invoice', method: (id: string) => handleViewXeroInvoice(id, props), disabled: disableLinks },
	]
		.concat(invoice && props.retryFailedTransaction ? getRetryOption(invoice, props) : [])
		.concat(
			props.stripeFeesBillsEnabled && paidEvent
				? [{ name: 'View Xero Bill (Stripe Fee)', method: (id: string) => handleViewXeroBill(id, props), disabled: !paidEvent?.xeroInvoiceID }]
				: []
		)
		.concat(
			props.creditNotesEnabled && creditNoteEvents.length > 0
				? [
						{
							name: 'View Stripe Credit Note',
							method: (id: string) => handleViewStripeCreditNote(id, props),
							disabled: !creditNoteEvents?.some((event) => event.stripeInvoiceStripeID),
						},
						{
							name: 'View Xero Credit Note',
							method: (id: string) => handleViewXeroCreditNote(id, props),
							disabled: !creditNoteEvents?.some((event) => event.xeroInvoiceID && event.result === 'success'),
						},
				  ]
				: []
		)
}

// could be an invoice.finalized event, or an invoice.paid event that we treated as a created event
const getOriginalInvoiceEvent = (events: StripeInvoiceSubSyncEvent[] | undefined) =>
	events?.find((event) => event.type === SubSyncEventType.Created || event.type === SubSyncEventType.UpdatedNew) ||
	// only fall through to paid type events if there are no created ones - this means we received finalized+paid together, ignored finalized, and there was an error before we could generate a simulated created event
	events?.find((event) => event.type === SubSyncEventType.Paid)

// an invoice.paid event (from Stripe, not Xero) that *wasn't* paid wholly by credit note - i.e. must have involved money
const getPaidInvoiceEvent = (events: StripeInvoiceSubSyncEvent[] | undefined): StripeInvoiceSubSyncEvent | undefined =>
	// for invoices paid by credit note we don't want to show any event at all in the paid column - including error messages - as it isn't relevant to Stripe fees and there's no paid amount
	events?.some((event) => event.stripeEventType === SubSyncEventStripeEventType.InvoicePaid && event.message?.includes('Paid by credit note'))
		? undefined
		: events?.find((event) => event.stripeEventType === SubSyncEventStripeEventType.InvoicePaid)

// any events related to credit notes from either Stripe or Xero
const getCreditNoteInvoiceEvents = (events: StripeInvoiceSubSyncEvent[] | undefined) => events?.filter((event) => event.type === SubSyncEventType.Credit)

const handleViewStripeInvoice = async (invoiceID: string, props: OverflowMenuOptions) => {
	const invoice = props.historyView.events.find((invoice) => invoice.stripeInvoiceID === invoiceID)
	const originalInvoiceEvent = getOriginalInvoiceEvent(invoice!.events)
	// we already know all of the correct events exist or these options wouldn't be accessible via the overflow menu
	window.open(`https://dashboard.stripe.com/invoices/${originalInvoiceEvent!.stripeInvoiceStripeID}`, 'StripeInvoiceTab')
}

const handleViewXeroInvoice = async (invoiceID: string, props: OverflowMenuOptions) => {
	const invoice = props.historyView.events.find((invoice) => invoice.stripeInvoiceID === invoiceID)
	const originalInvoiceEvent = getOriginalInvoiceEvent(invoice!.events)

	window.open(
		`https://go.xero.com/organisationlogin/default.aspx?shortcode=${
			originalInvoiceEvent!.xeroOrg!.shortCode
		}&redirecturl=/AccountsReceivable/View.aspx?InvoiceID=${originalInvoiceEvent!.xeroInvoiceID}`,
		'XeroInvoiceTab'
	)
}
const handleViewStripeCreditNote = async (invoiceID: string, props: OverflowMenuOptions) => {
	const invoice = props.historyView.events.find((invoice) => invoice.stripeInvoiceID === invoiceID)
	const stripeCreditNoteEvent = getCreditNoteInvoiceEvents(invoice?.events)

	window.open(`https://dashboard.stripe.com/credit_notes/${stripeCreditNoteEvent![0].stripeInvoiceStripeID}`, 'StripeCreditNoteTab')
}

const handleViewXeroCreditNote = async (invoiceID: string, props: OverflowMenuOptions) => {
	const invoice = props.historyView.events.find((invoice) => invoice.stripeInvoiceID === invoiceID)
	const xeroCreditNoteEvent = getCreditNoteInvoiceEvents(invoice!.events)

	window.open(
		`https://go.xero.com/organisationlogin/default.aspx?shortcode=${
			xeroCreditNoteEvent![0].xeroOrg!.shortCode
		}&redirecturl=/AccountsReceivable/ViewCreditNote.aspx?CreditNoteID=${xeroCreditNoteEvent![0].xeroInvoiceID}`,
		'XeroCreditNoteTab'
	)
}

const handleViewXeroBill = async (invoiceID: string, props: OverflowMenuOptions) => {
	const invoice = props.historyView.events.find((invoice) => invoice.stripeInvoiceID === invoiceID)
	const paidEvent = getPaidInvoiceEvent(invoice!.events)

	window.open(
		`https://go.xero.com/organisationlogin/default.aspx?shortcode=${paidEvent!.xeroOrg!.shortCode}&redirecturl=/AccountsPayable/View.aspx?InvoiceID=${
			paidEvent!.xeroInvoiceID
		}`,
		'XeroBillTab'
	)
}

const handleRetryTransaction = async (invoiceID: string, props: OverflowMenuOptions) => {
	const invoice = props.historyView.events.find((invoice) => invoice.stripeInvoiceID === invoiceID)
	const body = {
		stripeInvoiceID: invoiceID,
		xeroOrgID: invoice && invoice.events![0] ? invoice!.events![0].xeroOrg!.xeroOrgID : undefined,
		stripeOrgID: invoice!.stripeOrg!.stripeOrgID,
	}
	props.retryFailedTransaction && props.retryFailedTransaction(body)
}

const getRetryOption = (invoice: StripeInvoice, props: OverflowMenuOptions) => {
	// an event could have been retried many times - we only want to check the status of the most recent attempt, and only allow retry if that attempt was error
	const mostRecentTransactionsPerEvent = invoice.events!.reduce((final, curr) => {
		const eventIndex = final.findIndex((prev) => prev.stripeEventID === curr.stripeEventID)
		if (eventIndex < 0) {
			final.push(curr)
		}
		return final
	}, [] as StripeInvoiceSubSyncEvent[])
	return mostRecentTransactionsPerEvent.some((event) => event.result === 'error')
		? [{ name: 'Retry Transaction', method: (id: string) => handleRetryTransaction(id, props), disabled: false }]
		: []
}

export { getOverflowMenuOptions, getOriginalInvoiceEvent, getPaidInvoiceEvent }
export type { OverflowMenuOptions }
