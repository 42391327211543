import React from 'react'
import { Form, Row, Col, DropdownButton, Dropdown } from 'react-bootstrap'

// definitions
import { AccountXeroOrgWithData } from '../../../../screens/subsync/subsync'
import { StripeOrgAccountXeroOrg, XeroAPIContact } from '../../../../../../back-end/utilities/apiDefinitions'
import { invoiceLineItemDateFormats } from '../../../../constants/invoiceLineItemDateFormat'

interface InvoicePeriodsSettingsProps {
	orgMapping: StripeOrgAccountXeroOrg | undefined
	mappedXeroOrg: AccountXeroOrgWithData | undefined
	stripeOrgID: string
	handleSettingsUpdate: (stripeOrgID: string, values: { [name: string]: string | boolean | XeroAPIContact | undefined }) => void
}

const InvoicePeriodsSettings = (props: InvoicePeriodsSettingsProps) => {
	return (
		<>
			<Form.Group as={Row} className="d-flex align-items-center" style={{ height: '2.5rem' }}>
				<Col>
					<Form.Check
						type={'checkbox'}
						id={`${props.stripeOrgID}-invoice-periods`}
						name={'invoiceLineItemDatesEnabled'}
						label={'Add invoice period dates to line item description'}
						checked={!!props.orgMapping?.stripeOrgAccountXeroOrgSettings?.invoiceLineItemDatesEnabled}
						onChange={(e) => props.handleSettingsUpdate(props.stripeOrgID, { [e.target.name]: e.target.checked })}
					/>
				</Col>
			</Form.Group>
			<Form.Group as={Row} style={{ height: '2.5rem' }}>
				<Form.Label column sm={2}>
					Date Format
				</Form.Label>
				<Col sm={4}>
					<DropdownButton
						id={`${props.stripeOrgID}-invoice-periods-date-format`}
						title={props.orgMapping?.stripeOrgAccountXeroOrgSettings?.invoiceLineItemDateFormat || 'Select Date Format'}
						onSelect={(eventKey) => props.handleSettingsUpdate(props.stripeOrgID, { invoiceLineItemDateFormat: eventKey! })}
						disabled={!props.orgMapping?.stripeOrgAccountXeroOrgSettings?.invoiceLineItemDatesEnabled}
					>
						{invoiceLineItemDateFormats.map((format) => (
							<Dropdown.Item key={format} eventKey={format}>
								{format}
							</Dropdown.Item>
						))}
					</DropdownButton>
				</Col>
			</Form.Group>
		</>
	)
}

export { InvoicePeriodsSettings }
