import React from 'react'
import { Button, Col, Dropdown, Form, Row, Spinner, Table as RTable } from 'react-bootstrap'
import { MoreVertical } from 'react-feather'

import moment from 'moment'

import * as Paging from '../pagination/pagination'
import * as Sort from './sort'
import MoreMenuToggle from '../dropdown/toggle'

interface FilterDropdownOption {
	value: string
	text: string
}

interface TableAction {
	name: string
	method: (selected: string[]) => Promise<void>
}

interface RowAction {
	name: string
	method: (selected: string) => Promise<void>
	disabled?: boolean
}

interface TableColumn<T> {
	// body stuff
	value: (item: T) => string
	render: (item: T) => JSX.Element
	// header stuff
	showHeader: boolean
	headerText?: string
	sortColumnName?: keyof T | string
	filterType?: 'string' | 'dropdown' | 'dateRange'
	filterOptions?: {
		columnName: keyof T | string
		options?: FilterDropdownOption[]
	}
}

interface TableProps<T> {
	name: string
	namePlural: string
	getIDFunc: (type: T) => string // (t: T) => t.myID
	selectedActions?: TableAction[]
	individualActions?: (item: T) => RowAction[]
	list: T[]
	listFunction?: (replace: boolean, page?: number, filters?: { key: string; operator: string; value: string | number }[]) => Promise<void>
	columns: TableColumn<T>[]
	defaultSort: Sort.SortFilter<T>
	isLoading: boolean
	paginateRequest?: boolean
	innerTableClassName?: string
}

type SearchFilter<T> = Record<keyof Partial<T> | string, string>

const Table = <T,>(props: TableProps<T>) => {
	const [pageFilter, setPageFilter] = React.useState<Paging.FiltersState>({ page: 1, pageSize: Paging.getPagingPreferences() })
	const [searchFilters, setSearchFilters] = React.useState<SearchFilter<T>>({} as SearchFilter<T>)
	const [sortFilters, setSortFilters] = React.useState<Sort.SortFilter<T>>(props.defaultSort)
	const [selectFilter, setSelectFilter] = React.useState<string[]>([])
	const [filterHasChanged, setFilterHasChanged] = React.useState<boolean>(false)
	const [apiPageRequestNumber, setApiPageRequestNumber] = React.useState<number>(1)

	const setSelect = (id: string, checked: boolean) => {
		if (checked && id === 'All') {
			setSelectFilter(props.list.map(props.getIDFunc))
		} else if (checked) {
			setSelectFilter([...selectFilter, id])
		} else if (id === 'All') {
			setSelectFilter([])
		} else {
			setSelectFilter(selectFilter.filter((t) => t !== id))
		}
	}

	const searchFilterFunction = (record: T) =>
		props.columns.every((column) => {
			if (column.filterOptions) {
				if (column.filterType === 'dateRange') {
					const low = searchFilters[String(column.filterOptions.columnName) + '-low']
					const high = searchFilters[String(column.filterOptions.columnName) + '-high']
					const value = moment(column.value(record)).format('YYYY-MM-DD')
					if (low === undefined && high === undefined) {
						return true
					} else if (low && high) {
						return value >= low && value <= high
					} else if (low) {
						return value >= low
					} else if (high) {
						return value <= high
					}
					return true
				} else {
					const filterText = searchFilters[column.filterOptions.columnName]
					const value = column.value(record)
					if (filterText === 'Any' && column.filterType === 'dropdown') {
						return true
					}
					if (filterText && value) {
						return value.toLowerCase().includes(filterText.toLowerCase())
					} else if (filterText && !value) {
						return false
					}
				}
			}
			return true
		})
	const pageFilterFunction = (_record: T, index: number) => Paging.pageFilter(pageFilter, index)
	const sortFunction = Sort.getSortFunction(sortFilters, props.columns)

	const unFilteredRecords = props.list
	const filteredRecords = unFilteredRecords.filter(searchFilterFunction)
	const sortedFilteredRecords = filteredRecords.sort(sortFunction)
	const paginatedFilteredSortRecords = sortedFilteredRecords.filter(pageFilterFunction)

	const hasSelectedActions = props.selectedActions && props.selectedActions.length > 0
	const hasIndividualActions = props.individualActions && props.individualActions.length > 0

	return (
		<>
			<Row style={{ marginBottom: '20px' }} className="align-items-center">
				{hasSelectedActions && (
					<>
						<Col sm={'auto'}>
							<Dropdown>
								<Dropdown.Toggle
									className="form dropdown-toggle-long"
									variant="secondary"
									id="dropdown-action"
									style={{ width: '300px', height: '40px' }}
								>
									Actions
								</Dropdown.Toggle>
								<Dropdown.Menu>
									{props.selectedActions!.map((action, i) => (
										<Dropdown.Item
											key={i}
											onClick={async () => {
												await action.method(selectFilter)
												setSelectFilter([])
											}}
										>
											{action.name}
										</Dropdown.Item>
									))}
								</Dropdown.Menu>
							</Dropdown>
						</Col>
						<Col sm={'auto'}>
							<span className="span-grey">
								{selectFilter.length} {props.list.length === 1 ? props.name : props.namePlural} selected
							</span>
						</Col>
					</>
				)}
				{props.paginateRequest && props.listFunction && filterHasChanged && (
					<Col sm={'auto'}>
						<Button
							onClick={() => {
								setFilterHasChanged(false)
								setApiPageRequestNumber(1)
								props.listFunction!(true, 1, getFilters(searchFilters))
							}}
						>
							Get Filtered Results
						</Button>
					</Col>
				)}
				<Col></Col>

				<Col sm={'auto'} className="align-items-center">
					<div>
						<span className="span-bold align-items-center">Show</span>
						<Dropdown style={{ float: 'right' }}>
							<Dropdown.Toggle className="form pr-4" variant="secondary" id="dropdown-action">
								{pageFilter.pageSize} records
							</Dropdown.Toggle>
							<Dropdown.Menu>
								<Dropdown.Item
									onClick={() => {
										setPageFilter({ page: 1, pageSize: 10 })
										Paging.storePagingPreferences(10)
									}}
								>
									10 records
								</Dropdown.Item>
								<Dropdown.Item
									onClick={() => {
										setPageFilter({ page: 1, pageSize: 25 })
										Paging.storePagingPreferences(25)
									}}
								>
									25 records
								</Dropdown.Item>
								<Dropdown.Item
									onClick={() => {
										setPageFilter({ page: 1, pageSize: 50 })
										Paging.storePagingPreferences(50)
									}}
								>
									50 records
								</Dropdown.Item>
								<Dropdown.Item
									onClick={() => {
										setPageFilter({ page: 1, pageSize: 100 })
										Paging.storePagingPreferences(100)
									}}
								>
									100 records
								</Dropdown.Item>
							</Dropdown.Menu>
						</Dropdown>
					</div>
				</Col>
			</Row>

			<Row>
				<Col>
					<RTable borderless style={{ backgroundColor: 'white' }} className={props.innerTableClassName}>
						<thead>
							<tr>
								{hasSelectedActions && (
									<th style={{ textAlign: 'center' }}>
										<Row>
											<Col className="upper-table-header" style={{ paddingBottom: '8px' }}>
												select
											</Col>
										</Row>
										<Row className="card-title-filter">
											<Col>
												<Form.Control
													style={{ boxShadow: 'none' }}
													onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
														setSelect(event.target.value, event.target.checked)
													}}
													type="checkbox"
													id={'select_' + props.name}
													name={'select_' + props.name}
													value={'All'}
												/>
											</Col>
										</Row>
									</th>
								)}
								{props.columns.map((column, index) => (
									<th key={index}>
										{column.showHeader && column.headerText && column.sortColumnName && (
											<>
												<Row>
													<Col className="upper-table-header">{column.headerText}</Col>
													<Sort.SortButton sort={sortFilters} setSort={setSortFilters} columnName={column.sortColumnName} />
												</Row>
												<Row className="card-title-filter">
													<Col>
														{column.filterType && column.filterOptions && column.filterType === 'string' && (
															<Form.Control
																onChange={(event) => {
																	setSearchFilters({
																		...searchFilters,
																		[column.filterOptions!.columnName]: event.target.value,
																	})
																	setFilterHasChanged(true)
																}}
																type="text"
																id={`search_${String(column.filterOptions.columnName)}`}
																name={`search_${String(column.filterOptions.columnName)}`}
																value={searchFilters[column.filterOptions.columnName] || ''}
																placeholder="Type to filter"
															/>
														)}
														{column.filterType && column.filterOptions && column.filterType === 'dropdown' && (
															<Dropdown>
																<Dropdown.Toggle className="form" variant="secondary" id="dropdown-assetType">
																	{column.filterOptions?.options?.find(
																		(option) => option.value === searchFilters[column.filterOptions!.columnName]
																	)?.text || 'Any'}
																</Dropdown.Toggle>
																<Dropdown.Menu>
																	<Dropdown.Item
																		onClick={() => {
																			setSearchFilters({ ...searchFilters, [column.filterOptions!.columnName]: 'Any' })
																			setFilterHasChanged(true)
																		}}
																	>
																		Any
																	</Dropdown.Item>
																	{column.filterOptions.options &&
																		column.filterOptions.options.map((option) => (
																			<Dropdown.Item
																				key={option.value}
																				onClick={() => {
																					setSearchFilters({
																						...searchFilters,
																						[column.filterOptions!.columnName]: option.value,
																					})
																					setFilterHasChanged(true)
																				}}
																			>
																				{option.text}
																			</Dropdown.Item>
																		))}
																</Dropdown.Menu>
															</Dropdown>
														)}
														{column.filterType && column.filterOptions && column.filterType === 'dateRange' && (
															<>
																<Form.Control
																	onChange={(event) => {
																		setSearchFilters({
																			...searchFilters,
																			[String(column.filterOptions!.columnName) + '-low']: event.target.value,
																		})
																		setFilterHasChanged(true)
																	}}
																	type="date"
																	max={searchFilters[String(column.filterOptions.columnName) + '-high'] || ''}
																	id={`search_${String(column.filterOptions.columnName)}`}
																	name={`search_${String(column.filterOptions.columnName)}`}
																	value={searchFilters[String(column.filterOptions.columnName) + '-low'] || ''}
																	style={{ width: '50%', display: 'inline-block ' }}
																/>
																<Form.Control
																	onChange={(event) => {
																		setSearchFilters({
																			...searchFilters,
																			[String(column.filterOptions!.columnName) + '-high']: event.target.value,
																		})
																		setFilterHasChanged(true)
																	}}
																	type="date"
																	min={searchFilters[String(column.filterOptions.columnName) + '-low'] || ''}
																	id={`search_${String(column.filterOptions.columnName)}`}
																	name={`search_${String(column.filterOptions.columnName)}`}
																	value={searchFilters[String(column.filterOptions.columnName) + '-high'] || ''}
																	style={{ width: '50%', display: 'inline-block ' }}
																/>
															</>
														)}
													</Col>
												</Row>
											</>
										)}
									</th>
								))}
								{hasIndividualActions && <th></th>}
							</tr>
						</thead>

						<tbody>
							{props.isLoading ? (
								<tr>
									<td colSpan={props.columns.length + 1}>
										<Spinner size={'sm'} animation={'border'} />
									</td>
								</tr>
							) : (
								paginatedFilteredSortRecords.map((item) => (
									<tr key={props.getIDFunc(item)}>
										{hasSelectedActions && (
											<td>
												<Form.Control
													style={{ boxShadow: 'none' }}
													onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
														setSelect(event.target.value, event.target.checked)
													}}
													type="checkbox"
													id={`select_${props.getIDFunc(item)}`}
													name={`select_${props.getIDFunc(item)}`}
													checked={selectFilter.find((select) => select === props.getIDFunc(item)) !== undefined}
													value={props.getIDFunc(item)}
												/>
											</td>
										)}
										{props.columns.map((column) => (
											<td key={`${props.getIDFunc(item)}_${column.headerText}`}>{column.render(item)}</td>
										))}
										{hasIndividualActions && (
											<td>
												<Dropdown>
													<Dropdown.Toggle
														as={MoreMenuToggle}
														className="form dropdown-toggle-long"
														variant="secondary"
														id="dropdown-row-action"
														style={{ width: '300px', height: '40px' }}
													>
														<MoreVertical />
													</Dropdown.Toggle>
													<Dropdown.Menu>
														{props.individualActions!(item).map((action, i) => (
															<Dropdown.Item
																key={i}
																disabled={action.disabled}
																onClick={async () => {
																	await action.method(props.getIDFunc(item))
																	setSelectFilter([])
																}}
															>
																{action.name}
															</Dropdown.Item>
														))}
													</Dropdown.Menu>
												</Dropdown>
											</td>
										)}
									</tr>
								))
							)}
						</tbody>

						<tfoot>
							<tr>
								<td colSpan={11}>
									<Row>
										<Col>
											<span className="span-grey" style={{ verticalAlign: 'middle' }}>
												{filteredRecords.length ? (
													<>
														Displaying{' '}
														<span className="span-blue">
															{Paging.calculatePageStart(pageFilter)} to{' '}
															{Paging.calculatePageTo(pageFilter, filteredRecords.length)}
														</span>{' '}
														of {filteredRecords.length} {props.namePlural.toLowerCase()}
													</>
												) : (
													<>No {props.namePlural.toLowerCase()} found</>
												)}
											</span>
										</Col>
										<Col sm="auto">
											<Paging.Pagination
												filters={pageFilter}
												setFilterDispatch={setPageFilter}
												allPaginatedRecordsLength={filteredRecords.length}
												onLoadMoreClick={
													props.paginateRequest
														? () => {
																props.listFunction!(false, apiPageRequestNumber + 1, getFilters(searchFilters))
																setApiPageRequestNumber(apiPageRequestNumber + 1)
														  }
														: undefined
												}
											/>
										</Col>
									</Row>
								</td>
							</tr>
						</tfoot>
					</RTable>
				</Col>
			</Row>
		</>
	)
}

const getFilters = (searchFilters: SearchFilter<unknown>): WhereFilter[] => {
	return Object.keys(searchFilters).map((key) => ({
		key: getFilterKey(key),
		operator: getFilterOperator(key),
		value: searchFilters[key],
	}))
}

const getFilterKey = (key: string) => {
	return key.replace('-low', '').replace('-high', '')
}

const getFilterOperator = (key: string) => {
	if (key.includes('-low')) {
		return '>'
	} else if (key.includes('-high')) {
		return '<'
	} else {
		return '=~'
	}
}

type WhereFilter = { key: string; operator: string; value: string | number }

const getApiQueryStringWhereFromFilters = (filters?: WhereFilter[]) => {
	return filters && filters.length > 0
		? `&${filters
				.filter((filter) => filter.value)
				.map((filter) => `where=${filter.key}${filter.operator}${filter.value}`)
				.join('&')}`
		: ''
}

const mergeDataSets = <T,>(set1: T[], set2: T[], getIdFunc: (a: T) => string) => {
	if (getIdFunc(set1[set1.length - 1]) === getIdFunc(set2[0])) {
		const newSet1 = [...set1]
		newSet1.pop()
		return [...newSet1, ...set2]
	} else {
		return [...set1, ...set2]
	}
}

export { Table, getApiQueryStringWhereFromFilters, mergeDataSets }
export type { FilterDropdownOption, TableAction, TableColumn, SearchFilter, WhereFilter }
