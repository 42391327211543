import React from 'react'
import { Auth } from 'aws-amplify'
import { Container } from 'react-bootstrap'
import { XeroButton } from '../../components/ui/xeroButton/xeroButton'
import { getSignInProvider } from '../../utilities/login'

const Redirect = () => {
	React.useEffect(() => {
		Auth.federatedSignIn({ customProvider: getSignInProvider() })
	}, [])

	return (
		<Container>
			<p>Redirect in progress, if you aren&apos;t redirected soon please click the button below</p>
			<XeroButton text="Sign in with Xero" onClick={() => Auth.federatedSignIn({ customProvider: getSignInProvider() })} />
		</Container>
	)
}

export default Redirect
