import React from 'react'
import { Navigate, Link, useLocation } from 'react-router-dom'
import { Container } from 'react-bootstrap'

import * as Request from '../../utilities/request'
import * as Url from '../../utilities/url'
import LoadingImage from '../../images/Finlert-loading-GIF_100px.gif'
import TAYGLoadingImage from '../../images/TAYG_100px.gif'
import SubSyncLoadingImage from '../../images/subSyncIcons/SubSync_100x100.gif'
import { AppContext } from '../../App'

const Callback = () => {
	const { appState } = React.useContext(AppContext)
	const location = useLocation()

	const [isReady, updateIsReady] = React.useState<boolean>(false)
	const [failMessage, updateFailMessage] = React.useState<string>('')

	React.useEffect(() => {
		const exchangeKeys = async () => {
			const search = location.search.replace('?xcode', '?code').replace('&xcode', '&code')
			try {
				await Request.get(`google/tokens` + search, appState.authState)
				updateIsReady(true)
			} catch (err) {
				console.log(err)
				updateFailMessage('An Error has Occurred')
			}
		}

		if (
			appState.authState.isLoggedIn &&
			appState.accounts.length > 0 &&
			appState.permission[appState.context] &&
			appState.authState.invitationAccepted &&
			!Url.getUrlSearchParam(location.search, 'xerror')
		) {
			exchangeKeys()
		}
	}, [location.search, appState]) // eslint-disable-line

	if (Url.getUrlSearchParam(location.search, 'xerror')) {
		return (
			<Container>
				<p>
					Authentication with Google failed, <Link to="/google/integration">go to connections page</Link>
				</p>
			</Container>
		)
	} else if (isReady) {
		if (Url.getUrlSearchParam(location.search, 'state') === 'no_redirect') {
			return (
				<Container>
					<p>You&apos;ve successfully authed Google you may close this window or tab</p>
				</Container>
			)
		} else {
			return <Navigate to="/" />
		}
	} else if (failMessage === '') {
		return (
			<Container className="loading-gif">
				<img
					src={appState.context === 'tayg' ? TAYGLoadingImage : appState.context === 'subsync' ? SubSyncLoadingImage : LoadingImage}
					alt="Loading ..."
				/>
				<hr />
				<p>Logging you in using your Google credentials...</p>
				<p>This may take up to 30 seconds.</p>
			</Container>
		)
	} else {
		return (
			<Container>
				<p>{failMessage}</p>
			</Container>
		)
	}
}

export default Callback
