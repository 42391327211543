import React from 'react'
import { Button, Col, Form, Row } from 'react-bootstrap'
import { TaygSettingsExtraAccount, XeroAccount } from '../../../../back-end/utilities/apiDefinitions'
import { DropdownWithSearch } from '../ui/dropdown/dropdownWithSearch'
import { Edit } from 'react-feather'
import { properCase } from '../../utilities/format'
import { LabelPopUp } from './labelPopUp'

interface ExtraAccountRowProps {
	ea: Pick<TaygSettingsExtraAccount, 'taygSettingsExtraAccountID' | 'label' | 'type' | 'xeroAccountID'> & { AccountName: string }
	handleEdit: (id: string, newLabel: string) => void
	handleDelete: (id: string) => void
}

const ExtraAccountRow = (props: ExtraAccountRowProps) => {
	const [showEditLabelDialog, updateShowEditLabelDialog] = React.useState<boolean>(false)

	return (
		<Col>
			<li key={props.ea.taygSettingsExtraAccountID}>
				{props.ea.label} - {props.ea.AccountName || ''} <Edit onClick={() => updateShowEditLabelDialog(true)} style={{ cursor: 'pointer' }} />
			</li>
			<LabelPopUp
				show={showEditLabelDialog}
				handleClose={() => updateShowEditLabelDialog(false)}
				id={props.ea.taygSettingsExtraAccountID}
				currentLabel={props.ea.label}
				handleEdit={props.handleEdit}
				handleDelete={props.handleDelete}
			/>
		</Col>
	)
}

interface ExtraAccountsProps {
	accounts: XeroAccount[]
	extraAccounts: (Pick<TaygSettingsExtraAccount, 'taygSettingsExtraAccountID' | 'label' | 'type' | 'xeroAccountID'> & { AccountName: string })[]
	handleAdd: (extraAccount: Partial<TaygSettingsExtraAccount>) => void
	handleEdit: (id: string, newLabel: string) => void
	handleDelete: (id: string) => void
	type: 'asset' | 'liability'
}

const ExtraAccounts = (props: ExtraAccountsProps) => {
	const [assetXeroAccount, updateAssetXeroAccount] = React.useState<XeroAccount | null>(null)
	const [extraAssetLabel, updateExtraAssetLabel] = React.useState<string>('')

	return (
		<>
			<Row>
				<Col sm="12">
					<DropdownWithSearch
						title={props.type === 'asset' ? 'Other Assets' : props.type === 'liability' ? 'Other Liabilities' : 'Accounts'}
						items={props.accounts.map((acc) => ({ ...acc, id: acc.xeroAccountID!, label: acc.code ? `${acc.code} - ${acc.name!}` : acc.name! }))}
						onSelect={(item) => {
							updateAssetXeroAccount(item)
							updateExtraAssetLabel(item ? (item.code ? `${item.code} - ${item.name!}` : item.name!) : '')
						}}
					/>
				</Col>
			</Row>
			<Row>
				<Form.Label as={Col} className="tayg-settings-label">
					{properCase(props.type)} account label:
				</Form.Label>
				<Col>
					<Form.Control
						type="text"
						id={`extra${properCase(props.type)}Label`}
						name={`extra${properCase(props.type)}Label`}
						placeholder="Label"
						onChange={(event) => {
							const value = event.target.value
							updateExtraAssetLabel(value)
						}}
						value={extraAssetLabel || ''}
						style={{ display: 'inline', width: '40%', paddingLeft: '12px', fontSize: '16px', marginBottom: '5px' }}
					></Form.Control>
					<Button
						onClick={() => {
							updateAssetXeroAccount(null)
							updateExtraAssetLabel('')
							props.handleAdd({ type: props.type, xeroAccountID: assetXeroAccount?.xeroAccountID, label: extraAssetLabel })
						}}
					>
						Add
					</Button>
				</Col>
			</Row>
			<Row>
				<Col>
					<ul style={{ listStyle: 'none', textAlign: 'center' }}>
						{props.extraAccounts.map((ea) => (
							<ExtraAccountRow ea={ea} key={ea.taygSettingsExtraAccountID} handleEdit={props.handleEdit} handleDelete={props.handleDelete} />
						))}
					</ul>
				</Col>
			</Row>
		</>
	)
}

export { ExtraAccounts }
