import React from 'react'
import { Row, Col, Button, Carousel } from 'react-bootstrap'

// components
import { TimePeriodPicker } from './timePeriodPicker'
import { DashboardTable } from './table'
import { Messages, useMessageReducer } from '../../components/ui/messages/messages'
import Fade from '@material-ui/core/Fade'
import LinearProgress from '@material-ui/core/LinearProgress'

// utilities
import * as Request from '../../utilities/request'
import moment from 'moment'

// definitions
import { AppState } from '../../App.d'
import { DataSetMetricGroupedByXeroOrg, MetricValue, XeroOrgsGroupedByTag } from '../../screens/oversight/oversight.d'
import { DashboardTableProps } from './table'
import { XeroOrg } from '../../../../back-end/utilities/apiDefinitions'

export type DateRange = {
	startDate: moment.Moment | null
	endDate: moment.Moment | null
}

type Report = {
	organisation: XeroOrg
	metrics: MetricValue[]
	success: boolean
	message?: string
}

interface PointInTimeTabProps extends DashboardTableProps {
	appState: AppState
	filterAndGroup: (reports: DataSetMetricGroupedByXeroOrg[]) => XeroOrgsGroupedByTag[]
	rerunAnalysis: boolean
	updateNeedRerun: (rerun: boolean) => void
}

const initialDateRange = {
	startDate: null,
	endDate: null,
}

const PointInTimeTab = (props: PointInTimeTabProps) => {
	const [period, updatePeriod] = React.useState<DateRange>(initialDateRange)
	const [comparePeriod, updateComparePeriod] = React.useState<DateRange>(initialDateRange)
	const [reports, updateReports] = React.useState<DataSetMetricGroupedByXeroOrg[] | null>(null)
	const [errorReports, updateErrorReports] = React.useState<DataSetMetricGroupedByXeroOrg[] | null>(null)
	const [fetchedTs, updateFetchedTs] = React.useState<string>('')

	const [isFetching, updateIsFetching] = React.useState<boolean>(false)
	const [progress, updateProgress] = React.useState<number | null>(null)
	const [loadingPuns, updateLoadingPuns] = React.useState<string[]>([])

	const [messages, updateMessages] = useMessageReducer([])
	const [errorMessage, setErrorMessage] = React.useState<string | undefined>(undefined)

	// useInterval(() => {
	// 	if (isFetching) {
	// 		updateProgress(prev => prev === null ? 0 : prev >= 95 ? 99 : prev! + 5)
	// 	}
	// }, 400)

	const fetchData = async () => {
		props.updateIsFetching(true)
		try {
			updateLoadingPuns(getRandomLoadingText())
			updateReports(null)
			updateErrorReports(null)
			updateIsFetching(true)
			updateProgress(0)

			const uniqueOrgIDs: string[] = props.groups ? [...new Set(props.groups.flatMap((group) => group.xeroOrgs.map((xo) => xo.xeroOrgID!)))] : []

			if (uniqueOrgIDs.length > 0 && period.startDate && period.endDate) {
				const totalUniqueOrgs = uniqueOrgIDs.length
				let totalCompleted = 0
				let successfulReports: DataSetMetricGroupedByXeroOrg[] = []
				let failedReports: DataSetMetricGroupedByXeroOrg[] = []
				let errorMsg = errorMessage

				const miniFetchData = async () => {
					const orgs = uniqueOrgIDs.splice(0, Math.min(uniqueOrgIDs.length, 4))

					const queryString1 = `report?orgs=${orgs.join('&orgs=')}&from=${period.startDate!.format('YYYY-MM-DD')}&to=${period.endDate!.format(
						'YYYY-MM-DD'
					)}`
					const resultFirstPeriod = await Request.get(queryString1, props.appState.authState)

					let resultSecondPeriod
					if (comparePeriod.startDate !== null && comparePeriod.endDate !== null) {
						const queryString2 = `report?orgs=${orgs.join('&orgs=')}&from=${comparePeriod.startDate!.format(
							'YYYY-MM-DD'
						)}&to=${comparePeriod.endDate!.format('YYYY-MM-DD')}`
						resultSecondPeriod = await Request.get(queryString2, props.appState.authState)
					}

					const metricReports = formatReportsWithMetrics(resultFirstPeriod.data.reports, resultSecondPeriod ? resultSecondPeriod.data.reports : null)
					successfulReports = successfulReports.concat(metricReports.filter((r) => r.success))
					failedReports = failedReports.concat(metricReports.filter((r) => !r.success))

					updateReports(successfulReports)
					updateErrorReports(failedReports)
					updateFetchedTs(resultSecondPeriod ? resultSecondPeriod.data.fetchedTs : resultFirstPeriod.data.fetchedTs)
					totalCompleted = totalCompleted + orgs.length
					updateProgress((totalCompleted / totalUniqueOrgs) * 100)

					props.updateNeedRerun(false)
					console.log(`Done: ${totalCompleted}/${totalUniqueOrgs}`)

					if (!resultFirstPeriod.data.success || (resultSecondPeriod && !resultSecondPeriod.data.success)) {
						errorMsg =
							errorMsg || resultFirstPeriod.data.message || (resultSecondPeriod && resultSecondPeriod.data.message) || 'Unable to retrieve data.'
					}
					if (totalCompleted === totalUniqueOrgs) {
						updateIsFetching(false)
					}
				}

				do {
					miniFetchData()
					await Request.sleep(5000)
				} while (uniqueOrgIDs.length > 0)

				if (errorMsg) {
					updateMessages({
						type: 'add',
						data: {
							severity: 'danger',
							message: errorMsg,
							timeout: 3000,
							dismissible: true,
						},
					})
					setErrorMessage(errorMsg)
				}
			} else {
				updateIsFetching(false)
				updateProgress(100)
				updateMessages({
					type: 'add',
					data: {
						severity: 'danger',
						message: 'No companies match your query parameters.',
						timeout: 3000,
						dismissible: true,
					},
				})
			}
			updateProgress(null)
		} catch (e) {
			updateIsFetching(false)
			updateProgress(null)
			const errorMsg = 'Unable to retrieve data.'
			updateMessages({
				type: 'add',
				data: {
					severity: 'danger',
					message: errorMsg,
					timeout: 3000,
					dismissible: true,
				},
			})
			setErrorMessage(errorMsg)
		}
		props.updateIsFetching(false)
	}

	const getRandomLoadingText = () => {
		return LoadingText.map((a) => ({ sort: Math.random(), value: a }))
			.sort((a, b) => a.sort - b.sort)
			.map((a) => a.value)
	}

	React.useEffect(() => {
		if (props.rerunAnalysis && period.startDate !== null && period.endDate !== null) {
			props.updateNeedRerun(false)
			// fetchData() see FOV-174
		}
	}, [props.rerunAnalysis]) // eslint-disable-line

	return (
		<div className={'point-in-time'}>
			<Row style={{ width: '100%', margin: '0' }}>
				<Fade in={isFetching} style={{ width: '100%' }}>
					<LinearProgress variant={'determinate'} value={progress!} />
				</Fade>
			</Row>
			<Row style={{ marginTop: '1em' }}>
				<Col xs={12} sm={12} md={10} lg={'auto'}>
					<Row>
						<Col xs={'auto'} sm={2} md={2} lg={'auto'} className={'label'}>
							Period
						</Col>
						<TimePeriodPicker dates={period} id={'period'} handleDatesChange={(dates) => updatePeriod(dates)} />
					</Row>
				</Col>
				<Col xs={12} sm={12} md={10} lg={'auto'}>
					<Row>
						<Col xs={'auto'} sm={2} md={2} lg={'auto'} className={'label'}>
							Comparison
						</Col>
						<TimePeriodPicker dates={comparePeriod} id={'compare-period'} handleDatesChange={(dates) => updateComparePeriod(dates)} />
					</Row>
				</Col>
				<Col xs={12} sm={2} md={2} lg={'auto'}>
					<Button disabled={period.startDate === null || period.endDate === null || isFetching} variant="primary" onClick={fetchData}>
						Run
					</Button>
				</Col>
			</Row>
			{isFetching ? (
				<Row style={{ marginTop: '50px' }}>
					<Col style={{ display: 'flex', justifyContent: 'center' }}>
						<Carousel
							style={{ width: '100%', textAlign: 'center' }}
							fade
							controls={false}
							indicators={false}
							interval={1000}
							keyboard={false}
							nextLabel={null}
							pause={false}
							prevLabel={null}
							touch={false}
						>
							{loadingPuns.map((text, index) => (
								<Carousel.Item key={index}>{text}</Carousel.Item>
							))}
						</Carousel>
					</Col>
				</Row>
			) : null}
			<DashboardTable
				{...props}
				updateReports={updateReports}
				groups={reports ? props.filterAndGroup(reports!) : null}
				groupsWithErrors={errorReports && errorReports!.length > 0 ? props.filterAndGroup(errorReports!) : null}
				isComparing={comparePeriod.endDate !== null && comparePeriod.startDate !== null}
				fetchedTs={fetchedTs}
				errorMessage={errorMessage}
			/>
			<Messages messages={messages} updateMessage={updateMessages} />
		</div>
	)
}

const formatReportsWithMetrics = (reports: Report[], comparisonReports?: Report[]): DataSetMetricGroupedByXeroOrg[] => {
	return reports.map((r) => {
		const previousReport = comparisonReports ? comparisonReports.find((report) => report.organisation.xeroOrgID === r.organisation.xeroOrgID) : null
		return {
			...r.organisation,
			metrics: comparisonReports
				? r.metrics.map((m) => {
						const previousMetric = previousReport ? previousReport.metrics.find((metric) => metric.metricID === m.metricID) : null
						return {
							...m,
							oldValue: previousMetric ? previousMetric.value : null,
						}
				  })
				: r.metrics,
			success: r.success,
			message: r.message,
		}
	})
}

const LoadingText = [
	'Querying Xero . . .',
	'Fetching results . . .',
	'Receiving results . . .',
	'Processing results . . .',
	'Sharpening pencils . . .',
	'Crunching numbers . . .',
	'Good things come to those that wait . . .',
	'Searching the couch for spare change . . .',
	'Mind the GAAP. . .',
	`Don't hate. Depreciate . . .`,
	'Lets get fiscal . . .',
	'Its accrual world . . .',
	'Working our Assets off . . .',
	'Your call is important to us. Please hold. . .',
	'Beaming you up . . .',
	'Entering the twilight zone . . .',
	'Never gonna give you up . . .',
	'Checking DMs . . .',
	`Hold the line. Love isn't always on time . . .`,
	`It's the final countdown . . .`,
	'So tell me what you want, what you really really want . . .',
	`Strap yourselves in, I’m going to make the jump to lightspeed . . .`,
	`Deal, or no deal? . . .`,
	`Survey says . . .`,
	'Come on down . . .',
	'Cutting to the chase . . .',
	'Slow and steady wins the race . . .',
	'About to spill the beans . . .',
	'Leaving no stone unturned . . .',
	`You can't make an omelet without breaking some eggs . . .`,
]

export { PointInTimeTab }
