import React from 'react'
import { Modal } from 'react-bootstrap'

interface ModalListProps extends ModalListChildProps {
	show: string
	data: any
	size: 'sm' | 'md' | 'lg' | 'xl'
	modals: {
		id: string
		body: (props: any) => JSX.Element
	}[]
	children?: any
}

type Size = 'sm' | 'lg' | 'xl'

export interface ModalListChildProps {
	handleClose: (...args: any[]) => void
}

const ModalList = (props: ModalListProps) => {
	const ShownModal = props.modals.find((m) => m.id === props.show)

	if (ShownModal) {
		return (
			<Modal show={true} size={props.size as Size} aria-labelledby="modal" centered onHide={props.handleClose}>
				<Modal.Body>
					{/* eslint-disable-next-line */}
					<ShownModal.body {...props.data} handleClose={props.handleClose} />
				</Modal.Body>
			</Modal>
		)
	} else {
		return null
	}
}

export default ModalList
