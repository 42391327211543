import React from 'react'
import { Modal, Button, Row, Col, FormGroup, FormControl, FormLabel, Spinner } from 'react-bootstrap'
import { Account } from '../../../../../back-end/utilities/apiDefinitions'
import * as AppDefinitions from '../../../App.d'
import * as Request from '../../../utilities/request'
import * as Messages from '../../../components/ui/messages/messages'

interface CreateAccountDialogProps {
	show: boolean
	handleClose: () => void
	authState?: AppDefinitions.AuthState
	switchToNewAccount?: (e: string, accs: Account[]) => void
}

const CreateAccountDialog = (props: CreateAccountDialogProps) => {
	const [accountName, updateAccountName] = React.useState<string>('')
	const [loading, updateLoading] = React.useState<boolean>(false)
	const [messages, updateMessages] = Messages.useMessageReducer([])

	const handleCreation = async () => {
		// start account creation for this user
		const values = {
			name: accountName,
			ownerUserID: props.authState!.attributes!['custom:userId'],
			createUserID: props.authState!.attributes!['custom:userId'],
			modifiedUserID: props.authState!.attributes!['custom:userId'],
			canUseKeypay: window.location.hash === '#keypay' ? '1' : '0',
		}
		const req = await Request.post(`account/user`, values, props.authState!)

		if (req.data.success && req.data.accounts[0]) {
			props.handleClose()
			updateLoading(false)
			props.switchToNewAccount!(req.data.accounts[0].accountID, req.data.accounts)
		} else {
			// something went wrong in account creation
			updateMessages({
				type: 'add',
				data: {
					severity: 'danger',
					message: `Unable to create new account`,
					timeout: 3000,
					dismissible: true,
				},
			})
		}
	}
	return (
		<Modal show={props.show} onHide={props.handleClose}>
			<Modal.Header closeButton>
				<Modal.Title>Create a new account</Modal.Title>
			</Modal.Header>

			<Modal.Body>
				<FormGroup as={Row}>
					<FormLabel column sm={4}>
						Account Name
					</FormLabel>
					<Col sm="6">
						<FormControl
							type="text"
							id={'name'}
							name="name"
							placeholder="Account Name"
							onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
								const value = event.target.value
								updateAccountName(value)
							}}
							value={accountName}
						/>
					</Col>
				</FormGroup>
			</Modal.Body>
			{loading ? (
				<Modal.Footer>
					<Spinner animation="border" role="status"></Spinner>
				</Modal.Footer>
			) : (
				<Modal.Footer>
					<Button variant={'secondary'} onClick={props.handleClose}>
						Cancel
					</Button>
					<Button
						variant={'success'}
						onClick={() => {
							// props.handleClose()

							handleCreation()
							updateLoading(true)
						}}
					>
						Confirm
					</Button>
				</Modal.Footer>
			)}
			<Messages.Messages messages={messages} updateMessage={updateMessages} />
		</Modal>
	)
}

export { CreateAccountDialog }
