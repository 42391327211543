import React from 'react'
import { Form, Row, Col, DropdownButton, Dropdown } from 'react-bootstrap'

// components
import { ArrowRight } from 'react-feather'
import XeroLogo from '../../../../images/xero-logo.svg'
import StripeLogo from '../../../../images/stripe-logo.svg'

// definitions
import { AccountStripeOrgWithData, AccountXeroOrgWithData } from '../../../../screens/subsync/subsync'
import { StripeOrgAccountXeroOrg } from '../../../../../../back-end/utilities/apiDefinitions'

interface OrgMappingHeaderProps {
	stripeOrg: AccountStripeOrgWithData
	orgMappings: StripeOrgAccountXeroOrg[]
	orgMapping: StripeOrgAccountXeroOrg | undefined
	mappedXeroOrg: AccountXeroOrgWithData | undefined
	xeroOrgs: AccountXeroOrgWithData[]
	handleOrgUpdate: (stripeOrgID: string, name: string, value: string) => void
	handleOrgAdd: (stripeOrgID: string, value: string) => void
}

const OrgMappingHeader = (props: OrgMappingHeaderProps) => {
	return (
		<Form.Group as={Row} style={{ marginBottom: '0px', marginLeft: '0px', display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%' }}>
			<Col sm="1" />
			<input type="image" alt="xero-logo" style={{ width: '40px', borderRadius: '25px' }} src={StripeLogo} />
			<Col sm="2" style={{ display: 'flex', alignItems: 'center' }}>
				<div className="subsync-settings-label">{props.stripeOrg.stripeOrg!.name}</div>
			</Col>
			<Col
				sm="2"
				style={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'space-evenly',
				}}
			>
				<ArrowRight></ArrowRight>
			</Col>
			<input type="image" alt="xero-logo" style={{ width: '40px' }} src={XeroLogo} />
			<Col sm="3">
				<DropdownButton
					id={`${props.stripeOrg.stripeOrg!.stripeOrgID}-mapping-org-select`}
					title={props.mappedXeroOrg?.xeroOrg?.name || 'Select Xero Org'}
					onSelect={(eventKey) =>
						props.orgMapping
							? props.handleOrgUpdate(props.stripeOrg.stripeOrg!.stripeOrgID!, 'accountXeroOrgID', eventKey!)
							: props.handleOrgAdd(props.stripeOrg.stripeOrg!.stripeOrgID!, eventKey!)
					}
				>
					{props.mappedXeroOrg && <Dropdown.Item eventKey={''}>Remove mapping</Dropdown.Item>}
					{props.xeroOrgs.map((xo) => (
						<Dropdown.Item key={xo.accountXeroOrgID} eventKey={xo.accountXeroOrgID}>
							{xo.xeroOrg!.name}
						</Dropdown.Item>
					))}
				</DropdownButton>
			</Col>
		</Form.Group>
	)
}

export { OrgMappingHeader }
