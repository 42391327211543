import cognito from './auth.json' // eslint-disable-line
import cognitoProd from './auth-prod.json' // eslint-disable-line
import aws from './aws.json'
import stripeConfig from './stripe.json'
import { productContext } from '../utilities/context'

// change both these on a deploy
const cognitoToUse = aws.Stage === 'prod' ? cognitoProd : cognito
const product = productContext.charAt(0).toUpperCase() + productContext.slice(1) // 'Oversight' || 'Leavecal' || 'Tayg' || 'Subsync'
// eslint-disable-next-line
const auth = (cognitoToUse as any)[product] // change to cognitoProd for prod deploy
// eslint-disable-next-line
const url = (aws as any)[product + 'Url'] // change to OversightUrl for oversight deploy or LeavecalUrl for leavecal deploy or 'TaygUrl'

const stripe = stripeConfig[aws.Stage as 'dev' | 'prod']

export const awsConfig = {
	Auth: {
		region: auth.Auth.region,
		userPoolId: auth.Auth.userPoolId,
		userPoolWebClientId: auth.Auth.userPoolWebClientId,
		oauth: {
			domain: auth.Auth.domain,
			scope: ['openid', 'profile', 'email', 'aws.cognito.signin.user.admin'],
			redirectSignIn: url,
			redirectSignOut: url,
			responseType: 'code',
		},
	},
}

export const ApiConfig = {
	ControllerBaseUrl: aws.ServiceEndpoint,
}

export const S3Config = {
	BaseUrl: aws.S3BucketBaseUrl,
}

export const StripeConfig = {
	InstallLink: stripe.install_link,
	AppId: stripe.app_id,
}

export const signUpConfig = {
	header: 'Oversight Sign Up',
	hideAllDefaults: true,
	hiddenDefaults: [],
	defaultCountryCode: '1',
	signUpFields: [
		{
			label: 'Email',
			key: 'email',
			required: true,
			displayOrder: 1,
			type: 'string',
			placeholder: 'Email',
		},
		{
			label: 'Password',
			key: 'password',
			required: true,
			displayOrder: 2,
			type: 'password',
			placeholder: 'Password',
		},
		{
			label: 'Given Name',
			key: 'given_name',
			required: true,
			displayOrder: 3,
			type: 'string',
			placeholder: 'Given Name',
		},
		{
			label: 'Family Name',
			key: 'family_name',
			required: true,
			displayOrder: 4,
			type: 'string',
			placeholder: 'Family Name',
		},
		{
			label: 'Account Name',
			key: 'name',
			required: true,
			displayOrder: 5,
			type: 'string',
			placeholder: 'Account Name',
		},
	],
}
