import React from 'react'
import { Form, Row, Col, DropdownButton, Dropdown, Badge, OverlayTrigger, Tooltip } from 'react-bootstrap'

// components
import { ArrowRight } from 'react-feather'

// definitions
import { AccountXeroOrgWithData } from '../../../../screens/subsync/subsync'
import { StripeOrgAccountXeroOrg, StripeProduct, XeroAccount, XeroItem } from '../../../../../../back-end/utilities/apiDefinitions'

interface ProductMappingProps {
	stripeProducts: StripeProduct[]
	stripeOrgID: string
	orgMapping: StripeOrgAccountXeroOrg
	mappedXeroOrg: AccountXeroOrgWithData | undefined
	handleProductUpdate: (stripeOrgID: string, stripeProductID: string, name: string, value: string) => void
	handleProductAdd: (stripeOrgID: string, stripeProductID: string, name: string, value: string) => void
	handleApplyToAllProducts: (stripeOrgID: string, name: string, value: string) => void
}

const ProductMapping = (props: ProductMappingProps) => {
	const [applyToAllSelected, setApplyToAllSelected] = React.useState<boolean>(false)

	return (
		<>
			{props.stripeProducts.map((product, index) => {
				const productMapping = props.orgMapping.stripeProductXeroMappings!.find((mapping) => mapping.stripeProductID === product.stripeProductID)
				const selectedItem = productMapping
					? props.orgMapping.stripeOrgAccountXeroOrgMappingType === 'Account'
						? props.mappedXeroOrg!.accounts.find((account) => account.xeroAccountID === productMapping.xeroAccountID)
						: props.mappedXeroOrg!.items.find((item) => item.xeroItemID === productMapping.xeroItemID)
					: undefined
				return (
					<Form.Group as={Row} key={product.stripeProductID}>
						<Col
							sm={3}
							style={{
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'flex-end',
								width: '20vw',
							}}
						>
							{product.stripeID?.startsWith('prod_') ? (
								<OverlayTrigger
									placement={'left'}
									overlay={
										<Tooltip id={`${product.stripeProductID}-tooltip`}>
											{product.stripeID}
											{product.description ? `: ${product.description}` : ''}
										</Tooltip>
									}
								>
									<div className="subsync-settings-label-small">
										{product.name}
										{/* isActive can be null for products created before column was added and not yet updated */}
										{product.isActive === false ? ' [Archived]' : ''}
									</div>
								</OverlayTrigger>
							) : (
								<div className="subsync-settings-label-small">{product.name}</div>
							)}
						</Col>
						<Col
							sm={1}
							style={{
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
								width: '20vw',
							}}
						>
							<ArrowRight size="16"></ArrowRight>
						</Col>
						<Col
							sm={4}
							style={{
								display: 'flex',
								alignItems: 'center',
								width: '20vw',
							}}
						>
							<DropdownButton
								id={'mapping-account-select'}
								title={
									productMapping &&
									productMapping[`xero${props.orgMapping.stripeOrgAccountXeroOrgMappingType as 'Account' | 'Item'}ID`] === 'N/A'
										? 'Not in use'
										: selectedItem
										? selectedItem.name
										: `Select Xero ${props.orgMapping.stripeOrgAccountXeroOrgMappingType}`
								}
								onSelect={(eventKey) => {
									if (productMapping) {
										props.handleProductUpdate(
											props.stripeOrgID,
											product.stripeProductID!,
											`xero${props.orgMapping.stripeOrgAccountXeroOrgMappingType}ID`,
											eventKey!
										)
									} else {
										props.handleProductAdd(
											props.stripeOrgID,
											product.stripeProductID!,
											`xero${props.orgMapping.stripeOrgAccountXeroOrgMappingType}ID`,
											eventKey!
										)
									}
									if (applyToAllSelected) {
										props.handleApplyToAllProducts(
											props.stripeOrgID,
											`xero${props.orgMapping.stripeOrgAccountXeroOrgMappingType}ID`,
											eventKey!
										)
									}
								}}
								disabled={applyToAllSelected && index > 0}
							>
								{props.orgMapping.stripeOrgAccountXeroOrgMappingType === 'Account' ? (
									<>
										<Dropdown.Header>Revenue</Dropdown.Header>
										{props
											.mappedXeroOrg!.accounts.filter((account) => account.type === 'REVENUE')
											.sort((a, b) => a.name!.localeCompare(b.name!, undefined, { numeric: true }))
											.map((xa) => (
												<Dropdown.Item key={xa.xeroAccountID} eventKey={xa.xeroAccountID}>
													{xa.name}
												</Dropdown.Item>
											))}
										<Dropdown.Header>Other Income</Dropdown.Header>
										{props
											.mappedXeroOrg!.accounts.filter((account) => account.type === 'OTHERINCOME')
											.sort((a, b) => a.name!.localeCompare(b.name!, undefined, { numeric: true }))
											.map((xa) => (
												<Dropdown.Item key={xa.xeroAccountID} eventKey={xa.xeroAccountID}>
													{xa.name}
												</Dropdown.Item>
											))}
										<Dropdown.Header>Sales</Dropdown.Header>
										{props
											.mappedXeroOrg!.accounts.filter((account) => account.type === 'SALES')
											.sort((a, b) => a.name!.localeCompare(b.name!, undefined, { numeric: true }))
											.map((xa) => (
												<Dropdown.Item key={xa.xeroAccountID} eventKey={xa.xeroAccountID}>
													{xa.name}
												</Dropdown.Item>
											))}
										<Dropdown.Header>Current Liability</Dropdown.Header>
										{props
											.mappedXeroOrg!.accounts.filter((account) => account.type === 'CURRLIAB')
											.sort((a, b) => a.name!.localeCompare(b.name!, undefined, { numeric: true }))
											.map((xa) => (
												<Dropdown.Item key={xa.xeroAccountID} eventKey={xa.xeroAccountID}>
													{xa.name}
												</Dropdown.Item>
											))}
									</>
								) : (
									props
										.mappedXeroOrg!.items.sort((a, b) => a.code!.localeCompare(b.code!, undefined, { numeric: true }))
										.map((xi) => (
											<Dropdown.Item key={xi.xeroItemID} eventKey={xi.xeroItemID}>
												<Badge variant="light" className="p-1 mr-1">
													{xi.code}
												</Badge>
												{xi.name}
											</Dropdown.Item>
										))
								)}
								{product.isActive === false && (
									<>
										<Dropdown.Header></Dropdown.Header>
										<Dropdown.Item eventKey="N/A">Not in use</Dropdown.Item>
									</>
								)}
							</DropdownButton>
						</Col>
						<Col sm={3} style={{ width: '20vw' }}>
							{index === 0 && (
								<Form.Check
									type={'checkbox'}
									id={'applyToAllProducts'}
									name={'stripeFeesBillsEnabled'}
									label={'Apply to All Products'}
									checked={applyToAllSelected}
									onChange={(e) => {
										setApplyToAllSelected(e.target.checked)
										if (e.target.checked && selectedItem) {
											props.handleApplyToAllProducts(
												props.stripeOrgID,
												`xero${props.orgMapping.stripeOrgAccountXeroOrgMappingType}ID`,
												props.orgMapping.stripeOrgAccountXeroOrgMappingType === 'Account'
													? (selectedItem as XeroAccount).xeroAccountID!
													: (selectedItem as XeroItem).xeroItemID!
											)
										}
									}}
								/>
							)}
						</Col>
					</Form.Group>
				)
			})}
		</>
	)
}

export { ProductMapping }
export type { ProductMappingProps }
