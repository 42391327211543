import React from 'react'

// definitions
import * as AppDefinitions from '../../App.d'
import * as RecordStatus from '../../types/recordStatus'
import * as Product from '../../constants/products'

// components
import { Col, Row, FormLabel, FormGroup, Button, Form, OverlayTrigger, Tooltip, Modal, Popover, ButtonGroup, Dropdown, DropdownButton } from 'react-bootstrap'
import { AccountXeroOrg, Calendar as CalendarData, CalendarLeaveType, Google, LeaveType } from '../../../../back-end/utilities/apiDefinitions'
import * as Messages from '../../components/ui/messages/messages'

// utillites
import * as Request from '../../utilities/request'
import * as I from '../../utilities/me'
import { FormControl, FormControlLabel, Checkbox } from '@material-ui/core'
import { Edit, HelpCircle, Trash2 } from 'react-feather'
import { Dialog } from '../ui/dialog/dialog'
import { Plus, Info } from 'react-feather'
import LoadingImage from '../../images/Finlert-loading-GIF_100px.gif'
import { MessageAction } from '../ui/messages/message'
import { ColorPicker } from 'material-ui-color'

const allColours = { Blue: '#8aa6ff', Green: '#86A581', Lavendar: '#E4B1F7', Peach: '#F89995', Yellow: '#F8C950' }
interface SettingsFrameProps {
	show: boolean
	appState: AppDefinitions.AppState
	currentOrg: AccountXeroOrg
	currentCalendar: CalendarData
	updateCurrentCalendar: (cal: CalendarData) => void
	updateCurrentOrg: (xero: AccountXeroOrg) => void
	pageStatus: string
	updatePageStatus: React.Dispatch<React.SetStateAction<string>>
}

const newLeaveType = {
	name: '',
	xeroID: '',
	xeroOrgID: '',
	isActive: true,
	isNonXero: true,
	hexColour: '#86A581',
	isEnteredByAdmin: true,
	unitType: 'Hours',
}

interface IcsFeed {
	name: string
	url: string
}

const presetIcsFeeds: IcsFeed[] = [
	{ name: 'Australia', url: 'https://www.officeholidays.com/ics/australia' },
	{ name: '\u00A0\u00A0NSW', url: 'https://www.officeholidays.com/ics/australia/new-south-wales' },
	{ name: '\u00A0\u00A0VIC', url: 'https://www.officeholidays.com/ics/australia/victoria' },
	{ name: '\u00A0\u00A0QLD', url: 'https://www.officeholidays.com/ics/australia/queensland' },
	{ name: '\u00A0\u00A0ACT', url: 'https://www.officeholidays.com/ics/australia/act' },
	{ name: '\u00A0\u00A0WA', url: 'https://www.officeholidays.com/ics/australia/western-australia' },
	{ name: '\u00A0\u00A0SA', url: 'https://www.officeholidays.com/ics/australia/south-australia' },
	{ name: '\u00A0\u00A0TAS', url: 'https://www.officeholidays.com/ics/australia/tasmania' },
	{ name: '\u00A0\u00A0NT', url: 'https://www.officeholidays.com/ics/australia/northern-territory' },
	{ name: 'United Kingdom', url: 'https://www.officeholidays.com/ics/united-kingdom' },
	{ name: 'New Zealand', url: 'https://www.officeholidays.com/ics/new-zealand' },
	{ name: 'Philippines', url: 'https://www.officeholidays.com/ics/philippines' },
]

interface CalendarNameProps {
	appState: AppDefinitions.AppState
	updateMessages: (message: MessageAction) => void
	currentCalendar: CalendarData
	updateCurrentCalendar: (cal: CalendarData) => void
	calendarSettings: CalendarData | null
	updateCalendarSettings: (cal: CalendarData | null) => void
}

interface OrgHoursInDaysProps {
	appState: AppDefinitions.AppState
	updateMessages: (message: MessageAction) => void
	currentOrg: AccountXeroOrg
	updateCurrentOrg: (xero: AccountXeroOrg) => void
}

interface LeaveTypeRowProps {
	leaveType: LeaveType
	appState: AppDefinitions.AppState
	updateMessages: (message: MessageAction) => void
	updateXeroLeaveTypes: (lv: LeaveType[]) => void
	onChecked: (xeroOrgLeaveTypeID: string) => Promise<void>
	onUnChecked: (xeroOrgLeaveTypeID: string) => Promise<void>
	xeroLeaveTypes: LeaveType[]
	currentOrg: AccountXeroOrg
	currentCalendar: CalendarData
	updateCalendar: (cal: CalendarData) => void
	isCustom: boolean
}

const LeaveTypeRow = (props: LeaveTypeRowProps) => {
	const [showEditLeaveTypeDialog, updateShowEditLeaveTypeDialog] = React.useState<boolean>(false)
	const checked = props.currentCalendar.leaveTypes
		? props.currentCalendar.leaveTypes.some((lt) => lt.xeroOrgLeaveTypeID === props.leaveType.xeroOrgLeaveTypeID)
		: false

	return (
		<Col sm="auto" style={{ flex: '1', textAlign: 'left', paddingLeft: '20px' }}>
			<OverlayTrigger
				placement="auto"
				delay={{ hide: 1000, show: 0 }}
				overlay={
					<Popover id={`tooltip-edit-custom-type`}>
						<Button onClick={() => updateShowEditLeaveTypeDialog(true)}>
							Edit <Edit className={'settings-plus'}></Edit>
						</Button>
					</Popover>
				}
			>
				<FormControlLabel
					control={
						<Checkbox
							color={'default'}
							name={props.leaveType.xeroOrgLeaveTypeID}
							checked={checked}
							disabled={!props.leaveType.isActive}
							onClick={() =>
								checked ? props.onChecked(props.leaveType.xeroOrgLeaveTypeID!) : props.onUnChecked(props.leaveType.xeroOrgLeaveTypeID!)
							}
						/>
					}
					label={
						<div style={{ flex: 'auto' }}>
							<div
								style={{
									color: `${props.leaveType.hexColour}`,
									width: '20px',
									height: '20px',
									border: 'solid 10px',
									float: 'left',
									margin: '2px 5px 0px 0px',
								}}
							/>
							<div>
								{props.leaveType.name}
								{props.isCustom ? '' : <i style={{ opacity: '0.5' }}>{`  ${props.leaveType.unitType}`}</i>}
							</div>
						</div>
					}
				/>
			</OverlayTrigger>
			<LeaveTypePopUp
				show={showEditLeaveTypeDialog}
				handleClose={() => updateShowEditLeaveTypeDialog(false)}
				titleText={'Edit'}
				type={'Edit'}
				updateMessages={props.updateMessages}
				updateXeroLeaveTypes={props.updateXeroLeaveTypes}
				updateCalendar={props.updateCalendar}
				appState={props.appState}
				leaveTypes={props.xeroLeaveTypes}
				xeroOrgID={props.currentOrg.xeroOrg!.xeroOrgID!}
				calendarID={props.currentCalendar.calendarID!}
				currentLeaveType={props.leaveType}
				calendar={props.currentCalendar}
				isCustom={props.isCustom}
			/>
		</Col>
	)
}

const CalendarName = (props: CalendarNameProps) => {
	const [isNameDisabled, updateNameDisabled] = React.useState<boolean>(true)

	const postNameChange = async () => {
		if (I.have('admin', props.appState.permission[props.appState.context])) {
			try {
				if (props.currentCalendar.fileName!.length > 0) {
					await Request.put(`calendar/${props.calendarSettings!.calendarID}`, props.calendarSettings, props.appState.authState)
					props.updateCurrentCalendar(props.calendarSettings!)
					props.updateMessages(Messages.addMessage('success', 'Succesfully updated calendar name'))
				} else {
					updateNameDisabled(false)
					props.updateMessages(Messages.addMessage('danger', 'Calendar name cannot be empty.'))
					return
				}
			} catch (err) {
				console.log('Update Error: ', err)
				props.updateMessages(Messages.addMessage('danger', 'Failed to update calendar name'))
			}
		} else {
			props.updateMessages(Messages.addMessage('danger', 'You do no have permission to update a calendar name'))
		}
	}
	const resetName = async () => {
		props.updateCurrentCalendar(props.currentCalendar)
	}

	return (
		<>
			<Form.Control
				type="text"
				id={props.calendarSettings!.calendarID!}
				name="name"
				placeholder=""
				readOnly={isNameDisabled}
				plaintext={isNameDisabled}
				onClick={() => updateNameDisabled(false)}
				onChange={(event) => {
					const value = event.target.value
					props.updateCalendarSettings({
						...props.calendarSettings,
						fileName: value,
					})
				}}
				value={props.calendarSettings!.fileName}
				style={{
					display: 'inline',
					width: '50%',
					paddingLeft: '12px',
					fontSize: '16px',
				}}
				className={!I.have('admin', props.appState.permission[props.appState.context]) ? 'no-hover' : ''}
			/>
			{!isNameDisabled ? (
				<Button
					type="button"
					style={{ margin: '0px 10px 10px 10px', fontSize: '16px' }}
					variant="success"
					onClick={() => {
						updateNameDisabled(true)
						postNameChange()
					}}
				>
					Save
				</Button>
			) : null}
			{!isNameDisabled ? (
				<Button
					type="button"
					style={{ margin: '0px 5px 10px 5px', fontSize: '16px' }}
					onClick={() => {
						updateNameDisabled(true)
						resetName()
					}}
				>
					Cancel
				</Button>
			) : null}
		</>
	)
}

const OrgHoursInDay = (props: OrgHoursInDaysProps) => {
	const [isHoursInDayDisabled, updateHoursInDayDisabled] = React.useState<boolean>(true)
	const [hoursInDay, updateHoursInDay] = React.useState<number>(props.currentOrg.xeroOrg!.hoursInDay! || 0.0)

	const postHoursInDayChange = async () => {
		if (I.have('admin', props.appState.permission[props.appState.context])) {
			try {
				if (hoursInDay > 0) {
					await Request.put(`xeroorg`, { ...props.currentOrg.xeroOrg!, hoursInDay: hoursInDay }, props.appState.authState)
					props.updateCurrentOrg({
						...props.currentOrg,
						xeroOrg: { ...props.currentOrg.xeroOrg!, hoursInDay: hoursInDay },
					})
					props.updateMessages(Messages.addMessage('success', 'Succesfully updated standard number of hours in a day'))
				} else {
					updateHoursInDayDisabled(false)
					props.updateMessages(Messages.addMessage('danger', 'Standard number of hours in a day should be more than 0'))
					return
				}
			} catch (err) {
				console.log('Update Error: ', err)
				props.updateMessages(Messages.addMessage('danger', 'Failed to update standard number of hours in a day'))
			}
		} else {
			props.updateMessages(Messages.addMessage('danger', 'You do no have permission to update the standard number of hours in a day'))
		}
	}
	const resetHoursinDay = async () => {
		updateHoursInDay(props.currentOrg.xeroOrg!.hoursInDay!)
	}

	return (
		<>
			<Form.Control
				type="number"
				step="0.1"
				min="0"
				max="24"
				id={props.currentOrg.xeroOrgID!}
				name="hours in day"
				placeholder=""
				readOnly={isHoursInDayDisabled}
				plaintext={isHoursInDayDisabled}
				onClick={() => updateHoursInDayDisabled(false)}
				onChange={(event) => {
					const value = event.target.value
					updateHoursInDay(Number(value))
				}}
				value={hoursInDay}
				style={{
					display: 'inline',
					width: '50%',
					paddingLeft: '12px',
					fontSize: '16px',
				}}
				className={!I.have('admin', props.appState.permission[props.appState.context]) ? 'no-hover' : ''}
			/>
			{!isHoursInDayDisabled ? (
				<Button
					type="button"
					style={{ margin: '0px 10px 10px 10px', fontSize: '16px' }}
					variant="success"
					onClick={() => {
						updateHoursInDayDisabled(true)
						postHoursInDayChange()
					}}
				>
					Save
				</Button>
			) : null}
			{!isHoursInDayDisabled ? (
				<Button
					type="button"
					style={{ margin: '0px 5px 10px 5px', fontSize: '16px' }}
					onClick={() => {
						updateHoursInDayDisabled(true)
						resetHoursinDay()
					}}
				>
					Cancel
				</Button>
			) : null}
		</>
	)
}

const CalendarFirstDayOfWeek = (props: CalendarNameProps) => {
	const DaysOfTheWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']

	const postFirstDayOfWeekChange = async (selected: number) => {
		if (I.have('admin', props.appState.permission[props.appState.context])) {
			try {
				await Request.put(
					`calendar/${props.calendarSettings!.calendarID}`,
					{ ...props.calendarSettings, firstDayOfWeek: selected },
					props.appState.authState
				)
				props.updateCurrentCalendar({
					...props.calendarSettings!,
					firstDayOfWeek: selected,
				})
				props.updateMessages(Messages.addMessage('success', 'Succesfully updated first day of the week'))
			} catch (err) {
				console.log('Update Error: ', err)
				props.updateMessages(Messages.addMessage('danger', 'Failed to update first day of the week'))
			}
		} else {
			props.updateMessages(Messages.addMessage('danger', `You do no have permission to update a calendar's first day of the week`))
		}
	}

	return (
		<>
			<DropdownButton
				as={ButtonGroup}
				id={'dropdown'}
				variant={'primary'}
				title={DaysOfTheWeek[props.calendarSettings!.firstDayOfWeek!]}
				onSelect={(eventKey) => {
					if (eventKey) {
						const key = Number(eventKey)
						postFirstDayOfWeekChange(key)
					}
				}}
				alignRight
				style={{
					display: 'inline',
					width: '50%',
					paddingLeft: '0px',
					fontSize: '14px',
				}}
				className={!I.have('admin', props.appState.permission[props.appState.context]) ? 'no-hover' : ''}
			>
				{DaysOfTheWeek.map((day, index) => (
					<Dropdown.Item key={day} eventKey={String(index)} active={props.calendarSettings!.firstDayOfWeek! === index}>
						{day}
					</Dropdown.Item>
				))}
			</DropdownButton>
		</>
	)
}

interface LeaveTypePopUpProps {
	show: boolean
	appState: AppDefinitions.AppState
	updateMessages: (message: MessageAction) => void
	handleClose: () => void
	titleText: string
	type: 'Add' | 'Edit'
	leaveTypes: LeaveType[] | null
	updateXeroLeaveTypes: (lv: LeaveType[]) => void
	updateCalendar: (calendar: CalendarData) => void
	xeroOrgID: string
	calendarID: string
	calendar: CalendarData
	currentLeaveType: LeaveType | null
	isCustom: boolean
}

const LeaveTypePopUp = (props: LeaveTypePopUpProps) => {
	const [leaveType, updateleaveType] = React.useState<LeaveType>(props.currentLeaveType ? props.currentLeaveType : newLeaveType)

	const handleCustomTypeCreation = async () => {
		try {
			const req = await Request.post(`leavetype`, { ...leaveType, xeroOrgID: props.xeroOrgID }, props.appState.authState)

			if (req.data.success && req.data.leaveTypes.length > 0) {
				const xeroOrgLeaveTypeID = req.data.leaveTypes[0].xeroOrgLeaveTypeID
				await Request.post(
					`calendar/${props.calendarID}/leavetype`,
					{
						calendarID: props.calendarID,
						xeroOrgLeaveTypeID,
						recordStatusID: RecordStatus.Active,
					},
					props.appState.authState
				)
				props.updateMessages({
					type: 'add',
					data: {
						severity: 'success',
						message: 'Successfully created custom leave type',
						timeout: 3000,
						dismissible: true,
					},
				})
				const updatedCalendar = JSON.parse(JSON.stringify(props.calendar))
				updatedCalendar.leaveTypes!.push({ xeroOrgLeaveTypeID })
				props.updateCalendar(updatedCalendar)
				props.updateXeroLeaveTypes(props.leaveTypes!.concat(req.data.leaveTypes).sort((a, b) => a.name!.localeCompare(b.name!)))
				updateleaveType(newLeaveType)
			}
		} catch (err) {
			props.updateMessages({
				type: 'add',
				data: {
					severity: 'danger',
					message: 'Unable to create custom leave type',
					timeout: 3000,
					dismissible: true,
				},
			})
		}
	}

	const handleLeaveTypeEdit = async () => {
		try {
			const req = await Request.put(`leavetype/${leaveType.xeroOrgLeaveTypeID}`, { ...leaveType, xeroOrgID: props.xeroOrgID }, props.appState.authState)

			if (req.data.success) {
				props.updateMessages({
					type: 'add',
					data: {
						severity: 'success',
						message: 'Successfully updated custom leave type',
						timeout: 3000,
						dismissible: true,
					},
				})
			}
			const updatedTypes = props.leaveTypes!.map((lv: LeaveType) => {
				if (lv.xeroOrgLeaveTypeID === req.data.leaveTypes[0].xeroOrgLeaveTypeID) {
					return req.data.leaveTypes[0]
				} else {
					return lv
				}
			})
			props.updateXeroLeaveTypes(updatedTypes)
		} catch (err) {
			props.updateMessages({
				type: 'add',
				data: {
					severity: 'danger',
					message: 'Unable to update custom leave type',
					timeout: 3000,
					dismissible: true,
				},
			})
		}
	}

	const handleCustomTypeDelete = async () => {
		try {
			const req = await Request.del(`leavetype/${leaveType.xeroOrgLeaveTypeID}`, props.appState.authState)
			if (req.data.success) {
				props.updateMessages({
					type: 'add',
					data: {
						severity: 'success',
						message: 'Successfully deleted custom leave type',
						timeout: 3000,
						dismissible: true,
					},
				})
			}
			const updatedTypes = props.leaveTypes!.filter((lv: LeaveType) => lv.xeroOrgLeaveTypeID !== leaveType.xeroOrgLeaveTypeID)
			props.updateXeroLeaveTypes(updatedTypes)
		} catch (err) {
			props.updateMessages({
				type: 'add',
				data: {
					severity: 'danger',
					message: 'Unable to delete custom leave type',
					timeout: 3000,
					dismissible: true,
				},
			})
		}
	}

	return (
		<Modal
			show={props.show}
			aria-labelledby="modal"
			centered
			onHide={() => {
				updateleaveType({ ...props.currentLeaveType })
				props.handleClose()
			}}
		>
			{props.titleText ? (
				<Modal.Header>
					<Modal.Title>{props.titleText}</Modal.Title>
					{props.isCustom ? (
						<OverlayTrigger
							placement="auto"
							overlay={
								<Tooltip id={`tooltip-xero`}>
									Custom types can be created to describe all types of activities in an office. They can be used to define working location,
									fixed day&apos;s off or any other item that is not captured in Xero.
								</Tooltip>
							}
						>
							<div>
								<HelpCircle className={'settings-plus'} />
							</div>
						</OverlayTrigger>
					) : null}
				</Modal.Header>
			) : null}
			<Modal.Body>
				<FormGroup>
					<Row style={{ paddingLeft: '16px', alignItems: 'center' }}>
						<div className="settings-colourpicker">
							<ColorPicker
								onChange={(value) => updateleaveType({ ...leaveType, hexColour: '#' + value.hex })}
								value={leaveType.hexColour}
								palette={allColours}
								hideTextfield
								disableAlpha
								disableTextfield
								inputFormats={[]}
							/>
						</div>
						<Form.Control
							type="text"
							style={{ width: '75%' }}
							readOnly={!props.isCustom}
							value={leaveType.name}
							onChange={(e) => {
								const value = e.target.value
								updateleaveType({ ...leaveType, name: value })
							}}
						/>
						{props.isCustom ? (
							<Col sm="2" style={{ flex: '1', textAlign: 'right' }}>
								<OverlayTrigger placement="right" overlay={<Tooltip id={`tooltip-remove-custom-type`}>Delete Custom Type</Tooltip>}>
									<Trash2
										style={{
											cursor: 'pointer',
											width: '30px',
											height: '30px',
											textAlign: 'right',
										}}
										onClick={() => {
											props.handleClose()
											handleCustomTypeDelete()
										}}
									/>
								</OverlayTrigger>
							</Col>
						) : (
							<i style={{ opacity: '0.5', padding: '8px' }}>{`  ${leaveType.unitType}`}</i>
						)}
					</Row>
					{props.isCustom ? (
						<>
							<Row>
								<FormControlLabel
									style={{ padding: '15px 0px 0px 5px' }}
									control={
										<Checkbox
											color={'default'}
											checked={leaveType.isEnteredByAdmin}
											name={'byAdmin'}
											onClick={() =>
												updateleaveType({
													...leaveType,
													isEnteredByAdmin: !leaveType.isEnteredByAdmin,
												})
											}
										/>
									}
									label={'Entered by Admin'}
								/>
								<OverlayTrigger
									placement="auto"
									overlay={
										<Tooltip id={`tooltip-xero-entered-by-admin`}>
											If selected, only administrators can create calendar entries for this type
										</Tooltip>
									}
								>
									<div style={{ padding: '23px 0px 0px 10px' }}>
										<HelpCircle className={'settings-plus'} />
									</div>
								</OverlayTrigger>
							</Row>
							<Row>
								<FormControlLabel
									style={{ padding: '15px 0px 0px 5px' }}
									control={
										<Checkbox
											color={'default'}
											checked={leaveType.showTypeInCalendar}
											name={'alwaysShowTypeInCalendar'}
											onClick={() =>
												updateleaveType({
													...leaveType,
													showTypeInCalendar: !leaveType.showTypeInCalendar,
												})
											}
										/>
									}
									label={'Always show Custom Type in calendar'}
								/>
								<OverlayTrigger
									placement="auto"
									overlay={
										<Tooltip id={`tooltip-xero-entered-by-admin`}>
											If selected, this Custom Type will always be displayed in calendar feeds and alerts even if &apos;Leave Type&apos;
											is not included
										</Tooltip>
									}
								>
									<div style={{ padding: '23px 0px 0px 10px' }}>
										<HelpCircle className={'settings-plus'} />
									</div>
								</OverlayTrigger>
							</Row>

							{props.type === 'Edit' ? (
								<Row>
									<FormControlLabel
										style={{ padding: '15px 0px 5px 5px' }}
										control={
											<Checkbox
												color={'default'}
												name={'active'}
												checked={leaveType.isActive}
												onClick={() =>
													updateleaveType({
														...leaveType,
														isActive: !leaveType.isActive,
													})
												}
											/>
										}
										label={'Active'}
									/>
								</Row>
							) : null}
						</>
					) : (
						<div style={{ paddingTop: '15px' }} />
					)}

					<Button
						variant={'success'}
						style={{ marginRight: '15px' }}
						onClick={() => {
							props.handleClose()
							if (props.type === 'Add') {
								handleCustomTypeCreation()
							} else {
								handleLeaveTypeEdit()
							}
						}}
					>
						Save
					</Button>
					<Button
						variant={'secondary'}
						onClick={() => {
							updateleaveType({ ...props.currentLeaveType })
							props.handleClose()
						}}
					>
						Cancel
					</Button>
				</FormGroup>
			</Modal.Body>
		</Modal>
	)
}

interface CustomFeedPopUpProps {
	show: boolean
	appState: AppDefinitions.AppState
	updateMessages: (message: MessageAction) => void
	currentCalendar: CalendarData
	updateCalendar: (cal: CalendarData) => void
	handleClose: () => void
	titleText: string
}

const CustomFeedPopUp = (props: CustomFeedPopUpProps) => {
	const [inputFeed, updateInputFeed] = React.useState<string>('')

	const handleCustomFeedCreation = async () => {
		try {
			const feed = await Request.post(`inputfeed/check`, { url: inputFeed }, props.appState.authState)
			if (feed.data && feed.data.isValid) {
				const req = await Request.post(`calendar/${props.currentCalendar.calendarID}/inputfeed`, { url: inputFeed }, props.appState.authState)

				if (req.data.success) {
					props.updateMessages({
						type: 'add',
						data: {
							severity: 'success',
							message: 'Successfully added external calendar',
							timeout: 3000,
							dismissible: true,
						},
					})
				}
				props.updateCalendar({
					...props.currentCalendar,
					inputFeeds: req.data.calendarInputFeeds,
				})
			} else {
				props.updateMessages({
					type: 'add',
					data: {
						severity: 'danger',
						message: 'That does not look like an ics feed',
						timeout: 3000,
						dismissible: true,
					},
				})
			}
		} catch (err) {
			props.updateMessages({
				type: 'add',
				data: {
					severity: 'danger',
					message: 'Unable to add external calendar',
					timeout: 3000,
					dismissible: true,
				},
			})
		}
	}

	return (
		<Modal show={props.show} aria-labelledby="modal" centered onHide={props.handleClose}>
			{props.titleText ? (
				<Modal.Header>
					<Modal.Title>{props.titleText}</Modal.Title>
					<OverlayTrigger
						placement="auto"
						overlay={
							<Tooltip id={`tooltip-xero`}>
								An external calendar (.ics) feed can combine an external calendar with our graphical and ics feeds.
							</Tooltip>
						}
					>
						<div>
							<HelpCircle className={'settings-plus'} />
						</div>
					</OverlayTrigger>
				</Modal.Header>
			) : null}
			<Modal.Body>
				<FormGroup>
					<DropdownButton
						as={ButtonGroup}
						id={'dropdown'}
						variant={'primary'}
						title={inputFeed ? presetIcsFeeds.find((e) => e.url === inputFeed)?.name ?? 'Custom URL' : 'Select one'}
						onSelect={(e) => {
							if (e) {
								updateInputFeed(e)
							}
						}}
						alignRight
						style={{
							paddingLeft: '0px',
							marginBottom: '15px',
							fontSize: '14px',
						}}
					>
						<Dropdown.Header>Public Holidays</Dropdown.Header>
						{presetIcsFeeds.map((e) => (
							<Dropdown.Item key={e.name} eventKey={e.url} active={inputFeed === e.url}>
								{e.name}
							</Dropdown.Item>
						))}
					</DropdownButton>
					<Form.Control
						type="text"
						value={inputFeed}
						style={{ marginBottom: '15px' }}
						placeholder="https://example.com/calendar-name.ics"
						onChange={(e) => {
							const value = e.target.value
							updateInputFeed(value)
						}}
					/>
					<Button
						variant={'success'}
						style={{ marginRight: '15px' }}
						onClick={() => {
							props.handleClose()
							handleCustomFeedCreation()
						}}
					>
						Save
					</Button>
					<Button variant={'secondary'} onClick={props.handleClose}>
						Cancel
					</Button>
				</FormGroup>
			</Modal.Body>
		</Modal>
	)
}

const SettingsFrame = (props: SettingsFrameProps) => {
	const [leaveTypes, updateLeaveTypes] = React.useState<LeaveType[] | null>(null)
	const [nonXeroLeaveTypes, updateNonXeroLeaveTypes] = React.useState<LeaveType[] | null>(null)
	const [calendarLeaveTypes, updateCalendarLeaveTypes] = React.useState<CalendarLeaveType[] | null>(null)
	const [messages, updateMessages] = Messages.useMessageReducer([])
	const [calendarSettings, updateCalendarSettings] = React.useState<CalendarData | null>(null)
	const [showDialog, updateShowDialog] = React.useState<boolean>(false)
	const [googleIntegration, updateGoogleIntegration] = React.useState<Google | null>(null)

	const [showAddCustomTypeDialog, updateShowAddCustomTypeDialog] = React.useState<boolean>(false)
	const [showAddCustomFeedDialog, updateShowAddCustomFeedDialog] = React.useState<boolean>(false)

	React.useEffect(
		() => {
			const fetchData = async () => {
				const [leaveTypesData, calendarLeaveTypesData, googleReq] = await Promise.all([
					Request.get(
						`leavetype?where=XeroOrgID==${props.currentOrg.xeroOrg!.xeroOrgID}&where=AccountXeroOrgID==${props.currentOrg.accountXeroOrgID}`,
						props.appState.authState
					),
					Request.get(`calendar/${props.currentCalendar.calendarID}/leavetype`, props.appState.authState),
					I.have('admin', props.appState.permission[props.appState.context]) ? Request.get('google', props.appState.authState) : { data: [] },
				])

				updateLeaveTypes(leaveTypesData.data.leaveTypes.filter((lt: LeaveType) => !lt.isNonXero))
				updateNonXeroLeaveTypes(
					leaveTypesData.data.leaveTypes
						.filter((lt: LeaveType) => lt.isNonXero)
						.sort((a: { name: string }, b: { name: string }) => a.name!.localeCompare(b.name!))
				)
				updateCalendarLeaveTypes(calendarLeaveTypesData.data.calendarLeaveTypes)
				updateCalendarSettings(props.currentCalendar)
				if (I.have('admin', props.appState.permission[props.appState.context]) && googleReq && googleReq.data) {
					updateGoogleIntegration(googleReq.data.google)
				}

				if (props.show) {
					props.updatePageStatus('Finished')
				}
			}

			if (props.appState.authState.isLoggedIn && props.currentCalendar.calendarID && props.currentOrg.xeroOrg) {
				fetchData()
			}
		},
		// eslint-disable-next-line
		[props.appState.authState, props.currentCalendar.calendarID, props.currentOrg.xeroOrg, props.currentCalendar, props.currentOrg.accountXeroOrgID]
	)

	/** Custom Types Column */
	const handleCustomTypeAdd = async (xeroOrgLeaveTypeID: string) => {
		const req = await Request.post(
			`calendar/${props.currentCalendar.calendarID}/leavetype`,
			{ xeroOrgLeaveTypeID, recordStatusID: RecordStatus.Active },
			props.appState.authState
		)

		if (req.data.success) {
			updateMessages({
				type: 'add',
				data: {
					severity: 'success',
					message: 'Successfully updated the information to be included',
					timeout: 3000,
					dismissible: true,
				},
			})
			const copyCalendar = JSON.parse(JSON.stringify(props.currentCalendar)) as CalendarData
			copyCalendar.leaveTypes!.push({ xeroOrgLeaveTypeID })
			props.updateCurrentCalendar(copyCalendar)
		} else {
			updateMessages({
				type: 'add',
				data: {
					severity: 'danger',
					message: 'Unable to update the information to be included',
					timeout: 3000,
					dismissible: true,
				},
			})
		}
	}

	const handleCustomTypeRemove = async (xeroOrgLeaveTypeID: string) => {
		const req = await Request.put(
			`calendar/${props.currentCalendar.calendarID}/leavetype/${xeroOrgLeaveTypeID}`,
			{ recordStatusID: RecordStatus.Deleted },
			props.appState.authState
		)

		if (req.data.success) {
			updateMessages({
				type: 'add',
				data: {
					severity: 'success',
					message: 'Successfully updated the information to be included',
					timeout: 3000,
					dismissible: true,
				},
			})
			const copyCalendar = JSON.parse(JSON.stringify(props.currentCalendar)) as CalendarData
			copyCalendar.leaveTypes = copyCalendar.leaveTypes!.filter((lt) => lt.xeroOrgLeaveTypeID !== xeroOrgLeaveTypeID)
			props.updateCurrentCalendar(copyCalendar)
		} else {
			updateMessages({
				type: 'add',
				data: {
					severity: 'danger',
					message: 'Unable to update the information to be included',
					timeout: 3000,
					dismissible: true,
				},
			})
		}
	}

	/** Leave Types Column */

	const checkIfAllLeaveSelected = () => {
		const leaveTypeIDs = leaveTypes!.map((lt) => lt.xeroOrgLeaveTypeID!)
		const calendarLeaveTypeIDs = calendarLeaveTypes!.map((lt) => lt.xeroOrgLeaveTypeID!)
		const containsAll = leaveTypeIDs!.every((element) => {
			return calendarLeaveTypeIDs!.includes(element)
		})
		return containsAll
	}

	const checkIfAllIncludedInfoSelected = () => {
		return (
			props.currentCalendar.showAmount &&
			props.currentCalendar.showLeaveType &&
			props.currentCalendar.showPayrollDescription &&
			props.currentCalendar.showCustomLeaveDescription &&
			((props.currentOrg.xeroOrg && props.currentOrg.xeroOrg.version !== 'AU' && props.currentOrg.xeroOrg.version !== 'AUONRAMP') ||
				(props.currentCalendar.showTitle && props.currentCalendar.showGroup))
		)
	}

	const newRecordStatus = (option: string) => {
		if (option === 'all') {
			if (checkIfAllLeaveSelected()) {
				return RecordStatus.Deleted
			} else {
				return RecordStatus.Active
			}
		} else {
			if (calendarLeaveTypes!.some((clt) => clt.xeroOrgLeaveTypeID === option)) {
				return RecordStatus.Deleted
			} else {
				return RecordStatus.Active
			}
		}
	}

	const handleLeaveTypeChange = async (option: string) => {
		if (option === 'all') {
			try {
				if (checkIfAllLeaveSelected()) {
					// deselect them all
					await Promise.all(
						calendarLeaveTypes!.map(async (clt) => {
							const result = await Request.put(
								`calendar/${props.currentCalendar.calendarID}/leavetype/${clt.xeroOrgLeaveTypeID}`,
								{ recordStatusID: RecordStatus.Deleted },
								props.appState.authState
							)
							return result.data.calendarLeaveTypes
						})
					)
					updateCalendarLeaveTypes([])

					updateMessages({
						type: 'add',
						data: {
							severity: 'success',
							message: 'Successfully updated leave type selections',
							timeout: 3000,
							dismissible: true,
						},
					})
				} else {
					//select the unselected
					const unselected = leaveTypes!.filter((lt) => !calendarLeaveTypes!.some((clt) => clt.xeroOrgLeaveTypeID === lt.xeroOrgLeaveTypeID))
					await Promise.all(
						unselected.map(async (xolt) => {
							const result = await Request.post(
								`calendar/${props.currentCalendar.calendarID}/leavetype`,
								{
									calendarID: props.currentCalendar.calendarID,
									xeroOrgLeaveTypeID: xolt.xeroOrgLeaveTypeID,
									recordStatusID: RecordStatus.Active,
								},
								props.appState.authState
							)

							return result.data.calendarLeaveTypes
						})
					)
					const final = await Request.get(`calendar/${props.currentCalendar.calendarID}/leavetype`, props.appState.authState)
					updateCalendarLeaveTypes(final.data.calendarLeaveTypes)

					updateMessages({
						type: 'add',
						data: {
							severity: 'success',
							message: 'Successfully updated leave type selections',
							timeout: 3000,
							dismissible: true,
						},
					})
				}
			} catch (err) {
				updateMessages({
					type: 'add',
					data: {
						severity: 'danger',
						message: 'Unable to update leave type selections',
						timeout: 3000,
						dismissible: true,
					},
				})
			}
		} else if (option !== 'all') {
			try {
				const newRecordStatusID = newRecordStatus(option)
				if (newRecordStatusID === RecordStatus.Active) {
					//we are adding a leave type
					await Request.post(
						`calendar/${props.currentCalendar.calendarID}/leavetype`,
						{
							calendarID: props.currentCalendar.calendarID,
							xeroOrgLeaveTypeID: option,
							recordStatusID: RecordStatus.Active,
						},
						props.appState.authState
					)
				} else {
					//we are deleting it
					await Request.put(
						`calendar/${props.currentCalendar.calendarID}/leavetype/${option}`,
						{ recordStatusID: RecordStatus.Deleted },
						props.appState.authState
					)
				}

				const final = await Request.get(`calendar/${props.currentCalendar.calendarID}/leavetype`, props.appState.authState)
				updateCalendarLeaveTypes(final.data.calendarLeaveTypes)

				updateMessages({
					type: 'add',
					data: {
						severity: 'success',
						message: 'Successfully updated leave type selections',
						timeout: 3000,
						dismissible: true,
					},
				})
			} catch (err) {
				updateMessages({
					type: 'add',
					data: {
						severity: 'danger',
						message: 'Unable to update leave type selections',
						timeout: 3000,
						dismissible: true,
					},
				})
			}
		}
	}

	const handleCalendarChange = async (option: string) => {
		if (option === 'all') {
			const updatedCalendar =
				props.currentCalendar.showAmount &&
				props.currentCalendar.showGroup &&
				props.currentCalendar.showLeaveType &&
				props.currentCalendar.showTitle &&
				props.currentCalendar.showPayrollDescription &&
				props.currentCalendar.showCustomLeaveDescription
					? {
							showAmount: false,
							showGroup: false,
							showLeaveType: false,
							showTitle: false,
							showPayrollDescription: false,
							showCustomLeaveDescription: false,
					  }
					: {
							showAmount: true,
							showGroup: true,
							showLeaveType: true,
							showTitle: true,
							showPayrollDescription: true,
							showCustomLeaveDescription: true,
					  }

			const req = await Request.put(`calendar/${props.currentCalendar.calendarID}`, updatedCalendar, props.appState.authState)

			if (req.data.success) {
				updateMessages({
					type: 'add',
					data: {
						severity: 'success',
						message: 'Successfully updated the information to be included',
						timeout: 3000,
						dismissible: true,
					},
				})
				props.updateCurrentCalendar({ ...props.currentCalendar, ...updatedCalendar })
			} else {
				updateMessages({
					type: 'add',
					data: {
						severity: 'danger',
						message: 'Unable to update the information to be included',
						timeout: 3000,
						dismissible: true,
					},
				})
			}
		} else if (option !== 'all') {
			const updatedCalendar: Partial<CalendarData> = {}
			const calendarProperty = option as keyof Pick<
				CalendarData,
				| 'showAmount'
				| 'showTitle'
				| 'showLeaveType'
				| 'outputToIcs'
				| 'outputToHtml'
				| 'outputToGoogle'
				| 'showUnapprovedLeave'
				| 'showPayrollDescription'
				| 'showCustomLeaveDescription'
			>
			updatedCalendar[calendarProperty] = !props.currentCalendar[calendarProperty]

			const req = await Request.put(`calendar/${props.currentCalendar.calendarID}`, updatedCalendar, props.appState.authState)

			if (req.data.success) {
				updateMessages({
					type: 'add',
					data: {
						severity: 'success',
						message: 'Successfully updated the information to be included',
						timeout: 3000,
						dismissible: true,
					},
				})
				props.updateCurrentCalendar({ ...props.currentCalendar, ...updatedCalendar })
			} else {
				updateMessages({
					type: 'add',
					data: {
						severity: 'danger',
						message: 'Unable to update the information to be included',
						timeout: 3000,
						dismissible: true,
					},
				})
			}
		}
	}

	/** Celebrate Column */

	const checkIfAllCelebrationsSelected = () => {
		return props.currentCalendar.showAnniversary && props.currentCalendar.showBirthday
	}

	const handleCelebrationChange = async (option: string) => {
		if (option === 'all') {
			const updatedCalendar =
				props.currentCalendar.showAnniversary && props.currentCalendar.showBirthday
					? {
							showAnniversary: false,
							showAnniversaryYears: false,
							showDayOfBirthday: true,
							showBirthday: false,
							newEmployee1Month: false,
							newEmployee3Months: false,
							newEmployee6Months: false,
							newEmployee18Months: false,
					  }
					: {
							showAnniversary: true,
							showAnniversaryYears: true,
							showDayOfBirthday: false,
							showBirthday: true,
							newEmployee1Month: true,
							newEmployee3Months: true,
							newEmployee6Months: true,
							newEmployee18Months: true,
					  }

			const req = await Request.put(`calendar/${props.currentCalendar.calendarID}`, updatedCalendar, props.appState.authState)

			if (req.data.success) {
				updateMessages({
					type: 'add',
					data: {
						severity: 'success',
						message: 'Successfully updated celebrate',
						timeout: 3000,
						dismissible: true,
					},
				})
				props.updateCurrentCalendar({ ...props.currentCalendar, ...updatedCalendar })
			} else {
				updateMessages({
					type: 'add',
					data: {
						severity: 'danger',
						message: 'Unable to update celebrate',
						timeout: 3000,
						dismissible: true,
					},
				})
			}
		} else if (option !== 'all') {
			const updatedCalendar = {
				showAnniversary: option === 'showAnniversary' ? !props.currentCalendar.showAnniversary : props.currentCalendar.showAnniversary,
				showAnniversaryYears:
					option === 'showAnniversaryYears' ? !props.currentCalendar.showAnniversaryYears : props.currentCalendar.showAnniversaryYears,
				showDayOfBirthday: option === 'showDayOfBirthday' ? !props.currentCalendar.showDayOfBirthday : props.currentCalendar.showDayOfBirthday,
				showBirthday: option === 'showBirthday' ? !props.currentCalendar.showBirthday : props.currentCalendar.showBirthday,
				newEmployee1Month: option === 'newEmployee1Month' ? !props.currentCalendar.newEmployee1Month : props.currentCalendar.newEmployee1Month,
				newEmployee3Months: option === 'newEmployee3Months' ? !props.currentCalendar.newEmployee3Months : props.currentCalendar.newEmployee3Months,
				newEmployee6Months: option === 'newEmployee6Months' ? !props.currentCalendar.newEmployee6Months : props.currentCalendar.newEmployee6Months,
				newEmployee18Months: option === 'newEmployee18Months' ? !props.currentCalendar.newEmployee18Months : props.currentCalendar.newEmployee18Months,
			}

			const req = await Request.put(`calendar/${props.currentCalendar.calendarID}`, updatedCalendar, props.appState.authState)

			if (req.data.success) {
				updateMessages({
					type: 'add',
					data: {
						severity: 'success',
						message: 'Successfully updated celebrate',
						timeout: 3000,
						dismissible: true,
					},
				})
				props.updateCurrentCalendar({ ...props.currentCalendar, ...updatedCalendar })
			} else {
				updateMessages({
					type: 'add',
					data: {
						severity: 'danger',
						message: 'Unable to update celebrate',
						timeout: 3000,
						dismissible: true,
					},
				})
			}
		}
	}

	// Delete calendar
	const deleteCurrentCalendar = async () => {
		try {
			const req = await Request.del(`calendar/${props.currentCalendar.calendarID}`, props.appState.authState)

			if (req.data.success) {
				updateMessages({
					type: 'add',
					data: {
						severity: 'success',
						message: 'Successfully deleted current calendar',
						timeout: 3000,
						dismissible: true,
					},
				})
			}
			localStorage.removeItem('selectedCalendarID')
			window.location.reload()
		} catch (err) {
			updateMessages({
				type: 'add',
				data: {
					severity: 'danger',
					message: 'Unable to delete current calendar',
					timeout: 3000,
					dismissible: true,
				},
			})
		}
	}

	const changeEmptyLeaveAlertPreference = async (option: boolean) => {
		try {
			const updatedCalendar = {
				sendEmptyLeaveAlert: option,
			}
			const req = await Request.put(`calendar/${props.currentCalendar.calendarID}`, updatedCalendar, props.appState.authState)
			if (req.data.success) {
				updateMessages({
					type: 'add',
					data: {
						severity: 'success',
						message: 'Successfully updated selection for empty alerts',
						timeout: 3000,
						dismissible: true,
					},
				})
				props.updateCurrentCalendar({ ...props.currentCalendar, ...updatedCalendar })
			}
		} catch (err) {
			updateMessages({
				type: 'add',
				data: {
					severity: 'danger',
					message: 'Unable to change selection for empty alertrs',
					timeout: 3000,
					dismissible: true,
				},
			})
		}
	}

	const changeShowLeaveInDaysPreference = async (option: boolean) => {
		if (I.have('admin', props.appState.permission[props.appState.context])) {
			try {
				const updatedXeroOrg = {
					...props.currentOrg.xeroOrg,
					showLeaveInDays: option,
				}
				const req = await Request.put(`xeroorg`, updatedXeroOrg, props.appState.authState)
				if (req.status) {
					updateMessages({
						type: 'add',
						data: {
							severity: 'success',
							message: 'Successfully updated selection for showing leave in days',
							timeout: 3000,
							dismissible: true,
						},
					})
					props.updateCurrentOrg({
						...props.currentOrg,
						xeroOrg: updatedXeroOrg,
					})
				}
			} catch (err) {
				updateMessages({
					type: 'add',
					data: {
						severity: 'danger',
						message: 'Unable to change selection for showing leave in days',
						timeout: 3000,
						dismissible: true,
					},
				})
			}
		} else {
			updateMessages(Messages.addMessage('danger', 'You do no have permission to change selection for showing leave in days'))
		}
	}

	// delete calendar input feed
	const deleteCalendarFeed = async (feedID: string) => {
		try {
			const req = await Request.del(`calendar/${props.currentCalendar.calendarID}/inputfeed/${feedID}`, props.appState.authState)

			if (req.data.success && props.currentCalendar && props.currentCalendar.inputFeeds) {
				props.updateCurrentCalendar({
					...props.currentCalendar,
					inputFeeds: props.currentCalendar.inputFeeds.filter((feed) => feed.calendarInputFeedID !== feedID),
				})
				updateMessages({
					type: 'add',
					data: {
						severity: 'success',
						message: 'Successfully deleted external calendar',
						timeout: 3000,
						dismissible: true,
					},
				})
			}
		} catch (err) {
			updateMessages({
				type: 'add',
				data: {
					severity: 'danger',
					message: 'Unable to delete external calendar',
					timeout: 3000,
					dismissible: true,
				},
			})
		}
	}

	if (props.show) {
		if (props.pageStatus === 'Finished') {
			return (
				<>
					<FormGroup className="settings-card">
						<Row>
							<Col sm="auto">
								<FormLabel className="top-settings-label">Calendar Name</FormLabel>
							</Col>
							{calendarSettings && (
								<Col>
									<CalendarName
										updateMessages={updateMessages}
										appState={props.appState}
										currentCalendar={props.currentCalendar}
										updateCurrentCalendar={props.updateCurrentCalendar}
										calendarSettings={calendarSettings}
										updateCalendarSettings={updateCalendarSettings}
									/>
								</Col>
							)}
							{!props.currentCalendar.isUndeletable ? (
								<>
									<Col sm="auto" style={{ flex: '1', textAlign: 'right' }}>
										<OverlayTrigger placement="auto" overlay={<Tooltip id={`tooltip-remove-calendar`}>Delete calendar</Tooltip>}>
											<Trash2
												style={{
													cursor: 'pointer',
													paddingTop: '5px',
													width: '30px',
													height: '30px',
													textAlign: 'right',
												}}
												onClick={() => updateShowDialog(true)}
											/>
										</OverlayTrigger>
									</Col>
									<Dialog
										show={showDialog}
										handleClose={() => updateShowDialog(false)}
										handleConfirm={deleteCurrentCalendar}
										titleText={'Delete calendar confirmation'}
										bodyText={'Are you sure you want to delete this calendar?'}
									/>
								</>
							) : (
								<Col sm="auto" style={{ flex: '1', textAlign: 'right' }}>
									<OverlayTrigger
										placement="auto"
										overlay={<Tooltip id={`tooltip-remove-calendar`}>This is an All Employees calendar that cannot be deleted.</Tooltip>}
									>
										<Trash2
											style={{
												paddingTop: '5px',
												width: '30px',
												height: '30px',
												textAlign: 'right',
												color: '#7c7e80',
											}}
										/>
									</OverlayTrigger>
								</Col>
							)}
						</Row>
					</FormGroup>
					<FormGroup className="settings-card">
						<Row>
							<Col sm="7">
								<Row>
									<Col sm="6">
										<FormLabel className="top-settings-label">First Day of the Week</FormLabel>
									</Col>
									{calendarSettings && (
										<Col sm="3">
											<CalendarFirstDayOfWeek
												updateMessages={updateMessages}
												appState={props.appState}
												currentCalendar={props.currentCalendar}
												updateCurrentCalendar={props.updateCurrentCalendar}
												calendarSettings={calendarSettings}
												updateCalendarSettings={updateCalendarSettings}
											/>
										</Col>
									)}
								</Row>
								<Row>
									<Col sm="6">
										<FormLabel className="top-settings-label">Send alerts when no leave</FormLabel>
									</Col>
									<Col sm="3" style={{ paddingLeft: '3px' }}>
										<OverlayTrigger
											placement="auto"
											overlay={
												<Tooltip id={`tooltip-empty-leave-alerts`}>
													{props.currentCalendar.sendEmptyLeaveAlert
														? 'Deselecting this will ensure you do not get an alert when there is no leave'
														: 'Selecting this will ensure you still get an alert even when there is no leave'}
												</Tooltip>
											}
										>
											<FormControlLabel
												control={
													<Checkbox
														color={'default'}
														checked={props.currentCalendar.sendEmptyLeaveAlert}
														onChange={() => {
															changeEmptyLeaveAlertPreference(props.currentCalendar.sendEmptyLeaveAlert ? false : true)
														}}
													/>
												}
												label={''}
											/>
										</OverlayTrigger>
									</Col>
								</Row>
								<Row>
									<Col sm="6">
										<FormLabel className="top-settings-label">Standard number of hours in a day</FormLabel>
									</Col>
									<Col sm="3" style={{ paddingLeft: '3px' }}>
										<OrgHoursInDay
											appState={props.appState}
											updateMessages={updateMessages}
											currentOrg={props.currentOrg}
											updateCurrentOrg={props.updateCurrentOrg}
										/>
									</Col>
								</Row>
								<Row>
									<Col sm="6">
										<FormLabel className="top-settings-label">Show leave in days</FormLabel>
									</Col>
									<Col sm="3" style={{ paddingLeft: '3px' }}>
										<OverlayTrigger
											placement="auto"
											overlay={
												<Tooltip id={`tooltip-show-leave-in-days`}>
													{props.currentOrg.xeroOrg && props.currentOrg.xeroOrg.showLeaveInDays
														? 'Deselecting will update calendar and alerts to show in hours'
														: 'Selecting will update calendars and alerts to show in days'}
												</Tooltip>
											}
										>
											<FormControlLabel
												control={
													<Checkbox
														color={'default'}
														checked={props.currentOrg.xeroOrg!.showLeaveInDays}
														onChange={() => {
															changeShowLeaveInDaysPreference(props.currentOrg.xeroOrg!.showLeaveInDays ? false : true)
														}}
													/>
												}
												label={''}
											/>
										</OverlayTrigger>
									</Col>
								</Row>
							</Col>
							<Col>
								<Row>
									<Col sm="6">
										<FormLabel className="top-settings-label">Calendar Outputs</FormLabel>
									</Col>
									{props.currentCalendar && props.currentCalendar.calendarID !== '' ? (
										<Col sm="4">
											<Row>
												<FormControlLabel
													style={{ width: '100%' }}
													control={
														<Checkbox
															color={'default'}
															checked={props.currentCalendar.outputToIcs}
															onChange={(e) => handleCalendarChange(e.target.name)}
															name={'outputToIcs'}
														/>
													}
													label={'Calendar Feed'}
												/>
											</Row>
											<Row>
												<FormControlLabel
													style={{ width: '100%' }}
													control={
														<Checkbox
															color={'default'}
															checked={props.currentCalendar.outputToHtml}
															onChange={(e) => handleCalendarChange(e.target.name)}
															name={'outputToHtml'}
														/>
													}
													label={'Graphical Calendar'}
												/>
											</Row>
											{googleIntegration && (
												<Row>
													<FormControlLabel
														style={{ width: '100%' }}
														control={
															<Checkbox
																color={'default'}
																checked={props.currentCalendar.outputToGoogle}
																onChange={(e) => handleCalendarChange(e.target.name)}
																name={'outputToGoogle'}
															/>
														}
														label={'Google'}
													/>
												</Row>
											)}
										</Col>
									) : null}
								</Row>
							</Col>
						</Row>
					</FormGroup>
					<FormGroup className="settings-card">
						<Row>
							<Col sm="3">
								<Row style={{ justifyContent: 'space-between' }}>
									<FormLabel className="settings-label">Included Leave Types</FormLabel>
								</Row>
								<Row>
									<Col>
										<FormControl component="fieldset" style={{ width: '100%' }}>
											<FormGroup>
												<div className="settings-box">
													<FormControlLabel
														style={{ width: '100%' }}
														control={
															<Checkbox
																color={'default'}
																checked={props.currentCalendar.showUnapprovedLeave}
																onChange={(e) => handleCalendarChange(e.target.name)}
																name={'showUnapprovedLeave'}
															/>
														}
														label={'Unapproved Leave'}
													/>
													{leaveTypes && leaveTypes.length > 0 && calendarLeaveTypes ? (
														<>
															<FormControlLabel
																style={{ width: '100%', fontSize: '14px' }}
																control={
																	<Checkbox
																		color={'default'}
																		checked={checkIfAllLeaveSelected()}
																		onChange={(e) => handleLeaveTypeChange(e.target.name)}
																		name={'all'}
																	/>
																}
																label={'Select All'}
															/>
															{leaveTypes.map((leavetype: LeaveType) => (
																<LeaveTypeRow
																	key={leavetype.xeroOrgLeaveTypeID}
																	leaveType={leavetype}
																	appState={props.appState}
																	updateMessages={updateMessages}
																	updateXeroLeaveTypes={updateLeaveTypes}
																	xeroLeaveTypes={leaveTypes}
																	onChecked={handleCustomTypeRemove}
																	onUnChecked={handleCustomTypeAdd}
																	currentOrg={props.currentOrg}
																	currentCalendar={props.currentCalendar}
																	updateCalendar={props.updateCurrentCalendar}
																	isCustom={false}
																/>
															))}
														</>
													) : null}
												</div>
											</FormGroup>
										</FormControl>
									</Col>
								</Row>
							</Col>

							<Col sm="3">
								<Row
									style={{
										justifyContent: 'space-between',
										backgroundColor: '#f9f9fa',
										marginBottom: '-10px',
									}}
								>
									<FormLabel className="settings-label">Custom Types</FormLabel>
									<Col sm="auto" className="position-right" style={{ padding: '20px' }}>
										{I.have('admin', props.appState.permission[props.appState.context]) &&
										props.currentOrg!.xeroOrg!.products!.some((pr) => pr.productID === Product.CustomLeaveTypes.productID) ? (
											<OverlayTrigger placement="auto" overlay={<Tooltip id={`tooltip-xero`}>Add another Custom Type</Tooltip>}>
												<div>
													<Plus className={'settings-plus'} onClick={() => updateShowAddCustomTypeDialog(true)} />
												</div>
											</OverlayTrigger>
										) : (
											<OverlayTrigger
												placement="auto"
												overlay={<Tooltip id={`tooltip-xero`}>Premium Feature - contact support@finlert.com to get started.</Tooltip>}
											>
												<div>
													<Plus className={'settings-plus'} style={{ color: '#82888a' }} />
												</div>
											</OverlayTrigger>
										)}
									</Col>
								</Row>
								{showAddCustomTypeDialog && (
									<LeaveTypePopUp
										show={showAddCustomTypeDialog}
										handleClose={() => updateShowAddCustomTypeDialog(false)}
										titleText={'Add Custom Type'}
										type={'Add'}
										updateMessages={updateMessages}
										updateXeroLeaveTypes={updateNonXeroLeaveTypes}
										updateCalendar={props.updateCurrentCalendar}
										appState={props.appState}
										leaveTypes={nonXeroLeaveTypes}
										xeroOrgID={props.currentOrg.xeroOrg!.xeroOrgID!}
										calendarID={props.currentCalendar.calendarID!}
										calendar={props.currentCalendar}
										currentLeaveType={null}
										isCustom={true}
									/>
								)}
								{I.have('admin', props.appState.permission[props.appState.context]) &&
								props.currentOrg!.xeroOrg!.products!.some((pr) => pr.productID === Product.CustomLeaveTypes.productID) ? (
									<Row>
										<FormGroup as={Col}>
											{props.currentCalendar &&
											props.currentCalendar.calendarID !== '' &&
											nonXeroLeaveTypes &&
											nonXeroLeaveTypes.length !== 0 ? (
												<div className="settings-box">
													{/* <FormControlLabel
                                                    style={{ paddingLeft: '20px' }}
                                                    control={<Checkbox color={'default'} name={'all'} onChange={(e) => handleCustomLeaveTypeChange(e.target.name)} checked={nonXeroLeaveTypes.every(choice => choice.isActive)} />}
                                                    label={'Select All'}
                                                /> */}

													{nonXeroLeaveTypes.map((nxlt: LeaveType) => (
														<LeaveTypeRow
															key={nxlt.xeroOrgLeaveTypeID}
															leaveType={nxlt}
															appState={props.appState}
															updateMessages={updateMessages}
															updateXeroLeaveTypes={updateNonXeroLeaveTypes}
															onUnChecked={handleCustomTypeAdd}
															onChecked={handleCustomTypeRemove}
															xeroLeaveTypes={nonXeroLeaveTypes}
															currentOrg={props.currentOrg}
															currentCalendar={props.currentCalendar}
															updateCalendar={props.updateCurrentCalendar}
															isCustom={true}
														/>
													))}
												</div>
											) : null}
										</FormGroup>
									</Row>
								) : null}
							</Col>

							<Col sm="3">
								<Row
									style={{
										justifyContent: 'space-between',
										backgroundColor: '#f9f9fa',
									}}
								>
									<FormLabel className="settings-label">Celebrate</FormLabel>
								</Row>
								<Row>
									<FormGroup as={Col}>
										{props.currentCalendar && props.currentCalendar.calendarID !== '' ? (
											<div className="settings-box">
												<FormControlLabel
													style={{ width: '100%' }}
													control={
														<Checkbox
															color={'default'}
															checked={checkIfAllCelebrationsSelected()}
															onChange={(e) => handleCelebrationChange(e.target.name)}
															name={'all'}
														/>
													}
													label={'Select All'}
												/>
												<FormControlLabel
													style={{ width: '100%' }}
													control={
														<Checkbox
															color={'default'}
															checked={props.currentCalendar.showAnniversary}
															onChange={(e) => handleCelebrationChange(e.target.name)}
															name={'showAnniversary'}
														/>
													}
													label={'Anniversaries'}
												/>
												<FormControlLabel
													style={{ width: '100%', paddingLeft: '30px' }}
													control={
														<Checkbox
															color={'default'}
															checked={props.currentCalendar.showAnniversaryYears}
															onChange={(e) => handleCelebrationChange(e.target.name)}
															name={'showAnniversaryYears'}
														/>
													}
													label={'Display years'}
												/>
												<FormControlLabel
													style={{ width: '100%' }}
													control={
														<Checkbox
															color={'default'}
															checked={props.currentCalendar.showBirthday}
															onChange={(e) => handleCelebrationChange(e.target.name)}
															name={'showBirthday'}
														/>
													}
													label={'Birthdays'}
												/>
												<Row style={{ flexWrap: 'nowrap' }}>
													<FormControlLabel
														style={{ width: '100%', paddingLeft: '30px', marginLeft: '15px' }}
														control={
															<Checkbox
																color={'default'}
																checked={!props.currentCalendar.showDayOfBirthday}
																onChange={(e) => handleCelebrationChange(e.target.name)}
																name={'showDayOfBirthday'}
															/>
														}
														label={'Suppress day of birthday'}
													/>
													<Col sm="auto" className="position-right" style={{ color: '#7c7e80', paddingTop: '10px' }}>
														<OverlayTrigger
															placement="auto"
															overlay={
																<Tooltip id={`tooltip-show-day-of-bday`} style={{ maxWidth: '100%' }}>
																	<div style={{ textAlign: 'left', width: '100%' }}>
																		For privacy reasons, we offer the ability to suppress the actual date of the birthday in
																		alerts, and all birthdays will be on the 1st of the month in calendar.
																	</div>
																</Tooltip>
															}
														>
															<div>
																<Info />
															</div>
														</OverlayTrigger>
													</Col>
												</Row>

												<FormLabel className="settings-label">New Team Member Milestones</FormLabel>
												<FormControlLabel
													style={{ width: '100%' }}
													control={
														<Checkbox
															color={'default'}
															checked={props.currentCalendar.newEmployee1Month}
															onChange={(e) => handleCelebrationChange(e.target.name)}
															name={'newEmployee1Month'}
														/>
													}
													label={'1 month'}
												/>
												<FormControlLabel
													style={{ width: '100%' }}
													control={
														<Checkbox
															color={'default'}
															checked={props.currentCalendar.newEmployee3Months}
															onChange={(e) => handleCelebrationChange(e.target.name)}
															name={'newEmployee3Months'}
														/>
													}
													label={'3 months'}
												/>
												<FormControlLabel
													style={{ width: '100%' }}
													control={
														<Checkbox
															color={'default'}
															checked={props.currentCalendar.newEmployee6Months}
															onChange={(e) => handleCelebrationChange(e.target.name)}
															name={'newEmployee6Months'}
														/>
													}
													label={'6 months'}
												/>
												<FormControlLabel
													style={{ width: '100%' }}
													control={
														<Checkbox
															color={'default'}
															checked={props.currentCalendar.newEmployee18Months}
															onChange={(e) => handleCelebrationChange(e.target.name)}
															name={'newEmployee18Months'}
														/>
													}
													label={'18 months'}
												/>
											</div>
										) : null}
									</FormGroup>
								</Row>
							</Col>
						</Row>
					</FormGroup>
					<FormGroup className="settings-card">
						<Row>
							<Col sm="3">
								<Row style={{ justifyContent: 'space-between' }}>
									<FormLabel className="settings-inner-label">Information to Include</FormLabel>
								</Row>
								<Row>
									<FormGroup as={Col}>
										{props.currentCalendar && props.currentCalendar.calendarID !== '' ? (
											<div className="settings-box">
												<FormControlLabel
													style={{ width: '100%' }}
													control={
														<Checkbox
															color={'default'}
															checked={checkIfAllIncludedInfoSelected()}
															onChange={(e) => handleCalendarChange(e.target.name)}
															name={'all'}
														/>
													}
													label={'Select All'}
												/>
												<Col
													sm="auto"
													style={{
														flex: '1',
														textAlign: 'left',
														paddingLeft: '20px',
													}}
												>
													<FormControlLabel
														style={{ width: '100%' }}
														control={
															<Checkbox
																color={'default'}
																checked={props.currentCalendar.showLeaveType}
																onChange={(e) => handleCalendarChange(e.target.name)}
																name={'showLeaveType'}
															/>
														}
														label={'Leave Type'}
													/>
													<FormControlLabel
														style={{ width: '100%' }}
														control={
															<Checkbox
																color={'default'}
																checked={props.currentCalendar.showAmount}
																onChange={(e) => handleCalendarChange(e.target.name)}
																name={'showAmount'}
															/>
														}
														label={'Amount'}
													/>
													<FormControlLabel
														style={{ width: '100%' }}
														control={
															<Checkbox
																color={'default'}
																checked={props.currentCalendar.showPayrollDescription}
																onChange={(e) => handleCalendarChange(e.target.name)}
																name={'showPayrollDescription'}
															/>
														}
														label={'Description from payroll system'}
													/>
													<FormControlLabel
														style={{ width: '100%' }}
														control={
															<Checkbox
																color={'default'}
																checked={props.currentCalendar.showCustomLeaveDescription}
																onChange={(e) => handleCalendarChange(e.target.name)}
																name={'showCustomLeaveDescription'}
															/>
														}
														label={'Description from custom leave'}
													/>
												</Col>
											</div>
										) : null}
										{props.currentOrg.xeroOrg &&
										(props.currentOrg.xeroOrg.version === 'AU' || props.currentOrg.xeroOrg.version === 'AUONRAMP') ? (
											<div className="settings-box">
												<Col
													sm="auto"
													style={{
														flex: '1',
														textAlign: 'left',
														paddingLeft: '20px',
													}}
												>
													<FormControlLabel
														style={{ width: '100%' }}
														control={
															<Checkbox
																color={'default'}
																checked={props.currentCalendar.showTitle}
																onChange={(e) => handleCalendarChange(e.target.name)}
																name={'showTitle'}
															/>
														}
														label={'Title'}
													/>
													<FormControlLabel
														style={{ width: '100%' }}
														control={
															<Checkbox
																color={'default'}
																checked={props.currentCalendar.showGroup}
																onChange={(e) => handleCalendarChange(e.target.name)}
																name={'showGroup'}
															/>
														}
														label={'Employee Group'}
													/>
												</Col>
											</div>
										) : null}
									</FormGroup>
								</Row>
							</Col>
							<Col sm="4">
								<Row
									style={{
										justifyContent: 'space-between',
										marginBottom: '-10px',
									}}
								>
									<FormLabel className="settings-inner-label">Public Holidays / External Calendars</FormLabel>
									<Col sm="auto" className="position-right" style={{ padding: '20px' }}>
										{I.have('admin', props.appState.permission[props.appState.context]) && (
											<OverlayTrigger
												placement="auto"
												overlay={<Tooltip id={`tooltip-xero`}>Add Public Holidays / External Calendars</Tooltip>}
											>
												<div>
													<Plus className={'settings-plus'} onClick={() => updateShowAddCustomFeedDialog(true)} />
												</div>
											</OverlayTrigger>
										)}
									</Col>
								</Row>
								{showAddCustomFeedDialog && (
									<CustomFeedPopUp
										show={showAddCustomFeedDialog}
										handleClose={() => updateShowAddCustomFeedDialog(false)}
										titleText={'Public Holidays / External Calendars'}
										updateMessages={updateMessages}
										appState={props.appState}
										currentCalendar={props.currentCalendar}
										updateCalendar={props.updateCurrentCalendar}
									/>
								)}
								<Row>
									<FormGroup as={Col}>
										{props.currentCalendar && props.currentCalendar.calendarID !== '' && (
											<div className="settings-box">
												{props.currentCalendar.inputFeeds &&
													props.currentCalendar.inputFeeds.map((feed) => (
														<OverlayTrigger
															key={feed.calendarInputFeedID}
															placement="right"
															overlay={<Tooltip id={`tooltip-ical Feed`}>{feed.url}</Tooltip>}
														>
															<FormControlLabel
																key={feed.calendarInputFeedID}
																style={{ width: '100%' }}
																control={
																	<Checkbox
																		color={'default'}
																		checked={true}
																		onChange={() => deleteCalendarFeed(feed.calendarInputFeedID!)}
																		name={feed.url}
																	/>
																}
																label={feed.url}
															/>
														</OverlayTrigger>
													))}
											</div>
										)}
									</FormGroup>
								</Row>
							</Col>
						</Row>
					</FormGroup>
					<Messages.Messages messages={messages} updateMessage={updateMessages} />
				</>
			)
		} else {
			return (
				<div className="loading-gif">
					<img src={LoadingImage} alt="Loading ..." />
				</div>
			)
		}
	} else {
		return null
	}
}

export default SettingsFrame
