import React from 'react'
import { Button, Card, Col, Container, Row } from 'react-bootstrap'
import { Navigate, useLocation, useNavigate } from 'react-router-dom'
import { AppContext } from '../../App'

import * as Messages from '../../components/ui/messages/messages'

import * as Request from '../../utilities/request'
import { getUrlSearchParam } from '../../utilities/url'

const AccountInvite = () => {
	const { appState } = React.useContext(AppContext)
	const navigate = useNavigate()
	const location = useLocation()

	const [messages, setMessages] = Messages.useMessageReducer([])
	const account = appState.accounts.find((account) => account.accountID === appState.authState.attributes?.['custom:currentAccountId'])
	console.log(account, appState.authState.invitationAccepted, location.pathname)
	if (!account) {
		return <div>No Account</div>
	}

	if (appState.authState.invitationAccepted === true) {
		// if they have accepted the invite for this account and somehow ended up here send them back to the home screen
		return <Navigate to={getUrlSearchParam(location.search, 'redirect') || '/'} />
	} else if (appState.authState.invitationAccepted === false || location.pathname === '/denied') {
		// if they denied the invite request tell them
		return (
			<Container>
				<p style={{ textAlign: 'center', marginTop: '20px' }}>You did not have access to this account.</p>
			</Container>
		)
	} else {
		return (
			<Container>
				<Card title="Accept Invite" style={{ padding: '20px' }}>
					<Row>
						<Col>
							<Row>
								<Col>
									<h3>You&apos;ve been invited to {account.name}</h3>
								</Col>
							</Row>
							<Row>
								<Col>
									<p>Would you like to join {account.name}?</p>
								</Col>
							</Row>
							<Row>
								<Col sm="auto">
									<Button
										variant="outline-primary"
										onClick={async () => {
											try {
												await Request.put(
													`user/${appState.authState.attributes?.['custom:userId']}`,
													{ invitationAccepted: true },
													appState.authState
												)
												navigate('/')
												window.location.reload()
											} catch (err) {
												setMessages({
													type: 'add',
													data: {
														severity: 'danger',
														timeout: 0,
														message: 'Failed to Accept',
														dismissible: true,
													},
												})
											}
										}}
									>
										Yes
									</Button>
								</Col>
								<Col sm="auto">
									<Button
										variant="outline-secondary"
										onClick={async () => {
											try {
												await Request.put(
													`user/${appState.authState.attributes?.['custom:userId']}`,
													{ invitationAccepted: false },
													appState.authState
												)
												navigate('/')
												window.location.reload()
											} catch (err) {
												setMessages({
													type: 'add',
													data: {
														severity: 'danger',
														timeout: 0,
														message: 'Failed to Unaccept',
														dismissible: true,
													},
												})
											}
										}}
									>
										No
									</Button>
								</Col>
							</Row>
						</Col>
					</Row>
				</Card>
				<Messages.Messages messages={messages} updateMessage={setMessages} />
			</Container>
		)
	}
}

export default AccountInvite
