// libraries
import * as React from 'react'
import { Col, OverlayTrigger, Row, Tooltip, Badge } from 'react-bootstrap'

// definitions
import { AccountXeroOrg, User, Calendar as CalendarData } from '../../../../back-end/utilities/apiDefinitions'
import OnBoardModal from '../../components/ui/onBoardModal/onBoardModal'
import * as XeroProducts from '../../constants/products'

// components
import BodyFrame, { FrameType } from '../../components/tayg/frame'
import * as Icons from '../../components/ui/icons/icons'

// utilities
import { ModalState } from './tayg.d'
import * as I from '../../utilities/me'
import * as Format from '../../utilities/format'
import ModalList from '../../components/ui/modalList/modalList'
import * as Request from '../../utilities/request'
import { IndentedDropdownSelector } from '../../components/tayg/IndentedDropDown'
import { TaygSettings } from '../../../../back-end/common/taygSettings'
import { AppContext } from '../../App'
import { useLocation } from 'react-router-dom'

const getFrameFromHash = (hash: string): FrameType => {
	return ['emails', 'slack', 'settings', 'overview'].includes(hash) ? (hash as FrameType) : 'overview'
}

const initialModalState: ModalState = {
	modal: '',
	props: {},
	size: 'sm',
}

const TaxAsYouGo = () => {
	const { appState, updateAppState } = React.useContext(AppContext)
	const location = useLocation()

	const [orgs, updateOrgs] = React.useState<AccountXeroOrg[] | null>(null)
	const [, updateUsers] = React.useState<User[] | null>(null)
	const [xeroConsentUrl, updateXeroConsentUrl] = React.useState<string | null>(null)
	const [keypayConsentUrl, updateKeypayConsentUrl] = React.useState<string | null>(null)
	const [selectedOrg, updateSelectedOrg] = React.useState<AccountXeroOrg | null>(null)
	const [selectedCalendar, updateSelectedCalendar] = React.useState<CalendarData | null>(null)
	const [taygSettings, updateTaygSettings] = React.useState<TaygSettings | null>(null)
	const [currentFrame, updateCurrentFrame] = React.useState<FrameType>(getFrameFromHash(location.hash.substring(1)))
	const [pageStatus, updatePageStatus] = React.useState<string>('Loading')
	const [shownModal, updateShownModal] = React.useState<ModalState>(initialModalState)
	const [isGstSetup, updateIsGstSetup] = React.useState<boolean>(false)

	const updateFrameAndHash = (frame: FrameType) => {
		window.location.hash = frame
		updateCurrentFrame(frame)
	}

	React.useEffect(() => {
		if (selectedOrg) {
			updateAppState({
				state: 'selectedOrg',
				data: selectedOrg,
			})
			if (appState.selectedOrg && appState.selectedOrg.accountXeroOrgID !== selectedOrg.accountXeroOrgID) {
				updateSelectedOrg(null)
			}
		}
	}, [selectedOrg]) // eslint-disable-line

	const refreshData = React.useCallback(
		async (org?: AccountXeroOrg) => {
			const fetchData = async () => {
				const [accountXeroOrgs, calendars, users, tayg, xeroUrl, keypayUrl] = await Promise.all(
					[
						Request.get('organisation?where=AccountXeroOrgIsTayg==1', appState.authState),
						Request.get('calendar?where=AccountXeroOrgIsTayg==1', appState.authState),
						Request.get('user', appState.authState),
						// Request.get('account', appState.authState),
						Request.get('taygsettings', appState.authState),
						// Request.get('xeroorgapi', appState.authState),
					].concat(
						I.have('admin', appState.permission[appState.context])
							? [Request.get('xero/url/tayg', appState.authState), Request.get('keypay/url', appState.authState)]
							: []
					)
				)

				const axos = accountXeroOrgs.data.accountXeroOrgs
				const hasCombined = !!axos[0]
				const combinedTAYG = {
					accountXeroOrgID: axos.map((xo: AccountXeroOrg) => xo.accountXeroOrgID).join(','),
					account: hasCombined ? axos[0].account : undefined,
					xeroOrg: {
						name: 'Combined TAYG',
						taygLastDataFetch: hasCombined
							? axos
									.map((xo: AccountXeroOrg) => xo.xeroOrg)
									.sort(
										(a: { taygLastDataFetch: string }, b: { taygLastDataFetch: string }) =>
											new Date(b.taygLastDataFetch).getTime() - new Date(a.taygLastDataFetch).getTime()
									)[0].taygLastDataFetch
							: undefined,
						xeroOrgID: axos.map((xo: AccountXeroOrg) => xo.xeroOrg!.xeroOrgID).join(','),
						products: axos
							.map((xo: AccountXeroOrg) => xo.xeroOrg!.products)
							.flat()
							.filter((p: { name: string }) => p.name!.toLocaleLowerCase().includes('tayg')),
						isGstSetup: axos.some((xo: AccountXeroOrg) => xo.xeroOrg?.taxBasis === 'ACCRUALS' || xo.xeroOrg?.taxBasis === 'CASH'),
					},
				}
				updateOrgs(axos.concat(hasCombined && axos.length > 1 ? [combinedTAYG] : []))
				updateUsers(users.data.users)
				if (xeroUrl && xeroUrl.data.url) {
					updateXeroConsentUrl(xeroUrl.data.url)
				}
				if (keypayUrl && keypayUrl.data.url) {
					updateKeypayConsentUrl(keypayUrl.data.url)
				}
				//We pick org and the calendar choosen by either the first one to come back or the selected
				//console.log('org', org, 'cal', cal, 'accountXeroOrgs.data.accountXeroOrgs', accountXeroOrgs.data.accountXeroOrgs)
				if (org) {
					updateSelectedOrg(org)
					updateTaygSettings(tayg.data.taygSettings.find((ts: TaygSettings) => ts.accountXeroOrgID === org.accountXeroOrgID))
					updateSelectedCalendar(
						calendars.data.calendars.find((c: CalendarData) => c.fileName === 'TaygDummy' && c.accountXeroOrgID === selectedOrg?.accountXeroOrgID)
					)
					updateIsGstSetup(org.xeroOrg?.taxBasis === 'ACCRUALS' || org.xeroOrg?.taxBasis === 'CASH')
				} else if (axos.length === 0 && I.am(appState.permission[appState.context]) === 'Admin') {
					updateShownModal({
						modal: 'Connect',
						props: {
							xeroConsentUrl: xeroUrl ? xeroUrl.data.url : null,
							canUseKeypay: false,
							keypayConsentUrl: keypayUrl ? keypayUrl.data.url : null,
							product: 'Tax As You Go',
						},
						size: 'md',
					})
				} else if (calendars.data.calendars.length === 0 || (axos.length > 0 && calendars.data.calendars.length > 0)) {
					if (localStorage.getItem('selectedOrgID')) {
						if (localStorage.getItem('selectedOrgID')!.includes(',') && axos.length > 1) {
							// we are in a combined tayg
							updateSelectedOrg(combinedTAYG)
							updateTaygSettings(tayg.data.taygSettings.filter((ts: TaygSettings) => combinedTAYG.accountXeroOrgID.includes(ts.accountXeroOrgID)))
							updateSelectedCalendar(calendars.data.calendars.find((c: CalendarData) => c.fileName === 'TaygDummy'))
							updateIsGstSetup(combinedTAYG.xeroOrg.isGstSetup)
						} else {
							let selOrg = axos.find((org: AccountXeroOrg) => org.xeroOrg!.xeroOrgID === localStorage.getItem('selectedOrgID'))
							if (!selOrg) {
								selOrg = axos[0]
								localStorage.setItem('selectedOrgID', selOrg.xeroOrg!.xeroOrgID)
							}
							updateSelectedOrg(selOrg)
							updateTaygSettings(tayg.data.taygSettings.find((ts: TaygSettings) => ts.accountXeroOrgID === selOrg.accountXeroOrgID))
							updateIsGstSetup(selOrg.xeroOrg?.taxBasis === 'ACCRUALS' || selOrg.xeroOrg?.taxBasis === 'CASH')
							updateSelectedCalendar(
								calendars.data.calendars.find((c: CalendarData) => c.fileName === 'TaygDummy' && c.accountXeroOrgID === selOrg.accountXeroOrgID)
							)
						}
					} else {
						const firstCreateTsOrg = axos.sort((a: AccountXeroOrg, b: AccountXeroOrg) => (b.createTs || '').localeCompare(a.createTs || ''))[0]
						updateSelectedOrg(firstCreateTsOrg)
						updateIsGstSetup(firstCreateTsOrg.xeroOrg?.taxBasis === 'ACCRUALS' || firstCreateTsOrg.xeroOrg?.taxBasis === 'CASH')
						updateTaygSettings(tayg.data.taygSettings.find((ts: TaygSettings) => ts.accountXeroOrgID === firstCreateTsOrg.accountXeroOrgID))
						updateSelectedCalendar(
							calendars.data.calendars.find(
								(c: CalendarData) => c.fileName === 'TaygDummy' && c.accountXeroOrgID === firstCreateTsOrg.accountXeroOrgID
							)
						)
					}
				}
			}

			if (appState.authState.isLoggedIn && appState.permission[appState.context]) {
				await fetchData()
			}
		},
		// eslint-disable-next-line
		[appState.authState, appState.permission[appState.context]]
	)

	React.useEffect(
		() => {
			if (appState.authState.isLoggedIn) {
				refreshData()
			}
		},
		// eslint-disable-next-line
		[appState.authState]
	)

	return (
		<div className="leavecal-root">
			<div className="leavecal-container">
				<Row className="leavecal-blue-bar">
					<IndentedDropdownSelector
						organisations={orgs ? orgs : []}
						selectedOrg={selectedOrg && selectedOrg.accountXeroOrgID ? selectedOrg.accountXeroOrgID : ''}
						updateSelectedOrg={updateSelectedOrg}
						appState={appState}
						refreshData={refreshData}
						updatePageStatus={updatePageStatus}
					/>
					{selectedOrg &&
					selectedOrg.userAccountXeroOrgAuth &&
					(selectedOrg.userAccountXeroOrgAuth.refreshToken === null || selectedOrg.userAccountXeroOrgAuth.connectionID === null) ? (
						<Col sm="auto">
							<Badge variant="danger" className={'clickable-icon'}>
								{xeroConsentUrl && keypayConsentUrl ? (
									<a href={selectedOrg?.xeroOrg?.apiProvider === 'keypay' ? keypayConsentUrl : xeroConsentUrl} style={{ color: 'inherit' }}>
										Re-auth needed
									</a>
								) : (
									<span>Re-auth needed</span>
								)}
							</Badge>
						</Col>
					) : null}
					{selectedOrg &&
					selectedOrg.xeroOrg &&
					selectedOrg.xeroOrg.products &&
					(selectedOrg.xeroOrg.products.findIndex((p) => p.productID === XeroProducts.TaygTrial.productID) >= 0 ||
						selectedOrg.xeroOrg.products.length === 0) ? (
						<Col sm="auto">
							<Badge variant="info" className={'clickable-icon'}>
								{I.have('admin', appState.permission[appState.context]) ? (
									<a
										href={`https://apps.xero.com/${
											selectedOrg?.xeroOrg ? `${selectedOrg.xeroOrg.shortCode}/${selectedOrg.xeroOrg.version}/` : ''
										}subscribe/0b229c2a-4641-42a8-a66a-2dd0a37a0a33`}
										style={{ color: 'black' }}
									>
										Trial Account
									</a>
								) : (
									<span style={{ color: 'black' }}>Trial Account</span>
								)}
								{/*
                            ${selectedOrg.xeroOrg.shortCode}/${selectedOrg.xeroOrg.version}/
                            */}
							</Badge>
						</Col>
					) : null}
					<Col className="position-right" style={{ paddingRight: '10px', paddingTop: '8px' }}>
						<span>{`Last Sync: ${
							selectedOrg?.xeroOrg?.taygLastDataFetch ? Format.dateTime(selectedOrg?.xeroOrg?.taygLastDataFetch) : 'Never'
						}`}</span>
					</Col>
				</Row>
				<ModalList
					handleClose={() => {
						updateShownModal(initialModalState)
					}}
					show={shownModal.modal}
					data={shownModal.props}
					size={shownModal.size}
					modals={[
						{
							id: 'Connect',
							body: OnBoardModal,
						},
					]}
				/>
			</div>

			<Row className="leavecal-body">
				<div className="leavecal-left-menu">
					{I.have('admin', appState.permission[appState.context]) ? (
						<Row>
							<OverlayTrigger placement="auto" overlay={<Tooltip id={`tooltip-overview`}>Overview</Tooltip>}>
								<div className={'clickable'} onClick={() => updateFrameAndHash('overview')}>
									<img
										src={Icons.Overview}
										style={{
											width: '40px',
											filter: `${currentFrame === 'overview' ? 'opacity(1)' : 'opacity(0.6)'}`,
										}}
										alt="Overview icon"
									/>
								</div>
							</OverlayTrigger>
						</Row>
					) : null}
					{I.have('admin', appState.permission[appState.context]) && selectedOrg && selectedOrg.xeroOrg!.name !== 'Combined TAYG' ? (
						<Row>
							<OverlayTrigger placement="auto" overlay={<Tooltip id={`tooltip-email`}>E-mail alerts</Tooltip>}>
								<div className={'clickable'} onClick={() => updateFrameAndHash('emails')}>
									<img
										src={Icons.Mail}
										style={{
											width: '40px',
											filter: `${currentFrame === 'emails' ? 'opacity(1)' : 'opacity(0.6)'}`,
										}}
										alt="E-Mail icon"
									/>
								</div>
							</OverlayTrigger>
						</Row>
					) : null}
					{I.have('admin', appState.permission[appState.context]) && selectedOrg && selectedOrg.xeroOrg!.name !== 'Combined TAYG' ? (
						<Row>
							<OverlayTrigger placement="auto" overlay={<Tooltip id={`tooltip-slack`}>Slack alerts</Tooltip>}>
								<div className={'clickable'} onClick={() => updateFrameAndHash('slack')}>
									<img
										src={Icons.Slack}
										style={{
											width: '40px',
											filter: `${currentFrame === 'slack' ? 'opacity(1)' : 'opacity(0.6)'}`,
										}}
										alt="Slack icon"
									/>
								</div>
							</OverlayTrigger>
						</Row>
					) : null}
					{I.have('admin', appState.permission[appState.context]) && selectedOrg && selectedOrg.xeroOrg!.name !== 'Combined TAYG' ? (
						<Row>
							<OverlayTrigger placement="auto" overlay={<Tooltip id={`tooltip-settings`}>Settings</Tooltip>}>
								<div className={'clickable'} onClick={() => updateFrameAndHash('settings')}>
									<img
										src={Icons.Settings}
										style={{
											width: '40px',
											filter: `${currentFrame === 'settings' ? 'opacity(1)' : 'opacity(0.6)'}`,
										}}
										alt="Settings icon"
									/>
								</div>
							</OverlayTrigger>
						</Row>
					) : null}
					{/* this is to fill up the rest of the page */}
					<div style={{ flexGrow: 1 }}>{/* TODO: fill in the body */}</div>
				</div>
				<Col>
					<BodyFrame
						appState={appState}
						updateAppState={updateAppState}
						pageStatus={pageStatus}
						currentFrame={currentFrame}
						currentOrg={selectedOrg}
						currentCalendar={selectedCalendar!}
						currentTaygSettings={taygSettings!}
						updateCurrentTaygSettings={updateTaygSettings}
						isGstSetup={isGstSetup}
						updatePageStatus={updatePageStatus}
						updateModal={() => {
							if (selectedOrg && selectedOrg.xeroOrg) {
								updateShownModal({
									modal: 'Onboarding',
									props: {
										accountXeroOrgID: selectedOrg.accountXeroOrgID,
										organisationID: selectedOrg.xeroOrg.xeroOrgID,
										organisationName: selectedOrg.xeroOrg.name,
										organisationVersion: selectedOrg.xeroOrg.version,
										appState: appState,
										firstCalendar: selectedCalendar!,
									},
									size: 'lg',
								})
							}
						}}
					/>
				</Col>
			</Row>
		</div>
	)
}

export default TaxAsYouGo
