import React from 'react'

// components
import Collapsible from '../../../ui/collapsible/collapsible'

// definitions
import { AccountStripeOrgWithData, AccountXeroOrgWithData } from '../../../../screens/subsync/subsync'
import { StripeOrgAccountXeroOrg, StripeOrgAccountXeroOrgMapping, XeroAPIContact, StripeMappingType } from '../../../../../../back-end/utilities/apiDefinitions'
import { MessageAction } from '../../../ui/messages/message'
import { AppState } from '../../../../App.d'
import { GeneralSettings } from './GeneralSettings'
import { CreditNotesSettings } from './CreditNotesSettings'
import { DiscountsSettings } from './DiscountsSettings'
import { TaxSettings } from './TaxSettings'
import { StripeFeesSettings } from './StripeFeesSettings'
import { ProductSettings } from './ProductSettings'
import { CustomMappings } from './CustomMappings'
import { InvoicePeriodsSettings } from './InvoicePeriods'
import { CustomerBalanceSettings } from './CustomerBalanceSettings'

interface OrgMappingContentProps {
	stripeOrg: AccountStripeOrgWithData
	orgMapping: StripeOrgAccountXeroOrg | undefined
	mappedXeroOrg: AccountXeroOrgWithData | undefined
	stripeMappingTypes: StripeMappingType[]
	xeroOrgs: AccountXeroOrgWithData[]
	appState: AppState
	handleOrgUpdate: (stripeOrgID: string, name: string, value: string) => void
	handleProductUpdate: (stripeOrgID: string, stripeProductID: string, name: string, value: string) => void
	handleProductAdd: (stripeOrgID: string, stripeProductID: string, name: string, value: string) => void
	handleApplyToAllProducts: (stripeOrgID: string, name: string, value: string) => void
	handleTaxRateUpdate: (stripeOrgID: string, stripeTaxRateID: string, name: string, value: string) => void
	handleTaxRateAdd: (stripeOrgID: string, stripeTaxRateID: string, name: string, value: string) => void
	handleSettingsUpdate: (stripeOrgID: string, values: { [name: string]: string | boolean | XeroAPIContact | undefined }) => void
	handleCustomMappingUpdate: (stripeOrgID: string, customMappings: Partial<StripeOrgAccountXeroOrgMapping>[]) => void
	updateMessages: (action: MessageAction) => void
}

const OrgMappingContent = (props: OrgMappingContentProps) => {
	const mappedXeroOrgHasStripeBankAccount = props.mappedXeroOrg?.accounts.find(
		(acc) => acc.number === `x-${props.stripeOrg.stripeOrg?.stripeID}` || acc.number === `x${props.stripeOrg.stripeOrg?.stripeID}`
	)
	return props.orgMapping && props.mappedXeroOrg ? (
		<div className="pl-5 mt-5">
			{!mappedXeroOrgHasStripeBankAccount && (
				<div className="mb-3">
					We couldn&apos;t detect a Stripe Bank Feed in this Xero connection. This means payments from Stripe won&apos;t be automatically reflected in
					Xero.
				</div>
			)}
			<div className="mb-2">
				<Collapsible
					startsCollapsed={false}
					clickableElement={<p className="font-weight-bold ml-2 mt-3">Settings</p>}
					collapsibleElement={
						<GeneralSettings
							orgMapping={props.orgMapping}
							stripeOrgID={props.stripeOrg.stripeOrg!.stripeOrgID!}
							mappedXeroOrg={props.mappedXeroOrg}
							handleOrgUpdate={props.handleOrgUpdate}
							handleSettingsUpdate={props.handleSettingsUpdate}
						/>
					}
				/>
			</div>
			<div className="mb-2">
				<Collapsible
					startsCollapsed={false}
					clickableElement={<p className="font-weight-bold ml-2 mt-3">Account/Product Mapping</p>}
					collapsibleElement={
						<ProductSettings
							stripeOrgID={props.stripeOrg.stripeOrg!.stripeOrgID!}
							orgMapping={props.orgMapping!}
							stripeProducts={props.stripeOrg.products}
							types={['Account', 'Item']}
							customMappings={props.orgMapping!.customMappings!}
							mappedXeroOrg={props.mappedXeroOrg}
							stripeMappingTypes={props.stripeMappingTypes}
							handleOrgUpdate={props.handleOrgUpdate}
							handleCustomMappingUpdate={props.handleCustomMappingUpdate}
							handleSettingsUpdate={props.handleSettingsUpdate}
							handleProductUpdate={props.handleProductUpdate}
							handleProductAdd={props.handleProductAdd}
							handleApplyToAllProducts={props.handleApplyToAllProducts}
						/>
					}
				/>
			</div>
			<div></div>
			<div className="mb-2">
				<Collapsible
					startsCollapsed={false}
					clickableElement={<p className="font-weight-bold ml-2 mt-3">Tax Settings</p>}
					collapsibleElement={
						<TaxSettings
							orgMapping={props.orgMapping}
							mappedXeroOrg={props.mappedXeroOrg}
							stripeOrgID={props.stripeOrg.stripeOrg!.stripeOrgID!}
							stripeTaxRates={props.stripeOrg.taxRates}
							handleSettingsUpdate={props.handleSettingsUpdate}
							handleTaxRateUpdate={props.handleTaxRateUpdate}
							handleTaxRateAdd={props.handleTaxRateAdd}
						/>
					}
				/>
			</div>
			<div className="mb-2">
				<Collapsible
					startsCollapsed={true}
					clickableElement={<p className="font-weight-bold ml-2 mt-3">Tracking Categories</p>}
					collapsibleElement={
						<CustomMappings
							stripeOrgID={props.stripeOrg.stripeOrg!.stripeOrgID!}
							orgMapping={props.orgMapping!}
							types={['TrackingCategoryOption']}
							customMappings={props.orgMapping!.customMappings!}
							mappedXeroOrg={props.mappedXeroOrg}
							stripeMappingTypes={props.stripeMappingTypes}
							handleCustomMappingUpdate={props.handleCustomMappingUpdate}
							handleSettingsUpdate={props.handleSettingsUpdate}
						/>
					}
				/>
			</div>
			<div className="mb-2">
				<Collapsible
					startsCollapsed={true}
					clickableElement={<p className="font-weight-bold ml-2 mt-3">Stripe Fees</p>}
					collapsibleElement={
						<StripeFeesSettings
							orgMapping={props.orgMapping}
							mappedXeroOrg={props.mappedXeroOrg}
							stripeOrgID={props.stripeOrg.stripeOrg!.stripeOrgID!}
							xeroOrgs={props.xeroOrgs}
							appState={props.appState}
							handleSettingsUpdate={props.handleSettingsUpdate}
							updateMessages={props.updateMessages}
						/>
					}
				/>
			</div>
			<div className="mb-2">
				<Collapsible
					startsCollapsed={true}
					clickableElement={<p className="font-weight-bold ml-2 mt-3">Credit Notes</p>}
					collapsibleElement={
						<CreditNotesSettings
							orgMapping={props.orgMapping}
							mappedXeroOrg={props.mappedXeroOrg}
							stripeOrgID={props.stripeOrg.stripeOrg!.stripeOrgID!}
							handleSettingsUpdate={props.handleSettingsUpdate}
						/>
					}
				/>
			</div>
			<div className="mb-2">
				<Collapsible
					startsCollapsed={true}
					clickableElement={<p className="font-weight-bold ml-2 mt-3">Customer Balance</p>}
					collapsibleElement={
						<CustomerBalanceSettings
							orgMapping={props.orgMapping}
							mappedXeroOrg={props.mappedXeroOrg}
							stripeOrgID={props.stripeOrg.stripeOrg!.stripeOrgID!}
							handleSettingsUpdate={props.handleSettingsUpdate}
						/>
					}
				/>
			</div>
			<div className="mb-2">
				<Collapsible
					startsCollapsed={true}
					clickableElement={<p className="font-weight-bold ml-2 mt-3">Discounts</p>}
					collapsibleElement={
						<DiscountsSettings
							orgMapping={props.orgMapping}
							mappedXeroOrg={props.mappedXeroOrg}
							stripeOrgID={props.stripeOrg.stripeOrg!.stripeOrgID!}
							handleSettingsUpdate={props.handleSettingsUpdate}
						/>
					}
				/>
			</div>
			<div className="mb-2">
				<Collapsible
					startsCollapsed={true}
					clickableElement={<p className="font-weight-bold ml-2 mt-3">Invoice Periods</p>}
					collapsibleElement={
						<InvoicePeriodsSettings
							orgMapping={props.orgMapping}
							mappedXeroOrg={props.mappedXeroOrg}
							stripeOrgID={props.stripeOrg.stripeOrg!.stripeOrgID!}
							handleSettingsUpdate={props.handleSettingsUpdate}
						/>
					}
				/>
			</div>
		</div>
	) : null
}

export { OrgMappingContent }
