import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import AwsConfig from '../constants/aws.json'
import { AuthState } from '../App.d'

const setConfig = (authState: AuthState): AxiosRequestConfig => ({
	headers: {
		Authorization: authState.apiToken,
	},
})

const getUrl = (endpoint: string): string => `${process.env.NODE_ENV !== 'production' ? 'http' : 'https'}://${AwsConfig.DomainEndpoint}/${endpoint}`

export type RequestStatus = 'Loading' | 'Ready' | 'Error'

export const getPagedData = async (
	endpoint: string,
	limit: number,
	updatePage: (data: any, pageStatus: RequestStatus) => void,
	authState?: AuthState
): Promise<AxiosResponse<any>> => {
	let offset = 0
	let finished = false
	let status: RequestStatus = 'Loading'
	let result: any

	while (!finished) {
		result = await get(`${endpoint}${endpoint.endsWith('&') ? '' : '?'}limit=${limit}&offset=${offset}`, authState)

		if (!result.data || result.status !== 200) {
			status = 'Error'
			finished = true
		} else {
			offset += limit

			if (result.data.resultsLength < limit) {
				status = 'Ready'
				finished = true
			}
		}
		updatePage(result.data, status)
	}
	return {
		...result,
		data: [],
	}
}

export const get = async (endpoint: string, authState?: AuthState): Promise<AxiosResponse<any>> => {
	return new Promise<AxiosResponse<any>>((resolve, reject) => {
		axios
			.get(getUrl(endpoint), authState ? setConfig(authState) : undefined)
			.catch((error: any) => {
				if (error.response && error.response.status === 401) {
					window.location.href = '/login?redirect=' + encodeURIComponent(window.location.pathname) + window.location.hash
				}
				reject(error)
			})
			.then((value: AxiosResponse<any> | void) => {
				resolve(value as any)
			})
	})
}

export const post = (endpoint: string, body: any, authState: AuthState) => axios.post(getUrl(endpoint), body, setConfig(authState))

export const put = (endpoint: string, body: any, authState: AuthState) => axios.put(getUrl(endpoint), body, setConfig(authState))

export const del = (endpoint: string, authState: AuthState) => axios.delete(getUrl(endpoint), setConfig(authState))

export const sleep = (length: number) => new Promise((resolve) => setTimeout(() => resolve(undefined), length))
