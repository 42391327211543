import React from 'react'
import { Button, ButtonProps } from 'react-bootstrap'
import { X } from 'react-feather'

interface CloseButtonProps extends ButtonProps {
	onClick: () => void
	disabled: boolean
}

const CloseButton = (props: CloseButtonProps) => (
	<Button className="circle" variant={props.disabled ? 'dark' : 'info'} onClick={props.onClick} disabled={props.disabled}>
		<X />
	</Button>
)

export default CloseButton
