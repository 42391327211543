import React from 'react'
import { Col, Row, Spinner } from 'react-bootstrap'
import { XeroEmbeddedAppContext } from '@xeroapi/embedded-apps-sdk'
import { SubRow } from './SubRow'
import { SubFooter } from './SubFooter'
import { PaginationFooter } from '../common/PaginationFooter'
import { StripeInvoice } from '../../../../back-end/utilities/apiDefinitions'
import { StripeSubscription } from '../../../../back-end/utilities/apiDefinitions'
import { getSubRevenue } from '../helpers/functions'
import { roundToTwo } from '../../utilities/format'
import AwsConfig from '../../constants/aws.json'

import './SubTab.css'

const PAGE_SIZE = 4

interface SubTabProps {
	invoices: StripeInvoice[]
}

const SubTab = (props: SubTabProps) => {
	const [page, setPage] = React.useState<number>(1)
	const [stripeSubs, setStripesSubs] = React.useState<StripeSubscription[] | undefined>(undefined)
	const { xeroEmbeddedAppClient, xeroEmbeddedAppContext } = React.useContext(XeroEmbeddedAppContext)

	React.useEffect(() => {
		const getData = async () => {
			const topInvoice = props.invoices[0]
			if (topInvoice) {
				const subscriptions = await xeroEmbeddedAppClient!.fetchWithToken(
					`https://${AwsConfig.DomainEndpoint}/xeroembed/stripesubscriptions?stripeOrgID=${topInvoice.stripeOrg!.stripeOrgID}&stripeCustomerID=${
						topInvoice.stripeCustomerID
					}`
				)
				const subscriptionData = await subscriptions.json()
				setStripesSubs(subscriptionData.subscriptions)
			} else {
				setStripesSubs([])
			}
		}

		getData()
	}, [xeroEmbeddedAppContext, xeroEmbeddedAppClient, props.invoices, props.invoices[0]])

	const mrr = React.useMemo(() => {
		if (stripeSubs) {
			const subRevenue = getSubRevenue(stripeSubs)
			return subRevenue
		}
		return 0
	}, [stripeSubs])

	if (!stripeSubs) {
		return (
			<div style={{ padding: '2rem 46% 0' }}>
				<Spinner animation="border" />
			</div>
		)
	}

	return (
		<>
			{props.invoices.length > 0 && (
				<>
					<Row>
						<Col>
							<h2 className="p-3 contact-heading">{props.invoices[0]?.invoicee}</h2>
						</Col>
					</Row>
					<Row className="value-row">
						<Col>
							<span className="font-weight-bold">MRR: {roundToTwo(mrr)}</span> + Tax
						</Col>
					</Row>
					<Row>
						<Col>
							{stripeSubs.slice((page - 1) * PAGE_SIZE, page * PAGE_SIZE).map((sub) => (
								<SubRow key={sub.id} subscription={sub} />
							))}
						</Col>
					</Row>
				</>
			)}
			{props.invoices.length === 0 && (
				<Row>
					<Col>
						<div style={{ padding: '1rem' }}>
							<p>No Stripe subscriptions found for this Contact</p>
						</div>
					</Col>
				</Row>
			)}
			<Row>
				<Col>
					<div className="mb-5"></div>
				</Col>
			</Row>
			<PaginationFooter items={stripeSubs.length} page={page} pageSize={PAGE_SIZE} onPageChange={(newPage) => setPage(newPage)} bottomSpacing={65} />
			<SubFooter customerID={props.invoices[0]?.stripeCustomerID} />
		</>
	)
}

export { SubTab }
