import React from 'react'
import { Calendar, momentLocalizer, ToolbarProps } from 'react-big-calendar'
import moment from 'moment'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import CustomToolbar, { DateRange } from './toolbar'
import { Button, OverlayTrigger, Popover, Row, Table, Tooltip } from 'react-bootstrap'
import { Trash2 } from 'react-feather'
import { Calendar as CalendarData, Employee } from '../../../../../back-end/utilities/apiDefinitions'
import { formatLeaveTitle } from '../../leavecal/calendar'
import * as XLSX from 'xlsx'
import * as FileSaver from 'file-saver'
import { CalendarLeave, MergerObject, BigCalendarEventObject } from './bigCalendarTypes'

type BigLeaveCalendarProps = {
	events: CalendarLeave[]
	deleteEventPopUp: (event: boolean) => void
	editEventPopUp: (event: boolean) => void
	selectedEvent: (event: string) => void
	updateShowAddCalendarEntryDialog: (show: boolean) => void
	updateSelectedDateForEvent: (date: string) => void
	readOnly: boolean
	employees: Employee[]
	firstDayOfWeek: number
	currentUserID: string
	currentCalendar: CalendarData
	plannerDateRange?: {
		startDate: moment.Moment | null
		endDate: moment.Moment | null
	}
}

const spreadDateNums = (dateStr: string, add?: number): [number, number, number] => {
	const year = Number(dateStr.slice(0, 4))
	const month = Number(dateStr.slice(5, 7)) - 1
	const day = Number(dateStr.slice(8, 10)) + (add || 0)
	return [year, month, day]
}

const formatDate = (date: Date) => {
	return `${moment(date).format('ddd')}\n${moment(date).format('DD/MM')}`
}

const getEmployeeEventsForPeriod = (events: CalendarLeave[], employee: Employee, period: Date[]) => {
	return events
		.filter(
			(ev: CalendarLeave) =>
				ev.employeeID === employee.employeeID &&
				period.some((d) => {
					const current = d
					const start = new Date(ev.start)
					return start.getFullYear() === current.getFullYear() && start.getMonth() === current.getMonth() && start.getDay() === current.getDay()
				})
		)
		.sort((a, b) => new Date(a.start).getTime() - new Date(b.start).getTime())
}

const BigLeaveCalendar = (props: BigLeaveCalendarProps) => {
	const [view, updateView] = React.useState<string>('month')
	const [plannerPeriod, updatePlannerPeriod] = React.useState<DateRange>({
		startDate: moment(),
		endDate: moment().add(90, 'days'),
	})

	const Event = (event: BigCalendarEventObject): JSX.Element => {
		const popoverClick = (
			<Popover id={`popover-trigger-${event.title}`} style={{ zIndex: 10000 }}>
				<div style={{ margin: '10px' }}>
					{event.event.isNonXero && (!props.readOnly || event.event.createUserID === props.currentUserID) ? (
						<>
							<div>
								<Button
									type="button"
									style={{ float: 'left' }}
									onClick={() => {
										props.selectedEvent(event.event.leaveApplicationID)
										props.editEventPopUp(true)
									}}
								>
									Edit
								</Button>
							</div>

							<OverlayTrigger placement="auto" overlay={<Tooltip id={`tooltip-delete-event`}>Delete event</Tooltip>}>
								<div style={{ textAlign: 'right' }}>
									<Button
										style={{ padding: '0px', borderWidth: '0px' }}
										onClick={() => {
											props.selectedEvent(event.event.leaveApplicationID)
											props.deleteEventPopUp(true)
										}}
									>
										<Trash2 style={{ fontSize: 'smaller' }} />
									</Button>
								</div>
							</OverlayTrigger>
							<br />
						</>
					) : null}
					<strong>{event.title}</strong>
					<div>
						{event.event.description && event.event.isNonXero ? (
							event.event.description
						) : !event.event.isNonXero && event.event.type === 'leave' ? (
							<>
								<>
									{event.event.description}
									<br />
								</>
								<div style={{ fontStyle: 'italic' }}>
									This leave application comes from Xero -{' '}
									<a
										href={`https://go.xero.com/organisationlogin/default.aspx?shortcode=${
											props.currentCalendar.accountXeroOrg!.xeroOrg!.shortCode
										}`}
										target="_blank"
										rel="noopener noreferrer"
									>
										go to Xero to edit
									</a>
								</div>
							</>
						) : (
							''
						)}
					</div>
				</div>
			</Popover>
		)

		const textColour = event.event.hexColour
			? Number('0x' + event.event.hexColour.slice(1, 3)) * 0.299 + // Red
					Number('0x' + event.event.hexColour.slice(3, 5)) * 0.587 + // Green
					Number('0x' + event.event.hexColour.slice(5, 7)) * 0.114 > // Blue
			  186
				? '#000000'
				: '#ffffff'
			: '#ffffff'

		return (
			<div style={event.divSize ? { width: event.divSize, overflow: 'hidden' } : {}}>
				<div>
					<OverlayTrigger trigger="click" rootClose placement="top" overlay={popoverClick}>
						<div style={{ color: textColour }}>{event.title}</div>
					</OverlayTrigger>
				</div>
			</div>
		)
	}
	const fixedEvents = props.events.map((event) => ({
		...event,
		start: new Date(...spreadDateNums(String(event.start))),
		end: new Date(...spreadDateNums(String(event.end), 1)),
	}))

	//assign the first day of the week as Monday
	moment.updateLocale('en-gb', {
		week: {
			dow: props.firstDayOfWeek,
		},
	})

	const formats = {
		dayFormat: 'ddd DD/MM',
	}
	const localizer = momentLocalizer(moment)

	const getDatesRange = (s: string | number | Date, e: string | number | Date) => {
		const dates = []
		const amountOfDays = moment(e).diff(moment(s), 'days')
		for (let i = 0; i <= amountOfDays; i++) {
			const newDate = moment(s).add(i, 'days').toDate()
			dates.push(newDate)
		}
		return dates
	}

	const maxEventsOnADay = (empEvents: CalendarLeave[], period: Date[]) => {
		return period.reduce((maxCount: number, d: Date) => {
			const event = empEvents.filter(
				(ev) =>
					d.toDateString() === new Date(ev.end).toDateString() ||
					d.toDateString() === new Date(ev.start).toDateString() ||
					(new Date(ev.end).getTime() >= new Date(d).getTime() && new Date(ev.start).getTime() <= new Date(d).getTime())
			)
			return event.length > maxCount ? event.length : maxCount
		}, 1)
	}

	const getCSVData = (): [string[][], MergerObject[]] => {
		/**
		 *  This function creates the two necessary inputs for an excel export: data and the array of objects that indicates the merged cells
		 */
		let merges: MergerObject[] = []
		const period = getDatesRange(plannerPeriod.startDate!.toString(), plannerPeriod.endDate!.toString())
		const headers = ['Team Member'].concat(period.map((d) => formatDate(d)))

		if (props.employees && props.employees.length > 0) {
			let movedRow = 0
			const data: string[][] = props.employees!.reduce((arrays: string[][], employee: Employee, index: number) => {
				//variables we are going to be working on
				let diffInDays = 0
				const rowStart = index + 2 + movedRow
				//get the name of current employee and its events for the given period
				const name = ''.concat(
					employee!.alias ? employee.alias : `${employee!.firstName} ${employee!.lastName}`,
					props.currentCalendar.showTitle ? formatLeaveTitle(employee!.jobTitle!, '-') : '',
					props.currentCalendar.showGroup ? formatLeaveTitle(employee!.groupName!, '-') : ''
				)
				const empEvents = getEmployeeEventsForPeriod(props.events, employee, period)
				const maxEventsPerDay = maxEventsOnADay(empEvents, period)
				if (maxEventsPerDay > 1) {
					merges = merges.concat([
						{
							s: { c: 0, r: rowStart },
							e: { c: 0, r: rowStart + maxEventsPerDay - 1 },
						},
					])
					movedRow = movedRow + maxEventsPerDay - 1
				}
				const empRows = Array.from({ length: maxEventsPerDay }, (v, index) => {
					const row = period.map((d, i) => {
						if (diffInDays > 0) {
							diffInDays = diffInDays - 1
							return ''
						}
						const event = empEvents.filter(
							(ev) =>
								d.toDateString() === new Date(ev.end).toDateString() ||
								d.toDateString() === new Date(ev.start).toDateString() ||
								(new Date(ev.end).getTime() >= new Date(d).getTime() && new Date(ev.start).getTime() <= new Date(d).getTime())
						)
						if (event.length > 0 && event[index]) {
							diffInDays =
								moment(event[index].end).diff(moment(event[index].start), 'days') > 0
									? moment(event[index].end).diff(moment(event[index].start), 'days') -
									  (moment(period[0]).isAfter(event[index].start) ? moment(period[0]).diff(event[index].start, 'days') : 0)
									: 0
							if (diffInDays > 0) {
								merges = merges.concat([
									{
										s: { c: i + 1, r: rowStart },
										e: { c: i + 1 + diffInDays, r: rowStart },
									},
								])
							}
							return event[index].title
						} else if (event[index]) {
							return ''
						} else {
							return ''
						}
					})
					return [name].concat(row)
				})
				return arrays.concat(empRows)
			}, [])

			return [[headers].concat(data), merges]
		} else {
			return [[headers], merges]
		}
	}

	/** Create a new view component based on the requirements of BigCalendar; must have view.navigate and view.title otherwise it errors out */

	const Planner = () => {
		const period = getDatesRange(
			plannerPeriod.startDate ? plannerPeriod.startDate!.toString() : moment().toString(),
			plannerPeriod.endDate ? plannerPeriod.endDate!.toString() : moment().add(90, 'days').toString()
		)
		return (
			<Row style={{ overflow: 'auto', height: '800px' }}>
				<Table borderless className="no-wrap" style={{ overflow: 'auto', height: '800px' }}>
					<thead>
						<tr className="stripe-row-body">
							<th className="header-sticky-top" style={{ fontWeight: 'bold' }}>
								Team Member
							</th>
							{period.map((d, index) => (
								<th
									key={index}
									style={{
										textAlign: 'center',
										fontWeight: 'bold',
										position: 'sticky',
										top: 0,
									}}
								>
									{formatDate(d)}
								</th>
							))}
						</tr>
					</thead>
					<tbody>
						{props.employees && props.employees.length > 0 ? (
							props.employees!.flatMap((employee: Employee, i: number) => {
								const empEvents = getEmployeeEventsForPeriod(props.events, employee, period)
								const maxEventsPerDay = maxEventsOnADay(empEvents, period)
								let seenEvent: string[] = []
								let diffInDays = 0
								return Array.from({ length: maxEventsPerDay }, (v, index) => {
									return (
										<tr key={`${employee.employeeID}_${index}`} className={`stripe-row ${i % 2 !== 0 ? 'stripe-row-body' : ''}`}>
											{index === 0 && (
												<th rowSpan={maxEventsPerDay}>
													{employee!.alias ? employee.alias : `${employee!.firstName} ${employee!.lastName}`}
													{props.currentCalendar.showTitle ? formatLeaveTitle(employee!.jobTitle!, '-') : ''}
													{props.currentCalendar.showGroup ? formatLeaveTitle(employee!.groupName!, '-') : ''}
												</th>
											)}

											{period.map((d) => {
												const event = empEvents.filter(
													(ev) =>
														d.toDateString() === new Date(ev.end).toDateString() ||
														d.toDateString() === new Date(ev.start).toDateString() ||
														(new Date(ev.end).getTime() >= new Date(d).getTime() &&
															new Date(ev.start).getTime() <= new Date(d).getTime())
												) // && !(seenEvent.indexOf(ev.leaveApplicationID) !== -1)
												const dayOfWeek = d.getDay()
												if (event.length > 0 && event[index] && seenEvent.indexOf(event[index].leaveApplicationID) === -1) {
													const backgroundColor = event[index].hexColour
													diffInDays =
														moment(event[index].end).diff(moment(event[index].start), 'days') > 0
															? moment(event[index].end).diff(moment(event[index].start), 'days') -
															  (moment(period[0]).isAfter(event[index].start)
																	? moment(period[0]).diff(event[index].start, 'days')
																	: 0)
															: 0
													const elem = (
														<td
															style={{
																backgroundColor: backgroundColor,
																fontWeight: 'bold',
																color: 'white',
																width: '50px',
																borderRadius: '10px',
															}}
															colSpan={diffInDays + 1}
														>
															{Event({
																...event[index],
																event: event[index],
																divSize: `${(diffInDays + 1) * 45}px`,
															})}
														</td>
													)
													seenEvent = seenEvent.concat([event[index].leaveApplicationID])
													return elem
												} else if (event[index] && seenEvent.indexOf(event[index].leaveApplicationID) !== -1) {
													return null
												} else {
													return (
														<td
															key={index}
															style={{
																backgroundColor: dayOfWeek === 6 || dayOfWeek === 0 ? 'rgb(248, 153, 149, 0.1)' : '',
																borderTopLeftRadius: '0px',
																borderBottomLeftRadius: '0px',
															}}
														/>
													)
												}
											})}
										</tr>
									)
								})
							})
						) : (
							<tr>
								<td>No employees</td>
							</tr>
						)}
					</tbody>
				</Table>
			</Row>
		)
	}

	/** Defaults needed for view */
	Planner.navigate = (date: Date) => {
		return date
	}

	Planner.title = () => {
		return ``
	}

	const downloadExcel = () => {
		const [data, merges] = getCSVData()
		const ws = XLSX.utils.json_to_sheet(data)
		const wb = {
			Sheets: { data: { ...ws, '!merges': merges } },
			SheetNames: ['data'],
		}
		const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
		const filee = new Blob([excelBuffer], {
			type: 'application/vnd.ms-excel;charset=utf-8',
		})
		FileSaver.saveAs(filee, String(`${props.currentCalendar.accountXeroOrg!.xeroOrg!.name}>${props.currentCalendar.fileName!}`).concat('.xlsx'))
	}

	const handleDaySelect = (slotInfo: { start: moment.MomentInput }) => {
		if (moment(slotInfo.start).isSameOrBefore(moment().add(1, 'year'))) {
			props.updateSelectedDateForEvent(moment(slotInfo.start).format('YYYY-MM-DD'))
			props.updateShowAddCalendarEntryDialog(true)
		}
	}

	return (
		<div style={{ height: view === 'week' || view === 'work_week' ? 200 : 800 }}>
			<Calendar
				popup
				selectable
				onSelectSlot={handleDaySelect}
				formats={formats}
				events={fixedEvents}
				views={{ month: true, week: true, work_week: true, agenda: Planner }}
				localizer={localizer}
				onView={(e) => {
					updateView(e)
				}}
				components={{
					event: Event,
					toolbar: (props: ToolbarProps) => (
						<CustomToolbar {...props} plannerPeriod={plannerPeriod} updatePlannerPeriod={updatePlannerPeriod} downloadCSV={downloadExcel} />
					),
				}}
				eventPropGetter={(event) => {
					const backgroundColor = event.hexColour
					const fontWeight = 'bold'
					return { style: { backgroundColor, fontWeight } }
				}}
			/>
		</div>
	)
}

export { BigLeaveCalendar }
export type { CalendarLeave }
