import { useEffect, useRef } from 'react'

export function useInterval(callback: () => any, delay: number | null) {
	const savedCallback = useRef<() => any>()

	useEffect(() => {
		savedCallback.current = callback
	}, [callback])

	useEffect(() => {
		const tick = async () => {
			if (savedCallback && savedCallback.current) {
				await savedCallback.current()
			}
		}

		if (delay !== null) {
			const id = setInterval(tick, delay)
			return () => clearInterval(id)
		}
	}, [delay])
}
