import React from 'react'
import { Col, Dropdown, Form, InputGroup, ListGroup, Row } from 'react-bootstrap'

type Item<T> = T & { label: string; id: string }

interface DropdownWithSearchProps<T> {
	title: string
	items: Item<T>[]
	onSelect: (item: Item<T> | null) => void
	customFilter?: (searchText: string) => (item: Item<T>) => boolean
	customRenderSelected?: (item: Item<T>) => string
	customNoneSelectedText?: string
}

const DropdownWithSearch = <T,>(props: DropdownWithSearchProps<T>) => {
	const [show, updateShow] = React.useState<boolean>(false)
	const [searchText, updateSearchText] = React.useState<string>('')
	const [selectedId, updateSelectedId] = React.useState<string>('')

	const filter = (searchText: string) => (item: Item<T>) => item.label.toLowerCase().includes(searchText.toLocaleLowerCase())
	const renderSelected = (item: Item<T>) => item.label

	const filteredItems = props.items.filter(props.customFilter ? props.customFilter(searchText) : filter(searchText))

	const selected = props.items.find((item) => item.id === selectedId)

	return (
		<Row>
			<Col md={{ span: 5, offset: 1 }}>
				<div className="tayg-settings-label">{props.title}:</div>
			</Col>
			<Col md={{ span: 5, offset: 0 }}>
				<Dropdown style={{ marginBottom: '5px' }} onToggle={() => updateShow(!show)} show={show}>
					<Dropdown.Toggle>
						{selected
							? props.customRenderSelected
								? props.customRenderSelected(selected)
								: renderSelected(selected)
							: props.customNoneSelectedText || 'None Selected'}
					</Dropdown.Toggle>

					<Dropdown.Menu className={'tayg-settings-dropdown'}>
						<ListGroup>
							<ListGroup.Item className={'tayg-settings-search'}>
								<InputGroup size="sm" className="mb-3">
									<Form.Control value={searchText} placeholder={'Search...'} onChange={(event) => updateSearchText(event.target.value)} />
								</InputGroup>
							</ListGroup.Item>
							{filteredItems.map((item, key) => (
								<ListGroup.Item
									className={'list-group-hover tayg-settings-select-option'}
									key={key}
									onClick={() => {
										updateSelectedId(item.id)
										props.onSelect(item)
										updateShow(!show)
									}}
								>
									{item.label}
								</ListGroup.Item>
							))}
							{selected && (
								<ListGroup.Item
									className={'list-group-hover tayg-settings-select-option'}
									key={0}
									onClick={() => {
										updateSelectedId('')
										props.onSelect(null)
										updateShow(!show)
									}}
								>
									{props.customNoneSelectedText || 'None Selected'}
								</ListGroup.Item>
							)}
						</ListGroup>
					</Dropdown.Menu>
				</Dropdown>
			</Col>
		</Row>
	)
}

export { DropdownWithSearch }
