import React from 'react'
import { Spinner } from 'react-bootstrap'

const ForecastTab = () => {
	return (
		<div style={{ padding: '2rem 46% 0' }}>
			<Spinner animation="border" />
		</div>
	)
}

export { ForecastTab }
