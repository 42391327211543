import React from 'react'
import { Form, Row, Col, DropdownButton, Dropdown, Badge, Button } from 'react-bootstrap'

// components
import { ArrowDown, ArrowUp, Plus } from 'react-feather'

// definitions
import { AccountXeroOrgWithData } from '../../../../screens/subsync/subsync'
import {
	StripeOrgAccountXeroOrg,
	StripeOrgAccountXeroOrgMapping,
	StripeOrgAccountXeroOrgCustomMappingType,
	XeroAPIContact,
	StripeMappingType,
} from '../../../../../../back-end/utilities/apiDefinitions'
import { CustomMapping } from './CustomMapping'

// utilities
import { handleAddMapping, handleAddRule, handleMappingUpdate, handleReorder } from '../functions'

interface CustomMappingsProps {
	stripeOrgID: string
	orgMapping: StripeOrgAccountXeroOrg
	types: StripeOrgAccountXeroOrgCustomMappingType[]
	customMappings: StripeOrgAccountXeroOrgMapping[]
	mappedXeroOrg: AccountXeroOrgWithData | undefined
	stripeMappingTypes: StripeMappingType[]
	handleCustomMappingUpdate: (stripeOrgID: string, customMappings: Partial<StripeOrgAccountXeroOrgMapping>[]) => void
	handleSettingsUpdate: (stripeOrgID: string, values: { [name: string]: string | boolean | XeroAPIContact | undefined }) => void
}

const CustomMappings = (props: CustomMappingsProps) => {
	const selectedDefaultXeroElement =
		props.mappedXeroOrg?.accounts.find(
			(account) => account.xeroAccountID === props.orgMapping.stripeOrgAccountXeroOrgSettings?.customMappingDefaultXeroAccountID
		) || props.mappedXeroOrg?.items.find((item) => item.xeroItemID === props.orgMapping.stripeOrgAccountXeroOrgSettings?.customMappingDefaultXeroItemID)

	const handleAdd = () => {
		const newMapping = handleAddMapping(props.customMappings, props.types)
		props.handleCustomMappingUpdate(props.stripeOrgID, props.customMappings.concat([newMapping]))
	}

	const handleUpdate = (stripeOrgAccountXeroOrgMappingID: string, values: Partial<StripeOrgAccountXeroOrgMapping>) => {
		const updatedMappings = handleMappingUpdate(props.customMappings, stripeOrgAccountXeroOrgMappingID, values)
		props.handleCustomMappingUpdate(props.stripeOrgID, updatedMappings)
	}

	const handleRemove = (stripeOrgAccountXeroOrgMappingID: string) => {
		const updatedMappings = props.customMappings.filter((mapping) => mapping.stripeOrgAccountXeroOrgMappingID !== stripeOrgAccountXeroOrgMappingID)
		const reorderedElements = handleReorder(updatedMappings, stripeOrgAccountXeroOrgMappingID, -1, props.types)
		props.handleCustomMappingUpdate(props.stripeOrgID, reorderedElements)
	}

	const handleAddStripeRule = (stripeOrgAccountXeroOrgMappingID: string) => {
		const newRules = props.customMappings
			.find((customMapping) => customMapping.stripeOrgAccountXeroOrgMappingID === stripeOrgAccountXeroOrgMappingID)
			?.stripeRules?.concat([handleAddRule(stripeOrgAccountXeroOrgMappingID)])
		handleUpdate(stripeOrgAccountXeroOrgMappingID, { stripeRules: newRules })
	}

	const handleReorderMappings = (stripeOrgAccountXeroOrgMappingID: string, index: number) => {
		const reorderedElements = handleReorder(props.customMappings, stripeOrgAccountXeroOrgMappingID, index, props.types)
		props.handleCustomMappingUpdate(props.stripeOrgID, reorderedElements)
	}

	const filteredCustomMappings = props.customMappings.filter((customMapping) => props.types.includes(customMapping.type!))

	return (
		<>
			<Row className="mb-3">
				<Col sm="auto" className="px-0 mx-0" style={{ minWidth: '4em' }}></Col>
				<Col sm={2}>
					<Form.Label>Choose Xero element to map to:</Form.Label>
				</Col>
				<Col sm={9}>
					<Row>
						<Col sm={1}></Col>
						<Col sm={3}>
							<Form.Label>Choose Stripe element to use:</Form.Label>
						</Col>
						{/* in future these key/value inputs will look different for different stripe mapping types */}
						<Col sm={2}>
							<Form.Label>Enter metadata key (e.g. product_type):</Form.Label>
						</Col>
						<Col sm="auto" className="p-0 m-0 invisible">
							=
						</Col>
						<Col sm={3}>
							<Form.Label>Enter metadata value (e.g. subscription):</Form.Label>
						</Col>
					</Row>
				</Col>
			</Row>
			{filteredCustomMappings.map((customMapping, index) => (
				<React.Fragment key={customMapping.stripeOrgAccountXeroOrgMappingID}>
					<Form.Group as={Row} key={customMapping.stripeOrgAccountXeroOrgMappingID} className="mb-3 d-flex flex-row">
						<Col sm="auto" className="px-0 mx-0 d-flex align-items-center" style={{ minWidth: '4em' }}>
							{filteredCustomMappings.length > 0 && index > 0 && (
								<ArrowUp onClick={() => handleReorderMappings(customMapping.stripeOrgAccountXeroOrgMappingID!, index - 1)} />
							)}
							{filteredCustomMappings.length > 0 && index < props.customMappings.length - 1 && (
								<ArrowDown onClick={() => handleReorderMappings(customMapping.stripeOrgAccountXeroOrgMappingID!, index + 1)} />
							)}
						</Col>
						<CustomMapping
							customMapping={customMapping}
							mappedXeroOrg={props.mappedXeroOrg}
							stripeMappingTypes={props.stripeMappingTypes}
							handleMappingUpdate={handleUpdate}
							handleMappingRemove={handleRemove}
						/>
					</Form.Group>
					<Row>
						{/* spacers so everything lines up */}
						<Col sm="auto" className="px-0 mx-0" style={{ minWidth: '4em' }}></Col>
						<Col sm={2}></Col>
						<Col sm={9} className="d-flex row">
							<Col sm="auto" className="p-0 m-0 invisible">
								=
							</Col>
							<Col sm={10} className="d-flex justify-content-end">
								<Button onClick={() => handleAddStripeRule(customMapping.stripeOrgAccountXeroOrgMappingID!)}>
									<Plus />
									Add rule
								</Button>
							</Col>
						</Col>
					</Row>
					<hr />
				</React.Fragment>
			))}
			<Row className="mb-4">
				<Button onClick={handleAdd}>
					<Plus />
					Add new mapping
				</Button>
			</Row>
			{!props.types.includes('TrackingCategoryOption') && (
				<Form.Group as={Row}>
					<Form.Label column sm={4}>
						Choose a Xero Account/Product to use for any invoices or line items that don&apos;t fit the above criteria:
					</Form.Label>
					<Col sm={4}>
						<DropdownButton
							id={`${props.stripeOrgID}-custom-mapping-default-xero-select`}
							title={selectedDefaultXeroElement?.name ?? 'Select...'}
							onSelect={(eventKey) => {
								const xeroAccount = props.mappedXeroOrg?.accounts.find((account) => account.xeroAccountID === eventKey)
								const newValues = eventKey
									? {
											...(xeroAccount
												? {
														customMappingDefaultXeroAccountID: eventKey,
												  }
												: {
														customMappingDefaultXeroItemID: eventKey,
												  }),
									  }
									: {}
								props.handleSettingsUpdate(props.stripeOrgID, newValues)
							}}
						>
							<XeroAccountsAndItemsDropdownItems mappedXeroOrg={props.mappedXeroOrg} />
						</DropdownButton>
					</Col>
				</Form.Group>
			)}
		</>
	)
}

const XeroAccountsAndItemsDropdownItems = ({ mappedXeroOrg }: { mappedXeroOrg: AccountXeroOrgWithData | undefined }) => {
	return (
		<>
			<Dropdown.Header>Revenue</Dropdown.Header>
			{mappedXeroOrg!.accounts
				.filter((account) => account.type === 'REVENUE')
				.sort((a, b) => a.name!.localeCompare(b.name!, undefined, { numeric: true }))
				.map((xa) => (
					<Dropdown.Item key={xa.xeroAccountID} eventKey={xa.xeroAccountID}>
						<Badge variant="light" className="p-1 mr-1">
							{xa.code}
						</Badge>
						{xa.name}
					</Dropdown.Item>
				))}
			<Dropdown.Header>Other Income</Dropdown.Header>
			{mappedXeroOrg!.accounts
				.filter((account) => account.type === 'OTHERINCOME')
				.sort((a, b) => a.name!.localeCompare(b.name!, undefined, { numeric: true }))
				.map((xa) => (
					<Dropdown.Item key={xa.xeroAccountID} eventKey={xa.xeroAccountID}>
						<Badge variant="light" className="p-1 mr-1">
							{xa.code}
						</Badge>
						{xa.name}
					</Dropdown.Item>
				))}
			<Dropdown.Header>Sales</Dropdown.Header>
			{mappedXeroOrg!.accounts
				.filter((account) => account.type === 'SALES')
				.sort((a, b) => a.name!.localeCompare(b.name!, undefined, { numeric: true }))
				.map((xa) => (
					<Dropdown.Item key={xa.xeroAccountID} eventKey={xa.xeroAccountID}>
						<Badge variant="light" className="p-1 mr-1">
							{xa.code}
						</Badge>
						{xa.name}
					</Dropdown.Item>
				))}
			<Dropdown.Header>Current Liability</Dropdown.Header>
			{mappedXeroOrg!.accounts
				.filter((account) => account.type === 'CURRLIAB')
				.sort((a, b) => a.name!.localeCompare(b.name!, undefined, { numeric: true }))
				.map((xa) => (
					<Dropdown.Item key={xa.xeroAccountID} eventKey={xa.xeroAccountID}>
						<Badge variant="light" className="p-1 mr-1">
							{xa.code}
						</Badge>
						{xa.name}
					</Dropdown.Item>
				))}
			<Dropdown.Divider />
			<Dropdown.Header>Products & Services</Dropdown.Header>
			{mappedXeroOrg!.items
				.sort((a, b) => a.code!.localeCompare(b.code!, undefined, { numeric: true }))
				.map((xi) => (
					<Dropdown.Item key={xi.xeroItemID} eventKey={xi.xeroItemID}>
						<Badge variant="light" className="p-1 mr-1">
							{xi.code}
						</Badge>
						{xi.name}
					</Dropdown.Item>
				))}
		</>
	)
}

export { CustomMappings, XeroAccountsAndItemsDropdownItems }
export type { CustomMappingsProps }
