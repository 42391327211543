import React from 'react'
import { Navigate, Route, Routes, useLocation } from 'react-router-dom'
import { AppContext } from '../../App'
import ScreensGoogleIntegration from '../../screens/google/integration'
import ScreensKeypayConnections from '../../screens/keypay/connections'
import ScreensStripeConnections from '../../screens/stripe/connections'
import ScreensXeroConnections from '../../screens/xero/connections'

import ScreensLogin from '../../screens/login/login'
import ScreensSignup from '../../screens/login/signup'
import ScreensAdmin from '../../screens/admin/admin'
import ScreensCombinedCal from '../../screens/leavecal/combinedCal'
import ScreensGroups from '../../screens/groups/groups'
import ScreensGroupsSingle from '../../screens/groups/singleGroup'
import ScreensHomeNoPage from '../../screens/home/nopage'
import ScreensInvite from '../../screens/login/accountInvite'
import ScreenAccountCreationConfirmation from '../../screens/login/accountCreation'
import ScreensGoogleCallback from '../../screens/google/callback'
import ScreensUsers from '../../screens/users/users'
import ScreensUsersSingle from '../../screens/users/singleUser'
import ScreensSlackCallback from '../../screens/slack/callback'
import ScreensXeroCallback from '../../screens/xero/callback'
import ScreensKeypayCallback from '../../screens/keypay/callback'
import ScreensLeaveCal from '../../screens/leavecal/leavecal'
import ScreensOversight from '../../screens/oversight/oversight'
import ScreensTaxAsYouGo from '../../screens/tayg/tayg'
import ScreensSubSync from '../../screens/subsync/subsync'
import ScreensStripeCallback from '../../screens/stripe/callback'

import ScreensThresholdTemplates from '../../screens/thresholdTemplate/thresholdTemplates'
import ScreensSingleThresholdTemplate from '../../screens/thresholdTemplate/singleThresholdTemplate'

import ScreensXeroSubscriptions from '../../screens/xero/subscriptions'
import ScreensCustomBranding from '../../screens/customBranding/customBranding'
import StripeRedirect from '../../screens/stripe/redirect'
import HistoryComponent from '../subsync/history/history'

const getHomeScreen: { [product: string]: () => JSX.Element } = {
	leavecal: ScreensLeaveCal,
	tayg: ScreensTaxAsYouGo,
	oversight: ScreensOversight,
	subsync: ScreensSubSync,
}

//the route with path '/' to be modified once we decide where we separate leaveCal and oversight
const MyRoutes = () => {
	const { appState } = React.useContext(AppContext)
	const location = useLocation()

	if (
		location.pathname.endsWith('/callback') &&
		(location.search.includes('?code') || location.search.includes('&code') || location.search.includes('?error') || location.search.includes('&error'))
	) {
		console.log('callback')
		// if we are coming back from xero auth we don't want amplify to take over when it sees ?code
		const newLocationSearch = location.search
			.replace('?code', '?xcode')
			.replace('&code', '&xcode')
			.replace('?error', '?xerror')
			.replace('&error', '?xerror')
		location.search = newLocationSearch
		window.location.replace(`${location.pathname}${newLocationSearch}`)
		return <Navigate to={`${location.pathname}${newLocationSearch}`} />
	}
	if (appState.authState.isLoggedIn && appState.accounts.length === 0 && location.pathname !== '/confirmation' && appState.hasNoAccount) {
		return <Navigate to={`/confirmation`} />
	}
	if (appState.authState.isLoggedIn && appState.accounts.length > 0 && location.pathname === '/confirmation') {
		// we are done confirming our choice and created account so log in
		console.log('done confirmation')
		return <Navigate to={`/`} />
	}
	if (appState.authState.invitationAccepted === false && location.pathname !== '/denied') {
		// redirect to denied page if the user has declined the invitation
		console.log('denied redirect')
		return <Navigate to={`/denied`} />
	}
	if (appState.authState.invitationAccepted === null && location.pathname !== '/invite') {
		// redirect to acceptance page if we haven't accepted yet
		console.log('invite redirect')
		return <Navigate to={`/invite${location.pathname !== '/invite' ? `?redirect=${location.pathname}` : ''}`} />
	}
	if (appState.authState.isLoggedIn && appState.context === 'subsync' && location.search.includes('account_id') && location.pathname !== '/stripe/callback') {
		// if we directed the user to signup but they are already signed in, complete stripe org setup
		console.log('stripe setup redirect')
		return <Navigate to={`/stripe/callback${location.search}`} />
	}

	const HomeScreen = getHomeScreen[appState.context]

	return (
		<Routes>
			<Route path="/confirmation" element={<ScreenAccountCreationConfirmation />} />
			<Route path="/" element={<HomeScreen />} />
			<Route path="/login" element={<ScreensLogin />} />
			<Route path="/signup" element={<ScreensSignup />} />
			<Route path="/slack/callback" element={<ScreensSlackCallback />} />
			<Route path="/xero/callback" element={<ScreensXeroCallback />} />
			<Route path="/xero/connections" element={<ScreensXeroConnections />} />
			<Route path="/xero/subscription" element={<ScreensXeroSubscriptions />} />
			<Route path="/denied" element={<ScreensInvite />} />
			<Route path="/invite" element={<ScreensInvite />} />
			<Route path="/users" element={<ScreensUsers />} />
			<Route path="/user/:userID" element={<ScreensUsersSingle />} />
			<Route path="/admin" element={<ScreensAdmin />} />
			<Route path="/branding" element={<ScreensCustomBranding />} />
			{/* oversight */}
			{appState.context === 'oversight' && (
				<>
					<Route path="/tags" element={<ScreensGroups />} />
					<Route path="/tag/:tagGroupID" element={<ScreensGroupsSingle />} />
					<Route path="/threshold" element={<ScreensThresholdTemplates />} />
					<Route path="/threshold/:thresholdTemplateID" element={<ScreensSingleThresholdTemplate />} />
				</>
			)}
			{/* leavecal */}
			{appState.context === 'leavecal' && (
				<>
					<Route path="/combinedcal" element={<ScreensCombinedCal />} />
					<Route path="/keypay/callback" element={<ScreensKeypayCallback />} />
					<Route path="/keypay/connections" element={<ScreensKeypayConnections />} />
					<Route path="/google/callback" element={<ScreensGoogleCallback />} />
					<Route path="/google/integration" element={<ScreensGoogleIntegration />} />
				</>
			)}
			{/* subsync */}
			{appState.context === 'subsync' && (
				<>
					<Route path="/subsync/history" element={<HistoryComponent />} />
					<Route path="/stripe/connections" element={<ScreensStripeConnections />} />
					<Route path="/stripe/callback" element={<ScreensStripeCallback />} />
					<Route path="/stripe/redirect" element={<StripeRedirect />} />
				</>
			)}
			{/* catch 404 */}
			<Route path="*" element={<ScreensHomeNoPage />} />
		</Routes>
	)
}

export { MyRoutes }
