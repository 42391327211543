import React from 'react'
import { Navigate, Link, useLocation } from 'react-router-dom'
import { Container } from 'react-bootstrap'

import * as Request from '../../utilities/request'
import * as Url from '../../utilities/url'
import * as XeroApi from '../../constants/xeroApis'
import { Dialog } from '../../components/ui/dialog/dialog'
import LoadingImage from '../../images/Finlert-loading-GIF_100px.gif'
import { TaygSettingsResult } from '../../../../back-end/common/taygSettings'
import TAYGLoadingImage from '../../images/TAYG_100px.gif'
import SubSyncLoadingImage from '../../images/subSyncIcons/SubSync_100x100.gif'
import { AppContext } from '../../App'
import { AccountXeroOrg } from '../../../../back-end/utilities/apiDefinitions'
import { AxiosResponse } from 'axios'

const Callback = () => {
	const { appState } = React.useContext(AppContext)
	const location = useLocation()

	const [isReady, updateIsReady] = React.useState<boolean>(false)
	const [failMessage, updateFailMessage] = React.useState<string>('')
	const [noPayrollOrgs, updateNoPayrollOrgs] = React.useState<AccountXeroOrg[]>([])
	const [nonsupportedOrgs, updateNonsupportedOrgs] = React.useState<AccountXeroOrg[]>([])
	const [showDialog, updateShowDialog] = React.useState<boolean | null>(null)
	const [redirect, setRedirect] = React.useState<string>('/')

	//loading bar
	const [loadingMessage, updateLoadingMessage] = React.useState<string>('Logging you in using your Xero credentials...')
	const [currentProgress, updateCurrentProgress] = React.useState<number>(0)

	const disconnectOrg = async () => {
		await Promise.all(
			noPayrollOrgs.concat(nonsupportedOrgs).map(async (org) => {
				return Request.del(`xero/tokens/${appState.context}/${org.connectionID}`, appState.authState)
			})
		)
		updateShowDialog(false)
	}

	React.useEffect(() => {
		const remainingWorkSubSync = async (stage: 'accounts' | 'items' | 'taxrates', xeroOrgIds: string) => {
			if (failMessage !== '') {
				return
			}
			switch (stage) {
				case 'accounts': {
					updateLoadingMessage('Getting your accounts...')
					const accounts = await Request.get(`xeroaccount?where=XeroOrgID=in(${xeroOrgIds})`, appState.authState)

					if (accounts.data.success && accounts.data.xeroAccounts.length > 0) {
						updateCurrentProgress(60)
						remainingWorkSubSync('items', xeroOrgIds)
					} else {
						await Request.sleep(3000)
						remainingWorkSubSync('accounts', xeroOrgIds)
					}
					break
				}
				case 'items': {
					updateLoadingMessage('Getting your items...')
					const items = await Request.get(`xeroitem?where=XeroOrgID=in(${xeroOrgIds})`, appState.authState)
					// small wait before continuing even if no items came back to account for orgs with no items
					await Request.sleep(3000)
					if (items.data.success) {
						updateCurrentProgress(80)
						remainingWorkSubSync('taxrates', xeroOrgIds)
					} else {
						await Request.sleep(3000)
						remainingWorkSubSync('items', xeroOrgIds)
					}
					break
				}
				case 'taxrates': {
					updateLoadingMessage('Getting your tax rates...')
					const taxRates = await Request.get(`xerotaxrate?where=XeroOrgID=in(${xeroOrgIds})`, appState.authState)

					if (taxRates.data.success && taxRates.data.xeroTaxRates.length > 0) {
						// do you have stripe connected? if so, go to settings
						const stripeOrgs = await Request.get('accountstripeorg', appState.authState)
						if (stripeOrgs.data.accountStripeOrgs.length > 0) {
							setRedirect('/#settings')
						}
						updateCurrentProgress(100)
						updateLoadingMessage('All done! Redirecting you...')
						await Request.sleep(2000)
						updateIsReady(true)
					} else {
						await Request.sleep(3000)
						remainingWorkSubSync('taxrates', xeroOrgIds)
					}
					break
				}
			}
		}

		const remainingWorkTayg = async (accountXeroOrgIDS: string, auOrgsExist: boolean, extraText: string) => {
			if (failMessage !== '') {
				return
			}
			// proceed with taygSettings
			updateLoadingMessage(extraText !== '' ? `<p>Getting your Xero tax settings...</p>`.concat(`${extraText}`) : 'Getting your Xero tax settings...')
			//do stuff
			const taygSettings = await Request.get(`taygsettings?where=AccountXeroOrgID=in(${accountXeroOrgIDS})`, appState.authState)

			if (taygSettings.data.success && taygSettings.data.taygSettings.length > 0) {
				const newTaygSettings = taygSettings.data.taygSettings.filter((tayg: TaygSettingsResult) => tayg.isNew === true)
				const newAccountXeroOrgIDs = newTaygSettings.map((tayg: TaygSettingsResult) => tayg.taygSettings[0].accountXeroOrgID)

				if (localStorage['isNewIDs']) {
					// Get the IDs that are currently in storage, filter to make sure there are no doubles in the array, and set all the AccountXeroOrgIDs for all new orgs.
					const newAuths = JSON.parse(localStorage.getItem('isNewIDs') || '')
					const allNewIDs = newTaygSettings
						.filter((ts: TaygSettingsResult) => newAuths.includes(ts.taygSettings[0].accountXeroOrgID) === false)
						.map((ts: TaygSettingsResult) => ts.taygSettings[0].accountXeroOrgID)
					const allNewAuths = newAuths.concat(allNewIDs)
					localStorage.setItem('isNewIDs', JSON.stringify(allNewAuths))
				} else if (newAccountXeroOrgIDs.length >= 1) {
					localStorage.setItem('isNewIDs', JSON.stringify(newAccountXeroOrgIDs))
				}

				updateCurrentProgress(100)
				updateLoadingMessage(extraText !== '' ? `<p>All done! Redirecting you...</p>`.concat(`${extraText}`) : 'All done! Redirecting you...')
				await Request.sleep(2000)
				// conclude
				updateIsReady(true)
			} else {
				await Request.sleep(3000)
				remainingWorkTayg(accountXeroOrgIDS, auOrgsExist, extraText)
			}
		}

		const remainingWork = async (xeroOrgIDS: string, auOrgsExist: boolean, extraText: string, stage: string) => {
			if (failMessage !== '') {
				return
			}
			switch (stage) {
				case 'employees': {
					// proceed with employees
					updateLoadingMessage(extraText !== '' ? `<p>Getting your employees...</p>`.concat(`${extraText}`) : 'Getting your employees...')
					// do stuff
					const employees = await Request.get(`employee?where=XeroOrgID=in(${xeroOrgIDS})`, appState.authState)

					if (employees.data.success && employees.data.employees.length > 0) {
						updateCurrentProgress(auOrgsExist ? 60 : 75)
						remainingWork(xeroOrgIDS, auOrgsExist, extraText, 'leavetypes')
					} else {
						await Request.sleep(3000)
						remainingWork(xeroOrgIDS, auOrgsExist, extraText, 'employees')
					}
					break
				}
				case 'leavetypes': {
					// proceed with leavetypes
					updateLoadingMessage(extraText !== '' ? `<p>Getting your leave types...</p>`.concat(`${extraText}`) : 'Getting your leave types...')
					//do stuff
					const leaveTypes = await Request.get(`leavetype?where=XeroOrgID=in(${xeroOrgIDS})`, appState.authState)

					if (leaveTypes.data.success && leaveTypes.data.leaveTypes.length > 0) {
						updateCurrentProgress(auOrgsExist ? 80 : 100)
						// if Aus proceed with  leave applications
						if (auOrgsExist) {
							// wait a bit before continuing
							await Request.sleep(2000)
							remainingWork(xeroOrgIDS, auOrgsExist, extraText, 'leaveapplications')
						} else {
							updateLoadingMessage(
								extraText !== '' ? `<p>All done! Redirecting you...</p>`.concat(`${extraText}`) : 'All done! Redirecting you...'
							)
							await Request.sleep(2000)
							// conclude
							updateIsReady(true)
						}
					} else {
						await Request.sleep(3000)
						remainingWork(xeroOrgIDS, auOrgsExist, extraText, 'leavetypes')
					}
					break
				}
				case 'leaveapplications': {
					// proceed with leave applications
					updateLoadingMessage(
						extraText !== '' ? `<p>Getting your leave applications...</p>`.concat(`${extraText}`) : 'Getting your leave applications...'
					)
					//do stuff
					const leaveApp = await Request.get(`leave?where=XeroOrgID=in(${xeroOrgIDS})`, appState.authState)

					if (leaveApp.data.success && leaveApp.data.leaveApplications.length >= 0) {
						// in case some orgs have no leave applications yet
						updateCurrentProgress(100)
						updateLoadingMessage(extraText !== '' ? `<p>All done! Redirecting you...</p>`.concat(`${extraText}`) : 'All done! Redirecting you...')
						await Request.sleep(2000)
						// conclude
						updateIsReady(true)
					} else {
						await Request.sleep(3000)
						remainingWork(xeroOrgIDS, auOrgsExist, extraText, 'leaveapplications')
					}
					break
				}
				case 'terminate':
					Request.sleep(3000)
					updateCurrentProgress(100)
					updateIsReady(true)
					break
				default:
					await Request.sleep(3000)
			}
		}

		const exchangeKeys = async () => {
			const search = location.search.replace('?xcode', '?code')

			// fire off the tokens to do its thing
			Request.get(`xero/tokens/${appState.context}` + search, appState.authState).then((body: AxiosResponse<{ success: boolean; message?: string }>) => {
				// check if in leavecal it was a payroll Error and display it
				if (appState.context === 'leavecal' && body.data.success === false) {
					const errorMessage = body.data.message
					if (errorMessage && errorMessage.includes('Payroll Admin Access Required')) {
						updateFailMessage('No Payroll')
						updateShowDialog(true)
					}
				}
			})

			const code = Url.getUrlSearchParam(search, 'code')

			// keep checking different components and update the loading bar accordingly
			try {
				let auOrgsExist = false
				updateLoadingMessage('Authorising...')
				let authData = []
				do {
					const authReq = await Request.get(`xero/authcheck?where=ApiProvider==xero&where=Code==${code}`, appState.authState)
					authData = authReq.data
					await Request.sleep(2000)
				} while (authData.length === 0)
				updateCurrentProgress(20)

				// proceed with orgs
				updateLoadingMessage('Getting your organisations...')
				// do stuff
				const authIDs = authData.map((d: { UserAccountXeroOrgAuthID: string }) => d.UserAccountXeroOrgAuthID).join(',')
				const dbAccXeroOrgs = await Request.get(
					`organisation?where=ApiProvider==xero&where=UserAccountXeroOrgAuthID=in(${authIDs})`,
					appState.authState
				)
				const accountXeroOrgs: AccountXeroOrg[] = dbAccXeroOrgs.data.accountXeroOrgs
				auOrgsExist = accountXeroOrgs
					.filter((org) => org.xeroOrg!.apis!.some((api) => api.xeroApiID === XeroApi.Payroll))
					.some((org) => org.xeroOrg!.version === 'AU' || org.xeroOrg!.version === 'AUONRAMP')
				const xeroOrgIDS = accountXeroOrgs.map((org) => org.xeroOrg!.xeroOrgID).join(',')
				updateCurrentProgress(auOrgsExist ? 40 : 50)
				if (dbAccXeroOrgs.data.success) {
					// do checks for the orgs
					if (appState.context === 'leavecal') {
						const invalidOrgs = accountXeroOrgs.filter(
							(org) => !org.xeroOrg!.apis!.some((api) => api.xeroApiID === XeroApi.Payroll) && org.isLeaveCal
						)
						const noSupportOrgs = accountXeroOrgs.filter(
							(org) =>
								org.xeroOrg!.version !== 'UK' &&
								org.xeroOrg!.version !== 'NZ' &&
								org.xeroOrg!.version !== 'AU' &&
								org.xeroOrg!.version !== 'UKONRAMP' &&
								org.xeroOrg!.version !== 'NZONRAMP' &&
								org.xeroOrg!.version !== 'AUONRAMP'
						)
						if (invalidOrgs.length > 0) {
							if (invalidOrgs.length === accountXeroOrgs.length) {
								// all orgs have no payroll
								updateFailMessage('No Payroll')
								updateNoPayrollOrgs(invalidOrgs)
								updateShowDialog(true)
							} else {
								// tell user about invalid orgs but continue authing for the rest
								const extraText = `<p>Your Xero ${
									noPayrollOrgs.length > 1 ? 'accounts do' : 'account does'
								} not have sufficient permissions for all your organisations. Please contact your Xero administrator to get payroll access or have your payroll administrator authorise the connection for: <p>${invalidOrgs
									.map((org) => `- ${org.xeroOrg!.legalName}`)
									.join('\n')}</p></p>`
								updateLoadingMessage(`<p>${loadingMessage}</p>`.concat(`${extraText}`))
								remainingWork(xeroOrgIDS, auOrgsExist, extraText, 'employees')
							}
						} else if (noSupportOrgs.length > 0) {
							if (noSupportOrgs.length === accountXeroOrgs.length) {
								// all orgs have unsupported regions
								updateFailMessage('Unsupported Region')
								updateNonsupportedOrgs(noSupportOrgs)
								updateShowDialog(true)
							} else {
								// tell user about invalid orgs but continue authing for the rest
								const extraText = `<p>At the moment we support Xero payroll in Australia, New Zealand and the UK. It looks like some of your organisations are outside of those regions.Continuing only for the suported ones...</p>`
								updateLoadingMessage(`<p>${loadingMessage}</p>`.concat(`\n\n${extraText}`))
								remainingWork(xeroOrgIDS, auOrgsExist, extraText, 'employees')
							}
						} else {
							remainingWork(xeroOrgIDS, auOrgsExist, '', 'employees')
						}
					} else if (appState.context === 'tayg') {
						const noSupportOrgsTAYG = accountXeroOrgs.filter(
							(org) => org.xeroOrg!.version !== 'AU' && org.xeroOrg!.version !== 'AUONRAMP' && org.isTayg
						)
						const accountXeroOrgIDS = accountXeroOrgs.map((org) => org.accountXeroOrgID).join(',')
						if (noSupportOrgsTAYG.length > 0) {
							if (noSupportOrgsTAYG.length === dbAccXeroOrgs.data.accountXeroOrgs.length) {
								// all orgs have unsupported regions
								updateFailMessage('Unsupported Region')
								updateNonsupportedOrgs(noSupportOrgsTAYG)
								updateShowDialog(true)
							} else {
								// tell user about invalid orgs but continue authing for the rest
								const extraText = `<p>At the moment we support Xero payroll in Australia. It looks like some of your organisations are outside of those regions.Continuing only for the suported ones...</p>`
								updateLoadingMessage(`<p>${loadingMessage}</p>`.concat(`\n\n${extraText}`))
								remainingWorkTayg(accountXeroOrgIDS, auOrgsExist, extraText)
							}
						} else {
							remainingWorkTayg(accountXeroOrgIDS, auOrgsExist, '')
						}
					} else if (appState.context === 'subsync') {
						remainingWorkSubSync('accounts', xeroOrgIDS)
					} else {
						updateCurrentProgress(100)
						updateLoadingMessage('All done! Redirecting you...')
						await Request.sleep(2000)
						updateIsReady(true)
					}
				}
			} catch (err) {
				console.log(err)
				updateFailMessage('An Error has Occurred')
			}
		}

		if (
			appState.authState.isLoggedIn &&
			appState.accounts.length > 0 &&
			appState.permission[appState.context] &&
			appState.authState.invitationAccepted &&
			!Url.getUrlSearchParam(location.search, 'xerror')
		) {
			exchangeKeys()
		}
	}, [location.search, appState]) // eslint-disable-line

	if (Url.getUrlSearchParam(location.search, 'xerror')) {
		return (
			<Container>
				<p>
					Authentication with Xero failed, <Link to="/xero/connections">go to connections page</Link>
				</p>
			</Container>
		)
	} else if (isReady) {
		return <Navigate to={appState.context === 'oversight' ? '/xero/connections' : redirect} />
	} else if (failMessage === '') {
		return (
			<Container className="loading-gif">
				<img
					src={appState.context === 'tayg' ? TAYGLoadingImage : appState.context === 'subsync' ? SubSyncLoadingImage : LoadingImage}
					alt="Loading ..."
				/>
				<div className="progress" style={{ margin: '20px 0px 20px 23%', width: '52%' }}>
					<div
						className="progress-bar"
						role="progressbar"
						aria-valuenow={currentProgress}
						aria-valuemin={0}
						aria-valuemax={100}
						style={{ width: `${currentProgress}%`, backgroundColor: appState.context === 'tayg' ? '#86A581' : '#8AA6FF' }}
					></div>
				</div>
				<div style={{ textAlign: 'center' }}>
					<div dangerouslySetInnerHTML={{ __html: loadingMessage }} />
				</div>
			</Container>
		)
	} else if (failMessage === 'No Payroll') {
		if (showDialog !== null && showDialog) {
			return (
				<Dialog
					show={showDialog}
					style={{ marginTop: '160px' }}
					handleClose={disconnectOrg}
					handleConfirm={disconnectOrg}
					confirmText={'Ok'}
					showOnlyConfirm={true}
					titleText={'Insufficient Xero Permissions'}
					bodyText={`Your Xero ${
						noPayrollOrgs.length > 1 ? 'accounts do' : 'account does'
					} not have sufficient permissions. Please contact your Xero administrator to get payroll access or have your payroll administrator authorise the connection for them`}
				/>
			)
		} else if (showDialog !== null && showDialog === false) {
			return <Navigate to={'/xero/connections'} />
		} else {
			return <></>
		}
	} else if (failMessage === 'Unsupported Region') {
		if (showDialog !== null && showDialog) {
			return (
				<Dialog
					show={showDialog}
					style={{ marginTop: '160px' }}
					handleClose={disconnectOrg}
					handleConfirm={disconnectOrg}
					confirmText={'Ok'}
					showOnlyConfirm={true}
					titleText={'Unsupported Region'}
				>
					{appState.context === 'leavecal' ? (
						<p style={{ padding: '10px' }}>
							Thanks for looking at LeaveCal. At the moment we support Xero payroll in Australia, New Zealand and the UK. It looks like your Xero
							is outside of those regions. If you need any further help please email us at{' '}
							<a href="mailto:support@finlert.com">support@finlert.com</a>.
						</p>
					) : (
						<p style={{ padding: '10px' }}>
							Thanks for looking at Tax As You Go. At the moment we only support Xero Orgs from Australia. It looks like your Xero is outside of
							this region. If you need any further help please email us at <a href="mailto:support@finlert.com">support@finlert.com</a>.
						</p>
					)}
				</Dialog>
			)
		} else if (showDialog !== null && showDialog === false) {
			return <Navigate to={'/xero/connections'} />
		} else {
			return <></>
		}
	} else {
		return (
			<Container>
				<p>{failMessage}</p>
			</Container>
		)
	}
}

export default Callback
