import React from 'react'
import { Col, Row, Image, Badge } from 'react-bootstrap'
import { Check } from 'react-feather'

import CloseButton from '../ui/closeButton/closeButton'
import { ModalListChildProps } from '../ui/modalList/modalList'

import { StripeConfig } from '../../constants/config'

import XeroConnImage from '../../images/xero_connections.png'

interface OnBoardModalProps extends ModalListChildProps {
	xeroConsentUrl: string
	xeroIsConnected: string
	stripeIsConnected: string
}

const OnboardModal = (props: OnBoardModalProps) => {
	return (
		<div className="oversight-container">
			<Row>
				<Col sm="auto">
					<h3>Let&apos;s get started</h3>
				</Col>
				<Col></Col>
				<Col sm="auto">
					<CloseButton onClick={props.handleClose} disabled={false} />
				</Col>
			</Row>
			<Row>
				<Col>
					<p>To use SubSync you need to connect to Xero and Stripe.</p>

					<p>
						<a className="btn btn-dark center" href={props.xeroConsentUrl}>
							Connect to Xero
							{props.xeroIsConnected && (
								<Badge variant={'success'} className="p-0 ml-2">
									<Check size={20} />
								</Badge>
							)}
						</a>
					</p>

					<p>
						<a className="btn btn-dark center" href={StripeConfig.InstallLink}>
							Connect to Stripe
							{props.stripeIsConnected && (
								<Badge variant={'success'} className="p-0 ml-2">
									<Check size={20} />
								</Badge>
							)}
						</a>
					</p>

					<p>
						To add more connections in the future you can go to the Connection screen.
						<Image src={XeroConnImage} className="center" style={{ width: '80%' }} />
					</p>
				</Col>
			</Row>
		</div>
	)
}

export default OnboardModal
