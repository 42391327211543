import React from 'react'
import { DateTime } from 'luxon'

import './SyncLogRow.css'
import { Badge } from 'react-bootstrap'
import { OverflowMenuOptions, getOriginalInvoiceEvent } from '../../components/subsync/overview/functions'
import { getStripeFeeEvent } from '../helpers/functions'
import { StripeInvoice } from '../../../../back-end/utilities/apiDefinitions'
import { SyncLogRowAction } from './SyncLogRowActions'

interface SyncLogRowProps extends OverflowMenuOptions {
	invoice: StripeInvoice
}

const SyncLogRow = (props: SyncLogRowProps) => {
	const originalInvoiceEvent = getOriginalInvoiceEvent(props.invoice.events)
	const stripeFeeEvent = getStripeFeeEvent(props.invoice.events)
	return (
		<tr>
			<td>
				<div className="pink inv-font">Inv# {originalInvoiceEvent?.stripeInvoiceNumber}</div>
				<div className="date-font">{DateTime.fromISO(props.invoice.createTs!).toFormat('dd LLL yyyy')}</div>
			</td>
			<td className="bottom-align">
				<Badge variant={originalInvoiceEvent?.result === 'success' ? 'success' : 'danger'}>
					{originalInvoiceEvent?.stripeInvoiceCurrency?.toUpperCase()} {originalInvoiceEvent?.stripeInvoiceAmount}
				</Badge>
			</td>
			<td className="bottom-align">
				{stripeFeeEvent && (
					<Badge variant={stripeFeeEvent.result === 'success' ? 'success' : 'danger'}>
						({stripeFeeEvent.stripeInvoiceCurrency?.toUpperCase()} {stripeFeeEvent.stripeInvoiceAmount})
					</Badge>
				)}
			</td>
			<td>
				<SyncLogRowAction
					stripeInvoice={props.invoice}
					creditNotesEnabled={props.creditNotesEnabled}
					stripeFeesBillsEnabled={props.stripeFeesBillsEnabled}
					historyView={props.historyView}
				/>
			</td>
		</tr>
	)
}

export { SyncLogRow }
