import React from 'react'
import { Navigate, useLocation } from 'react-router-dom'

// this page exists because Stripe won't allow us to direct users to a url without a path
const StripeRedirect = () => {
	const location = useLocation()
	const urlParams = new URLSearchParams(window.location.search)
	const search = urlParams.get('redirect')

	// handles normal paths + e.g. #settings
	const redirect = search || location.hash

	if (redirect) {
		return <Navigate to={'/' + redirect} />
	}
	return <Navigate to="/" />
}

export default StripeRedirect
