import React from 'react'
import { Button, Card, Col, Dropdown, DropdownButton, Form, Row, Spinner } from 'react-bootstrap'
import { TimePeriodPicker } from '../../oversight/timePeriodPicker'
import { DateRange } from '../../oversight/pointInTime'
import { SubSyncPageStatus } from '../../../screens/subsync/subsync.d'
import { AccountStripeOrg } from '../../../../../back-end/common/accountStripeOrg'
import { get, put } from '../../../utilities/request'
import { AppContext } from '../../../App'

const initialDateRange = {
	startDate: null,
	endDate: null,
}

const HistoryComponent = () => {
	const { appState } = React.useContext(AppContext)

	const [stripeOrgs, setStripeOrgs] = React.useState<AccountStripeOrg[]>([])
	const [stripeOrg, setStripeOrg] = React.useState<AccountStripeOrg | null>(null)
	const [period, updatePeriod] = React.useState<DateRange>(initialDateRange)
	const [invoiceStatus, setInvoiceStatus] = React.useState<'open' | 'all'>('all')

	const [pageStatus, setPageStatus] = React.useState<SubSyncPageStatus>('Loading')

	React.useEffect(() => {
		const fetchData = async () => {
			try {
				const res = await get('accountstripeorg?where=StripeOrgIsConnected==1', appState.authState)
				if (res.status === 200) {
					setStripeOrgs(res.data.accountStripeOrgs)
					setPageStatus('Ready')
				} else {
					setPageStatus('Error')
				}
			} catch (e) {
				console.log(e)
				setPageStatus('Error')
			}
		}
		if (appState.authState.isLoggedIn) {
			fetchData()
		}
	}, [appState.authState])

	const handleStart = async () => {
		setPageStatus('Submitting')
		try {
			const params = {
				stripeOrgID: stripeOrg?.stripeOrg?.stripeOrgID,
				filters: {
					startDate: period.startDate!.toISOString(),
					endDate: period.endDate!.toISOString(),
					status: invoiceStatus === 'all' ? undefined : invoiceStatus,
				},
			}
			const res = await put('subsynchistory', params, appState.authState)
			if (res.status === 200) {
				setPageStatus('Success')
			} else {
				setPageStatus('Error')
			}
		} catch (e) {
			console.log(e)
			setPageStatus('Error')
		}
	}

	return (
		<Card
			style={{
				marginLeft: '5vw',
				marginRight: '5vw',
				marginTop: '25px',
			}}
		>
			<Card.Body>
				<Row className="mb-5">
					<Col className="d-flex">
						<div className="settings-label">Historical Loading</div>
					</Col>
				</Row>
				<Row className="mb-3">
					<Col sm="auto" className="d-flex align-items-center">
						Select a Stripe account:
					</Col>
					<Col>
						<DropdownButton
							title={stripeOrg?.stripeOrg?.name || 'Select a Stripe account'}
							onSelect={(eventKey) => setStripeOrg(stripeOrgs.find((stripeOrg) => stripeOrg.stripeOrg?.stripeOrgID === eventKey) || null)}
						>
							{stripeOrgs.map((stripeOrg) => (
								<Dropdown.Item key={stripeOrg.stripeOrg?.stripeOrgID} eventKey={stripeOrg.stripeOrg?.stripeOrgID}>
									{stripeOrg.stripeOrg?.name}
								</Dropdown.Item>
							))}
						</DropdownButton>
					</Col>
				</Row>
				<Row className="mb-3">
					<Col sm="auto" className="d-flex align-items-center">
						Select a time period:
					</Col>
					<TimePeriodPicker dates={period} id={'period'} noPeriod handleDatesChange={(dates) => updatePeriod(dates)} />
				</Row>
				<Row className="mb-3">
					<Col sm="auto" className="d-flex align-items-center">
						Filter by invoice status:
					</Col>
					<Form.Check
						inline
						type={'radio'}
						label={'All'}
						value={'all'}
						id={'status-all'}
						checked={invoiceStatus === 'all'}
						onChange={() => setInvoiceStatus('all')}
					/>
					<Form.Check
						inline
						type={'radio'}
						label={'Open'}
						value={'open'}
						id={'status-open'}
						checked={invoiceStatus === 'open'}
						onChange={() => setInvoiceStatus('open')}
					/>
				</Row>
				<Row>
					<Col>
						<Button onClick={handleStart} variant="success" disabled={!stripeOrg || !period.startDate || !period.endDate || pageStatus !== 'Ready'}>
							{pageStatus === 'Submitting' ? <Spinner animation="border" size="sm" /> : 'Start'}
						</Button>
					</Col>
				</Row>
				<Row>
					<Col>
						{pageStatus === 'Success'
							? 'Historical loading in progress - this will take some time depending on the number of invoices. You may safely navigate away from this screen - please do not press the Start button again.'
							: pageStatus === 'Error'
							? 'Something went wrong - please contact us for help.'
							: ''}
					</Col>
				</Row>
			</Card.Body>
		</Card>
	)
}

export default HistoryComponent
