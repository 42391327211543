import React from 'react'
import { Badge, Button, Col, Row } from 'react-bootstrap'
import { DateTime } from 'luxon'
import { StripeProduct, StripeSubscription } from '../../../../back-end/utilities/apiDefinitions'
import './SubRow.css'
import { getSubRevenue } from '../helpers/functions'

import ExternalIcon from '../icons/external.svg'

interface SubRowProps {
	subscription: StripeSubscription
}

const SubRow = (props: SubRowProps) => {
	const { subscription } = props

	const handleViewStripeSub = React.useCallback(() => {
		window.open(`https://dashboard.stripe.com/subscriptions/${subscription.id}`, 'StripeViewSub')
	}, [subscription])

	return (
		<div className="sub-row">
			<Row>
				<Col>
					<h5 className="sub-product">{subscription.items.data.map((data) => (data.price.product as StripeProduct).name).join(' + ')}</h5>
				</Col>
			</Row>
			<Row>
				<Col xs="4">
					<span>Start Date:</span>
				</Col>
				<Col xs="8">
					<span>{DateTime.fromSeconds(subscription.start_date!).toFormat('dd LLL yyyy')}</span>
				</Col>
			</Row>
			<Row>
				<Col xs="4">
					<span>Status:</span>
				</Col>
				<Col xs="8">
					<Badge variant="success" pill className="active-pill">
						{subscription.status}
					</Badge>
				</Col>
			</Row>
			<Row>
				<Col xs="4">
					<span>Frequency:</span>
				</Col>
				<Col xs="8">
					<span>{getFrequencyText(subscription.items.data[0].price.recurring!)}</span>
				</Col>
			</Row>
			<Row>
				<Col xs="4">
					<span>Price:</span>
				</Col>
				<Col xs="8">
					<span>{getPriceText(subscription)}</span>
				</Col>
			</Row>
			<Row className="mt-4">
				<Col>
					<Button variant="primary-outline" onClick={handleViewStripeSub}>
						View Stripe subscription <img src={ExternalIcon} />
					</Button>
				</Col>
			</Row>
		</div>
	)
}

interface Recurring {
	interval: 'day' | 'week' | 'month' | 'year'
	interval_count: number
}

const getFrequencyText = (recurring: Recurring) => {
	if (recurring.interval_count === 1) {
		switch (recurring.interval) {
			case 'day':
				return 'Daily'
			case 'week':
				return 'Weekly'
			case 'month':
				return 'Monthly'
			case 'year':
				return 'Yearly'
		}
	} else {
		switch (recurring.interval) {
			case 'day':
				return `Every ${recurring.interval_count} Days`
			case 'week':
				return `Every ${recurring.interval_count} Weeks`
			case 'month':
				return `Every ${recurring.interval_count} Months`
			case 'year':
				return `Every ${recurring.interval_count} Years`
		}
	}
}

const getPriceText = (sub: StripeSubscription) => {
	const subValues = getSubRevenue([sub])
	return `${subValues.toFixed(2)} ${sub.currency.toUpperCase()}`
}

export { SubRow }
