import React, { useRef } from 'react'
import { Col, Row, Table, OverlayTrigger, Tooltip, Spinner } from 'react-bootstrap'
import { ChevronDown, ChevronUp } from 'react-feather'
import { FormatID, PagesWrapperChildProps } from './admin'
import * as Format from '../../utilities/format'
import * as Request from '../../utilities/request'
import { Product } from '../../../../back-end/common/product'
import * as AppDefinitions from '../../App.d'
import { Account } from './account'
import XeroDisconnect from '../../images/disconnect-white.svg'
interface Client {
	index: number
	xeroOrgID: string
	name: string
	products: Product[]
	version: string
	legalName: string
	lastSync: string
	active: boolean
	createTs: string
	numberOfEmployees: number
	connectionID?: string
	data: Client[]
	setData: React.Dispatch<React.SetStateAction<Client[]>>
	appState: AppDefinitions.AppState
	setTab: React.Dispatch<React.SetStateAction<string>>
	focusOn: {
		id: string
		setId: React.Dispatch<React.SetStateAction<string>>
	}
	setPage: React.Dispatch<React.SetStateAction<number>>
	pageSize: number
}

const ClientRow = (props: Client) => {
	const [isOpen, updateIsOpen] = React.useState<boolean>(props.focusOn.id === props.xeroOrgID ? true : false)
	const [accounts, updateAccounts] = React.useState<Account[]>([])
	const [disconnecting, updateDisconnecting] = React.useState<boolean>(false)
	const elemRef = useRef<HTMLTableRowElement | null>(null)
	React.useEffect(() => {
		const getData = async () => {
			const [accountsForXero] = await Promise.all([Request.get(`admin/account/${props.xeroOrgID}`, props.appState.authState)])
			updateAccounts(accountsForXero.data.accounts)
			props.focusOn.setId('')
		}

		if (props.appState.authState.isLoggedIn && isOpen) {
			getData()
		}

		if (props.focusOn.id === props.xeroOrgID && !isOpen) {
			if (props.index + 1 > props.pageSize) {
				props.setPage(Math.floor(props.index / props.pageSize) + 1)
			}
			updateIsOpen(true)
			elemRef.current?.lastElementChild?.scrollIntoView({ behavior: 'smooth' })
		}
	}, [isOpen, props])

	const disconnectOrg = async (connectionID: string) => {
		updateDisconnecting(true)
		await Request.del(`admin/xero/tokens/${props.appState.context}/${connectionID}`, props.appState.authState)
		props.setData(
			props.data
				? props.data.map((xo: Client) => {
						if (xo.xeroOrgID === props.xeroOrgID) {
							return {
								...xo,
								active: false,
								connectionID: undefined,
							}
						} else {
							return xo
						}
				  })
				: []
		)
		updateDisconnecting(false)
	}

	return (
		<>
			<tr key={props.index} ref={elemRef} className={`stripe-row ${props.index % 2 === 0 ? 'stripe-row-body' : ''}`}>
				<td>
					<FormatID id={props.xeroOrgID} />
				</td>
				<td>{props.name}</td>
				<td>{props.version}</td>
				<td>{props.legalName}</td>
				<td>{props.numberOfEmployees}</td>
				<td>{Format.dateTime(props.createTs)}</td>
				<td>{Format.dateTime(props.lastSync)}</td>
				<td>{props.active ? 'Active' : 'Inactive'}</td>
				<td>
					<td>
						{props.connectionID ? (
							disconnecting ? (
								<Spinner animation={'border'} size={'sm'} style={{ marginLeft: '5px' }} />
							) : (
								<img
									src={XeroDisconnect}
									alt="Disconnect from Xero"
									style={{ cursor: 'pointer' }}
									onClick={() => disconnectOrg(props.connectionID!)}
								/>
							)
						) : null}
					</td>
				</td>
				<td>
					{isOpen ? (
						<OverlayTrigger placement="left" overlay={<Tooltip id={`tooltip-xero`}>Show Less</Tooltip>}>
							<ChevronUp
								className="show-cursor"
								onClick={() => {
									props.focusOn.setId('')
									updateIsOpen(!isOpen)
								}}
							/>
						</OverlayTrigger>
					) : (
						<OverlayTrigger placement="left" overlay={<Tooltip id={`tooltip-xero`}>Show More</Tooltip>}>
							<ChevronDown
								className="show-cursor"
								onClick={() => {
									props.focusOn.setId('')
									updateIsOpen(!isOpen)
								}}
							/>
						</OverlayTrigger>
					)}
				</td>
			</tr>
			{isOpen ? (
				<tr style={{ backgroundColor: props.index % 2 === 0 ? '#f8f6f6' : '' }}>
					<td colSpan={2} style={{ verticalAlign: 'top' }}>
						<Table>
							<thead>
								<tr className="header-row" style={{ backgroundColor: props.index % 2 === 0 ? '#fff' : '#f8f6f6' }}>
									<th>Product Name</th>
								</tr>
							</thead>
							<tbody className={'card-table-body'}>
								{props.products.length > 0
									? props.products.map((p, i) => (
											<tr key={i} className={`stripe-row ${(i + props.index) % 2 === 0 ? 'stripe-row-body' : 'stripe-row-body-white'}`}>
												<td colSpan={10}>{p.name}</td>
											</tr>
									  ))
									: 'No products available'}
							</tbody>
						</Table>
					</td>
					<td colSpan={7} style={{ verticalAlign: 'top' }}>
						<Table>
							<thead>
								<tr className="header-row" style={{ backgroundColor: props.index % 2 === 0 ? '#fff' : '#f8f6f6' }}>
									<th>Account ID</th>
									<th>Account Name</th>
									<th>Auth User</th>
									<th>Auth Token</th>
									<th>Auth Expire Date</th>
									<th>Calendars</th>
								</tr>
							</thead>
							<tbody className={'card-table-body'}>
								{accounts
									? accounts.map((acc, i) => (
											<tr key={i} className={`stripe-row ${(i + props.index) % 2 === 0 ? 'stripe-row-body' : 'stripe-row-body-white'}`}>
												<td>
													<FormatID id={acc.accountID} />
												</td>
												<td>
													<span
														className="clickable"
														style={{ margin: '0px' }}
														onClick={() => {
															props.setTab('accounts')
															props.focusOn.setId(acc.accountID)
														}}
													>
														{acc.name}
													</span>
												</td>
												{acc.authUsers && acc.authUsers.length > 0 ? (
													<>
														<td style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>
															{acc.authUsers.map((u) => `${u.firstName} ${u.lastName}`).join('\n\n')}
														</td>
														<td style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>
															{acc.authUsers.map((u) => `${u.xeroAccessToken ? 'Token exists' : 'Null'}`).join('\n\n')}
														</td>
														<td style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>
															{acc.authUsers
																.map((u) => `${u.xeroTokenExpiry ? Format.dateTime(u.xeroTokenExpiry) : ''}`)
																.join('\n\n')}
														</td>
													</>
												) : (
													<>
														<td></td>
														<td></td>
														<td></td>
													</>
												)}
												{acc.calendars && acc.calendars.length > 0 ? (
													<td style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>
														{acc.calendars.map((c) =>
															c.outputToHtml ? (
																<>
																	<a key={c.calendarID} href={`${c.fileLocation}.html`} target="_blank" rel="noreferrer">
																		{c.fileName}
																	</a>
																	<br />
																</>
															) : (
																<></>
															)
														)}
													</td>
												) : (
													<td></td>
												)}
											</tr>
									  ))
									: 'No accounts found'}
							</tbody>
						</Table>
					</td>
				</tr>
			) : null}
		</>
	)
}

const ClientTab = (props: PagesWrapperChildProps<Client[]>) => (
	<>
		<Row>
			<Col>
				<h4>LeaveCal Clients</h4>
			</Col>
			{props.pageElements}
		</Row>
		<Row>
			<Col>
				<Table borderless responsive>
					<thead>
						<tr className="header-row">
							<th>XeroOrgID</th>
							<th>Org Name</th>
							<th>Org Country</th>
							<th>Org Legal Name</th>
							<th>No. Employees</th>
							<th>Created</th>
							<th>Last Sync</th>
							<th>Org Active</th>
							<th></th>
						</tr>
					</thead>
					<tbody className={'card-table-body'}>
						{props.isLoading && (
							<tr>
								<td colSpan={10}>Loading Clients</td>
							</tr>
						)}
						{!props.isLoading && props.data.length === 0 && (
							<tr>
								<td colSpan={10}>No Clients</td>
							</tr>
						)}
						{!props.isLoading &&
							props.data.length > 0 &&
							props.data
								.map((xo, index) => (
									<ClientRow
										key={xo.xeroOrgID}
										{...xo}
										index={index}
										pageSize={props.pageSize}
										setPage={props.setPage}
										setTab={props.setTab}
										focusOn={props.focusOn}
										data={props.data}
										setData={props.setData}
										appState={props.appState}
									/>
								))
								.slice(props.pageSize * (props.page - 1), props.pageSize * props.page)}
					</tbody>
				</Table>
			</Col>
		</Row>
	</>
)

export type { Client }
export default ClientTab
