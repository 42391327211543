import React, { Dispatch, SetStateAction } from 'react'

// definitions
import { AccountXeroOrg } from '../../../../back-end/utilities/apiDefinitions'
import { DataSetMetricGroupedByXeroOrg, MetricValue, XeroOrgsGroupedByTag } from '../../screens/oversight/oversight.d'

// iccons
import { ReactComponent as ASC } from '../../images/sort-asc.svg'
import { ReactComponent as DES } from '../../images/sort-des.svg'

type SortingButtonProps = {
	metricID: string
	orgs: AccountXeroOrg[]
	sort: string
	currentSort: { id: string; sort: string } | null
	groups: XeroOrgsGroupedByTag[] | null
	groupsWithErrors: XeroOrgsGroupedByTag[] | null
	updateGroups: Dispatch<SetStateAction<XeroOrgsGroupedByTag[] | null>>
	updateGroupsWithErrors: Dispatch<SetStateAction<XeroOrgsGroupedByTag[] | null>>
	updateCurrentSort: Dispatch<SetStateAction<{ id: string; sort: string } | null>>
}

const sortTypeBased = (metricA: MetricValue, metricB: MetricValue, orgA: DataSetMetricGroupedByXeroOrg, orgB: DataSetMetricGroupedByXeroOrg, sort: string) => {
	if (metricA.value === metricB.value) {
		return sort === 'DEC' ? orgB.name!.localeCompare(orgA.name!) : orgA.name!.localeCompare(orgB.name!)
	} else {
		switch (metricA.dataType) {
			case 'VARCHAR':
				return sort === 'DEC' ? (metricB!.value! > metricA!.value! ? 1 : -1) : metricA!.value! > metricB!.value! ? 1 : -1
			case 'DATETIME':
				return sort === 'DEC'
					? new Date(metricB!.value!).getTime() > new Date(metricA!.value!).getTime()
						? 1
						: -1
					: new Date(metricA!.value!).getTime() > new Date(metricB!.value!).getTime()
					? 1
					: -1
			default:
				return sort === 'DEC'
					? Number(metricB!.value!) > Number(metricA!.value!)
						? 1
						: -1
					: Number(metricA!.value!) > Number(metricB!.value!)
					? 1
					: -1
		}
	}
}

const SortingButton = (props: SortingButtonProps) => {
	const sort = (groups: XeroOrgsGroupedByTag[] | null, sort: string) => {
		if (groups) {
			if (props.metricID === 'name') {
				const newGroups: XeroOrgsGroupedByTag[] = groups!.map((group) => {
					return {
						...group,
						xeroOrgs: group.xeroOrgs!.sort((a, b) => (sort === 'DEC' ? b.name!.localeCompare(a.name!) : a.name!.localeCompare(b.name!))),
					}
				})
				return newGroups
			} else if (props.metricID === 'status') {
				//Check the refresh tokens
				const newGroups: XeroOrgsGroupedByTag[] = groups!.map((group) => {
					return {
						...group,
						xeroOrgs: group.xeroOrgs!.sort((a, b) => {
							const associatedXeroOrgA = props.orgs.find((o: AccountXeroOrg) => o.xeroOrg!.xeroOrgID === a.xeroOrgID)
							const associatedXeroOrgB = props.orgs.find((o: AccountXeroOrg) => o.xeroOrg!.xeroOrgID === b.xeroOrgID)
							const statusA = associatedXeroOrgA
								? associatedXeroOrgA.userAccountXeroOrgAuth!.refreshToken !== null &&
								  associatedXeroOrgA.userAccountXeroOrgAuth!.connectionID !== null
									? 'OK'
									: 'Re-auth needed'
								: 'Disconnected'
							const statusB = associatedXeroOrgB
								? associatedXeroOrgB.userAccountXeroOrgAuth!.refreshToken !== null &&
								  associatedXeroOrgB.userAccountXeroOrgAuth!.connectionID !== null
									? 'OK'
									: 'Re-auth needed'
								: 'Disconnected'
							if (statusA === statusB) {
								return sort === 'DEC' ? b.name!.localeCompare(a.name!) : a.name!.localeCompare(b.name!)
							} else {
								return sort === 'DEC' ? statusB.localeCompare(statusA) : statusA.localeCompare(statusB)
							}
						}),
					}
				})

				return newGroups
			} else {
				const newGroups: XeroOrgsGroupedByTag[] = groups!.map((group) => {
					return {
						...group,
						xeroOrgs: group.xeroOrgs!.sort((a, b) => {
							const metricA = a.metrics.find((m: any) => m.metricID === props.metricID)
							const metricB = b.metrics.find((m: any) => m.metricID === props.metricID)
							return sortTypeBased(metricA!, metricB!, a, b, sort)
						}),
					}
				})

				return newGroups
			}
		} else {
			return groups
		}
	}

	if (props.sort === 'ASC' && props.currentSort && props.metricID === props.currentSort.id) {
		return (
			<ASC
				onClick={() => {
					props.updateGroups(sort(props.groups, 'DEC'))
					props.updateGroupsWithErrors(sort(props.groupsWithErrors, 'DEC'))
					props.updateCurrentSort({ id: props.metricID, sort: 'DEC' })
				}}
				style={{
					filter: 'brightness(0%)',
					strokeWidth: '1px',
					stroke: 'black',
				}}
				className="small-clickable-icon"
			/>
		)
	} else if (props.sort === 'DEC' && props.currentSort && props.metricID === props.currentSort.id) {
		return (
			<DES
				onClick={() => {
					props.updateGroups(sort(props.groups, 'ASC'))
					props.updateGroupsWithErrors(sort(props.groupsWithErrors, 'ASC'))
					props.updateCurrentSort({ id: props.metricID, sort: 'ASC' })
				}}
				style={{
					filter: 'brightness(0%)',
					strokeWidth: '1px',
					stroke: 'black',
				}}
				className="small-clickable-icon"
			/>
		)
	} else {
		return (
			<ASC
				onClick={() => {
					props.updateGroups(sort(props.groups, 'ASC'))
					props.updateGroupsWithErrors(sort(props.groupsWithErrors, 'ASC'))
					props.updateCurrentSort({ id: props.metricID, sort: 'ASC' })
				}}
				className="small-clickable-icon"
			/>
		)
	}
}

export default SortingButton
