import React, { Dispatch, SetStateAction } from 'react'
import { Button, Col, Form, Row } from 'react-bootstrap'

import * as Messages from '../../../components/ui/messages/messages'

import * as AppDefinitions from '../../../App.d'
import * as Request from '../../../utilities/request'
import * as I from '../../../utilities/me'
import { MessageAction } from '../messages/message'
import * as RecordStatus from '../../../types/recordStatus'
import { FormErrors, MetricsInGroups, schemaMetrics, ThresholdMetric, ThresholdMetricGroup, ThresholdTemplateFields } from '../../../utilities/thresholds'
import { DataSetMetricGroupedByXeroOrg } from '../../../screens/oversight/oversight.d'
import { AccountXeroOrgMetric } from '../../../../../back-end/utilities/apiDefinitions'
import { ValidationError } from 'yup'

interface ThresholdMetricSettingsProps {
	type: 'thresholdSettings' | 'orgSettings'
	updateMessages: (action: MessageAction) => void
	metric: ThresholdMetric
	readonly?: boolean
	showSideButtons?: boolean
	reset: () => void
	updateThresholdTemplate?: React.Dispatch<React.SetStateAction<ThresholdTemplateFields>>
	updateXeroOrgMetrics?: React.Dispatch<React.SetStateAction<MetricsInGroups>>
	originalData?: any
	appState: AppDefinitions.AppState
	authState?: AppDefinitions.AuthState
	thresholdTemplate?: ThresholdTemplateFields
	xeroOrgMetrics?: MetricsInGroups
	metricsArray: ThresholdMetric[]
	selectedThresholdTemplate?: {
		metricID: string
		templateThresholdBad: number | null
		templateThresholdGood: number | null
		thresholdTemplateID: string
	} | null
	updateOriginalDataThreshold?: React.Dispatch<React.SetStateAction<ThresholdTemplateFields>>
	updateOriginalDataOrgSettings?: React.Dispatch<React.SetStateAction<MetricsInGroups>>
	errorMessage?: string
	updateErrors: React.Dispatch<React.SetStateAction<FormErrors>>
	updateReports?: Dispatch<SetStateAction<DataSetMetricGroupedByXeroOrg[] | null>>
}

const ThresholdMetricSettings = (props: ThresholdMetricSettingsProps) => {
	const [isMetricDisabled, updateMetricDisabled] = React.useState<boolean>(true)

	React.useEffect(() => {
		if (props.readonly !== undefined) {
			updateMetricDisabled(props.readonly)
		}
	}, [props.readonly])

	const postData = async () => {
		if (I.have('admin', props.appState.permission[props.appState.context])) {
			try {
				try {
					// try validation
					await schemaMetrics.validate(props.metric, { abortEarly: false })
				} catch (validationError) {
					// handle errors
					if (validationError instanceof ValidationError) {
						const errors = validationError.inner.map((err: { errors: string[] }) => err.errors.join(', '))
						// set errors in state
						props.updateErrors((existingErrors) => ({
							...existingErrors,
							[props.metric.metricID!]: errors.join(', '),
						}))
						return
					}
				}
				updateMetricDisabled(true)
				props.updateErrors({}) //remove any previous errors
				if (props.type === 'thresholdSettings' && props.thresholdTemplate) {
					const req = await Request.put(
						`threshold/${props.thresholdTemplate.thresholdTemplateID}/metric/${props.metric.metricID}`,
						{
							thresholdTemplateID: props.thresholdTemplate.thresholdTemplateID,
							metricID: props.metric.metricID,
							templateThresholdGood: props.metric.goodValue,
							templateThresholdBad: props.metric.badValue,
						},
						props.appState.authState
					)

					const updatedMetric = req.data.metrics
					const newMetrics = props.metricsArray.map((metric: ThresholdMetric) => {
						if (metric.metricID === updatedMetric[0].metricID) {
							return {
								...updatedMetric[0],
								goodValue: updatedMetric[0].templateThresholdGood,
								badValue: updatedMetric[0].templateThresholdBad,
							}
						}
						return metric
					})
					if (props.updateOriginalDataThreshold) {
						props.updateOriginalDataThreshold({
							...props.thresholdTemplate,
							metrics: convertDataToOriginalFormat(newMetrics, props.thresholdTemplate.metrics),
						})
					}
				} else if (props.type === 'orgSettings') {
					const req = await Request.put(
						`organisationmetric`,
						{
							MetricID: props.metric.metricID,
							RecordStatusID: RecordStatus.Active,
							AccountXeroOrgID: props.metric.accountXeroOrgID,
							thresholdGood: props.metric.goodValue,
							thresholdBad: props.metric.badValue,
							AccountXeroOrgMetricID: props.metric.accountXeroOrgMetricID,
						},
						props.authState!
					)

					const updatedMetric: AccountXeroOrgMetric = req.data
					const newOrgMetrics = props.metricsArray.map((orgMetric: ThresholdMetric) => {
						if (
							orgMetric.accountXeroOrgMetricID === updatedMetric.accountXeroOrgMetricID &&
							orgMetric.metricID === updatedMetric.metric!.metricID
						) {
							return {
								...orgMetric,
								goodValue: updatedMetric.thresholdGood!,
								badValue: updatedMetric.thresholdBad!,
							}
						}
						return orgMetric
					})
					if (props.updateOriginalDataOrgSettings) {
						props.updateOriginalDataOrgSettings(convertDataToOriginalFormat(newOrgMetrics, props.xeroOrgMetrics!))
					}
					if (props.updateReports) {
						props.updateReports((r: DataSetMetricGroupedByXeroOrg[] | null) => {
							if (r !== null) {
								r = r.map((org) => {
									if (org.accountXeroOrgID === updatedMetric.accountXeroOrgID) {
										return {
											...org,
											metrics: org.metrics.map((metric) => {
												if (
													metric.metricID === updatedMetric.metric!.metricID &&
													org.xeroOrgID === updatedMetric.accountXeroOrg!.xeroOrgID
												) {
													return {
														...metric,
														thresholdGood: updatedMetric.thresholdGood,
														thresholdBad: updatedMetric.thresholdBad,
													}
												}
												return metric
											}),
										}
									}
									return org
								})
							}
							return r
						})
					}
				}

				props.updateMessages(
					Messages.addMessage('success', `Succesfully updated ${props.type === 'thresholdSettings' ? 'threshold template' : 'metric settings'}`)
				)
			} catch (err) {
				console.log('Update Metric/Threshold Error: ', err)
				props.updateMessages(
					Messages.addMessage('danger', `Failed to update ${props.type === 'thresholdSettings' ? 'threshold template' : 'metric settings'}`)
				)
			}
		} else {
			props.updateMessages(
				Messages.addMessage(
					'danger',
					`You do no have permission to update a ${props.type === 'thresholdSettings' ? 'threshold template' : 'metric settings'}`
				)
			)
		}
	}

	const convertDataToOriginalFormat = (newMetrics: ThresholdMetric[], originalData: MetricsInGroups) => {
		const newValues = Object.keys(originalData)
			.map((key) => ({
				[key]: originalData[key].map((group: ThresholdMetricGroup) => ({
					...group,
					metrics: group.metrics!.map((metric: ThresholdMetric) => {
						const currMetric = newMetrics.find((met) => metric.metricID === met.metricID)
						return {
							...metric,
							goodValue: currMetric!.goodValue,
							badValue: currMetric!.badValue,
						}
					}),
				})),
			}))
			.reduce((obj, item) => {
				obj[Object.keys(item)[0]] = item[Object.keys(item)[0]]
				return obj
			}, {})
		return newValues
	}

	const handleValueChange = (event: any) => {
		const value = event.target.value
		const type = event.target.name
		const newMetrics = props.metricsArray.map((metric: ThresholdMetric) => {
			if (metric.metricID === props.metric.metricID) {
				if (type === 'goodValue') {
					if (value !== '') {
						return { ...metric, goodValue: Number(value) }
					} else {
						return { ...metric, goodValue: null }
					}
				} else if (type === 'badValue') {
					if (value !== '') {
						return { ...metric, badValue: Number(value) }
					} else {
						return { ...metric, badValue: null }
					}
				}
			}
			return metric
		})
		if (props.type === 'thresholdSettings' && props.thresholdTemplate) {
			//convert metric array into metrics array of thresholdTemplate
			props.updateThresholdTemplate!({
				...props.thresholdTemplate,
				metrics: convertDataToOriginalFormat(newMetrics, props.thresholdTemplate.metrics),
			})
		} else {
			props.updateXeroOrgMetrics!(convertDataToOriginalFormat(newMetrics, props.xeroOrgMetrics!))
		}
	}

	const getInBetweenValue = (thresholdGood: number | null, thresholdBad: number | null) => {
		if (thresholdGood !== null && thresholdBad !== null) {
			if (props.metric.biggerIsBetter) {
				return `>${thresholdBad} and <${thresholdGood}`
			} else {
				return `>${thresholdGood} and <${thresholdBad}`
			}
		}
		return ''
	}

	const decideRangeValues = (
		goodValue: number | null,
		badValue: number | null,
		templateValues: {
			metricID: string
			templateThresholdBad: number | null
			templateThresholdGood: number | null
			thresholdTemplateID: string
		} | null
	) => {
		if (templateValues) {
			if (goodValue !== null && badValue !== null) {
				return getInBetweenValue(goodValue, badValue)
			} else if (goodValue === null && badValue !== null) {
				return getInBetweenValue(templateValues.templateThresholdGood, badValue)
			} else if (goodValue !== null && badValue === null) {
				return getInBetweenValue(goodValue, templateValues.templateThresholdBad)
			} else {
				return ''
			}
		} else if (goodValue !== null && badValue !== null) {
			return getInBetweenValue(goodValue, badValue)
		} else {
			return ''
		}
	}

	return (
		<>
			<Form.Group as={Row}>
				<Form.Label column sm="3">
					{props.metric.name}
				</Form.Label>
				<Col sm="2">
					<Form.Control
						type={'number'}
						name="goodValue"
						placeholder={
							props.type === 'orgSettings' && props.selectedThresholdTemplate
								? props.selectedThresholdTemplate.templateThresholdGood!.toString()
								: ''
						}
						readOnly={isMetricDisabled}
						plaintext={isMetricDisabled}
						onClick={() => I.have('admin', props.appState.permission[props.appState.context]) && updateMetricDisabled(false)}
						onChange={handleValueChange}
						value={props.metric.goodValue !== null ? props.metric.goodValue : ''}
						className={!I.have('admin', props.appState.permission[props.appState.context]) ? 'no-hover' : 'border-grey-ouline'}
						isInvalid={props.errorMessage !== undefined}
					/>
				</Col>
				<Col sm="2">
					<Form.Control
						type="text"
						name="name"
						placeholder={
							props.type === 'orgSettings' && props.selectedThresholdTemplate
								? getInBetweenValue(props.selectedThresholdTemplate.templateThresholdGood, props.selectedThresholdTemplate.templateThresholdBad)
								: ''
						}
						readOnly={isMetricDisabled}
						plaintext={isMetricDisabled}
						value={decideRangeValues(
							props.metric.goodValue,
							props.metric.badValue,
							props.type === 'thresholdSettings' ? props.selectedThresholdTemplate! : null
						)}
						disabled={true}
						className={'no-hover'}
					/>
				</Col>
				<Col sm="2">
					<Form.Control
						type={'number'}
						name="badValue"
						placeholder={
							props.type === 'orgSettings' && props.selectedThresholdTemplate
								? props.selectedThresholdTemplate.templateThresholdBad?.toString()
								: ''
						}
						readOnly={isMetricDisabled}
						plaintext={isMetricDisabled}
						onClick={() => I.have('admin', props.appState.permission[props.appState.context]) && updateMetricDisabled(false)}
						onChange={handleValueChange}
						value={props.metric.badValue !== null ? props.metric.badValue : ''}
						className={!I.have('admin', props.appState.permission[props.appState.context]) ? 'no-hover' : 'border-grey-ouline'}
						isInvalid={props.errorMessage !== undefined}
					/>
				</Col>
				<Col sm="auto">
					{(!isMetricDisabled && props.showSideButtons) || (!isMetricDisabled && props.type === 'orgSettings') ? (
						<Button
							type="button"
							variant="success"
							onClick={() => {
								postData()
							}}
						>
							Save
						</Button>
					) : null}
				</Col>
				<Col sm="auto">
					{(!isMetricDisabled && props.showSideButtons) || (!isMetricDisabled && props.type === 'orgSettings') ? (
						<Button
							type="button"
							onClick={() => {
								updateMetricDisabled(true)
								props.reset()
							}}
						>
							Cancel
						</Button>
					) : null}
				</Col>
			</Form.Group>
			{props.errorMessage ? (
				<Row>
					<Col sm={{ offset: 3, span: 6 }}>
						<div className="invalid-feedback" style={{ display: 'block', position: 'relative', bottom: '10px' }}>
							{props.errorMessage}
						</div>
					</Col>
				</Row>
			) : null}
		</>
	)
}

export { ThresholdMetricSettings }
