import React from 'react'
import { Col, Row, Table } from 'react-bootstrap'
import { FormatID, PagesWrapperChildProps } from './admin'
import * as Format from '../../utilities/format'

interface Sub {
	xeroOrgProductID: string
	recordStatusID: string
	productName: string
	xeroOrgName: string
	xeroOrgID: string
	createTs: string
	modifiedTs: string
}

const SubRow = (props: Sub, index: number) => (
	<tr key={index} className={`stripe-row ${index % 2 === 0 ? 'stripe-row-body' : ''}`}>
		<td>
			<FormatID id={props.xeroOrgProductID} />
		</td>
		<td>{props.recordStatusID === '433a217a-9ff0-420e-9ed0-1668a38213aa' ? 'Active' : 'Deleted'}</td>
		<td>{props.productName}</td>
		<td>{props.xeroOrgName}</td>
		<td>
			<FormatID id={props.xeroOrgID} />
		</td>
		<td>{Format.dateTime(props.createTs)}</td>
		<td>{Format.dateTime(props.modifiedTs)}</td>
	</tr>
)

const SubTab = (props: PagesWrapperChildProps<Sub[]>) => (
	<>
		<Row>
			<Col>
				<h4>Subscriptions</h4>
			</Col>
			{props.pageElements}
		</Row>
		<Row>
			<Col>
				<Table borderless responsive>
					<thead>
						<tr className="header-row">
							<th>XeroOrgProductID</th>
							<th>Status</th>
							<th>Product</th>
							<th>Org Name</th>
							<th>Org ID</th>
							<th>Created</th>
							<th>Modified</th>
						</tr>
					</thead>
					<tbody className={'card-table-body'}>
						{props.isLoading && (
							<tr>
								<td colSpan={7}>Loading Subs</td>
							</tr>
						)}
						{!props.isLoading && props.data.length === 0 && (
							<tr>
								<td colSpan={7}>No Subs</td>
							</tr>
						)}
						{!props.isLoading &&
							props.data.length > 0 &&
							props.data.map(SubRow).slice(props.pageSize * (props.page - 1), props.pageSize * props.page)}
					</tbody>
				</Table>
			</Col>
		</Row>
	</>
)

export type { Sub }
export default SubTab
