import { AccountXeroOrg, XeroOrg } from '../../../../back-end/utilities/apiDefinitions'

interface Org extends XeroOrg {
	authStatus: boolean
	connectionID: string
}

interface UserOrg {
	userID: string
	firstName: string
	lastName: string
	orgs: Org[]
}

const groupConnectionsByUser = (accountXeroOrgs: AccountXeroOrg[]): UserOrg[] => {
	return accountXeroOrgs.reduce((users: UserOrg[], accountXeroOrg) => {
		const index = users.findIndex((u) => u.userID === accountXeroOrg.userAccountXeroOrgAuth!.user!.id)
		const org = {
			...accountXeroOrg.xeroOrg!,
			authStatus: !!accountXeroOrg.userAccountXeroOrgAuth?.refreshToken && !!accountXeroOrg.connectionID,
			connectionID: accountXeroOrg.connectionID!,
		}
		if (index >= 0) {
			users[index].orgs.push(org)
		} else {
			users.push({
				userID: accountXeroOrg.userAccountXeroOrgAuth!.user!.id,
				firstName: accountXeroOrg.userAccountXeroOrgAuth!.user!.firstName,
				lastName: accountXeroOrg.userAccountXeroOrgAuth!.user!.lastName,
				orgs: [org],
			})
		}
		return users
	}, [])
}

export { groupConnectionsByUser }
export type { UserOrg, Org }
