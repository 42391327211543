import React from 'react'
import { Form, Col, DropdownButton, Dropdown } from 'react-bootstrap'

// definitions
import { StripeRule } from '../../../../../../back-end/utilities/apiDefinitions'
import { CustomMappingProps } from './CustomMapping'

interface CustomMappingRuleProps extends CustomMappingProps {
	stripeRule: StripeRule
	handleRuleUpdate: (stripeRuleID: string, values: Partial<StripeRule>) => void
}

const CustomMappingRule = (props: CustomMappingRuleProps) => {
	const selectedStripeElementType = props.stripeMappingTypes.find((mappingType) => mappingType.stripeMappingTypeID === props.stripeRule.stripeMappingTypeID)
	return (
		<>
			<Col sm={3}>
				<DropdownButton
					id={`${props.customMapping.stripeOrgAccountXeroOrgMappingID}-stripe-select`}
					title={selectedStripeElementType?.name ?? 'Select...'}
					onSelect={(eventKey) => eventKey && props.handleRuleUpdate(props.stripeRule.stripeRuleID!, { stripeMappingTypeID: eventKey })}
				>
					{props.stripeMappingTypes.map((mappingType) => (
						<Dropdown.Item key={mappingType.stripeMappingTypeID} eventKey={mappingType.stripeMappingTypeID}>
							{mappingType.name}
						</Dropdown.Item>
					))}
				</DropdownButton>
			</Col>
			<Col sm={2}>
				<Form.Control
					value={props.stripeRule.key ?? ''}
					onChange={(e) => props.handleRuleUpdate(props.stripeRule.stripeRuleID!, { key: e.target.value })}
				/>
			</Col>
			<Col sm="auto" className="p-0 m-0 d-flex align-items-center">
				=
			</Col>
			<Col sm={3}>
				<Form.Control
					value={props.stripeRule.value ?? ''}
					onChange={(e) => props.handleRuleUpdate(props.stripeRule.stripeRuleID!, { value: e.target.value })}
				/>
			</Col>
		</>
	)
}

export { CustomMappingRule }
