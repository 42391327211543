import React from 'react'
import { TaygSettings, XeroAccount } from '../../../../back-end/utilities/apiDefinitions'
import { FilteredAccounts, FilterText } from './settings'
import { Col, Dropdown, Form, InputGroup, ListGroup, Row } from 'react-bootstrap'

interface SettingsDropDownProps {
	title: string
	selectedItem: XeroAccount | undefined | null
	updateSelectedItem: (e: XeroAccount | null) => void
	updateName: string
	selectOptions: XeroAccount[] | []
	filteredAccounts: FilteredAccounts
	updateFilteredAccounts: (e: FilteredAccounts) => void
	filterBy: 'gst' | 'payg' | 'super' | 'savings'
	searchText: FilterText
	updateSearchText: (e: FilterText) => void
	taygSettings: TaygSettings
	updateTaygSettings: (e: TaygSettings) => void
	noSelectOption?: boolean
}

const SettingsDropDown = (props: SettingsDropDownProps) => {
	const [show, updateShow] = React.useState<boolean>(false)
	return (
		<Row>
			<Col md={{ span: 5, offset: 1 }}>
				<div className="tayg-settings-label">{props.title}:</div>
			</Col>
			<Col md={{ span: 5, offset: 0 }}>
				<Dropdown style={{ marginBottom: '5px' }} onToggle={() => updateShow(!show)} show={show}>
					<Dropdown.Toggle
						id={
							props.selectedItem
								? props.filterBy !== 'savings'
									? `${props.selectedItem.code || 'No Code'} - ${props.selectedItem.name}`
									: `${props.selectedItem.name}`
								: 'Pick an account'
						}
					>
						{props.selectedItem
							? props.filterBy !== 'savings'
								? `${props.selectedItem.code || 'No Code'} - ${props.selectedItem.name}`
								: `${props.selectedItem.name}`
							: 'Pick an account'}
					</Dropdown.Toggle>

					<Dropdown.Menu className={'tayg-settings-dropdown'}>
						<ListGroup>
							{props.filteredAccounts ? (
								<ListGroup.Item className={'tayg-settings-search'}>
									<InputGroup size="sm" className="mb-3">
										<Form.Control
											value={
												props.filterBy === 'gst'
													? props.searchText.gstSearch
													: props.filterBy === 'payg'
													? props.searchText.paygSearch
													: props.filterBy === 'super'
													? props.searchText.superSearch
													: props.searchText.savingsSearch
											}
											placeholder={'Search...'}
											onChange={(event) => {
												props.updateSearchText({
													...props.searchText,
													[`${props.filterBy}Search`]: event.target.value,
												})
												props.updateFilteredAccounts({
													...props.filteredAccounts,
													[`${props.filterBy}Filter`]: props.selectOptions.filter(
														(xa: XeroAccount) =>
															xa.name?.toLowerCase().includes(event.target.value.toLowerCase()) ||
															xa.code?.includes(event.target.value)
													),
												})
											}}
										/>
									</InputGroup>
								</ListGroup.Item>
							) : null}
							{props.filteredAccounts ? (
								(
									(props.filterBy === 'gst'
										? props.filteredAccounts.gstFilter
										: props.filterBy === 'payg'
										? props.filteredAccounts.paygFilter
										: props.filterBy === 'super'
										? props.filteredAccounts.superFilter
										: props.filteredAccounts.savingsFilter) as XeroAccount[]
								).map((item, key) => {
									return (
										<ListGroup.Item
											className={'list-group-hover tayg-settings-select-option'}
											key={key}
											onClick={() => {
												props.updateSelectedItem({ ...item })
												props.updateTaygSettings({
													...props.taygSettings,
													[props.updateName]: item.xeroAccountID,
												})
												updateShow(!show)
											}}
										>
											{props.filterBy !== 'savings' ? `${item.code || 'No Code'} - ` : ''}
											{item.name}
										</ListGroup.Item>
									)
								})
							) : (
								<ListGroup.Item key={0} disabled={true} className={'tayg-settings-select-option'}>
									No Accounts
								</ListGroup.Item>
							)}
							{props.noSelectOption && props.selectedItem ? (
								<ListGroup.Item
									className={'list-group-hover tayg-settings-select-option'}
									key={0}
									onClick={() => {
										props.updateSelectedItem(null)
										props.updateTaygSettings({
											...props.taygSettings,
											[props.updateName]: null,
										})
										updateShow(!show)
									}}
								>
									Remove Account
								</ListGroup.Item>
							) : null}
						</ListGroup>
					</Dropdown.Menu>
				</Dropdown>
			</Col>
		</Row>
	)
}

export { SettingsDropDown }
