import React from 'react'
import { Row, Form, FormLabel, Col, Button, FormGroup, OverlayTrigger, Tooltip, Modal } from 'react-bootstrap'
import { ChevronDown, ChevronUp, Copy } from 'react-feather'
import * as Moment from 'moment'

// definitions
import { AccountXeroOrg, Calendar as CalendarData, Employee, LeaveApplication, LeaveType, Recurrence } from '../../../../back-end/utilities/apiDefinitions'
import { LeavePeriod } from '../../../../back-end/common/leaveApplication'
import * as AppDefinitions from '../../App.d'
import { S3Config } from '../../constants/config'
import * as Product from '../../constants/products'

// components
import * as Messages from '../../components/ui/messages/messages'
import { BigLeaveCalendar, CalendarLeave } from '../ui/bigCalendar/bigCalendar'
import LoadingImage from '../../images/Finlert-loading-GIF_100px.gif'

// utillites
import * as Request from '../../utilities/request'
import * as I from '../../utilities/me'
import Select from 'react-select'
import moment from 'moment'
import * as yup from 'yup'
import { MessageAction } from '../ui/messages/message'

enum LeavePeriodStatus {
	approved,
	completed,
	pending,
	rejected,
	cancelled,
}
interface CalendarComponentFrameProps {
	xeroOrgCalendars: CalendarData[]
	show: boolean
	appState: AppDefinitions.AppState
	currentOrg: AccountXeroOrg
	currentCalendar: CalendarData
	pageStatus: string
	updatePageStatus: React.Dispatch<React.SetStateAction<string>>
}

export const formatLeaveTitle = (title: string, character: string) => {
	if (title) {
		if (character === ' ') {
			return title + ' '
		} else if (character === '-') {
			return ' - ' + title
		}
	}
	return ''
}
const convertNumberToHoursAndMinutes = (hoursAsADecimal: number) => {
	return {
		hours: Math.floor(hoursAsADecimal),
		minutes: Number(((hoursAsADecimal - Math.floor(hoursAsADecimal)) * 60).toFixed(0)),
	}
}

const getAnniversaryYears = (eventDate: string, startDate: string) => {
	const amount = new Date(eventDate).getFullYear() - new Date(startDate).getFullYear()
	return amount === 1 ? '1 year' : `${amount} years`
}

const getAmount = (leaveApplication: LeaveApplication, showLeaveInDays: boolean, hoursInDay: number) => {
	const totalUnits = leaveApplication.leavePeriod
		? leaveApplication.leavePeriod.reduce((total: number, leavePeriod: LeavePeriod) => Number(total + (Number(leavePeriod.numberOfUnits) || 0)), 0)
		: 0

	if (leaveApplication.leaveType && leaveApplication.leaveType.unitType) {
		if (leaveApplication.leaveType.unitType.toLowerCase() === 'hours') {
			if (showLeaveInDays && totalUnits > 0) {
				const days = Math.floor(totalUnits / hoursInDay)
				const { hours, minutes } = convertNumberToHoursAndMinutes(totalUnits % hoursInDay)
				return ` - ${days > 0 ? days + ` day${days > 1 ? 's' : ''}` : ''}${days > 0 && hours > 0 ? ' and ' : ''}${hours > 0 ? hours + ' hours' : ''}${
					(days > 0 || hours > 0) && minutes > 0 ? ' and ' : ''
				}${minutes > 0 ? minutes + ' minutes' : ''}`
			} else {
				const { hours, minutes } = convertNumberToHoursAndMinutes(totalUnits)
				if (totalUnits > 0) {
					return ` - ${hours > 0 ? hours + ' hours' : ''}${hours > 0 && minutes > 0 ? ' and ' : ''}${minutes > 0 ? minutes + ' minutes' : ''}`
				}
			}
		} else {
			return ` - ${totalUnits} ${leaveApplication.leaveType.unitType.toLowerCase()}`
		}
	}
	return ''
}

const formatEventDate = (date: string, dontAddYear?: boolean) => {
	const givenDate = moment.utc(date)
	const today = moment.utc()
	const sixWeeksAgo = today.clone().subtract(6, 'weeks')

	const eventDate = moment.utc(today.clone().format(`YYYY-${givenDate.format('MM')}-${givenDate.format('DD')}`))

	if (eventDate.isBefore(sixWeeksAgo) && !dontAddYear) {
		return eventDate.clone().add(1, 'year').format('YYYY-MM-DD')
	} else {
		return eventDate.format('YYYY-MM-DD')
	}
}

const isNewStart = (startDate: string) => {
	// An anniversary is a newstart if the employee's start date is greater than the calendar start date
	const calStart = new Date()
	calStart.setDate(calStart.getDate() - 45)
	const givenDate = new Date(startDate)
	if (givenDate > calStart) {
		return true
	} else {
		return false
	}
}

interface HandleEventPopUpProps {
	popUpEventType: 'Edit' | 'Delete'
	handleClose: () => void
	show: boolean
	updateMessages: (e: MessageAction) => void
	leaveApplications: CalendarLeave[] | []
	appState: AppDefinitions.AppState
	updateLeaveApplications: React.Dispatch<React.SetStateAction<CalendarLeave[]>>
	leaveApplicationID: string | null
	leaveApplication?: LeaveApplication & { updatedStartOrEnd: boolean }
	calendar: CalendarData
	xeroOrgCalendars: CalendarData[]
}

const checkHoursWithinPeriod = (start: string | number | Date, end: string | number | Date) => {
	return (new Date(end).getTime() - new Date(start).getTime()) / (1000 * 3600) + 24 //+24 to include the end date hours
}

const HandleEventPopUp = (props: HandleEventPopUpProps) => {
	const [choice, updateChoice] = React.useState<string>('')
	const [isSubmitting, updateIsSubmitting] = React.useState<boolean>(false)
	const [isRecurring, updateIsRecurring] = React.useState<boolean | null>(null)

	React.useEffect(() => {
		const fetchData = async () => {
			const [leaveApplicationReq] = await Promise.all([
				Request.get(`leave?where=LeaveApplicationID=in(${props.leaveApplicationID})`, props.appState.authState),
			])
			if (leaveApplicationReq.data.leaveApplications.length > 0) {
				const recurrence = await leaveApplicationReq.data.leaveApplications[0].recurrence!
				const recurring = recurrence.recurrenceID !== null
				updateIsRecurring(recurring)

				if (!recurring) {
					handlePopUpEvent()
				}
			}
		}

		if (props.appState.authState && props.leaveApplicationID) {
			fetchData()
		}
	}, [props.appState.authState, props.leaveApplicationID])

	const handlePopUpEvent: () => Promise<void> = async () => {
		try {
			if (props.popUpEventType === 'Edit') {
				const employeeID = props.leaveApplication?.employeeID
				const affectedCals = props.xeroOrgCalendars!.filter((c) => c.employees!.find((emp) => emp.employeeID === employeeID))
				await Promise.all([
					Request.put(
						`leave/${props.leaveApplicationID}`,
						{ ...props.leaveApplication, choice: choice, updatedStartOrEnd: true },
						props.appState.authState
					),
					...affectedCals.map(async (c) =>
						Request.post(`organisation/${c.accountXeroOrg!.xeroOrgID}/updatecalendar`, null, props.appState.authState)
					),
				])
			} else if (props.popUpEventType === 'Delete') {
				const employeeID = props.leaveApplications.find((l) => l.leaveApplicationID === props.leaveApplicationID)!.employeeID
				const affectedCals = props.xeroOrgCalendars!.filter((c) => c.employees!.find((emp) => emp.employeeID === employeeID))
				await Promise.all([
					choice === 'this' || !isRecurring
						? Request.del(`leave/${props.leaveApplicationID}`, props.appState.authState)
						: Request.del(`leave/${props.leaveApplicationID}/${choice}`, props.appState.authState),
					...affectedCals.map(async (c) =>
						Request.post(`organisation/${c.accountXeroOrg!.xeroOrgID}/updatecalendar`, null, props.appState.authState)
					),
				])
			}

			props.updateMessages({
				type: 'add',
				data: {
					severity: 'success',
					message: `Successful ${props.popUpEventType.toLowerCase()} of ${isRecurring ? 'recurring ' : ''}event`,
					timeout: 3000,
					dismissible: true,
				},
			})

			if (props.popUpEventType === 'Edit') {
				props.updateLeaveApplications(
					props.leaveApplications.filter((leave: LeaveApplication) => leave.leaveApplicationID === props.leaveApplicationID)
				)
			} else {
				props.updateLeaveApplications(
					props.leaveApplications.filter((leave: LeaveApplication) => leave.leaveApplicationID !== props.leaveApplicationID)
				)
			}
			props.handleClose()
			updateIsSubmitting(false)
		} catch (err) {
			props.updateMessages({
				type: 'add',
				data: {
					severity: 'danger',
					message: `Unable to ${props.popUpEventType.toLowerCase()} ${isRecurring ? 'recurring ' : ''} event`,
					timeout: 3000,
					dismissible: true,
				},
			})
		}
	}

	if (isRecurring) {
		return (
			<Modal show={props.show} aria-labelledby="modal" centered onHide={props.handleClose}>
				<Modal.Header>
					<Modal.Title>{`${props.popUpEventType} recurring event`}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<FormGroup>
						<Row style={{ margin: '15px 0px 0px 0px' }}>
							<label>
								<input
									type="radio"
									style={{ marginRight: '5px', marginTop: '3px' }}
									value="this"
									checked={choice === 'this'}
									readOnly
									onClick={() => updateChoice('this')}
								/>{' '}
								This event{' '}
							</label>
						</Row>
						<Row style={{ margin: '15px 0px 0px 0px' }}>
							<label>
								<input
									type="radio"
									style={{ marginRight: '5px', marginTop: '3px' }}
									value="following"
									checked={choice === 'following'}
									readOnly
									onClick={() => updateChoice('following')}
								/>{' '}
								This and following events{' '}
							</label>
						</Row>
						<Row style={{ margin: '15px 0px 20px 0px' }}>
							<label>
								<input
									type="radio"
									style={{ marginRight: '5px', marginTop: '3px' }}
									value="all"
									checked={choice === 'all'}
									readOnly
									onClick={() => updateChoice('all')}
								/>{' '}
								All events{' '}
							</label>
						</Row>
						{!isSubmitting ? (
							<>
								<Button
									variant={'success'}
									disabled={isSubmitting}
									style={{ marginRight: '15px' }}
									onClick={() => {
										handlePopUpEvent()
										updateIsSubmitting(true)
									}}
								>
									Ok
								</Button>
								<Button variant={'secondary'} onClick={props.handleClose}>
									Cancel
								</Button>
							</>
						) : (
							<img src={LoadingImage} alt="Loading ..." style={{ width: '10%' }} />
						)}
					</FormGroup>
				</Modal.Body>
			</Modal>
		)
	} else {
		return <></>
	}
}

const leaveApp: LeaveApplication & { updatedStartOrEnd: boolean } = {
	description: '',
	startDate: undefined,
	endDate: undefined,
	employeeID: '',
	xeroOrgLeaveTypeID: '',
	leavePeriod: [
		{
			startDate: '',
			endDate: '',
			numberOfUnits: undefined,
		},
	],
	leaveType: undefined,
	employee: undefined,
	updatedStartOrEnd: true,
}

const recurrenceEntry: Recurrence = {
	recurrenceID: '',
	weeklyRegularity: undefined,
	startDate: undefined,
	endDate: null,
	isMonday: false,
	isTuesday: false,
	isWednesday: false,
	isThursday: false,
	isFriday: false,
	isSaturday: false,
	isSunday: false,
	typeIsWeekly: true,
	typeIsMonthly: false,
	typeIsAnnually: false,
}

const recurrenceRegularity = [
	{ label: 'week(s)', value: 'week' },
	{ label: 'month(s)', value: 'month' },
	{ label: 'year(s)', value: 'year' },
]

type EndingRecurrences = 'Never' | 'On' | 'After'

interface CalendarEntryPopUpProps {
	handleClose: () => void
	titleText: string
	types: LeaveType[] | null
	employees: Employee[] | undefined
	leaveApplications: CalendarLeave[]
	updateLeaveApplications: React.Dispatch<React.SetStateAction<[] | CalendarLeave[]>>
	updateMessages: (message: MessageAction) => void
	selectedDateForEvent?: string | null
	show: boolean
	appState: AppDefinitions.AppState
	currentOrg: AccountXeroOrg
	currentCalendar: CalendarData
	xeroOrgCalendars: CalendarData[]
	leaveApplicationID?: string | null
	showCalendar?: boolean
}
const CalendarEntryPopUp = (props: CalendarEntryPopUpProps) => {
	const [isEdit, updateIsEdit] = React.useState<boolean>(false)
	const [leaveApplication, updateLeaveApplication] = React.useState<LeaveApplication & { updatedStartOrEnd: boolean }>(leaveApp)
	const [recurring, updateRecurring] = React.useState<string>('No')
	const [recurrenceLeave, updateRecurrenceLeave] = React.useState<Recurrence>(recurrenceEntry)
	const [endingRecurrence, updateEndingRecurrence] = React.useState<EndingRecurrences>('Never')
	const [occurences, updateOccurences] = React.useState<number | null>(null)
	const [showEditRecurrenceDialog, updateShowEditRecurrenceDialog] = React.useState<boolean>(false)
	const [showDialog, updateShowDialog] = React.useState<boolean>(props.show)

	React.useEffect(
		() => {
			const fetchData = async () => {
				const [leaveApplicationReq] = await Promise.all([
					Request.get(`leave?where=LeaveApplicationID=in(${props.leaveApplicationID})`, props.appState.authState),
				])
				const leaveApplication = leaveApplicationReq.data.leaveApplications[0]
				const recurrence = leaveApplicationReq.data.leaveApplications[0].recurrence!
				const startDate = leaveApplication.startDate ? leaveApplication.startDate.slice(0, 10) : null
				const endDate = leaveApplication.endDate ? leaveApplication.endDate.slice(0, 10) : null
				updateLeaveApplication({
					...leaveApplication,
					startDate: startDate,
					endDate: endDate,
					updatedStartOrEnd: false,
					leavePeriod: [
						{
							...leaveApplication.leavePeriod![0],
							numberOfUnits: calculateNumberOfHours(startDate, endDate),
						},
					],
				})
				updateIsEdit(true)

				if (recurrence.recurrenceID !== null) {
					updateRecurring('Yes')
					updateRecurrenceLeave({
						...recurrence,
						startDate: recurrence.startDate.slice(0, 10),
						endDate: recurrence.endDate ? recurrence.endDate.slice(0, 10) : null,
					})
					updateEndingRecurrence(recurrence.endDate ? 'On' : 'Never')
				}
			}

			if (props.appState.authState && props.leaveApplicationID) {
				fetchData()
			}

			if (props.appState.authState && props.selectedDateForEvent) {
				updateLeaveApplication({
					...leaveApplication,
					startDate: props.selectedDateForEvent,
					endDate: props.selectedDateForEvent,
					leavePeriod: [
						{
							...leaveApplication.leavePeriod![0],
							numberOfUnits: calculateNumberOfHours(props.selectedDateForEvent, props.selectedDateForEvent),
						},
					],
				})
			}
		},
		// eslint-disable-next-line
		[props.appState.authState, props.leaveApplicationID, props.selectedDateForEvent]
	)

	// ---- Validation Schemas ---- //
	const schema = yup.object().shape({
		description: yup.string().optional(),
		startDate: yup.date().required('Start date is required.'),
		endDate: yup.date().min(yup.ref('startDate'), 'End date cannot be before start date.').required('End date is required.'),
		employeeID: yup.string().required('Employee is required.'),
		xeroOrgLeaveTypeID: yup.string().required('Leave type is required.'),
		leavePeriod: yup.array().of(
			yup.object().shape({
				numberOfUnits: yup
					.number()
					.min(0, 'Number of hours cannot be below 0.')
					.test({
						name: 'max',
						exclusive: false,
						params: {},
						message: 'Number of hours cannot be greater than\n the total hours in a period.',
						test: (value, context) => value! <= checkHoursWithinPeriod(context.parent.startDate, context.parent.endDate),
					})
					.required('Number of hours is required.'),
			})
		),
		recurrence: yup
			.object()
			.shape({
				weeklyRegularity: yup.number().min(1).required('Regularity frequency is required'),
				startDate: yup.date().required('Start date is required'),
				endDate:
					endingRecurrence === 'On'
						? yup.date().required('Ends on date is required').nullable()
						: endingRecurrence === 'After'
						? yup.date().required('Ends after occurences is required').nullable()
						: yup.date().optional().nullable(),
				isMonday: yup.boolean(),
				isTuesday: yup.boolean(),
				isWednesday: yup.boolean(),
				isThursday: yup.boolean(),
				isFriday: yup.boolean(),
				isSaturday: yup.boolean(),
				isSunday: yup.boolean(),
				daySelection: yup.boolean().when(['isMonday', 'isTuesday', 'isWednesday', 'isThursday', 'isFriday', 'isSaturday', 'isSunday'], {
					is: (
						isMonday: boolean,
						isTuesday: boolean,
						isWednesday: boolean,
						isThursday: boolean,
						isFriday: boolean,
						isSaturday: boolean,
						isSunday: boolean
					) => !isMonday && !isTuesday && !isWednesday && !isThursday && !isFriday && !isSaturday && !isSunday,
					then: yup.boolean().required('At least one repeating day required.'),
				}),
				typeIsWeekly: yup.boolean(),
				typeIsMonthly: yup.boolean(),
				typeIsAnnually: yup.boolean(),
			})
			.notRequired()
			.default(undefined),
	})

	const resetModal = () => {
		updateLeaveApplication({ ...leaveApp })
		updateRecurring('No')
		updateRecurrenceLeave(recurrenceEntry)
		updateEndingRecurrence('Never')
		updateOccurences(null)
	}

	const convertDayIntoRecurrence = (day: number) => {
		switch (day) {
			case 0:
				return { ...recurrenceLeave, isSunday: true }
			case 1:
				return { ...recurrenceLeave, isMonday: true }
			case 2:
				return { ...recurrenceLeave, isTuesday: true }
			case 3:
				return { ...recurrenceLeave, isWednesday: true }
			case 4:
				return { ...recurrenceLeave, isThursday: true }
			case 5:
				return { ...recurrenceLeave, isFriday: true }
			case 6:
				return { ...recurrenceLeave, isSaturday: true }
			default:
				return { ...recurrenceLeave, isMonday: true }
		}
	}

	const calculateEndDate = (occurences: number) => {
		const days = [
			recurrenceLeave.isSunday,
			recurrenceLeave.isMonday,
			recurrenceLeave.isTuesday,
			recurrenceLeave.isWednesday,
			recurrenceLeave.isThursday,
			recurrenceLeave.isFriday,
			recurrenceLeave.isSaturday,
		] //index corresponds to day in Date object
		if (recurring === 'Yes' && endingRecurrence === 'After' && recurrenceLeave.weeklyRegularity && days.includes(true)) {
			if (recurrenceLeave.typeIsMonthly || recurrenceLeave.typeIsAnnually) {
				const lastStart = new Date(recurrenceLeave.startDate!)
				return moment
					.utc(lastStart)
					.add(occurences * recurrenceLeave.weeklyRegularity, recurrenceLeave.typeIsMonthly ? 'month' : 'year')
					.format('YYYY-MM-DD')
			} else {
				const totalOFDaysAWeek = days.filter(Boolean).length
				const endingPointInDays = Math.ceil((occurences * (recurrenceLeave.weeklyRegularity * 7)) / totalOFDaysAWeek)
				const lastStart = new Date(recurrenceLeave.startDate!)
				lastStart.setDate(lastStart.getDate() + endingPointInDays)
				//console.log("Calculated end date", lastStart)
				if (lastStart.getDay() === days.lastIndexOf(true)) {
					return moment.utc(lastStart).format('YYYY-MM-DD')
				} else if (lastStart.getDay() > days.lastIndexOf(true)) {
					lastStart.setDate(lastStart.getDate() - (lastStart.getDay() - days.lastIndexOf(true)))
					return moment.utc(lastStart).format('YYYY-MM-DD')
				} else if (lastStart.getDay() < days.lastIndexOf(true)) {
					lastStart.setDate(lastStart.getDate() + (days.lastIndexOf(true) - lastStart.getDay()))
					return moment.utc(lastStart).format('YYYY-MM-DD')
				}
			}
		}

		return null
	}

	const handleUpdateNonRecurringEvent = async (updatedLeaveApp: LeaveApplication) => {
		try {
			const employeeID = updatedLeaveApp.employeeID
			const affectedCals = props.xeroOrgCalendars!.filter((c) => c.employees!.find((emp) => emp.employeeID === employeeID))
			const [req] = await Promise.all([
				Request.put(`leave/${props.leaveApplicationID}`, updatedLeaveApp, props.appState.authState),
				...affectedCals.map(async (c) => Request.post(`organisation/${c.accountXeroOrg!.xeroOrgID}/updatecalendar`, null, props.appState.authState)),
			])

			if (req.data.success) {
				props.updateMessages({
					type: 'add',
					data: {
						severity: 'success',
						message: 'Successfully updated custom leave',
						timeout: 3000,
						dismissible: true,
					},
				})
				resetModal()
				props.handleClose()
				const newLeave = req.data.leaveApplications[0]
				const event = {
					start: newLeave.startDate!,
					end: newLeave.endDate!,
					description: newLeave.description!,
					isNonXero: newLeave.leaveType.isNonXero,
					leaveApplicationID: newLeave.leaveApplicationID,
					title: `${
						(props.currentCalendar.showLeaveType && newLeave.leaveType) ||
						(newLeave.leaveType && newLeave.leaveType.isNonXero && newLeave.leaveType.showTypeInCalendar)
							? newLeave.leaveType.name
							: 'Leave'
					}: ${newLeave.employee!.alias ? newLeave.employee!.alias : `${newLeave.employee!.firstName} ${newLeave.employee!.lastName}`}${
						props.currentCalendar.showTitle ? formatLeaveTitle(newLeave.employee!.jobTitle!, '-') : ''
					}${props.currentCalendar.showGroup ? formatLeaveTitle(newLeave.employee!.groupName!, '-') : ''}${
						props.currentCalendar.showAmount && props.currentOrg.xeroOrg
							? getAmount(newLeave, props.currentOrg.xeroOrg.showLeaveInDays!, props.currentOrg.xeroOrg.hoursInDay!)
							: ''
					}`,
				}
				props.updateLeaveApplications(props.leaveApplications.concat(event))
				updateShowDialog(false)
			}
		} catch (err) {
			props.updateMessages({
				type: 'add',
				data: {
					severity: 'danger',
					message: `Unable to update event`,
					timeout: 3000,
					dismissible: true,
				},
			})
		}
	}

	const handleUpdatePress = async () => {
		const updatedLA = {
			...leaveApplication,
			leavePeriod: [
				{
					...leaveApplication.leavePeriod![0],
					startDate: leaveApplication.startDate,
					endDate: leaveApplication.endDate,
					status: 0,
				},
			],
			recurrence:
				recurring === 'Yes'
					? {
							weeklyRegularity: recurrenceLeave.weeklyRegularity,
							startDate: recurrenceLeave.startDate,
							endDate: recurrenceLeave.endDate,
							isMonday: recurrenceLeave.isMonday,
							isTuesday: recurrenceLeave.isTuesday,
							isWednesday: recurrenceLeave.isWednesday,
							isThursday: recurrenceLeave.isThursday,
							isFriday: recurrenceLeave.isFriday,
							isSaturday: recurrenceLeave.isSaturday,
							isSunday: recurrenceLeave.isSunday,
					  }
					: undefined,
		}

		try {
			await schema.validate(updatedLA, { abortEarly: false })
			if (recurring === 'Yes') {
				updateShowEditRecurrenceDialog(true)
				updateShowDialog(false)
			} else {
				handleUpdateNonRecurringEvent(updatedLA)
			}
		} catch (validationError) {
			if (validationError instanceof yup.ValidationError) {
				const newErrors = validationError.inner.map((err) => err.errors.join('\n'))
				props.updateMessages({
					type: 'add',
					data: {
						severity: 'danger',
						message: `Unable to add Custom calendar entry: \n\n ${newErrors
							.filter((val: string, id: number, array: string | string[]) => array.indexOf(val) === id)
							.join('\n')}`,
						timeout: 3000,
						dismissible: true,
					},
				})
			}
			return
		}
	}

	const handleCalendarEntryCreation = async () => {
		try {
			const newLA = {
				...leaveApplication,
				isNonXero: true,
				updatedDateUTC: moment.utc(new Date()).format('YYYY-MM-DD'),
				xeroOrgLeaveTypeID: leaveApplication.leaveType ? leaveApplication.leaveType!.xeroOrgLeaveTypeID : '',
				leavePeriod: [
					{
						...leaveApplication.leavePeriod![0],
						startDate: leaveApplication.startDate,
						endDate: leaveApplication.endDate,
						status: 0,
					},
				],
				recurrence:
					recurring === 'Yes'
						? {
								weeklyRegularity: recurrenceLeave.weeklyRegularity,
								startDate: recurrenceLeave.startDate,
								endDate: recurrenceLeave.endDate,
								isMonday: recurrenceLeave.isMonday,
								isTuesday: recurrenceLeave.isTuesday,
								isWednesday: recurrenceLeave.isWednesday,
								isThursday: recurrenceLeave.isThursday,
								isFriday: recurrenceLeave.isFriday,
								isSaturday: recurrenceLeave.isSaturday,
								isSunday: recurrenceLeave.isSunday,
								typeIsWeekly: recurrenceLeave.typeIsWeekly,
								typeIsMonthly: recurrenceLeave.typeIsMonthly,
								typeIsAnnually: recurrenceLeave.typeIsAnnually,
						  }
						: undefined,
			}

			try {
				await schema.validate(newLA, { abortEarly: false })
			} catch (validationError) {
				if (validationError instanceof yup.ValidationError) {
					const newErrors = validationError.inner.map((err) => err.errors.join('\n'))
					props.updateMessages({
						type: 'add',
						data: {
							severity: 'danger',
							message: `Unable to add Custom calendar entry: \n\n ${newErrors
								.filter((val: string, id: number, array: string | string[]) => array.indexOf(val) === id)
								.join('\n')}`,
							timeout: 3000,
							dismissible: true,
						},
					})
				}
				return
			}

			const employeeID = newLA.employeeID
			const affectedCals = props.xeroOrgCalendars!.filter((c) => c.employees!.find((emp) => emp.employeeID === employeeID))
			const [req] = await Promise.all([
				Request.post(`leave`, newLA, props.appState.authState),
				...affectedCals.map(async (c) => Request.post(`organisation/${c.accountXeroOrg!.xeroOrgID}/updatecalendar`, null, props.appState.authState)),
			])
			if (req.data.success) {
				props.updateMessages({
					type: 'add',
					data: {
						severity: 'success',
						message: 'Successfully created custom leave',
						timeout: 3000,
						dismissible: true,
					},
				})
				const newLeave = req.data.leaveApplications[0]
				const event = {
					start: newLeave.startDate!,
					end: newLeave.endDate!,
					description: newLeave.description!,
					isNonXero: newLeave.leaveType.isNonXero,
					leaveApplicationID: newLeave.leaveApplicationID,
					title: `${
						(props.currentCalendar.showLeaveType && newLeave.leaveType) ||
						(newLeave.leaveType && newLeave.leaveType.isNonXero && newLeave.leaveType.showTypeInCalendar)
							? newLeave.leaveType.name
							: 'Leave'
					}: ${newLeave.employee!.alias ? newLeave.employee!.alias : `${newLeave.employee!.firstName} ${newLeave.employee!.lastName}`}${
						props.currentCalendar.showTitle ? formatLeaveTitle(newLeave.employee!.jobTitle!, '-') : ''
					}${props.currentCalendar.showGroup ? formatLeaveTitle(newLeave.employee!.groupName!, '-') : ''}${
						props.currentCalendar.showAmount && props.currentOrg.xeroOrg
							? getAmount(newLeave, props.currentOrg.xeroOrg.showLeaveInDays!, props.currentOrg.xeroOrg.hoursInDay!)
							: ''
					}`,
				}
				props.updateLeaveApplications(props.leaveApplications.concat(event))
				resetModal()
				props.handleClose()
			}
		} catch (err) {
			props.updateMessages({
				type: 'add',
				data: {
					severity: 'danger',
					message: 'Unable to create custom leave',
					timeout: 3000,
					dismissible: true,
				},
			})
		}
	}

	const calculateNumberOfHours = (startDate: string | undefined | null, endDate: string | undefined | null) => {
		if (startDate && endDate && props.currentOrg.xeroOrg) {
			const days = Math.round(Math.abs((new Date(startDate).getTime() - new Date(endDate).getTime()) / (24 * 60 * 60 * 1000))) + 1 // to include end date
			return Number(days * props.currentOrg.xeroOrg.hoursInDay!)
		}

		return 0
	}

	return (
		<>
			<Modal
				show={showDialog}
				aria-labelledby="modal"
				centered
				onHide={() => {
					resetModal()
					props.handleClose()
				}}
			>
				{props.titleText ? (
					<Modal.Header>
						<Modal.Title>{props.titleText}</Modal.Title>
					</Modal.Header>
				) : null}
				<Modal.Body>
					<FormGroup>
						<Row>
							<Col sm="6">
								<Form.Control
									type="date"
									name="startDate"
									value={leaveApplication.startDate ? leaveApplication.startDate.split('T')[0] : leaveApplication.startDate}
									max={moment.utc().add('1', 'year').format('YYYY-MM-DD')}
									onChange={(e) => {
										const value = e.target.value.slice(0, 10)
										if (recurring === 'Yes') {
											updateRecurrenceLeave({
												...recurrenceLeave,
												startDate: value,
												endDate: value,
											})
										}
										const todayInOneYear = moment.utc().add('1', 'year')
										const threeMonthsBefore = moment.utc().subtract('90', 'days')
										if (moment.utc(value).isSameOrAfter(threeMonthsBefore) && moment.utc(value).isSameOrBefore(todayInOneYear)) {
											updateLeaveApplication({
												...leaveApplication,
												startDate: value,
												endDate: value,
												updatedStartOrEnd: true,
												leavePeriod: [
													{
														...leaveApplication.leavePeriod![0],
														numberOfUnits: calculateNumberOfHours(value, value),
													},
												],
											})
										}
									}}
								/>
							</Col>
							<Col sm="6">
								<Form.Control
									type="date"
									name="endDate"
									value={leaveApplication.endDate ? leaveApplication.endDate.split('T')[0] : leaveApplication.endDate}
									onChange={(e) => {
										const value = e.target.value.slice(0, 10) // we just want the date
										updateLeaveApplication({
											...leaveApplication,
											endDate: value,
											updatedStartOrEnd: true,
											leavePeriod: [
												{
													...leaveApplication.leavePeriod![0],
													numberOfUnits: calculateNumberOfHours(leaveApplication.startDate, value),
												},
											],
										})
									}}
								/>
							</Col>
						</Row>
						<Row style={{ padding: '15px' }}>
							<Form.Control
								type="number"
								placeholder={'Number of hours'}
								value={leaveApplication.leavePeriod![0].numberOfUnits}
								onChange={(e) =>
									updateLeaveApplication({
										...leaveApplication,
										leavePeriod: [
											{
												...leaveApplication.leavePeriod![0],
												numberOfUnits: Number(e.target.value),
											},
										],
									})
								}
							/>
						</Row>
						<Row>
							<Col style={{ paddingBottom: '15px' }}>
								<Select
									placeholder={'Select Type'}
									options={
										I.have('admin', props.appState.permission[props.appState.context])
											? props.types!.map((t) => ({
													label: t.name!,
													value: t.xeroOrgLeaveTypeID,
											  }))
											: props
													.types!.filter((t: LeaveType) => t.isEnteredByAdmin === false)
													.map((t) => ({
														label: t.name!,
														value: t.xeroOrgLeaveTypeID,
													}))
									}
									value={
										leaveApplication.leaveType !== undefined
											? {
													label: leaveApplication.leaveType!.name!,
													value: leaveApplication.leaveType!.xeroOrgLeaveTypeID,
											  }
											: undefined
									}
									onChange={(e) => {
										const selected = props.types!.find((t) => t.xeroOrgLeaveTypeID === e!.value)
										updateLeaveApplication({
											...leaveApplication,
											xeroOrgLeaveTypeID: selected!.xeroOrgLeaveTypeID,
											leaveType: selected,
										})
									}}
								/>
							</Col>
						</Row>
						<Row>
							<Col style={{ paddingBottom: '15px' }}>
								<Select
									placeholder={'Select Team Member'}
									options={props.employees!.map((e: Employee) => ({
										label: `${e!.alias ? e.alias : `${e!.firstName} ${e!.lastName}`}`,
										value: e.employeeID,
									}))}
									value={
										leaveApplication.employee !== undefined
											? {
													label: `${
														leaveApplication.employee!.alias
															? leaveApplication.employee!.alias
															: `${leaveApplication.employee!.firstName} ${leaveApplication.employee!.lastName}`
													}`,
													value: leaveApplication.employeeID,
											  }
											: undefined
									}
									onChange={(e) => {
										const selected = props.employees!.find((t) => t.employeeID === e!.value)
										updateLeaveApplication({
											...leaveApplication,
											employeeID: selected!.employeeID,
											employee: selected,
										})
									}}
								/>
							</Col>
						</Row>
						<Row>
							<Col sm="4">
								<FormLabel>Description (for inclusion in Calendar entries):</FormLabel>
							</Col>
							<Col sm="8">
								<textarea
									name="description"
									value={leaveApplication.description}
									className={'form-control'}
									rows={2}
									style={{ marginBottom: '15px' }}
									onChange={(e) =>
										updateLeaveApplication({
											...leaveApplication,
											description: e.target.value,
										})
									}
								/>
							</Col>
						</Row>
						<Row>
							<Col sm="4">
								<FormLabel>Recurring Event:</FormLabel>
							</Col>
							<Col sm="6">
								<label>
									<input
										type="radio"
										value="Yes"
										name="recurrence"
										checked={recurring === 'Yes'}
										onClick={() => {
											updateRecurring('Yes')
											updateRecurrenceLeave({
												...convertDayIntoRecurrence(new Date(leaveApplication.startDate!).getDay()),
												startDate: leaveApplication.startDate,
											})
											convertDayIntoRecurrence(new Date(leaveApplication.startDate!).getDay())
										}}
									/>{' '}
									Yes{' '}
								</label>
								<label>
									<input
										type="radio"
										value="No"
										name="recurrence"
										checked={recurring === 'No'}
										onClick={() => {
											updateRecurring('No')
											updateRecurrenceLeave(recurrenceEntry)
										}}
										style={{ marginLeft: '15px' }}
									/>{' '}
									No{' '}
								</label>
							</Col>
						</Row>
						{recurring === 'Yes' ? (
							<>
								<Row style={{ marginTop: '15px' }}>
									<Col sm="3">
										<FormLabel>Repeat every </FormLabel>
									</Col>
									<Col sm="3">
										<input
											type="number"
											min="1"
											style={{ marginTop: '-8px' }}
											className={'form-control'}
											value={recurrenceLeave.weeklyRegularity}
											onChange={(event) =>
												updateRecurrenceLeave({
													...recurrenceLeave,
													weeklyRegularity: parseInt(event.target.value),
												})
											}
										/>
									</Col>
									<Col sm="4" style={{ marginTop: '-8px' }}>
										<Select
											placeholder={'Select Recurrence Regularity'}
											options={recurrenceRegularity}
											value={recurrenceRegularity.find(
												(rec) =>
													rec.value ===
													(recurrenceLeave.typeIsWeekly
														? 'week'
														: recurrenceLeave.typeIsAnnually
														? 'year'
														: recurrenceLeave.typeIsMonthly
														? 'month'
														: '')
											)}
											onChange={(e) => {
												switch (e!.value) {
													case 'week':
														updateRecurrenceLeave({
															...recurrenceLeave,
															typeIsWeekly: true,
															typeIsAnnually: false,
															typeIsMonthly: false,
														})
														break
													case 'month':
														updateRecurrenceLeave({
															...recurrenceLeave,
															typeIsWeekly: false,
															typeIsAnnually: false,
															typeIsMonthly: true,
														})
														break
													case 'year':
														updateRecurrenceLeave({
															...recurrenceLeave,
															typeIsWeekly: false,
															typeIsAnnually: true,
															typeIsMonthly: false,
														})
														break
													default:
														return
												}
											}}
										/>
									</Col>
								</Row>
								<Row style={{ marginTop: '15px' }}>
									<Col sm="3">
										<FormLabel>Repeat on </FormLabel>
									</Col>
								</Row>
								<Row style={{ marginLeft: '0px', paddingBottom: '10px' }}>
									<button
										disabled={recurrenceLeave.typeIsAnnually || recurrenceLeave.typeIsMonthly}
										type="button"
										className={recurrenceLeave.isMonday ? 'focus-button-weekday-picker' : 'button-weekday-picker'}
										title="Monday"
										onClick={() =>
											updateRecurrenceLeave({
												...recurrenceLeave,
												isMonday: !recurrenceLeave.isMonday,
											})
										}
									>
										<span>M</span>
									</button>
									<button
										disabled={recurrenceLeave.typeIsAnnually || recurrenceLeave.typeIsMonthly}
										type="button"
										className={recurrenceLeave.isTuesday ? 'focus-button-weekday-picker' : 'button-weekday-picker'}
										title="Tuesday"
										onClick={() =>
											updateRecurrenceLeave({
												...recurrenceLeave,
												isTuesday: !recurrenceLeave.isTuesday,
											})
										}
									>
										<span>T</span>
									</button>
									<button
										disabled={recurrenceLeave.typeIsAnnually || recurrenceLeave.typeIsMonthly}
										type="button"
										className={recurrenceLeave.isWednesday ? 'focus-button-weekday-picker' : 'button-weekday-picker'}
										title="Wednesday"
										onClick={() =>
											updateRecurrenceLeave({
												...recurrenceLeave,
												isWednesday: !recurrenceLeave.isWednesday,
											})
										}
									>
										<span>W</span>
									</button>
									<button
										disabled={recurrenceLeave.typeIsAnnually || recurrenceLeave.typeIsMonthly}
										type="button"
										className={recurrenceLeave.isThursday ? 'focus-button-weekday-picker' : 'button-weekday-picker'}
										title="Thursday"
										onClick={() =>
											updateRecurrenceLeave({
												...recurrenceLeave,
												isThursday: !recurrenceLeave.isThursday,
											})
										}
									>
										<span>T</span>
									</button>
									<button
										disabled={recurrenceLeave.typeIsAnnually || recurrenceLeave.typeIsMonthly}
										type="button"
										className={recurrenceLeave.isFriday ? 'focus-button-weekday-picker' : 'button-weekday-picker'}
										title="Friday"
										onClick={() =>
											updateRecurrenceLeave({
												...recurrenceLeave,
												isFriday: !recurrenceLeave.isFriday,
											})
										}
									>
										<span>F</span>
									</button>
									<button
										disabled={recurrenceLeave.typeIsAnnually || recurrenceLeave.typeIsMonthly}
										type="button"
										className={recurrenceLeave.isSaturday ? 'focus-button-weekday-picker' : 'button-weekday-picker'}
										title="Saturday"
										onClick={() =>
											updateRecurrenceLeave({
												...recurrenceLeave,
												isSaturday: !recurrenceLeave.isSaturday,
											})
										}
									>
										<span>S</span>
									</button>
									<button
										disabled={recurrenceLeave.typeIsAnnually || recurrenceLeave.typeIsMonthly}
										type="button"
										className={recurrenceLeave.isSunday ? 'focus-button-weekday-picker' : 'button-weekday-picker'}
										title="Sunday"
										onClick={() =>
											updateRecurrenceLeave({
												...recurrenceLeave,
												isSunday: !recurrenceLeave.isSunday,
											})
										}
									>
										<span>S</span>
									</button>
								</Row>
								<Row>
									<Col sm="3">
										<FormLabel>Ends</FormLabel>
									</Col>
								</Row>
								<Row>
									<Col sm="3">
										<label>
											<input
												type="radio"
												value="Never"
												name="endingRecurrence"
												checked={endingRecurrence === 'Never'}
												onClick={() => {
													updateEndingRecurrence('Never')
													updateRecurrenceLeave({
														...recurrenceLeave,
														endDate: null,
													})
												}}
											/>{' '}
											Never{' '}
										</label>
									</Col>
								</Row>
								<Row style={{ marginTop: '15px' }}>
									<Col sm="2">
										<label>
											<input
												type="radio"
												value="On"
												name="endingRecurrence"
												checked={endingRecurrence === 'On'}
												onClick={() => updateEndingRecurrence('On')}
											/>{' '}
											On{' '}
										</label>
									</Col>
									<Col sm="6" style={{ marginTop: '-6px' }}>
										<Form.Control
											type="date"
											name="endDate"
											readOnly={endingRecurrence !== 'On'}
											value={endingRecurrence === 'On' ? recurrenceLeave.endDate! : undefined}
											onChange={(e) =>
												updateRecurrenceLeave({
													...recurrenceLeave,
													endDate: e.target.value,
												})
											}
										/>
									</Col>
								</Row>
								<Row style={{ marginTop: '15px', marginBottom: '20px' }}>
									<Col sm="2">
										<label>
											<input
												type="radio"
												value="After"
												name="endingRecurrence"
												checked={endingRecurrence === 'After'}
												onClick={() => updateEndingRecurrence('After')}
											/>{' '}
											After{' '}
										</label>
									</Col>
									<Col sm="3">
										<input
											type="number"
											min="0"
											readOnly={endingRecurrence !== 'After'}
											value={occurences!}
											style={{ marginTop: '-8px' }}
											className={'form-control'}
											onChange={(e) => {
												updateOccurences(parseInt(e.target.value))
												updateRecurrenceLeave({
													...convertDayIntoRecurrence(new Date(leaveApplication.startDate!).getDay()),
													endDate: calculateEndDate(parseInt(e.target.value)),
												})
											}}
										/>
									</Col>
									<Col sm="3">
										<FormLabel>occurence(s)</FormLabel>
									</Col>
								</Row>
							</>
						) : null}

						{!isEdit ? (
							<Button
								variant={'success'}
								style={{ marginRight: '15px' }}
								onClick={() => {
									handleCalendarEntryCreation()
								}}
							>
								Save
							</Button>
						) : (
							<Button
								variant={'success'}
								style={{ marginRight: '15px' }}
								onClick={() => {
									updateLeaveApplication({
										...leaveApplication,
										recurrence: recurrenceLeave,
									})
									handleUpdatePress()
								}}
							>
								Update
							</Button>
						)}

						<Button
							variant={'secondary'}
							onClick={() => {
								resetModal()
								props.handleClose()
							}}
						>
							Cancel
						</Button>
					</FormGroup>
				</Modal.Body>
			</Modal>
			{showEditRecurrenceDialog && (
				<HandleEventPopUp
					leaveApplication={leaveApplication}
					leaveApplicationID={leaveApplication.leaveApplicationID!}
					popUpEventType={'Edit'}
					handleClose={() => {
						updateShowEditRecurrenceDialog(false)
						updateShowDialog(false)
						resetModal()
						props.handleClose()
					}}
					show={showEditRecurrenceDialog}
					leaveApplications={props.leaveApplications}
					updateMessages={props.updateMessages}
					appState={props.appState}
					updateLeaveApplications={props.updateLeaveApplications}
					calendar={props.currentCalendar}
					xeroOrgCalendars={props.xeroOrgCalendars}
				/>
			)}
		</>
	)
}

const CalendarComponent = (props: CalendarComponentFrameProps) => {
	const [messages, updateMessages] = Messages.useMessageReducer([])
	const [LeaveApplications, updateLeaveApplications] = React.useState<CalendarLeave[] | []>([])
	const [EmployeeBirthdays, updateEmployeeBirthdays] = React.useState<CalendarLeave[] | []>([])
	const [EmployeeAnniversaries, updateEmployeeAnniversaries] = React.useState<CalendarLeave[] | []>([])
	const [trialEvent, updateTrialEvent] = React.useState<CalendarLeave[] | []>([])
	const [showAddCalendarEntryDialog, updateShowAddCalendarEntryDialog] = React.useState<boolean>(false)
	const [showEditCalendarEntryDialog, updateShowEditCalendarEntryDialog] = React.useState<boolean>(false)
	const [nonXeroLeaveTypes, updateNonXeroLeaveTypes] = React.useState<LeaveType[] | null>(null)
	const [showDeleteEventDialog, updateShowDeleteEventDialog] = React.useState<boolean>(false)
	const [selectedLeaveIDForEvent, updateSelectedLeaveIDForEvent] = React.useState<string | null>(null)
	const [selectedDateForEvent, updateSelectedDateForEvent] = React.useState<string | null>(null)

	const [showInfo, updateShowInfo] = React.useState<boolean>(false)

	React.useEffect(
		() => {
			const fetchData = async () => {
				const sixtyDaysAgo = Moment.utc().subtract(60, 'days').format('YYYY-MM-DD')
				const twelveMonthsInFuture = moment().add(1, 'year').format('YYYY-MM-DD')
				const uniqueEmployees = props.currentCalendar.employees!.map((e) => e.employeeID!)
				const leaveTypeIDs = props.currentCalendar.leaveTypes!.map((clt) => clt.xeroOrgLeaveTypeID)
				const [nonXeroLeaveTypes, calendarLeaveTypesData] = await Promise.all([
					Request.get(
						`leavetype?where=XeroOrgID==${props.currentOrg.xeroOrg!.xeroOrgID}&where=XeroOrgLeaveTypeIsNonXero==1`,
						props.appState.authState
					),
					Request.get(`calendar/${props.currentCalendar.calendarID}/leavetype`, props.appState.authState),
				])
				updateNonXeroLeaveTypes(
					nonXeroLeaveTypes.data.leaveTypes.filter(
						(lt: { isActive: boolean; xeroOrgID: string; xeroOrgLeaveTypeID: string }) =>
							lt.isActive &&
							lt.xeroOrgID === props.currentOrg.xeroOrg!.xeroOrgID &&
							calendarLeaveTypesData.data.calendarLeaveTypes.some(
								(clt: { xeroOrgLeaveTypeID: string }) => clt.xeroOrgLeaveTypeID === lt.xeroOrgLeaveTypeID
							)
					)
				)
				const nonXeroTypeIDs = nonXeroLeaveTypes.data.leaveTypes
					.filter(
						(lt: { isActive: boolean; xeroOrgID: string; xeroOrgLeaveTypeID: string }) =>
							lt.isActive &&
							lt.xeroOrgID === props.currentOrg.xeroOrg!.xeroOrgID &&
							calendarLeaveTypesData.data.calendarLeaveTypes.some(
								(clt: { xeroOrgLeaveTypeID: string }) => clt.xeroOrgLeaveTypeID === lt.xeroOrgLeaveTypeID
							)
					)
					.map((clt: { xeroOrgLeaveTypeID: string }) => clt.xeroOrgLeaveTypeID)
				const [leaveAppXero, leaveAppCustom] = await Promise.all([
					Request.get(
						`leave?where=LeaveApplicationStartDate>${sixtyDaysAgo}&where=LeaveApplicationIsNonXero==0&where=EmployeeID=in(${uniqueEmployees.join(
							','
						)})&where=XeroOrgLeaveTypeID=in(${leaveTypeIDs.concat(nonXeroTypeIDs).join(',')})`,
						props.appState.authState
					),
					Request.get(
						`leave?where=LeaveApplicationStartDate>${sixtyDaysAgo}&where=LeaveApplicationStartDate<=${twelveMonthsInFuture}&where=LeaveApplicationIsNonXero==1&where=EmployeeID=in(${uniqueEmployees.join(
							','
						)})&where=XeroOrgLeaveTypeID=in(${leaveTypeIDs.concat(nonXeroTypeIDs).join(',')})`,
						props.appState.authState
					),
				])

				const trial = props.currentOrg.xeroOrg!.products!.find((p) => p.productID === Product.LeaveCalTrial.productID)
				if (trial && trial.startDate) {
					const startDate = new Date(trial.startDate)
					startDate.setDate(startDate.getDate() + 15)
					const endDate = new Date(startDate)
					endDate.setDate(endDate.getDate() + 7)

					updateTrialEvent([
						{
							start: startDate.toJSON(),
							end: endDate.toJSON(),
							type: 'reminder',
							allDay: true,
							leaveApplicationID: 'trial',
							hexColour: '#FF0000',
							title: `Your LeaveCal trial is expiring - make sure you subscribe to keep receiving leave updates`,
							showTitle: true,
						},
					])
				}

				//Create B-day Events if selected
				const birthdayEvents = props.currentCalendar.showBirthday
					? props.currentCalendar
							.employees!.filter((e) => e.isActive === true && e.dateOfBirth !== null)
							.map((employee: Employee) => {
								const event = {
									start: props.currentCalendar.showDayOfBirthday
										? formatEventDate(employee.dateOfBirth!)
										: formatEventDate(moment.utc(employee.dateOfBirth!).format('YYYY-MM-01')),
									end: props.currentCalendar.showDayOfBirthday
										? formatEventDate(employee.dateOfBirth!)
										: formatEventDate(moment.utc(employee.dateOfBirth!).format('YYYY-MM-01')),
									type: 'birthday',
									allDay: true,
									employeeID: employee!.employeeID!,
									leaveApplicationID: `birthday-${employee!.employeeID!}`,
									showTitle: true,
									hexColour: '#F89995',
									title: `Birthday: ${employee!.alias ? employee.alias : `${employee!.firstName} ${employee!.lastName}`}${
										props.currentCalendar.showTitle ? formatLeaveTitle(employee!.jobTitle!, '-') : ''
									}${props.currentCalendar.showGroup ? formatLeaveTitle(employee!.groupName!, '-') : ''}`,
								}
								return event
							})
					: []
				updateEmployeeBirthdays(birthdayEvents)

				//Create Anniversary events if selected
				const anniversaryEvents =
					props.currentCalendar.showAnniversary ||
					props.currentCalendar.newEmployee1Month ||
					props.currentCalendar.newEmployee3Months ||
					props.currentCalendar.newEmployee6Months ||
					props.currentCalendar.newEmployee18Months
						? props.currentCalendar
								.employees!.filter((e) => e.isActive && e.startDate)
								.map((employee: Employee) => {
									const event = ([] as CalendarLeave[])
										.concat(
											props.currentCalendar.showAnniversary
												? [
														{
															start: formatEventDate(moment.utc(employee.startDate).toJSON()),
															end: formatEventDate(moment.utc(employee.startDate).toJSON()),
															type: 'anniversary',
															allDay: true,
															employeeID: employee!.employeeID!,
															leaveApplicationID: `anniversary-${employee!.employeeID!}`,
															showTitle: true,
															hexColour: '#F8C950',
															title: `${isNewStart(employee.startDate!) ? 'New Start' : 'Anniversary'}: ${
																employee!.alias ? employee.alias : `${employee!.firstName} ${employee!.lastName}`
															}${props.currentCalendar.showTitle ? formatLeaveTitle(employee!.jobTitle!, '-') : ''}${
																props.currentCalendar.showGroup ? formatLeaveTitle(employee!.groupName!, '-') : ''
															}${
																props.currentCalendar.showAnniversaryYears && !isNewStart(employee.startDate!)
																	? ` (${getAnniversaryYears(
																			formatEventDate(moment.utc(employee.startDate).toJSON()),
																			employee.startDate!
																	  )})`
																	: ''
															}`,
														},
												  ]
												: []
										)
										.concat(
											props.currentCalendar.newEmployee1Month
												? [
														{
															start: moment.utc(employee.startDate).add(1, 'month').toJSON(),
															end: moment.utc(employee.startDate).add(1, 'month').toJSON(),
															type: 'anniversary',
															leaveApplicationID: `anniversary-1-month-${employee!.employeeID!}`,
															allDay: true,
															hexColour: '#F8C950',
															title: `1 Month Anniversary: ${
																employee!.alias ? employee.alias : `${employee!.firstName} ${employee!.lastName}`
															}${props.currentCalendar.showTitle ? formatLeaveTitle(employee!.jobTitle!, '-') : ''}${
																props.currentCalendar.showGroup ? formatLeaveTitle(employee!.groupName!, '-') : ''
															}`,
														},
												  ]
												: []
										)
										.concat(
											props.currentCalendar.newEmployee3Months
												? [
														{
															start: moment.utc(employee.startDate).add(3, 'month').toJSON(),
															end: moment.utc(employee.startDate).add(3, 'month').toJSON(),
															type: 'anniversary',
															allDay: true,
															hexColour: '#F8C950',
															leaveApplicationID: `anniversary-3-months-${employee!.employeeID!}`,
															title: `3 Month Anniversary: ${
																employee!.alias ? employee.alias : `${employee!.firstName} ${employee!.lastName}`
															}${props.currentCalendar.showTitle ? formatLeaveTitle(employee!.jobTitle!, '-') : ''}${
																props.currentCalendar.showGroup ? formatLeaveTitle(employee!.groupName!, '-') : ''
															}`,
														},
												  ]
												: []
										)
										.concat(
											props.currentCalendar.newEmployee6Months
												? [
														{
															start: moment.utc(employee.startDate).add(6, 'month').toJSON(),
															end: moment.utc(employee.startDate).add(6, 'month').toJSON(),
															type: 'anniversary',
															allDay: true,
															hexColour: '#F8C950',
															leaveApplicationID: `anniversary-6-months-${employee!.employeeID!}`,
															title: `6 Month Anniversary: ${
																employee!.alias ? employee.alias : `${employee!.firstName} ${employee!.lastName}`
															}${props.currentCalendar.showTitle ? formatLeaveTitle(employee!.jobTitle!, '-') : ''}${
																props.currentCalendar.showGroup ? formatLeaveTitle(employee!.groupName!, '-') : ''
															}`,
														},
												  ]
												: []
										)
										.concat(
											props.currentCalendar.newEmployee18Months
												? [
														{
															start: moment.utc(employee.startDate).add(18, 'month').toJSON(),
															end: moment.utc(employee.startDate).add(18, 'month').toJSON(),
															type: 'anniversary',
															leaveApplicationID: `anniversary-18-months-${employee!.employeeID!}`,
															allDay: true,
															hexColour: '#F8C950',
															title: `18 Month Anniversary: ${
																employee!.alias ? employee.alias : `${employee!.firstName} ${employee!.lastName}`
															}${props.currentCalendar.showTitle ? formatLeaveTitle(employee!.jobTitle!, '-') : ''}${
																props.currentCalendar.showGroup ? formatLeaveTitle(employee!.groupName!, '-') : ''
															}`,
														},
												  ]
												: []
										)
									return event
								})
								.flat()
						: []

				updateEmployeeAnniversaries(anniversaryEvents)
				const activeEmployees = props.currentCalendar.employees!.filter((e) => e.isActive).map((e) => e.employeeID!)
				//Create leave events
				const leaves =
					leaveAppXero.data.leaveApplications.length > 0 || leaveAppCustom.data.leaveApplications.length > 0
						? leaveAppXero.data.leaveApplications
								.concat(leaveAppCustom.data.leaveApplications)
								.filter(
									(lv: LeaveApplication) =>
										activeEmployees.includes(lv.employee!.employeeID!) &&
										(props.currentCalendar.showUnapprovedLeave ||
											(lv.leavePeriod || []).every((lp) => lp.status !== LeavePeriodStatus.pending)) &&
										(lv.leavePeriod || []).every((lp) => lp.status !== LeavePeriodStatus.rejected)
								)
								.map((leave: LeaveApplication) => {
									const event = {
										start: leave.startDate!,
										end: leave.endDate!,
										allDay: true,
										description:
											(leave.isNonXero && props.currentCalendar.showCustomLeaveDescription) ||
											(!leave.isNonXero && props.currentCalendar.showPayrollDescription)
												? leave.description!
												: '',
										isNonXero: leave.isNonXero,
										type: leave.isNonXero ? 'custom' : 'leave',
										hexColour: leave.leaveType!.hexColour,
										leaveApplicationID: leave.leaveApplicationID,
										title: `${
											(props.currentCalendar.showLeaveType && leave.leaveType) ||
											(leave.leaveType && leave.leaveType.isNonXero && leave.leaveType.showTypeInCalendar)
												? `${leave.leavePeriod!.some((lp) => lp.status === LeavePeriodStatus.pending) ? 'UNAPPROVED ' : ''}${
														leave.leaveType.name
												  }`
												: `${leave.leavePeriod!.some((lp) => lp.status === LeavePeriodStatus.pending) ? 'UNAPPROVED ' : ''}Leave`
										}: ${leave.employee!.alias ? leave.employee!.alias : `${leave.employee!.firstName} ${leave.employee!.lastName}`}${
											props.currentCalendar.showTitle ? formatLeaveTitle(leave.employee!.jobTitle!, '-') : ''
										}${props.currentCalendar.showGroup ? formatLeaveTitle(leave.employee!.groupName!, '-') : ''}${
											props.currentCalendar.showAmount
												? getAmount(leave, props.currentOrg.xeroOrg!.showLeaveInDays!, props.currentOrg.xeroOrg!.hoursInDay!)
												: ''
										}`,
										createUserID: leave.createUserID,
										employeeID: leave.employee!.employeeID!,
										showTitle: true,
									}
									return event
								})
						: []
				updateLeaveApplications(leaves)

				// If this is a new calendar, show info
				if (localStorage.getItem('newCalendarID') && props.currentCalendar.calendarID === localStorage.getItem('newCalendarID')) {
					updateShowInfo(true)
					localStorage.removeItem('newCalendarID')
				}
				if (props.show) {
					props.updatePageStatus('Finished')
				}
			}

			if (props.currentCalendar.calendarID !== '') {
				fetchData()
			}
		},
		// eslint-disable-next-line
		[props.appState.authState, props.currentCalendar.calendarID, props.currentOrg, props.show, LeaveApplications.length]
	)

	const activeEmployees = props.currentCalendar.employees?.filter((e) => e.isActive) || []

	if (props.show) {
		if (props.pageStatus === 'Finished') {
			return (
				<>
					<FormGroup style={{ padding: '30px' }}>
						<Row className="calendar-alerts-card" style={{ paddingBottom: '0px' }}>
							<Col>
								<Row style={{ paddingLeft: '20px' }}>
									<FormLabel className="emails-label" style={{ paddingTop: '25px' }}>
										Team Calendar
									</FormLabel>
									<>
										{props.currentOrg!.xeroOrg!.products!.some((pr) => pr.productID === Product.CustomLeaveTypes.productID) ? (
											<>
												<Col sm="auto" className="position-right" style={{ padding: '20px' }}>
													<OverlayTrigger
														placement="auto"
														overlay={
															<Tooltip id={`tooltip-xero`}>
																{nonXeroLeaveTypes && nonXeroLeaveTypes.length !== 0
																	? 'Add custom calendar entry'
																	: 'You need to add a custom leave type in settings'}
																{/* Premium Feature - contact support@finlert.com to get started */}
															</Tooltip>
														}
													>
														<div>
															<Button
																type="button"
																onClick={() => {
																	updateShowAddCalendarEntryDialog(true)
																}}
															>
																Add Entry
															</Button>
														</div>
													</OverlayTrigger>
												</Col>
												{showAddCalendarEntryDialog && (
													<CalendarEntryPopUp
														show={showAddCalendarEntryDialog}
														handleClose={() => updateShowAddCalendarEntryDialog(false)}
														titleText={'Update Calendar'}
														types={nonXeroLeaveTypes}
														employees={activeEmployees}
														leaveApplications={LeaveApplications}
														updateLeaveApplications={updateLeaveApplications}
														updateMessages={updateMessages}
														appState={props.appState}
														currentCalendar={props.currentCalendar}
														xeroOrgCalendars={props.xeroOrgCalendars}
														currentOrg={props.currentOrg}
														selectedDateForEvent={selectedDateForEvent}
													/>
												)}
											</>
										) : (
											<Col sm="auto" className="position-right" style={{ padding: '20px' }}>
												<OverlayTrigger
													placement="auto"
													overlay={
														<Tooltip id={`tooltip-xero`}>Premium Feature - contact support@finlert.com to get started.</Tooltip>
													}
												>
													<div>
														<Button
															type="button"
															onClick={() => {
																updateShowAddCalendarEntryDialog(true)
															}}
														>
															Add Entry
														</Button>
													</div>
												</OverlayTrigger>
											</Col>
										)}
									</>
									{props.currentCalendar.outputToIcs || props.currentCalendar.outputToHtml ? (
										<Col className="position-right" style={{ paddingTop: '30px' }}>
											{showInfo ? (
												<OverlayTrigger placement="auto" overlay={<Tooltip id={`tooltip-xero`}>Show Less</Tooltip>}>
													<ChevronUp onClick={() => updateShowInfo(!showInfo)} />
												</OverlayTrigger>
											) : (
												<OverlayTrigger placement="left" overlay={<Tooltip id={`tooltip-xero`}>Show More</Tooltip>}>
													<ChevronDown onClick={() => updateShowInfo(!showInfo)} />
												</OverlayTrigger>
											)}
										</Col>
									) : null}
								</Row>
							</Col>
						</Row>

						<Row className="calendar-alerts-card ">
							<Col>
								{I.have('admin', props.appState.permission[props.appState.context]) &&
								showInfo &&
								(props.currentCalendar.outputToIcs || props.currentCalendar.outputToHtml) ? (
									<div style={{ backgroundColor: 'white', padding: '20px' }}>
										{props.currentCalendar.outputToIcs && (
											<>
												<Row style={{ marginTop: '-5px' }}>
													<p>
														Share your LeaveCal Calendar in all major Calendar Apps - including Google Calendar, Microsoft Outlook
														and Apple Calendar. Use the button below to copy a link which you can subscribe to. Follow our{' '}
														<a
															href={'https://help.finlert.com/leavecal/adding-leavecal-to-your-calendar/'}
															target="_blank"
															rel="noopener noreferrer"
														>
															simple installation instructions
														</a>
														!
													</p>
												</Row>
												<Row>
													<Col sm="auto">
														<Button
															type="button"
															onClick={() => {
																navigator.clipboard.writeText(
																	`https://${S3Config.BaseUrl}/${props.currentCalendar.fileLocation}.ics`
																)
																updateMessages({
																	type: 'add',
																	data: {
																		severity: 'success',
																		message: 'Copied URL to clipboard',
																		timeout: 3000,
																		dismissible: true,
																	},
																})
															}}
														>
															<Copy /> Copy Calendar Feed Link
														</Button>
													</Col>
												</Row>
											</>
										)}
										{props.currentCalendar.outputToHtml && (
											<>
												<Row>
													<Col sm="auto" style={{ paddingLeft: '0px' }}>
														<p style={{ margin: '10px 0px' }}>
															Get a link you can embed in Microsoft teams or your intranet.{' '}
															<a
																href={
																	'https://help.finlert.com/leavecal/viewing-leavecal-with-microsoft-teams-or-your-intranet/'
																}
																target="_blank"
																rel="noopener noreferrer"
															>
																Instructions can be found here.
															</a>
														</p>
													</Col>
												</Row>
												<Row>
													<Col sm="auto" style={{ textAlign: 'center' }}>
														<Button
															type="button"
															onClick={() => {
																navigator.clipboard.writeText(
																	`https://${S3Config.BaseUrl}/${props.currentCalendar.fileLocation}.html`
																)
																updateMessages({
																	type: 'add',
																	data: {
																		severity: 'success',
																		message: 'Copied URL to clipboard',
																		timeout: 3000,
																		dismissible: true,
																	},
																})
															}}
														>
															<Copy /> Copy Graphical Calendar Link
														</Button>
													</Col>
												</Row>
											</>
										)}
									</div>
								) : null}
								<Row
									style={{
										width: '100%',
										padding: '20px',
										marginLeft: '0px',
										display: 'inline',
									}}
								>
									<BigLeaveCalendar
										events={[...LeaveApplications, ...EmployeeAnniversaries, ...EmployeeBirthdays, ...trialEvent]}
										firstDayOfWeek={props.currentCalendar.firstDayOfWeek || 0}
										deleteEventPopUp={updateShowDeleteEventDialog}
										selectedEvent={updateSelectedLeaveIDForEvent}
										editEventPopUp={updateShowEditCalendarEntryDialog}
										updateShowAddCalendarEntryDialog={updateShowAddCalendarEntryDialog}
										updateSelectedDateForEvent={updateSelectedDateForEvent}
										readOnly={!I.have('admin', props.appState.permission[props.appState.context])}
										employees={props.currentCalendar.employees?.filter((e) => e.isActive) || []}
										currentCalendar={props.currentCalendar}
										currentUserID={props.appState.authState.attributes!['custom:userId'] || ''}
									/>
									{showDeleteEventDialog && (
										<HandleEventPopUp
											show={showDeleteEventDialog}
											popUpEventType={'Delete'}
											leaveApplicationID={selectedLeaveIDForEvent}
											leaveApplications={LeaveApplications}
											handleClose={() => updateShowDeleteEventDialog(false)}
											updateMessages={updateMessages}
											appState={props.appState}
											updateLeaveApplications={updateLeaveApplications}
											calendar={props.currentCalendar}
											xeroOrgCalendars={props.xeroOrgCalendars}
										/>
									)}
									{showEditCalendarEntryDialog && (
										<CalendarEntryPopUp
											leaveApplicationID={selectedLeaveIDForEvent}
											leaveApplications={LeaveApplications}
											updateMessages={updateMessages}
											appState={props.appState}
											updateLeaveApplications={updateLeaveApplications}
											showCalendar={showAddCalendarEntryDialog}
											types={nonXeroLeaveTypes}
											employees={props.currentCalendar.employees?.filter((e) => e.isActive)}
											currentCalendar={props.currentCalendar}
											xeroOrgCalendars={props.xeroOrgCalendars}
											currentOrg={props.currentOrg}
											show={showEditCalendarEntryDialog}
											handleClose={() => updateShowEditCalendarEntryDialog(false)}
											titleText={'Edit Calendar Event'}
										/>
									)}
								</Row>
							</Col>
						</Row>
					</FormGroup>
					<Messages.Messages messages={messages} updateMessage={updateMessages} />
				</>
			)
		} else {
			return (
				<div className="loading-gif">
					<img src={LoadingImage} alt="Loading ..." />
				</div>
			)
		}
	} else {
		return null
	}
}

export default CalendarComponent
