import React from 'react'
import { Col, Form, InputGroup, Row, Table } from 'react-bootstrap'
import { Search } from 'react-feather'
import { SyncLogRow } from './SyncLogRow'
import { PaginationFooter } from '../common/PaginationFooter'
import { StripeInvoice } from '../../../../back-end/common/stripeInvoice'

import './SyncLogTab.css'
import { StripeOrgAccountXeroOrg } from '../../../../back-end/common/stripeOrgAccountXeroOrg'

const PAGE_SIZE = 20

interface SyncLogTabProps {
	invoices: StripeInvoice[]
	orgMappings: StripeOrgAccountXeroOrg[]
}

const SyncLogTab = (props: SyncLogTabProps) => {
	const [filter, setFilter] = React.useState<string>('')
	const [page, setPage] = React.useState<number>(1)

	const handlePageChange = React.useCallback(
		(newPage: number) => {
			setPage(newPage)
		},
		[setPage]
	)
	const handleFilterChange = React.useCallback(
		(newFilter: string) => {
			setFilter(newFilter)
			setPage(1)
		},
		[setPage, setFilter]
	)

	const contactName = props.invoices[0]?.invoicee
	const filteredInvoices = React.useMemo(
		() =>
			props.invoices
				.slice((page - 1) * PAGE_SIZE, page * PAGE_SIZE)
				.filter((inv) => inv.events!.some((event) => event.stripeInvoiceNumber!.includes(filter))),
		[page, filter, props.invoices]
	)
	return (
		<>
			{props.invoices.length > 0 && (
				<Row>
					<Col>
						<h2 className="p-3 contact-heading">{contactName}</h2>
					</Col>
				</Row>
			)}
			<Row>
				<Col>
					<InputGroup className="p-3 search-bar">
						<InputGroup.Text>
							<Search />
						</InputGroup.Text>
						<Form.Control placeholder="Search" value={filter} onChange={({ target }) => handleFilterChange(target.value)} />
					</InputGroup>
				</Col>
			</Row>
			<Row>
				<Col>
					<Table>
						<thead>
							<tr>
								<th>Creation date</th>
								<th>Amount</th>
								<th>Stripe fee</th>
								<th></th>
							</tr>
						</thead>
						<tbody>
							{filteredInvoices.length === 0 && (
								<tr>
									<td colSpan={3}>No Stripe Invoices for this Contact</td>
								</tr>
							)}
							{filteredInvoices.map((inv) => (
								<SyncLogRow
									key={inv.stripeInvoiceID}
									invoice={inv}
									historyView={{ events: props.invoices }}
									stripeFeesBillsEnabled={props.orgMappings.some(
										(mapping) => mapping.stripeOrgAccountXeroOrgSettings?.stripeFeesBillsEnabled
									)}
									creditNotesEnabled={props.orgMappings.some((mapping) => mapping.stripeOrgAccountXeroOrgSettings?.creditNotesEnabled)}
								/>
							))}
						</tbody>
					</Table>
					<div className="mb-6">&nbsp;</div>
				</Col>
			</Row>
			<PaginationFooter items={filteredInvoices.length} page={page} pageSize={PAGE_SIZE} onPageChange={handlePageChange} />
		</>
	)
}

export { SyncLogTab }
