const getPermissionProperty = (product: 'leavecal' | 'oversight' | 'tayg' | 'subsync'): string => {
	if (product === 'leavecal') {
		return 'lcPermissionID'
	} else if (product === 'oversight') {
		return 'osPermissionID'
	} else if (product === 'tayg') {
		return 'taygPermissionID'
	} else if (product === 'subsync') {
		return 'subsyncPermissionID'
	}
	return 'lcPermissionID'
}

export { getPermissionProperty }
