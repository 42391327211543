import React, { useEffect, useContext } from 'react'
import { XeroEmbeddedAppContext } from '@xeroapi/embedded-apps-sdk'
import AwsConfig from '../constants/aws.json'

function Settings() {
	const { xeroEmbeddedAppClient } = useContext(XeroEmbeddedAppContext)

	useEffect(() => {
		xeroEmbeddedAppClient!.display()
	}, [])

	const getContent = () => {
		return (
			<div className="p-3">
				<p>
					To configure SubSync please visit{' '}
					<a href={`${AwsConfig.SubsyncUrl}#settings`} target="_blank" rel="noopener noreferrer">
						subsync.finlert.com
					</a>
				</p>
			</div>
		)
	}

	return <div className="ea-content">{getContent()}</div>
}

export { Settings }
