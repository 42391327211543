import React from 'react'
import { Form, Row, Col } from 'react-bootstrap'

// definitions
import { CustomMappings, CustomMappingsProps } from './CustomMappings'
import { ProductMapping, ProductMappingProps } from './ProductMapping'

interface ProductSettingsProps extends ProductMappingProps, CustomMappingsProps {
	handleOrgUpdate: (stripeOrgID: string, name: string, value: string) => void
}

const ProductSettings = (props: ProductSettingsProps) => {
	return (
		<>
			<Form.Group as={Row} className="mb-5">
				<Col sm="2">
					<Form.Label className="mr-3">Mapping from Stripe to Xero:</Form.Label>
				</Col>
				<Col className="d-flex flex-column">
					<Form.Check
						inline
						type={'radio'}
						label={'Stripe Products to Xero Accounts'}
						value={'Account'}
						id={`${props.stripeOrgID}-mapping-type-account`}
						checked={props.orgMapping?.stripeOrgAccountXeroOrgMappingType === 'Account'}
						onChange={() => props.handleOrgUpdate(props.stripeOrgID!, 'stripeOrgAccountXeroOrgMappingType', 'Account')}
					/>
					<Form.Check
						inline
						type={'radio'}
						label={'Stripe Products to Xero Products'}
						value={'Item'}
						id={`${props.stripeOrgID}-mapping-type-item`}
						checked={props.orgMapping?.stripeOrgAccountXeroOrgMappingType === 'Item'}
						onChange={() => props.handleOrgUpdate(props.stripeOrgID!, 'stripeOrgAccountXeroOrgMappingType', 'Item')}
					/>
					<Form.Check
						inline
						type={'radio'}
						label={'Advanced mapping (Stripe metadata)'}
						value={'Custom'}
						id={`${props.stripeOrgID}-mapping-type-custom`}
						checked={props.orgMapping?.stripeOrgAccountXeroOrgMappingType === 'Custom'}
						onChange={() => props.handleOrgUpdate(props.stripeOrgID!, 'stripeOrgAccountXeroOrgMappingType', 'Custom')}
					/>
				</Col>
			</Form.Group>
			{props.orgMapping?.stripeOrgAccountXeroOrgMappingType === 'Custom' ? (
				<CustomMappings
					stripeOrgID={props.stripeOrgID!}
					orgMapping={props.orgMapping!}
					types={['Account', 'Item']}
					customMappings={props.orgMapping!.customMappings!}
					mappedXeroOrg={props.mappedXeroOrg}
					stripeMappingTypes={props.stripeMappingTypes}
					handleCustomMappingUpdate={props.handleCustomMappingUpdate}
					handleSettingsUpdate={props.handleSettingsUpdate}
				/>
			) : (
				<ProductMapping
					stripeProducts={props.stripeProducts}
					orgMapping={props.orgMapping!}
					mappedXeroOrg={props.mappedXeroOrg}
					stripeOrgID={props.stripeOrgID!}
					handleProductUpdate={props.handleProductUpdate}
					handleProductAdd={props.handleProductAdd}
					handleApplyToAllProducts={props.handleApplyToAllProducts}
				/>
			)}
		</>
	)
}

export { ProductSettings }
export type { ProductSettingsProps }
