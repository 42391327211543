import React from 'react'
import { Form, Col, Row, Button } from 'react-bootstrap'

import * as I from '../../utilities/me'
import * as Request from '../../utilities/request'
import { Tag } from '../../../../back-end/utilities/apiDefinitions'
import { AppState } from '../../App.d'

interface SingleTagProps {
	tag: Tag
	appState: AppState
	displayMessage: (severity: 'danger' | 'success', message: string) => void
	updateTags: (tag: Tag, deleted?: boolean) => void
}

const TagSingle = (props: SingleTagProps) => {
	const [isNameDisabled, updateNameDisabled] = React.useState<boolean>(true)
	const [hasSomethingChanged, updateSomethingChanged] = React.useState<boolean>(false)

	const [originalData, updateOriginalData] = React.useState<Tag>({
		tagName: props.tag.tagName,
	})
	const [fields, updateFields] = React.useState<Tag>({
		tagName: props.tag.tagName,
	})

	const updateTag = async () => {
		if (I.have('admin', props.appState.permission[props.appState.context])) {
			if (hasSomethingChanged) {
				const req = await Request.put(
					`tag/${props.tag.tagID}`,
					{
						tagName: fields.tagName,
						tagGroup: props.tag.tagGroup,
					},
					props.appState.authState
				)
				updateOriginalData({
					tagName: req.data.tags[0].tagName,
				})
				props.displayMessage('success', `Successfully updated tag ${fields.tagName}`)
				props.updateTags(req.data.tags[0])
			}
		} else {
			props.displayMessage('danger', 'You do not have permission to edit this tag')
		}
	}
	const resetTag = async () => {
		updateFields(originalData)
		props.tag.tagID === 'new' && props.updateTags(fields, true)
	}

	const handleChange = (e: React.ChangeEvent<any>) => {
		const name = e.target.name
		const value = e.target.value
		updateFields({ ...fields, [name]: value })
		updateSomethingChanged(true)
	}

	const createTag = async () => {
		if (I.have('admin', props.appState.permission[props.appState.context])) {
			const req = await Request.post(
				'tag',
				{
					tagName: fields.tagName,
					tagGroup: props.tag.tagGroup,
				},
				props.appState.authState
			)
			updateNameDisabled(true)
			props.displayMessage('success', `Successfully added tag ${fields.tagName}`)
			props.updateTags(req.data.tags[0])
		} else {
			props.displayMessage('danger', 'You do not have permission to add a tag')
		}
	}

	const handleDeleteTag = async () => {
		if (I.have('admin', props.appState.permission[props.appState.context])) {
			const req = await Request.del(`tag/${props.tag.tagID}`, props.appState.authState)

			if (req.data.success) {
				props.displayMessage('success', `Successfully deleted tag ${fields.tagName}`)
				props.updateTags(props.tag, true)
			} else {
				props.displayMessage('danger', req.data.message || 'You can not delete this group at this time')
			}
		} else {
			props.displayMessage('danger', `You do not have permission to delete a group`)
		}
	}

	return (
		<Form.Group controlId="formTagName" as={Row}>
			<Col sm="7">
				<Form.Control
					type="text"
					name="tagName"
					placeholder="Tag Name"
					readOnly={isNameDisabled && props.tag.tagID !== 'new'}
					plaintext={isNameDisabled && props.tag.tagID !== 'new'}
					disabled={!I.have('admin', props.appState.permission[props.appState.context])}
					onClick={() => I.have('admin', props.appState.permission[props.appState.context]) && updateNameDisabled(false)}
					onFocus={() => I.have('admin', props.appState.permission[props.appState.context]) && updateNameDisabled(false)}
					onChange={handleChange}
					value={fields.tagName}
					className={!I.have('admin', props.appState.permission[props.appState.context]) ? 'no-hover' : ''}
					autoFocus={props.tag.tagID === 'new'}
				/>
			</Col>
			<Col sm="auto">
				{!isNameDisabled ? (
					<Button
						type="submit"
						variant="success"
						onClick={() => {
							updateNameDisabled(true)
							props.tag.tagID === 'new' ? createTag() : updateTag()
						}}
					>
						Save
					</Button>
				) : null}
			</Col>
			<Col sm="auto">
				{!isNameDisabled ? (
					<Button
						type="button"
						onClick={() => {
							updateNameDisabled(true)
							resetTag()
						}}
					>
						Cancel
					</Button>
				) : null}
			</Col>
			<Col sm="auto" style={{ textAlign: 'right', flex: '1 0 auto' }}>
				{props.tag.tagID !== 'new' ? (
					<Button type="button" onClick={handleDeleteTag}>
						Delete tag
					</Button>
				) : null}
			</Col>
		</Form.Group>
	)
}

export { TagSingle }
