import moment from 'moment'

const date = (date: string | Date): string => {
	const d = moment(date)
	if (!d.isValid()) {
		return ''
	}
	return d.format('Do MMM YYYY')
}

const time = (date: string | Date): string => {
	const d = moment(date)
	if (!d.isValid()) {
		return ''
	}
	return d.format('h:mm a')
}

const dateTime = (date: string | Date): string => {
	const d = moment(date)
	if (!d.isValid()) {
		return ''
	}
	return d.format('ddd, MMM Do YYYY, h:mm:ss a')
}

const number = (number: number, decimalPlaces: number): string => {
	if (number === 0) {
		return '0'
	} else if (number < 1 && number > -1) {
		return `${number < 0 ? '-' : ''}<1`
	}
	const absNumber = Math.abs(number)
	const sizes = ['', 'k', 'm', 'b']
	const zeroes = Math.log10(absNumber)
	const size = Math.floor(zeroes / 3)
	const num = absNumber / Math.pow(10, size * 3)
	return `${number < 0 ? '-' : ''}${num.toFixed(decimalPlaces)}${sizes[Math.abs(size)]}`
}

const roundToTwo = (num: number): number => Math.round((num + Number.EPSILON) * 100) / 100

const properCase = (str: string): string => {
	return str[0].toUpperCase() + str.slice(1)
}

// 3 letter ISO currency code
// Stripe app can override the browser language because that function doesn't work in the sandbox - we use Stripe's userContext instead
const currency = (number: number | null | undefined, currency: string | null | undefined, locale?: string) => {
	if (number === null || number === undefined) {
		return '-'
	}
	// if we don't know the currency (e.g. old invoice), just show amount
	if (currency === null || currency === undefined) {
		return number
	}

	// adds currency symbol in correct location, also handles display of decimals
	// e.g. Australian user viewing US dollars sees US$1.00, US user viewing US dollars sees $1.00
	// Australian user viewing Euros sees €1.00, Dutch user viewing Euros sees 1,00 €
	const userLanguage = locale ?? navigator.language // e.g. 'en-US'
	const formattedNumber = Intl.NumberFormat(userLanguage, { style: 'currency', currency: currency }).format(number)
	return formattedNumber
}

export { date, time, dateTime, number, properCase, currency, roundToTwo }
