import { StripeSubscription, StripeInvoiceSubSyncEvent } from '../../../../back-end/utilities/apiDefinitions'
import { SubSyncEventStripeEventType, SubSyncEventType } from './enums'

export const getStripeFeeEvent = (events: StripeInvoiceSubSyncEvent[] | undefined) =>
	events?.find((event) => event.type === SubSyncEventType.Paid && event.stripeEventType === SubSyncEventStripeEventType.InvoicePaid)

export const getSubRevenue = (stripeSubs: StripeSubscription[]) =>
	stripeSubs?.reduce((subRevenue, sub) => {
		const mrrs = sub.items.data.map((data) => {
			const price = data.price.unit_amount! / 100
			const quantity = data.quantity || 1
			if (data.price.billing_scheme === 'per_unit') {
				const interval = data.price.recurring!.interval
				const interval_count = data.price.recurring!.interval_count
				if (interval === 'day') {
					return (price / interval_count) * (365 / 12) * quantity
				} else if (interval === 'week') {
					return (price / interval_count) * (52.1429 / 12) * quantity
				} else if (interval === 'month') {
					return (price / interval_count) * quantity
				} else {
					// if (interval === 'year') {
					return (price / interval_count / 12) * quantity
				}
			} else if (data.price.billing_scheme === 'tiered' && data.price.tiers) {
				// if (data.price.billing_scheme === 'tiered') {
				if (data.price.tiers_mode === 'volume') {
					const tierAchieved = data.price.tiers.find((tier) => tier.up_to === null || quantity > tier.up_to)
					return tierAchieved!.unit_amount! * quantity
				} else {
					// if (data.price.tiers_mode === 'graduated') {
					const [total] = data.price.tiers.reduce(
						([total, quantity_used], tier) => {
							if (tier.up_to === null || quantity > tier.up_to) {
								total += tier.unit_amount! * ((tier.up_to || quantity) - quantity_used)
							} else {
								total += tier.unit_amount! * (quantity - quantity_used)
							}
							return [total, tier.up_to || quantity]
						},
						[0, 0]
					)
					return total
				}
			} else {
				// we shouldn't be here
				// could possible get here if data.price.billing_scheme === 'tiered' but data.rpice.tiers is undefined
				return 0
			}
		})
		return (subRevenue += mrrs.reduce((total, mrr) => (total += mrr), 0))
	}, 0)
