import React from 'react'
import { Badge, Button, Container, Col, Row, Table, Spinner } from 'react-bootstrap'
import { Dialog } from '../../components/ui/dialog/dialog'

import * as AppDefinitions from '../../App.d'
import * as Request from '../../utilities/request'
import * as I from '../../utilities/me'

import * as Helpers from './helpers'
import XeroConnect from '../../images/connect-white.svg'
import XeroDisconnect from '../../images/disconnect-white.svg'
import { AppContext } from '../../App'

const getAxoForProduct = (product: string) => {
	if (product === 'subsync') {
		return 'AccountXeroOrgIsSubSync==1'
	} else if (product === 'tayg') {
		return 'AccountXeroOrgIsTayg==1'
	} else if (product === 'leavecal') {
		return 'AccountXeroOrgIsLeaveCal==1'
	} else {
		return 'AccountXeroOrgIsOversight==1'
	}
}

const ScreensXeroConnections = () => {
	const { appState } = React.useContext(AppContext)

	const [consentUrl, updateConsentUrl] = React.useState<string>('')
	const [connections, updateConnections] = React.useState<Helpers.UserOrg[] | null>(null)
	const [disconnecting, updateDisconnecting] = React.useState<string[]>([])
	const [showDialog, updateShowDialog] = React.useState<string>('')

	const getData = (authState: AppDefinitions.AuthState, permission: string | null) => {
		const getUrl = async () => {
			const req = await Request.get(`xero/url/${appState.context}`, authState)
			updateConsentUrl(req.data.url)
		}

		const getConnections = async () => {
			const req = await Request.get(`organisation?where=ApiProvider==xero&where=${getAxoForProduct(appState.context)}`, authState)
			const cons = Helpers.groupConnectionsByUser(req.data.accountXeroOrgs)
			updateConnections(cons)
		}

		if (I.have('admin', permission)) {
			getConnections()
			getUrl()
		}
	}

	React.useEffect(() => {
		getData(appState.authState, appState.permission[appState.context])
	}, [appState.authState, appState.permission[appState.context]]) // eslint-disable-line

	const disconnectOrg = async (connectionID: string) => {
		await Request.del(`xero/tokens/${appState.context}/${connectionID}`, appState.authState) // TODO determine if leavecal or oversight
		updateDisconnecting(disconnecting.filter((d) => d !== connectionID))
		getData(appState.authState, appState.permission[appState.context])
	}

	if (consentUrl !== '' && connections !== null) {
		return (
			<Container className="oversight-container">
				<Row>
					<Col sm="auto">
						<h2>Xero Connections</h2>
					</Col>
					<Col></Col>
					<Col sm="auto">
						<a href={consentUrl} style={{ color: 'inherit' }}>
							<img src={XeroConnect} alt="Connect new Xero"></img>
						</a>
					</Col>
				</Row>
				<Row>
					<Col>
						<Table borderless responsive>
							<thead>
								<tr className="header-row">
									<th>User</th>
									<th>Organisations</th>
								</tr>
							</thead>
							<tbody className={'card-table-body well-spaced-table'}>
								{connections.length > 0 ? (
									connections.map((con: Helpers.UserOrg, index: number) => (
										<tr key={con.userID} className={`stripe-row ${index % 2 === 0 ? 'stripe-row-body' : ''}`}>
											<td>
												{con.firstName} {con.lastName}
											</td>
											<td>
												{con.orgs.map((org: Helpers.Org, index: number) => (
													<React.Fragment key={org.xeroOrgID}>
														{org.name}
														{org.authStatus ? (
															<Badge variant="success">OK</Badge>
														) : (
															<Badge variant="danger" className={'clickable-icon'}>
																<a href={consentUrl} style={{ color: 'inherit' }}>
																	Re-auth needed
																</a>
															</Badge>
														)}
														{disconnecting.includes(org.connectionID) ? (
															<Spinner animation={'border'} size={'sm'} style={{ marginLeft: '5px' }} />
														) : (
															<Button variant="secondary" onClick={() => updateShowDialog(org.connectionID)}>
																<img src={XeroDisconnect} alt="Disconnect from Xero"></img>
															</Button>
														)}
														{index < con.orgs.length - 1 ? <br /> : null}
													</React.Fragment>
												))}
											</td>
										</tr>
									))
								) : (
									<tr key={'no connections'} className={`stripe-row stripe-row-body`}>
										<td>No Xero Connections</td>
										<td />
									</tr>
								)}
							</tbody>
						</Table>
					</Col>
				</Row>
				{showDialog ? (
					<Dialog
						show={true}
						handleClose={() => updateShowDialog('')}
						handleConfirm={() => {
							updateDisconnecting([...disconnecting, showDialog])
							disconnectOrg(showDialog)
						}}
						titleText={'Disconnect Xero Organisation'}
						bodyText={'Are you sure you want to disconnect this organisation?'}
					/>
				) : null}
			</Container>
		)
	} else if (!I.have('admin', appState.permission[appState.context])) {
		return (
			<Container className="oversight-container">
				<Row>
					<Col></Col>
					<Col xs="auto">
						<p>You do not have permission to view this page.</p>
					</Col>
					<Col></Col>
				</Row>
			</Container>
		)
	} else {
		return <div></div>
	}
}

export default ScreensXeroConnections
