import React from 'react'
import * as AppDefinitions from '../../App.d'
import {
	AccountXeroOrg,
	Calendar,
	Calendar as CalendarData,
	Employee,
	EmployeeLeaveBalance,
	LeaveApplication,
	LeaveType,
} from '../../../../back-end/utilities/apiDefinitions'
import { FormGroup, Row, Col, FormLabel } from 'react-bootstrap'
import { PieChart } from 'react-minimal-pie-chart'
import Plot from 'react-plotly.js'

// components
import * as Messages from '../../components/ui/messages/messages'
import LoadingImage from '../../images/Finlert-loading-GIF_100px.gif'

// utillites
import * as Request from '../../utilities/request'
import moment from 'moment'
import { TimePeriodPicker } from '../oversight/timePeriodPicker'

const pieColors = ['#86A581', '#E4B1F7', '#F89995', '#F8C950', '#8AA6FF']

const getTimezone = (version: string) => {
	return version === 'NZ' || version === 'NZONRAMP' ? 'Pacific/Auckland' : version === 'UK' || version === 'UKONRAMP' ? 'Europe/London' : 'Australia/Sydney'
}

const calculateUpcomingLeave = (totalFutureLeave: number, leaveApp: LeaveApplication) =>
	(totalFutureLeave += Number((new Date(leaveApp.endDate!).getTime() + 1000 * 3600 * 24 - new Date(leaveApp.startDate!).getTime()) / (1000 * 3600 * 24)) || 1) //we add a day in the difference so the end date is included

const getCalendarsWithBalances = (
	calendar: Calendar,
	leave: LeaveApplication[],
	leaveBalance: EmployeeLeaveBalance[],
	version: string,
	start?: Date,
	end?: Date
) => {
	const todaysDate = new Date(moment().tz(getTimezone(version)).format('YYYY-MM-DD'))

	return {
		...calendar,
		employees: calendar.employees
			? calendar.employees
					.map((emp) => {
						const employeeLeave = leave.filter((leaveApp) => emp.employeeID === leaveApp.employeeID)
						const fromTodayOnwardsLeaves =
							start && end
								? employeeLeave.filter((leaveApp) => new Date(leaveApp.startDate!) >= start && new Date(leaveApp.startDate!) <= end)
								: employeeLeave.filter((leaveApp) => new Date(leaveApp.startDate!) > todaysDate)
						const associatedBalances = leaveBalance.filter((leaveBalance) => emp.employeeID === leaveBalance.employeeID)

						return {
							...emp,
							daysSinceLastLeave: 0, //not important here
							leaveBalance: associatedBalances
								.map((bal) => {
									const totalFutureLeave = fromTodayOnwardsLeaves.filter((leaveApp) => leaveApp.xeroOrgLeaveTypeID === bal.xeroOrgLeaveTypeID)
									return {
										...bal,
										corespondingLeaves: totalFutureLeave,
										upcoming: totalFutureLeave.reduce(calculateUpcomingLeave, 0),
									}
								})
								.sort((a, b) => a.leaveTypeName!.localeCompare(b.leaveTypeName!)),
						}
					})
					.filter((emp) => emp.leaveBalance.length > 0)
					.sort((a, b) => (a.firstName === b.firstName ? a.lastName!.localeCompare(b.lastName!) : a.firstName!.localeCompare(b.firstName!)))
			: [],
	}
}

const getAge = (dob: string) => {
	const age = moment().diff(dob, 'years')
	return age
}
interface OverviewComponentFrameProps {
	show: boolean
	appState: AppDefinitions.AppState
	currentOrg: AccountXeroOrg
	currentCalendar: CalendarData
	pageStatus: string
	updatePageStatus: React.Dispatch<React.SetStateAction<string>>
}

const initialDateRange = {
	startDate: moment().subtract(30, 'days'),
	endDate: moment(),
}

const OverviewComponent = (props: OverviewComponentFrameProps) => {
	const [period, updatePeriod] = React.useState<{
		startDate: moment.Moment | null
		endDate: moment.Moment | null
	}>(initialDateRange)
	const [messages, updateMessages] = Messages.useMessageReducer([])
	const [allLeaveApplications, updateAllLeaveApplications] = React.useState<LeaveApplication[] | []>([])
	const [originalBalanceData, updateOriginalBalanceData] = React.useState<EmployeeLeaveBalance[]>([])

	React.useEffect(
		() => {
			const fetchData = async () => {
				const uniqueEmployees = props.currentCalendar.employees!.map((e) => e.employeeID!)
				const leaveTypeIDs = props.currentCalendar.leaveTypes!.map((clt) => clt.xeroOrgLeaveTypeID)
				const [nonXeroLeaveTypes, calendarLeaveTypesData] = await Promise.all([
					Request.get(
						`leavetype?where=XeroOrgID==${props.currentOrg.xeroOrg!.xeroOrgID}&where=XeroOrgLeaveTypeIsNonXero==1`,
						props.appState.authState
					),
					Request.get(`calendar/${props.currentCalendar.calendarID}/leavetype`, props.appState.authState),
				])

				const nonXeroTypeIDs = nonXeroLeaveTypes.data.leaveTypes
					.filter(
						(lt: LeaveType) =>
							lt.isActive &&
							lt.xeroOrgID === props.currentOrg.xeroOrg!.xeroOrgID &&
							calendarLeaveTypesData.data.calendarLeaveTypes.some(
								(clt: { xeroOrgLeaveTypeID: string }) => clt.xeroOrgLeaveTypeID === lt.xeroOrgLeaveTypeID
							)
					)
					.map((clt: { xeroOrgLeaveTypeID: string }) => clt.xeroOrgLeaveTypeID)
				const [leaveApp, leaveBalanceData] = await Promise.all([
					Request.get(
						`leave?where=EmployeeID=in(${uniqueEmployees.join(',')})&where=XeroOrgLeaveTypeID=in(${leaveTypeIDs
							.concat(nonXeroTypeIDs)
							.join(',')}&minify`,
						props.appState.authState
					),
					Request.get(`leavebalance?where=EmployeeID=in(${uniqueEmployees.join(',')})`, props.appState.authState),
				])

				const activeEmployees = props.currentCalendar.employees!.filter((e) => e.isActive).map((e) => e.employeeID!)
				const leaves =
					leaveApp.data.leaveApplications.length > 0
						? leaveApp.data.leaveApplications
								.filter((lv: LeaveApplication) => activeEmployees.includes(lv.employeeID!))
								.map((leave: LeaveApplication) => leave)
						: []
				updateAllLeaveApplications(leaves)
				updateOriginalBalanceData(leaveBalanceData.data.employeeLeaveBalances)
				if (props.show) {
					props.updatePageStatus('Finished')
				}
			}

			if (props.currentCalendar.calendarID !== '') {
				fetchData()
			}
		},
		// eslint-disable-next-line
		[props.appState.authState, props.currentCalendar.calendarID, props.currentOrg]
	)

	//** Calculative functions */
	const returnEmployeePropertyValueCount = (employees: Employee[], compare: (employee: Employee) => boolean) =>
		employees.filter((e) => e.isActive && compare(e)).length
	const getGenders = () => {
		const genders = {
			Male: returnEmployeePropertyValueCount(props.currentCalendar.employees!, (e: Employee) => e.gender === 'M'),
			Female: returnEmployeePropertyValueCount(props.currentCalendar.employees!, (e: Employee) => e.gender === 'F'),
			'Not Stated': returnEmployeePropertyValueCount(props.currentCalendar.employees!, (e: Employee) => e.gender === 'N'),
			'Non Binary': returnEmployeePropertyValueCount(props.currentCalendar.employees!, (e: Employee) => e.gender === 'I'),
		}

		return genders
	}

	const getAges = () => {
		const ageGroups = {
			'<18': returnEmployeePropertyValueCount(props.currentCalendar.employees!, (e: Employee) => getAge(e.dateOfBirth!) < 18),
			'18-30': returnEmployeePropertyValueCount(
				props.currentCalendar.employees!,
				(e: Employee) => getAge(e.dateOfBirth!) >= 18 && getAge(e.dateOfBirth!) < 31
			),
			'31-45': returnEmployeePropertyValueCount(
				props.currentCalendar.employees!,
				(e: Employee) => getAge(e.dateOfBirth!) >= 31 && getAge(e.dateOfBirth!) < 46
			),
			'46-55': returnEmployeePropertyValueCount(
				props.currentCalendar.employees!,
				(e: Employee) => getAge(e.dateOfBirth!) >= 46 && getAge(e.dateOfBirth!) < 56
			),
			'>55': returnEmployeePropertyValueCount(props.currentCalendar.employees!, (e: Employee) => getAge(e.dateOfBirth!) >= 56),
		}

		return ageGroups
	}

	const getTenures = () => {
		const tenureYears = {
			'<1': returnEmployeePropertyValueCount(props.currentCalendar.employees!, (e: Employee) => getAge(e.startDate!) < 1),
			'1-5': returnEmployeePropertyValueCount(props.currentCalendar.employees!, (e: Employee) => getAge(e.startDate!) >= 1 && getAge(e.startDate!) < 6),
			'6-10': returnEmployeePropertyValueCount(props.currentCalendar.employees!, (e: Employee) => getAge(e.startDate!) >= 6 && getAge(e.startDate!) < 11),
			'11-20': returnEmployeePropertyValueCount(
				props.currentCalendar.employees!,
				(e: Employee) => getAge(e.startDate!) >= 11 && getAge(e.startDate!) < 21
			),
			'>20': returnEmployeePropertyValueCount(props.currentCalendar.employees!, (e: Employee) => getAge(e.startDate!) >= 21),
		}

		return tenureYears
	}

	// const getEmployeesByBasis = () => {
	// 	// out for now
	// }

	const getEmployeesByGroup = () => {
		return props.currentCalendar
			.employees!.filter((e) => e.isActive)
			.reduce((result, currentValue: Employee) => {
				result[currentValue.groupName! || 'None'] = result[currentValue.groupName! || 'None'] + 1 || 1
				return result
			}, {} as { [groupName: string]: number })
	}

	// this function should be refactored
	const groupDaysByLeaveType = () => {
		const leaveTypeObjects = props.currentCalendar.leaveTypes!.reduce((result, currentValue: LeaveType) => {
			result[currentValue.xeroOrgLeaveTypeID!] = {
				name: currentValue.name!,
				days: 0,
			}
			return result
		}, {} as { [xeroOrgLeaveTypeID: string]: { name: string; days: number } | number })

		const balances = getCalendarsWithBalances(props.currentCalendar, allLeaveApplications, originalBalanceData, props.currentOrg!.xeroOrg!.version!)

		balances.employees
			.filter((e) => e.isActive)
			.map((emp) => emp.leaveBalance)
			.flat()
			.forEach((lb) => {
				if (leaveTypeObjects[lb.xeroOrgLeaveTypeID!]) {
					leaveTypeObjects[lb.xeroOrgLeaveTypeID!] = {
						...(leaveTypeObjects[lb.xeroOrgLeaveTypeID!] as { name: string; days: number }),
						days: (leaveTypeObjects[lb.xeroOrgLeaveTypeID!] as { name: string; days: number }).days! + lb.value!,
					}
				}
			})
		Object.keys(leaveTypeObjects).forEach((key: string) => {
			if ((leaveTypeObjects[key] as { name: string; days: number }).days) {
				leaveTypeObjects['total'] =
					((leaveTypeObjects['total'] as number) || 0) + Number((leaveTypeObjects[key] as { name: string; days: number }).days)
			}
		})
		return leaveTypeObjects
	}

	// this function should be refactored
	const daysOff = (start: Date, end: Date) => {
		const employeesGroup = props.currentCalendar
			.employees!.filter((e) => e.isActive)
			.reduce((result, currentValue: Employee) => {
				result[currentValue.employeeID!] = {
					name: `${currentValue.alias ? currentValue.alias : `${currentValue.firstName} ${currentValue.lastName}`}`,
					daysOff: 0,
				}
				return result
			}, {} as { [employeeID: string]: { name: string; daysOff: number } })

		const filteredBalances = getCalendarsWithBalances(
			props.currentCalendar,
			allLeaveApplications,
			originalBalanceData,
			props.currentOrg!.xeroOrg!.version!,
			start,
			end
		)

		filteredBalances.employees
			.map((emp) => emp.leaveBalance)
			.flat()
			.forEach((lb) => {
				if (employeesGroup[lb.employee!.employeeID!]) {
					employeesGroup[lb.employee!.employeeID!] = {
						...employeesGroup[lb.employee!.employeeID!],
						daysOff: employeesGroup[lb.employee!.employeeID!].daysOff! + lb.upcoming,
					}
				}
			})

		return employeesGroup
	}

	const totalDaysOff = (daysOff: { [employeeID: string]: { name: string; daysOff: number } }) => {
		let totalDays = 0
		Object.keys(daysOff).forEach((emp) => (totalDays = totalDays + daysOff[emp].daysOff))
		return totalDays
	}

	const employeeWithMostDaysOff = (daysOff: { [employeeID: string]: { name: string; daysOff: number } }) => {
		let maxDays = {
			name: '',
			daysOff: 0,
		}

		Object.keys(daysOff).forEach((emp) => {
			if (daysOff[emp].daysOff > maxDays.daysOff) {
				maxDays = daysOff[emp]
			}
		})

		return maxDays
	}

	// this function should be refactored
	const employeeWithMostRequests = (start: Date, end: Date) => {
		const employeesGroup = props.currentCalendar
			.employees!.filter((e) => e.isActive)
			.reduce((result, currentValue: Employee) => {
				result[currentValue.employeeID!] = {
					name: `${currentValue.alias ? currentValue.alias : `${currentValue.firstName} ${currentValue.lastName}`}`,
					requests: 0,
				}
				return result
			}, {} as { [employeeID: string]: { name: string; requests: number } })

		const filteredBalances = getCalendarsWithBalances(
			props.currentCalendar,
			allLeaveApplications,
			originalBalanceData,
			props.currentOrg!.xeroOrg!.version!,
			start,
			end
		)
		filteredBalances.employees
			.map((emp) => emp.leaveBalance)
			.flat()
			.forEach((lb) => {
				if (employeesGroup[lb.employee!.employeeID!]) {
					employeesGroup[lb.employee!.employeeID!] = {
						...employeesGroup[lb.employee!.employeeID!],
						requests: employeesGroup[lb.employee!.employeeID!].requests + lb.corespondingLeaves.length,
					}
				}
			})

		let maxRequestss = {
			name: '',
			requests: 0,
		}

		Object.keys(employeesGroup).forEach((emp) => {
			if (employeesGroup[emp].requests > maxRequestss.requests) {
				maxRequestss = employeesGroup[emp]
			}
		})

		return maxRequestss
	}

	// const mostCommonDayForSickLeave = (start: Date, end: Date) => {
	// 	// out for now
	// }

	// const averageLeadtime = (start: Date, end: Date) => {
	// 	// out for now
	// }
	const convertNumberToDay = (day: number) => {
		switch (day) {
			case 0:
				return 'Sunday'
			case 1:
				return 'Monday'
			case 2:
				return 'Tuesday'
			case 3:
				return 'Wednesday'
			case 4:
				return 'Thursday'
			case 5:
				return 'Friday'
			case 6:
				return 'Saturday'
			default:
				return `Day ${day}`
		}
	}

	const convertNumberToMonth = (month: number) => {
		switch (month) {
			case 0:
				return 'January'
			case 1:
				return 'February'
			case 2:
				return 'March'
			case 3:
				return 'April'
			case 4:
				return 'May'
			case 5:
				return 'June'
			case 6:
				return 'July'
			case 7:
				return 'August'
			case 8:
				return 'September'
			case 9:
				return 'October'
			case 10:
				return 'November'
			case 11:
				return 'December'
			default:
				return `Month ${month}`
		}
	}

	// this function should be refactored
	const groupLeaveBetweenPeriod = (start: Date, end: Date) => {
		const filteredCals = getCalendarsWithBalances(
			props.currentCalendar,
			allLeaveApplications,
			originalBalanceData,
			props.currentOrg!.xeroOrg!.version!,
			start,
			end
		)
		const allLeaves = filteredCals.employees
			.map((emp) => emp.leaveBalance)
			.flat()
			.map((lb) => lb.corespondingLeaves)
			.flat()
		const nrDays = Number((end.getTime() + 1000 * 3600 * 24 - start.getTime()) / (1000 * 3600 * 24))
		const periodBreakDown = nrDays <= 7 ? 'days' : nrDays <= 30 ? 'weeks' : nrDays <= 365 ? 'months' : 'years'
		let i = 0
		if (periodBreakDown === 'days') {
			const daysWithLeaves: { [day: string]: number } = {}
			while (i < nrDays) {
				const currentDay = new Date(start)
				currentDay.setDate(start.getDate() + i)
				daysWithLeaves[convertNumberToDay(currentDay.getDay())] = 0
				allLeaves.forEach((l) => {
					if (new Date(l.startDate!).getDay() === currentDay.getDay() && currentDay.getDay() <= new Date(l.endDate!).getDay()) {
						daysWithLeaves[convertNumberToDay(currentDay.getDay())] = daysWithLeaves[convertNumberToDay(currentDay.getDay())] + 1
					}
				})
				i = i + 1
			}

			return daysWithLeaves
		} else if (periodBreakDown === 'weeks') {
			const weeksWithLeaves: { [week: string]: number } = {}
			while (i <= nrDays) {
				const currentDay = new Date(start)
				currentDay.setDate(start.getDate() + i)
				const weekName = `Week ${i / 7 + 1}`
				weeksWithLeaves[weekName] = 0
				allLeaves.forEach((l) => {
					if (new Date(l.startDate!).getTime() <= currentDay.getTime() && new Date(l.endDate!).getTime() <= currentDay.getTime()) {
						weeksWithLeaves[weekName] = weeksWithLeaves[weekName] + 1
					}
				})
				i = i + 7
			}

			return weeksWithLeaves
		} else if (periodBreakDown === 'months') {
			const monthsWithLeaves: { [month: string]: number } = {}
			while (i <= nrDays) {
				const currentDay = new Date(start)
				currentDay.setDate(start.getDate() + i)
				monthsWithLeaves[convertNumberToMonth(currentDay.getMonth())] = 0
				allLeaves.forEach((l) => {
					if (new Date(l.startDate!).getMonth() === currentDay.getMonth() && currentDay.getMonth() <= new Date(l.endDate!).getMonth()) {
						monthsWithLeaves[convertNumberToMonth(currentDay.getMonth())] = monthsWithLeaves[convertNumberToMonth(currentDay.getMonth())] + 1
					}
				})
				i = i + 28
			}
			return monthsWithLeaves
		} else {
			//years
			const yearsWithLeaves: { [year: string]: number } = {}
			while (i <= nrDays) {
				const currentDay = new Date(start)
				currentDay.setDate(start.getDate() + i)
				yearsWithLeaves[currentDay.getFullYear()] = 0
				allLeaves.forEach((l) => {
					if (new Date(l.startDate!).getFullYear() === currentDay.getFullYear() && currentDay.getFullYear() <= new Date(l.endDate!).getFullYear()) {
						yearsWithLeaves[currentDay.getFullYear()] = yearsWithLeaves[currentDay.getFullYear()] + 1
					}
				})

				i = i + 365
			}

			return yearsWithLeaves
		}
	}

	// this function should be refactored
	const getLeaveTypesTaken = (start: Date, end: Date) => {
		const leaveTypes = props.currentCalendar
			.leaveTypes!.sort((a, b) => a.name!.localeCompare(b.name!))
			.reduce((result, currentValue: LeaveType) => {
				result[currentValue.xeroOrgLeaveTypeID!] = {
					name: currentValue.name!,
					amount: 0,
				}
				return result
			}, {} as { [xeroOrgLeaveTypeID: string]: { name: string; amount: number } })

		const filteredBalances = getCalendarsWithBalances(
			props.currentCalendar,
			allLeaveApplications,
			originalBalanceData,
			props.currentOrg!.xeroOrg!.version!,
			start,
			end
		)

		filteredBalances.employees
			.map((emp) => emp.leaveBalance)
			.flat()
			.map((lb) => lb.corespondingLeaves)
			.flat()
			.forEach((l) => {
				if (leaveTypes[l.xeroOrgLeaveTypeID!]) {
					leaveTypes[l.xeroOrgLeaveTypeID!].amount = leaveTypes[l.xeroOrgLeaveTypeID!].amount + 1 || 0
				}
			})

		return leaveTypes
	}
	// eslint-disable-next-line
	const getDataForPieChart = (object: { [x: string]: any }, colorIndex: number) => {
		const data: { title: string; value: number; color: string }[] = []
		Object.keys(object).forEach((key: string, index: number) => {
			if (object[key] !== 0) {
				data.push({
					title: key,
					value: object[key],
					color: pieColors[colorIndex] + 15 * (index + 1),
				})
			}
		})
		return data
	}

	// eslint-disable-next-line
	const getDataForBarChart = (object: any, colorIndex: number, showLeaveInDays: boolean, hoursInDay: number) => {
		// eslint-disable-next-line
		const data: any = {
			type: 'bar',
			marker: { color: pieColors[colorIndex] },
			x: [],
			y: [],
			orientation: 'h',
			options: {
				scales: {
					y: {
						beginAtZero: true,
					},
					x: {
						beginAtZero: true,
					},
				},
			},
		}
		Object.keys(object).forEach((key: string) => {
			if (key === 'total') {
				data.y.push('Total')
				data.x.push(showLeaveInDays ? parseFloat(Math.fround(object[key] / hoursInDay).toFixed(2)) : object[key])
			} else {
				if (object[key].name) {
					data.y.push(object[key].name)
					if (object[key].amount) {
						data.x.push(object[key].amount)
					} else {
						data.x.push(showLeaveInDays ? parseFloat(Math.fround(object[key].days / hoursInDay).toFixed(2)) : object[key].days)
					}
				} else {
					data.y.push(key)
					data.x.push(object[key])
				}
			}
		})
		return { ...data, x: data.x.reverse(), y: data.y.reverse() }
	}

	if (props.show) {
		if (props.pageStatus === 'Finished') {
			return (
				<>
					<FormGroup style={{ padding: '30px' }}>
						<Row className="calendar-alerts-card ">
							<Col>
								<Row style={{ paddingLeft: '20px' }}>
									<FormLabel className="emails-label" style={{ paddingTop: '20px' }}>
										Team Demographics
									</FormLabel>
								</Row>

								<Row
									style={{
										marginTop: '-5px',
										paddingLeft: '20px',
										backgroundColor: 'white',
										paddingTop: '20px',
									}}
								>
									<Col style={{ textAlign: 'center' }}>
										<PieChart
											data={getDataForPieChart(getGenders(), 0)}
											style={{ height: '230px' }}
											label={({ dataEntry }) => `${dataEntry.title!} (${dataEntry.value!})`}
											labelStyle={{
												fontSize: '5px',
											}}
											labelPosition={60}
											animate
										/>
										<FormLabel style={{ paddingTop: '20px', fontWeight: 'bold' }}>Gender</FormLabel>
									</Col>
									<Col style={{ textAlign: 'center' }}>
										<PieChart
											data={getDataForPieChart(getAges(), 1)}
											style={{ height: '230px' }}
											label={({ dataEntry }) => `${dataEntry.title!} (${dataEntry.value!})`}
											labelStyle={{
												fontSize: '5px',
											}}
											labelPosition={60}
											animate
										/>
										<FormLabel style={{ paddingTop: '20px', fontWeight: 'bold' }}>Age</FormLabel>
									</Col>
									<Col style={{ textAlign: 'center' }}>
										<PieChart
											data={getDataForPieChart(getTenures(), 2)}
											style={{ height: '230px' }}
											label={({ dataEntry }) => `${dataEntry.title!} (${dataEntry.value!})`}
											labelStyle={{
												fontSize: '5px',
											}}
											labelPosition={60}
											animate
										/>
										<FormLabel style={{ paddingTop: '20px', fontWeight: 'bold' }}>Tenure</FormLabel>
									</Col>
									<Col style={{ textAlign: 'center' }}>
										<PieChart
											data={getDataForPieChart(getEmployeesByGroup(), 3)}
											style={{ height: '230px' }}
											label={({ dataEntry }) => `${dataEntry.title!} (${dataEntry.value!})`}
											labelStyle={{
												fontSize: '5px',
											}}
											labelPosition={60}
											animate
										/>
										<FormLabel style={{ paddingTop: '20px', fontWeight: 'bold' }}>Employee Group</FormLabel>
									</Col>
								</Row>
								<Row
									style={{
										marginTop: '-5px',
										paddingLeft: '20px',
										backgroundColor: 'white',
										paddingTop: '20px',
									}}
								>
									<Col style={{ textAlign: 'center' }}>
										<Plot
											data={[
												getDataForBarChart(
													groupDaysByLeaveType(),
													4,
													props.currentOrg.xeroOrg!.showLeaveInDays!,
													props.currentOrg.xeroOrg!.hoursInDay!
												),
											]}
											layout={{
												title: '',
												yaxis: {
													automargin: true,
													type: 'category',
													ticksuffix: '  ',
												},
												xaxis: { automargin: true },
												hovermode: 'closest',
											}}
											config={{
												displayModeBar: false,
											}}
										/>
										<Col sm="5"></Col>
										<FormLabel style={{ paddingTop: '10px', fontWeight: 'bold' }}>
											{props.currentOrg.xeroOrg && props.currentOrg.xeroOrg.showLeaveInDays
												? 'Days currently owed to employees'
												: 'Amount currently owed to employees'}
										</FormLabel>
									</Col>
								</Row>
							</Col>
						</Row>

						<Row className="calendar-alerts-card ">
							<Col>
								<Row style={{ paddingLeft: '20px' }}>
									<Col xs={'auto'} sm={2} md={2} lg={'auto'} className={'emails-label'}>
										Leave Overview
									</Col>
									<TimePeriodPicker dates={period} id={'period'} noPeriod={true} handleDatesChange={(dates) => updatePeriod(dates)} />
								</Row>
								{period.startDate && period.endDate ? (
									<div
										style={{
											marginTop: '5px',
											paddingLeft: '20px',
											backgroundColor: 'white',
											paddingTop: '20px',
										}}
									>
										{totalDaysOff(daysOff(period.startDate!.toDate(), period.endDate!.toDate())) +
											employeeWithMostDaysOff(daysOff(period.startDate!.toDate(), period.endDate!.toDate())).daysOff +
											employeeWithMostRequests(period.startDate!.toDate(), period.endDate!.toDate()).requests >
										0 ? (
											<>
												<FormGroup>
													<Row>
														<p>{`There were ${totalDaysOff(
															daysOff(period.startDate!.toDate(), period.endDate!.toDate())
														)} day(s) taken off in this period.`}</p>
													</Row>
													<Row>
														<p>{`${
															employeeWithMostDaysOff(daysOff(period.startDate!.toDate(), period.endDate!.toDate())).name
														} took more leave than anyone with ${
															employeeWithMostDaysOff(daysOff(period.startDate!.toDate(), period.endDate!.toDate())).daysOff
														} day(s).`}</p>
													</Row>
													<Row>
														<p>{`${
															employeeWithMostRequests(period.startDate!.toDate(), period.endDate!.toDate()).name
														} had more requests approved than anyone with ${
															employeeWithMostRequests(period.startDate!.toDate(), period.endDate!.toDate()).requests
														} request(s).`}</p>
													</Row>
												</FormGroup>
												<FormGroup>
													<Row>
														<Col style={{ textAlign: 'center', marginTop: 'auto' }}>
															<PieChart
																data={getDataForPieChart(
																	groupLeaveBetweenPeriod(period.startDate!.toDate(), period.endDate!.toDate()),
																	4
																)}
																style={{
																	height: '300px',
																	marginBottom: '100px',
																}}
																label={({ dataEntry }) => `${dataEntry.title!} (${dataEntry.value!})`}
																labelStyle={{
																	fontSize: '5px',
																}}
																labelPosition={60}
																animate
															/>
															<FormLabel
																style={{
																	paddingTop: '10px',
																	fontWeight: 'bold',
																}}
															>{`Leave by ${
																Number(
																	(period.endDate.toDate().getTime() +
																		1000 * 3600 * 24 -
																		period.startDate.toDate().getTime()) /
																		(1000 * 3600 * 24)
																) <= 7
																	? 'Day'
																	: Number(
																			(period.endDate.toDate().getTime() +
																				1000 * 3600 * 24 -
																				period.startDate.toDate().getTime()) /
																				(1000 * 3600 * 24)
																	  ) <= 30
																	? 'Week'
																	: Number(
																			(period.endDate.toDate().getTime() +
																				1000 * 3600 * 24 -
																				period.startDate.toDate().getTime()) /
																				(1000 * 3600 * 24)
																	  ) <= 365
																	? 'Month'
																	: 'Year'
															}`}</FormLabel>
														</Col>
														<Col style={{ textAlign: 'center' }}>
															<Plot
																style={{}}
																data={[
																	getDataForBarChart(
																		getLeaveTypesTaken(period.startDate!.toDate(), period.endDate!.toDate()),
																		4,
																		props.currentOrg.xeroOrg!.showLeaveInDays!,
																		props.currentOrg.xeroOrg!.hoursInDay!
																	),
																]}
																layout={{
																	title: '',
																	yaxis: {
																		automargin: true,
																		type: 'category',
																		ticksuffix: '  ',
																	},
																	xaxis: {
																		automargin: true,
																		rangemode: 'nonnegative',
																	},
																	hovermode: 'closest',
																	// width: 500,
																	height: 500,
																}}
																config={{
																	displayModeBar: false,
																	responsive: true,
																}}
															/>
															<Col sm="3"></Col>
															<FormLabel
																style={{
																	paddingTop: '10px',
																	fontWeight: 'bold',
																}}
															>{`Leave by Type`}</FormLabel>
														</Col>
													</Row>
												</FormGroup>
											</>
										) : (
											<Row
												style={{
													marginTop: '5px',
													paddingLeft: '20px',
													backgroundColor: 'white',
													paddingTop: '20px',
													paddingBottom: '50px',
												}}
											>
												<p style={{ fontWeight: 'lighter' }}>There are no leaves taken during this period.</p>
											</Row>
										)}
									</div>
								) : (
									<Row
										style={{
											marginTop: '5px',
											paddingLeft: '20px',
											backgroundColor: 'white',
											paddingTop: '20px',
											paddingBottom: '50px',
										}}
									>
										<p style={{ fontWeight: 'lighter' }}>Select a date range in orfer to display a summary for that period.</p>
									</Row>
								)}
							</Col>
						</Row>
					</FormGroup>
					<Messages.Messages messages={messages} updateMessage={updateMessages} />
				</>
			)
		} else {
			return (
				<div className="loading-gif">
					<img src={LoadingImage} alt="Loading ..." />
				</div>
			)
		}
	} else {
		return null
	}
}

export default OverviewComponent
