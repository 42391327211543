import React from 'react'
import { ReactComponent as OversightLogo } from '../../images/oversight_finlert-1.svg'
import { ReactComponent as LeaveCalLogo } from '../../images/leavecal_finlert-1.svg'
import { ReactComponent as TAYGLogo } from '../../images/taxasyougo_finlert-1.svg'
import { ReactComponent as SubSyncLogo } from '../../images/subSyncIcons/subSync_finlert-1.svg'

const Logo = (props: { type: string }) => {
	if (props.type === 'leavecal') {
		return <LeaveCalLogo height="48" style={{ cursor: 'pointer' }} />
	} else if (props.type === 'tayg') {
		return <TAYGLogo height="48" style={{ cursor: 'pointer' }} />
	} else if (props.type === 'subsync') {
		return <SubSyncLogo height="48" style={{ cursor: 'pointer' }} />
	} else {
		return <OversightLogo height="48" style={{ cursor: 'pointer' }} />
	}
}

export default Logo
