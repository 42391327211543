import React from 'react'
import { Pagination as RPagination } from 'react-bootstrap'

interface FiltersState {
	page: number
	pageSize: number
}

const storePagingPreferences = (pageSize: number) => {
	if (typeof Storage !== 'undefined') {
		window.localStorage.setItem('pageSize', String(pageSize))
	}
}

const getPagingPreferences = (defaultPageSize?: number): number => {
	if (typeof Storage !== 'undefined') {
		return Number(window.localStorage.getItem('pageSize') || defaultPageSize || 10)
	}

	return defaultPageSize || 10
}

const pageFilter = (filters: FiltersState, index: number) => index < filters.page * filters.pageSize && index >= (filters.page - 1) * filters.pageSize

const calculatePageStart = (filters: FiltersState) => {
	if (filters.page === 1) {
		return filters.page
	}
	return filters.page * filters.pageSize - filters.pageSize + 1
}

const calculatePageTo = (filters: FiltersState, unFilteredRecordsLength: number) => {
	if (filters.page * filters.pageSize < unFilteredRecordsLength) {
		return filters.page * filters.pageSize
	}
	return unFilteredRecordsLength
}
interface PaginationProps {
	filters: FiltersState
	setFilterDispatch: React.Dispatch<React.SetStateAction<FiltersState>>
	allPaginatedRecordsLength: number
	onLoadMoreClick?: () => void
}

const Pagination = (props: PaginationProps) => {
	const numPages = Math.ceil(props.allPaginatedRecordsLength / props.filters.pageSize)

	return (
		<RPagination style={{ marginBottom: '0px' }}>
			<RPagination.First disabled={!(props.filters.page > 1 && numPages > 2)} onClick={() => props.setFilterDispatch({ ...props.filters, page: 1 })} />

			<RPagination.Prev
				disabled={!(props.filters.page > 1)}
				onClick={() =>
					props.setFilterDispatch({
						...props.filters,
						page: props.filters.page - 1,
					})
				}
			/>

			{props.filters.page > 6 ? <RPagination.Ellipsis /> : null}

			{props.allPaginatedRecordsLength > 0
				? Array.from({ length: numPages }).map((_value: unknown, index: number) =>
						index >= props.filters.page - 6 && index <= props.filters.page + 4 ? (
							<RPagination.Item
								key={index}
								active={props.filters.page === index + 1}
								activeLabel=""
								onClick={() =>
									props.setFilterDispatch({
										...props.filters,
										page: index + 1,
									})
								}
							>
								{index + 1}
							</RPagination.Item>
						) : null
				  )
				: null}

			{props.filters.page <= numPages - 6 ? <RPagination.Ellipsis /> : null}

			{props.filters.page === numPages && props.onLoadMoreClick ? (
				<RPagination.Item activeLabel="" onClick={() => props.onLoadMoreClick!()}>
					Load More
				</RPagination.Item>
			) : null}

			<RPagination.Next
				disabled={!(props.filters.page < numPages)}
				onClick={() =>
					props.setFilterDispatch({
						...props.filters,
						page: props.filters.page + 1,
					})
				}
			/>

			<RPagination.Last
				disabled={!(props.filters.page < numPages && numPages > 2)}
				onClick={() => props.setFilterDispatch({ ...props.filters, page: numPages })}
			/>
		</RPagination>
	)
}

export { Pagination, calculatePageTo, calculatePageStart, getPagingPreferences, pageFilter, storePagingPreferences }
export type { FiltersState }
