import React from 'react'
import { View } from '@aws-amplify/ui-react'
import { Navigate, useSearchParams } from 'react-router-dom'

const Login = () => {
	const [searchParams] = useSearchParams()

	const redirect = searchParams.get('redirect')

	if (redirect) {
		return <Navigate to={redirect} />
	}
	return <Navigate to="/" />
}

export default Login
