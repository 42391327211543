import React, { Dispatch, SetStateAction } from 'react'
import { Table, Row, Col, Collapse, Badge } from 'react-bootstrap'

// components
import MetricValue from '../../components/metric/value'
import { CollapseChevron } from '../../components/ui/collapseChevron/collapseChevron'
import SettingsBar, { UpdateData, Units } from '../../components/oversight/settingsBar'
import { AlertCircle } from 'react-feather'

// definitions
import { AccountXeroOrg, Metric, MetricGroup, UserAccountSettings } from '../../../../back-end/utilities/apiDefinitions'
import { AppState } from '../../App.d'
import { DataSetMetricGroupedByXeroOrg, ModalState, TagCollapse, XeroOrgsGroupedByTag } from '../../screens/oversight/oversight.d'

// utilities
import { renderIcon } from '../../utilities/icon'
import SortingButton from './sortingButton'

export interface DashboardTableProps {
	metricGroups: MetricGroup[]
	groups: XeroOrgsGroupedByTag[] | null // change to tag groups eventually
	groupsWithErrors?: XeroOrgsGroupedByTag[] | null
	orgs: AccountXeroOrg[]
	metricSettings: UserAccountSettings[]
	appState: AppState
	showSettingsBar: boolean
	isTagsCollapsed: TagCollapse
	tab: 'live' | 'pit'
	isComparing: boolean
	isLoading: boolean
	fetchedTs?: string
	errorMessage?: string
	updateIsFetching: (isFetching: boolean) => void
	updateShownModal: (modalState: ModalState) => void
	updateShowSettingsBar: (show: boolean) => void
	updateMetricSettings: (settings: UserAccountSettings[]) => void
	refreshData: (updateData: UpdateData) => void
	updateReports?: Dispatch<SetStateAction<DataSetMetricGroupedByXeroOrg[] | null>>
}

const DashboardTable = (props: DashboardTableProps) => {
	const [isTagsCollapsed, updateTagsCollapsed] = React.useState<TagCollapse>(props.isTagsCollapsed)
	const [, updateShowSettingsBar] = React.useState<boolean>(false)
	const [metricsToShow, updateMetricsToShow] = React.useState<string[]>([])
	const [relativeUnits, updateRelativeUnits] = React.useState<Units>('amount')

	// sorting components
	const [groups, updateGroups] = React.useState<XeroOrgsGroupedByTag[] | null>(props.groups || null)
	const [groupsWithErrors, updateGroupsWithErrors] = React.useState<XeroOrgsGroupedByTag[] | null>(props.groupsWithErrors || null)
	const [currentSort, updateCurrentSort] = React.useState<{
		id: string
		sort: string
	} | null>(null)

	React.useEffect(() => {
		updateShowSettingsBar(props.showSettingsBar)
	}, [props.showSettingsBar])

	React.useEffect(() => {
		updateGroups(props.groups)
	}, [props.groups])

	React.useEffect(() => {
		updateGroupsWithErrors(props.groupsWithErrors || null)
	}, [props.groupsWithErrors])

	if (groups !== null || groupsWithErrors !== null || props.errorMessage) {
		const [filteredMetrics, filteredMetricGroups] = filterMetrics(props.metricGroups, groups || [], metricsToShow)

		return (
			<div className="table-sticky">
				<Table borderless responsive className="no-wrap">
					<thead>
						<tr className="header-row">
							<th className="header-sticky-top" colSpan={3}>
								General{' '}
							</th>
							{filteredMetricGroups
								.filter((mg) => mg.length > 0)
								.map((mg) => (
									<th className="header-sticky-top" key={mg.metricGroupID} colSpan={mg.length}>
										{mg.name}
									</th>
								))}
						</tr>
						<tr className="stripe-row stripe-row-header pipes-header">
							<th className="header-sticky">
								<span className="span-space-between">
									Company
									<SortingButton
										metricID={'name'}
										sort={currentSort && currentSort.id === 'name' ? currentSort.sort : ''}
										currentSort={currentSort}
										orgs={props.orgs}
										groups={groups}
										groupsWithErrors={groupsWithErrors}
										updateGroups={updateGroups}
										updateGroupsWithErrors={updateGroupsWithErrors}
										updateCurrentSort={updateCurrentSort}
									/>
								</span>
							</th>
							<th className="pipes-th header-sticky">
								<span>Tags</span>
							</th>
							<th className="pipes-th header-sticky">
								<span className="span-space-between">
									Status
									<SortingButton
										metricID={'status'}
										sort={currentSort && currentSort.id === 'status' ? currentSort.sort : ''}
										currentSort={currentSort}
										orgs={props.orgs}
										groups={groups}
										groupsWithErrors={groupsWithErrors}
										updateGroups={updateGroups}
										updateGroupsWithErrors={updateGroupsWithErrors}
										updateCurrentSort={updateCurrentSort}
									/>
								</span>
							</th>
							{filteredMetrics.map((m) => (
								<th className="pipes-th header-sticky" key={m.metricID}>
									<span className="span-space-between">
										{m.name}
										<SortingButton
											metricID={m.metricID!}
											sort={currentSort && currentSort.id === m.metricID ? currentSort.sort : ''}
											currentSort={currentSort}
											orgs={props.orgs}
											groups={groups}
											groupsWithErrors={groupsWithErrors}
											updateGroups={updateGroups}
											updateGroupsWithErrors={updateGroupsWithErrors}
											updateCurrentSort={updateCurrentSort}
										/>
									</span>
								</th>
							))}
							{props.groupsWithErrors && filteredMetrics.length === 0 ? (
								<th className="pipes-th header-sticky">
									<span> </span>
								</th>
							) : null}
						</tr>
					</thead>
					{groups || props.errorMessage ? (
						<DashboardTableBody
							groups={groups || []}
							orgs={props.orgs}
							metricGroups={props.metricGroups}
							appState={props.appState}
							isTagsCollapsed={isTagsCollapsed}
							filteredMetrics={filteredMetrics}
							relativeUnits={relativeUnits}
							updateTagsCollapsed={updateTagsCollapsed}
							updateShownModal={props.updateShownModal}
							refreshData={props.refreshData}
							isComparing={props.isComparing}
							errors={false}
							tab={props.tab}
							updateReports={props.updateReports}
							fetchedTs={props.fetchedTs}
							isLoading={props.isLoading}
							errorMessage={props.errorMessage}
						/>
					) : null}
					{props.groupsWithErrors ? (
						<DashboardTableBody
							groups={groupsWithErrors || []}
							orgs={props.orgs}
							metricGroups={props.metricGroups}
							appState={props.appState}
							isTagsCollapsed={isTagsCollapsed}
							filteredMetrics={filteredMetrics}
							relativeUnits={relativeUnits}
							updateTagsCollapsed={updateTagsCollapsed}
							updateShownModal={props.updateShownModal}
							refreshData={props.refreshData}
							isComparing={props.isComparing}
							errors={true}
							tab={props.tab}
							updateReports={props.updateReports}
							fetchedTs={props.fetchedTs}
							isLoading={props.isLoading}
						/>
					) : null}
				</Table>

				<SettingsBar
					open={props.showSettingsBar}
					onClose={() => props.updateShowSettingsBar(false)}
					onDataUpdate={(data: UpdateData) => {
						updateMetricsToShow(data.metricIDsToShow)
						updateRelativeUnits(data.unitDifference)
					}}
					metricGroups={props.metricGroups}
					metricSettings={props.metricSettings}
					tab={props.tab}
					appState={props.appState}
					updateMetricSettings={props.updateMetricSettings}
				/>
			</div>
		)
	} else {
		return (
			<div>
				<SettingsBar
					open={props.showSettingsBar}
					onClose={() => props.updateShowSettingsBar(false)}
					onDataUpdate={(data: UpdateData) => {
						updateMetricsToShow(data.metricIDsToShow)
						updateRelativeUnits(data.unitDifference)
					}}
					metricGroups={props.metricGroups}
					metricSettings={props.metricSettings}
					tab={props.tab}
					appState={props.appState}
					updateMetricSettings={props.updateMetricSettings}
				/>
			</div>
		)
	}
}

interface DashboardTableBodyProps {
	groups: XeroOrgsGroupedByTag[]
	orgs: AccountXeroOrg[]
	metricGroups: MetricGroup[]
	appState: AppState
	isTagsCollapsed: TagCollapse
	filteredMetrics: Metric[]
	relativeUnits: Units
	errors: boolean
	tab: 'live' | 'pit'
	isComparing: boolean
	isLoading: boolean
	fetchedTs?: string
	errorMessage?: string
	updateTagsCollapsed: (tagCollapse: TagCollapse) => void
	updateShownModal: (modalState: ModalState) => void
	refreshData: (updateData: UpdateData) => void
	updateReports?: Dispatch<SetStateAction<DataSetMetricGroupedByXeroOrg[] | null>>
}

const DashboardTableBody = (props: DashboardTableBodyProps) => {
	return (
		<tbody>
			{props.groups.map((group: XeroOrgsGroupedByTag, index0: number) => (
				<React.Fragment key={index0}>
					{group.tagName ? (
						<tr className="header-row" key={group.tagID}>
							<th colSpan={props.filteredMetrics.length + 2}>{group.tagName} </th>
						</tr>
					) : null}
					{group.xeroOrgs.length > 0 ? (
						group.xeroOrgs.map((xo: DataSetMetricGroupedByXeroOrg, index1: number) => {
							const associatedXeroOrg = props.orgs.find((o) => o.xeroOrg!.xeroOrgID === xo.xeroOrgID)
							return (
								<tr key={xo.xeroOrgID} className={`stripe-row ${(index0 + index1) % 2 === 0 ? 'stripe-row-body' : ''}`}>
									<td
										className="clickable"
										onClick={() =>
											props.updateShownModal({
												modal: 'EditOrg',
												props: {
													appState: props.appState,
													accountXeroOrg: associatedXeroOrg,
													accountXeroOrgMetrics: props.metricGroups,
													filteredMetrics: xo.metrics,
													refreshData: props.refreshData,
													updateReports: props.updateReports,
													tab: props.tab,
													fetchedTs: props.fetchedTs,
												},
												size: 'xl',
											})
										}
									>
										{xo.name}
									</td>
									<td>
										{associatedXeroOrg ? (
											<Row className={'tag'}>
												{associatedXeroOrg.tags!.slice(0, 2).map((t, i) => (
													<Col key={t.tagID} className={'tag'}>
														<Badge
															style={{
																backgroundColor: t.tagGroup!.colourHexCode,
															}}
															className={'tag-dash ellipsis'}
															pill
														>
															{renderIcon(t.tagGroup!.tagGroupIcon, 14)} {t.tagName}
														</Badge>
														{i === 0 && associatedXeroOrg.tags!.length > 2 ? (
															<span className={'tag-collapse'}>
																<CollapseChevron
																	collapsed={props.isTagsCollapsed[associatedXeroOrg.xeroOrg!.xeroOrgID!]}
																	updateCollapsed={() => {
																		props.updateTagsCollapsed({
																			...props.isTagsCollapsed,
																			[associatedXeroOrg.xeroOrg!.xeroOrgID!]:
																				!props.isTagsCollapsed[associatedXeroOrg.xeroOrg!.xeroOrgID!],
																		})
																	}}
																/>
															</span>
														) : null}
													</Col>
												))}
												<Collapse in={!props.isTagsCollapsed[associatedXeroOrg.xeroOrg!.xeroOrgID!]}>
													<Row className={'tag'}>
														{associatedXeroOrg.tags!.slice(2).map((t) => (
															<Col key={t.tagID} className={'tag'}>
																<Badge
																	style={{
																		backgroundColor: t.tagGroup!.colourHexCode,
																	}}
																	className={'tag-dash ellipsis'}
																	pill
																>
																	{renderIcon(t.tagGroup!.tagGroupIcon, 14)} {t.tagName}
																</Badge>
															</Col>
														))}
													</Row>
												</Collapse>
											</Row>
										) : null}
									</td>
									<td>
										{associatedXeroOrg ? (
											associatedXeroOrg.userAccountXeroOrgAuth!.refreshToken !== null &&
											associatedXeroOrg.userAccountXeroOrgAuth!.connectionID !== null ? (
												<Badge variant="success">OK</Badge>
											) : (
												<Badge variant="danger">Re-auth needed</Badge>
											)
										) : (
											<Badge variant="warning">Disconnected</Badge>
										)}
									</td>
									{props.errors ? (
										<td colSpan={props.filteredMetrics.length}>
											<AlertCircle size={20} color={'#ffbc1f'} style={{ marginRight: '.5em' }} />
											{xo.message ? xo.message : 'Unable to retrieve data for this company.'}
										</td>
									) : (
										<>
											{props.filteredMetrics.map((fm: Metric, index: number) => {
												const m = xo.metrics.find((m) => m.metricID === fm.metricID)
												return (
													<MetricValue
														key={index}
														metric={m}
														differenceUnits={props.relativeUnits}
														tab={props.tab}
														isComparing={props.isComparing}
														isLoading={props.isLoading}
													/>
												)
											})}
										</>
									)}
								</tr>
							)
						})
					) : (
						<tr key={`${index0}_none`} className={`stripe-row ${index0 % 2 === 0 ? 'stripe-row-body' : ''}`}>
							<td colSpan={props.filteredMetrics.length + 3}>{props.errorMessage || 'No companies.'}</td>
						</tr>
					)}
				</React.Fragment>
			))}
		</tbody>
	)
}

type FilteredMetrics = [
	Metric[],
	{
		metricGroupID: string
		name: string
		length: number
	}[]
]

export const filterMetrics = (metricGroups: MetricGroup[], organisationMetrics: XeroOrgsGroupedByTag[], metricsToShow: string[]) => {
	return metricGroups.reduce(
		(res: FilteredMetrics, cur: MetricGroup): FilteredMetrics => {
			const arr = filterAvailableMetrics(cur, organisationMetrics, metricsToShow)

			return [
				[...res[0], ...arr],
				[
					...res[1],
					{
						metricGroupID: cur.metricGroupID!,
						name: cur.name!,
						length: arr.length,
					},
				],
			]
		},
		[[], []] as FilteredMetrics
	)
}

const filterAvailableMetrics = (cur: MetricGroup, organisationMetrics: XeroOrgsGroupedByTag[], metricsToShow: string[]) =>
	cur.metrics!.filter((m: Metric) =>
		organisationMetrics.some((om: XeroOrgsGroupedByTag) =>
			om.xeroOrgs.some((xo: DataSetMetricGroupedByXeroOrg) =>
				xo.metrics.some((omm) => omm.metricID === m.metricID && metricsToShow.includes(m.metricID!))
			)
		)
	)

export { DashboardTable }
