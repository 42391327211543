import * as React from 'react'
import { DropzoneArea } from 'material-ui-dropzone'
import * as AppDefinitions from '../../App.d'

// utillites
import * as I from '../../utilities/me'
import * as Request from '../../utilities/request'
import { Button, Col, OverlayTrigger, Row, Tooltip } from 'react-bootstrap'
import { Edit, Trash2 } from 'react-feather'
import { MessageAction } from '../../components/ui/messages/message'
import { S3Config } from '../../constants/config'

export interface ImageFile {
	name?: string
	type?: string
	size?: string
	extention?: string
	base64?: string | ArrayBuffer | null
	file?: File
}

interface LogoUploaderProps {
	appState: AppDefinitions.AppState
	updateMessage: (message: MessageAction) => void
	logo: string | null
	handleLogoUpload: (file: ImageFile, action: 'update' | 'delete', signedUrl?: string) => Promise<void>
}

const LogoUploader = (props: LogoUploaderProps) => {
	const [isEditing, updateIsEditing] = React.useState<boolean>(false)
	const [imagePath, updateImagePath] = React.useState<string>('')
	const [file, updateFile] = React.useState<ImageFile>({})
	const [signedUrl, updateSignedUrl] = React.useState<string>('')

	const getPhotoUploadSignedUrl = React.useCallback(
		async (fileType: string): Promise<{ signedUrl: string; s3Path: string }> => {
			// get s3 url
			// eslint-disable-next-line
			const s3Path = `logos/${props.appState.authState.attributes?.['custom:currentAccountId']}/logo.${fileType.split(/[\/+]/)[1]}` // eslint-disable-line
			const req = await Request.post(
				'accountlogo/presignedurl',
				{
					type: fileType,
					s3Location: s3Path,
					operation: 'putObject',
				},
				props.appState.authState
			)
			updateImagePath(s3Path)
			return { signedUrl: req.data.file.signedUrl!, s3Path }
		},
		[props.appState.authState]
	)

	return (
		<>
			{isEditing && !I.have('general', props.appState.permission[props.appState.context]) ? (
				<>
					<DropzoneArea
						acceptedFiles={['image/*']}
						dropzoneText={'Drag and drop your logo here or click to select'}
						onChange={async (files) => {
							// Make new FileReader
							const reader = new FileReader()
							const file = files[0]
							if (file) {
								const { signedUrl } = await getPhotoUploadSignedUrl(file.type)
								updateSignedUrl(signedUrl)
								// Convert the file to base64 text
								reader.readAsDataURL(file as Blob)

								// on reader load somthing...
								reader.onload = () => {
									// Make a fileInfo Object
									const fileInfo = {
										name: file.name,
										type: file.type,
										//eslint-disable-next-line
										extention: file.type.split(/[\/+]/)[1],
										size: Math.round(file.size / 1000) + ' kB',
										base64: reader.result,
										file: file,
									}
									updateFile(fileInfo)
								}
							}
						}}
						filesLimit={1}
						showPreviews={true}
						showPreviewsInDropzone={false}
						showAlerts={false}
						showFileNamesInPreview={true}
					/>
					<Row style={{ padding: '15px 0px 0px 8px' }}>
						<Button
							type="button"
							style={{ margin: '-5px 5px 0px 5px' }}
							variant={imagePath === '' ? 'primary' : 'success'}
							disabled={imagePath === ''}
							onClick={() => {
								updateIsEditing(false)
								props.handleLogoUpload(file, 'update', signedUrl)
							}}
						>
							Save
						</Button>
						<Button
							type="button"
							style={{ margin: '-5px 5px 0px 5px' }}
							onClick={() => {
								updateIsEditing(false)
								updateImagePath('')
							}}
						>
							Cancel
						</Button>
					</Row>
				</>
			) : (
				<>
					<Row style={{ justifyContent: 'space-between' }}>
						{props.logo ? (
							<>
								<Row>
									<Col>
										<p style={{ paddingLeft: '30px' }}>This is the size the image will appear in your alerts.</p>
									</Col>
								</Row>
								<Row style={{ width: '-webkit-fill-available' }}>
									<br />
								</Row>
								<Row>
									<img
										className="custom-logo"
										//src={`https://s3.ap-southeast-1.amazonaws.com/doc-dev.finlert.com/${props.logo}`} // for local visualization use this
										src={`https://${S3Config.BaseUrl}/${props.logo}`}
										alt={'custom account logo'}
									/>
								</Row>
							</>
						) : (
							<span style={{ padding: '20px' }}>You do not have a logo for this account.</span>
						)}
						<Col sm="auto" className="position-right" style={{ padding: '25px' }}>
							<OverlayTrigger placement="auto" overlay={<Tooltip id={`tooltip-xero`}>Edit your logo</Tooltip>}>
								<div>
									<Edit
										className={'settings-plus'}
										onClick={() => {
											if (!I.have('general', props.appState.permission[props.appState.context])) {
												updateIsEditing(true)
											} else {
												props.updateMessage({
													type: 'add',
													data: {
														severity: 'danger',
														message: `You don't have permissions to change the account logo`,
														timeout: 3000,
														dismissible: true,
													},
												})
											}
										}}
									/>
								</div>
							</OverlayTrigger>
						</Col>
						{props.logo ? (
							<Col sm="auto" className="position-right" style={{ paddingTop: '25px' }}>
								<OverlayTrigger placement="auto" overlay={<Tooltip id={`tooltip-xero`}>Delete logo</Tooltip>}>
									<div>
										<Trash2
											className={'settings-plus'}
											onClick={() => {
												if (!I.have('general', props.appState.permission[props.appState.context])) {
													props.handleLogoUpload({}, 'delete')
												} else {
													props.updateMessage({
														type: 'add',
														data: {
															severity: 'danger',
															message: `You don't have permissions to delete the account logo`,
															timeout: 3000,
															dismissible: true,
														},
													})
												}
											}}
										/>
									</div>
								</OverlayTrigger>
							</Col>
						) : null}
					</Row>
				</>
			)}
		</>
	)
}

export default LogoUploader
