import React from 'react'
// components
import OverviewComponent from './overview/overview'
import SettingsComponent from './settings/settings'
import LoadingImage from '../../images/subSyncIcons/SubSync_100x100.gif'

// definitions
import * as AppDefinitions from '../../App.d'
import { OrgSettings, HistoryView, RetryBody } from '../../screens/subsync/subsync'

type FrameType = 'overview' | 'settings'

interface FrameProps {
	pageStatus: string
	appState: AppDefinitions.AppState
	currentFrame: FrameType
	historyView: HistoryView
	updateHistoryView: (historyView: HistoryView) => void
	orgSettings: OrgSettings
	updateOrgSettings: (orgSettings: OrgSettings) => void
	refreshSettingsData: () => void
	refreshOverviewData: () => void
	retryFailedTransaction: (body: RetryBody) => void
}

const BodyFrame = (props: FrameProps) => {
	if (props.pageStatus === 'Finished') {
		return (
			<>
				<OverviewComponent
					show={props.currentFrame === 'overview'}
					appState={props.appState}
					stripeFeesBillsEnabled={props.orgSettings.orgMappings.some((mapping) => mapping.stripeOrgAccountXeroOrgSettings?.stripeFeesBillsEnabled)}
					creditNotesEnabled={props.orgSettings.orgMappings.some((mapping) => mapping.stripeOrgAccountXeroOrgSettings?.creditNotesEnabled)}
					historyView={props.historyView}
					updateHistoryView={props.updateHistoryView}
					refreshData={props.refreshOverviewData}
					retryFailedTransaction={props.retryFailedTransaction}
				/>
				<SettingsComponent
					show={props.currentFrame === 'settings'}
					appState={props.appState}
					orgSettings={props.orgSettings}
					updateOrgSettings={props.updateOrgSettings}
					refreshData={props.refreshSettingsData}
				/>
			</>
		)
	} else {
		return (
			<div className="loading-gif">
				<img src={LoadingImage} alt="Loading ..." />
			</div>
		)
	}
}

export type { FrameType }
export default BodyFrame
