import React from 'react'
import { Table, Badge } from 'react-bootstrap'
import { Link } from 'react-router-dom'

import { TagGroup } from '../../../../../back-end/utilities/apiDefinitions'

import { renderIcon } from '../../../utilities/icon'

const GroupList = (props: { groups: TagGroup[] }) => {
	return (
		<Table borderless responsive>
			<thead>
				<tr className="header-row">
					<th></th>
					<th>Name</th>
					<th>Tags</th>
				</tr>
			</thead>
			<tbody>
				{props.groups.map((group: TagGroup, index: number) => (
					<tr key={group.tagGroupID} className={`stripe-row ${index % 2 === 0 ? 'stripe-row-body' : ''}`}>
						<td>{renderIcon(group.tagGroupIcon)}</td>
						<td colSpan={1}>
							<Link to={`/tag/${group.tagGroupID}`}>{group.tagGroupName}</Link>
						</td>
						<td>
							{group.tags!.map((t) => (
								<Badge style={{ backgroundColor: group.colourHexCode }} className={'tag'} pill key={t.tagID}>
									{t.tagName}
								</Badge>
							))}
						</td>
					</tr>
				))}
				{props.groups.length === 0 ? (
					<tr className={`stripe-row stripe-row-body`}>
						<td colSpan={3}>No Tag Groups</td>
					</tr>
				) : null}
			</tbody>
		</Table>
	)
}

export default GroupList
