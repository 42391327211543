import React from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import { Container } from 'react-bootstrap'

// utilities
import { AppContext } from '../../App'
import * as Url from '../../utilities/url'
import * as Request from '../../utilities/request'

import SubSyncLoadingImage from '../../images/subSyncIcons/SubSync_100x100.gif'

const Callback = () => {
	const { appState } = React.useContext(AppContext)
	const location = useLocation()
	const stripeUserId = Url.getUrlSearchParam(location.search, 'user_id')
	const stripeAccountId = Url.getUrlSearchParam(location.search, 'account_id')
	const stripeError = Url.getUrlSearchParam(location.search, 'xerror')
	console.log({ stripeUserId, stripeAccountId, stripeError })

	const [loadingMessage, updateLoadingMessage] = React.useState<string>('Connecting to your Stripe account...')
	const [currentProgress, updateCurrentProgress] = React.useState<number>(0)
	const [error, updateError] = React.useState<string>(stripeError || '')
	const [redirect, setRedirect] = React.useState<string>('/')

	React.useEffect(() => {
		const setupStripeOrg = async () => {
			const orgParams = {
				stripeID: stripeAccountId,
			}
			const orgResult = await Request.post('stripeorg', orgParams, appState.authState)
			// NTH: do progressive loading like Xero connections
			if (orgResult.status === 200) {
				// do you have xero connected? if so, go to settings
				const xeroOrg = await Request.get('organisation?where=AccountXeroOrgIsSubSync==1', appState.authState)
				if (xeroOrg.data.accountXeroOrgs.length > 0) {
					setRedirect('/#settings')
				}
				updateLoadingMessage('All done! Redirecting you...')
				await Request.sleep(2000)
				updateCurrentProgress(100)
			} else {
				updateError('Something went wrong')
			}
		}

		// TODO: verify install signature before continuing
		if (appState.authState.isLoggedIn && stripeAccountId && !error) {
			setupStripeOrg()
		}
	}, [appState.authState.isLoggedIn])

	if (currentProgress === 100) {
		return <Navigate to={redirect} />
	} else if (!error) {
		return (
			<Container className="loading-gif">
				<img src={SubSyncLoadingImage} alt="Loading ..." />
				<div className="progress" style={{ margin: '20px 0px 20px 23%', width: '52%' }}>
					<div
						className="progress-bar"
						role="progressbar"
						aria-valuenow={currentProgress}
						aria-valuemin={0}
						aria-valuemax={100}
						style={{ width: `${currentProgress}%`, backgroundColor: '#8AA6FF' }}
					></div>
				</div>
				<div style={{ textAlign: 'center' }}>
					<div dangerouslySetInnerHTML={{ __html: loadingMessage }} />
				</div>
			</Container>
		)
	} else {
		return (
			<Container>
				<p>{error}</p>
			</Container>
		)
	}
}

export default Callback
