import React from 'react'
import { Button } from 'react-bootstrap'

import { TableColumn } from './table'

import sortAsc from '../../../images/sort-asc.svg'
import sortDes from '../../../images/sort-des.svg'

interface SortFilter<T> {
	column: keyof T | string
	order: 'ASC' | 'DSC'
}

interface SortButtonProps<T> {
	setSort: React.Dispatch<React.SetStateAction<SortFilter<T>>>
	sort: SortFilter<T>
	columnName: keyof T | string
}

const SortButton = <T,>(props: SortButtonProps<T>) => {
	const setSortOrder = (column: keyof T | string) => {
		if (column !== props.sort.column) {
			props.setSort({ column, order: 'ASC' })
		} else {
			props.setSort({
				column,
				order: props.sort.order === 'ASC' ? 'DSC' : 'ASC',
			})
		}
	}

	const imgSrc = (props.sort.column === props.columnName && props.sort.order === 'ASC') || props.sort.column !== props.columnName ? sortAsc : sortDes
	const altText = `A sort ${
		(props.sort.column === props.columnName && props.sort.order === 'ASC') || props.sort.column !== props.columnName ? 'ascending' : 'descending'
	} icon`
	const className = props.sort.column === props.columnName ? 'table-sort-active' : ''

	return (
		<Button className="sort-table-btn" onClick={() => setSortOrder(props.columnName)}>
			<img src={imgSrc} alt={altText} className={className} />
		</Button>
	)
}

const getSortFunction =
	<T,>(sort: SortFilter<T>, columns: TableColumn<T>[]) =>
	(a: T, b: T) => {
		const sortColumn = columns.find((c) => c.sortColumnName === sort.column)
		if (sortColumn) {
			const valueA = sortColumn.value(a)
			const valueB = sortColumn.value(b)
			return valueA && valueB ? (sort.order === 'ASC' ? String(valueA).localeCompare(String(valueB)) : String(valueB).localeCompare(String(valueA))) : 1
		}
		return 0
	}

export { SortButton, getSortFunction }
export type { SortFilter, SortButtonProps }
