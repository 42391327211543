import React from 'react'
import { Metric, MetricDataType } from '../../../../back-end/utilities/apiDefinitions'
import * as Format from '../../utilities/format'
import { Units } from '../oversight/settingsBar'
import { Badge, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { AlertCircle } from 'react-feather'

interface MetricValueProps {
	metric?: Metric
	differenceUnits?: Units
	tab?: 'live' | 'pit'
	isComparing?: boolean
	isLoading?: boolean
}

const MetricValue = (props: MetricValueProps) => {
	if (props.metric && props.metric.value !== null && props.metric.value !== undefined) {
		const values = compareValues(
			props.metric.value!,
			props.metric.oldValue,
			props.metric.dataType!,
			props.metric.biggerIsBetter!,
			props.metric.thresholdGood!,
			props.metric.thresholdBad!
		)
		return (
			<td>
				<span className="metric-value">{getFormattedValue(props.metric.value!, props.metric.dataType!, false)} </span>
				{values.showPill ? (
					<span className="metric-diff-icon">
						{getDiffIcon(
							(values.absoluteDifference !== null && values.absoluteDifference !== undefined) ||
								(values.percentageDifference !== null && values.percentageDifference !== undefined)
								? props.differenceUnits === 'amount'
									? getFormattedValue(values.absoluteDifference!, props.metric.dataType!, true)
									: getFormattedPercentage(values.percentageDifference!, props.metric.dataType!)
								: undefined,
							values.pillColour
						)}
					</span>
				) : props.tab === 'pit' && props.isComparing === true ? (
					<span className={'no-data-comparison'}>
						<NoDataIndicator text={'Unable to retrieve comparison data.'} />
					</span>
				) : null}
			</td>
		)
	} else {
		return (
			<td>
				{!props.isLoading && (
					<span className={'no-data'}>
						<NoDataIndicator text={'Unable to retrieve data.'} />
					</span>
				)}
			</td>
		)
	}
}

interface NoDataIndicatorProps {
	text: string
}

export const NoDataIndicator = ({ text }: NoDataIndicatorProps) => {
	return (
		<OverlayTrigger placement={'auto'} overlay={<Tooltip id={'no-data-tooltip'}>{text}</Tooltip>}>
			<AlertCircle size={20} />
		</OverlayTrigger>
	)
}

const compareValues = (
	currValue: string | number,
	oldValue: string | number | null | undefined,
	dataType: MetricDataType,
	biggerIsBetter: boolean,
	thresholdGood: number | null,
	thresholdBad: number | null
) => {
	let pillColour = 'grey' // grey, green, orange, red
	let absDiff
	let percDiff

	if (oldValue !== null && oldValue !== undefined) {
		if (dataType === 'FLOAT' || dataType === 'INT') {
			absDiff = Number(currValue) - Number(oldValue)
			percDiff = Number(currValue) === 0 ? 0 : Number(oldValue) === 0 ? 'infinity' : absDiff / Number(oldValue)
		} else if (dataType === 'DATETIME') {
			// subtract two values and get days difference?
		} else if (dataType === 'VARCHAR') {
			// how even?
		}
	}

	if (thresholdGood !== null && thresholdBad !== null) {
		if (biggerIsBetter) {
			if (Number(currValue) >= thresholdGood) {
				pillColour = 'green'
			} else if (Number(currValue <= thresholdBad)) {
				pillColour = 'red'
			} else {
				pillColour = 'orange'
			}
		} else {
			if (Number(currValue) >= thresholdBad) {
				pillColour = 'red'
			} else if (Number(currValue) <= thresholdGood) {
				pillColour = 'green'
			} else {
				pillColour = 'orange'
			}
		}
	} else if (thresholdGood !== null) {
		if (biggerIsBetter) {
			if (Number(currValue) >= thresholdGood) {
				pillColour = 'green'
			}
		} else {
			if (Number(currValue) <= thresholdGood) {
				pillColour = 'green'
			}
		}
	} else if (thresholdBad !== null) {
		if (biggerIsBetter) {
			if (Number(currValue) <= thresholdBad) {
				pillColour = 'red'
			}
		} else {
			if (Number(currValue) >= thresholdBad) {
				pillColour = 'red'
			}
		}
	}

	return {
		showPill:
			(oldValue !== null && oldValue !== undefined) ||
			(thresholdGood !== null && thresholdBad !== null) ||
			(thresholdGood !== null && pillColour === 'green') ||
			(thresholdBad !== null && pillColour === 'red'),
		pillColour: pillColour,
		absoluteDifference: absDiff,
		percentageDifference: percDiff,
	}
}

const getFormattedValue = (value: string | number, dataType: MetricDataType, hasSign: boolean): string => {
	if (dataType === 'FLOAT' || dataType === 'INT') {
		return `${hasSign && Number(value) >= 0 ? '+' : ''}${Format.number(Number(value), 0)}`
	} else if (dataType === 'DATETIME') {
		return Format.date(String(value))
	}

	return String(value)
}

const getFormattedPercentage = (value: string | number, dataType: MetricDataType) => {
	if (value === 'infinity') {
		return '+\u221e%'
	} else if (dataType === 'FLOAT' || dataType === 'INT') {
		const plusSign = Number(value) < 0 ? '' : '+'
		return `${value < 0.01 && value > -0.01 && value !== 0 ? '<1' : `${plusSign}${(Number(value) * 100).toFixed(0)}`}%`
	}
	return ''
}

const getDiffIcon = (value: string | undefined, colour: string) => {
	const colourToVariant = colour === 'green' ? 'success' : colour === 'grey' ? 'light' : colour === 'orange' ? 'warning' : 'danger'

	if (value) {
		return (
			<Badge className={`badge-diff`} pill variant={colourToVariant}>
				{value}
			</Badge>
		)
	} else {
		return (
			<Badge className={`badge-diff`} pill variant={colourToVariant}>
				{' '}
			</Badge>
		)
	}
}

export default MetricValue
