import { Checkbox, FormControlLabel } from '@material-ui/core'
import React from 'react'
import { Row, FormLabel, Col, FormControl, Form, Button, OverlayTrigger, Tooltip, FormGroup } from 'react-bootstrap'
import { X } from 'react-feather'
import { AccountXeroOrg, Calendar, LeaveAlert } from '../../../../back-end/utilities/apiDefinitions'
import * as yup from 'yup'

// definitions
import * as AppDefinitions from '../../App.d'
import { MessageAction } from '../../components/ui/messages/message'
import { LeaveBalance as LeaveBalanceProduct } from '../../constants/products'

// components
import * as Messages from '../../components/ui/messages/messages'
import TAYGLoadingImage from '../../images/TAYG_100px.gif'

// utillites
import * as Request from '../../utilities/request'
interface EmailAlertsFrameProps {
	show: boolean
	appState: AppDefinitions.AppState
	currentOrg: AccountXeroOrg
	currentCalendar: Calendar
	pageStatus: string
	updatePageStatus: React.Dispatch<React.SetStateAction<string>>
}

export interface AlertCardProps {
	alert: LeaveAlert
	appState: AppDefinitions.AppState
	reset: () => void
	updateMessages: (message: MessageAction) => void
	updateOriginalData: (alert: LeaveAlert[]) => void
	removeAlert: (alert: LeaveAlert) => void
}

interface EmailAlertCardProps extends AlertCardProps {
	hasLeaveBalanceProduct: boolean
	emailAlerts: LeaveAlert[]
	updateEmailAlerts: (alert: LeaveAlert[]) => void
}

const emptyAlert: LeaveAlert = {
	leaveAlertID: '',
	recipientEmail: '',
	recipientName: '',
	isWeekly: false,
	isMonthly: false,
	isDaily: false,
	isDailyWeekdays: false,
	isOnApproval: false,
	isOnRequest: false,
	isMonthlyLeaveBalance: false,
	isTwiceMonthlyLeaveBalance: false,
	isSlack: false,
	calendarID: '',
	taygIsGraphical: true,
}

const ConstructAlertCard = (props: EmailAlertCardProps) => {
	const [isEditing, updateIsEditing] = React.useState<boolean>(props.alert.leaveAlertID!.includes('new') ? true : false)
	const [isSubmitting, updateIsSubmitting] = React.useState<boolean>(false)

	// ---- Validation Schema ---- //
	const schema = yup.object().shape({
		recipientEmail: yup
			.string()
			.matches(/^[\W]*([\w+\-.%]+@[\w\-.]+\.[A-Za-z]{2,4}[\W]*,{1}[\W]*)*([\w+\-.%]+@[\w\-.]+\.[A-Za-z]{2,63})[\W]*$/, 'Invalid email format.')
			.required('Email is required.'), //.email('Enter a valid email.')
		ccRecipientEmail: yup
			.string()
			.nullable()
			.optional()
			.transform((curr, orig) => (orig === '' ? null : curr))
			.matches(/^[\W]*([\w+\-.%]+@[\w\-.]+\.[A-Za-z]{2,4}[\W]*,{1}[\W]*)*([\w+\-.%]+@[\w\-.]+\.[A-Za-z]{2,63})[\W]*$/, 'Invalid cc email format.'), //.email('Enter a valid email.')
		recipientName: yup.string().required('Name is required.'),
		isWeekly: yup.boolean(),
		isMonthly: yup.boolean(),
		alertSelection: yup.boolean().when(['isWeekly', 'isMonthly'], {
			is: (isWeekly: boolean, isMonthly: boolean) => !isWeekly && !isMonthly,
			then: yup.boolean().required('At least one alert type must be selected'),
		}),
		note: yup.string().nullable().optional().max(1000, 'Note cannot exceed 1000 characters.'),
	})

	const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault()
		try {
			try {
				await schema.validate(props.alert, { abortEarly: false })
			} catch (validationError) {
				if (validationError instanceof yup.ValidationError) {
					const newErrors = validationError.inner.map((err) => err.errors.join('\n'))
					props.updateMessages({
						type: 'add',
						data: {
							severity: 'danger',
							message: `Unable to update Email Alerts: \n\n ${newErrors
								.filter((val: string, id: number, array: string | string[]) => array.indexOf(val) === id)
								.join('\n')}`,
							timeout: 3000,
							dismissible: true,
						},
					})
				}
				return
			}
			updateIsSubmitting(true)

			const req = await (props.alert.leaveAlertID!.includes('new')
				? Request.post('alert', props.alert, props.appState.authState)
				: Request.put(`alert/${props.alert.leaveAlertID}`, props.alert, props.appState.authState))

			if (req.data.success) {
				props.updateMessages({
					type: 'add',
					data: {
						severity: 'success',
						message: 'Successfully updated Email Alerts',
						timeout: 3000,
						dismissible: true,
					},
				})
				const newAlerts = props.emailAlerts!.map((al: LeaveAlert) => {
					if (al.leaveAlertID?.includes('new')) {
						return req.data.leaveAlerts[0]
					}
					return al
				})
				props.updateEmailAlerts(newAlerts)
				props.updateOriginalData(newAlerts)
			} else {
				props.updateMessages({
					type: 'add',
					data: {
						severity: 'danger',
						message: 'Unable to update Email Alerts',
						timeout: 3000,
						dismissible: true,
					},
				})
			}

			updateIsSubmitting(false)
			updateIsEditing(false)
		} catch (error) {
			console.log('Error: ', error, props.appState.authState)
		}
	}
	const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		event.preventDefault()
		const value = event.target.value

		const newAlerts = props.emailAlerts!.map((al: LeaveAlert) => {
			if (al.leaveAlertID === props.alert.leaveAlertID) {
				return { ...al, recipientEmail: value }
			}
			return al
		})
		props.updateEmailAlerts(newAlerts)
	}

	const handleCCEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		event.preventDefault()
		const value = event.target.value

		const newAlerts = props.emailAlerts!.map((al: LeaveAlert) => {
			if (al.leaveAlertID === props.alert.leaveAlertID) {
				return { ...al, ccRecipientEmail: value }
			}
			return al
		})
		props.updateEmailAlerts(newAlerts)
	}

	const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		event.preventDefault()
		const value = event.target.value

		const newAlerts = props.emailAlerts!.map((al: LeaveAlert) => {
			if (al.leaveAlertID === props.alert.leaveAlertID) {
				return { ...al, recipientName: value }
			}
			return al
		})
		props.updateEmailAlerts(newAlerts)
	}

	const handleNoteChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		event.preventDefault()
		const value = event.target.value

		const newAlerts = props.emailAlerts.map((al: LeaveAlert) => {
			if (al.leaveAlertID === props.alert.leaveAlertID) {
				return { ...al, note: value }
			}
			return al
		})
		props.updateEmailAlerts(newAlerts)
	}

	const handleTypeChange = (option: string) => {
		const newAlerts = props.emailAlerts.map((al: LeaveAlert) => {
			if (al.leaveAlertID === props.alert.leaveAlertID) {
				if (option === 'weekly') {
					return { ...al, isWeekly: !al.isWeekly }
				} else if (option === 'monthly') {
					return { ...al, isMonthly: !al.isMonthly }
				} else if (option === 'graphical') {
					return { ...al, taygIsGraphical: !al.taygIsGraphical }
				} else {
					return { ...al, [option]: !al[option as keyof typeof al] }
				}
			}
			return al
		})
		props.updateEmailAlerts(newAlerts)
	}

	return (
		<div className={`form ${isEditing ? 'editing' : ''}`} style={{ paddingTop: '15px' }}>
			<div onClick={() => updateIsEditing(true)}>
				<Form onSubmit={handleSubmit} className="needs-validation">
					<Row className="leavecal-wrap-style">
						<Col sm="2" style={{ marginTop: '15px', textAlign: 'right' }}>
							<FormLabel>Recipient Name:</FormLabel>
						</Col>
						<Col sm="5">
							<FormControl
								type={'text'}
								style={{ marginTop: '7px', textAlign: 'left' }}
								value={props.alert.recipientName}
								readOnly={!isEditing}
								onClick={() => updateIsEditing(true)} //I.have('admin', props.appState.permission[props.appState.context]) &&
								onChange={handleNameChange}
								name={'recipientName'}
							/>
						</Col>
						<Col sm="auto" style={{ flex: '1', textAlign: 'right' }}>
							<OverlayTrigger placement="auto" overlay={<Tooltip id={`tooltip-remove-alert`}>Remove email alert.</Tooltip>}>
								<X
									style={{ cursor: 'pointer', paddingTop: '5px', width: '30px', height: '30px', textAlign: 'right' }}
									onClick={() => props.removeAlert(props.alert)}
								/>
							</OverlayTrigger>
						</Col>
					</Row>
					<Row className="leavecal-wrap-style">
						<Col sm="2" style={{ marginTop: '15px', textAlign: 'right' }}>
							<FormLabel>Recipient Email Address(es):</FormLabel>
						</Col>
						<Col sm="5">
							<Form.Group style={{ marginBottom: '0px' }}>
								<Form.Control
									as="textarea"
									className="form-control"
									style={{ marginTop: '7px', textAlign: 'left', resize: 'none' }}
									rows={
										(props.alert.recipientEmail!.match(/,/g) || []).length > 1
											? Math.floor((props.alert.recipientEmail!.match(/,/g) || []).length / 3) + 1
											: 1
									}
									value={props.alert.recipientEmail}
									readOnly={!isEditing}
									onClick={() => updateIsEditing(true)} //I.have('admin', props.appState.permission[props.appState.context]) &&
									onChange={handleEmailChange}
									name={'recipientEmail'}
								/>
							</Form.Group>
						</Col>
					</Row>
					<Row className="leavecal-wrap-style">
						<Col sm="2" style={{ marginTop: '15px', textAlign: 'right' }}>
							<FormLabel>CC Email Address(es):</FormLabel>
						</Col>
						<Col sm="5">
							<Form.Group style={{ marginBottom: '0px' }}>
								<Form.Control
									as="textarea"
									className="form-control"
									style={{ marginTop: '7px', textAlign: 'left', resize: 'none' }}
									rows={
										(props.alert.ccRecipientEmail?.match(/,/g) || []).length > 1
											? Math.floor((props.alert.ccRecipientEmail?.match(/,/g) || []).length / 3) + 1
											: 1
									}
									value={props.alert.ccRecipientEmail}
									readOnly={!isEditing}
									onClick={() => updateIsEditing(true)} //I.have('admin', props.appState.permission[props.appState.context]) &&
									onChange={handleCCEmailChange}
									name={'ccRecipientEmail'}
								/>
							</Form.Group>
						</Col>
					</Row>
					<Row className="leavecal-wrap-style">
						<Col sm="2" style={{ marginTop: '15px', textAlign: 'right' }}>
							<FormLabel>Alert Notes:</FormLabel>
						</Col>
						<Col sm="5">
							<Form.Group style={{ marginBottom: '0px' }}>
								<OverlayTrigger
									placement={'right'}
									overlay={<Tooltip id={`tooltip-remove-alert`}>Adds a note to the alert for everyone to see.</Tooltip>}
								>
									<Form.Control
										as="textarea"
										className="form-control"
										value={props.alert.note}
										style={{ marginTop: '7px', textAlign: 'left', resize: 'none' }}
										rows={3}
										readOnly={!isEditing}
										onClick={() => updateIsEditing(true)}
										onChange={handleNoteChange}
										name={'alertNote'}
									/>
								</OverlayTrigger>
							</Form.Group>
						</Col>
					</Row>
					<Row className="leavecal-wrap-style">
						<Col sm="2" style={{ marginTop: '15px', textAlign: 'right' }}>
							<FormLabel>TAYG Alert:</FormLabel>
						</Col>

						<Form.Group as={Col} style={{ paddingTop: '7px' }}>
							<FormControlLabel
								control={
									<Checkbox
										color={'default'}
										readOnly={!isEditing}
										checked={props.alert.isMonthly}
										onChange={(e) => {
											updateIsEditing(true)
											handleTypeChange(e.target.name)
										}}
										name={'monthly'}
									/>
								}
								label={'Monthly Alert - Send on the 25th of the month (which gives you time to act before EOM)'}
							/>

							<FormControlLabel
								control={
									<Checkbox
										color={'default'}
										readOnly={!isEditing}
										checked={props.alert.isWeekly}
										onChange={(e) => {
											updateIsEditing(true)
											handleTypeChange(e.target.name)
										}}
										name={'weekly'}
									/>
								}
								label={'Weekly Alert - Send on mornings of your choice'}
							/>

							{props.alert.isWeekly && (
								<div style={{ paddingLeft: '30px' }}>
									<FormControlLabel
										control={
											<Checkbox
												color="primary"
												name="onMonday"
												readOnly={!isEditing}
												checked={props.alert.onMonday}
												onChange={(e) => {
													updateIsEditing(true)
													handleTypeChange(e.target.name)
												}}
											/>
										}
										label="Monday morning"
									/>
									<FormControlLabel
										control={
											<Checkbox
												color="primary"
												name="onTuesday"
												readOnly={!isEditing}
												checked={props.alert.onTuesday}
												onChange={(e) => {
													updateIsEditing(true)
													handleTypeChange(e.target.name)
												}}
											/>
										}
										label="Tuesday morning"
									/>
									<FormControlLabel
										control={
											<Checkbox
												color="primary"
												name="onWednesday"
												readOnly={!isEditing}
												checked={props.alert.onWednesday}
												onChange={(e) => {
													updateIsEditing(true)
													handleTypeChange(e.target.name)
												}}
											/>
										}
										label="Wednesday morning"
									/>
									<FormControlLabel
										control={
											<Checkbox
												color="primary"
												name="onThursday"
												readOnly={!isEditing}
												checked={props.alert.onThursday}
												onChange={(e) => {
													updateIsEditing(true)
													handleTypeChange(e.target.name)
												}}
											/>
										}
										label="Thursday morning"
									/>
									<FormControlLabel
										control={
											<Checkbox
												color="primary"
												name="onFriday"
												readOnly={!isEditing}
												checked={props.alert.onFriday}
												onChange={(e) => {
													updateIsEditing(true)
													handleTypeChange(e.target.name)
												}}
											/>
										}
										label="Friday morning"
									/>
									<FormControlLabel
										control={
											<Checkbox
												color="primary"
												name="onSaturday"
												readOnly={!isEditing}
												checked={props.alert.onSaturday}
												onChange={(e) => {
													updateIsEditing(true)
													handleTypeChange(e.target.name)
												}}
											/>
										}
										label="Saturday morning"
									/>
									<FormControlLabel
										control={
											<Checkbox
												color="primary"
												name="onSunday"
												readOnly={!isEditing}
												checked={props.alert.onSunday}
												onChange={(e) => {
													updateIsEditing(true)
													handleTypeChange(e.target.name)
												}}
											/>
										}
										label="Sunday morning"
									/>
								</div>
							)}
						</Form.Group>
					</Row>
					<Row className="leavecal-wrap-style">
						<Col sm="2" style={{ marginTop: '15px', textAlign: 'right' }}>
							<FormLabel>TAYG Email Format:</FormLabel>
						</Col>
						<FormGroup>
							<Row style={{ margin: '15px 0px 0px 0px' }}>
								<label>
									<input
										type="radio"
										style={{ marginRight: '5px', marginTop: '3px' }}
										value="graphical"
										checked={props.alert.taygIsGraphical}
										onClick={() => handleTypeChange('graphical')}
									/>{' '}
									Graphical - same format as the overview{' '}
								</label>
							</Row>
							<Row style={{ margin: '15px 0px 0px 0px' }}>
								<label>
									<input
										type="radio"
										style={{ marginRight: '5px', marginTop: '3px' }}
										value="text"
										checked={!props.alert.taygIsGraphical}
										onClick={() => handleTypeChange('graphical')}
									/>{' '}
									Text - figures in a list{' '}
								</label>
							</Row>
						</FormGroup>
					</Row>
					{isEditing ? (
						<Row>
							<Col sm="2"></Col>
							<Col sm="auto" style={{ marginTop: '7px', marginBottom: '7px' }}>
								<Button type="submit" disabled={isSubmitting} variant="info">
									{props.alert.leaveAlertID!.includes('new') ? 'Save' : 'Finish Editing'}
								</Button>
							</Col>
							<Col sm="auto" style={{ marginTop: '7px', marginBottom: '7px' }}>
								<Button
									type="button"
									disabled={isSubmitting}
									onClick={(event) => {
										event.stopPropagation()
										props.reset()
										updateIsEditing(false)
									}}
								>
									Cancel
								</Button>
							</Col>
						</Row>
					) : null}
				</Form>
			</div>
		</div>
	)
}

const EmailAlerts = (props: EmailAlertsFrameProps) => {
	const [emailAlerts, updateEmailAlerts] = React.useState<LeaveAlert[] | null>(null)
	const [originalData, updateOriginalData] = React.useState<LeaveAlert[] | null>(null)
	const [messages, updateMessages] = Messages.useMessageReducer([])

	React.useEffect(
		() => {
			const fetchData = async () => {
				const [alerts] = await Promise.all([
					Request.get(
						`alert?where=LeaveAlertIsSlack==0&where=AccountXeroOrgIsTayg==1&where=XeroOrgID==${props.currentOrg.xeroOrg!.xeroOrgID}`,
						props.appState.authState
					),
				])

				updateEmailAlerts(alerts.data.leaveAlerts)
				updateOriginalData(alerts.data.leaveAlerts)
				if (props.show) {
					props.updatePageStatus('Finished')
				}
			}

			if (props.currentOrg.xeroOrgID !== '') {
				fetchData()
			}
		},
		// eslint-disable-next-line
		[props.appState.authState, props.currentOrg.xeroOrgID, props.currentOrg]
	)

	const reset = () => {
		updateEmailAlerts(originalData)
	}

	const removeAlert = async (alert: LeaveAlert) => {
		if (alert.leaveAlertID!.includes('new')) {
			updateEmailAlerts(emailAlerts!.filter((al: LeaveAlert) => al.leaveAlertID !== alert.leaveAlertID))
			updateMessages({
				type: 'add',
				data: {
					severity: 'success',
					message: 'Successfully removed email alert',
					timeout: 3000,
					dismissible: true,
				},
			})
		} else {
			const req = await Request.del(`alert/${alert.leaveAlertID}`, props.appState.authState)
			if (req.data.success) {
				updateEmailAlerts(emailAlerts!.filter((al: LeaveAlert) => al.leaveAlertID !== alert.leaveAlertID))
				updateMessages({
					type: 'add',
					data: {
						severity: 'success',
						message: 'Successfully removed email alert',
						timeout: 3000,
						dismissible: true,
					},
				})
			} else {
				updateMessages({
					type: 'add',
					data: {
						severity: 'danger',
						message: 'Unable to remove email alert',
						timeout: 3000,
						dismissible: true,
					},
				})
			}
		}
	}

	if (props.show) {
		if (props.pageStatus === 'Finished') {
			return (
				<>
					<FormGroup style={{ padding: '30px' }}>
						<Row className="email-alerts-card">
							<Row style={{ justifyContent: 'space-between' }}>
								<FormLabel className="emails-label">Email Alerts</FormLabel>
							</Row>
							{emailAlerts
								? emailAlerts.map((alert: LeaveAlert) => (
										<div key={alert.leaveAlertID}>
											<ConstructAlertCard
												alert={alert}
												appState={props.appState}
												removeAlert={removeAlert}
												reset={reset}
												emailAlerts={emailAlerts}
												hasLeaveBalanceProduct={
													(props.currentOrg.xeroOrg &&
														props.currentOrg.xeroOrg.products &&
														props.currentOrg.xeroOrg.products.findIndex(
															(product) => product.productID === LeaveBalanceProduct.productID
														) >= 0) ||
													false
												}
												updateEmailAlerts={updateEmailAlerts}
												updateMessages={updateMessages}
												updateOriginalData={updateOriginalData}
											/>
										</div>
								  ))
								: null}
							<Row style={{ justifyContent: 'flex-end' }}>
								<Col sm="auto">
									<Button
										type="submit"
										onClick={() => {
											emailAlerts
												? updateEmailAlerts(
														emailAlerts.concat({
															...emptyAlert,
															leaveAlertID: `new${emailAlerts.length}`,
															calendarID: props.currentCalendar.calendarID,
														})
												  )
												: updateEmailAlerts([
														{
															...emptyAlert,
															leaveAlertID: 'new0',
															calendarID: props.currentCalendar.calendarID,
														},
												  ])
										}}
									>
										{emailAlerts && emailAlerts.length !== 0 ? 'Add another Alert' : 'Add an Alert'}
									</Button>
								</Col>
							</Row>
						</Row>
					</FormGroup>
					<Messages.Messages messages={messages} updateMessage={updateMessages} />
				</>
			)
		} else {
			return (
				<div className="loading-gif">
					<img src={TAYGLoadingImage} alt="Loading ..." />
				</div>
			)
		}
	} else {
		return null
	}
}

export default EmailAlerts
