import React from 'react'
import { Form, Row, Col } from 'react-bootstrap'

// definitions
import { AccountXeroOrgWithData } from '../../../../screens/subsync/subsync'
import { StripeOrgAccountXeroOrg, StripeTaxRate, XeroAPIContact } from '../../../../../../back-end/utilities/apiDefinitions'
import { TaxAsLineItemSettings } from './TaxAsLineItemSettings'
import { TaxRateMapping } from './TaxRateMapping'

interface TaxSettingsProps {
	orgMapping: StripeOrgAccountXeroOrg | undefined
	mappedXeroOrg: AccountXeroOrgWithData | undefined
	stripeOrgID: string
	stripeTaxRates: StripeTaxRate[]
	handleSettingsUpdate: (stripeOrgID: string, values: { [name: string]: string | boolean | XeroAPIContact | undefined }) => void
	handleTaxRateUpdate: (stripeOrgID: string, stripeTaxRateID: string, name: string, value: string) => void
	handleTaxRateAdd: (stripeOrgID: string, stripeTaxRateID: string, name: string, value: string) => void
}

const TaxSettings = (props: TaxSettingsProps) => {
	return (
		<>
			<Form.Group as={Row} style={{ height: '2.5rem' }}>
				<Col>
					<Form.Label className="mr-3">Map Stripe taxes to:</Form.Label>
					<Form.Check
						inline
						type={'radio'}
						label={'Xero tax rates'}
						value={'Rates'}
						id={`${props.stripeOrgID}-mapping-type-rate`}
						checked={!props.orgMapping?.stripeOrgAccountXeroOrgSettings?.taxAsLineItem}
						onChange={(e) => props.handleSettingsUpdate(props.stripeOrgID, { taxAsLineItem: !e.target.checked })}
					/>
					<Form.Check
						inline
						type={'radio'}
						label={'Xero line item'}
						value={'Line'}
						id={`${props.stripeOrgID}-mapping-type-line`}
						checked={!!props.orgMapping?.stripeOrgAccountXeroOrgSettings?.taxAsLineItem}
						onChange={(e) => props.handleSettingsUpdate(props.stripeOrgID, { taxAsLineItem: e.target.checked })}
					/>
				</Col>
			</Form.Group>
			{props.orgMapping?.stripeOrgAccountXeroOrgSettings?.taxAsLineItem ? (
				<TaxAsLineItemSettings {...props} />
			) : (
				<>
					{props.stripeTaxRates.filter((tr) => tr.isStripeGenerated).length > 0 && <p className="font-weight-semi-bold ml-2 mt-3">Tax Mapping</p>}
					{props.stripeTaxRates
						.filter((tr) => !tr.isStripeGenerated)
						.map((taxRate) => {
							return (
								<TaxRateMapping
									key={taxRate.stripeTaxRateID}
									stripeTaxRate={taxRate}
									orgMapping={props.orgMapping!}
									xeroTaxRates={props.mappedXeroOrg!.taxRates}
									stripeOrgId={props.stripeOrgID!}
									handleTaxRateUpdate={props.handleTaxRateUpdate}
									handleTaxRateAdd={props.handleTaxRateAdd}
								/>
							)
						})}

					{props.stripeTaxRates.filter((tr) => tr.isStripeGenerated).length > 0 && (
						<>
							<p className="font-weight-semi-bold ml-2 mt-3">Stripe Generated Tax Mapping</p>
							{props.stripeTaxRates
								.filter((tr) => tr.isStripeGenerated)
								.map((taxRate) => {
									return (
										<TaxRateMapping
											key={taxRate.stripeTaxRateID}
											stripeTaxRate={taxRate}
											orgMapping={props.orgMapping!}
											xeroTaxRates={props.mappedXeroOrg!.taxRates}
											stripeOrgId={props.stripeOrgID!}
											handleTaxRateUpdate={props.handleTaxRateUpdate}
											handleTaxRateAdd={props.handleTaxRateAdd}
										/>
									)
								})}
						</>
					)}
				</>
			)}
		</>
	)
}

export { TaxSettings }
export type { TaxSettingsProps }
