import React from 'react'
import { Button, ButtonGroup, Col, Form, Row } from 'react-bootstrap'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'

import CloseButton from '../../components/ui/closeButton/closeButton'

import { Metric, MetricGroup, UserAccountSettings } from '../../../../back-end/utilities/apiDefinitions'
import { AppState } from '../../App.d'

import * as Request from '../../utilities/request'

type Units = 'percentage' | 'amount'

interface UpdateData {
	metricIDsToShow: string[]
	unitDifference: Units
	displayRows: number
}

interface SettingsBarProps {
	open: boolean
	onClose: () => void
	onDataUpdate: (updateData: UpdateData) => void
	metricGroups: MetricGroup[]
	metricSettings: UserAccountSettings[]
	tab: 'live' | 'pit'
	appState: AppState
	updateMetricSettings: (settings: UserAccountSettings[]) => void
}

const initialDisplayedMetricsState = (metricGroups: MetricGroup[], metricSettings: UserAccountSettings[]) =>
	metricGroups.reduce(
		(displayedMetrics: { [metricID: string]: boolean }, metricGroup: MetricGroup) => ({
			...displayedMetrics,
			...metricGroup.metrics!.reduce(
				(metrics: { [metricID: string]: boolean }, metric: Metric) => ({
					...metrics,
					[metric.metricID!]: metricSettings.find((ms) => ms.metric?.metricID === metric.metricID!)?.active || false,
				}),
				{}
			),
		}),
		{}
	)

const getInitialUnits = (fromStorage: string | null): Units => (fromStorage === 'percentage' ? 'percentage' : 'amount')
const getInitialRows = (fromStorage: string | null): number => (fromStorage ? parseInt(fromStorage) : -1)

const SettingsBar = (props: SettingsBarProps) => {
	const [displayedMetrics, updateDisplayedMetrics] = React.useState<{
		[metricID: string]: boolean
	}>(initialDisplayedMetricsState(props.metricGroups, props.metricSettings))
	const [units, updateUnits] = React.useState<Units>(getInitialUnits(localStorage.getItem(`units_${props.tab}`)))
	const [rows, updateRows] = React.useState<number>(getInitialRows(localStorage.getItem(`rows_${props.tab}`)))

	const onDataUpdate = props.onDataUpdate
	React.useEffect(() => {
		onDataUpdate({
			metricIDsToShow: Object.keys(displayedMetrics).reduce((idArray: string[], id: string) => (displayedMetrics[id] ? [...idArray, id] : idArray), []),
			unitDifference: units,
			displayRows: rows,
		})
	}, [displayedMetrics, units, rows]) // eslint-disable-line

	const updateMetricSettingsInDb = async (metricsToShow: string[]) => {
		if (props.appState.authState.isLoggedIn && props.metricSettings !== null) {
			const settings = createSettingsPostObject(props.metricSettings, metricsToShow)
			const req = await Request.put('setting', settings, props.appState.authState)
			const newSettings = req.data.userAccountSettings
			props.updateMetricSettings(newSettings)
		}
	}

	React.useEffect(() => {
		localStorage.setItem(`units_${props.tab}`, units)
		localStorage.setItem(`rows_${props.tab}`, rows > -1 ? rows.toString() : '-1')
	}, [units, rows]) // eslint-disable-line

	const getMetricGroupChecked = (metricGroupId: string): boolean => {
		const metricGroup = props.metricGroups.find((mg) => mg.metricGroupID === metricGroupId)
		if (metricGroup && metricGroup.metrics) {
			// for each metric in the metric group check if it is true in displayed metrics
			return metricGroup.metrics.every((m) => displayedMetrics[m.metricID!])
		}
		return false
	}
	const getMetricGroupIndeterminate = (metricGroupId: string): boolean => {
		const metricGroup = props.metricGroups.find((mg) => mg.metricGroupID === metricGroupId)
		if (metricGroup && metricGroup.metrics) {
			// for each metric in the metric group check if it is true in displayed metrics
			return metricGroup.metrics.some((m) => displayedMetrics[m.metricID!]) && metricGroup.metrics.some((m) => !displayedMetrics[m.metricID!])
		}
		return false
	}

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		let newDisplayedMetrics: { [metricID: string]: boolean } = {}
		if (event.target.name === 'metricGroupCheckbox') {
			// handle group mass de/select
			const metricGroup = props.metricGroups.find((mg) => mg.metricGroupID === event.target.value)
			if (metricGroup && metricGroup.metrics) {
				const metricIds = metricGroup.metrics.reduce((obj: { [metricID: string]: boolean }, metric) => {
					return {
						...obj,
						[metric.metricID!]: event.target.checked,
					}
				}, {})
				newDisplayedMetrics = { ...displayedMetrics, ...metricIds }
				updateDisplayedMetrics(newDisplayedMetrics)
			}
		} else if (event.target.name === 'metricCheckbox') {
			// handle individual metric de/select
			newDisplayedMetrics = {
				...displayedMetrics,
				[event.target.value]: !displayedMetrics[event.target.value],
			}
			updateDisplayedMetrics(newDisplayedMetrics)
		}
		updateMetricSettingsInDb(
			Object.keys(newDisplayedMetrics).reduce((idArray: string[], id: string) => (newDisplayedMetrics[id] ? [...idArray, id] : idArray), [])
		)
	}

	if (props.open) {
		return (
			<Row>
				<Col className="sidebar-overlay" onClick={props.onClose}></Col>
				<Col className="sidebar" xs="auto">
					<Row>
						<Col sm="auto">
							<h4>Settings</h4>
						</Col>
						<Col></Col>
						<Col sm="auto">
							<CloseButton onClick={props.onClose} disabled={false} />
						</Col>
					</Row>
					<Row>
						<Col>
							<Form.Label>Display</Form.Label>
							<Form.Control
								as="select"
								value={rows}
								onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
									updateRows(parseInt(event.target.value))
								}}
							>
								<option value={10}>10 rows</option>
								<option value={20}>20 rows</option>
								<option value={50}>50 rows</option>
								<option value={-1}>unlimited rows</option>
							</Form.Control>
						</Col>
					</Row>
					<Row>
						<Col>
							<Form.Label>Show differences as</Form.Label>
							<ButtonGroup style={{ display: 'flex' }}>
								<Button
									active={units === 'percentage'}
									onClick={() => {
										updateUnits('percentage')
									}}
								>
									% Percentage
								</Button>
								<Button
									active={units === 'amount'}
									onClick={() => {
										updateUnits('amount')
									}}
								>
									$ Amount
								</Button>
							</ButtonGroup>
						</Col>
					</Row>
					<Row>
						<Col>
							{props.metricGroups.map((mg) => (
								<React.Fragment key={mg.metricGroupID}>
									<FormControlLabel
										className="first-level-checkbox"
										control={
											<Checkbox
												value={mg.metricGroupID}
												indeterminate={getMetricGroupIndeterminate(mg.metricGroupID!)}
												checked={getMetricGroupChecked(mg.metricGroupID!)}
												onChange={handleChange}
												name="metricGroupCheckbox"
												color="primary"
												id={`metricGroupCheckBox-${mg.metricGroupID}`}
											/>
										}
										label={mg.name}
									/>
									{mg.metrics!.map((m) => (
										<FormControlLabel
											key={m.metricID}
											className="second-level-checkbox"
											control={
												<Checkbox
													name="metricCheckbox"
													value={m.metricID}
													checked={displayedMetrics[m.metricID!]}
													onChange={handleChange}
													color="primary"
													id={`metricCheckbox-${m.metricID}`}
												/>
											}
											label={m.name}
										/>
									))}
								</React.Fragment>
							))}
						</Col>
					</Row>
				</Col>
			</Row>
		)
	} else {
		return <div></div>
	}
}

const createSettingsPostObject = (metricSettings: UserAccountSettings[], metricsToShow: string[]) => {
	let metricsList = [...metricsToShow]
	// update all settings that already exist
	const updatedSettings = metricSettings.map((ms) => {
		const index = metricsList.indexOf(ms.metric?.metricID || '')
		const active = index >= 0
		metricsList = metricsList.filter((_m, i) => i !== index)
		return {
			...ms,
			active,
		}
	})
	// insert settings that don't yet exist
	const insertedSettings = metricsList.map((metricID) => ({
		active: true,
		metricID,
	}))
	return [...updatedSettings, ...insertedSettings]
}

export default SettingsBar
export type { UpdateData, Units }
