import React, { Dispatch, SetStateAction } from 'react'
import { Col, Row, Form, Container, Badge } from 'react-bootstrap'

import * as Request from '../../utilities/request'
import * as Format from '../../utilities/format'
import * as I from '../../utilities/me'
import * as Messages from '../../components/ui/messages/messages'
import * as AppDefinitions from '../../App.d'
import { AccountXeroOrg, AccountXeroOrgMetric } from '../../../../back-end/utilities/apiDefinitions'
import { ThresholdMetricSettings } from '../../components/ui/metricSettings/thresholdMetricSettings'
import { convertData, FormErrors, MetricsInGroups, ThresholdMetric, ThresholdMetricGroup } from '../../utilities/thresholds'
import { DataSetMetricGroupedByXeroOrg } from '../oversight/oversight.d'

interface OrgSettingsProps {
	authState: AppDefinitions.AuthState
	appState: AppDefinitions.AppState
	organisation: AccountXeroOrg
	updateReports?: Dispatch<SetStateAction<DataSetMetricGroupedByXeroOrg[] | null>>
}

const OrgSettings = (props: OrgSettingsProps) => {
	const [xeroOrgSettings, updateXeroOrgSettings] = React.useState<any>(null)
	const [thresholds, setThresholds] = React.useState<{ thresholdTemplateID: string; name: string }[]>([])
	const [xeroOrgMetrics, updateXeroOrgMetrics] = React.useState<MetricsInGroups>({})
	const [originalData, updateOriginalData] = React.useState<MetricsInGroups>({})
	const [errors, updateErrors] = React.useState<FormErrors>({})

	const [messages, updateMessages] = Messages.useMessageReducer([])

	React.useEffect(() => {
		const getSettings = async () => {
			const [allThresholds, metricGroups, orgMetrics, selectedTemplate] = await Promise.all([
				Request.get('threshold', props.authState),
				Request.get(`metricgroup?where=MetricDataType=in(INT,FLOAT)`, props.appState.authState),
				Request.get(`organisationmetric?where=AccountXeroOrgID==${props.organisation.accountXeroOrgID}`, props.authState),
				Request.get(`threshold/${props.organisation.thresholdTemplateID}`, props.appState.authState),
			])

			if (props.organisation.thresholdTemplateID !== null) {
				updateXeroOrgSettings({
					...props.organisation,
					selectedThresholdTemplate: selectedTemplate.data.thresholdTemplates[0],
				})
			} else {
				updateXeroOrgSettings({
					...props.organisation,
					thresholdTemplateID: '',
				})
			}
			setThresholds(
				allThresholds.data.thresholdTemplates.concat({
					thresholdTemplateID: '',
					name: 'None selected',
				})
			)
			const allMetrics = Object.keys(metricGroups.data.metricGroups)
				.map((key) => ({
					[key]: metricGroups.data.metricGroups[key].map((group: ThresholdMetricGroup) => ({
						...group,
						metrics: group.metrics!.map((metric: ThresholdMetric) => {
							const currMetric = orgMetrics.data.accountXeroOrgMetrics.find(
								(orgMetric: AccountXeroOrgMetric) => metric.metricID === orgMetric.metric!.metricID
							)
							if (currMetric) {
								return {
									...metric,
									goodValue: currMetric!.thresholdGood,
									badValue: currMetric!.thresholdBad,
									accountXeroOrgID: currMetric!.accountXeroOrg.id,
									accountXeroOrgMetricID: currMetric!.accountXeroOrgMetricID,
								}
							}
							return { ...metric, goodValue: null, badValue: null }
						}),
					})),
				}))
				.reduce((obj, item) => {
					obj[Object.keys(item)[0]] = item[Object.keys(item)[0]]
					return obj
				}, {})
			updateOriginalData(allMetrics)
			updateXeroOrgMetrics(allMetrics)
		}

		getSettings()
	}, [props.organisation, props.appState.authState, props.authState])

	const handleTemplateChange = async (eventKey: any) => {
		if (thresholds) {
			const threshold = thresholds.find((u) => u.thresholdTemplateID === eventKey.target.value)

			if (threshold) {
				try {
					const udpateValue = {
						thresholdTemplateID: threshold.thresholdTemplateID === '' ? null : threshold.thresholdTemplateID,
						accountXeroOrgID: xeroOrgSettings.accountXeroOrgID,
					}

					const [, newTemplate] = await Promise.all([
						Request.put(`organisation/${xeroOrgSettings.accountXeroOrgID}`, udpateValue, props.authState),
						Request.get(`threshold/${threshold.thresholdTemplateID}`, props.appState.authState),
					])

					updateXeroOrgSettings({
						...xeroOrgSettings,
						thresholdTemplateID: threshold.thresholdTemplateID,
						selectedThresholdTemplate: newTemplate.data.thresholdTemplates[0],
					})
					updateMessages(Messages.addMessage('success', 'Succesfully updated metric settings'))
				} catch (err) {
					console.log('Error Update Template: ', err)
					updateMessages(Messages.addMessage('danger', 'Failed to update metric settings'))
				}
			}
		}
	}

	const resetThreshold = async () => {
		updateErrors({}) //remove any previous errors
		updateXeroOrgMetrics(originalData)
	}

	if (thresholds.length > 0 && xeroOrgMetrics) {
		return (
			<Container>
				<Form.Group as={Row} controlId="templatePicker" style={{ paddingTop: 10 }}>
					<Form.Label column sm="auto">
						Use template:{' '}
					</Form.Label>
					<Col sm="auto">
						<Form.Control
							as="select"
							name={'templateSelect'}
							value={xeroOrgSettings.thresholdTemplateID}
							placeholder={'Select a value'}
							onChange={handleTemplateChange}
							readOnly={!I.have('admin', props.appState.permission[props.appState.context])}
							plaintext={!I.have('admin', props.appState.permission[props.appState.context])}
							disabled={!I.have('admin', props.appState.permission[props.appState.context])}
							className={!I.have('admin', props.appState.permission[props.appState.context]) ? 'no-hover' : ''}
						>
							{thresholds.map((u) => (
								<option key={u.thresholdTemplateID} value={u.thresholdTemplateID}>
									{u.name}
								</option>
							))}
						</Form.Control>
					</Col>
				</Form.Group>
				<Form.Group controlId="formThresholdTemplateName" as={Row}>
					<Form.Label column sm="3" style={{ fontWeight: 'bold' }}>
						Metric / Metric Group
					</Form.Label>
					<Form.Label column sm="2" style={{ textAlign: 'center' }}>
						<Badge className={`badge-diff`} pill variant="success">
							{' '}
						</Badge>
					</Form.Label>
					<Form.Label column sm="2" style={{ textAlign: 'center' }}>
						<Badge className={`badge-diff`} pill variant="warning">
							{' '}
						</Badge>
					</Form.Label>
					<Form.Label column sm="2" style={{ textAlign: 'center' }}>
						<Badge className={`badge-diff`} pill variant="danger">
							{' '}
						</Badge>
					</Form.Label>
				</Form.Group>
				{Object.keys(xeroOrgMetrics).map((key) =>
					xeroOrgMetrics[key].map((group: ThresholdMetricGroup, index: number) => (
						<React.Fragment key={index}>
							<Form.Group controlId="formThresholdTemplateName" as={Row}>
								<Form.Label column sm="3" style={{ fontWeight: 'bolder' }}>
									{group.name} ({Format.properCase(key)})
								</Form.Label>
							</Form.Group>
							{group.metrics!.map((metric: ThresholdMetric) => (
								<ThresholdMetricSettings
									key={metric.metricID}
									type="orgSettings"
									metric={metric}
									metricsArray={convertData(xeroOrgMetrics)}
									originalData={originalData}
									xeroOrgMetrics={xeroOrgMetrics}
									appState={props.appState}
									authState={props.authState}
									reset={resetThreshold}
									updateOriginalDataOrgSettings={updateOriginalData}
									updateMessages={updateMessages}
									updateXeroOrgMetrics={updateXeroOrgMetrics}
									selectedThresholdTemplate={
										xeroOrgSettings.thresholdTemplateID !== ''
											? xeroOrgSettings.selectedThresholdTemplate.metrics.find(
													(template: ThresholdMetric) => template.metricID === metric.metricID
											  )
											: null
									}
									updateErrors={updateErrors}
									errorMessage={errors[metric.metricID!]}
									updateReports={props.updateReports}
								/>
							))}
						</React.Fragment>
					))
				)}
				<Messages.Messages messages={messages} updateMessage={updateMessages} />
			</Container>
		)
	} else {
		return <div></div>
	}
}

export { OrgSettings }
