import React from 'react'
import { Button, Col, Container, Row, Spinner, Table } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom'

import * as Request from '../../utilities/request'
import * as I from '../../utilities/me'
import * as Messages from '../../components/ui/messages/messages'
import { User } from '../../../../back-end/common/users'
import { getPermissionProperty } from '../../utilities/permissions'
import LcLoadingImage from '../../images/Finlert-loading-GIF_100px.gif'
import OsLoadingImage from '../../images/3887_Oversight_100x100px.gif'
import TaygLoadingImage from '../../images/TAYG_100px.gif'
import SubSyncLoadingImage from '../../images/subSyncIcons/SubSync_100x100.gif'
import { AppContext } from '../../App'

const LoadingImages = {
	leavecal: LcLoadingImage,
	oversight: OsLoadingImage,
	tayg: TaygLoadingImage,
	subsync: SubSyncLoadingImage,
}

const inviteProducts = (user: User) => {
	return [
		user.osPermission && user.osPermission.permissionID ? 'oversight' : '',
		user.lcPermission && user.lcPermission.permissionID ? 'leavecal' : '',
		user.taygPermission && user.taygPermission.permissionID ? 'tayg' : '',
		user.subsyncPermission && user.subsyncPermission.permissionID ? 'subsync' : '',
	].filter((permission: string) => permission !== '')
}

const Users = () => {
	const { appState } = React.useContext(AppContext)
	const navigate = useNavigate()

	const [messages, updateMessages] = Messages.useMessageReducer([])
	const [users, setUsers] = React.useState<User[] | null>(null)
	const [lcButtonLoading, updateLcButtonLoading] = React.useState<boolean>(false)
	const [osButtonLoading, updateOsButtonLoading] = React.useState<boolean>(false)
	const [taygButtonLoading, updateTaygButtonLoading] = React.useState<boolean>(false)
	const [subsyncButtonLoading, updateSubsyncButtonLoading] = React.useState<boolean>(false)

	const getUsers = React.useCallback(async () => {
		const request = await Request.get('user', appState.authState)
		setUsers(request.data.users)
	}, [appState.authState])

	React.useEffect(() => {
		if (appState.authState.isLoggedIn) {
			getUsers()
		}
	}, [appState.authState, getUsers])

	const handleResendInvite = async (userID: string) => {
		if (I.have('admin', appState.permission[appState.context])) {
			try {
				const products = inviteProducts(users!.find((u) => u.userID === userID)!)
				const result = await Request.put(
					`user/${userID}/invite/${products.length > 1 ? products.join(',') : products[0]}`,
					{ resend: true },
					appState.authState
				)
				if (!result.data.success) {
					updateMessages(Messages.addMessage('danger', 'Unable to resend invite'))
				} else {
					updateMessages(Messages.addMessage('success', 'Invitation resent'))
					getUsers()
				}
			} catch (e) {
				console.log(e)
				updateMessages(Messages.addMessage('danger', 'Unable to resend invite'))
			}
		}
	}

	const hasLeavecal = users?.some((user) => user.lcPermission?.permissionID)
	const hasOversight = users?.some((user) => user.osPermission?.permissionID)
	const hasTayg = users?.some((user) => user.taygPermission?.permissionID)
	const hasSubsync = users?.some((user) => user.subsyncPermission?.permissionID)

	return (
		<Container className="oversight-container">
			<Row>
				<Col sm="auto">
					<h2>Users</h2>
				</Col>
				{I.have('admin', appState.permission[appState.context]) ? (
					<>
						<Col></Col>
						<Col sm="auto">
							<Button
								onClick={async () => {
									navigate('/user/new')
								}}
							>
								Invite User
							</Button>
						</Col>
					</>
				) : null}
			</Row>
			{users ? (
				<Row>
					<Col>
						<Table borderless responsive>
							<thead>
								<tr className="header-row">
									<th>Name</th>
									<th className={!hasLeavecal ? 'no-product-head leavecal-product' : ''}>LeaveCal</th>
									<th className={!hasOversight ? 'no-product-head oversight-product' : ''}>Oversight</th>
									<th className={!hasTayg ? 'no-product-head tayg-product' : ''}>Tax As You Go</th>
									<th className={!hasSubsync ? 'no-product-head subsync-product' : ''}>SubSync</th>
									<th></th>
								</tr>
							</thead>
							<tbody className={'card-table-body'}>
								{users
									? users.map((user, index) => (
											<tr key={user.userID} className={`stripe-row ${index % 2 === 0 ? 'stripe-row-body' : ''}`}>
												<td>
													{user.userID === appState.authState.attributes?.['custom:userId'] ||
													I.have('admin', appState.permission[appState.context]) ? (
														<Link to={`/user/${user.userID}`}>{`${user.firstName} ${user.lastName}`}</Link>
													) : (
														`${user.firstName} ${user.lastName}`
													)}
												</td>
												{hasLeavecal ? (
													<td>{user.lcPermission?.name}</td>
												) : index === 0 ? (
													<td rowSpan={users.length} className="no-product-body leavecal-product">
														<Button
															className="centered"
															disabled={lcButtonLoading}
															onClick={async () => {
																updateLcButtonLoading(true)
																await Request.put(
																	`user/${appState.authState.attributes?.['custom:userId']}`,
																	{
																		[getPermissionProperty('leavecal')]: I.Admin,
																	},
																	appState.authState
																)
																window.open(`${window.location.href.replace(appState.context, 'leavecal')}`, '_blank')
																document.body.click()
																updateLcButtonLoading(false)
															}}
														>
															Try LeaveCal {lcButtonLoading && <Spinner animation="border" />}
														</Button>
													</td>
												) : null}
												{hasOversight ? (
													<td>{user.osPermission?.name}</td>
												) : index === 0 ? (
													<td rowSpan={users.length} className="no-product-body oversight-product">
														<Button
															className="centered"
															disabled={osButtonLoading}
															onClick={async () => {
																updateOsButtonLoading(true)
																await Request.put(
																	`user/${appState.authState.attributes?.['custom:userId']}`,
																	{
																		[getPermissionProperty('oversight')]: I.Admin,
																	},
																	appState.authState
																)
																window.open(`${window.location.href.replace(appState.context, 'oversight')}`, '_blank')
																document.body.click()
																updateOsButtonLoading(false)
															}}
														>
															Try Oversight {osButtonLoading && <Spinner animation="border" />}
														</Button>
													</td>
												) : null}
												{hasTayg ? (
													<td>{user.taygPermission?.name}</td>
												) : index === 0 ? (
													<td rowSpan={users.length} className="no-product-body tayg-product">
														<Button
															className="centered"
															disabled={taygButtonLoading}
															onClick={async () => {
																updateTaygButtonLoading(true)
																await Request.put(
																	`user/${appState.authState.attributes?.['custom:userId']}`,
																	{ [getPermissionProperty('tayg')]: I.Admin },
																	appState.authState
																)
																window.open(`${window.location.href.replace(appState.context, 'tayg')}`, '_blank')
																document.body.click()
																updateTaygButtonLoading(false)
															}}
														>
															Try Tax As You Go {taygButtonLoading && <Spinner animation="border" />}
														</Button>
													</td>
												) : null}
												{hasSubsync ? (
													<td>{user.subsyncPermission?.name}</td>
												) : index === 0 ? (
													<td rowSpan={users.length} className="no-product-body subsync-product">
														<Button
															className="centered"
															disabled={taygButtonLoading}
															onClick={async () => {
																updateSubsyncButtonLoading(true)
																await Request.put(
																	`user/${appState.authState.attributes?.['custom:userId']}`,
																	{
																		[getPermissionProperty('subsync')]: I.Admin,
																	},
																	appState.authState
																)
																window.open(`${window.location.href.replace(appState.context, 'subsync')}`, '_blank')
																document.body.click()
																updateSubsyncButtonLoading(false)
															}}
														>
															Try Subsync {subsyncButtonLoading && <Spinner animation="border" />}
														</Button>
													</td>
												) : null}
												{!user.invitationAccepted ? (
													<td>
														<Button
															onClick={handleResendInvite.bind(null, user.userID!)}
															disabled={!I.have('admin', appState.permission[appState.context])}
														>
															Resend Invite
														</Button>
														{/* <Button onClick={handleRescindInvite} style={{ marginLeft: '5px' }}>Rescind Invite</Button> */}
													</td>
												) : (
													<td></td>
												)}
											</tr>
									  ))
									: null}
							</tbody>
							<tfoot>
								<tr>
									<td></td>
									<td className={!hasLeavecal ? 'no-product-foot leavecal-product' : ''}></td>
									<td className={!hasOversight ? 'no-product-foot oversight-product' : ''}></td>
									<td className={!hasTayg ? 'no-product-foot tayg-product' : ''}></td>
									{/* <td className={!hasSubsync ? 'no-product-foot subsync-product' : ''}></td> */}
								</tr>
							</tfoot>
						</Table>
					</Col>
				</Row>
			) : (
				<div className="loading-gif">
					<img src={LoadingImages[appState.context]} alt="Loading ..." />
				</div>
			)}
			<Messages.Messages messages={messages} updateMessage={updateMessages} />
		</Container>
	)
}

export default Users
