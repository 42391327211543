import React from 'react'
import * as AppDefinitions from '../../App.d'
import {
	AccountXeroOrg,
	Calendar,
	Calendar as CalendarData,
	CalendarWithBalances,
	EmployeeLeaveBalance,
	LeaveApplication,
	LeaveBalanceWithUpcoming,
	XeroOrg,
} from '../../../../back-end/utilities/apiDefinitions'

// utillites
import * as Request from '../../utilities/request'
// components
import * as Messages from '../../components/ui/messages/messages'
import moment from 'moment-timezone'
import { FormGroup, Row, Col, FormLabel, Table, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { Info } from 'react-feather'
import LoadingImage from '../../images/Finlert-loading-GIF_100px.gif'

const getTimezone = (version: string) => {
	return version === 'NZ' || version === 'NZONRAMP' ? 'Pacific/Auckland' : version === 'UK' || version === 'UKONRAMP' ? 'Europe/London' : 'Australia/Sydney'
}

const calculateUpcomingLeave = (totalFutureLeave: number, leaveApp: LeaveApplication) =>
	(totalFutureLeave += leaveApp.leavePeriod
		? leaveApp.leavePeriod.reduce((totalUnits, leavePeriod) => (totalUnits += Number(leavePeriod.numberOfUnits) || 0), 0)
		: 0)

const getCalendarsWithBalances = (
	calendar: Calendar,
	leave: LeaveApplication[],
	leaveBalance: EmployeeLeaveBalance[],
	version: string
): CalendarWithBalances => {
	const todaysDate = new Date()
	const endOfMonth = new Date(todaysDate.getFullYear(), todaysDate.getMonth() + 1, 0)
	const threeMonthsAgo = new Date(moment().tz(getTimezone(version)).subtract(3, 'months').format('YYYY-MM-DD'))

	return {
		...calendar,
		employees: calendar.employees
			? calendar.employees
					.map((emp) => {
						// get all leaves of employee and find the most recent one for days since last break
						const employeeLeave = leave
							.filter((leaveApp) => emp.employeeID === leaveApp.employeeID)
							.sort((leaveA, leaveB) => new Date(leaveB.endDate!).valueOf() - new Date(leaveA.endDate!).valueOf())
						const lastLeave = employeeLeave.find((leave) => new Date(leave.startDate!) <= todaysDate && !leave.isNonXero)
						const hasTakenLeave = lastLeave !== undefined

						const mostRecentLeaveDate = new Date(lastLeave ? lastLeave!.endDate! : emp.startDate!).getTime()
						const daysSinceLastLeave = Math.round((todaysDate.getTime() - mostRecentLeaveDate) / (24 * 60 * 60 * 1000)) //converting miliseconds to days

						// filter the employee leaves so it can only contain the leaves of the past three months and proceed with the calculations
						const last3MonthsEmployeeLeave = employeeLeave.filter((leaveApp) => new Date(leaveApp.startDate!) >= threeMonthsAgo)
						const futureLeave = last3MonthsEmployeeLeave.filter((leaveApp) => new Date(leaveApp.startDate!) > endOfMonth)
						const byEndOfMonthLeave = last3MonthsEmployeeLeave.filter(
							(leaveApp) => new Date(leaveApp.startDate!) >= todaysDate && new Date(leaveApp.startDate!) <= endOfMonth
						)
						const recentLeave = last3MonthsEmployeeLeave.filter((leaveApp) => new Date(leaveApp.startDate!) < todaysDate)
						const associatedBalances = leaveBalance.filter((leaveBalance) => emp.employeeID === leaveBalance.employeeID)

						return {
							...emp,
							hasTakenLeave: hasTakenLeave,
							daysSinceLastLeave: daysSinceLastLeave,
							leaveBalance: associatedBalances
								.filter((bal) => calendar.leaveTypes?.some((calLT) => calLT.xeroOrgLeaveTypeID === bal.xeroOrgLeaveTypeID))
								.map((bal) => {
									const leave = futureLeave.filter((leaveApp) => leaveApp.xeroOrgLeaveTypeID === bal.xeroOrgLeaveTypeID)
									const recent = recentLeave.filter((leaveApp) => leaveApp.xeroOrgLeaveTypeID === bal.xeroOrgLeaveTypeID)
									const byEOMleave = byEndOfMonthLeave.filter((leaveApp) => leaveApp.xeroOrgLeaveTypeID === bal.xeroOrgLeaveTypeID)

									return {
										...bal,
										upcoming: byEOMleave.reduce(calculateUpcomingLeave, 0), //till the end of month
										recent: recent.reduce(calculateUpcomingLeave, 0), //past 3 months
										futureAmount: leave.length, //number of requests beyond EOM
										future: leave.reduce(calculateUpcomingLeave, 0), //number of hours till EOM
									}
								})
								.sort((a, b) => a.leaveTypeName!.localeCompare(b.leaveTypeName!)),
						}
					})
					.filter((emp) => emp.leaveBalance.length > 0)
					.sort((a, b) => (a.firstName === b.firstName ? a.lastName!.localeCompare(b.lastName!) : a.firstName!.localeCompare(b.firstName!)))
			: [],
	}
}
interface EmployeesLeaveBalancesFrameProps {
	show: boolean
	appState: AppDefinitions.AppState
	currentOrg: AccountXeroOrg
	currentCalendar: CalendarData
	pageStatus: string
	updatePageStatus: React.Dispatch<React.SetStateAction<string>>
}

const displayLeaveBalance = (value: number, unitType: string, showInDays: boolean, hoursInDay: number) => {
	if (unitType.toLowerCase() === 'hours') {
		if (showInDays) {
			const days = parseFloat(Math.fround(value / hoursInDay).toFixed(2))
			if (days >= 1 || days <= -1) {
				return `${days} day${days > 1 || days < -1 ? 's' : ''}`
			} else {
				return `${value} days`
			}
		}
	}
	return `${value} ${unitType.toLowerCase()}`
}

const EmployeeBalance = (employee: {
	alias?: string | undefined
	firstName?: string | undefined
	lastName?: string | undefined
	jobTitle?: string | undefined
	showTitle: boolean
	startDate?: moment.MomentInput
	hasTakenLeave?: boolean | undefined
	daysSinceLastLeave: number
	leaveBalance: LeaveBalanceWithUpcoming[]
	xeroOrg: XeroOrg
}) => {
	return (
		<div style={{ backgroundColor: 'white', paddingBottom: '20px' }}>
			<Row>
				<Col>
					<Row style={{ padding: '30px 0px 0px, 20px', marginLeft: '10px' }}>
						<FormLabel style={{ fontWeight: 'bold', fontSize: 'larger' }}>
							{employee.alias ? employee.alias : `${employee.firstName} ${employee.lastName}`}{' '}
							{employee.jobTitle && employee.showTitle ? `- ${employee.jobTitle} ` : ''}
						</FormLabel>
					</Row>
				</Col>
				<Col
					style={{
						textAlign: 'right',
						padding: '30px 30px 0px, 20px',
						marginRight: '10px',
					}}
				>
					<span>{`(start date ${moment.utc(employee.startDate).format('DD-MM-YYYY')})`}</span>
				</Col>
			</Row>
			<Row style={{ padding: '20px 0px 0px, 20px', marginLeft: '10px' }}>
				<span>
					{!employee.hasTakenLeave
						? `${employee.alias ? employee.alias : employee.firstName} has not taken leave in the last 3 months`
						: employee.daysSinceLastLeave <= 0
						? `${employee.alias ? employee.alias : employee.firstName} is currently on break`
						: `it's been ${employee.daysSinceLastLeave} day(s) since ${employee.alias ? employee.alias : employee.firstName} had a break`}
				</span>
			</Row>
			<hr />
			<Table borderless responsive className="no-wrap" style={{ border: '0px', marginLeft: '10px' }}>
				<thead>
					<tr className="stripe-row">
						<th></th>
						<th className="header-sticky-top" style={{ fontWeight: 'bold' }}>
							Recent
						</th>
						<th className="header-sticky-top" style={{ fontWeight: 'bold' }}>
							Today&apos;s Balance
						</th>
						<th className="header-sticky-top" style={{ fontWeight: 'bold' }}>
							Upcoming
						</th>
						<th className="header-sticky-top" style={{ fontWeight: 'bold' }}>
							Net
						</th>
						<th className="header-sticky-top" style={{ fontWeight: 'bold' }}>
							Future
						</th>
						<th className="header-sticky-top" style={{ fontWeight: 'bold' }}>
							No. Requests
						</th>
					</tr>
				</thead>
				<tbody>
					{employee.leaveBalance.map((leaveBalance: LeaveBalanceWithUpcoming) => (
						<tr key={leaveBalance.leaveTypeName} style={{ border: '0px' }}>
							<td>{`${leaveBalance.leaveTypeName}${leaveBalance.leaveTypeUnitType === 'Days' ? ' (Days)' : ''}`}</td>
							<td>
								{displayLeaveBalance(
									parseFloat(leaveBalance.recent.toFixed(2)),
									leaveBalance.leaveTypeUnitType!,
									employee.xeroOrg.showLeaveInDays!,
									employee.xeroOrg.hoursInDay!
								)}
							</td>
							<td>
								{displayLeaveBalance(
									parseFloat(leaveBalance.value!.toFixed(2)),
									leaveBalance.leaveTypeUnitType!,
									employee.xeroOrg.showLeaveInDays!,
									employee.xeroOrg.hoursInDay!
								)}
							</td>
							<td>
								{displayLeaveBalance(
									parseFloat(leaveBalance.upcoming.toFixed(2)),
									leaveBalance.leaveTypeUnitType!,
									employee.xeroOrg.showLeaveInDays!,
									employee.xeroOrg.hoursInDay!
								)}
							</td>
							<td>
								{displayLeaveBalance(
									parseFloat((leaveBalance.value! - leaveBalance.upcoming).toFixed(2)),
									leaveBalance.leaveTypeUnitType!,
									employee.xeroOrg.showLeaveInDays!,
									employee.xeroOrg.hoursInDay!
								)}
							</td>
							<td>
								{displayLeaveBalance(
									parseFloat(leaveBalance.future.toFixed(2)),
									leaveBalance.leaveTypeUnitType!,
									employee.xeroOrg.showLeaveInDays!,
									employee.xeroOrg.hoursInDay!
								)}
							</td>
							<td>{parseFloat(leaveBalance.futureAmount.toFixed(2))}</td>
						</tr>
					))}
				</tbody>
			</Table>
		</div>
	)
}

const EmployeesLeaveBalances = (props: EmployeesLeaveBalancesFrameProps) => {
	const [balances, updateBalances] = React.useState<CalendarWithBalances>({
		employees: [],
	})
	const [messages, updateMessages] = Messages.useMessageReducer([])

	React.useEffect(
		() => {
			const fetchData = async () => {
				const uniqueEmployees = props.currentCalendar.employees!.filter((e) => e.isActive).map((e) => e.employeeID!)
				const [leaveData, leaveBalanceData] = await Promise.all([
					Request.get(`leave?where=EmployeeID=in(${uniqueEmployees.join(',')})%2BLeaveApplicationIsNonXero==0&minify`, props.appState.authState), //%2BLeaveApplicationStartDate>=${threeMonthsAgo}
					Request.get(`leavebalance?where=EmployeeID=in(${uniqueEmployees.join(',')})`, props.appState.authState),
				])
				const currentCalBalances = getCalendarsWithBalances(
					props.currentCalendar,
					leaveData.data.leaveApplications,
					leaveBalanceData.data.employeeLeaveBalances,
					props.currentOrg!.xeroOrg!.version!
				)
				updateBalances(currentCalBalances)
				if (props.show) {
					props.updatePageStatus('Finished')
				}
			}

			if (props.currentCalendar.calendarID !== '') {
				fetchData()
			}
		},
		// eslint-disable-next-line
		[props.appState.authState, props.currentCalendar.calendarID, props.currentOrg, props.currentCalendar]
	)

	if (props.show) {
		if (props.pageStatus === 'Finished') {
			return (
				<>
					<FormGroup style={{ padding: '30px' }}>
						<Row className="calendar-alerts-card ">
							<Col>
								<Row style={{ paddingLeft: '20px' }}>
									<FormLabel className="emails-label" style={{ paddingTop: '20px' }}>
										Leave Balances
									</FormLabel>
									<Col sm="auto" className="position-right" style={{ color: '#7c7e80', paddingTop: '21px' }}>
										<OverlayTrigger
											placement="auto"
											overlay={
												<Tooltip id={`tooltip-leave-balance`} style={{ maxWidth: '100%' }}>
													<div style={{ textAlign: 'left', width: '100%' }}>
														Leave Balance definitions:
														<p className="tooltip-style">&middot; Recent: The amount in the past 3 months.</p>
														<p className="tooltip-style">
															&middot; Today&apos;s Balance: The total amount until the end of the month.
														</p>
														<p className="tooltip-style">&middot; Upcoming: The amount from today until the end of the month.</p>
														<p className="tooltip-style">&middot; Net: The total amount excluding upcoming.</p>
														<p className="tooltip-style">&middot; Future: The amount beyond the end of the month.</p>
														<p className="tooltip-style">
															&middot; No. Requests: The number of requests beyond the end of the month.
														</p>
													</div>
												</Tooltip>
											}
										>
											<div>
												<Info />
											</div>
										</OverlayTrigger>
									</Col>
								</Row>
							</Col>

							<Row
								style={{
									width: '100%',
									padding: '20px',
									marginLeft: '0px',
									display: 'inline',
								}}
							>
								{balances && balances.employees && balances.employees!.length !== 0 ? (
									balances.employees.map((emp) => (
										<EmployeeBalance
											key={emp.employeeID}
											{...emp}
											showTitle={props.currentCalendar.showTitle!}
											xeroOrg={props.currentOrg.xeroOrg!}
										/>
									))
								) : (
									<Row
										style={{
											marginTop: '-5px',
											paddingLeft: '20px',
											backgroundColor: 'white',
											paddingTop: '20px',
										}}
									>
										<p>No leave balances available.</p>
									</Row>
								)}
							</Row>
						</Row>
					</FormGroup>
					<Messages.Messages messages={messages} updateMessage={updateMessages} />
				</>
			)
		} else {
			return (
				<div className="loading-gif">
					<img src={LoadingImage} alt="Loading ..." />
				</div>
			)
		}
	} else {
		return null
	}
}

export default EmployeesLeaveBalances
