import { Checkbox, FormControlLabel } from '@material-ui/core'
import React from 'react'
import { Row, FormLabel, Col, FormControl, Form, Button, OverlayTrigger, Tooltip, FormGroup } from 'react-bootstrap'
import { X } from 'react-feather'
import { AccountXeroOrg, Calendar, LeaveAlert } from '../../../../back-end/utilities/apiDefinitions'
import * as yup from 'yup'

// definitions
import * as AppDefinitions from '../../App.d'
import { MessageAction } from '../../components/ui/messages/message'
import { LeaveBalance as LeaveBalanceProduct } from '../../constants/products'

// components
import * as Messages from '../../components/ui/messages/messages'
import LoadingImage from '../../images/Finlert-loading-GIF_100px.gif'

// utillites
import * as Request from '../../utilities/request'
interface EmailAlertsFrameProps {
	show: boolean
	appState: AppDefinitions.AppState
	currentOrg: AccountXeroOrg
	currentCalendar: Calendar
	pageStatus: string
	updatePageStatus: React.Dispatch<React.SetStateAction<string>>
}

export interface AlertCardProps {
	alert: LeaveAlert
	appState: AppDefinitions.AppState
	reset: () => void
	updateMessages: (message: MessageAction) => void
	updateOriginalData: (alert: LeaveAlert[]) => void
	removeAlert: (alert: LeaveAlert) => void
}

interface EmailAlertCardProps extends AlertCardProps {
	hasLeaveBalanceProduct: boolean
	emailAlerts: LeaveAlert[]
	updateEmailAlerts: (alert: LeaveAlert[]) => void
}

const emptyAlert: LeaveAlert = {
	leaveAlertID: '',
	recipientEmail: '',
	recipientName: '',
	isWeekly: false,
	isMonthly: false,
	isDaily: false,
	isDailyWeekdays: false,
	isOnApproval: false,
	isOnRequest: false,
	isMonthlyLeaveBalance: false,
	isTwiceMonthlyLeaveBalance: false,
	isSlack: false,
	calendarID: '',
}

const ConstructAlertCard = (props: EmailAlertCardProps) => {
	const [isEditing, updateIsEditing] = React.useState<boolean>(props.alert.leaveAlertID!.includes('new') ? true : false)
	const [isSubmitting, updateIsSubmitting] = React.useState<boolean>(false)

	// ---- Validation Schema ---- //
	const schema = yup.object().shape(
		{
			recipientEmail: yup
				.string()
				.matches(/^[\W]*([\w+\-.%]+@[\w\-.]+\.[A-Za-z]{2,4}[\W]*,{1}[\W]*)*([\w+\-.%]+@[\w\-.]+\.[A-Za-z]{2,63})[\W]*$/, 'Invalid email format.')
				.required('Email is required.'), //.email('Enter a valid email.')
			recipientName: yup.string().required('Name is required.'),
			note: yup.string().nullable().optional().max(1000, 'Note cannot exceed 1000 characters.'),
			isWeekly: yup
				.boolean()
				.when(['isDaily', 'isOnApproval', 'isOnRequest', 'isMonthly', 'isMonthlyLeaveBalance', 'isTwiceMonthlyLeaveBalance', 'isDailyWeekdays'], {
					is: (
						isDaily: boolean,
						isOnApproval: boolean,
						isOnRequest: boolean,
						isMonthly: boolean,
						isMonthlyLeaveBalance: boolean,
						isTwiceMonthlyLeaveBalance: boolean,
						isDailyWeekdays: boolean
					) => !isDaily && !isOnApproval && !isOnRequest && !isMonthly && !isMonthlyLeaveBalance && !isTwiceMonthlyLeaveBalance && !isDailyWeekdays,
					then: yup.boolean().required('One or more types must be accepted.').oneOf([true], 'One or more types must be accepted.'),
				}),
			isMonthly: yup
				.boolean()
				.when(['isDaily', 'isWeekly', 'isOnApproval', 'isOnRequest', 'isMonthlyLeaveBalance', 'isTwiceMonthlyLeaveBalance', 'isDailyWeekdays'], {
					is: (
						isDaily: boolean,
						isOnApproval: boolean,
						isOnRequest: boolean,
						isWeekly: boolean,
						isMonthlyLeaveBalance: boolean,
						isTwiceMonthlyLeaveBalance: boolean,
						isDailyWeekdays: boolean
					) => !isDaily && !isOnApproval && !isOnRequest && !isWeekly && !isMonthlyLeaveBalance && !isTwiceMonthlyLeaveBalance && !isDailyWeekdays,
					then: yup.boolean().required('One or more types must be accepted.').oneOf([true], 'One or more types must be accepted.'),
				}),
			isDaily: yup
				.boolean()
				.when(['isWeekly', 'isOnApproval', 'isOnRequest', 'isMonthly', 'isMonthlyLeaveBalance', 'isTwiceMonthlyLeaveBalance', 'isDailyWeekdays'], {
					is: (
						isWeekly: boolean,
						isOnApproval: boolean,
						isOnRequest: boolean,
						isMonthly: boolean,
						isMonthlyLeaveBalance: boolean,
						isTwiceMonthlyLeaveBalance: boolean,
						isDailyWeekdays: boolean
					) => !isWeekly && !isOnApproval && !isOnRequest && !isMonthly && !isMonthlyLeaveBalance && !isTwiceMonthlyLeaveBalance && !isDailyWeekdays,
					then: yup.boolean().required('One or more types must be accepted.').oneOf([true], 'One or more types must be accepted.'),
				}),
			isDailyWeekdays: yup
				.boolean()
				.when(['isWeekly', 'isDaily', 'isOnApproval', 'isOnRequest', 'isMonthly', 'isMonthlyLeaveBalance', 'isTwiceMonthlyLeaveBalance'], {
					is: (
						isWeekly: boolean,
						isDaily: boolean,
						isOnApproval: boolean,
						isOnRequest: boolean,
						isMonthly: boolean,
						isMonthlyLeaveBalance: boolean,
						isTwiceMonthlyLeaveBalance: boolean
					) => !isWeekly && !isOnApproval && !isOnRequest && !isMonthly && !isMonthlyLeaveBalance && !isDaily && !isTwiceMonthlyLeaveBalance,
					then: yup.boolean().required('One or more types must be accepted.').oneOf([true], 'One or more types must be accepted.'),
				}),
			isOnApproval: yup
				.boolean()
				.when(['isWeekly', 'isDaily', 'isMonthly', 'isOnRequest', 'isMonthlyLeaveBalance', 'isTwiceMonthlyLeaveBalance', 'isDailyWeekdays'], {
					is: (
						isWeekly: boolean,
						isDaily: boolean,
						isOnRequest: boolean,
						isMonthly: boolean,
						isMonthlyLeaveBalance: boolean,
						isTwiceMonthlyLeaveBalance: boolean,
						isDailyWeekdays: boolean
					) => !isWeekly && !isDaily && !isOnRequest && !isMonthly && !isMonthlyLeaveBalance && !isTwiceMonthlyLeaveBalance && !isDailyWeekdays,
					then: yup.boolean().required('One or more types must be accepted.').oneOf([true], 'One or more types must be accepted.'),
				}),
			isMonthlyLeaveBalance: yup
				.boolean()
				.when(['isMonthly', 'isWeekly', 'isDaily', 'isOnApproval', 'isOnRequest', 'isTwiceMonthlyLeaveBalance', 'isDailyWeekdays'], {
					is: (
						isMonthly: boolean,
						isWeekly: boolean,
						isDaily: boolean,
						isOnApproval: boolean,
						isOnRequest: boolean,
						isTwiceMonthlyLeaveBalance: boolean,
						isDailyWeekdays: boolean
					) => !isMonthly && !isWeekly && !isDaily && !isOnApproval && !isOnRequest && !isTwiceMonthlyLeaveBalance && !isDailyWeekdays,
					then: yup.boolean().required('One or more types must be accepted.').oneOf([true], 'One or more types must be accepted.'),
				}),
			isTwiceMonthlyLeaveBalance: yup
				.boolean()
				.when(['isMonthly', 'isWeekly', 'isDaily', 'isOnApproval', 'isOnRequest', 'isMonthlyLeaveBalance', 'isDailyWeekdays'], {
					is: (
						isMonthly: boolean,
						isWeekly: boolean,
						isDaily: boolean,
						isOnApproval: boolean,
						isOnRequest: boolean,
						isMonthlyLeaveBalance: boolean,
						isDailyWeekdays: boolean
					) => !isMonthly && !isWeekly && !isDaily && !isOnApproval && !isOnRequest && !isMonthlyLeaveBalance && !isDailyWeekdays,
					then: yup.boolean().required('One or more types must be accepted.').oneOf([true], 'One or more types must be accepted.'),
				}),
			isOnRequest: yup
				.boolean()
				.when(['isMonthly', 'isWeekly', 'isDaily', 'isOnApproval', 'isMonthlyLeaveBalance', 'isDailyWeekdays', 'isTwiceMonthlyLeaveBalance'], {
					is: (
						isMonthly: boolean,
						isWeekly: boolean,
						isDaily: boolean,
						isOnApproval: boolean,
						isTwiceMonthlyLeaveBalance: boolean,
						isMonthlyLeaveBalance: boolean,
						isDailyWeekdays: boolean
					) => !isMonthly && !isWeekly && !isDaily && !isOnApproval && !isTwiceMonthlyLeaveBalance && !isMonthlyLeaveBalance && !isDailyWeekdays,
					then: yup.boolean().required('One or more types must be accepted.').oneOf([true], 'One or more types must be accepted.'),
				}),
		},
		[
			['isDailyWeekdays', 'isMonthlyLeaveBalance'],
			['isDailyWeekdays', 'isTwiceMonthlyLeaveBalance'],
			['isDailyWeekdays', 'isWeekly'],
			['isDailyWeekdays', 'isMonthly'],
			['isDailyWeekdays', 'isDaily'],
			['isDailyWeekdays', 'isOnApproval'],
			['isWeekly', 'isDaily'],
			['isWeekly', 'isOnApproval'],
			['isDaily', 'isOnApproval'],
			['isDaily', 'isMonthly'],
			['isOnApproval', 'isMonthly'],
			['isWeekly', 'isMonthly'],
			['isMonthlyLeaveBalance', 'isMonthly'],
			['isMonthlyLeaveBalance', 'isWeekly'],
			['isMonthlyLeaveBalance', 'isDaily'],
			['isMonthlyLeaveBalance', 'isOnApproval'],
			['isTwiceMonthlyLeaveBalance', 'isMonthly'],
			['isTwiceMonthlyLeaveBalance', 'isWeekly'],
			['isTwiceMonthlyLeaveBalance', 'isDaily'],
			['isTwiceMonthlyLeaveBalance', 'isOnApproval'],
			['isTwiceMonthlyLeaveBalance', 'isMonthlyLeaveBalance'],
			['isOnRequest', 'isOnApproval'],
			['isOnRequest', 'isDaily'],
			['isOnRequest', 'isWeekly'],
			['isOnRequest', 'isMonthly'],
			['isOnRequest', 'isMonthlyLeaveBalance'],
			['isOnRequest', 'isTwiceMonthlyLeaveBalance'],
			['isOnRequest', 'isDailyWeekdays'],
		]
	)

	const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault()
		try {
			try {
				await schema.validate(props.alert, { abortEarly: false })
			} catch (validationError) {
				if (validationError instanceof yup.ValidationError) {
					const newErrors = validationError.inner.map((err) => err.errors.join('\n'))
					props.updateMessages({
						type: 'add',
						data: {
							severity: 'danger',
							message: `Unable to update Email Alerts: \n\n ${newErrors
								.filter((val: string, id: number, array: string | string[]) => array.indexOf(val) === id)
								.join('\n')}`,
							timeout: 3000,
							dismissible: true,
						},
					})
					return
				}
			}
			updateIsSubmitting(true)

			const req = await (props.alert.leaveAlertID!.includes('new')
				? Request.post('alert', props.alert, props.appState.authState)
				: Request.put(`alert/${props.alert.leaveAlertID}`, props.alert, props.appState.authState))

			if (req.data.success) {
				props.updateMessages({
					type: 'add',
					data: {
						severity: 'success',
						message: 'Successfully updated Email Alerts',
						timeout: 3000,
						dismissible: true,
					},
				})
				const newAlerts = props.emailAlerts!.map((al: LeaveAlert) => {
					if (al.leaveAlertID?.includes('new')) {
						return req.data.leaveAlerts[0]
					}
					return al
				})
				props.updateEmailAlerts(newAlerts)
				props.updateOriginalData(newAlerts)
			} else {
				props.updateMessages({
					type: 'add',
					data: {
						severity: 'danger',
						message: 'Unable to update Email Alerts',
						timeout: 3000,
						dismissible: true,
					},
				})
			}

			updateIsSubmitting(false)
			updateIsEditing(false)
		} catch (error) {
			console.log('Error: ', error, props.appState.authState)
		}
	}

	const handleEmailChange = (event: { preventDefault: () => void; target: { value: string } }) => {
		event.preventDefault()
		const value = event.target.value

		const newAlerts = props.emailAlerts!.map((al: LeaveAlert) => {
			if (al.leaveAlertID === props.alert.leaveAlertID) {
				return { ...al, recipientEmail: value }
			}
			return al
		})
		props.updateEmailAlerts(newAlerts)
	}

	const handleNameChange = (event: { preventDefault: () => void; target: { value: string } }) => {
		event.preventDefault()
		const value = event.target.value

		const newAlerts = props.emailAlerts!.map((al: LeaveAlert) => {
			if (al.leaveAlertID === props.alert.leaveAlertID) {
				return { ...al, recipientName: value }
			}
			return al
		})
		props.updateEmailAlerts(newAlerts)
	}

	const checkIfAllSelected = (alert: LeaveAlert) => {
		//to add the other options in the future as possible types
		return alert.isWeekly && alert.isOnApproval && alert.isDaily && alert.isMonthly && alert.isDailyWeekdays
	}

	const handleTypeChange = async (option: string) => {
		const newAlerts = props.emailAlerts.map((al: LeaveAlert) => {
			if (al.leaveAlertID === props.alert.leaveAlertID) {
				if (option === 'all' && checkIfAllSelected(props.alert)) {
					return { ...al, isWeekly: false, isDaily: false, isOnApproval: false, isMonthly: false, isDailyWeekdays: false }
				} else if (option === 'all' && !checkIfAllSelected(props.alert)) {
					return { ...al, isWeekly: true, isDaily: true, isOnApproval: true, isMonthly: true, isDailyWeekdays: true }
				} else if (option === 'daily') {
					return { ...al, isDaily: !al.isDaily }
				} else if (option === 'dailyWeekdays') {
					return { ...al, isDailyWeekdays: !al.isDailyWeekdays }
				} else if (option === 'weekly') {
					return { ...al, isWeekly: !al.isWeekly }
				} else if (option === 'monthly') {
					return { ...al, isMonthly: !al.isMonthly }
				} else if (option === 'onDemand') {
					return { ...al, isOnApproval: !al.isOnApproval }
				} else if (option === 'onRequest') {
					return { ...al, isOnRequest: !al.isOnRequest }
				} else if (option === 'monthlyLeaveBalance') {
					return { ...al, isMonthlyLeaveBalance: !al.isMonthlyLeaveBalance }
				} else if (option === 'twiceMonthlyLeaveBalance') {
					return { ...al, isTwiceMonthlyLeaveBalance: !al.isTwiceMonthlyLeaveBalance }
				}
			}
			return al
		})

		props.updateEmailAlerts(newAlerts)
	}

	const handleNoteChange = (event: { preventDefault: () => void; target: { value: string } }) => {
		event.preventDefault()
		const value = event.target.value

		const newAlerts = props.emailAlerts.map((al: LeaveAlert) => {
			if (al.leaveAlertID === props.alert.leaveAlertID) {
				return { ...al, note: value }
			}
			return al
		})
		props.updateEmailAlerts(newAlerts)
	}

	return (
		<div className={`form ${isEditing ? 'editing' : ''}`} onClick={() => updateIsEditing(true)}>
			<Form onSubmit={handleSubmit} className="needs-validation">
				<Row className="leavecal-wrap-style">
					<Col sm="2" style={{ marginTop: '15px', textAlign: 'right' }}>
						<FormLabel>Recipient Name:</FormLabel>
					</Col>
					<Col sm="5">
						<FormControl
							type={'text'}
							style={{ marginTop: '7px', textAlign: 'left' }}
							value={props.alert.recipientName}
							readOnly={!isEditing}
							onClick={() => updateIsEditing(true)} //I.have('admin', props.appState.permission[props.appState.context]) &&
							onChange={handleNameChange}
							name={'recipientName'}
						/>
					</Col>
					<Col sm="auto" style={{ flex: '1', textAlign: 'right' }}>
						<OverlayTrigger placement="auto" overlay={<Tooltip id={`tooltip-remove-alert`}>Remove email alert.</Tooltip>}>
							<X
								style={{ cursor: 'pointer', paddingTop: '5px', width: '30px', height: '30px', textAlign: 'right' }}
								onClick={() => props.removeAlert(props.alert)}
							/>
						</OverlayTrigger>
					</Col>
				</Row>
				<Row className="leavecal-wrap-style">
					<Col sm="2" style={{ marginTop: '15px', textAlign: 'right' }}>
						<FormLabel>Recipient Email Address(es):</FormLabel>
					</Col>
					<Col sm="5">
						<textarea
							className="form-control"
							style={{ marginTop: '7px', textAlign: 'left', resize: 'none' }}
							rows={
								(props.alert.recipientEmail!.match(/,/g) || []).length > 1
									? Math.floor((props.alert.recipientEmail!.match(/,/g) || []).length / 3) + 1
									: 1
							}
							value={props.alert.recipientEmail}
							readOnly={!isEditing}
							onClick={() => updateIsEditing(true)} //I.have('admin', props.appState.permission[props.appState.context]) &&
							onChange={handleEmailChange}
							name={'recipientEmail'}
						/>
					</Col>
				</Row>
				<Row className="leavecal-wrap-style">
					<Col sm="2" style={{ marginTop: '15px', textAlign: 'right' }}>
						<FormLabel>Alert Notes:</FormLabel>
					</Col>
					<Col sm="5">
						<Form.Group style={{ marginBottom: '0px' }}>
							<OverlayTrigger
								placement={'right'}
								overlay={<Tooltip id={`tooltip-remove-alert`}>Adds a note to the alert for everyone to see.</Tooltip>}
							>
								<Form.Control
									as="textarea"
									className="form-control"
									value={props.alert.note}
									style={{ marginTop: '7px', textAlign: 'left', resize: 'none' }}
									rows={3}
									readOnly={!isEditing}
									onClick={() => updateIsEditing(true)}
									onChange={handleNoteChange}
									name={'alertNote'}
								/>
							</OverlayTrigger>
						</Form.Group>
					</Col>
				</Row>
				<Row className="leavecal-wrap-style">
					<Col sm="2" style={{ marginTop: '15px', textAlign: 'right' }}>
						<FormLabel>Leave Alert:</FormLabel>
					</Col>

					<Form.Group as={Col} style={{ paddingTop: '7px' }}>
						<FormControlLabel
							control={
								<Checkbox
									color={'default'}
									readOnly={!isEditing}
									checked={props.alert.isWeekly && props.alert.isDaily && props.alert.isOnApproval && props.alert.isMonthly}
									onChange={(e) => {
										updateIsEditing(true)
										handleTypeChange(e.target.name)
									}}
									name={'all'}
								/>
							}
							label={'Select All'}
						/>
						<FormControlLabel
							control={
								<Checkbox
									color={'default'}
									readOnly={!isEditing}
									checked={props.alert.isMonthly}
									onChange={(e) => {
										updateIsEditing(true)
										handleTypeChange(e.target.name)
									}}
									name={'monthly'}
								/>
							}
							label={'Monthly Alert - includes everything in the coming month (sent on the 1st day of the month)'}
						/>

						<FormControlLabel
							control={
								<Checkbox
									color={'default'}
									readOnly={!isEditing}
									checked={props.alert.isWeekly}
									onChange={(e) => {
										updateIsEditing(true)
										handleTypeChange(e.target.name)
									}}
									name={'weekly'}
								/>
							}
							label={'Weekly Alert - includes everything in the next 14 days (sent Monday mornings)'}
						/>

						<FormControlLabel
							control={
								<Checkbox
									color={'default'}
									readOnly={!isEditing}
									checked={props.alert.isDaily}
									onChange={(e) => {
										updateIsEditing(true)
										handleTypeChange(e.target.name)
									}}
									name={'daily'}
								/>
							}
							label={'Daily Alert - includes everything for today (sent every morning)'}
						/>

						<FormControlLabel
							control={
								<Checkbox
									color={'default'}
									readOnly={!isEditing}
									checked={props.alert.isDailyWeekdays}
									onChange={(e) => {
										updateIsEditing(true)
										handleTypeChange(e.target.name)
									}}
									name={'dailyWeekdays'}
								/>
							}
							label={'Daily Weekdays Alert - includes everything for today (sent every morning on weekdays)'}
						/>

						<FormControlLabel
							control={
								<Checkbox
									color={'default'}
									readOnly={!isEditing}
									checked={props.alert.isOnApproval}
									onChange={(e) => {
										updateIsEditing(true)
										handleTypeChange(e.target.name)
									}}
									name={'onDemand'}
								/>
							}
							label={'On Approval Alert - includes any new items (sent as soon as we find out about it)'}
						/>
						{/* <FormControlLabel
							control={
								<Checkbox
									color={'default'}
									readOnly={!isEditing}
									checked={props.alert.isOnRequest}
									onChange={(e) => {
										updateIsEditing(true)
										handleTypeChange(e.target.name)
									}}
									name={'onRequest'}
								/>
							}
							label={'Leave Request Alert - includes any new unapproved leave requests (sent as soon as we find out about it)'}
						/> */}
					</Form.Group>
				</Row>
				{props.hasLeaveBalanceProduct ? (
					<Row className="leavecal-wrap-style">
						<Col sm="2" style={{ marginTop: '15px', textAlign: 'right' }}>
							<FormLabel>Manager Alert:</FormLabel>
						</Col>

						<Form.Group as={Col} style={{ paddingTop: '7px' }}>
							<FormControlLabel
								control={
									<Checkbox
										color={'default'}
										readOnly={!isEditing}
										checked={props.alert.isMonthlyLeaveBalance}
										onChange={(e) => {
											updateIsEditing(true)
											handleTypeChange(e.target.name)
										}}
										name={'monthlyLeaveBalance'}
									/>
								}
								label={'Monthly Employee Leave Balance - includes leave balances for employees (sent on the 1st of the month)'}
							/>
							<FormControlLabel
								control={
									<Checkbox
										color={'default'}
										readOnly={!isEditing}
										checked={props.alert.isTwiceMonthlyLeaveBalance}
										onChange={(e) => {
											updateIsEditing(true)
											handleTypeChange(e.target.name)
										}}
										name={'twiceMonthlyLeaveBalance'}
									/>
								}
								label={
									'Twice Monthly Employee Leave Balance - includes leave balances for employees (sent on the 1st and the 15th of the month)'
								}
							/>
						</Form.Group>
					</Row>
				) : null}
				{isEditing ? (
					<Row>
						<Col sm="2"></Col>
						<Col sm="auto" style={{ marginTop: '7px' }}>
							<Button type="submit" disabled={isSubmitting} variant="info">
								{props.alert.leaveAlertID!.includes('new') ? 'Save' : 'Finish Editing'}
							</Button>
						</Col>
						<Col sm="auto" style={{ marginTop: '7px' }}>
							<Button
								type="button"
								disabled={isSubmitting}
								onClick={(event) => {
									event.stopPropagation()
									updateIsEditing(false)
									props.reset()
								}}
							>
								Cancel
							</Button>
						</Col>
					</Row>
				) : null}
			</Form>
		</div>
	)
}

const EmailAlerts = (props: EmailAlertsFrameProps) => {
	const [emailAlerts, updateEmailAlerts] = React.useState<LeaveAlert[] | null>(null)
	const [originalData, updateOriginalData] = React.useState<LeaveAlert[] | null>(null)
	const [messages, updateMessages] = Messages.useMessageReducer([])

	React.useEffect(() => {
		const fetchData = async () => {
			const [alerts] = await Promise.all([
				Request.get(`alert?where=LeaveAlertIsSlack==0&where=CalendarID==${props.currentCalendar.calendarID}`, props.appState.authState),
			])

			updateEmailAlerts(alerts.data.leaveAlerts)
			updateOriginalData(alerts.data.leaveAlerts)
			if (props.show) {
				props.updatePageStatus('Finished')
			}
		}

		if (props.currentCalendar.calendarID !== '') {
			fetchData()
		}
	}, [props.show, props.appState.authState, props.currentCalendar.calendarID, props.currentOrg, props.updatePageStatus]) // eslint-disable-line

	const reset = () => {
		updateEmailAlerts(originalData)
	}

	const removeAlert = async (alert: LeaveAlert) => {
		if (alert.leaveAlertID!.includes('new')) {
			updateEmailAlerts(emailAlerts!.filter((al: LeaveAlert) => al.leaveAlertID !== alert.leaveAlertID))
			updateMessages({
				type: 'add',
				data: {
					severity: 'success',
					message: 'Successfully removed email alert',
					timeout: 3000,
					dismissible: true,
				},
			})
		} else {
			const req = await Request.del(`alert/${alert.leaveAlertID}`, props.appState.authState)
			if (req.data.success) {
				updateEmailAlerts(emailAlerts!.filter((al: LeaveAlert) => al.leaveAlertID !== alert.leaveAlertID))
				updateMessages({
					type: 'add',
					data: {
						severity: 'success',
						message: 'Successfully removed email alert',
						timeout: 3000,
						dismissible: true,
					},
				})
			} else {
				updateMessages({
					type: 'add',
					data: {
						severity: 'danger',
						message: 'Unable to remove email alert',
						timeout: 3000,
						dismissible: true,
					},
				})
			}
		}
	}

	if (props.show) {
		if (props.pageStatus === 'Finished') {
			return (
				<>
					<FormGroup style={{ padding: '30px' }}>
						<Row className="email-alerts-card">
							<Row style={{ justifyContent: 'space-between' }}>
								<FormLabel className="emails-label">Team Email Alerts</FormLabel>
							</Row>
							<Row style={{ paddingLeft: '20px' }}>
								<FormLabel style={{ marginTop: '-5px', paddingLeft: '20px' }}>
									Set up Email alerts so your team is always aware of what&apos;s happening
								</FormLabel>
							</Row>
							{emailAlerts
								? emailAlerts.map((alert: LeaveAlert) => (
										<ConstructAlertCard
											key={alert.leaveAlertID}
											alert={alert}
											appState={props.appState}
											removeAlert={removeAlert}
											reset={reset}
											emailAlerts={emailAlerts}
											hasLeaveBalanceProduct={
												(props.currentOrg.xeroOrg &&
													props.currentOrg.xeroOrg.products &&
													props.currentOrg.xeroOrg.products.findIndex(
														(product) => product.productID === LeaveBalanceProduct.productID
													) >= 0) ||
												false
											}
											updateEmailAlerts={updateEmailAlerts}
											updateMessages={updateMessages}
											updateOriginalData={updateOriginalData}
										/>
								  ))
								: null}
							<Row style={{ justifyContent: 'flex-end', paddingTop: '10px' }}>
								<Col sm="auto">
									<Button
										type="submit"
										onClick={() => {
											emailAlerts
												? updateEmailAlerts(
														emailAlerts.concat({
															...emptyAlert,
															leaveAlertID: `new${emailAlerts.length}`,
															calendarID: props.currentCalendar.calendarID,
														})
												  )
												: updateEmailAlerts([{ ...emptyAlert, leaveAlertID: 'new0', calendarID: props.currentCalendar.calendarID }])
										}}
									>
										{emailAlerts && emailAlerts.length !== 0 ? 'Add another Alert' : 'Add an Alert'}
									</Button>
								</Col>
							</Row>
						</Row>
					</FormGroup>
					<Messages.Messages messages={messages} updateMessage={updateMessages} />
				</>
			)
		} else {
			return (
				<div className="loading-gif">
					<img src={LoadingImage} alt="Loading ..." />
				</div>
			)
		}
	} else {
		return null
	}
}

export default EmailAlerts
