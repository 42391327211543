import React from 'react'

const NoPage = () => {
	return (
		<div>
			<p>This page doesn&apos;t exist</p>
			<a href="/">Go home</a>
		</div>
	)
}

export default NoPage
