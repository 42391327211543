import React from 'react'
import { Row, Col, Card, Button, OverlayTrigger, Tooltip, Spinner } from 'react-bootstrap'

// components
import * as Messages from '../../ui/messages/messages'
import Collapsible from '../../ui/collapsible/collapsible'
import { RefreshCw } from 'react-feather'
import { OrgMappingContent } from './components/OrgMappingContent'
import { OrgMappingHeader } from './components/OrgMappingHeader'

// definitions
import * as AppDefinitions from '../../../App.d'
import { OrgSettings } from '../../../screens/subsync/subsync'
import {
	StripeOrgAccountXeroOrg,
	StripeOrgAccountXeroOrgMapping,
	StripeProductXeroMapping,
	StripeRule,
	StripeTaxRateXeroTaxRate,
} from '../../../../../back-end/utilities/apiDefinitions'

// utilities
import * as Request from '../../../utilities/request'

// helper functions
import * as functions from './functions'

interface SettingsComponentFrameProps {
	show: boolean
	appState: AppDefinitions.AppState
	orgSettings: OrgSettings
	updateOrgSettings: (orgSettings: OrgSettings) => void
	refreshData: () => void
}

interface EditedMarker {
	edited?: boolean
}

interface DetailedStripeOrgAccountXeroOrg extends StripeOrgAccountXeroOrg {
	edited?: boolean
	stripeProductXeroMappings?: (StripeProductXeroMapping & EditedMarker)[]
	stripeTaxRateXeroTaxRates?: (StripeTaxRateXeroTaxRate & EditedMarker)[]
	customMappings?: (Omit<StripeOrgAccountXeroOrgMapping, 'stripeRules'> &
		EditedMarker & {
			stripeRules?: (StripeRule & EditedMarker)[]
		})[]
}

const SettingsComponent = (props: SettingsComponentFrameProps) => {
	const [messages, updateMessages] = Messages.useMessageReducer([])

	const [orgMappings, updateOrgMappings] = React.useState<DetailedStripeOrgAccountXeroOrg[]>(props.orgSettings.orgMappings)

	const [submitting, setSubmitting] = React.useState<boolean>(false)

	React.useEffect(() => {
		updateOrgMappings(props.orgSettings.orgMappings)
	}, [props.orgSettings.orgMappings])

	const handleSave = async () => {
		setSubmitting(true)
		if (
			!functions.validateMappings(
				props.orgSettings.stripeOrgs,
				orgMappings.filter((mapping) => mapping.edited)
			)
		) {
			updateMessages({
				type: 'add',
				data: {
					severity: 'danger',
					message: 'All mapped accounts must have complete product and tax mappings',
					timeout: 3000,
					dismissible: true,
				},
			})
		} else {
			const filteredMappings = functions.filterUneditedMappings(orgMappings)
			// remove hardcoded 'not in use' option
			const mappings = filteredMappings.map((mapping) => ({
				...mapping,
				stripeTaxRateXeroTaxRates: mapping.stripeTaxRateXeroTaxRates!.map((taxRateMapping) =>
					taxRateMapping.xeroTaxRateID === 'N/A' ? { ...taxRateMapping, xeroTaxRateID: null } : taxRateMapping
				),
				stripeProductXeroMappings: mapping.stripeProductXeroMappings!.map((productMapping) => ({
					...productMapping,
					...(productMapping.xeroAccountID === 'N/A' ? { xeroAccountID: null } : {}),
					...(productMapping.xeroItemID === 'N/A' ? { xeroItemID: null } : {}),
				})),
				xeroOrgID: props.orgSettings.xeroOrgs.find((xo) => xo.accountXeroOrgID === mapping.accountXeroOrgID)?.xeroOrg?.xeroOrgID,
			}))
			const res = await Request.put('stripeorgaccountxeroorg', mappings, props.appState.authState)
			if (res.status === 200) {
				updateMessages({
					type: 'add',
					data: {
						severity: 'success',
						message: 'Successfully updated account mappings',
						timeout: 3000,
						dismissible: true,
					},
				})
				props.updateOrgSettings({
					...props.orgSettings,
					orgMappings: res.data.stripeOrgAccountXeroOrgs.map((orgMapping: StripeOrgAccountXeroOrg) => ({
						...orgMapping,
						stripeTaxRateXeroTaxRates: orgMapping.stripeTaxRateXeroTaxRates!.map((strxtr) =>
							strxtr.xeroTaxRateID === null ? { ...strxtr, xeroTaxRateID: 'N/A' } : strxtr
						),
						stripeProductXeroMappings: orgMapping.stripeProductXeroMappings!.map((spxm) =>
							spxm.xeroAccountID === null && spxm.xeroItemID === null ? { ...spxm, xeroAccountID: 'N/A', xeroItemID: 'N/A' } : spxm
						),
					})),
				})
			} else {
				updateMessages({
					type: 'add',
					data: {
						severity: 'danger',
						message: 'Unable to update account mappings',
						timeout: 3000,
						dismissible: true,
					},
				})
			}
		}
		setSubmitting(false)
	}

	if (props.show) {
		return (
			<>
				<Card
					style={{
						marginLeft: '5vw',
						marginRight: '5vw',
						marginTop: '25px',
					}}
				>
					<Card.Body id="subsync-settings">
						<Row>
							<Col style={{ display: 'flex' }}>
								<div className="settings-label">Account Mapping</div>
								<Col sm="auto" style={{ margin: '15px 0 0 -20px' }}>
									<OverlayTrigger placement="auto" overlay={<Tooltip id={`tooltip-remove-connection`}>Refresh Data</Tooltip>}>
										<Button type="submit" style={{ border: '0px' }} onClick={() => props.refreshData()}>
											<RefreshCw />
										</Button>
									</OverlayTrigger>
								</Col>
							</Col>
						</Row>
						{props.orgSettings.stripeOrgs.map((so) => {
							const orgMapping = orgMappings.find((mapping) => mapping.stripeOrgID === so.stripeOrg!.stripeOrgID)
							const mappedOrg = props.orgSettings.xeroOrgs.find((xo) => xo.accountXeroOrgID === orgMapping?.accountXeroOrgID)
							return (
								<Row
									key={so.stripeOrg!.stripeOrgID}
									style={{
										flexDirection: 'column',
										margin: '20px',
										padding: '10px 0px 30px',
										borderBottomWidth: 0.5,
										borderBottomColor: 'black',
										borderBottomStyle: 'solid',
									}}
								>
									<Collapsible
										startsCollapsed={props.orgSettings.stripeOrgs.length > 1}
										chevronSize={24}
										clickableElement={
											<OrgMappingHeader
												stripeOrg={so}
												orgMappings={orgMappings}
												orgMapping={orgMapping}
												mappedXeroOrg={mappedOrg}
												xeroOrgs={props.orgSettings.xeroOrgs}
												handleOrgUpdate={(...args) => functions.handleOrgUpdate(orgMappings, updateOrgMappings, ...args)}
												handleOrgAdd={(...args) => functions.handleOrgAdd(orgMappings, updateOrgMappings, ...args)}
											/>
										}
										// TODO: can we improve all these update functions somehow
										collapsibleElement={
											<OrgMappingContent
												stripeOrg={so}
												orgMapping={orgMapping!}
												mappedXeroOrg={mappedOrg}
												stripeMappingTypes={props.orgSettings.stripeMappingTypes}
												xeroOrgs={props.orgSettings.xeroOrgs}
												appState={props.appState}
												handleOrgUpdate={(...args) => functions.handleOrgUpdate(orgMappings, updateOrgMappings, ...args)}
												handleProductUpdate={(...args) => functions.handleProductUpdate(orgMappings, updateOrgMappings, ...args)}
												handleProductAdd={(...args) => functions.handleProductAdd(orgMappings, updateOrgMappings, ...args)}
												handleApplyToAllProducts={(...args) =>
													functions.handleApplyToAllProducts(orgMappings, updateOrgMappings, so.products, ...args)
												}
												handleTaxRateUpdate={(...args) => functions.handleTaxRateUpdate(orgMappings, updateOrgMappings, ...args)}
												handleTaxRateAdd={(...args) => functions.handleTaxRateAdd(orgMappings, updateOrgMappings, ...args)}
												handleSettingsUpdate={(...args) => functions.handleSettingsUpdate(orgMappings, updateOrgMappings, ...args)}
												handleCustomMappingUpdate={(...args) =>
													functions.handleCustomMappingUpdate(orgMappings, updateOrgMappings, ...args)
												}
												updateMessages={updateMessages}
											/>
										}
									/>
								</Row>
							)
						})}
						<Row style={{ paddingTop: '20px', justifyContent: 'flex-end' }}>
							<Col sm="2">
								<Button onClick={handleSave} variant={'success'}>
									{submitting ? <Spinner animation={'border'} size={'sm'} /> : 'Save Settings'}
								</Button>
							</Col>
						</Row>
					</Card.Body>
				</Card>
				<Messages.Messages messages={messages} updateMessage={updateMessages} />
			</>
		)
	}
	return null
}

export default SettingsComponent
export type { DetailedStripeOrgAccountXeroOrg }
