import React from 'react'
import { Row, Col, DropdownButton, Dropdown, Button } from 'react-bootstrap'

// components
import { Minus, Trash } from 'react-feather'

// definitions
import { AccountXeroOrgWithData } from '../../../../screens/subsync/subsync'
import { StripeOrgAccountXeroOrgMapping, StripeMappingType, StripeRule } from '../../../../../../back-end/utilities/apiDefinitions'
import { CustomMappingRule } from './CustomMappingRule'

// utilities
import { handleRuleUpdate } from '../functions'
import { XeroAccountsAndItemsDropdownItems } from './CustomMappings'

interface CustomMappingProps {
	customMapping: StripeOrgAccountXeroOrgMapping
	mappedXeroOrg: AccountXeroOrgWithData | undefined
	stripeMappingTypes: StripeMappingType[]
	handleMappingUpdate: (stripeOrgAccountXeroOrgMappingID: string, values: Partial<StripeOrgAccountXeroOrgMapping>) => void
	handleMappingRemove: (stripeOrgAccountXeroOrgMappingID: string) => void
}

const CustomMapping = (props: CustomMappingProps) => {
	const trackingCategoryOptions = props.mappedXeroOrg?.trackingCategories.flatMap((category) => category.options) ?? []
	const selectedXeroElementType = props.customMapping.type
	const selectedXeroElement =
		selectedXeroElementType === 'Account'
			? props.mappedXeroOrg?.accounts.find((account) => account.xeroAccountID === props.customMapping.xeroAccountID)
			: selectedXeroElementType === 'Item'
			? props.mappedXeroOrg?.items.find((item) => item.xeroItemID === props.customMapping.xeroItemID)
			: trackingCategoryOptions.find((option) => option.xeroTrackingCategoryOptionID === props.customMapping.xeroTrackingCategoryOptionID)

	const handleUpdate = (stripeRuleID: string, values: Partial<StripeRule>) => {
		const updatedRules = handleRuleUpdate(props.customMapping, stripeRuleID, values)
		props.handleMappingUpdate(props.customMapping.stripeOrgAccountXeroOrgMappingID!, { stripeRules: updatedRules })
	}

	const handleRemove = (stripeRuleID: string) => {
		const updatedRules = props.customMapping.stripeRules!.filter((rule) => rule.stripeRuleID !== stripeRuleID)
		props.handleMappingUpdate(props.customMapping.stripeOrgAccountXeroOrgMappingID!, { stripeRules: updatedRules })
	}

	const handleSelect = (eventKey: string | null) => {
		let newValues: Partial<StripeOrgAccountXeroOrgMapping> | undefined
		const account = props.mappedXeroOrg?.accounts.find((account) => account.xeroAccountID === eventKey)
		const item = props.mappedXeroOrg?.items.find((item) => item.xeroItemID === eventKey)
		const trackingCategoryOption = trackingCategoryOptions.find((option) => option.xeroTrackingCategoryOptionID === eventKey)
		if (account) {
			newValues = {
				type: 'Account',
				xeroAccountID: eventKey,
			}
		} else if (item) {
			newValues = {
				type: 'Item',
				xeroItemID: eventKey,
			}
		} else if (trackingCategoryOption) {
			newValues = {
				type: 'TrackingCategoryOption',
				xeroTrackingCategoryOptionID: eventKey,
			}
		}
		newValues && props.handleMappingUpdate(props.customMapping.stripeOrgAccountXeroOrgMappingID!, newValues)
	}

	return (
		<>
			<Col sm={2} className="d-flex justify-content-end">
				<DropdownButton
					id={`${props.customMapping.stripeOrgAccountXeroOrgMappingID}-xero-select`}
					title={selectedXeroElement?.name ?? 'Select...'}
					onSelect={handleSelect}
				>
					{selectedXeroElementType === 'TrackingCategoryOption' ? (
						<>
							{props.mappedXeroOrg?.trackingCategories.map((trackingCategory) => (
								<React.Fragment key={trackingCategory.xeroTrackingCategoryID}>
									<Dropdown.Header>{trackingCategory.name}</Dropdown.Header>
									{trackingCategory.options.map((option) => (
										<Dropdown.Item key={option.xeroTrackingCategoryOptionID} eventKey={option.xeroTrackingCategoryOptionID}>
											{option.name}
										</Dropdown.Item>
									))}
								</React.Fragment>
							))}
						</>
					) : (
						<XeroAccountsAndItemsDropdownItems mappedXeroOrg={props.mappedXeroOrg} />
					)}
				</DropdownButton>
			</Col>
			<Col sm={9}>
				{props.customMapping.stripeRules?.map((rule, index) => (
					<Row key={rule.stripeRuleID} className="mb-1">
						<Col sm={1} className={`d-flex align-items-center ${index === 0 ? 'justify-content-end' : 'justify-content-end'}`}>
							{index === 0 ? 'When:' : 'and'}
						</Col>
						<CustomMappingRule {...props} stripeRule={rule} handleRuleUpdate={handleUpdate} />
						<Col sm="auto" className="px-0 mx-0">
							<Button onClick={() => handleRemove(rule.stripeRuleID!)} className="mx-1 px-1">
								<Minus size={20} />
							</Button>
						</Col>
						{index === 0 && (
							<Col sm="auto" className="px-2 mx-2 d-flex justify-content-center">
								<Button onClick={() => props.handleMappingRemove(props.customMapping.stripeOrgAccountXeroOrgMappingID!)}>
									<Trash size={20} />
								</Button>
							</Col>
						)}
					</Row>
				))}
			</Col>
		</>
	)
}

export { CustomMapping }
export type { CustomMappingProps }
