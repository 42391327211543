import React from 'react'
import { Collapse } from 'react-bootstrap'
import { CollapseChevron } from '../collapseChevron/collapseChevron'

interface CollapsibleProps {
	startsCollapsed?: boolean
	chevronSize?: number
	clickableElement: JSX.Element
	collapsibleElement: JSX.Element
}

const Collapsible = (props: CollapsibleProps) => {
	const [isCollapsed, setIsCollapsed] = React.useState<boolean>(props.startsCollapsed !== undefined ? props.startsCollapsed : true)

	return (
		<>
			<div className={'d-flex flex-row align-items-center'}>
				<CollapseChevron collapsed={isCollapsed} updateCollapsed={setIsCollapsed} size={props.chevronSize} />
				{props.clickableElement}
			</div>
			<Collapse in={!isCollapsed}>
				<div>
					{props.collapsibleElement}
				</div>
			</Collapse>
		</>
	)
}

export default Collapsible