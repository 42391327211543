/** All common functions between threshold screens and components */
import { MetricGroup } from '../../../back-end/common/metricGroup'
import * as yup from 'yup'
export interface ThresholdMetric {
	metricID?: string | undefined
	badValue: number | null
	goodValue: number | null
	name?: string
	biggerIsBetter?: boolean
	dataType?: 'VARCHAR' | 'FLOAT' | 'INT' | 'DATETIME' | undefined
	accountXeroOrgID?: string
	accountXeroOrgMetricID?: string
}

export interface ThresholdTemplateFields {
	recordStatusID?: string
	thresholdTemplateID: string
	name: string
	metrics: any
	linkedAccountXeroOrgs: number
}

export type ThresholdMetricGroup = MetricGroup & {
	metrics: ThresholdMetric[]
}

export interface MetricsInGroups {
	[key: string]: ThresholdMetricGroup[]
}

export const removePropetyFromObject = (obj: any, property: string) => {
	delete obj[property]
	return obj
}

export type FormErrors = {
	[fieldID: string]: string
}

export const convertData = (metricGroups: any) => {
	if (metricGroups) {
		const allMetrics = Object.keys(metricGroups)
			.map((key) =>
				metricGroups[key].map((group: ThresholdMetricGroup) =>
					group.metrics!.map((metric: ThresholdMetric) => ({
						...metric,
					}))
				)
			)
			.flat()
			.flat()
		return allMetrics
	}
	return []
}

// ---- Validation Schema ---- //
export const schemaMetrics = yup
	.object()
	.shape(
		{
			biggerIsBetter: yup.boolean(),
			goodValue: yup.number().nullable(),
			badValue: yup.number().nullable(),
		},
		[['badValue', 'goodValue']]
	)
	.when('biggerIsBetter', (biggerIsBetter: boolean, schema: any) =>
		schema.test({
			name: 'validatebiggerIsBetter',
			test: (value: any) => {
				if (value.goodValue !== null && value.badValue !== null && value.biggerIsBetter) {
					if (value.goodValue <= value.badValue) {
						return false
					}
				} else if (value.goodValue !== null && value.badValue !== null && !value.biggerIsBetter) {
					if (value.goodValue >= value.badValue) {
						return false
					}
				}
				return true
			},
			message: ({ value }: any) =>
				value.biggerIsBetter
					? `${value.goodValue} is required to be greater than ${value.badValue}`
					: `${value.badValue} is required to be greater than ${value.badValue}`,
			exclusive: false,
		})
	)
