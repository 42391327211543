import React from 'react'
import { Button, Col, Container, Row, Table } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom'

import * as Request from '../../utilities/request'
import * as I from '../../utilities/me'
import { AppContext } from '../../App'

const ThresholdTemplates = () => {
	const { appState } = React.useContext(AppContext)
	const navigate = useNavigate()
	const [templates, setTemplates] = React.useState<{ thresholdTemplateID: string; name: string }[] | null>(null)

	React.useEffect(() => {
		const getTemplatess = async () => {
			const request = await Request.get('threshold', appState.authState)
			setTemplates(request.data.thresholdTemplates)
		}

		if (appState.authState.isLoggedIn) {
			getTemplatess()
		}
	}, [appState.authState])

	return (
		<Container className="oversight-container">
			<Row>
				<Col sm="auto">
					<h2>Threshold Templates</h2>
				</Col>
				{I.have('admin', appState.permission[appState.context]) ? (
					<>
						<Col></Col>
						<Col sm="auto">
							<Button
								onClick={async () => {
									navigate('/threshold/new')
								}}
							>
								Create New Threshold Template
							</Button>
						</Col>
					</>
				) : null}
			</Row>
			<Row>
				<Col>
					<Table borderless responsive>
						<thead>
							<tr className="header-row">
								<th>Name</th>
							</tr>
						</thead>
						<tbody className={'card-table-body'}>
							{templates && templates.length > 0 ? (
								templates!.map((template: { thresholdTemplateID: string; name: string }, index: number) => (
									<tr key={template.thresholdTemplateID} className={`stripe-row ${index % 2 === 0 ? 'stripe-row-body' : ''}`}>
										<td>
											<Link to={`/threshold/${template.thresholdTemplateID}`}>{template.name}</Link>
										</td>
									</tr>
								))
							) : (
								<tr key={'no template'} className={`stripe-row stripe-row-body`}>
									<td>No Threshold Templates</td>
								</tr>
							)}
						</tbody>
					</Table>
				</Col>
			</Row>
		</Container>
	)
}

export default ThresholdTemplates
