import React, { useState, useEffect, useContext } from 'react'
import { XeroEmbeddedAppContext } from '@xeroapi/embedded-apps-sdk'
import { useInterval } from './useInterval'
import { SyncLogTab } from './sync/SyncLogTab'
import { SubTab } from './subs/SubTab'
import { Tab, Tabs } from 'react-bootstrap'
import AwsConfig from '../constants/aws.json'
import { StripeInvoice } from '../../../back-end/common/stripeInvoice'

import './App.css'
import { StripeOrgAccountXeroOrg } from '../../../back-end/common/stripeOrgAccountXeroOrg'
import { ForecastTab } from './forecast/ForecastTab'

interface IErrorResponse {
	message: string
}

function App() {
	const [stripeInvoices, setStripeInvoices] = useState<StripeInvoice[] | undefined>(undefined)
	const [orgMappings, setOrgMappings] = useState<StripeOrgAccountXeroOrg[] | undefined>(undefined)
	const [errorResponse, setErrorResponse] = useState<IErrorResponse | undefined>(undefined)
	const [unauthorizedResponse, setUnauthorizedResponse] = useState<IErrorResponse | undefined>(undefined)
	const [key, setKey] = useState<string>('subs')

	const { xeroEmbeddedAppClient, xeroEmbeddedAppContext } = useContext(XeroEmbeddedAppContext)

	const retrieveInvoices = async () => {
		let queryParamKey = ''
		let queryParamValue = ''

		switch (xeroEmbeddedAppContext.metaData.triggerLocation) {
			case 'CONTACT_VIEW':
			case 'CONTACT_LIST_ITEM':
				queryParamKey = 'XeroContactID'
				queryParamValue = xeroEmbeddedAppContext.contactId
				break
			case 'INVOICE_VIEW':
				queryParamKey = 'XeroInvoiceID'
				queryParamValue = xeroEmbeddedAppContext.invoiceId
				break
			default:
				setErrorResponse({ message: `Unexpected triggerLocation: ${xeroEmbeddedAppContext.metaData.triggerLocation}` })
				return
		}

		const [invoices, orgMappings] = await Promise.all([
			xeroEmbeddedAppClient!.fetchWithToken(`https://${AwsConfig.DomainEndpoint}/xeroembed/stripeinvoice?where=${queryParamKey}==${queryParamValue}`),
			xeroEmbeddedAppClient!.fetchWithToken(`https://${AwsConfig.DomainEndpoint}/xeroembed/mappings`),
		])
		if (invoices.status === 200) {
			const invoiceData: { stripeInvoices: StripeInvoice[] } = await invoices.json()
			const orgMappingData: { stripeOrgAccountXeroOrgs: StripeOrgAccountXeroOrg[] } = await orgMappings.json()

			setStripeInvoices(invoiceData.stripeInvoices)
			setOrgMappings(
				orgMappingData.stripeOrgAccountXeroOrgs.map((orgMapping) => ({
					...orgMapping,
					stripeTaxRateXeroTaxRates: orgMapping.stripeTaxRateXeroTaxRates!.map((strxtr) =>
						strxtr.xeroTaxRateID === null ? { ...strxtr, xeroTaxRateID: 'N/A' } : strxtr
					),
				}))
			)
			setErrorResponse(undefined)
			setUnauthorizedResponse(undefined)
		} else if (invoices.status === 400) {
			const data: IErrorResponse | undefined = await invoices.json()

			setStripeInvoices(undefined)
			setOrgMappings(undefined)
			setErrorResponse(data)
			setUnauthorizedResponse(undefined)

			console.log(`Outstanding invoice retrieval failure: ${data?.message}`)
		} else if (invoices.status === 401) {
			const data: IErrorResponse | undefined = await invoices.json()

			setStripeInvoices(undefined)
			setOrgMappings(undefined)
			setErrorResponse(undefined)
			setUnauthorizedResponse(data)

			console.log(`Outstanding invoice retrieval failure: ${data?.message}`)
		}
	}

	// Perform initial token request, and display retrieved info or error message as appropriate
	useEffect(() => {
		const getData = async () => {
			xeroEmbeddedAppClient!.hide()
			await retrieveInvoices()
			xeroEmbeddedAppClient!.display()
		}
		getData()
	}, [xeroEmbeddedAppContext, xeroEmbeddedAppClient])

	// Initiate polling for token if info has not already been retrieved
	if (!stripeInvoices) {
		useInterval(retrieveInvoices, 5000)
	} else {
		useInterval(retrieveInvoices, null)
	}

	const getContent = () => {
		if (stripeInvoices && orgMappings) {
			return (
				<Tabs activeKey={key} onSelect={(k) => setKey(k!)}>
					<Tab eventKey="subs" title="Subscriptions">
						<SubTab invoices={stripeInvoices} />
					</Tab>
					<Tab eventKey="sync" title="Sync Log">
						<SyncLogTab invoices={stripeInvoices} orgMappings={orgMappings} />
					</Tab>
					<Tab eventKey="forecast" title="Forecast">
						<ForecastTab />
					</Tab>
				</Tabs>
			)
		} else if (unauthorizedResponse) {
			return (
				<div>
					<h3>Connection Error</h3>
					{
						<p>
							We&apos;re having trouble connecting to your organisation. You should try refreshing the connection by following the process{' '}
							<a target="_blank" href={`${process.env.REACT_APP_EMBEDDED_APP_SERVER_BASE_URL}/connect`} rel="noopener noreferrer">
								here
							</a>
							.
						</p>
					}
				</div>
			)
		} else if (errorResponse) {
			return (
				<div>
					<h3>Error</h3>
					{<p>{errorResponse.message}</p>}
				</div>
			)
		}

		return null
	}

	return <div className="ea-content">{getContent()}</div>
}

export { App }
