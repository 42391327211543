import React from 'react'
import { Form, Row, Col, DropdownButton, Dropdown } from 'react-bootstrap'

// definitions
import { TaxSettingsProps } from './TaxSettings'

const TaxAsLineItemSettings = (props: TaxSettingsProps) => {
	return (
		<>
			<Form.Group as={Row}>
				<Col>Choose an account to use for tax line items:</Col>
			</Form.Group>
			<Form.Group as={Row} style={{ height: '2.5rem' }}>
				<Form.Label column sm={2}>
					Xero Account
				</Form.Label>
				<Col sm={4}>
					<DropdownButton
						id={`${props.stripeOrgID}-tax-settings-account-select`}
						title={
							props.mappedXeroOrg!.accounts.find((a) => a.xeroAccountID === props.orgMapping?.stripeOrgAccountXeroOrgSettings?.taxXeroAccountID)
								?.name || 'Select Xero Account'
						}
						onSelect={(eventKey) => props.handleSettingsUpdate(props.stripeOrgID, { taxXeroAccountID: eventKey! })}
					>
						<Dropdown.Header>Revenue</Dropdown.Header>
						{props
							.mappedXeroOrg!.accounts.filter((account) => account.type === 'REVENUE')
							.sort((a, b) => a.name!.localeCompare(b.name!, undefined, { numeric: true }))
							.map((xa) => (
								<Dropdown.Item key={xa.xeroAccountID} eventKey={xa.xeroAccountID}>
									{xa.name}
								</Dropdown.Item>
							))}
						<Dropdown.Header>Other Income</Dropdown.Header>
						{props
							.mappedXeroOrg!.accounts.filter((account) => account.type === 'OTHERINCOME')
							.sort((a, b) => a.name!.localeCompare(b.name!, undefined, { numeric: true }))
							.map((xa) => (
								<Dropdown.Item key={xa.xeroAccountID} eventKey={xa.xeroAccountID}>
									{xa.name}
								</Dropdown.Item>
							))}
						<Dropdown.Header>Current Liability</Dropdown.Header>
						{props
							.mappedXeroOrg!.accounts.filter((account) => account.type === 'CURRLIAB')
							.sort((a, b) => a.name!.localeCompare(b.name!, undefined, { numeric: true }))
							.map((xa) => (
								<Dropdown.Item key={xa.xeroAccountID} eventKey={xa.xeroAccountID}>
									{xa.name}
								</Dropdown.Item>
							))}
					</DropdownButton>
				</Col>
			</Form.Group>
			<Form.Group as={Row}>
				<Col>Choose a 0.00% Xero tax rate to apply to line items:</Col>
			</Form.Group>
			<Form.Group as={Row} style={{ height: '2.5rem' }}>
				<Form.Label column sm={2}>
					Xero Tax Rate
				</Form.Label>
				<Col sm={4}>
					<DropdownButton
						id={`${props.stripeOrgID}-tax-settings-tax-rate-select`}
						title={
							props.mappedXeroOrg!.taxRates.find((tr) => tr.xeroTaxRateID === props.orgMapping?.stripeOrgAccountXeroOrgSettings?.taxXeroTaxRateID)
								?.name || 'Select Xero Tax Rate'
						}
						onSelect={(eventKey) => props.handleSettingsUpdate(props.stripeOrgID, { taxXeroTaxRateID: eventKey! })}
					>
						{props
							.mappedXeroOrg!.taxRates.filter((tr) => tr.canApplyToRevenue && Number(tr.effectiveRate) === 0)
							.map((taxRate) => (
								<Dropdown.Item key={taxRate.xeroTaxRateID} eventKey={taxRate.xeroTaxRateID}>
									{taxRate.name} {taxRate.effectiveRate ? `(${(Number(taxRate.effectiveRate) * 100).toFixed(2)}%)` : ''}
								</Dropdown.Item>
							))}
					</DropdownButton>
				</Col>
			</Form.Group>
		</>
	)
}

export { TaxAsLineItemSettings }
