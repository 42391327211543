// our event type that makes sense for choosing our application flow
enum SubSyncEventType {
	// events originating from Stripe
	Created = 'created',
	UpdatedOld = 'updated.old',
	UpdatedNew = 'updated.new',

	// events originating from either Stripe or Xero
	Paid = 'paid',
	Voided = 'voided',
	Credit = 'credit',
}

// subset of Stripe's event types that are relevant for the SubSyncEvent table
enum SubSyncEventStripeEventType {
	InvoiceFinalized = 'invoice.finalized',
	InvoicePaid = 'invoice.paid',
	InvoiceVoided = 'invoice.voided',
	CreditNoteCreated = 'credit_note.created',
}

export { SubSyncEventType, SubSyncEventStripeEventType }
