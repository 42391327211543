import React from 'react'
import { Button, Col, Form, FormControl, FormLabel, Row, OverlayTrigger, Tooltip, FormGroup } from 'react-bootstrap'
import { AccountXeroOrg, Calendar as CalendarData, LeaveAlert, Slack, SlackChannel } from '../../../../back-end/utilities/apiDefinitions'
import * as yup from 'yup'

// definitions
import * as AppDefinitions from '../../App.d'

// components
import * as Messages from '../../components/ui/messages/messages'
import { MessageAction } from '../../components/ui/messages/message'
import { Trash2, X } from 'react-feather'

// utilities
import * as Request from '../../utilities/request'
interface EmailAlertsFrameProps {
	show: boolean
	appState: AppDefinitions.AppState
	currentOrg: AccountXeroOrg
	currentCalendar: CalendarData
	pageStatus: string
	updatePageStatus: React.Dispatch<React.SetStateAction<string>>
	loadingImage: string
	updateAppState: React.Dispatch<AppDefinitions.AppStateAction>
}

export interface AlertCardProps {
	alert: LeaveAlert
	appState: AppDefinitions.AppState
	reset: () => void
	updateMessages: (message: MessageAction) => void
	updateOriginalData: (alert: LeaveAlert[]) => void
	removeAlert: (alert: LeaveAlert) => void
}

interface SlackAlertCardProps extends AlertCardProps {
	slackAlerts: LeaveAlert[]
	slack: Slack
	updateSlackAlerts: (alerts: LeaveAlert[]) => void
}

const emptyAlert: LeaveAlert = {
	leaveAlertID: '',
	isSlack: true,
	isWeekly: false,
	isMonthly: false,
	isDaily: false,
	isDailyWeekdays: false,
	isOnApproval: false,
	isOnRequest: false,
	slackChannel: '',
	calendarID: '',
	isMonthlyLeaveBalance: false,
	isTwiceMonthlyLeaveBalance: false,
}

const ConstructAlertCard = (props: SlackAlertCardProps) => {
	const [isEditing, updateIsEditing] = React.useState<boolean>(props.alert.leaveAlertID!.includes('new'))
	const [isTypeDisabled, updateTypeDisabled] = React.useState<boolean>(true)
	const [isSubmitting, updateIsSubmitting] = React.useState<boolean>(false)
	const [showSuggestions, setShowSuggestions] = React.useState<boolean>(false)
	const [filter, updateFilter] = React.useState<string>('')
	const [searchedChannels, updateSearchedChannels] = React.useState<SlackChannel[]>(props.slack.channels ? props.slack.channels : [])

	// ---- Validation Schema ---- //
	const schema = yup.object().shape(
		{
			slackChannel: yup.string().optional(), // change back to required FOV-341 'Slack channel is required.'
			note: yup.string().nullable().optional().max(1000, 'Note cannot exceed 1000 characters.'),
			isWeekly: yup.boolean().when(['isDaily', 'isOnApproval', 'isOnRequest', 'isMonthly', 'isDailyWeekdays'], {
				is: (isDaily: boolean, isOnApproval: boolean, isMonthly: boolean, isDailyWeekdays: boolean, isOnRequest: boolean) =>
					!isDaily && !isOnApproval && !isMonthly && !isDailyWeekdays && !isOnRequest,
				then: yup.boolean().required('One or more types must be accepted.').oneOf([true], 'One or more types must be accepted.'),
			}),
			isMonthly: yup.boolean().when(['isDaily', 'isWeekly', 'isOnApproval', 'isOnRequest', 'isDailyWeekdays'], {
				is: (isDaily: boolean, isOnApproval: boolean, isWeekly: boolean, isDailyWeekdays: boolean, isOnRequest: boolean) =>
					!isDaily && !isOnApproval && !isWeekly && !isDailyWeekdays && !isOnRequest,
				then: yup.boolean().required('One or more types must be accepted.').oneOf([true], 'One or more types must be accepted.'),
			}),
			isDaily: yup.boolean().when(['isWeekly', 'isOnApproval', 'isOnRequest', 'isMonthly', 'isDailyWeekdays'], {
				is: (isWeekly: boolean, isOnApproval: boolean, isMonthly: boolean, isDailyWeekdays: boolean, isOnRequest: boolean) =>
					!isWeekly && !isOnApproval && !isMonthly && !isDailyWeekdays && !isOnRequest,
				then: yup.boolean().required('One or more types must be accepted.').oneOf([true], 'One or more types must be accepted.'),
			}),
			isDailyWeekdays: yup.boolean().when(['isWeekly', 'isOnApproval', 'isOnRequest', 'isMonthly', 'isDaily'], {
				is: (isWeekly: boolean, isDaily: boolean, isOnApproval: boolean, isMonthly: boolean, isOnRequest: boolean) =>
					!isWeekly && !isOnApproval && !isMonthly && !isDaily && !isOnRequest,
				then: yup.boolean().required('One or more types must be accepted.').oneOf([true], 'One or more types must be accepted.'),
			}),
			isOnApproval: yup.boolean().when(['isWeekly', 'isDaily', 'isMonthly', 'isOnRequest', 'isDailyWeekdays'], {
				is: (isWeekly: boolean, isDaily: boolean, isMonthly: boolean, isDailyWeekdays: boolean, isOnRequest: boolean) =>
					!isWeekly && !isDaily && !isMonthly && !isDailyWeekdays && !isOnRequest,
				then: yup.boolean().required('One or more types must be accepted.').oneOf([true], 'One or more types must be accepted.'),
			}),
			isOnRequest: yup.boolean().when(['isWeekly', 'isDaily', 'isMonthly', 'isOnApproval', 'isDailyWeekdays'], {
				is: (isWeekly: boolean, isDaily: boolean, isMonthly: boolean, isDailyWeekdays: boolean, isOnApproval: boolean) =>
					!isWeekly && !isDaily && !isMonthly && !isDailyWeekdays && !isOnApproval,
				then: yup.boolean().required('One or more types must be accepted.').oneOf([true], 'One or more types must be accepted.'),
			}),
		},
		[
			['isDailyWeekdays', 'isWeekly'],
			['isDailyWeekdays', 'isMonthly'],
			['isDailyWeekdays', 'isDaily'],
			['isDailyWeekdays', 'isOnApproval'],
			['isWeekly', 'isDaily'],
			['isWeekly', 'isOnApproval'],
			['isDaily', 'isOnApproval'],
			['isDaily', 'isMonthly'],
			['isOnApproval', 'isMonthly'],
			['isWeekly', 'isMonthly'],
			['isOnRequest', 'isWeekly'],
			['isOnRequest', 'isMonthly'],
			['isOnRequest', 'isDaily'],
			['isOnRequest', 'isOnApproval'],
			['isOnRequest', 'isDailyWeekdays'],
		]
	)

	const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault()
		try {
			try {
				await schema.validate(props.alert, { abortEarly: false })
			} catch (validationError) {
				if (validationError instanceof yup.ValidationError) {
					const newErrors = validationError.inner.map((err) => err.errors.join('\n'))
					props.updateMessages({
						type: 'add',
						data: {
							severity: 'danger',
							message: `Unable to update Slack Alerts: \n\n ${newErrors
								.filter((val: string, id: number, array: string | string[]) => array.indexOf(val) === id)
								.join('\n')}`,
							timeout: 3000,
							dismissible: true,
						},
					})
				}
				return
			}
			updateIsSubmitting(true)
			const req = await (props.alert.leaveAlertID!.includes('new')
				? Request.post('alert', props.alert, props.appState.authState)
				: Request.put(`alert/${props.alert.leaveAlertID}`, props.alert, props.appState.authState))

			if (req.data.success) {
				props.updateMessages({
					type: 'add',
					data: {
						severity: 'success',
						message: 'Successfully updated Slack Alerts',
						timeout: 3000,
						dismissible: true,
					},
				})
				const newAlerts = props.slackAlerts!.map((al: LeaveAlert) => {
					if (al.leaveAlertID?.includes('new')) {
						return req.data.leaveAlerts[0]
					}
					return al
				})
				props.updateSlackAlerts(newAlerts)
				props.updateOriginalData(newAlerts)
				updateIsEditing(false)
			} else {
				props.updateMessages({
					type: 'add',
					data: {
						severity: 'danger',
						message: 'Unable to update Slack Alerts',
						timeout: 3000,
						dismissible: true,
					},
				})
			}
			updateIsSubmitting(false)
		} catch (error) {
			console.log('Error: ', error, props.appState.authState)
		}
	}

	const handleNoteChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		event.preventDefault()
		const value = event.target.value

		const newAlerts = props.slackAlerts.map((al: LeaveAlert) => {
			if (al.leaveAlertID === props.alert.leaveAlertID) {
				return { ...al, note: value }
			}
			return al
		})
		props.updateSlackAlerts(newAlerts)
	}

	const handleChannelChangeDiv = (event: React.MouseEvent<HTMLDivElement>) => {
		event.preventDefault()
		const value = event.currentTarget.innerText
		handleChannelChange(value)
	}
	const handleChannelChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
		event.preventDefault()
		const value = event.target.value
		handleChannelChange(value)
	}
	const handleChannelChange = (value: string) => {
		const newAlerts = props.slackAlerts!.map((al) => {
			if (al.leaveAlertID === props.alert.leaveAlertID) {
				return { ...al, slackChannel: value }
			}
			return al
		})
		props.updateSlackAlerts(newAlerts)
		setShowSuggestions(false)
		updateFilter('')
		updateSearchedChannels(props.slack.channels!)
	}

	const checkIfAllSelectedLeavecal = (alert: LeaveAlert) => {
		//to add the other options in the future as possible types
		return alert.isWeekly && alert.isOnApproval && alert.isDaily && alert.isDailyWeekdays && alert.isMonthly && alert.isOnRequest
	}

	const handleTypeChangeLeavecal = async (option: string) => {
		const newAlerts = props.slackAlerts.map((al: LeaveAlert) => {
			if (al.leaveAlertID === props.alert.leaveAlertID) {
				if (option === 'all' && checkIfAllSelectedLeavecal(props.alert)) {
					return {
						...props.alert,
						isWeekly: false,
						isDaily: false,
						isDailyWeekdays: false,
						isMonthly: false,
						isOnApproval: false,
						isOnRequest: false,
					}
				} else if (option === 'all' && !checkIfAllSelectedLeavecal(props.alert)) {
					return {
						...props.alert,
						isWeekly: true,
						isDaily: true,
						isDailyWeekdays: true,
						isMonthly: true,
						isOnApproval: true,
						isOnRequest: true,
					}
				} else if (option === 'daily') {
					return {
						...props.alert,
						isDaily: !props.alert.isDaily,
					}
				} else if (option === 'dailyWeekdays') {
					return {
						...props.alert,
						isDailyWeekdays: !props.alert.isDailyWeekdays,
					}
				} else if (option === 'weekly') {
					return {
						...props.alert,
						isWeekly: !props.alert.isWeekly,
					}
				} else if (option === 'onDemand') {
					return {
						...props.alert,
						isOnApproval: !props.alert.isOnApproval,
					}
				} else if (option === 'monthly') {
					return {
						...props.alert,
						isMonthly: !props.alert.isMonthly,
					}
				} else if (option === 'onRequest') {
					return {
						...props.alert,
						isOnRequest: !props.alert.isOnRequest,
					}
				}
			}
			return al
		})
		props.updateSlackAlerts(newAlerts)
	}

	const handleTypeChangeTAYG = async (option: string) => {
		const newAlerts = props.slackAlerts.map((al: LeaveAlert) => {
			if (al.leaveAlertID === props.alert.leaveAlertID) {
				if (option === 'weekly') {
					return { ...al, isWeekly: !al.isWeekly }
				} else if (option === 'monthly') {
					return { ...al, isMonthly: !al.isMonthly }
				} else {
					// option = day of week
					return { ...al, [option]: !al[option as keyof typeof al] }
				}
			}
			return al
		})
		props.updateSlackAlerts(newAlerts)
	}

	return (
		<div className={`form ${isEditing ? 'editing' : ''}`} style={{ paddingTop: '15px' }}>
			<div
				onClick={() => {
					updateIsEditing(true)
				}}
			>
				<Form onSubmit={handleSubmit} className="needs-validation">
					<Row className="leavecal-wrap-style">
						<Col sm="2" style={{ marginTop: '15px', textAlign: 'right' }}>
							<FormLabel>Slack Channel:</FormLabel>
						</Col>
						<Col sm="5">
							<FormControl
								type={'text'}
								style={{ marginTop: '7px', textAlign: 'left' }}
								value={props.alert.slackChannel || 'No Channel Selected'}
								readOnly={!isEditing}
								onClick={(event: React.MouseEvent<HTMLInputElement>) => {
									event.stopPropagation()
									setShowSuggestions(true)
								}}
								onChange={handleChannelChangeInput}
								name={'slackChannel'}
							/>
							{showSuggestions ? (
								<div className="slack-channel-dropdown">
									<input
										type="text"
										style={{ margin: '5px', paddingLeft: '8px' }}
										onBlur={() => {
											setShowSuggestions(false)
											updateFilter('')
											updateSearchedChannels(props.slack.channels!)
										}}
										onChange={(event) => {
											updateFilter(event.target.value)
											const channels = props.slack.channels!.filter((channel) => channel.name.includes(event.target.value))
											updateSearchedChannels(channels)
										}}
										value={filter}
										placeholder="Search channel..."
									/>
									{searchedChannels.map((channel, index) => (
										<div
											id={channel.channelID}
											key={index}
											onMouseDown={(event) => event.preventDefault()}
											onClick={handleChannelChangeDiv}
										>
											{channel.name}
										</div>
									))}
								</div>
							) : null}
						</Col>
						{!props.alert.leaveAlertID!.includes('new') ? (
							<Col sm="auto" style={{ flex: '1', textAlign: 'right' }}>
								<OverlayTrigger placement="auto" overlay={<Tooltip id={`tooltip-remove-alert`}>Remove slack alert.</Tooltip>}>
									<X
										style={{
											cursor: 'pointer',
											paddingTop: '5px',
											width: '30px',
											height: '30px',
											textAlign: 'right',
										}}
										onClick={(event) => {
											event.stopPropagation()
											updateIsEditing(false)
											props.removeAlert(props.alert)
										}}
									/>
								</OverlayTrigger>
							</Col>
						) : null}
					</Row>
					<Row className="leavecal-wrap-style">
						<Col sm="2" style={{ marginTop: '15px', textAlign: 'right' }}>
							<FormLabel>Alert Notes:</FormLabel>
						</Col>
						<Col sm="5">
							<Form.Group style={{ marginBottom: '0px' }}>
								<OverlayTrigger
									placement={'right'}
									overlay={<Tooltip id={`tooltip-remove-alert`}>Adds a note to the alert for everyone to see.</Tooltip>}
								>
									<Form.Control
										as="textarea"
										className="form-control"
										value={props.alert.note}
										style={{
											marginTop: '7px',
											textAlign: 'left',
											resize: 'none',
										}}
										rows={3}
										readOnly={!isEditing}
										onClick={() => updateIsEditing(true)}
										onChange={handleNoteChange}
										name={'alertNote'}
									/>
								</OverlayTrigger>
							</Form.Group>
						</Col>
					</Row>
					<Row className="leavecal-wrap-style">
						<Col sm="2" style={{ marginTop: '15px', textAlign: 'right' }}>
							<FormLabel>Types:</FormLabel>
						</Col>

						<Form.Group as={Col} style={{ paddingTop: '7px' }}>
							{props.appState.context === 'leavecal' ? (
								<Form.Check
									type="checkbox"
									name="all"
									readOnly={isTypeDisabled}
									checked={
										props.alert.isWeekly &&
										props.alert.isDaily &&
										props.alert.isDailyWeekdays &&
										props.alert.isOnApproval &&
										props.alert.isMonthly
									}
									onChange={(e) => {
										if (props.alert.leaveAlertID!.includes('new')) {
											updateTypeDisabled(false)
										}
										handleTypeChangeLeavecal(e.target.name)
									}}
									label="Select All"
								/>
							) : null}
							<Form.Check
								type="checkbox"
								name="monthly"
								readOnly={isTypeDisabled}
								checked={props.alert.isMonthly}
								onChange={(e) => {
									if (props.alert.leaveAlertID!.includes('new')) {
										updateTypeDisabled(false)
									}
									if (props.appState.context === 'leavecal') {
										handleTypeChangeLeavecal(e.target.name)
									} else if (props.appState.context === 'tayg') {
										handleTypeChangeTAYG(e.target.name)
									}
								}}
								label={
									props.appState.context === 'leavecal'
										? 'Monthly Alert - includes everything in the coming month (sent on the 1st day of the month)'
										: 'Monthly Alert - Sent on the 25th of the month (which gives you time to act before EOM)'
								}
							/>
							<Form.Check
								type="checkbox"
								name="weekly"
								readOnly={isTypeDisabled}
								checked={props.alert.isWeekly}
								onChange={(e) => {
									if (props.alert.leaveAlertID!.includes('new')) {
										updateTypeDisabled(false)
									}
									if (props.appState.context === 'leavecal') {
										handleTypeChangeLeavecal(e.target.name)
									} else if (props.appState.context === 'tayg') {
										handleTypeChangeTAYG(e.target.name)
									}
								}}
								label={
									props.appState.context === 'leavecal'
										? 'Weekly Alert - includes everything in the next 14 days (sent Monday mornings)'
										: 'Weekly Alert - Sent on mornings of your choice'
								}
							/>
							{props.appState.context === 'tayg' && props.alert.isWeekly && (
								<>
									<Form.Check
										type="checkbox"
										name="onMonday"
										readOnly={isTypeDisabled}
										checked={props.alert.onMonday}
										onChange={(e) => {
											if (props.alert.leaveAlertID!.includes('new')) {
												updateTypeDisabled(false)
											}
											if (props.appState.context === 'leavecal') {
												handleTypeChangeLeavecal(e.target.name)
											} else if (props.appState.context === 'tayg') {
												handleTypeChangeTAYG(e.target.name)
											}
										}}
										label="Send on Monday morning"
									/>
									<Form.Check
										type="checkbox"
										name="onTuesday"
										readOnly={isTypeDisabled}
										checked={props.alert.onTuesday}
										onChange={(e) => {
											if (props.alert.leaveAlertID!.includes('new')) {
												updateTypeDisabled(false)
											}
											if (props.appState.context === 'leavecal') {
												handleTypeChangeLeavecal(e.target.name)
											} else if (props.appState.context === 'tayg') {
												handleTypeChangeTAYG(e.target.name)
											}
										}}
										label="Send on Tuesday morning"
									/>
									<Form.Check
										type="checkbox"
										name="onWednesday"
										readOnly={isTypeDisabled}
										checked={props.alert.onWednesday}
										onChange={(e) => {
											if (props.alert.leaveAlertID!.includes('new')) {
												updateTypeDisabled(false)
											}
											if (props.appState.context === 'leavecal') {
												handleTypeChangeLeavecal(e.target.name)
											} else if (props.appState.context === 'tayg') {
												handleTypeChangeTAYG(e.target.name)
											}
										}}
										label="Send on Wednesday morning"
									/>
									<Form.Check
										type="checkbox"
										name="onThursday"
										readOnly={isTypeDisabled}
										checked={props.alert.onThursday}
										onChange={(e) => {
											if (props.alert.leaveAlertID!.includes('new')) {
												updateTypeDisabled(false)
											}
											if (props.appState.context === 'leavecal') {
												handleTypeChangeLeavecal(e.target.name)
											} else if (props.appState.context === 'tayg') {
												handleTypeChangeTAYG(e.target.name)
											}
										}}
										label="Send on Thursday morning"
									/>
									<Form.Check
										type="checkbox"
										name="onFriday"
										readOnly={isTypeDisabled}
										checked={props.alert.onFriday}
										onChange={(e) => {
											if (props.alert.leaveAlertID!.includes('new')) {
												updateTypeDisabled(false)
											}
											if (props.appState.context === 'leavecal') {
												handleTypeChangeLeavecal(e.target.name)
											} else if (props.appState.context === 'tayg') {
												handleTypeChangeTAYG(e.target.name)
											}
										}}
										label="Sent on Friday morning"
									/>
									<Form.Check
										type="checkbox"
										name="onSaturday"
										readOnly={isTypeDisabled}
										checked={props.alert.onSaturday}
										onChange={(e) => {
											if (props.alert.leaveAlertID!.includes('new')) {
												updateTypeDisabled(false)
											}
											if (props.appState.context === 'leavecal') {
												handleTypeChangeLeavecal(e.target.name)
											} else if (props.appState.context === 'tayg') {
												handleTypeChangeTAYG(e.target.name)
											}
										}}
										label="Sent on Saturday morning"
									/>
									<Form.Check
										type="checkbox"
										name="onSunday"
										readOnly={isTypeDisabled}
										checked={props.alert.onSunday}
										onChange={(e) => {
											if (props.alert.leaveAlertID!.includes('new')) {
												updateTypeDisabled(false)
											}
											if (props.appState.context === 'leavecal') {
												handleTypeChangeLeavecal(e.target.name)
											} else if (props.appState.context === 'tayg') {
												handleTypeChangeTAYG(e.target.name)
											}
										}}
										label="Sent on Sunday morning"
									/>
								</>
							)}
							{props.appState.context === 'leavecal' ? (
								<>
									<Form.Check
										type="checkbox"
										name="daily"
										readOnly={isTypeDisabled}
										checked={props.alert.isDaily}
										onChange={(e) => {
											if (props.alert.leaveAlertID!.includes('new')) {
												updateTypeDisabled(false)
											}
											handleTypeChangeLeavecal(e.target.name)
										}}
										label="Daily Alert - includes everything for today (sent every morning)"
									/>
									<Form.Check
										type="checkbox"
										name="dailyWeekdays"
										readOnly={isTypeDisabled}
										checked={props.alert.isDailyWeekdays}
										onChange={(e) => {
											if (props.alert.leaveAlertID!.includes('new')) {
												updateTypeDisabled(false)
											}
											handleTypeChangeLeavecal(e.target.name)
										}}
										label="Daily Weekdays Alert - includes everything for today (sent every morning on weekdays)"
									/>
									<Form.Check
										type="checkbox"
										name="onDemand"
										readOnly={isTypeDisabled}
										checked={props.alert.isOnApproval}
										onChange={(e) => {
											if (props.alert.leaveAlertID!.includes('new')) {
												updateTypeDisabled(false)
											}
											handleTypeChangeLeavecal(e.target.name)
										}}
										label="On Approval Alert - includes any new items (sent as soon as we find out about it)"
									/>
									{/* <Form.Check
										type="checkbox"
										name="onRequest"
										readOnly={isTypeDisabled}
										checked={props.alert.isOnRequest}
										onChange={(e) => {
											if (props.alert.leaveAlertID!.includes('new')) {
												updateTypeDisabled(false)
											}
											handleTypeChangeLeavecal(e.target.name)
										}}
										label="Leave Request Alert - includes any new unapproved leave requests (sent as soon as we find out about it)"
									/> */}
								</>
							) : null}
						</Form.Group>
					</Row>
					<Row>
						<Col sm="2"></Col>
						<Col sm="auto" style={{ marginTop: '7px' }}>
							{isEditing ? (
								<Button type="submit" disabled={isSubmitting} variant="success">
									{props.alert.leaveAlertID!.includes('new') ? 'Save' : 'Finish Editing'}
								</Button>
							) : null}
						</Col>
						<Col sm="auto" style={{ marginTop: '7px' }}>
							{isEditing ? (
								<Button
									type="button"
									disabled={isSubmitting}
									onClick={(event) => {
										event.stopPropagation()
										updateIsEditing(false)
										props.reset()
									}}
								>
									Cancel
								</Button>
							) : null}
						</Col>
					</Row>
				</Form>
			</div>
		</div>
	)
}

const SlackAlerts = (props: EmailAlertsFrameProps) => {
	const [slackUrl, updateSlackUrl] = React.useState<string>('')
	const [slackConnection, updateSlackConnection] = React.useState<Slack | null>(null)
	const [slackAlerts, updateSlackAlerts] = React.useState<LeaveAlert[] | null>(null)
	const [originalData, updateOriginalData] = React.useState<LeaveAlert[] | null>(null)
	const [messages, updateMessages] = Messages.useMessageReducer([])
	const defaultSlackAlert = {
		...emptyAlert,
		leaveAlertID: 'new',
		calendarID: props.currentCalendar?.calendarID,
	}

	React.useEffect(
		() => {
			const fetchData = async () => {
				const [url, alerts, slack] = await Promise.all([
					Request.get(`slack/url/${props.appState.context}`, props.appState.authState),
					Request.get(`alert?where=LeaveAlertIsSlack==1&where=CalendarID==${props.currentCalendar?.calendarID}`, props.appState.authState),
					Request.get('slack', props.appState.authState),
				])

				const slackAlerts = alerts.data.leaveAlerts.length > 0 ? alerts.data.leaveAlerts : [defaultSlackAlert]
				updateSlackUrl(url.data.url)

				// saving channels in state to avoid constant calling
				if (slack.data.slack) {
					if (props.appState.slackChannels && props.appState.slackChannels.slackID === slack.data.slack.slackID) {
						updateSlackConnection({
							...slack.data.slack,
							channels: props.appState.slackChannels!.channels,
						})
					} else {
						const channels = await Request.get(`slack/${slack.data.slack.slackID}/channels`, props.appState.authState)
						updateSlackConnection({
							...slack.data.slack,
							channels: channels.data.channels,
						})
						props.updateAppState({
							state: 'slack',
							data: {
								slackID: slack.data.slack.slackID,
								channels: channels.data.channels,
							},
						})
					}
				} else {
					updateSlackConnection(slack.data.slack)
				}

				updateSlackAlerts(slackAlerts)
				updateOriginalData(slackAlerts)
				if (props.show) {
					props.updatePageStatus('Finished')
				}
			}

			if (props.currentCalendar?.calendarID !== '' && props.pageStatus !== 'Finished') {
				fetchData()
			}
		},
		// eslint-disable-next-line
		[props.appState.authState, props.currentCalendar?.calendarID, props.currentOrg]
	)

	const reset = () => {
		updateSlackAlerts(originalData)
	}

	const removeAlert = async (alert: LeaveAlert) => {
		const newAlerts = slackAlerts!.filter((al: LeaveAlert) => al.leaveAlertID !== alert.leaveAlertID)
		if (alert.leaveAlertID!.includes('new')) {
			updateSlackAlerts(newAlerts.length === 0 ? [defaultSlackAlert] : newAlerts)
			updateMessages({
				type: 'add',
				data: {
					severity: 'success',
					message: 'Successfully removed slack alert',
					timeout: 3000,
					dismissible: true,
				},
			})
		} else {
			const req = await Request.del(`alert/${alert.leaveAlertID}`, props.appState.authState)
			if (req.data.success) {
				updateSlackAlerts(newAlerts.length === 0 ? [defaultSlackAlert] : newAlerts)
				updateMessages({
					type: 'add',
					data: {
						severity: 'success',
						message: 'Successfully removed slack alert',
						timeout: 3000,
						dismissible: true,
					},
				})
			} else {
				updateMessages({
					type: 'add',
					data: {
						severity: 'danger',
						message: 'Unable to remove slack alert',
						timeout: 3000,
						dismissible: true,
					},
				})
			}
		}
	}

	// send test messages in the chosen channel
	const sendTestMessage = async () => {
		try {
			if (slackAlerts && slackAlerts.length > 0 && slackAlerts![0].slackChannel) {
				const channelID = slackConnection!.channels!.find((c) => c.name === slackAlerts![0].slackChannel)
				if (channelID) {
					const channelID = slackConnection!.channels!.find((c) => c.name === (slackAlerts![0].slackChannel || `#${slackConnection!.webhookChannel}`))
					const res = await Request.get(
						`slack/${slackConnection!.slackID!}/${channelID!.channelID}/${props.appState.context}/test`,
						props.appState.authState
					)
					if (res.request) {
						updateMessages({
							type: 'add',
							data: {
								severity: 'success',
								message: 'Successfully sent slack test message',
								timeout: 3000,
								dismissible: true,
							},
						})
					}
				} else {
					//Channel no longer exists
					updateMessages({
						type: 'add',
						data: {
							severity: 'danger',
							message: 'Unable to sent slack test message:\n\n Channel no longer exists, select another channel for your configuration ',
							timeout: 3000,
							dismissible: true,
						},
					})
				}
			} else {
				const channelID = slackConnection!.channels!.find((c) => c.name === `#${slackConnection!.webhookChannel}`)
				const res = await Request.get(
					`slack/${slackConnection!.slackID!}/${channelID!.channelID}/${props.appState.context}/test`,
					props.appState.authState
				)
				if (res.request) {
					updateMessages({
						type: 'add',
						data: {
							severity: 'success',
							message: 'Successfully sent slack test message',
							timeout: 3000,
							dismissible: true,
						},
					})
				}
			}
		} catch (err) {
			updateMessages({
				type: 'add',
				data: {
					severity: 'danger',
					message: 'Unable to send slack test message',
					timeout: 3000,
					dismissible: true,
				},
			})
		}
	}

	const removeSlackConnection = async () => {
		try {
			if (slackAlerts && slackAlerts.length !== 0) {
				// remove alert first
				removeAlert(slackAlerts[0])
			}
			await Request.del(`slack/${slackConnection!.slackID!}`, props.appState.authState)
			updateMessages({
				type: 'add',
				data: {
					severity: 'success',
					message: 'Successfully removed slack connection',
					timeout: 3000,
					dismissible: true,
				},
			})
			updateSlackConnection(null)
		} catch (err) {
			updateMessages({
				type: 'add',
				data: {
					severity: 'danger',
					message: 'Unable to remove slack connection',
					timeout: 3000,
					dismissible: true,
				},
			})
		}
	}

	if (props.show) {
		if (props.pageStatus === 'Finished') {
			if (!slackConnection) {
				return (
					<>
						<FormGroup style={{ padding: '30px' }}>
							<Row className="calendar-alerts-card ">
								<Col>
									<p>You are not connected to slack</p>
								</Col>
								<Col>
									<a href={slackUrl}>
										<img
											alt="Add to Slack"
											height="40"
											width="139"
											src="https://platform.slack-edge.com/img/add_to_slack.png"
											srcSet="https://platform.slack-edge.com/img/add_to_slack.png 1x, https://platform.slack-edge.com/img/add_to_slack@2x.png 2x"
										/>
									</a>
								</Col>
							</Row>
						</FormGroup>
					</>
				)
			} else {
				return (
					<>
						<Form.Group style={{ padding: '30px' }}>
							<Row>
								<FormLabel style={{ marginTop: '-5px' }}>
									You can receive alerts in Slack, you can even ask our SlackBot about upcoming items.{' '}
									<a href={'https://help.finlert.com/leavecal/slack-integration/'} target="_blank" rel="noopener noreferrer">
										Find out how to make the most of LeaveCal in Slack.
									</a>
								</FormLabel>
							</Row>
							<Row className="email-alerts-card">
								<Row style={{ justifyContent: 'space-between' }}>
									<FormLabel className="emails-label">Team Slack Alerts</FormLabel>
								</Row>
								<Row style={{ paddingLeft: '20px' }}>
									<FormLabel style={{ marginTop: '-5px', paddingLeft: '20px' }}>
										Set up Slack alerts so your team is always aware of what&apos;s happening
									</FormLabel>
								</Row>
								{slackAlerts && slackAlerts.length !== 0
									? slackAlerts.map((alert: LeaveAlert) => (
											<ConstructAlertCard
												key={alert.leaveAlertID}
												alert={alert}
												appState={props.appState}
												reset={reset}
												slackAlerts={slackAlerts}
												slack={slackConnection}
												updateSlackAlerts={updateSlackAlerts}
												updateMessages={updateMessages}
												updateOriginalData={updateOriginalData}
												removeAlert={removeAlert}
											/>
									  ))
									: null}
							</Row>
							<Row style={{ justifyContent: 'flex-start', flexDirection: 'row' }} className="email-alerts-card">
								<Col sm="auto">
									<OverlayTrigger
										placement="auto"
										overlay={
											<Tooltip id={`tooltip-send-test`}>
												Confirm that your Slack integration is configured correctly by sending a test message.
											</Tooltip>
										}
									>
										<Button type="submit" onClick={() => sendTestMessage()}>
											Send Test Message
										</Button>
									</OverlayTrigger>
								</Col>
								<Col sm="auto">
									<OverlayTrigger
										placement="auto"
										overlay={<Tooltip id={`tooltip-remove-connection`}>Remove your Slack integration.</Tooltip>}
									>
										<Button type="submit" onClick={() => removeSlackConnection()}>
											<Trash2 /> Remove Integration
										</Button>
									</OverlayTrigger>
								</Col>
							</Row>
						</Form.Group>
						<Messages.Messages messages={messages} updateMessage={updateMessages} />
					</>
				)
			}
		} else {
			return (
				<div className="loading-gif">
					<img src={props.loadingImage} alt="Loading ..." />
				</div>
			)
		}
	} else {
		return null
	}
}

export default SlackAlerts
