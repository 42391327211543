import React, { useState } from 'react'
import moment from 'moment'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import { TimePeriodPicker } from '../../oversight/timePeriodPicker'
import { Download } from 'react-feather'

export type DateRange = {
	startDate: moment.Moment | null
	endDate: moment.Moment | null
}

const CustomToolbar = (props: any) => {
	const [viewState, setViewState] = useState(props.view ? props.view : 'month')
	const [plannerPeriod, updatePlannerPeriod] = React.useState<DateRange>(props.plannerPeriod)

	function addMonths(
		date: {
			getDate: () => any
			setMonth: (arg0: any) => void
			getMonth: () => any
			setDate: (arg0: number) => void
		},
		months: number
	) {
		const d = date.getDate()
		date.setMonth(date.getMonth() + months)
		if (date.getDate() !== d) {
			date.setDate(0)
		}
		return date
	}

	function addWeeks(date: { setDate: (arg0: any) => void; getDate: () => number }, weeks: number) {
		date.setDate(date.getDate() + 7 * weeks)
		return date
	}

	const goToWorkWeekView = () => {
		props.onView('work_week')
		setViewState('work_week')
	}
	const goToWeekView = () => {
		props.onView('week')
		setViewState('week')
	}
	const goToMonthView = () => {
		props.onView('month')
		setViewState('month')
	}

	const goToScheduleView = () => {
		props.onView('agenda')
		setViewState('agenda')
	}

	const goToBack = () => {
		if (viewState === 'month') {
			props.onNavigate('prev', addMonths(props.date, -1))
		} else if (viewState === 'week') {
			props.onNavigate('prev', addWeeks(props.date, -1))
		} else {
			props.onNavigate('prev', addWeeks(props.date, -1))
		}
	}

	const goToNext = () => {
		if (viewState === 'month') {
			props.onNavigate('next', addMonths(props.date, +1))
		} else if (viewState === 'week') {
			props.onNavigate('next', addWeeks(props.date, +1))
		} else {
			props.onNavigate('next', addWeeks(props.date, +1))
		}
	}

	const goToToday = () => {
		const now = new Date()
		props.date.setMonth(now.getMonth())
		props.date.setYear(now.getFullYear())
		props.date.setDate(now.getDate())
		props.onNavigate('current')
	}

	const checkBackPeriod = () => {
		// const today = new Date()
		const start = new Date(Date.now() - 62 * 24 * 60 * 60 * 1000) // we only allow 60 days in the past
		if (viewState === 'month') {
			const previous = new Date(props.date - 30 * 24 * 60 * 60 * 1000)
			return previous.getTime() < start.getTime()
		} else if (viewState === 'week') {
			const previous = new Date(props.date - 7 * 24 * 60 * 60 * 1000)
			return previous.getTime() < start.getTime()
		} else if (viewState === 'work_week') {
			const previous = new Date(props.date - 7 * 24 * 60 * 60 * 1000)
			return previous.getTime() < start.getTime()
		} else {
			return false
		}
	}

	const checkForwardPeriod = () => {
		// const today = new Date()
		const end = new Date(Date.now() + 365 * 24 * 60 * 60 * 1000) // Allow 1 year into the future
		if (viewState === 'month') {
			const next = new Date(props.date.valueOf() + 30 * 24 * 60 * 60 * 1000)
			return next.getTime() > end.getTime()
		} else if (viewState === 'week') {
			const next = new Date(props.date.valueOf() + 7 * 24 * 60 * 60 * 1000)
			return next.getTime() > end.getTime()
		} else if (viewState === 'work_week') {
			const next = new Date(props.date.valueOf() + 7 * 24 * 60 * 60 * 1000)
			return next.getTime() > end.getTime()
		} else {
			return false
		}
	}

	return (
		<div className="rbc-toolbar">
			<span className="rbc-btn-group">
				<button disabled={checkBackPeriod()} style={checkBackPeriod() ? { opacity: '0.4' } : {}} onClick={goToBack}>
					&#8249;
				</button>
				<button onClick={goToToday}>Today</button>
				<button disabled={checkForwardPeriod()} style={checkForwardPeriod() ? { opacity: '0.4' } : {}} onClick={goToNext}>
					&#8250;
				</button>
			</span>
			{viewState === 'agenda' ? (
				<>
					<span className="rbc-toolbar-label">
						<TimePeriodPicker
							dates={plannerPeriod}
							noPeriod={true}
							id={'plannerPeriod'}
							handleDatesChange={(dates) => {
								updatePlannerPeriod(dates)
								props.updatePlannerPeriod(dates)
								setViewState('agenda')
							}}
						/>
					</span>
					<span className="rbc-btn-group">
						<button onClick={() => props.downloadCSV()}>
							<Download /> Export .xlsx{' '}
						</button>
					</span>
				</>
			) : (
				<span className="rbc-toolbar-label">{moment(props.date).format('MMMM YYYY')}</span>
			)}

			<span className="rbc-btn-group">
				<button onClick={goToScheduleView}>Planner</button>
				<button onClick={goToMonthView}>Month</button>
				<button onClick={goToWeekView}>Week</button>
				<button onClick={goToWorkWeekView}>Work Week</button>
			</span>
		</div>
	)
}

export default CustomToolbar
