import React from 'react'
import axios from 'axios'
import { Button, Container, Col, Row, Table, Form } from 'react-bootstrap'

import * as Messages from '../../components/ui/messages/messages'
import { Dialog } from '../../components/ui/dialog/dialog'

import * as ApiDefinitions from '../../../../back-end/utilities/apiDefinitions'
import * as AppDefinitions from '../../App.d'
import { SubSyncPageStatus } from '../subsync/subsync.d'

import * as Request from '../../utilities/request'
import * as I from '../../utilities/me'
import { StripeConfig } from '../../constants/config'

import { AppContext } from '../../App'
import StripeLogo from '../../images/stripe-logo-rounded.svg'

const ScreensStripeConnections = () => {
	const { appState } = React.useContext(AppContext)

	const [pageStatus, setPageStatus] = React.useState<SubSyncPageStatus>('Loading')

	const [connections, updateConnections] = React.useState<ApiDefinitions.StripeOrg[] | null>(null)
	const [editingConnection, updateEditingConnection] = React.useState<ApiDefinitions.StripeOrg | null>(null)
	const [showDialog, updateShowDialog] = React.useState<string>('')

	const [messages, updateMessages] = Messages.useMessageReducer([])

	const getData = (authState: AppDefinitions.AuthState, permission: string | null) => {
		const getConnections = async () => {
			setPageStatus('Loading')
			const req = await Request.get(`stripeorg?where=StripeOrgIsConnected==1`, authState)
			updateConnections(req.data.stripeOrgs)
			setPageStatus('Ready')
		}

		if (I.have('admin', permission)) {
			getConnections()
		}
	}

	React.useEffect(() => {
		getData(appState.authState, appState.permission[appState.context])
	}, [appState.authState, appState.permission[appState.context]]) // eslint-disable-line

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const name = e.target.name
		const value = e.target.value
		const updatedConnection = { ...editingConnection, [name]: value }
		updateEditingConnection(updatedConnection)
	}

	const handleCancel = () => {
		updateEditingConnection(null)
		updateConnections(connections!.filter((con) => con.stripeOrgID !== 'new'))
	}

	// TODO: remove create and edit functionality from this page
	const handleSave = async () => {
		setPageStatus('Submitting')
		const isCreate = editingConnection!.stripeOrgID === 'new'

		try {
			// check if api key has changed and verify it first before saving
			if (
				editingConnection &&
				connections &&
				editingConnection.apiKey !== connections.find((con) => con.stripeOrgID === editingConnection.stripeOrgID)!.apiKey
			) {
				await Request.post('stripeorg/verify', editingConnection, appState.authState)
			}

			const res = isCreate
				? await Request.post('stripeorg', editingConnection, appState.authState)
				: await Request.put('stripeorg', editingConnection, appState.authState)

			if (res.status === 200) {
				updateEditingConnection(null)
				getData(appState.authState, appState.permission[appState.context])
				updateMessages({
					type: 'add',
					data: {
						severity: 'success',
						message: `Successfully ${isCreate ? 'added' : 'updated'} Stripe connection`,
						timeout: 3000,
						dismissible: true,
					},
				})
				setPageStatus('Ready')
			} else {
				updateMessages({
					type: 'add',
					data: {
						severity: 'danger',
						message: `Unable to ${isCreate ? 'add' : 'update'} Stripe connection`,
						timeout: 3000,
						dismissible: true,
					},
				})
				setPageStatus('Error')
			}
		} catch (e) {
			let errorMessage = `Unable to ${isCreate ? 'add' : 'update'} Stripe connection`
			if (axios.isAxiosError(e) && e.response) {
				errorMessage = e.response.data.message
			}
			updateMessages({
				type: 'add',
				data: {
					severity: 'danger',
					message: errorMessage,
					timeout: 3000,
					dismissible: true,
				},
			})
			setPageStatus('Error')
		}
	}

	// TODO: remove old delete functionality and replace with ?link to app settings page in stripe?
	const handleDelete = async (stripeOrgID: string) => {
		setPageStatus('Submitting')
		try {
			const res = await Request.del(`stripeorg/${stripeOrgID}`, appState.authState)

			if (res.status === 200) {
				getData(appState.authState, appState.permission[appState.context])
				updateMessages({
					type: 'add',
					data: {
						severity: 'success',
						message: 'Successfully removed Stripe connection',
						timeout: 3000,
						dismissible: true,
					},
				})
				setPageStatus('Ready')
			} else {
				updateMessages({
					type: 'add',
					data: {
						severity: 'danger',
						message: 'Unable to remove Stripe connection',
						timeout: 3000,
						dismissible: true,
					},
				})
				setPageStatus('Error')
			}
		} catch (e) {
			updateMessages({
				type: 'add',
				data: {
					severity: 'danger',
					message: 'Unable to remove Stripe connection',
					timeout: 3000,
					dismissible: true,
				},
			})
			setPageStatus('Error')
		}
	}

	if (connections) {
		return (
			<Container className="oversight-container">
				<Row>
					<Col sm="auto">
						<h2>Stripe Connections</h2>
					</Col>
					<Col></Col>
					<Col sm="auto">
						<Button variant="primary" style={{ display: 'flex' }} disabled={pageStatus === 'Submitting'}>
							<a href={StripeConfig.InstallLink} style={{ color: 'inherit', display: 'flex', flexDirection: 'row' }}>
								<img src={StripeLogo} alt="Connect to Stripe" style={{ width: '40px' }}></img>
								<p style={{ margin: '10px', color: '#0C84AB' }}>Connect to Stripe</p>
							</a>
						</Button>
					</Col>
				</Row>
				<Row>
					<Col>
						<Table borderless responsive>
							<thead>
								<tr className="header-row">
									<th>Name</th>
									<th></th>
								</tr>
							</thead>
							<tbody className={'card-table-body well-spaced-table'}>
								{connections.length > 0 ? (
									connections.map((con, index) => (
										<tr key={con.stripeOrgID} className={`stripe-row ${index % 2 === 0 ? 'stripe-row-body' : ''}`}>
											<td>
												{editingConnection?.stripeOrgID === con.stripeOrgID ? (
													<Form.Control
														type="text"
														name="name"
														placeholder="Stripe Organisation Name"
														onChange={handleChange}
														value={editingConnection!.name}
														autoFocus
														disabled={pageStatus === 'Submitting'}
													/>
												) : (
													<>{con.name}</>
												)}
											</td>
											<td>
												{con.apiKey ? (
													<>
														{editingConnection?.stripeOrgID === con.stripeOrgID ? (
															<>
																<Button
																	variant="secondary"
																	onClick={handleCancel}
																	disabled={pageStatus === 'Submitting'}
																	style={{ marginRight: '5px' }}
																>
																	Cancel
																</Button>
																<Button variant="primary" onClick={handleSave} disabled={pageStatus === 'Submitting'}>
																	Save
																</Button>
															</>
														) : (
															<>
																<Button
																	variant="secondary"
																	onClick={() => updateEditingConnection(con)}
																	disabled={pageStatus === 'Submitting'}
																	style={{ marginRight: '5px' }}
																>
																	Edit
																</Button>
																<Button
																	variant="secondary"
																	onClick={() => updateShowDialog(con.stripeOrgID!)}
																	disabled={pageStatus === 'Submitting'}
																>
																	Delete
																</Button>
															</>
														)}
													</>
												) : (
													<Button variant="primary">
														<a
															href={`https://dashboard.stripe.com/settings/apps/${StripeConfig.AppId}`}
															target={'_blank'}
															rel="noreferrer"
														>
															Uninstall
														</a>
													</Button>
												)}
											</td>
										</tr>
									))
								) : (
									<tr key={'no connections'} className={`stripe-row stripe-row-body`}>
										<td>No Stripe Connections</td>
										<td />
									</tr>
								)}
							</tbody>
						</Table>
					</Col>
				</Row>
				{showDialog ? (
					<Dialog
						show={true}
						handleClose={() => updateShowDialog('')}
						handleConfirm={() => {
							handleDelete(showDialog)
						}}
						titleText={'Remove Stripe Organisation'}
						bodyText={'Are you sure you want to remove this organisation?'}
					/>
				) : null}
				<Messages.Messages messages={messages} updateMessage={updateMessages} />
			</Container>
		)
	} else if (!I.have('admin', appState.permission[appState.context])) {
		return (
			<Container className="oversight-container">
				<Row>
					<Col></Col>
					<Col xs="auto">
						<p>You do not have permission to view this page.</p>
					</Col>
					<Col></Col>
				</Row>
			</Container>
		)
	} else {
		return <div></div>
	}
}

export default ScreensStripeConnections
