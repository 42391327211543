const Admin = 'e864733a-aecf-48f9-9a23-5268418889a8'
const GeneralUser = 'abc4733a-ah56-56g5-3f93-674541888def'

const am = (permission: string | null) => {
	return permission === Admin ? 'Admin' : 'General User'
}

const have = (role: 'admin' | 'general', permissionID: string | null) => {
	return (role === 'admin' && permissionID === Admin) || (role === 'general' && permissionID === GeneralUser)
}

export { am, have, Admin, GeneralUser }
