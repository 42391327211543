import { productContext } from './context'
import { Auth } from 'aws-amplify'

const getSignInProvider = () => {
	return (
		'SignInWithXero' +
		(productContext === 'leavecal' ? 'LeaveCal' : '') +
		(productContext === 'tayg' ? 'Tayg' : '') +
		(productContext === 'subsync' ? 'SubSync' : '')
	)
}

const refreshSession = async () => {
	const session = await Auth.currentSession()
	const user = await Auth.currentAuthenticatedUser()
	return new Promise((resolve, reject) => {
		// have to use it as no type can be located or inferred
		// eslint-disable-next-line
		user.refreshSession(session.getRefreshToken(), (err: any, session: any) => {
			if (err) {
				reject(err)
			} else {
				resolve(session)
			}
		})
	})
}

export { getSignInProvider, refreshSession }
