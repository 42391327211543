// libraries
import * as React from 'react'
import { Col, OverlayTrigger, Row, Tooltip, Badge, Button } from 'react-bootstrap'
import { useLocation } from 'react-router-dom'

// definitions
import { AccountXeroOrg, User, Calendar as CalendarData, Account } from '../../../../back-end/utilities/apiDefinitions'
import { ModalState } from './leavecal.d'
import * as XeroProducts from '../../constants/products'

// components
import ModalList from '../../components/ui/modalList/modalList'
import OnBoardModal from '../../components/ui/onBoardModal/onBoardModal'
import LeaveCalOnboardModal from '../../components/leavecal/onboardModal'
import BodyFrame, { FrameType } from '../../components/leavecal/frame'
import * as Icons from '../../components/ui/icons/icons'

// utilities
import * as I from '../../utilities/me'
import * as Format from '../../utilities/format'
import * as Request from '../../utilities/request'
import OrgCalPicker from '../../components/leavecal/OrgCalPicker'
import { IndentedDropdownSelector } from '../../components/leavecal/IndentedDropDown'
import { AppContext } from '../../App'

const initialModalState: ModalState = {
	modal: '',
	props: {},
	size: 'sm',
}

const getUpdatedTs = (selectedCalendar: CalendarData, selectedOrg: AccountXeroOrg) => {
	// get the most recent date between calendar.fileLastUpdatedTs, org.leaveCalLastDataFetch and org.leaveCalLastEmployeeDataFetch
	const dates = [selectedCalendar.fileLastUpdatedTs, selectedOrg.xeroOrg?.leaveCalLastDataFetch, selectedOrg.xeroOrg?.leaveCalLastEmployeeDataFetch]
		.filter((x) => x)
		.map((x) => new Date(x!))
	const maxDate = new Date(Math.max(...dates.map((d) => d.getTime())))
	return Format.dateTime(maxDate)
}

const getFrameFromHash = (hash: string): FrameType => {
	return ['calendar', 'emails', 'google', 'slack', 'settings', 'employees', 'leaveBalances', 'overview'].includes(hash) ? (hash as FrameType) : 'calendar'
}

const canAccountUseKeypay = (accounts: Account[], currentAccountID: string) => {
	if (accounts.length === 1) {
		return accounts[0].canUseKeypay!
	} else {
		const currentAccount = accounts.find((a) => a.accountID === currentAccountID)
		if (currentAccount) {
			return currentAccount!.canUseKeypay!
		} else {
			return false
		}
	}
}

const LeaveCal = () => {
	const { appState, updateAppState } = React.useContext(AppContext)
	const location = useLocation()

	const [orgs, updateOrgs] = React.useState<AccountXeroOrg[] | null>(null)
	const [, updateUsers] = React.useState<User[] | null>(null)
	const [calendars, updateCalendars] = React.useState<CalendarData[] | null>(null)
	const [xeroConsentUrl, updateXeroConsentUrl] = React.useState<string | null>(null)
	const [keypayConsentUrl, updateKeypayConsentUrl] = React.useState<string | null>(null)
	const [shownModal, updateShownModal] = React.useState<ModalState>(initialModalState)
	const [selectedOrg, updateSelectedOrg] = React.useState<AccountXeroOrg | null>(null)
	const [selectedCalendar, updateSelectedCalendar] = React.useState<CalendarData | null>(null)
	const [currentFrame, updateCurrentFrame] = React.useState<FrameType>(getFrameFromHash(location.hash.substring(1)))
	const [pageStatus, updatePageStatus] = React.useState<string>('Loading')

	const updateFrameAndHash = (frame: FrameType) => {
		window.location.hash = frame
		updateCurrentFrame(frame)
	}

	React.useEffect(() => {
		if (selectedOrg) {
			updateAppState({
				state: 'selectedOrg',
				data: selectedOrg,
			})
		}
	}, [selectedOrg]) // eslint-disable-line

	const refreshData = React.useCallback(
		async (org?: AccountXeroOrg, cal?: CalendarData) => {
			const fetchData = async () => {
				const [accountXeroOrgs, calendars, users, currentAccount, xeroUrl, keypayUrl] = await Promise.all(
					[
						Request.get('organisation?where=AccountXeroOrgIsLeaveCal==1', appState.authState),
						Request.get('calendar?where=AccountXeroOrgIsLeaveCal==1', appState.authState),
						Request.get('user', appState.authState),
						Request.get('account', appState.authState),
					].concat(
						I.have('admin', appState.permission[appState.context])
							? [Request.get('xero/url/leavecal', appState.authState), Request.get('keypay/url', appState.authState)]
							: []
					)
				)

				updateOrgs(accountXeroOrgs.data.accountXeroOrgs)
				updateUsers(users.data.users)
				updateCalendars(calendars.data.calendars)
				if (xeroUrl && xeroUrl.data.url) {
					updateXeroConsentUrl(xeroUrl.data.url)
				}
				if (keypayUrl && keypayUrl.data.url) {
					updateKeypayConsentUrl(keypayUrl.data.url)
				}

				//We pick org and the calendar choosen by either the first one to come back or the selected
				//console.log('org', org, 'cal', cal, 'accountXeroOrgs.data.accountXeroOrgs', accountXeroOrgs.data.accountXeroOrgs)
				if (org || cal) {
					if (org) {
						updateSelectedOrg(org)
					}
					if (cal) {
						updateSelectedCalendar(cal)
					}
				} else if (accountXeroOrgs.data.accountXeroOrgs.length === 0 && I.am(appState.permission[appState.context]) === 'Admin') {
					updateShownModal({
						modal: 'Connect',
						props: {
							xeroConsentUrl: xeroUrl.data.url,
							canUseKeypay: canAccountUseKeypay(currentAccount.data.accounts, appState!.authState!.attributes!['custom:currentAccountId']),
							keypayConsentUrl: keypayUrl.data.url,
							product: 'LeaveCal',
						},
						size: 'md',
					})
				} else if (calendars.data.calendars.length === 0) {
					const axo = accountXeroOrgs.data.accountXeroOrgs[0]
					updateSelectedOrg(axo)
					updateShownModal({
						modal: 'Onboarding',
						props: {
							currentOrg: axo,
							accountXeroOrgID: axo.accountXeroOrgID,
							organisationID: axo.xeroOrg.xeroOrgID,
							organisationName: axo.xeroOrg.name,
							organisationVersion: axo.xeroOrg.version,
							appState: appState,
							firstCalendar: true,
						},
						size: 'lg',
					})
				} else if (accountXeroOrgs.data.accountXeroOrgs.length > 0 && calendars.data.calendars.length > 0) {
					if (localStorage.getItem('selectedOrgID')) {
						let selOrg = accountXeroOrgs.data.accountXeroOrgs.find(
							(org: AccountXeroOrg) => org.xeroOrg!.xeroOrgID === localStorage.getItem('selectedOrgID')
						)
						if (!selOrg) {
							selOrg = accountXeroOrgs.data.accountXeroOrgs[0]
							localStorage.setItem('selectedOrgID', selOrg.xeroOrg!.xeroOrgID)
						}

						updateSelectedOrg(selOrg)
						const calsForOrg = calendars.data.calendars.filter((cal: CalendarData) => cal.accountXeroOrgID === selOrg.accountXeroOrgID)
						const selectedCal = calsForOrg.find((cal: CalendarData) => cal.calendarID === localStorage.getItem('selectedCalendarID'))
						if (selectedCal) {
							updateSelectedCalendar(selectedCal)
						} else if (calsForOrg.length > 0) {
							updateSelectedCalendar(calsForOrg[0])
						} else {
							updateSelectedCalendar({ calendarID: '' })
						}
						if (calsForOrg.length === 0) {
							updateShownModal({
								modal: 'Onboarding',
								props: {
									accountXeroOrgID: selOrg.accountXeroOrgID,
									organisationID: selOrg.xeroOrg.xeroOrgID,
									organisationName: selOrg.xeroOrg.name,
									organisationVersion: selOrg.xeroOrg.version,
									appState: appState,
									firstCalendar: true,
								},
								size: 'lg',
							})
						}
					} else {
						const firstCreateTsOrg = accountXeroOrgs.data.accountXeroOrgs.sort((a: AccountXeroOrg, b: AccountXeroOrg) =>
							(b.createTs || '').localeCompare(a.createTs || '')
						)[0]
						updateSelectedOrg(firstCreateTsOrg)
						const calsForOrg = calendars.data.calendars.filter((cal: CalendarData) => cal.accountXeroOrgID === firstCreateTsOrg.accountXeroOrgID)
						updateSelectedCalendar(calsForOrg && calsForOrg.length !== 0 ? calsForOrg[0] : { calendarID: '' })
						if (calsForOrg.length === 0) {
							updateShownModal({
								modal: 'Onboarding',
								props: {
									accountXeroOrgID: firstCreateTsOrg.accountXeroOrgID,
									organisationID: firstCreateTsOrg.xeroOrg.xeroOrgID,
									organisationName: firstCreateTsOrg.xeroOrg.name,
									organisationVersion: firstCreateTsOrg.xeroOrg.version,
									appState: appState,
									firstCalendar: true,
								},
								size: 'lg',
							})
						}
					}
				}
			}

			if (appState.authState.isLoggedIn && appState.permission.leavecal) {
				fetchData()
			}
		},
		// eslint-disable-next-line
		[appState.authState, appState.permission.leavecal]
	)

	React.useEffect(
		() => {
			if (appState.authState.isLoggedIn) {
				refreshData()
			}
		},
		// eslint-disable-next-line
		[appState.authState]
	)

	React.useEffect(() => {
		if (shownModal.modal === 'ChooseOrgCal') {
			updateShownModal({
				modal: 'ChooseOrgCal',
				props: {
					appState: appState,
					orgs: orgs,
					cals: calendars,
					currentOrg: selectedOrg,
					currentCal: selectedCalendar,
					updateCurrentOrg: updateSelectedOrg,
					updateCurrentCal: updateSelectedCalendar,
				},
				size: 'md',
			})
		}
	}, [selectedOrg, selectedCalendar]) // eslint-disable-line

	const updateCurrentCalendar = (cal: CalendarData) => {
		if (calendars) {
			const newCals = calendars.map((c) => {
				if (c.calendarID === cal.calendarID) {
					return cal
				} else {
					return c
				}
			})
			updateCalendars(newCals)
			updateSelectedCalendar(cal)
		}
	}

	const updateCurrentOrg = (org: AccountXeroOrg) => {
		if (selectedOrg) {
			updateSelectedOrg(org)
		}
	}

	return (
		<>
			<Row className="leavecal-container">
				<Col>
					<Row className="leavecal-blue-bar">
						<IndentedDropdownSelector
							organisations={orgs ? orgs : []}
							calendars={calendars ? calendars : []}
							selectedCal={selectedCalendar && selectedCalendar.calendarID ? selectedCalendar.calendarID : ''}
							selectedOrg={selectedOrg && selectedOrg.accountXeroOrgID ? selectedOrg.accountXeroOrgID : ''}
							updateSelectedOrg={updateSelectedOrg}
							updateSelectedCalendar={updateSelectedCalendar}
							appState={appState}
							refreshData={refreshData}
							updatePageStatus={updatePageStatus}
						/>
						{selectedOrg &&
						selectedOrg.userAccountXeroOrgAuth &&
						(selectedOrg.userAccountXeroOrgAuth.refreshToken === null || selectedOrg.userAccountXeroOrgAuth?.connectionID === null) ? (
							<Col sm="auto">
								<Badge variant="danger" className={'clickable-icon'}>
									{xeroConsentUrl && keypayConsentUrl ? (
										<a
											href={selectedOrg?.xeroOrg?.apiProvider === 'keypay' ? keypayConsentUrl : xeroConsentUrl}
											style={{ color: 'inherit' }}
										>
											Re-auth needed
										</a>
									) : (
										<span>Re-auth needed</span>
									)}
								</Badge>
							</Col>
						) : null}
						{selectedOrg &&
						selectedOrg.xeroOrg &&
						selectedOrg.xeroOrg.products &&
						(selectedOrg.xeroOrg.products.findIndex((p) => p.productID === XeroProducts.LeaveCalTrial.productID) >= 0 ||
							selectedOrg.xeroOrg.products.length === 0) ? (
							<Col sm="auto">
								<Badge variant="info" className={'clickable-icon'}>
									{I.have('admin', appState.permission[appState.context]) ? (
										<a
											href={`https://apps.xero.com/${
												selectedOrg?.xeroOrg ? `${selectedOrg.xeroOrg.shortCode}/${selectedOrg.xeroOrg.version}/` : ''
											}subscribe/95a516a0-5aaf-4445-ba80-d20f3aaf855a`}
											style={{ color: 'black' }}
										>
											Trial Account
										</a>
									) : (
										<span style={{ color: 'black' }}>Trial Account</span>
									)}
								</Badge>
							</Col>
						) : null}
						<Col className="position-right" style={{ paddingRight: '10px', paddingTop: '8px' }}>
							<span>{`Last Sync: ${selectedCalendar && selectedOrg ? getUpdatedTs(selectedCalendar, selectedOrg) : 'Never'}`}</span>
						</Col>
						{selectedOrg && I.have('admin', appState.permission[appState.context]) ? (
							<Col sm="auto">
								<OverlayTrigger placement="auto" overlay={<Tooltip id={`tooltip-xero`}>Add new calendar.</Tooltip>}>
									<div>
										<Button
											type="button"
											onClick={() =>
												updateShownModal({
													modal: 'Onboarding',
													props: {
														accountXeroOrgID: selectedOrg.accountXeroOrgID,
														organisationID: selectedOrg.xeroOrg!.xeroOrgID,
														organisationName: selectedOrg.xeroOrg!.name,
														organisationVersion: selectedOrg.xeroOrg!.version,
														appState: appState,
														firstCalendar: calendars!.length === 0,
													},
													size: 'lg',
												})
											}
											style={{ cursor: 'pointer' }}
										>
											Add Calendar
										</Button>
									</div>
								</OverlayTrigger>
							</Col>
						) : null}
					</Row>
					<ModalList
						handleClose={(...args: unknown[]) => {
							updateShownModal(initialModalState)
							if (shownModal.modal === 'Onboarding' && args.length > 0 && args[0] === 'refreshData') {
								const org = args[1] as AccountXeroOrg
								const cal = args[2] as CalendarData
								refreshData(org, cal)
							}
						}}
						show={shownModal.modal}
						data={shownModal.props}
						size={shownModal.size}
						modals={[
							{
								id: 'ChooseOrgCal',
								body: OrgCalPicker,
							},
							{
								id: 'Connect',
								body: OnBoardModal,
							},
							{
								id: 'Onboarding',
								body: LeaveCalOnboardModal,
							},
						]}
					/>
				</Col>
			</Row>

			<Row className="leavecal-body">
				<Col xs="auto" className="leavecal-left-menu">
					{I.have('admin', appState.permission[appState.context]) ? (
						<Row>
							<OverlayTrigger placement="auto" overlay={<Tooltip id={`tooltip-overview`}>Overview</Tooltip>}>
								<div className={'clickable'} onClick={() => updateFrameAndHash('overview')}>
									<img
										src={Icons.Overview}
										style={{
											width: '40px',
											filter: `${currentFrame === 'overview' ? 'opacity(1)' : 'opacity(0.6)'}`,
										}}
										alt="Overview icon"
									/>
								</div>
							</OverlayTrigger>
						</Row>
					) : null}
					<Row>
						<OverlayTrigger placement="auto" overlay={<Tooltip id={`tooltip-calendar`}>Calendar</Tooltip>}>
							<div className={'clickable'} onClick={() => updateFrameAndHash('calendar')}>
								<img
									src={Icons.Calendar}
									style={{
										width: '40px',
										filter: `${currentFrame === 'calendar' ? 'opacity(1)' : 'opacity(0.6)'}`,
									}}
									alt="Calendar icon"
								/>
							</div>
						</OverlayTrigger>
					</Row>
					{I.have('admin', appState.permission[appState.context]) ? (
						<Row>
							<OverlayTrigger placement="auto" overlay={<Tooltip id={`tooltip-email`}>E-mail alerts</Tooltip>}>
								<div className={'clickable'} onClick={() => updateFrameAndHash('emails')}>
									<img
										src={Icons.Mail}
										style={{
											width: '40px',
											filter: `${currentFrame === 'emails' ? 'opacity(1)' : 'opacity(0.6)'}`,
										}}
										alt="E-Mail icon"
									/>
								</div>
							</OverlayTrigger>
						</Row>
					) : null}
					{I.have('admin', appState.permission[appState.context]) ? (
						<Row>
							<OverlayTrigger placement="auto" overlay={<Tooltip id={`tooltip-slack`}>Slack alerts</Tooltip>}>
								<div className={'clickable'} onClick={() => updateFrameAndHash('slack')}>
									<img
										src={Icons.Slack}
										style={{
											width: '40px',
											filter: `${currentFrame === 'slack' ? 'opacity(1)' : 'opacity(0.6)'}`,
										}}
										alt="Slack icon"
									/>
								</div>
							</OverlayTrigger>
						</Row>
					) : null}
					<Row>
						<OverlayTrigger placement="auto" overlay={<Tooltip id={`tooltip-employees`}>Team Members</Tooltip>}>
							<div className={'clickable'} onClick={() => updateFrameAndHash('employees')}>
								<img
									src={Icons.Employee}
									style={{
										width: '40px',
										filter: `${currentFrame === 'employees' ? 'opacity(1)' : 'opacity(0.6)'}`,
									}}
									alt="Employees icon"
								/>
							</div>
						</OverlayTrigger>
					</Row>
					{I.have('admin', appState.permission[appState.context]) &&
					selectedOrg &&
					selectedOrg.xeroOrg &&
					selectedOrg.xeroOrg.products &&
					selectedOrg.xeroOrg.products.findIndex((p) => p.productID === XeroProducts.LeaveBalance.productID) >= 0 ? (
						<Row>
							<OverlayTrigger placement="auto" overlay={<Tooltip id={`tooltip-leaveBalances`}>Leave Balances</Tooltip>}>
								<div className={'clickable'} onClick={() => updateFrameAndHash('leaveBalances')}>
									<img
										src={Icons.Balance}
										style={{
											width: '40px',
											filter: `${currentFrame === 'leaveBalances' ? 'opacity(1)' : 'opacity(0.6)'}`,
										}}
										alt="Leave Balances icon"
									/>
								</div>
							</OverlayTrigger>
						</Row>
					) : null}
					{I.have('admin', appState.permission[appState.context]) ? (
						<Row>
							<OverlayTrigger placement="auto" overlay={<Tooltip id={`tooltip-settings`}>Settings</Tooltip>}>
								<div className={'clickable'} onClick={() => updateFrameAndHash('settings')}>
									<img
										src={Icons.Settings}
										style={{
											width: '40px',
											filter: `${currentFrame === 'settings' ? 'opacity(1)' : 'opacity(0.6)'}`,
										}}
										alt="Settings icon"
									/>
								</div>
							</OverlayTrigger>
						</Row>
					) : null}
					{/* this is to fill up the rest of the page */}
					<div style={{ flexGrow: 1 }}> </div>
				</Col>
				<Col>
					<BodyFrame
						appState={appState}
						updateAppState={updateAppState}
						pageStatus={pageStatus}
						currentFrame={currentFrame}
						currentOrg={selectedOrg}
						currentCalendar={selectedCalendar}
						xeroOrgCalendars={
							calendars && selectedOrg ? calendars.filter((cal: CalendarData) => cal.accountXeroOrgID === selectedOrg.accountXeroOrgID) : []
						}
						updateCurrentCalendar={updateCurrentCalendar}
						updateCurrentOrg={updateCurrentOrg}
						updatePageStatus={updatePageStatus}
						updateModal={() => {
							if (selectedOrg && selectedOrg.xeroOrg) {
								updateShownModal({
									modal: 'Onboarding',
									props: {
										accountXeroOrgID: selectedOrg.accountXeroOrgID,
										organisationID: selectedOrg.xeroOrg.xeroOrgID,
										organisationName: selectedOrg.xeroOrg.name,
										organisationVersion: selectedOrg.xeroOrg.version,
										appState: appState,
										firstCalendar: calendars!.length === 0,
									},
									size: 'lg',
								})
							}
						}}
					/>
				</Col>
			</Row>
		</>
	)
}

export default LeaveCal
