// libraries
import React from 'react'
import { Col, Row, Image } from 'react-bootstrap'

import CloseButton from '../closeButton/closeButton'
import { ModalListChildProps } from '../modalList/modalList'

import XeroConnImage from '../../../images/xero_connections.png'

interface OnBoardModalProps extends ModalListChildProps {
	xeroConsentUrl: string
	keypayConsentUrl: string
	product: string
	canUseKeypay: boolean
}

const OnBoardModal = (props: OnBoardModalProps) => {
	return (
		<div className="oversight-container">
			<Row>
				<Col sm="auto">
					<h3>Let&apos;s get started</h3>
				</Col>
				<Col></Col>
				<Col sm="auto">
					<CloseButton onClick={props.handleClose} disabled={false} />
				</Col>
			</Row>
			<Row>
				<Col>
					<p>
						To use {props.product} you need to connect {props.product === 'Tax As You Go' ? 'to your accounting platform' : 'to a payroll system'}
					</p>
					<p>
						<a className="btn btn-dark center" href={props.xeroConsentUrl}>
							Connect to Xero
						</a>
					</p>
					{props.canUseKeypay ? (
						<>
							<p style={{ marginBottom: '10px', textAlign: 'center' }}>or</p>
							<p>
								<a className="btn btn-dark center" href={props.keypayConsentUrl}>
									Connect to Keypay
								</a>
							</p>
						</>
					) : null}

					<p>
						To add more connections in the future you can go to the Connection screen{' '}
						<Image src={XeroConnImage} className="center" style={{ width: '80%' }} />
					</p>
				</Col>
			</Row>
		</div>
	)
}

export default OnBoardModal
