// libraries
import React, { Dispatch, SetStateAction } from 'react'
import { Col, Row, Form, Tabs, Tab, Button, Badge } from 'react-bootstrap'

// definitions
import * as AppDefinitions from '../../App.d'
import { AccountXeroOrg, MetricGroup, Tag } from '../../../../back-end/utilities/apiDefinitions'
import { DataSetMetricGroupedByXeroOrg, MetricValue } from '../oversight/oversight.d'

// components
import CloseButton from '../../components/ui/closeButton/closeButton'
import PlusButton from '../../components/ui/plusButton/plusButton'
import { OrgOverview } from './orgOverview'
import Select, { components, OnChangeValue } from 'react-select'

// utilies
import * as Request from '../../utilities/request'
import * as I from '../../utilities/me'
import * as Format from '../../utilities/format'
import * as Messages from '../../components/ui/messages/messages'
import { renderIcon } from '../../utilities/icon'
import { OrgSettings } from './orgSettings'
import { ModalListChildProps } from '../../components/ui/modalList/modalList'

interface SingleAccountClientProps extends ModalListChildProps {
	appState: AppDefinitions.AppState
	accountXeroOrg: AccountXeroOrg
	accountXeroOrgMetrics: MetricGroup[]
	filteredMetrics: MetricValue[]
	refreshData: (modalProps?: any) => Promise<void>
	updateReports?: Dispatch<SetStateAction<DataSetMetricGroupedByXeroOrg[] | null>>
	tab: 'live' | 'pit'
	fetchedTs?: string
}

type TagOption = {
	label: string
	value: string | undefined
	colour: string | undefined
	icon: string | undefined
}

const SingleOrg = (props: SingleAccountClientProps) => {
	const org = props.accountXeroOrg

	const [user, setUser] = React.useState<string>(org.responsibleUser!.userID || '')
	const [users, setUsers] = React.useState<null | any[]>(null)
	const [orgTags, setOrgTags] = React.useState<Tag[]>(org.tags || [])
	const [tags, setTags] = React.useState<null | Tag[]>(null)
	const [isTagsDisabled, setTagsDisabled] = React.useState<boolean>(true)

	const [key, setKey] = React.useState<string>('overview')
	const [isReady, setIsReady] = React.useState<boolean>(false)
	const [messages, updateMessages] = Messages.useMessageReducer([])

	React.useEffect(() => {
		const getTagsAndUsers = async () => {
			await Promise.all([getTags(), getUsers()])
			setIsReady(true)
		}

		const getTags = async () => {
			const req = await Request.get(`tag`, props.appState.authState)
			setTags(req.data.tags)
		}

		const getUsers = async () => {
			const req = await Request.get(`user`, props.appState.authState)
			setUsers(req.data.users)
		}

		getTagsAndUsers()
	}, [props.appState.authState])

	const handleOwnerChange = async (eventKey: any) => {
		if (users) {
			const user = users.find((u: any) => u.userID === eventKey.target.value)

			if (user) {
				setUser(user.userID)

				const updateValue = {
					responsibleUserID: user.userID,
					accountXeroOrgID: org.accountXeroOrgID,
				}

				await Request.put(`organisation/${org.accountXeroOrgID}`, updateValue, props.appState.authState)

				const newProps: SingleAccountClientProps = {
					...props,
					accountXeroOrg: {
						...props.accountXeroOrg,
						responsibleUser: user,
					},
				}
				props.refreshData({ modal: 'EditOrg', props: newProps, size: 'xl' })
			}
		}
	}

	const handleTagChange = async (event: OnChangeValue<TagOption, true>) => {
		if (tags && event) {
			const newTags = tags.filter((t) => event.some((e: TagOption) => e.value === t.tagID))
			setOrgTags(newTags)
		}
	}

	const updateTags = async () => {
		setIsReady(false)

		const req = await Request.put(
			`organisation/${org!.accountXeroOrgID}`,
			{
				accountXeroOrgID: org!.accountXeroOrgID,
				tags: orgTags,
			},
			props.appState.authState
		)

		const newProps: SingleAccountClientProps = {
			...props,
			accountXeroOrg: {
				...props.accountXeroOrg,
				tags: orgTags,
			},
		}
		await props.refreshData({ modal: 'EditOrg', props: newProps, size: 'xl' })

		if (req.data.success) {
			updateMessages({
				type: 'add',
				data: {
					severity: 'success',
					message: 'Successfully updated tags',
					timeout: 3000,
					dismissible: true,
				},
			})
		} else {
			updateMessages({
				type: 'add',
				data: {
					severity: 'danger',
					message: 'Unable to update tags',
					timeout: 3000,
					dismissible: true,
				},
			})
		}

		setTimeout(() => setIsReady(true), 2000)
	}

	if (users !== null && org !== null && tags !== null) {
		return (
			<div className="oversight-container">
				<Row>
					<Col sm="auto">
						<h3>{org.xeroOrg!.name}</h3>
						{org.xeroOrg && org.xeroOrg.lastDataFetch ? (
							<small>
								Data Last Fetched:{' '}
								{props.tab === 'pit' && props.fetchedTs ? Format.dateTime(props.fetchedTs) : Format.dateTime(org.xeroOrg.lastDataFetch)}
							</small>
						) : null}
					</Col>
					<Col sm="auto">
						<a
							href={`https://go.xero.com/organisationlogin/default.aspx?shortcode=${org.xeroOrg!.shortCode}`}
							target="_blank"
							rel="noopener noreferrer"
							className="btn btn-success"
						>
							Jump to Xero
						</a>
					</Col>
					<Col></Col>
					<Col sm="auto">
						<Form.Group as={Row} controlId="formOwner">
							<Form.Label column sm="auto">
								Owner
							</Form.Label>
							<Col sm="auto">
								<Form.Control
									as="select"
									name={'responsibleUserID'}
									value={user}
									onChange={handleOwnerChange}
									readOnly={!I.have('admin', props.appState.permission[props.appState.context])}
									plaintext={!I.have('admin', props.appState.permission[props.appState.context])}
									disabled={!I.have('admin', props.appState.permission[props.appState.context])}
									className={!I.have('admin', props.appState.permission[props.appState.context]) ? 'no-hover' : ''}
								>
									{users.map((u: any) => (
										<option key={u.userID} value={u.userID}>
											{u.firstName} {u.lastName}
										</option>
									))}
								</Form.Control>
							</Col>
						</Form.Group>
					</Col>
					<Col sm="auto">
						<CloseButton onClick={props.handleClose} disabled={!isReady} />
					</Col>
				</Row>
				<Row style={{ marginTop: '10px' }}>
					<Col sm="auto" style={{ flex: '1 0 auto' }}>
						<Form.Group as={Row} controlId="formTags">
							<Form.Label column sm="auto" style={{ lineHeight: '2em' }}>
								Tags
							</Form.Label>
							<Col
								sm="auto"
								style={{
									minWidth: '20%',
									flex: '1 0 auto',
									maxWidth: isTagsDisabled ? '90%' : '75%',
									paddingLeft: 0,
								}}
							>
								{isTagsDisabled ? (
									<Row style={{ margin: 0 }}>
										<Col
											sm={'auto'}
											className={`form-control-plaintext${
												!I.have('admin', props.appState.permission[props.appState.context]) ? ' no-hover' : ''
											} tag-container`}
											onClick={() => I.have('admin', props.appState.permission[props.appState.context]) && setTagsDisabled(false)}
										>
											<Row style={{ margin: 0 }}>
												{orgTags.map((t) => (
													<Col
														key={t.tagID}
														sm={'auto'}
														style={{
															paddingLeft: '0px',
															paddingRight: '0px',
															paddingBottom: '.25em',
														}}
													>
														<Badge
															style={{
																backgroundColor: t.tagGroup!.colourHexCode,
																display: 'inline',
															}}
															className={'tag-org'}
															pill
														>
															{renderIcon(t.tagGroup!.tagGroupIcon, 14)} {t.tagGroup!.tagGroupName}: {t.tagName}
														</Badge>
													</Col>
												))}
											</Row>
										</Col>
										<Col sm="1" style={{ paddingTop: '.25em' }}>
											<PlusButton
												onClick={() => I.have('admin', props.appState.permission[props.appState.context]) && setTagsDisabled(false)}
											/>
										</Col>
									</Row>
								) : (
									<Select
										options={tags.map((t) => ({
											label: `${t.tagGroup!.tagGroupName}: ${t.tagName}`,
											value: t.tagID,
											colour: t.tagGroup!.colourHexCode,
											icon: t.tagGroup!.tagGroupIcon,
										}))}
										value={orgTags.map((t) => ({
											label: `${t.tagGroup!.tagGroupName}: ${t.tagName}`,
											value: t.tagID,
											colour: t.tagGroup!.colourHexCode,
											icon: t.tagGroup!.tagGroupIcon,
										}))}
										onChange={handleTagChange}
										components={{ MultiValueLabel }}
										isMulti
										isSearchable
										isDisabled={!I.have('admin', props.appState.permission[props.appState.context])}
										className={!I.have('admin', props.appState.permission[props.appState.context]) ? 'no-hover' : ''}
										onFocus={() => I.have('admin', props.appState.permission[props.appState.context]) && setTagsDisabled(false)}
										styles={customStyles}
										autoFocus
										defaultMenuIsOpen
									/>
								)}
							</Col>
							<Col sm="auto">
								{!isTagsDisabled ? (
									<Button
										type="submit"
										variant="success"
										onClick={() => {
											setTagsDisabled(true)
											updateTags()
										}}
									>
										Save
									</Button>
								) : null}
							</Col>
							<Col sm="auto">
								{!isTagsDisabled ? (
									<Button
										type="button"
										onClick={() => {
											setTagsDisabled(true)
											setOrgTags(org.tags!)
										}}
									>
										Cancel
									</Button>
								) : null}
							</Col>
						</Form.Group>
					</Col>
				</Row>
				<Row>
					<Col>
						<Tabs id="client-tabs" activeKey={key} onSelect={(k) => k && setKey(k)}>
							<Tab eventKey="overview" title={props.tab === 'live' ? 'Overview' : 'Analysis'} active={key === 'overview'}>
								<OrgOverview
									authState={props.appState.authState}
									accountXeroOrgMetrics={props.accountXeroOrgMetrics}
									filteredMetrics={props.filteredMetrics}
								/>
							</Tab>
							<Tab eventKey="settings" title="Company Thresholds" active={key === 'settings'}>
								<OrgSettings
									authState={props.appState.authState}
									appState={props.appState}
									organisation={org}
									updateReports={props.updateReports}
								/>
							</Tab>
						</Tabs>
					</Col>
				</Row>
				<Messages.Messages messages={messages} updateMessage={updateMessages} />
			</div>
		)
	} else {
		return <></>
	}
}

const MultiValueLabel = (props: any) => {
	return (
		<span style={{ borderRadius: '10em' }}>
			{renderIcon(props.data.icon, 16, { margin: '0px 0px 2px 4px' })}
			<components.MultiValueLabel {...props} />
		</span>
	)
}

const customStyles = {
	control: (styles: any) => ({ ...styles, backgroundColor: 'white' }),
	multiValue: (styles: any, { data }: any) => ({
		...styles,
		backgroundColor: data.colour,
		borderRadius: '10em',
	}),
	multiValueLabel: (styles: any) => ({
		...styles,
		display: 'inline',
	}),
	multiValueRemove: (styles: any) => ({
		...styles,
		':hover': {
			backgroundColor: '#ff6262',
			color: 'white',
			borderRadius: '10em',
		},
	}),
}

export default SingleOrg
