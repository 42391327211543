import React from 'react'
import { XeroEmbeddedAppProvider } from '@xeroapi/embedded-apps-sdk'
import { Outlet } from 'react-router-dom'

export const AppContent = () => {
	if (window.self === window.top) {
		return (
			<p>
				Congratulations the embedded app is now running. To view the app please go to <a href="https://go.xero.com">Xero</a>
			</p>
		)
	}

	return (
		<div className="xero-embedded-app">
			<XeroEmbeddedAppProvider>
				<Outlet />
			</XeroEmbeddedAppProvider>
		</div>
	)
}
