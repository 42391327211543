import React from 'react'
import { Badge, Button, Container, Col, Row, Table } from 'react-bootstrap'

import * as AppDefinitions from '../../App.d'
import * as Request from '../../utilities/request'
import * as I from '../../utilities/me'

import * as Helpers from './helpers'
import { AppContext } from '../../App'

const ScreensKeypayConnections = () => {
	const { appState } = React.useContext(AppContext)

	const [consentUrl, updateConsentUrl] = React.useState<string>('')
	const [connections, updateConnections] = React.useState<Helpers.UserOrg[] | null>(null)

	const getData = (authState: AppDefinitions.AuthState, permission: string | null) => {
		const getUrl = async () => {
			const req = await Request.get(`keypay/url`, authState)
			updateConsentUrl(req.data.url)
		}

		const getConnections = async () => {
			const req = await Request.get('organisation?where=ApiProvider==keypay', authState)
			const cons = Helpers.groupConnectionsByUser(req.data.accountXeroOrgs)
			updateConnections(cons)
		}

		if (I.have('admin', permission)) {
			getConnections()
			getUrl()
		}
	}

	React.useEffect(() => {
		getData(appState.authState, appState.permission[appState.context])
	}, [appState.authState, appState.permission[appState.context]]) // eslint-disable-line

	if (consentUrl !== '' && connections !== null) {
		return (
			<Container className="oversight-container">
				<Row>
					<Col sm="auto">
						<h2>Keypay Connections</h2>
					</Col>
					<Col></Col>
					<Col sm="auto">
						<Button>
							<a href={consentUrl} style={{ color: 'inherit' }}>
								New Connection
							</a>
						</Button>
					</Col>
				</Row>
				<Row>
					<Col>
						<Table borderless responsive>
							<thead>
								<tr className="header-row">
									<th>User</th>
									<th>Organisations</th>
									<th>Status</th>
								</tr>
							</thead>
							<tbody className={'card-table-body well-spaced-table'}>
								{connections.length > 0 ? (
									connections.map((con: Helpers.UserOrg, index: number) => (
										<tr key={con.userID} className={`stripe-row ${index % 2 === 0 ? 'stripe-row-body' : ''}`}>
											<td>
												{con.firstName} {con.lastName}
											</td>
											<td>
												{con.orgs.map((org: Helpers.Org, index: number) => (
													<React.Fragment key={org.xeroOrgID}>
														{org.name}
														{index < con.orgs.length - 1 ? <br /> : null}
													</React.Fragment>
												))}
											</td>
											<td>
												{con.keypayStatus ? (
													<Badge variant="success">OK</Badge>
												) : (
													<Badge variant="danger" className={'clickable-icon'}>
														<a href={consentUrl} style={{ color: 'inherit' }}>
															Re-auth needed
														</a>
													</Badge>
												)}
											</td>
										</tr>
									))
								) : (
									<tr key={'no connections'} className={`stripe-row stripe-row-body`}>
										<td>No Keypay Connections</td>
										<td />
									</tr>
								)}
							</tbody>
						</Table>
					</Col>
				</Row>
			</Container>
		)
	} else if (!I.have('admin', appState.permission[appState.context])) {
		return (
			<Container className="oversight-container">
				<Row>
					<Col></Col>
					<Col xs="auto">
						<p>You do not have permission to view this page.</p>
					</Col>
					<Col></Col>
				</Row>
			</Container>
		)
	} else {
		return <div></div>
	}
}

export default ScreensKeypayConnections
