// libraries
import * as moment from 'moment'
import * as React from 'react'
import { Button, ButtonGroup, Col, Dropdown, DropdownButton, OverlayTrigger, Row, Tooltip, Tabs, Tab, InputGroup, FormControl } from 'react-bootstrap'
import { Plus, Settings } from 'react-feather'

// definitions
import { MetricType } from '../../constants/metricType'
import { AccountXeroOrg, DataSetMetric, MetricGroup, TagGroup, User, UserAccountSettings } from '../../../../back-end/utilities/apiDefinitions'
import { DataSetMetricGroupedByXeroOrg, ModalState, TagCollapse, XeroOrgsGroupedByTag, OversightPageStatus } from './oversight.d'

// components
import ModalList from '../../components/ui/modalList/modalList'
import { LiveTab } from '../../components/oversight/live'
import { PointInTimeTab } from '../../components/oversight/pointInTime'
import SingleOrg from '../orgs/singleOrg'
import { Search } from 'react-feather'
import Select, { components, OnChangeValue } from 'react-select'
import OnBoardModal from '../../components/ui/onBoardModal/onBoardModal'
import * as XLSX from 'xlsx'
import * as FileSaver from 'file-saver'
import LoadingImage from '../../images/3887_Oversight_100x100px.gif'

// utilities
import * as I from '../../utilities/me'
import * as Request from '../../utilities/request'
import { renderIcon } from '../../utilities/icon'
import { filterMetrics } from '../../components/oversight/table'
import { AppContext } from '../../App'
import { useNavigate } from 'react-router-dom'

type OptionType = {
	value: string
	label: string
	colour?: string
	icon?: string
	type?: string
	tagGroupID?: string
	disabled?: boolean
	options?: OptionType[]
}

type MergerObject = {
	// position of first cell that will be merged
	s: {
		c: number //column number
		r: number // row number
	}
	// last cell to partake in the merging of cells
	e: {
		c: number
		r: number
	}
}

// global constants
const NoUserName = 'No User'
const AllUsersID = 'all'
const NoGroupName = 'No Group'
const AllGroupsID = 'All Groups'
const NoTimeRangeID = 'none'
const OrgBatchNumber = 10

const initialModalState: ModalState = {
	modal: '',
	props: {},
	size: 'sm',
}

const Oversight = () => {
	const { appState } = React.useContext(AppContext)
	const navigate = useNavigate()

	const [pageStatus, setPageStatus] = React.useState<OversightPageStatus>('Loading')
	// TO DO types for these and the matching API calls
	const [metricGroups, updateMetricGroups] = React.useState<{
		overview: MetricGroup[]
		analysis: MetricGroup[]
	} | null>(null)
	const [organisationMetrics, updateOrganisationMetrics] = React.useState<DataSetMetricGroupedByXeroOrg[] | null>(null)
	const [orgs, updateOrgs] = React.useState<AccountXeroOrg[] | null>(null)
	const [metricSettings, updateMetricSettings] = React.useState<UserAccountSettings[] | null>(null)
	const [consentUrl, updateConsentUrl] = React.useState<string | null>(null)
	const [users, updateUsers] = React.useState<User[] | null>(null)
	const [tagGroups, updateTagGroups] = React.useState<TagGroup[] | null>(null)

	const [selectedUser, updateSelectedUser] = React.useState<string>(AllUsersID)
	const [selectedTagGroup, updateSelectedTagGroup] = React.useState<string>(AllGroupsID)
	const [selectedTags, updateSelectedTags] = React.useState<OptionType[]>([])
	const [timeRange, updateTimeRange] = React.useState<'day' | 'week' | 'month' | 'quarter' | 'year' | 'none'>(NoTimeRangeID)
	const [search, updateSearch] = React.useState<string>('')
	const [originalOrganisationMetrics, updateOriginalOrganisationMetrics] = React.useState<DataSetMetricGroupedByXeroOrg[] | null>(null)

	const [isTagsCollapsed, updateTagsCollapsed] = React.useState<TagCollapse>({})
	// const [ displayRows, updateDisplayRows ] = React.useState<number>(-1)

	const [showSettingsBar, updateShowSettingsBar] = React.useState<boolean>(false)
	const [shownModal, updateShownModal] = React.useState<ModalState>(initialModalState)
	const [tab, setTab] = React.useState<string>('live')
	const [hasDismissedOnBoard, updateHasDismissedOnBoard] = React.useState<boolean>(false)

	const [needRerun, updateNeedRerun] = React.useState<boolean>(false)
	const [isFetching, updateIsFetching] = React.useState<boolean>(false)

	const refreshData = React.useCallback(
		async (modalProps?: any) => {
			const fetchData = async () => {
				updateIsFetching(true)
				const [metricGroups, accountXeroOrgs, metricSettings, users, tagGroups, xeroUrl] = await Promise.all(
					[
						Request.get('metricgroup', appState.authState),
						Request.get('organisation?where=AccountXeroOrgIsOversight==1', appState.authState),
						Request.get('setting', appState.authState),
						Request.get('user', appState.authState),
						Request.get('taggroup', appState.authState),
					].concat(I.have('admin', appState.permission[appState.context]) ? [Request.get('xero/url/oversight', appState.authState)] : [])
				)

				const tagCollapse: TagCollapse = {}
				accountXeroOrgs.data.accountXeroOrgs.forEach((axo: AccountXeroOrg) => {
					tagCollapse[axo.xeroOrg!.xeroOrgID!] = true
				})

				updateTagsCollapsed(tagCollapse)
				updateMetricGroups(metricGroups.data.metricGroups)
				updateOrgs(accountXeroOrgs.data.accountXeroOrgs)
				updateMetricSettings(metricSettings.data.userAccountSettings)
				updateUsers(users.data.users)
				updateTagGroups(tagGroups.data.tagGroups)
				if (I.have('admin', appState.permission[appState.context])) {
					updateConsentUrl(xeroUrl.data.url)
				}

				if (modalProps) {
					updateShownModal(modalProps)
				} else if (accountXeroOrgs.data.accountXeroOrgs.length === 0 && xeroUrl.data.url && !hasDismissedOnBoard) {
					updateShownModal({
						modal: 'OnBoard',
						props: {
							xeroConsentUrl: xeroUrl.data.url,
						},
						size: 'md',
					})
				}

				const orgs = accountXeroOrgs.data.accountXeroOrgs as AccountXeroOrg[]
				let orgBatches = [] as AccountXeroOrg[][]
				for (let i = 0; i < orgs.length; i += OrgBatchNumber) {
					orgBatches = orgBatches.concat([orgs.slice(i, Math.min(i + OrgBatchNumber, orgs.length))])
				}

				await Promise.all(
					orgBatches.map(async (batch, index) => {
						await Request.sleep(index * 2000)
						const batchRes = await Request.get(
							`dataset?where=xeroOrgID=in(${batch.map((o) => o.xeroOrg?.xeroOrgID).join(',')})`,
							appState.authState
						)
						updateDataSetResults(batchRes.data as { dataSetMetrics: DataSetMetric[] }, 'Ready')
					})
				)
				updateIsFetching(false)
			}

			let interimDataSetResults: DataSetMetric[] = []
			const updateDataSetResults = (responseData: { dataSetMetrics: DataSetMetric[] }, status: Request.RequestStatus) => {
				if (status !== 'Error') {
					interimDataSetResults = interimDataSetResults.concat(responseData.dataSetMetrics)
					const groupedMetrics = groupDataSetMetricByXeroOrgID(interimDataSetResults)
					updateOrganisationMetrics(groupedMetrics)
					updateOriginalOrganisationMetrics(groupedMetrics)
				}
				setPageStatus(status)
			}

			if (appState.authState.isLoggedIn && appState.permission[appState.context]) {
				fetchData()
			}
		},
		// eslint-disable-next-line
		[appState.authState, appState.permission[appState.context]]
	)

	React.useEffect(() => {
		if (appState.authState.isLoggedIn) {
			refreshData()
		}
	}, [appState.authState, refreshData])

	React.useEffect(() => {
		const getOldDataSetMetric = async () => {
			updateIsFetching(true)
			if (timeRange !== NoTimeRangeID && organisationMetrics) {
				const time = moment
					.utc()
					.subtract(1, timeRange)
					.subtract(timeRange === 'day' ? 1 : 0, 'day')
					.format('YYYY-MM-DD')
				await Request.getPagedData(`dataset?where=fetchTs==${time}&`, 4000, updateOldDataSetResults, appState.authState)
			} else if (timeRange === NoTimeRangeID) {
				updateOrganisationMetrics(originalOrganisationMetrics)
			}
			updateIsFetching(false)
		}

		let interimDataSetResults: DataSetMetric[] = []
		const updateOldDataSetResults = (responseData: { dataSetMetrics: DataSetMetric[] }, status: Request.RequestStatus) => {
			if (status !== 'Error') {
				interimDataSetResults = interimDataSetResults.concat(responseData.dataSetMetrics)
				updateOrganisationMetrics(updateOrganisationMetricsWithPreviousValues(organisationMetrics!, interimDataSetResults))
			}
			setPageStatus(status)
		}

		setPageStatus('Loading')
		getOldDataSetMetric()
	}, [timeRange]) // eslint-disable-line

	const handleTagChange = (event: OnChangeValue<OptionType, true>) => {
		if (event) {
			updateNeedRerun(true)
			updateSelectedTags(event.filter((option) => !option.tagGroupID || !event.some((opt) => opt.value === option.tagGroupID)))
		}
	}

	if (metricGroups !== null && organisationMetrics !== null && orgs !== null && metricSettings !== null && users !== null && tagGroups !== null) {
		const filterAndGroup = (xeroOrgMetrics: DataSetMetricGroupedByXeroOrg[]): XeroOrgsGroupedByTag[] => {
			const groups: XeroOrgsGroupedByTag[] =
				selectedTagGroup === AllGroupsID
					? [
							{
								tagID: '',
								tagName: '',
								xeroOrgs: [],
							},
					  ]
					: tagGroups
							.find((tg) => tg.tagGroupID === selectedTagGroup)!
							.tags!.map((tag) => ({
								tagID: tag.tagID!,
								tagName: tag.tagName,
								xeroOrgs: [],
							}))
			const searchTerm = search.toLowerCase()
			xeroOrgMetrics.forEach((xom) => {
				const thisOrg = orgs.find((o) => o.xeroOrg!.xeroOrgID === xom.xeroOrgID)
				if (thisOrg && xeroOrgPassesFilters(thisOrg, xom, searchTerm, selectedTags, selectedTagGroup, selectedUser)) {
					groups.forEach((group) => {
						if (selectedTagGroup === AllGroupsID || thisOrg.tags!.some((t) => t.tagID === group.tagID)) {
							group.xeroOrgs.push(xom)
						}
					})
				}
			})
			return groups.sort((a, b) => b.xeroOrgs.length - a.xeroOrgs.length)
		}

		const tagOptions: OptionType[] = tagGroups.map((tg) => ({
			value: tg.tagGroupID!,
			label: tg.tagGroupName!,
			colour: tg.colourHexCode,
			icon: tg.tagGroupIcon,
			options: tg.tags!.map((t) => ({
				value: t.tagID!,
				label: t.tagName,
				colour: tg.colourHexCode,
				icon: tg.tagGroupIcon,
				tagGroupID: tg.tagGroupID,
				disabled: selectedTags.some((t) => t.value === tg.tagGroupID),
			})),
		}))

		const GroupHeading = (props: any) => (
			<div
				style={{ backgroundColor: props.data.colour }}
				className={'select-group-heading'}
				onClick={() => handleTagChange(selectedTags.concat([props.data]))}
			>
				{props.data.label}
			</div>
		)

		// current selections
		const currUser = users.find((user) => user.userID === selectedUser)
		const currTagGroup = tagGroups.find((tagGroup) => tagGroup.tagGroupID === selectedTagGroup)
		const currTags = tagOptions.reduce((tags: OptionType[], groupOption: OptionType) => {
			if (selectedTags.some((tag) => tag.value === groupOption.value)) {
				tags.push(groupOption)
			} else {
				const tagOptions = groupOption.options!.filter((option) => selectedTags.some((tag) => tag.value === option.value))
				tags = tags.concat(tagOptions)
			}
			return tags
		}, [])

		const filteredAndGroupedOrgs = filterAndGroup(organisationMetrics)

		// Export excel file
		const getExcelData = (): [string[][], MergerObject[]] => {
			/**
			 *  This function creates the two necessary inputs for an excel export: data and the array of objects that indicates the merged cells
			 */
			const metricToShowIDs = metricSettings.filter((s) => s.metric!.typeID === MetricType.Live).map((currentValue) => currentValue.metric!.metricID!)
			const [filteredMetrics, filteredMetricGroups] = filterMetrics(metricGroups.overview, filteredAndGroupedOrgs || [], metricToShowIDs)
			const header_1 = ['Company', 'Tags', 'Status'].concat(filteredMetrics.map((m) => m.name!))

			let merges: MergerObject[] = [{ s: { c: 0, r: 1 }, e: { c: 2, r: 1 } }]
			let trackerOfIndex = 3
			let header_0 = ['General', '', '']
			// due to the metric length changing we need to popullate header_0 with forEach and push to accoount for the merges
			filteredMetricGroups
				.filter((mg) => mg.length > 0)
				.forEach((mg: any) => {
					merges = merges.concat([
						{
							s: { c: trackerOfIndex, r: 1 },
							e: { c: trackerOfIndex + mg.length - 1, r: 1 },
						},
					])
					trackerOfIndex = trackerOfIndex + mg.length
					header_0 = header_0.concat([mg.name])
					for (let i = 0; i < mg.length - 1; i++) {
						header_0 = header_0.concat([''])
					}
				})

			// Popullate the data table
			const data = filteredAndGroupedOrgs.map((group) => {
				const tagNameForGroup = group.tagName ? [group.tagName] : null
				const row = group.xeroOrgs.map((xo: DataSetMetricGroupedByXeroOrg) => {
					const associatedXeroOrg = orgs.find((o) => o.xeroOrg!.xeroOrgID === xo.xeroOrgID)
					const tagNames = associatedXeroOrg ? associatedXeroOrg.tags!.map((currentValue) => currentValue.tagName!).join(', ') : ''
					const status = associatedXeroOrg
						? associatedXeroOrg.userAccountXeroOrgAuth!.refreshToken !== null && associatedXeroOrg.userAccountXeroOrgAuth!.connectionID !== null
							? 'OK'
							: 'Re-auth needed'
						: 'Disconnected'
					return [xo.name!, tagNames, status].concat(
						filteredMetrics.map((fm) => {
							const m = xo.metrics.find((m) => m.metricID === fm.metricID)
							return String(m!.value)
						})
					)
				})
				if (tagNameForGroup) {
					return tagNameForGroup.concat(...row)
				} else {
					return row
				}
			})
			return [[header_0, header_1].concat(...data), merges]
		}

		const downloadExcel = () => {
			const [data, merges] = getExcelData()
			const ws = XLSX.utils.json_to_sheet(data)
			const wb = {
				Sheets: { data: { ...ws, '!merges': merges } },
				SheetNames: ['data'],
			}
			const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
			const filee = new Blob([excelBuffer], {
				type: 'application/vnd.ms-excel;charset=utf-8',
			})
			FileSaver.saveAs(filee, String(`oversight-${moment.utc().format('DD-MM-YYYY')}`) + '.xlsx')
		}

		return (
			<div className="oversight-container">
				<div
					style={{
						position: 'fixed',
						width: '100%',
						left: '0',
						right: '0',
						top: '0',
						bottom: '0',
						backgroundColor: 'rgba(89, 83, 83, 0.7)',
						zIndex: '9999',
						display: isFetching ? 'flow' : 'none',
					}}
				>
					<div style={{ textAlign: 'center', paddingTop: ' 6% ' }}>
						<img src={LoadingImage} alt="Loading ..." />
					</div>
				</div>
				<Row className={'filters-container'}>
					<Col sm="auto" className={'filters-expand'}>
						<InputGroup className={'filters-sm'}>
							<InputGroup.Prepend>
								<InputGroup.Text className={'search-icon'}>
									<Search size={20} />
								</InputGroup.Text>
							</InputGroup.Prepend>
							<FormControl placeholder="Search..." aria-label="Search" value={search} onChange={(e) => updateSearch(e.target.value)} />
						</InputGroup>
					</Col>
					{tab === 'live' ? (
						<Col sm="auto" className={'filters-sm'}>
							<DropdownButton
								as={ButtonGroup}
								id={'dropdown'}
								variant={'primary'}
								title={
									<span>
										<OverlayTrigger
											placement="bottom"
											overlay={
												<Tooltip id={'time-comparison-tooltip'} style={{ whiteSpace: 'pre-wrap' }}>
													This data is built up over time.{'\n'}
													If connections are new, jump to the Analysis tab to see history.
												</Tooltip>
											}
										>
											<span>{timeRange === NoTimeRangeID ? 'No Time Range' : getTimeRangeToDisplayName(timeRange)}</span>
										</OverlayTrigger>
									</span>
								}
								onSelect={(eventKey: any) => updateTimeRange(eventKey)}
								alignRight
							>
								<Dropdown.Item eventKey={NoTimeRangeID} active={NoTimeRangeID === timeRange}>
									No Time Range
								</Dropdown.Item>
								<Dropdown.Item eventKey={'day'} active={timeRange === 'day'}>
									Yesterday
								</Dropdown.Item>
								<Dropdown.Item eventKey={'week'} active={timeRange === 'week'}>
									Last Week
								</Dropdown.Item>
								<Dropdown.Item eventKey={'month'} active={timeRange === 'month'}>
									Last Month
								</Dropdown.Item>
								<Dropdown.Item eventKey={'quarter'} active={timeRange === 'quarter'}>
									Last Quarter
								</Dropdown.Item>
								<Dropdown.Item eventKey={'year'} active={timeRange === 'year'}>
									Last Year
								</Dropdown.Item>
							</DropdownButton>
						</Col>
					) : null}
					<Col sm="auto" className={'filters-lg filters-expand'}>
						<Select
							options={tagOptions}
							value={currTags}
							onChange={handleTagChange}
							components={{ MultiValueLabel, DropdownIndicator, GroupHeading }}
							isMulti
							isSearchable
							styles={customStyles}
							placeholder={'Filter By...'}
							className={'select-filter'}
						/>
					</Col>
					<Col sm="auto">
						<OverlayTrigger placement="auto" overlay={<Tooltip id={`tooltip-export`}>Export an excel spreadsheet.</Tooltip>}>
							<Button onClick={() => downloadExcel()}>Export</Button>
						</OverlayTrigger>
					</Col>
					<Col sm="auto" className={'filters-md'}>
						<DropdownButton
							as={ButtonGroup}
							id={'dropdown'}
							variant={'primary'}
							title={selectedTagGroup === AllGroupsID ? 'Group By...' : currTagGroup ? `Group By: ${currTagGroup.tagGroupName}` : NoGroupName}
							onSelect={(eventKey: any) => {
								if (eventKey === 'new') {
									navigate('/tag/new')
								} else {
									updateNeedRerun(true)
									updateSelectedTagGroup(eventKey)
								}
							}}
							alignRight
						>
							<Dropdown.Item eventKey={AllGroupsID} active={AllGroupsID === selectedTagGroup}>
								All Groups
							</Dropdown.Item>
							<Dropdown.Divider />
							{tagGroups.map((group: TagGroup) => (
								<Dropdown.Item key={group.tagGroupID} eventKey={group.tagGroupID} active={group.tagGroupID === selectedTagGroup}>
									{`${group.tagGroupName}` || NoGroupName}
								</Dropdown.Item>
							))}
							{tagGroups.length === 0 ? (
								<>
									<Dropdown.Item disabled>No Tag Groups</Dropdown.Item>
									<Dropdown.Divider />
									<Dropdown.Item eventKey={'new'}>Add Tag Group</Dropdown.Item>
								</>
							) : null}
						</DropdownButton>
					</Col>
					<Col sm="auto" className={'filters-md'}>
						<DropdownButton
							as={ButtonGroup}
							id={'dropdown'}
							variant={'primary'}
							title={selectedUser === AllUsersID ? 'All Users' : currUser ? `User: ${currUser.firstName} ${currUser.lastName}` : NoUserName}
							onSelect={(eventKey: any) => {
								updateNeedRerun(true)
								updateSelectedUser(eventKey)
							}}
							alignRight
						>
							<Dropdown.Item eventKey={AllUsersID} active={AllUsersID === selectedUser}>
								All Users
							</Dropdown.Item>
							<Dropdown.Divider />
							{users.map((user: User) => (
								<Dropdown.Item key={user.userID} eventKey={user.userID} active={user.userID === selectedUser}>
									{`${user.firstName} ${user.lastName}` || NoUserName}
								</Dropdown.Item>
							))}
						</DropdownButton>
					</Col>
					{consentUrl ? (
						<Col sm="auto">
							<OverlayTrigger placement="auto" overlay={<Tooltip id={`tooltip-xero`}>Connect another xero organisation.</Tooltip>}>
								<Button>
									<a href={consentUrl} style={{ color: 'inherit' }}>
										<Plus className={'clickable-icon'} />
									</a>
								</Button>
							</OverlayTrigger>
						</Col>
					) : null}
					<Col sm="auto">
						<Button>
							<Settings onClick={() => updateShowSettingsBar(true)} />
						</Button>
					</Col>
				</Row>
				<Tabs activeKey={tab} onSelect={(t) => t && setTab(t)} id="dashboard-tabs">
					<Tab eventKey="live" title="Overview" active={tab === 'live'}>
						<LiveTab
							metricGroups={metricGroups.overview}
							groups={filteredAndGroupedOrgs}
							orgs={orgs}
							refreshData={refreshData}
							appState={appState}
							updateShownModal={updateShownModal}
							showSettingsBar={showSettingsBar}
							metricSettings={metricSettings.filter((s) => s.metric!.typeID === MetricType.Live)}
							updateMetricSettings={updateMetricSettings}
							isTagsCollapsed={isTagsCollapsed}
							isComparing={timeRange !== 'none'}
							updateShowSettingsBar={updateShowSettingsBar}
							tab={'live'}
							isLoading={pageStatus === 'Loading'}
							updateIsFetching={updateIsFetching}
						/>
					</Tab>
					<Tab eventKey="pit" title="Analysis" active={tab === 'pit'}>
						<PointInTimeTab
							metricGroups={metricGroups.analysis}
							groups={filteredAndGroupedOrgs}
							orgs={orgs}
							refreshData={refreshData}
							appState={appState}
							updateShownModal={updateShownModal}
							showSettingsBar={showSettingsBar}
							metricSettings={metricSettings.filter((s) => s.metric!.typeID === MetricType.PIT)}
							updateMetricSettings={updateMetricSettings}
							isComparing={false} // couldn't get the types working properly for this this isn't actually used
							isTagsCollapsed={isTagsCollapsed}
							updateShowSettingsBar={updateShowSettingsBar}
							tab={'pit'}
							rerunAnalysis={needRerun}
							updateNeedRerun={updateNeedRerun}
							filterAndGroup={filterAndGroup}
							isLoading={pageStatus === 'Loading'}
							updateIsFetching={updateIsFetching}
						/>
					</Tab>
				</Tabs>

				<ModalList
					handleClose={() => {
						updateShownModal(initialModalState)
						if (tab === 'live' && shownModal.modal === 'EditOrg') {
							refreshData()
						}
						if (shownModal.modal === 'OnBoard') {
							updateHasDismissedOnBoard(true)
						}
					}}
					show={shownModal.modal}
					data={shownModal.props}
					size={shownModal.size}
					modals={[
						{
							id: 'EditOrg',
							body: SingleOrg,
						},
						{
							id: 'OnBoard',
							body: OnBoardModal,
						},
					]}
				/>
			</div>
		)
	} else {
		return (
			<Row>
				<Col></Col>
				<Col sm="auto">
					<img src={LoadingImage} alt="Loading ..." />
				</Col>
				<Col></Col>
			</Row>
		)
	}
}

const groupDataSetMetricByXeroOrgID = (dsm: DataSetMetric[]) =>
	dsm.reduce((xeroOrgs: DataSetMetricGroupedByXeroOrg[], dsm: DataSetMetric) => {
		const index = xeroOrgs.findIndex((xo) => xo.xeroOrgID === dsm.xeroOrg.xeroOrgID)
		const metric = {
			...dsm.metric,
			value: dsm.value,
		}

		if (index >= 0) {
			xeroOrgs[index].metrics.push(metric)
		} else {
			xeroOrgs.push({
				...dsm.xeroOrg,
				metrics: [metric],
			})
		}
		return xeroOrgs
	}, [])

const updateOrganisationMetricsWithPreviousValues = (
	orgMetrics: DataSetMetricGroupedByXeroOrg[],
	dataSetMetrics: DataSetMetric[]
): DataSetMetricGroupedByXeroOrg[] =>
	orgMetrics.map((xeroOrg) => ({
		...xeroOrg,
		metrics: xeroOrg.metrics.map((metric) => {
			const prev = dataSetMetrics.find((dsm) => dsm.metric.metricID === metric.metricID && dsm.xeroOrg.accountXeroOrgID === xeroOrg.accountXeroOrgID)
			return {
				...metric,
				oldValue: prev ? prev.value : null,
			}
		}),
	}))

const xeroOrgPassesFilters = (
	thisOrg: AccountXeroOrg,
	xom: DataSetMetricGroupedByXeroOrg,
	searchTerm: string,
	selectedTags: OptionType[],
	selectedTagGroup: string,
	selectedUser: string
) =>
	(xom.name!.toLowerCase().includes(searchTerm.toLowerCase()) ||
		xom.metrics.find((m) => m.dataType === 'VARCHAR' && m.value && String(m.value).toLowerCase().includes(searchTerm.toLowerCase())) ||
		thisOrg.tags!.some((t) => t.tagName.toLowerCase().includes(searchTerm) || t.tagGroup!.tagGroupName!.toLowerCase().includes(searchTerm))) &&
	(selectedTags.length === 0 || thisOrg.tags!.some((t) => selectedTags.some((st) => st.value === t.tagID || st.value === t.tagGroup!.tagGroupID))) &&
	(selectedTagGroup === AllGroupsID || thisOrg.tags!.some((t) => t.tagGroup!.tagGroupID === selectedTagGroup)) &&
	(selectedUser === AllUsersID || thisOrg!.responsibleUser!.userID === selectedUser)

const getTimeRangeToDisplayName = (timeRange: string) => {
	if (timeRange === 'day') {
		return 'Yesterday'
	} else {
		return `Last ${timeRange[0].toUpperCase()}${timeRange.substr(1)}`
	}
}

const MultiValueLabel = (props: any) => (
	<span style={{ borderRadius: '10em', maxWidth: '90%' }}>
		{renderIcon(props.data.icon, 16, { margin: '-.75em .25em 0 .5em' })}
		<components.MultiValueLabel {...props} />
	</span>
)

const DropdownIndicator = (props: any) => (
	<components.DropdownIndicator {...props}>
		<span className={'select-dropdown-indicator'}></span>
	</components.DropdownIndicator>
)

const customStyles = {
	control: (styles: any) => ({
		...styles,
		backgroundColor: 'white',
	}),
	container: (styles: any) => ({
		...styles,
		minWidth: '11em',
	}),
	placeholder: (styles: any) => ({
		...styles,
		color: '#6c757d',
		fontSize: '1.2em',
	}),
	indicatorSeparator: (styles: any) => ({
		...styles,
		display: 'none',
	}),
	menu: (styles: any) => ({
		...styles,
		zIndex: 2,
		width: '100%',
	}),
	option: (styles: any, { data }: any) => ({
		...styles,
		fontSize: '.9em',
		paddingLeft: '2em',
		color: data.disabled ? 'grey' : 'black',
	}),
	multiValue: (styles: any, { data }: any) => ({
		...styles,
		backgroundColor: data.colour,
		borderRadius: '10em',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		height: '1.75em',
	}),
	multiValueLabel: (styles: any) => ({
		...styles,
		display: 'inline-block',
		maxWidth: '80%',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		padding: '.25em 0 0 0',
	}),
	multiValueRemove: (styles: any) => ({
		...styles,
		':hover': {
			backgroundColor: '#ff6262',
			color: 'white',
			borderRadius: '10em',
		},
	}),
}

export default Oversight
