import React from 'react'
import { Button, Col, Container, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'

import { TagGroup } from '../../../../back-end/utilities/apiDefinitions'
import * as Request from '../../utilities/request'
import * as I from '../../utilities/me'
import GroupList from '../../components/ui/groupList/groupList'
import { AppContext } from '../../App'

const Groups = () => {
	const { appState } = React.useContext(AppContext)

	const [groups, setGroups] = React.useState<null | TagGroup[]>(null)

	React.useEffect(() => {
		const getGroups = async () => {
			const request = await Request.get('taggroup', appState.authState)
			setGroups(request.data.tagGroups.sort((a: any, b: any) => a.tagGroupName.toLowerCase().localeCompare(b.tagGroupName.toLowerCase())))
		}

		getGroups()
	}, [appState.authState])

	return (
		<Container className="oversight-container">
			<Row>
				<Col sm="auto">
					<h2>Tag Groups</h2>
				</Col>
				{I.have('admin', appState.permission[appState.context]) ? (
					<>
						<Col></Col>
						<Col sm="auto">
							<Link to="/tag/new">
								<Button>Add a tag group</Button>
							</Link>
						</Col>
					</>
				) : null}
			</Row>
			{groups !== null ? <GroupList groups={groups} /> : null}
		</Container>
	)
}

export default Groups
