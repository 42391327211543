import React from 'react'
import { Button, Card, Col, Container, Form, Row } from 'react-bootstrap'
import Select from 'react-select'
import * as Feather from 'react-feather'
import { Link, useMatch, useNavigate } from 'react-router-dom'
import { TagSingle } from './singleTag'

import * as Messages from '../../components/ui/messages/messages'
import { Dialog } from '../../components/ui/dialog/dialog'

import * as I from '../../utilities/me'
import * as Request from '../../utilities/request'
import { renderIcon } from '../../utilities/icon'
import { TagGroup, Tag, Colour } from '../../../../back-end/utilities/apiDefinitions'
import { AppContext } from '../../App'

const renderIconAndName = (name: any) => {
	return name ? (
		<Row>
			<Col sm={'1'}>{renderIcon(name)}</Col>
			<Col>{name}</Col>
		</Row>
	) : (
		''
	)
}

const GroupSingle = () => {
	const { appState } = React.useContext(AppContext)
	const match = useMatch('/tag/:tagGroupID')
	const navigate = useNavigate()
	const urlGroupID = match?.params.tagGroupID || ''

	const [originalData, updateOriginalData] = React.useState<TagGroup>({
		tagGroupName: '',
		tagGroupIcon: '',
		colourID: '',
		tags: [],
	})
	const [fields, updateFields] = React.useState<TagGroup>({
		tagGroupName: '',
		tagGroupIcon: '',
		colourID: '',
		tags: [],
	})
	const [colours, updateColours] = React.useState<Colour[]>([])

	const [loading, updateLoading] = React.useState<boolean>(false)
	const [isNameDisabled, updateNameDisabled] = React.useState<boolean>(true)
	const [isIconDisabled, updateIconDisabled] = React.useState<boolean>(true)
	const [isColourDisabled, updateColourDisabled] = React.useState<boolean>(true)
	const [hasSomethingChanged, updateSomethingChanged] = React.useState<boolean>(false)

	const [messages, updateMessages] = Messages.useMessageReducer([])
	const [showDialog, updateShowDialog] = React.useState<boolean>(false)

	React.useEffect(() => {
		const getGroup = async () => {
			updateLoading(true)
			const req = await Request.get(`taggroup/${urlGroupID}`, appState.authState)
			const col = await Request.get(`colour`, appState.authState)
			if (req.data.tagGroups.length > 0) {
				updateFields({
					tagGroupName: req.data.tagGroups[0].tagGroupName,
					tagGroupIcon: req.data.tagGroups[0].tagGroupIcon,
					colourID: req.data.tagGroups[0].colourID,
					colourHexCode: req.data.tagGroups[0].colourHexCode,
					tags: req.data.tagGroups[0].tags,
				})
				updateOriginalData({
					tagGroupName: req.data.tagGroups[0].tagGroupName,
					tagGroupIcon: req.data.tagGroups[0].tagGroupIcon,
					colourID: req.data.tagGroups[0].colourID,
					colourHexCode: req.data.tagGroups[0].colourHexCode,
					tags: req.data.tagGroups[0].tags,
				})
			}
			updateColours(col.data.colours)
			updateLoading(false)
		}

		if (urlGroupID) {
			getGroup()
		}
	}, [appState.authState, urlGroupID])

	const getTitle = (urlMatch: string, name: string) => (urlMatch === 'new' ? 'New Group' : name)

	const handleChange = (e: React.ChangeEvent<any>) => {
		const name = e.target.name
		const value = e.target.value
		updateFields({ ...fields, [name]: value })
		updateSomethingChanged(true)
	}

	const updateGroup = async () => {
		if (I.have('admin', appState.permission[appState.context])) {
			if (hasSomethingChanged) {
				const req = await Request.put(
					`taggroup/${urlGroupID}`,
					{
						tagGroupName: fields.tagGroupName,
						tagGroupIcon: fields.tagGroupIcon,
						colourID: fields.colourID,
						tags: fields.tags,
					},
					appState.authState
				)
				updateOriginalData({
					tagGroupName: req.data.tagGroups[0].tagGroupName,
					tagGroupIcon: req.data.tagGroups[0].tagGroupIcon,
					colourID: req.data.tagGroups[0].colourID,
					tags: req.data.tagGroups[0].tags,
				})
				displayMessage('success', `Successfully updated group ${fields.tagGroupName}`)
			}
		} else {
			displayMessage('danger', 'You do not have permission to edit this group')
		}
	}
	const resetGroup = async () => {
		updateFields(originalData)
	}

	const validateForm = () =>
		fields.tagGroupName!.length > 0 && fields.colourID && fields.colourID.length > 0 && fields.tagGroupIcon && fields.tagGroupIcon.length > 0

	const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
		if (I.have('admin', appState.permission[appState.context])) {
			e.preventDefault()

			const req = await Request.post(
				'taggroup',
				{
					tagGroupName: fields.tagGroupName,
					tagGroupIcon: fields.tagGroupIcon,
					colourID: fields.colourID,
					tags: fields.tags,
				},
				appState.authState
			)
			updateNameDisabled(true)
			updateIconDisabled(true)
			updateColourDisabled(true)
			displayMessage('success', `Successfully added group ${fields.tagGroupName}`)
			navigate(`/tag/${req.data.tagGroups[0].tagGroupID}`)
		} else {
			displayMessage('danger', 'You do not have permission to add a group')
		}
	}

	const displayMessage = (severity: 'danger' | 'success', message: string) => {
		updateMessages({
			type: 'add',
			data: {
				severity: severity,
				message: message,
				timeout: 3000,
				dismissible: true,
			},
		})
	}

	const handleDeleteGroup = async () => {
		if (I.have('admin', appState.permission[appState.context])) {
			const req = await Request.del(`taggroup/${urlGroupID}`, appState.authState)

			if (req.data.success) {
				navigate('/tags')
			} else {
				displayMessage('danger', req.data.message || 'You can not delete this group at this time')
			}
		} else {
			displayMessage('danger', `You do not have permission to delete a group`)
		}
	}

	const handleAddTag = () => {
		updateFields({
			...fields,
			tags: [
				...fields.tags!,
				{
					tagID: 'new',
					tagName: '',
				},
			],
		})
	}

	const updateTags = (tag: Tag, deleted?: boolean) => {
		let newTags = fields.tags!.filter((t) => t.tagID !== 'new')
		const tagIndex = newTags.findIndex((t) => t.tagID === tag.tagID)
		if (deleted) {
			newTags = newTags.filter((t) => t.tagID !== tag.tagID)
		} else if (tagIndex >= 0) {
			newTags[tagIndex].tagName = tag.tagName
		} else {
			newTags.push(tag)
		}
		updateFields({
			...fields,
			tags: newTags,
		})
	}

	if (!loading) {
		return (
			<Container>
				<Row>
					<Col style={{ paddingTop: '3px' }}>
						<Link to="/tags">
							<Feather.ArrowLeftCircle /> Back to all groups
						</Link>
					</Col>
				</Row>
				<Card>
					<Row className={'modal-header-row'}>
						<Col xs="auto">
							<h3>{getTitle(urlGroupID, originalData.tagGroupName!)}</h3>
						</Col>
						<Col></Col>
						<Col xs="auto">
							{urlGroupID !== 'new' && I.have('admin', appState.permission[appState.context]) ? (
								<Col style={{ display: 'flex', justifyContent: 'end' }}>
									<Button onClick={() => updateShowDialog(true)}>Delete Group</Button>
								</Col>
							) : null}
						</Col>
					</Row>
					<Row>
						<Col>
							<Form onSubmit={handleSubmit}>
								<Form.Group controlId="formGroupName" as={Row}>
									<Form.Label column sm="2">
										Name
									</Form.Label>
									<Col sm="7">
										<Form.Control
											type="text"
											name="tagGroupName"
											placeholder="Group Name"
											readOnly={isNameDisabled && urlGroupID !== 'new'}
											plaintext={isNameDisabled && urlGroupID !== 'new'}
											disabled={!I.have('admin', appState.permission[appState.context])}
											onClick={() => I.have('admin', appState.permission[appState.context]) && updateNameDisabled(false)}
											onChange={handleChange}
											value={fields.tagGroupName}
											autoFocus
											className={!I.have('admin', appState.permission[appState.context]) ? 'no-hover' : ''}
										/>
									</Col>
									<Col sm="auto">
										{!isNameDisabled && urlGroupID !== 'new' ? (
											<Button
												type="submit"
												variant="success"
												onClick={() => {
													updateNameDisabled(true)
													updateGroup()
												}}
											>
												Save
											</Button>
										) : null}
									</Col>
									<Col sm="auto">
										{!isNameDisabled && urlGroupID !== 'new' ? (
											<Button
												type="button"
												onClick={() => {
													updateNameDisabled(true)
													resetGroup()
												}}
											>
												Cancel
											</Button>
										) : null}
									</Col>
								</Form.Group>

								<Form.Group controlId="formGroupIcon" as={Row}>
									<Form.Label column sm="2">
										Icon
									</Form.Label>
									<Col sm={'7'}>
										{isIconDisabled && urlGroupID !== 'new' ? (
											<div
												className={`form-control-plaintext${
													!I.have('admin', appState.permission[appState.context]) ? ' no-hover' : ''
												}`}
												onClick={() => I.have('admin', appState.permission[appState.context]) && updateIconDisabled(false)}
											>
												<>{renderIconAndName(fields.tagGroupIcon)}</>
											</div>
										) : (
											<Select
												name="tagGroupIcon"
												// readOnly={isIconDisabled && urlGroupID !== 'new'}
												// plaintext={isIconDisabled && urlGroupID !== 'new'}
												isDisabled={!I.have('admin', appState.permission[appState.context])}
												// onClick={() => I.have('admin', appState.permission[appState.context]) && updateIconDisabled(false)}
												onChange={({ value }: any) => {
													updateFields({ ...fields, tagGroupIcon: value })
													updateSomethingChanged(true)
												}}
												value={{
													value: fields.tagGroupIcon,
													label: renderIconAndName(fields.tagGroupIcon),
												}}
												className={!I.have('admin', appState.permission[appState.context]) ? 'no-hover' : ''}
												options={Object.keys(Feather).map((i: any) => {
													return {
														value: i,
														label: renderIconAndName(i),
													}
												})}
												defaultMenuIsOpen={urlGroupID !== 'new'}
											/>
										)}
									</Col>
									<Col sm="auto">
										{!isIconDisabled && urlGroupID !== 'new' ? (
											<Button
												type="button"
												variant="success"
												onClick={() => {
													updateIconDisabled(true)
													updateGroup()
												}}
											>
												Save
											</Button>
										) : null}
									</Col>
									<Col sm="auto">
										{!isIconDisabled && urlGroupID !== 'new' ? (
											<Button
												type="button"
												onClick={() => {
													updateIconDisabled(true)
													resetGroup()
												}}
											>
												Cancel
											</Button>
										) : null}
									</Col>
								</Form.Group>

								<Form.Group controlId="formGroupColour" as={Row}>
									<Form.Label column sm="2">
										Colour
									</Form.Label>
									<Col sm={'7'}>
										{isColourDisabled && urlGroupID !== 'new' ? (
											<div
												className={`form-control-plaintext${
													!I.have('admin', appState.permission[appState.context]) ? ' no-hover' : ''
												}`}
												onClick={() => I.have('admin', appState.permission[appState.context]) && updateColourDisabled(false)}
											>
												<>
													<div
														style={{
															backgroundColor: fields.colourHexCode,
															width: '100%',
															height: '2em',
															borderRadius: '5px',
														}}
													></div>
												</>
											</div>
										) : (
											<Select
												name="colourID"
												// readOnly={isColourDisabled && urlGroupID !== 'new'}
												// plaintext={isColourDisabled && urlGroupID !== 'new'}
												isDisabled={!I.have('admin', appState.permission[appState.context])}
												// onClick={() => I.have('admin', appState.permission[appState.context]) && updateColourDisabled(false)}
												onChange={({ value }: any) => {
													updateFields({
														...fields,
														colourID: value,
														colourHexCode: colours.find((c) => c.colourID === value)
															? colours.find((c) => c.colourID === value)!.colourHexCode
															: fields.colourHexCode,
													})
													updateSomethingChanged(true)
												}}
												value={{
													value: fields.colourID,
													label: (
														<div
															style={{
																backgroundColor: fields.colourHexCode,
																width: '100%',
																height: '2em',
																borderRadius: '5px',
															}}
														></div>
													),
												}}
												className={!I.have('admin', appState.permission[appState.context]) ? 'no-hover' : ''}
												options={colours.map((c: any) => {
													return {
														value: c.colourID,
														label: (
															<div
																style={{
																	backgroundColor: c.colourHexCode,
																	width: '100%',
																	height: '2em',
																	borderRadius: '5px',
																}}
															></div>
														),
													}
												})}
												styles={customStyles}
											/>
										)}
									</Col>
									<Col sm="auto">
										{!isColourDisabled && urlGroupID !== 'new' ? (
											<Button
												type="button"
												variant="success"
												onClick={() => {
													updateColourDisabled(true)
													updateGroup()
												}}
											>
												Save
											</Button>
										) : null}
									</Col>
									<Col sm="auto">
										{!isColourDisabled && urlGroupID !== 'new' ? (
											<Button
												type="button"
												onClick={() => {
													updateColourDisabled(true)
													resetGroup()
												}}
											>
												Cancel
											</Button>
										) : null}
									</Col>
								</Form.Group>

								{urlGroupID !== 'new' ? (
									<Form.Group as={Row}>
										<Form.Label column sm="2">
											Tags
										</Form.Label>
										<Col sm="10">
											{fields.tags!.map((t) => (
												<TagSingle
													key={t.tagID}
													tag={{
														...t,
														tagGroup: {
															tagGroupID: urlGroupID,
															tagGroupName: fields.tagGroupName,
														},
													}}
													appState={appState}
													displayMessage={displayMessage}
													updateTags={updateTags}
												/>
											))}
											{urlGroupID !== 'new' &&
											(fields.tags!.length === 0 || fields.tags![fields.tags!.length - 1].tagID !== 'new') &&
											I.have('admin', appState.permission[appState.context]) ? (
												<Button type="button" onClick={handleAddTag}>
													Add a tag
												</Button>
											) : null}
										</Col>
									</Form.Group>
								) : null}
								<Row>
									<Col sm={{ span: 'auto', offset: '2' }}>
										{urlGroupID === 'new' ? (
											<Button variant="primary" type="submit" disabled={!validateForm()}>
												Add Group
											</Button>
										) : null}
									</Col>
								</Row>
							</Form>
						</Col>
					</Row>
				</Card>
				<Dialog
					show={showDialog}
					handleClose={() => updateShowDialog(false)}
					handleConfirm={handleDeleteGroup}
					titleText={'Delete group confirmation'}
					bodyText={'Are you sure you want to delete this group?'}
				/>
				<Messages.Messages messages={messages} updateMessage={updateMessages} />
			</Container>
		)
	} else {
		return (
			<Container>
				<Row>
					<Col style={{ paddingTop: '3px' }}>
						<Link to="/tags">
							<Feather.ArrowLeftCircle /> Back to all groups
						</Link>
					</Col>
				</Row>
			</Container>
		)
	}
}

const customStyles = {
	singleValue: (styles: any, { data }: any) => ({
		...styles,
		backgroundColor: data.colourHexCode,
		borderRadius: '5px',
		width: '97%',
		height: '2em',
	}),
}

export default GroupSingle
