import React from 'react'
import * as Feather from 'react-feather'

const renderIcon = (name: any, size?: number, style?: any) => {
	const IconType: keyof typeof Feather = name
	const Icon = Feather[IconType]
	return name ? <Icon size={size} style={style} /> : ''
}

export { renderIcon }
