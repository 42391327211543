import React from 'react'

import { Auth } from 'aws-amplify'
import { Col, Badge, Button, Row, OverlayTrigger, Popover, Spinner } from 'react-bootstrap'
import { Bell, MoreVertical, ExternalLink } from 'react-feather'
import { Link } from 'react-router-dom'

import { UserButton } from '../auth/auther'
import { AuthState, CognitoUserAttributes, Permission, ProductContext } from '../../App.d'
import * as ApiDefinitions from '../../../../back-end/utilities/apiDefinitions'
import { Product } from '../../../../back-end/common/product'
import OversightLogo from '../../images/oversight_finlert-1.svg'
import LeaveCalLogo from '../../images/leavecal_finlert-1.svg'
import TAYGLogo from '../../images/taxasyougo_finlert-1.svg'
import SubSyncLogo from '../../images/subSyncIcons/subSync_finlert-1.svg'
import XeroLogo from '../../images/xero-logo.svg'
import * as Request from '../../utilities/request'
import * as I from '../../utilities/me'
import { getPermissionProperty } from '../../utilities/permissions'

interface UserProfileProps {
	authState: AuthState
	userAttributes: CognitoUserAttributes | null | undefined
	permission: Permission
	accounts: ApiDefinitions.Account[]
	context: ProductContext
	selectedOrg?: ApiDefinitions.AccountXeroOrg
}

const refreshSession = async () => {
	const session = await Auth.currentSession()
	const user = await Auth.currentAuthenticatedUser()
	return new Promise((resolve, reject) => {
		// have to use it as no type can be located or inferred
		//eslint-disable-next-line
		user.refreshSession(session.getRefreshToken(), (err: any, session: any) => {
			if (err) {
				reject(err)
			} else {
				resolve(session)
			}
		})
	})
}

const UserProfile = (props: UserProfileProps) => {
	const [buttonLoading, updateButtonLoading] = React.useState<boolean>(false)
	const [accountOrgs, updateAccountOrgs] = React.useState<ApiDefinitions.AccountXeroOrg[] | null>(null)
	const alerts = props.accounts ? props.accounts.filter((acc) => acc.userAccountInvitationAccepted === null) : []
	const allProducts: ('leavecal' | 'oversight' | 'tayg' | 'subsync')[] = ['leavecal', 'oversight', 'tayg', 'subsync']

	const getAccountOrgs = async () => {
		if (props.userAttributes && props.userAttributes?.['custom:currentAccountId']) {
			const req = await Request.get(`organisation?where=AccountID==${props.userAttributes?.['custom:currentAccountId']}`, props.authState)
			if (req.data.accountXeroOrgs.length > 0) {
				updateAccountOrgs(req.data.accountXeroOrgs)
			}
		}
	}

	React.useEffect(() => {
		getAccountOrgs()
	}, [props.userAttributes]) // eslint-disable-line

	const onAccountSwitch = async (eventKey: string) => {
		const accountID = eventKey
		const selectedAccount = props.accounts.find((account) => account.accountID === accountID)
		await Auth.currentAuthenticatedUser()

		if (selectedAccount) {
			const user = await Auth.currentAuthenticatedUser()

			await Auth.updateUserAttributes(user, {
				'custom:currentAccountId': accountID,
				'custom:userAccountId': selectedAccount.userAccountID,
				'custom:role': JSON.stringify({
					os: selectedAccount.osPermissionID,
					lc: selectedAccount.lcPermissionID,
					tayg: selectedAccount.taygPermissionID,
					subsync: selectedAccount.subsyncPermissionID,
				}),
			})
			await refreshSession()

			// if(selectedUserAccount.UserAccountStatusID !== UserAccountStatus.Active) {
			// 	history.push(`invite?code=${selectedUserAccount.UserAccountID}`)
			// }

			window.location.href = '/'
			window.location.reload()
		}
	}

	const getProductImage = (product: string) => {
		if (product === 'leavecal') {
			return <input type="image" alt="leavecal-logo" style={{ width: '80%' }} src={LeaveCalLogo} />
		} else if (product === 'tayg') {
			return <input type="image" alt="tayg-logo" style={{ width: '80%' }} src={TAYGLogo} />
		} else if (product === 'oversight') {
			return <input type="image" alt="oversight-logo" style={{ width: '80%' }} src={OversightLogo} />
		} else if (product === 'subsync') {
			return <input type="image" alt="subsync-logo" style={{ width: '80%' }} src={SubSyncLogo} />
		} else if (product === 'xero') {
			return (
				<div style={{ display: 'inline-flex', width: '-webkit-fill-available' }}>
					<span style={{ minWidth: 'fit-content', paddingRight: '10px' }}>Jump to </span>
					<input type="image" alt="xero-logo" style={{ width: '80%' }} src={XeroLogo} />
				</div>
			)
		}
	}

	const getAccountProducts = () => {
		if (props.selectedOrg === undefined) {
			if (accountOrgs) {
				const allOrgProducts = accountOrgs.reduce(
					(products: Product[], org: ApiDefinitions.AccountXeroOrg) => products.concat(org.xeroOrg!.products!),
					[]
				)
				return allProducts.filter((p) => allOrgProducts.some((prod: Product) => prod.name!.toLocaleLowerCase().includes(p)) || props.permission[p])
			}
			return allProducts.filter((p) => props.context.toLocaleLowerCase().includes(p) || props.permission[p])
		} else {
			return allProducts.filter(
				(p) => props.selectedOrg!.xeroOrg!.products!.some((prod: Product): boolean => prod.name!.toLocaleLowerCase().includes(p)) || props.permission[p]
			)
		}
	}

	if (props.userAttributes === null || props.userAttributes === undefined) {
		return <Link to="/login" />
	} else {
		const connectedProducts = getAccountProducts()
		const otherProducts = allProducts.filter((p) => connectedProducts.indexOf(p) < 0)

		return (
			<Row>
				<Col xs="auto" className="alert-bell">
					{alerts.length > 0 ? (
						<OverlayTrigger
							trigger="click"
							key="bottom"
							placement="bottom"
							overlay={
								<Popover id="popover-positioned-bottom">
									<Popover.Title as="h3">{`You have an account invite`}</Popover.Title>
									<Popover.Content>
										{alerts.map((alert) => (
											<div className="bell-alert-row" key={alert.accountID}>
												<p>
													{alert.owner?.email} <Button onClick={() => onAccountSwitch(alert.accountID!)}>View</Button>
												</p>
											</div>
										))}
									</Popover.Content>
								</Popover>
							}
						>
							<div>
								<Bell size={30} className={'blue'} />
								<Badge
									style={{
										position: 'absolute',
										top: 0,
										right: 0,
										padding: 5,
										borderRadius: 10,
										fontWeight: 'bold',
									}}
									variant="danger"
									pill
								>
									{alerts.length}
								</Badge>
							</div>
						</OverlayTrigger>
					) : (
						<Bell size={30} className={'blue'} />
					)}
				</Col>
				<Col xs="auto" className="more-apps">
					<OverlayTrigger
						rootClose
						trigger="click"
						key="bottom"
						placement="bottom"
						overlay={
							<Popover id="popover-positioned-bottom">
								<Popover.Content>
									<div>
										<span>Connected apps</span>
										{connectedProducts
											.filter((cp: string) => cp !== props.context)
											.map((cp: string) => (
												<Row style={{ padding: '10px' }} key={cp}>
													<Button
														style={{ border: 'hidden', display: 'inline-flex' }}
														onClick={() => {
															window.open(`${window.location.href.replace(props.context, cp)}`, '_blank')
															document.body.click() // to close popover after a selection is made
														}}
													>
														{getProductImage(cp)}
														<ExternalLink
															style={{
																paddingLeft: '20px',
																marginTop: '5px',
																width: '50%',
															}}
														/>
													</Button>
												</Row>
											))}
										<Row style={{ padding: '10px', justifyContent: 'end' }}>
											<Button
												style={{ border: 'hidden', display: 'inline-flex' }}
												onClick={() => {
													window.open(
														props.selectedOrg &&
															props.selectedOrg.xeroOrg &&
															!(props.selectedOrg.xeroOrg!.name! === 'Combined TAYG')
															? `https://go.xero.com/app/${props.selectedOrg!.xeroOrg!.shortCode}/dashboard`
															: 'https://login.xero.com/',
														'_blank'
													)
													document.body.click()
												}}
											>
												{getProductImage('xero')}
												<div style={{ paddingRight: '105px' }}></div>
												<ExternalLink
													style={{
														paddingLeft: '20px',
														marginTop: '5px',
														width: '50%',
													}}
												/>
											</Button>
										</Row>
									</div>
									<hr />
									<div>
										<span>Other apps</span>
										{otherProducts.map((cp) => (
											<Row style={{ padding: '10px' }} key={cp}>
												<Button
													disabled={buttonLoading}
													style={{ border: 'hidden', display: 'inline-flex' }}
													onClick={async () => {
														updateButtonLoading(true)
														await Request.put(
															`user/${props.userAttributes?.['custom:userId']}`,
															{ [getPermissionProperty(cp)]: I.Admin },
															props.authState
														)
														window.open(`${window.location.href.replace(props.context, cp)}`, '_blank')
														document.body.click()
														updateButtonLoading(false)
													}}
												>
													{getProductImage(cp)} {buttonLoading && <Spinner animation="border" size={'sm'} />}
													<ExternalLink
														style={{
															paddingLeft: '20px',
															marginTop: '5px',
															width: '50%',
														}}
													/>
												</Button>
											</Row>
										))}
									</div>
								</Popover.Content>
							</Popover>
						}
					>
						<div style={{ marginLeft: '-8px', marginRight: '12px' }}>
							<MoreVertical size={30} className={'dots'} />
							<MoreVertical size={30} className={'dots'} />
							<MoreVertical size={30} className={'dots'} />
						</div>
					</OverlayTrigger>
				</Col>
				<Col xs="auto">
					<UserButton
						userAttributes={props.userAttributes}
						permission={props.permission}
						accounts={props.accounts}
						context={props.context}
						selectedOrg={props.selectedOrg}
					/>
				</Col>
			</Row>
		)
	}
}

export default UserProfile
