import * as React from 'react'
import Dropdown from 'react-bootstrap/Dropdown'
import { AccountXeroOrg, Calendar as CalendarData } from '../../../../back-end/utilities/apiDefinitions'
import * as AppDefinitions from '../../App.d'

interface IndentedDropdownSelectorProps {
	selectedCal: string | null
	selectedOrg: string | null
	organisations: AccountXeroOrg[] | null
	calendars: CalendarData[] | null
	updateSelectedOrg: React.Dispatch<React.SetStateAction<AccountXeroOrg | null>>
	updateSelectedCalendar: React.Dispatch<React.SetStateAction<CalendarData | null>>
	appState: AppDefinitions.AppState
	refreshData: () => void
	updatePageStatus: React.Dispatch<React.SetStateAction<string>>
}

type list = {
	id: string | undefined
	name: string | undefined
	disabled: boolean
	type: string
}

const IndentedDropdownSelector = (props: IndentedDropdownSelectorProps) => {
	React.useEffect(() => {
		const fetchData = async () => {
			theChosenCal()
		}

		if (props.selectedCal !== '' && props.selectedOrg !== '') {
			fetchData()
		}
	}, [props.appState.authState, props.selectedCal, props.selectedOrg]) // eslint-disable-line

	const theChosenCal = () => {
		const chosenCal: CalendarData | undefined = props.calendars!.find((c) => c.calendarID === props.selectedCal)
		const chosenOrg: AccountXeroOrg | undefined = chosenCal
			? props.organisations!.find((org) => org.accountXeroOrgID === chosenCal.accountXeroOrgID)
			: props.organisations!.find((org) => org.accountXeroOrgID === props.selectedOrg)
		return `${chosenOrg ? `${chosenOrg.xeroOrg!.name}` : 'None'} > ${chosenCal ? `${chosenCal!.fileName}` : 'None'}`
	}

	const getMenu = () => {
		const finalList: list[] = props
			.organisations!.sort((orgA, orgB) => orgA.xeroOrg!.name!.localeCompare(orgB.xeroOrg!.name!))
			.reduce(
				(final, org) => [
					...final,
					{
						id: org.accountXeroOrgID,
						name: org.xeroOrg!.name,
						type: 'org',
						disabled: props.calendars!.filter((cal) => org.accountXeroOrgID === cal.accountXeroOrgID).length !== 0 ? true : false,
					},
					...props
						.calendars!.filter((cal) => org.accountXeroOrgID === cal.accountXeroOrgID)
						.sort((calA, calB) => calA.fileName!.localeCompare(calB.fileName!))
						.map((cal) => ({
							id: cal.calendarID,
							name: `        ${cal.fileName}`,
							type: 'cal',
							disabled: false,
						})),
				],
				[] as list[]
			)

		return finalList
	}

	return (
		<Dropdown
			style={{ paddingLeft: '15px' }}
			onSelect={(e: string | null) => {
				const selectedCal = props.calendars!.find((c) => c.calendarID === e!.toString())
				const selectedOrg = selectedCal
					? props.organisations!.find((o) => o.accountXeroOrgID === selectedCal!.accountXeroOrgID)
					: props.organisations!.find((o) => o.accountXeroOrgID === e!.toString())
				if (selectedCal && selectedCal.calendarID) {
					props.updateSelectedCalendar(selectedCal)
					localStorage.setItem('selectedCalendarID', selectedCal.calendarID)
				}
				if (selectedOrg && selectedOrg.xeroOrg && selectedOrg.xeroOrg.xeroOrgID) {
					props.updateSelectedOrg(selectedOrg)
					localStorage.setItem('selectedOrgID', selectedOrg.xeroOrg.xeroOrgID)
				}
				props.refreshData()
				props.updatePageStatus('Loading')
			}}
		>
			<Dropdown.Toggle id="dropdown-custom-components" style={{ border: '0px', fontSize: '14px', height: '31px' }}>
				{theChosenCal()}
			</Dropdown.Toggle>

			<Dropdown.Menu>
				{getMenu().map((item) => {
					return (
						<Dropdown.Item
							key={item.id}
							eventKey={item.id}
							disabled={item.disabled && item.type === 'org'}
							style={{
								paddingLeft: item.type === 'org' ? '24px' : ' 44px',
								fontSize: '14px',
							}}
						>
							{item.name}
						</Dropdown.Item>
					)
				})}
			</Dropdown.Menu>
		</Dropdown>
	)
}

export { IndentedDropdownSelector }
