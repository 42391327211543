import React, { useRef } from 'react'
import { Button, Col, OverlayTrigger, Row, Spinner, Table, Tooltip } from 'react-bootstrap'
import { FormatID, PagesWrapperChildProps } from './admin'
import * as Format from '../../utilities/format'
import { ChevronDown, ChevronUp, Lock, Unlock } from 'react-feather'
import * as Request from '../../utilities/request'
import * as AppDefinitions from '../../App.d'
import { User, XeroOrg } from '../../../../back-end/utilities/apiDefinitions'
import * as RecordStatus from '../../types/recordStatus'
import { Client } from './clients'
import XeroDisconnect from '../../images/disconnect-white.svg'

interface Account {
	accountID: string
	name: string
	active: boolean
	orgs: { id: string; name: string; products: string }[]
	createTs: string
	modifiedTs: string
	authUsers?: {
		userAccountXeroOrgAuthID?: string
		userID?: string
		firstName?: string
		lastName?: string
		email?: string
		xeroAccessToken?: string
		xeroTokenExpiry?: string
	}[]
	owner?: {
		userID?: string
		firstName?: string
		lastName?: string
		email?: string
	}
	calendars?: {
		calendarID?: string
		fileName?: string
		fileLocation?: string
		fileLastUpdatedTs?: string | null
		outputToHtml?: boolean
	}[]
	invoiceCount?: number
}

interface AccountRowProps extends Account {
	deleteAccount: (accountID: string) => void
	setAccountActivity: (accountID: string, isActive: boolean) => void
	revokeConnections: (accountID: string) => void
	index: number
	appState: AppDefinitions.AppState
	setTab: React.Dispatch<React.SetStateAction<string>>
	focusOn: {
		id: string
		setId: React.Dispatch<React.SetStateAction<string>>
	}
	setPage: React.Dispatch<React.SetStateAction<number>>
	pageSize: number
}

const AccountRow = (props: AccountRowProps) => {
	const [deleteIsLocked, setDeleteIsLocked] = React.useState<boolean>(true)
	const [userIsSure, setUserIsSure] = React.useState<boolean>(false)
	const [isOpen, updateIsOpen] = React.useState<boolean>(props.focusOn.id === props.accountID ? true : false)
	const [users, updateUsers] = React.useState<User[]>([])
	const [orgs, updateOrgs] = React.useState<Client[]>([])
	const [disconnecting, updateDisconnecting] = React.useState<boolean>(false)

	const elemRef = useRef<HTMLTableRowElement | null>(null)

	React.useEffect(() => {
		const getData = async () => {
			const [usersForAccount, xeroOrgsForAccount] = await Promise.all([
				Request.get(`admin/user/${props.accountID}`, props.appState.authState),
				Request.get(`admin/clients?account=${props.accountID}`, props.appState.authState),
			])
			updateUsers(usersForAccount.data.users)
			updateOrgs(
				xeroOrgsForAccount.data.clients
					? xeroOrgsForAccount.data.clients.map((xo: XeroOrg) => {
							return {
								xeroOrgID: xo.xeroOrgID,
								name: xo.name,
								createTs: xo.createTs,
								products: xo.products,
								version: xo.version,
								legalName: xo.legalName,
								lastSync: xo.leaveCalLastEmployeeDataFetch || xo.leaveCalLastDataFetch,
								active: xo.accountXeroOrgRecordStatusID === RecordStatus.Active ? true : false,
								numberOfEmployees: xo.numEmployees,
								connectionID: xo.connectionID,
							}
					  })
					: []
			)
			props.focusOn.setId('')
		}

		if (props.appState.authState.isLoggedIn && isOpen) {
			getData()
		}

		if (props.focusOn.id === props.accountID && !isOpen) {
			if (props.index + 1 > props.pageSize) {
				props.setPage(Math.floor(props.index / props.pageSize) + 1)
			}
			updateIsOpen(true)
			elemRef.current?.lastElementChild?.scrollIntoView({ behavior: 'smooth' })
		}
	}, [isOpen, props])

	const disconnectOrg = async (org: XeroOrg) => {
		updateDisconnecting(true)
		await Request.del(`admin/xero/tokens/${props.appState.context}/${org.connectionID}`, props.appState.authState)
		updateOrgs(
			orgs
				? orgs.map((xo: Client) => {
						if (xo.xeroOrgID === org.xeroOrgID) {
							return {
								...xo,
								active: false,
								connectionID: undefined,
							}
						} else {
							return xo
						}
				  })
				: []
		)
		updateDisconnecting(false)
	}

	return (
		<>
			<tr key={props.index} ref={elemRef} className={`stripe-row ${props.index % 2 === 0 ? 'stripe-row-body' : ''}`}>
				<td>
					<FormatID id={props.accountID} />
				</td>
				<td>{props.name}</td>
				<td>
					<span>{props.active ? 'Active  ' : 'Inactive  '}</span>
					<Button type="button" onClick={() => props.setAccountActivity(props.accountID, !props.active)}>
						Make {props.active ? 'Inactive' : 'Active'}
					</Button>
				</td>
				<td>{Format.dateTime(props.createTs)}</td>
				<td>{Format.dateTime(props.modifiedTs)}</td>
				<td>{`${props.owner!.firstName} ${props.owner!.lastName}`}</td>
				<td>{props.owner!.email}</td>
				<td>{`${props.invoiceCount ? `${props.invoiceCount}` : '-'}`}</td>
				<td colSpan={2}>
					<>
						{userIsSure && (
							<Button type="button" disabled={deleteIsLocked} onClick={() => props.deleteAccount(props.accountID)}>
								Really Delete?!?!
							</Button>
						)}
						{!userIsSure && (
							<Button type="button" disabled={deleteIsLocked} onClick={() => setUserIsSure(true)}>
								Delete?
							</Button>
						)}
						{deleteIsLocked && <Lock onClick={() => setDeleteIsLocked(false)} />}
						{!deleteIsLocked && (
							<Unlock
								onClick={() => {
									setDeleteIsLocked(true)
									setUserIsSure(false)
								}}
							/>
						)}
					</>
				</td>
				<td>
					{isOpen ? (
						<OverlayTrigger placement="left" overlay={<Tooltip id={`tooltip-xero`}>Show Less</Tooltip>}>
							<ChevronUp
								className="show-cursor"
								onClick={() => {
									props.focusOn.setId('')
									updateIsOpen(!isOpen)
								}}
							/>
						</OverlayTrigger>
					) : (
						<OverlayTrigger placement="left" overlay={<Tooltip id={`tooltip-xero`}>Show More</Tooltip>}>
							<ChevronDown
								className="show-cursor"
								onClick={() => {
									props.focusOn.setId('')
									updateIsOpen(!isOpen)
								}}
							/>
						</OverlayTrigger>
					)}
				</td>
			</tr>
			{isOpen ? (
				<tr style={{ backgroundColor: props.index % 2 === 0 ? '#f8f6f6' : '' }}>
					<td colSpan={3} style={{ verticalAlign: 'top' }}>
						<Table>
							<thead>
								<tr className="header-row" style={{ backgroundColor: props.index % 2 === 0 ? '#fff' : '#f8f6f6' }}>
									<th>User Name</th>
									<th>OS Permission</th>
									<th>LC Permission</th>
									<th>TAYG Permission</th>
									<th>SubSync Permission</th>
								</tr>
							</thead>
							<tbody className={'card-table-body'}>
								{users.length > 0
									? users.map((u, i) => (
											<tr key={i} className={`stripe-row ${(i + props.index) % 2 === 0 ? 'stripe-row-body' : 'stripe-row-body-white'}`}>
												<td>{`${u.firstName} ${u.lastName}`}</td>
												<td>{u.osPermission ? (u.osPermission.name ? u.osPermission.name : 'N/A') : 'No permissions'}</td>
												<td>{u.lcPermission ? (u.lcPermission.name ? u.lcPermission.name : 'N/A') : 'No permissions'}</td>
												<td>{u.taygPermission ? (u.taygPermission.name ? u.taygPermission.name : 'N/A') : 'No permissions'}</td>
												<td>
													{u.subsyncPermission ? (u.subsyncPermission.name ? u.subsyncPermission.name : 'N/A') : 'No permissions'}
												</td>
											</tr>
									  ))
									: 'No users available'}
							</tbody>
						</Table>
					</td>
					<td colSpan={8} style={{ verticalAlign: 'top' }}>
						<Table>
							<thead>
								<tr className="header-row" style={{ backgroundColor: props.index % 2 === 0 ? '#fff' : '#f8f6f6' }}>
									<th>XeroOrgID</th>
									<th>Org Name</th>
									<th>Org Country</th>
									<th>Org Legal Name</th>
									<th>No. Employees</th>
									<th>Created</th>
									<th>Last Sync</th>
									<th>Org Active</th>
									<th></th>
								</tr>
							</thead>
							<tbody className={'card-table-body'}>
								{orgs.length > 0
									? orgs.map((org, i) => (
											<tr key={i} className={`stripe-row ${(i + props.index) % 2 === 0 ? 'stripe-row-body' : 'stripe-row-body-white'}`}>
												<td>
													<FormatID id={org.xeroOrgID} />
												</td>
												<td>
													<span
														className="clickable"
														style={{ margin: '0px' }}
														onClick={() => {
															props.setTab('clients')
															props.focusOn.setId(org.xeroOrgID)
														}}
													>
														{org.name}
													</span>
												</td>
												<td>{org.version}</td>
												<td>{org.legalName}</td>
												<td>{org.numberOfEmployees}</td>
												<td>{Format.dateTime(org.createTs)}</td>
												<td>{Format.dateTime(org.lastSync)}</td>
												<td>{org.active ? 'Active' : 'Inactive'}</td>
												<td>
													{org.active && org.connectionID ? (
														disconnecting ? (
															<Spinner animation={'border'} size={'sm'} style={{ marginLeft: '5px' }} />
														) : (
															<img
																src={XeroDisconnect}
																alt="Disconnect from Xero"
																style={{ cursor: 'pointer' }}
																onClick={() => disconnectOrg(org)}
															/>
														)
													) : null}
												</td>
											</tr>
									  ))
									: 'No Xero Orgs available'}
							</tbody>
						</Table>
					</td>
				</tr>
			) : null}
		</>
	)
}
const setAccountActivityFake = () => {}
const deleteAccountFake = () => {}
const revokeConnectionsFake = () => {}

const AccountTab = (props: PagesWrapperChildProps<Account[]>) => (
	<>
		<Row>
			<Col>
				<h4>Accounts</h4>
			</Col>
			{props.pageElements}
		</Row>
		<Row>
			<Col>
				<Table borderless responsive>
					<thead>
						<tr className="header-row">
							<th>AccountID</th>
							<th>Name</th>
							<th>Active</th>
							<th>Created</th>
							<th>Modified</th>
							<th>Owner</th>
							<th>Email</th>
							<th>{`No. Invoices (Last 30 days)`}</th>
							<th></th>
							<th></th>
						</tr>
					</thead>
					<tbody className={'card-table-body'}>
						{props.isLoading && (
							<tr>
								<td colSpan={7}>Loading Accounts</td>
							</tr>
						)}
						{!props.isLoading && props.data.length === 0 && (
							<tr>
								<td colSpan={7}>No Accounts</td>
							</tr>
						)}
						{!props.isLoading &&
							props.data.length > 0 &&
							props.data
								.map((d, index) => (
									<AccountRow
										key={d.accountID}
										{...d}
										index={index}
										pageSize={props.pageSize}
										setPage={props.setPage}
										setTab={props.setTab}
										focusOn={props.focusOn}
										appState={props.appState}
										revokeConnections={props.actions?.revokeConnections || revokeConnectionsFake}
										setAccountActivity={props.actions?.setAccountActivity || setAccountActivityFake}
										deleteAccount={props.actions?.deleteAccount || deleteAccountFake}
									/>
								))
								.slice(props.pageSize * (props.page - 1), props.pageSize * props.page)}
					</tbody>
				</Table>
			</Col>
		</Row>
	</>
)

export type { Account }
export default AccountTab
