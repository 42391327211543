import React from 'react'

// components
import { DashboardTable } from './table'
import Fade from '@material-ui/core/Fade'
import LinearProgress from '@material-ui/core/LinearProgress'

// definitions
import { DashboardTableProps } from './table'

interface LiveTabProps extends DashboardTableProps {}

const LiveTab = (props: LiveTabProps) => {
	const [progress, setProgress] = React.useState<number>(0)

	React.useEffect(() => {
		if (!props.isLoading) {
			setProgress(0)
			return
		}

		const intervalId = setInterval(() => {
			setProgress(progress + 5)
		}, 500)

		return () => clearInterval(intervalId)
	}, [props.isLoading, progress])

	return (
		<>
			<Fade in={props.isLoading} style={{ width: '100%' }}>
				<LinearProgress variant={'determinate'} value={progress} />
			</Fade>
			<DashboardTable {...props} />
		</>
	)
}

export { LiveTab }
