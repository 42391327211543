import React from 'react'
import { Form, Row, Col, DropdownButton, Dropdown, InputGroup, Spinner } from 'react-bootstrap'

// components
import { Search } from 'react-feather'

// definitions
import { AccountXeroOrgWithData } from '../../../../screens/subsync/subsync'
import { StripeOrgAccountXeroOrg, XeroAPIContact } from '../../../../../../back-end/utilities/apiDefinitions'
import { MessageAction } from '../../../ui/messages/message'
import { AppState } from '../../../../App.d'

// utilities
import * as Request from '../../../../utilities/request'

interface StripeFeesSettingsProps {
	orgMapping: StripeOrgAccountXeroOrg | undefined
	mappedXeroOrg: AccountXeroOrgWithData | undefined
	stripeOrgID: string
	xeroOrgs: AccountXeroOrgWithData[]
	appState: AppState
	handleSettingsUpdate: (stripeOrgID: string, values: { [name: string]: string | boolean | XeroAPIContact | undefined }) => void
	updateMessages: (action: MessageAction) => void
}

const StripeFeesSettings = (props: StripeFeesSettingsProps) => {
	// Xero Contact live search
	const [xeroContactSearch, setXeroContactSearch] = React.useState<string>('')
	const [isSearching, setIsSearching] = React.useState<boolean>(false)
	const [xeroContacts, setXeroContacts] = React.useState<XeroAPIContact[]>([])
	const [selectedXeroContactID, setSelectedXeroContactID] = React.useState<string>('')

	const handleXeroContactSearch = async (mapping: StripeOrgAccountXeroOrg) => {
		setIsSearching(true)
		try {
			const accountXeroOrg = props.xeroOrgs.find((accountXeroOrg) => accountXeroOrg.accountXeroOrgID === mapping.accountXeroOrgID)
			const body = {
				userAccountXeroOrgAuthID: accountXeroOrg?.userAccountXeroOrgAuth?.id,
				tenantID: accountXeroOrg?.xeroOrg?.tenantID,
				xeroOrgID: accountXeroOrg?.xeroOrg?.xeroOrgID,
				contactName: xeroContactSearch,
			}
			const res = await Request.post('xerocontact/search', body, props.appState.authState)
			setXeroContacts(res.data.data)
		} catch (e) {
			props.updateMessages({
				type: 'add',
				data: {
					severity: 'danger',
					message: 'Unable to search Xero contacts',
					timeout: 3000,
					dismissible: true,
				},
			})
		} finally {
			setIsSearching(false)
		}
	}

	return (
		<>
			<Form.Group as={Row} className="d-flex align-items-center" style={{ height: '2.5rem' }}>
				<Col>
					<Form.Check
						type={'checkbox'}
						id={`${props.stripeOrgID}-stripe-fees-bills-enabled`}
						name={'stripeFeesBillsEnabled'}
						label={'Enable Bills for Stripe Fees'}
						checked={!!props.orgMapping?.stripeOrgAccountXeroOrgSettings?.stripeFeesBillsEnabled}
						onChange={(e) => props.handleSettingsUpdate(props.stripeOrgID, { [e.target.name]: e.target.checked })}
					/>
				</Col>
			</Form.Group>
			<Form.Group as={Row} style={{ height: '2.5rem' }}>
				<Form.Label column sm={2}>
					Xero Contact
				</Form.Label>
				<Col sm={3}>
					<InputGroup>
						{props.orgMapping?.stripeOrgAccountXeroOrgSettings?.stripeFeesBillsEnabled && (
							<p>
								{xeroContacts.find((c) => c.contactID === selectedXeroContactID)?.name ||
									props.mappedXeroOrg!.contacts.find(
										(c) => c.xeroContactID === props.orgMapping?.stripeOrgAccountXeroOrgSettings?.stripeFeesXeroContactID
									)?.name ||
									'Search to select a contact'}
							</p>
						)}
					</InputGroup>
				</Col>
				{props.orgMapping?.stripeOrgAccountXeroOrgSettings?.stripeFeesBillsEnabled ? (
					<>
						<Col sm={3}>
							<InputGroup>
								<Form.Control
									placeholder="Search Xero contacts"
									value={xeroContactSearch}
									onChange={(e) => setXeroContactSearch(e.target.value)}
								/>
								<InputGroup.Text>
									{isSearching ? (
										<Spinner animation={'border'} size={'sm'} />
									) : (
										<Search className="clickable-icon" onClick={() => handleXeroContactSearch(props.orgMapping!)} />
									)}
								</InputGroup.Text>
							</InputGroup>
						</Col>
						<Col>
							<DropdownButton
								id={`${props.stripeOrgID}-stripe-fees-bills-contact-select`}
								title={xeroContacts.find((c) => c.contactID === selectedXeroContactID)?.name || xeroContacts[0]?.name || 'Select Xero Contact'}
								onSelect={(eventKey) => {
									setSelectedXeroContactID(eventKey!)
									props.handleSettingsUpdate(props.stripeOrgID, {
										stripeFeesXeroContactXeroID: eventKey!,
										xeroContact: xeroContacts.find((c) => c.contactID === eventKey),
									})
								}}
								disabled={!props.orgMapping?.stripeOrgAccountXeroOrgSettings?.stripeFeesBillsEnabled}
							>
								{xeroContacts.map((contact) => (
									<Dropdown.Item key={contact.contactID} eventKey={contact.contactID}>
										{contact.name}
									</Dropdown.Item>
								))}
							</DropdownButton>
						</Col>
					</>
				) : null}
			</Form.Group>
			<Form.Group as={Row} style={{ height: '2.5rem' }}>
				<Form.Label column sm={2}>
					Xero Account
				</Form.Label>
				<Col sm={4}>
					<DropdownButton
						id={`${props.stripeOrgID}-stripe-fees-bills-account-select`}
						title={
							props.mappedXeroOrg!.accounts.find(
								(a) => a.xeroAccountID === props.orgMapping?.stripeOrgAccountXeroOrgSettings?.stripeFeesXeroAccountID
							)?.name || 'Select Xero Account'
						}
						onSelect={(eventKey) => props.handleSettingsUpdate(props.stripeOrgID, { stripeFeesXeroAccountID: eventKey! })}
						disabled={!props.orgMapping?.stripeOrgAccountXeroOrgSettings?.stripeFeesBillsEnabled}
					>
						<Dropdown.Header>Direct Costs</Dropdown.Header>
						{props
							.mappedXeroOrg!.accounts.filter((account) => account.type === 'DIRECTCOSTS')
							.sort((a, b) => a.name!.localeCompare(b.name!, undefined, { numeric: true }))
							.map((account) => (
								<Dropdown.Item key={account.xeroAccountID} eventKey={account.xeroAccountID}>
									{account.name}
								</Dropdown.Item>
							))}
						<Dropdown.Header>Expenses</Dropdown.Header>
						{props
							.mappedXeroOrg!.accounts.filter((account) => account.type === 'EXPENSE')
							.sort((a, b) => a.name!.localeCompare(b.name!, undefined, { numeric: true }))
							.map((account) => (
								<Dropdown.Item key={account.xeroAccountID} eventKey={account.xeroAccountID}>
									{account.name}
								</Dropdown.Item>
							))}
					</DropdownButton>
				</Col>
			</Form.Group>
			<Form.Group as={Row} style={{ height: '2.5rem' }}>
				<Form.Label column sm={2}>
					Xero Tax Rate
				</Form.Label>
				<Col sm={4}>
					<DropdownButton
						id={`${props.stripeOrgID}-stripe-fees-bills-tax-rate-select`}
						title={
							props.mappedXeroOrg!.taxRates.find(
								(tr) => tr.xeroTaxRateID === props.orgMapping?.stripeOrgAccountXeroOrgSettings?.stripeFeesXeroTaxRateID
							)?.name || 'Select Xero Tax Rate'
						}
						onSelect={(eventKey) => props.handleSettingsUpdate(props.stripeOrgID, { stripeFeesXeroTaxRateID: eventKey! })}
						disabled={!props.orgMapping?.stripeOrgAccountXeroOrgSettings?.stripeFeesBillsEnabled}
					>
						{props
							.mappedXeroOrg!.taxRates.filter((tr) => tr.canApplyToExpenses)
							.map((taxRate) => (
								<Dropdown.Item key={taxRate.xeroTaxRateID} eventKey={taxRate.xeroTaxRateID}>
									{taxRate.name} {taxRate.effectiveRate ? `(${(Number(taxRate.effectiveRate) * 100).toFixed(2)}%)` : ''}
								</Dropdown.Item>
							))}
					</DropdownButton>
				</Col>
			</Form.Group>
		</>
	)
}

export { StripeFeesSettings }
