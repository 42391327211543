import React from 'react'
import { Col, Container, OverlayTrigger, Row, Tooltip } from 'react-bootstrap'
import axios from 'axios'
import * as Request from '../../utilities/request'
import * as Messages from '../../components/ui/messages/messages'
import { Info } from 'react-feather'
import LogoUploader, { ImageFile } from './logoUploader'
import { Account } from '../../../../back-end/utilities/apiDefinitions'
import { AppContext } from '../../App'

const CustomBranding = () => {
	const { appState } = React.useContext(AppContext)
	const [messages, updateMessages] = Messages.useMessageReducer([])
	const [account, updateAccount] = React.useState<Account | null>(null)

	const getAccount = React.useCallback(async () => {
		const request = await Request.get(`account?where=AccountID==${appState?.authState?.attributes?.['custom:currentAccountId']}`, appState.authState)
		updateAccount(request.data.accounts.filter((a: Account) => a.accountID === appState?.authState?.attributes?.['custom:currentAccountId'])[0])
	}, [appState])

	React.useEffect(() => {
		if (appState.authState.isLoggedIn) {
			getAccount()
		}
	}, [appState.authState, getAccount])

	const uploadToSignedUrl = async (signedUrl: string, file: ImageFile) => {
		const options = {
			headers: {
				'Content-Type': `${file.type}`,
				'Content-Disposition': `attachment; filename="${file.name!}"`,
			},
		}

		return axios.put<unknown>(signedUrl, file.file, options)
	}

	const handleLogoUpload = async (file: ImageFile, action: 'update' | 'delete', signedUrl?: string) => {
		try {
			if (action === 'update') {
				await uploadToSignedUrl(signedUrl!, file)
				await Request.put(`accountlogo/${account!.accountID}`, { fileType: file.extention! }, appState.authState)
			} else {
				await Request.del(`accountlogo/${account!.accountID}`, appState.authState)
			}

			updateAccount({
				...account,
				accountCustomLogoS3Path: action === 'update' ? `logos/${account!.accountID}/logo.${file.extention}` : '',
			})
			updateMessages({
				type: 'add',
				data: {
					severity: 'success',
					message: `Successfully ${action === 'update' ? 'uploaded' : 'deleted'} logo`,
					timeout: 3000,
					dismissible: true,
				},
			})
		} catch (err) {
			updateMessages({
				type: 'add',
				data: {
					severity: 'danger',
					message: `Unable to ${action === 'update' ? 'upload' : 'delete'} this logo`,
					timeout: 3000,
					dismissible: true,
				},
			})
		}
	}

	if (account) {
		return (
			<Container className="oversight-container">
				<Row>
					<Col sm="auto" style={{ paddingBottom: '20px' }}>
						<h2>Custom Branding</h2>
					</Col>
				</Row>
				<Row>
					<Col sm="auto">
						<p>
							The image will be displayed in the exact size that you upload, so
							<a href="https://imageresizer.com/" target="_blank" title="please resize the image" rel="noreferrer">
								{' '}
								please resize the image
							</a>{' '}
							if it is not exactly as you want it.
						</p>
					</Col>
				</Row>
				<Row>
					<Col sm="auto">
						<h6>Account Logo</h6>
					</Col>
					<Col></Col>
					<Col sm="auto">
						<OverlayTrigger
							placement="auto"
							overlay={
								<Tooltip id={`tooltip-custom`}>
									Personalise your email alerts for this account by having them be sent with the logo you upload here.
								</Tooltip>
							}
						>
							<div>
								<Info className={'settings-plus'} />
							</div>
						</OverlayTrigger>
					</Col>
				</Row>
				<Row>
					<LogoUploader
						appState={appState}
						updateMessage={updateMessages}
						logo={account!.accountCustomLogoS3Path || null}
						handleLogoUpload={handleLogoUpload}
					/>
				</Row>
				<Messages.Messages messages={messages} updateMessage={updateMessages} />
			</Container>
		)
	} else {
		return <></>
	}
}

export default CustomBranding
