import * as React from 'react'
import Dropdown from 'react-bootstrap/Dropdown'
import { AccountXeroOrg } from '../../../../back-end/utilities/apiDefinitions'
import * as AppDefinitions from '../../App.d'

interface IndentedDropdownSelectorProps {
	selectedOrg: string | null
	organisations: AccountXeroOrg[] | null
	updateSelectedOrg: React.Dispatch<React.SetStateAction<AccountXeroOrg | null>>
	appState: AppDefinitions.AppState
	refreshData: () => void
	updatePageStatus: React.Dispatch<React.SetStateAction<string>>
}

type list = {
	id: string | undefined
	name: string | undefined
	disabled: boolean
	type: string
}

const IndentedDropdownSelector = (props: IndentedDropdownSelectorProps) => {
	React.useEffect(() => {
		const fetchData = async () => {
			chosenOrg()
		}

		if (props.selectedOrg !== '') {
			fetchData()
		}
	}, [props.appState.authState, props.selectedOrg]) // eslint-disable-line

	const chosenOrg = () => {
		const chosenOrg: AccountXeroOrg | undefined = props.organisations!.find((org) => org.accountXeroOrgID === props.selectedOrg)
		return `${chosenOrg ? `${chosenOrg.xeroOrg!.name}` : 'None'}`
	}

	const getMenu = () => {
		const finalList: list[] = props
			.organisations!.sort((orgA, orgB) => orgA.xeroOrg!.name!.localeCompare(orgB.xeroOrg!.name!))
			.reduce(
				(final, org) => [
					...final,
					{
						id: org.accountXeroOrgID,
						name: org.xeroOrg!.name,
						type: 'org',
						disabled: false,
					},
				],
				[] as list[]
			)

		return finalList
	}

	return (
		<Dropdown
			style={{ paddingLeft: '15px' }}
			onSelect={(e: string | null) => {
				const selectedOrg = props.organisations!.find((o) => o.accountXeroOrgID === e!.toString())
				props.updateSelectedOrg(selectedOrg!)
				props.refreshData()
				localStorage.setItem('selectedOrgID', selectedOrg!.xeroOrg!.xeroOrgID!)
				props.refreshData()
				props.updatePageStatus('Loading')
			}}
		>
			<Dropdown.Toggle id="dropdown-custom-components" style={{ border: '0px', fontSize: '14px', height: '31px' }}>
				{chosenOrg()}
			</Dropdown.Toggle>

			<Dropdown.Menu>
				{getMenu().map((item) => {
					return (
						<Dropdown.Item
							key={item.id}
							eventKey={item.id}
							disabled={item.disabled && item.type === 'org'}
							style={{
								paddingLeft: item.type === 'org' ? '24px' : ' 44px',
								fontSize: '14px',
							}}
						>
							{item.name}
						</Dropdown.Item>
					)
				})}
			</Dropdown.Menu>
		</Dropdown>
	)
}

export { IndentedDropdownSelector }
